import { makeStyles, createStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ typography, palette, shadows, breakpoints }) =>
    createStyles({
      wrapper: {
        display: 'flex',
        overflowX: 'scroll',
        paddingBottom: 15,
        marginBottom: -15,
        [breakpoints.down('sm')]: {
          flexDirection: 'column',
        },
      },
      itemWrapper: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      },
      thumbnailWrapper: {
        width: '100%',
        height: 140,
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      thumbnail: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        objectPosition: 'top left',
        transform: 'unset',
      },
      nameWrapper: {
        padding: '20px 16px',
      },
      name: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        fontWeight: typography.fontWeightMedium,
      },
      mobileList: {
        display: 'flex',
      },
      button: {
        width: 160,
        minWidth: 160,
        marginRight: 16,
        transition: 'all .3s ease',
        borderRadius: 8,
        border: `1px solid ${palette.divider}`,
        overflow: 'hidden',
        '&:hover:not($selected)': {
          textDecoration: 'none',
          borderColor: palette.bottomLineColor,
          boxShadow: shadows[6],
          '@media (hover: none)': {
            backgroundColor: 'transparent',
          },
          '&[disabled]': {
            backgroundColor: 'transparent',
          },
        },
      },
      buttonActive: {
        border: `1px solid ${palette.primary.main}!important`,
      },
      itemPreview: {
        position: 'absolute',
        width: 24,
        height: 24,
        right: 8,
        padding: 0,
        top: 8,
        '& svg': {
          fontSize: 16,
        },
      },
    }),
  { index: 0 }
);
