import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Button, Typography } from '@material-ui/core';
import { useStyles } from './styles';

const NoDataItems = ({ className: classNameProp, label, buttonText, onClick }) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, classNameProp)}>
      {label && (
        <Typography variant="subtitle2" gutterBottom>
          {label}
        </Typography>
      )}

      {buttonText && !!onClick && (
        <Button color="secondary" size="small" onClick={onClick} className={classes.button}>
          {buttonText}
        </Button>
      )}
    </div>
  );
};

NoDataItems.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  buttonText: PropTypes.string,
  onClick: PropTypes.func,
};

export default NoDataItems;
