import { DocumentRole } from 'src/models/document.model';
import { DocumentStatus, TemplateDocumentType } from '../../models/documents.model';

const selectDocumentAuthorId = (state) => state.documentDisplayStore.document?.authorId;

const isDocumentExpired = (state) =>
  state.documentDisplayStore.document?.status === DocumentStatus.EXPIRED;

const isDocumentRejected = (state) =>
  state.documentDisplayStore.document?.status === DocumentStatus.REJECTED;

const isCertificate = (state) =>
  state.documentDisplayStore.document?.documentType === TemplateDocumentType.CERTIFICATES;

const isDocumentFinished = (state) =>
  state.documentDisplayStore.document?.status === DocumentStatus.FINISHED;

const isDocumentSigning = (state) =>
  state.documentDisplayStore.document?.status === DocumentStatus.SIGNING;

const isDocumentIssuing = (state) =>
  state.documentDisplayStore.document?.status === DocumentStatus.ISSUING;

const isDocumentEditing = (state) =>
  state.documentDisplayStore.document?.status === DocumentStatus.EDITING;

const isDocumentValid = (state) =>
  state.documentDisplayStore.document?.status === DocumentStatus.VALID;

const isDocumentTempInvalidated = (state) =>
  state.documentDisplayStore.document?.status === DocumentStatus.TEMPORARILY_INVALIDATE;

const isDocumentInvalidated = (state) =>
  state.documentDisplayStore.document?.status === DocumentStatus.INVALIDATED;

const hasUserSignedDocument = (state) => {
  const { documentMembers } = state.documentManagementStore;
  const activeIdentity = state.identityStore.currentIdentity;

  const getCurrentUserSignerFromMembers = documentMembers.find(
    (key) => key.id === activeIdentity?.identity?.identityId && key.role === DocumentRole.SIGNERS
  );

  return getCurrentUserSignerFromMembers !== undefined
    ? getCurrentUserSignerFromMembers?.status === 'SIGNED'
    : false;
};

const selectDocumentExpiresOn = (state) =>
  state.documentDisplayStore.document?.additionalAttributes?.expiresOn;

export const documentDisplaySelector = {
  hasUserSignedDocument,
  isCertificate,
  isDocumentEditing,
  isDocumentFinished,
  isDocumentInvalidated,
  isDocumentIssuing,
  isDocumentRejected,
  isDocumentExpired,
  isDocumentSigning,
  isDocumentTempInvalidated,
  isDocumentValid,
  selectDocumentExpiresOn,
  selectDocumentAuthorId,
};
