export enum DocumentMemberRole {
  SIGNER = 'SIGNER',
  ADMIN = 'ADMIN',
  AUDITOR = 'AUDITOR',
  EDITOR = 'EDITOR',
  VIEWER = 'VIEWER',
  HOLDER = 'HOLDER',
}

export enum DocumentInvitationMemberRole {
  ADMINS = 'admins',
  AUDITORS = 'auditors',
  EDITORS = 'editors',
  VIEWERS = 'viewers',
  SIGNERS = 'signers',
  HOLDER = 'holder',
}

export enum DocumentMemberStatus {
  SIGNED = 'SIGNED',
  REJECTED_SIGNATURE = 'REJECTED_SIGNATURE',
  AWAITING_SIGNATURE = 'AWAITING_SIGNATURE',
  PARTICIPANT = 'PARTICIPANT',
}

export interface DocumentMember {
  id: string;
  role: DocumentInvitationMemberRole;
  status: DocumentMemberStatus;
}

export interface InvitedDocumentMember {
  mail: string | null;
  role: DocumentInvitationMemberRole;
}

export interface DocumentSigner {
  isOrderSignificant: boolean;
  requiredSignersCount: number;
  ids: string[];
}

export interface UserManagerPerson {
  _id?: string; // entity id
  creatorId?: string; // entity id of user who created group
  email?: string; // entity mail
  contactEmail?: string;
  _name?: string; // entity name
  description?: string; // group description
  workspaceId?: string; // external workspace id
  workspaceName?: string; // external workspace name
  active?: boolean;
  role?: DocumentMemberRole;
  _status?: DocumentMemberStatus;
  isGroup: boolean; // entity is group
  isToken?: boolean; // invitation is generated token
  isInvited: boolean; // invitation sent, but not accepted yet
  isInvitationToBeSend: boolean; // new user - invitation not sent yet
  isExternal?: boolean; // is from external workspace
}

export enum DocumentUserAction {
  RejectDocument = 'reject_document',
  EditFile = 'edit_file',
  AddSigner = 'add_signer',
  AddSignerByInvitation = 'add_signer_by_invitation',
  AddUser = 'add_user',
  ModifyRole = 'modify_role',
  ModifySigners = 'modify_signers',
  Sign = 'sign',
  RejectSignature = 'reject_signature',
  LinkDocument = 'link_document',
  UnlinkDocument = 'unlink_document',
  View = 'view',
  RenameDocument = 'rename_document',
  UpdateTags = 'update_tags',
  SetTransferability = 'set_transferability',
  InvalidateTemporarilyDocument = 'invalidate_temporarily_document',
  ValidateDocument = 'validate_document',
  InvalidateDocument = 'invalidate_document',
  ChangeHolder = 'change_holder',
}
