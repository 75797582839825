import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Badge } from '@material-ui/core';
import { Avatar } from '../index';

import { useStyles } from './styles';

const UserAvatarWithBadge = ({
  children,
  className: classNameProp,
  tooltip,
  color,
  customColor,
  size,
  alt,
  src,
  badgeChildren,
  badgeSize,
  badgeAlt,
  badgeSrc,
  badgeTooltip,
  badgeColor,
  badgeCustomColor,
}) => {
  const classes = useStyles();
  return (
    <Badge
      overlap="circle"
      color="default"
      classes={{
        badge: classes.badge,
      }}
      badgeContent={
        (badgeChildren || badgeSrc) && (
          <Avatar
            size={badgeSize}
            alt={badgeAlt}
            src={badgeSrc}
            tooltip={badgeTooltip}
            color={badgeColor}
            customColor={badgeCustomColor}
          >
            {badgeChildren}
          </Avatar>
        )
      }
      className={clsx(classes.root, classNameProp)}
    >
      <Avatar
        alt={alt}
        src={src}
        size={size}
        tooltip={tooltip}
        color={color}
        customColor={customColor}
      >
        {children}
      </Avatar>
    </Badge>
  );
};

UserAvatarWithBadge.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(['large', 'medium', 'small', 'mini']),
  tooltip: PropTypes.string,
  color: PropTypes.oneOf(['default', 'error', 'warning', 'success', 'info']),
  customColor: PropTypes.string,
  children: PropTypes.node,
  alt: PropTypes.string,
  src: PropTypes.string,
  badgeSize: PropTypes.oneOf(['large', 'medium', 'small', 'mini']),
  badgeChildren: PropTypes.node,
  badgeAlt: PropTypes.string,
  badgeSrc: PropTypes.string,
  badgeTooltip: PropTypes.string,
  badgeColor: PropTypes.oneOf(['default', 'error', 'warning', 'success', 'info']),
  badgeCustomColor: PropTypes.string,
};

UserAvatarWithBadge.defaultProps = {
  size: 'large',
  badgeSize: 'mini',
};

export default UserAvatarWithBadge;
