import { fade } from '@material-ui/core/styles';
import palette from '../palette';
import spacing from '../spacing';
import typography from '../typography';

export default {
  root: {
    textTransform: typography.button.textTransform,
    fontWeight: typography.fontWeightMedium,
    lineHeight: '1.2',
    minHeight: 25,
    color: palette.text.secondary,
    fontSize: typography.body2.fontSize,
    backgroundColor: fade(palette.greySimple.light, 0.5),
  },
  sizeSmall: {
    fontSize: typography.subtitle2.fontSize,
  },
  label: {
    paddingLeft: spacing(1.75),
    paddingRight: spacing(1.75),
  },
  labelSmall: {
    paddingLeft: spacing(1.5),
    paddingRight: spacing(1.5),
  },
  deleteIconSmall: {
    width: 17,
    height: 17,
  },
};
