import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ breakpoints, mixins, palette, spacing }) =>
    createStyles({
      root: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        minHeight: mixins.toolbar.minHeight,
        [breakpoints.up('lg')]: {
          paddingTop: spacing(3),
          paddingBottom: spacing(3),
        },
      },
      title: {
        flex: 1,
      },
      iconWrapper: {
        '& > svg': {
          display: 'block',
          opacity: 0.66,
          color: palette.text.secondary,
        },
      },
      closeButton: {
        margin: `${spacing(-0.5)}px 0 ${spacing(-0.5)}px ${spacing(1)}px`,
        [breakpoints.up('md')]: {
          marginRight: spacing(-0.5),
        },
      },
    }),
  { index: 0 }
);
