import React from 'react';
import { Autocomplete, AutocompleteChangeReason } from '@material-ui/lab';
import { TextField, InputLabel, Checkbox, Chip, Typography } from '@material-ui/core';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { generateUUID } from 'src/helpers/globalUtils';
import { useStyles } from './styles';

export interface AutocompleteWithChipType {
  name: string;
  id: string;
}

type AutocompleteWithChipProps = {
  items: AutocompleteWithChipType[];
  placeholder?: string;
  label?: string;
  error?: boolean;
  helperText?: string;
  name: string;
  defaultValues?: AutocompleteWithChipType[];
  onChange: (items: AutocompleteWithChipType[]) => void;
};

const AutocompleteWithChip = ({
  defaultValues = [],
  name,
  items,
  placeholder,
  label,
  onChange,
  error,
  helperText,
}: AutocompleteWithChipProps): JSX.Element => {
  const classes = useStyles();
  const id = generateUUID();
  const [inputValue, setInputValue] = React.useState<string>('');
  const [selectedValues, setSelectedValues] = React.useState<AutocompleteWithChipType[]>(
    defaultValues
  );
  const [pendingValues, setPendingValues] = React.useState<AutocompleteWithChipType[]>(
    defaultValues
  );

  const onDelete = (index: number) => {
    const newValues = selectedValues.filter((item, idx) => idx !== index);
    setSelectedValues(newValues);
    onChange(newValues);
  };

  const onAutocompleteChange = (
    event: React.ChangeEvent<HTMLSelectElement | unknown>,
    newValues: AutocompleteWithChipType[],
    reason: AutocompleteChangeReason
  ) => {
    if (reason === 'clear') {
      setInputValue('');
    } else {
      onChange(newValues);
      setPendingValues(newValues);
    }
  };

  return (
    <>
      {label && (
        <InputLabel error={error} shrink htmlFor={id}>
          {label}
        </InputLabel>
      )}
      <Autocomplete
        id={id}
        value={pendingValues}
        onChange={onAutocompleteChange}
        onClose={() => {
          setSelectedValues(pendingValues);
          setInputValue('');
        }}
        multiple
        disableCloseOnSelect
        getOptionLabel={(item) => item.name}
        getOptionSelected={(option, value) => option.id === value.id}
        renderTags={() => null}
        inputValue={inputValue}
        renderInput={(params) => (
          <TextField
            {...params}
            ref={params.InputProps.ref}
            name={name}
            value={inputValue}
            onChange={(e) => {
              setInputValue(e.target.value);
            }}
            className={classes.input}
            placeholder={placeholder}
            error={error}
            helperText={helperText}
          />
        )}
        options={items}
        renderOption={(option, { selected }) => {
          const matches = match(option.name, inputValue, {
            insideWords: true,
            findAllOccurrences: true,
          });
          const parts = parse(option.name, matches);

          return (
            <div
              className={classes.optionWrapper}
              key={`autocomplete_with_chip_option_${option.id}`}
            >
              <Checkbox checked={selected} />
              <Typography variant="body2" component="span">
                {parts.map((part, index) => (
                  <span key={index} className={part.highlight ? classes.partHighlight : undefined}>
                    {part.text}
                  </span>
                ))}
              </Typography>
            </div>
          );
        }}
      />

      {selectedValues.map((item, index) => (
        <Chip
          onDelete={() => onDelete(index)}
          className={classes.chip}
          key={`${id}_chip_${index}`}
          label={item.name}
        />
      ))}
    </>
  );
};

export default AutocompleteWithChip;
