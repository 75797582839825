import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import TimeAgo from 'timeago-react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import { DateTimeFormats, FormatDate } from 'src/helpers/dateTimeFormats';
import * as timeago from 'timeago.js';
import pl from 'timeago.js/lib/lang/pl';
import { ExtendedGrid } from '../../index';
import { useStyles } from '../styles';

const TimeAgoCell = ({
  className: classNameProp,
  value,
  timeRangeAgo,
  live,
  smallScreen,
  ...otherProps
}) => {
  const classes = useStyles();
  const { i18n } = useTranslation();
  const { language } = i18n;
  timeago.register('pl', pl);
  const type = Date.now() - new Date(value) <= timeRangeAgo ? 'ago' : 'date';
  const date = new Date(value);
  return (
    <ExtendedGrid
      item
      className={clsx(classes.timeAgoCell, classNameProp, classes.cell)}
      {...otherProps}
    >
      <Typography variant={smallScreen ? 'subtitle2' : 'body2'} component="span">
        {type === 'ago' && <TimeAgo datetime={value} locale={language.toLowerCase()} live={live} />}
        {type === 'date' && (
          <FormatDate
            date={date}
            dateFormat={DateTimeFormats.dateLong}
            timeFormat={DateTimeFormats.timeLong}
          />
        )}
      </Typography>
    </ExtendedGrid>
  );
};

TimeAgoCell.propTypes = {
  className: PropTypes.string,
  value: PropTypes.number,
  timeRangeAgo: PropTypes.number,
  live: PropTypes.bool,
  smallScreen: PropTypes.bool,
};

TimeAgoCell.defaultProps = {
  live: true,
  className: '',
  timeRangeAgo: 86400000,
};

export default TimeAgoCell;
