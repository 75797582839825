import React, { useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, useMediaQuery } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DataTable, FullPageLoader, NoDocumentsBanner } from 'src/components';
import { SpeedDial } from 'src/pages/DrivePage/subcomponents';
import { RequestStatus } from 'src/helpers/reduxReuquest.util';
import { useStyles } from './styles';
import RecentPageWebsocket from './RecentPageWebsocket';
import documentsActions from '../../redux/actions/documents.actions';
import { FileColsConfig } from '../../helpers/fileAndFolderColsConfig';
import usePrevious from '../../helpers/hooks/usePrevious';
import ContactHelper from '../../helpers/contactHelper';
import { IdentitySelector } from '../../redux/selectors/identity.selector';

const RecentPage = () => {
  const filesTableTitleRef = useRef(null);
  const classes = useStyles();
  const smallScreen = useMediaQuery(({ breakpoints }) => breakpoints.down('sm'));
  const {
    fetchDocumentsStatus,
    documents,
    hasMoreDocuments,
    defaultSortKey,
    defaultSortOrder,
    currentFolderId,
  } = useSelector((state) => state.documentsStore, undefined);

  const workspaceId = useSelector(IdentitySelector.selectWorkspaceId, undefined);
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [sortKey, setSortKey] = useState(defaultSortKey);
  const [sortOrder, setSortOrder] = useState(defaultSortOrder);
  const [page, setPage] = useState(0);
  const filesColConfig = FileColsConfig(t, false, false, false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const hasAnyDocument = documents.length > 0;
  const filesPageLimit = 40;
  const iconWidth = 40;
  const prevState = usePrevious({
    sortKey,
    sortOrder,
    page,
    documents,
  });

  const isLoadingFiles = fetchDocumentsStatus === RequestStatus.PENDING;
  const allLoaded = !isLoadingFiles;

  const goToDocument = (row) => {
    if (row?.documentType.includes('dsl')) {
      history.push(`/certificate/${row.id}`);
    } else {
      history.push(`/document/${row.id}`);
    }
  };

  const onLoadMoreFiles = () => {
    const isLoadingMoreFiles = fetchDocumentsStatus === RequestStatus.PENDING;

    if (!isLoadingMoreFiles && hasMoreDocuments && !isLoadingMore) {
      setPage(page + 1);
      setIsLoadingMore(true);
    }
  };

  const loadFilesRows = useCallback(() => {
    if (workspaceId)
      dispatch(
        documentsActions.fetchAnyDocuments(
          workspaceId,
          filesPageLimit,
          page * filesPageLimit,
          'updatedAt',
          'DESC',
          page > 0,
          'updatedAt'
        )
      );
  }, [page, workspaceId, dispatch]);

  useEffect(() => {
    localStorage.setItem('lastMainPage', 'recent');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      documents.length > 0 &&
      prevState &&
      (prevState?.sortKey !== sortKey || prevState?.sortOrder !== sortOrder)
    ) {
      loadFilesRows();
    }
  }, [prevState, sortKey, sortOrder, documents, loadFilesRows]);

  useEffect(() => {
    if (prevState && prevState?.page !== page) {
      loadFilesRows();
    }
  }, [prevState, page, loadFilesRows]);

  useEffect(() => {
    loadFilesRows();
    dispatch(documentsActions.getFolderTree(workspaceId, 'root'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceId]);

  useEffect(() => {
    if (
      documents &&
      JSON.stringify(documents) !== JSON.stringify(prevState?.documents) &&
      documents.length > 0
    ) {
      const missingContacts = [];
      documents.forEach((document) => {
        const matchedIdentity = ContactHelper.getEntityData(document.author);
        if (!matchedIdentity && !ContactHelper.checkIfIsInProgress(document.author)) {
          missingContacts.push(document.author);
        }
      });
      if (missingContacts.length > 0) {
        ContactHelper.addContactsToQueue(missingContacts);
      }
      setIsLoadingMore(false);
    }
  }, [documents, prevState]);

  // Set storage current folder Id
  useEffect(() => {
    if (currentFolderId) {
      localStorage.setItem('currentFolderId', currentFolderId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceId, currentFolderId]);

  return (
    <>
      <RecentPageWebsocket sortKey={sortKey} sortOrder={sortOrder} />
      {allLoaded && smallScreen && <SpeedDial />}
      <Typography className={classes.title} color="textPrimary">
        <strong>{t('sideBar.recent')}</strong>
      </Typography>
      <div className={classes.root}>
        <Typography variant="h6" color="textPrimary">
          <strong>{t('drivePage.documentsTable.title')}</strong>
        </Typography>
        {hasAnyDocument && filesColConfig && (
          <DataTable
            classes={{
              headerWrapper: clsx(classes.header),
            }}
            rows={documents}
            cols={filesColConfig}
            withTableHeader
            iconWidth={iconWidth}
            headerTextWidthLikeIcon={false}
            titleRef={filesTableTitleRef}
            onClick={(row) => {
              if (smallScreen) {
                goToDocument(row);
              }
            }}
            onDblClick={(row) => {
              if (!smallScreen) {
                goToDocument(row);
              }
            }}
            onLongPress={() => undefined}
            sortBy={sortKey}
            sortOrder={sortOrder}
            onChangeSort={({ sortOrder, sortKey }) => {
              setSortKey(sortKey);
              setSortOrder(sortOrder);
              setPage(0);
            }}
            isLoading={isLoadingFiles}
            isLoadingMore={isLoadingFiles}
            hasMoreItems={hasMoreDocuments} // TODO: remove !
            paginationType="infinite"
            defaultIcon="file"
            smallScreen={smallScreen}
            disableHeader={smallScreen}
            onLoadMore={() => {
              onLoadMoreFiles();
            }}
            noItems={{
              label: t('drivePage.documentsTable.noItems'),
            }}
          />
        )}
      </div>

      {allLoaded && !hasAnyDocument && (
        <NoDocumentsBanner
          header={t('noDocuments.recent.header')}
          headerVariant={smallScreen ? 'h3' : 'h2'}
          description={t('noDocuments.recent.description')}
          showButton={!smallScreen}
          buttonText={t('noDocuments.button')}
          classes={{ root: classes.noDocumentsBanner }}
        />
      )}

      <FullPageLoader
        open={!allLoaded && !hasMoreDocuments}
        classes={{ root: classes.loader }}
        transitionDuration={{ enter: 100, exit: 300 }}
      />
    </>
  );
};

export default RecentPage;
