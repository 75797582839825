import { RequestStatus } from 'src/helpers/reduxReuquest.util';
import documentsConstants from 'src/redux/constants/documents.constants';
import moment from 'moment';
import {
  AdvancedDocumentsQueryType,
  AdvancedSearchFields,
  AdvancedSearchType,
  DocumentItem,
  DocumentsQueryType,
  FolderItem,
  FolderPathType,
  FolderTreeItem,
} from 'src/models/documents.model';
import { DateDropdownType } from 'src/components/DateDropdown/DateDropdown';

type SortDirection = 'asc' | 'desc' | 'none';

enum FolderOperationType {
  UPDATE = 'update',
  MOVE = 'move',
  DELETE = 'delete',
}

export const advancedSearchInitialState: AdvancedSearchType = {
  [AdvancedSearchFields.NAME]: '',
  [AdvancedSearchFields.TYPE]: [],
  [AdvancedSearchFields.STATUS]: [],
  [AdvancedSearchFields.DATE_CREATED]: {
    type: DateDropdownType.ANY,
    from: moment().subtract(1, 'days'),
    to: moment(),
  },
  [AdvancedSearchFields.DATE_MODIFIED]: {
    type: DateDropdownType.ANY,
    from: moment().subtract(1, 'days'),
    to: moment(),
  },
  [AdvancedSearchFields.RECIPIENTS]: [],
  [AdvancedSearchFields.LOCATION]: null,
};

export type DocumentsStoreState = {
  createFolderStatus: RequestStatus;
  currentFolderId: string | null;
  currentPath: string | null;
  defaultSortKey: string;
  defaultSortOrder: string;
  documents: DocumentItem[];
  documentsQuery: DocumentsQueryType;
  advancedSearchedDocumentsQuery: AdvancedDocumentsQueryType;
  documentsSearchFetchStatus: RequestStatus;
  fetchDocumentsStatus: RequestStatus;
  fetchFolderPathStatus: RequestStatus;
  fetchFoldersStatus: RequestStatus;
  folderPath: FolderPathType;
  folderTree: FolderTreeItem[] | null;
  foldersSearchFetchStatus: RequestStatus;
  hasMoreDocuments: boolean;
  moveDocumentsStatus: RequestStatus;
  removeFolderStatus: RequestStatus;
  renameFolderStatus: RequestStatus;
  quickSearchedDocuments: DocumentItem[];
  quickSearchedFolders: FolderItem[];
  advancedSearchedDocuments: DocumentItem[];
  advancedSearchedDocumentsStatus: RequestStatus;
  advancedSearchedFolders: FolderItem[];
  advancedSearch: AdvancedSearchType;
  draftAdvancedSearch: AdvancedSearchType;
  selectedFolders: string[];
};

const initialState = {
  createFolderStatus: RequestStatus.IDLE,
  currentFolderId: null,
  currentPath: null,
  defaultSortKey: 'updatedAt',
  defaultSortOrder: 'desc',
  documents: [],
  documentsQuery: {
    limit: 20,
    offset: 0,
  },
  documentsSearchFetchStatus: RequestStatus.IDLE,
  fetchDocumentsStatus: RequestStatus.IDLE,
  fetchFolderPathStatus: RequestStatus.IDLE,
  fetchFoldersStatus: RequestStatus.IDLE,
  folderPath: { idPath: '', namePath: '' },
  folderTree: null,
  foldersSearchFetchStatus: RequestStatus.IDLE,
  hasMoreDocuments: false,
  moveDocumentsStatus: RequestStatus.IDLE,
  removeFolderStatus: RequestStatus.IDLE,
  renameFolderStatus: RequestStatus.IDLE,
  quickSearchedDocuments: [],
  quickSearchedFolders: [],
  advancedSearchedDocuments: [],
  advancedSearchedDocumentsStatus: RequestStatus.IDLE,
  advancedSearchedDocumentsQuery: {
    limit: 20,
    offset: 0,
    hasMoreDocuments: true,
  },
  advancedSearchedFolders: [],
  advancedSearch: advancedSearchInitialState,
  draftAdvancedSearch: advancedSearchInitialState,
  selectedFolders: [],
};

const sortByKeyAndOrder = <T>(
  data: T[],
  keyToSort: keyof T,
  direction: 'asc' | 'desc' | 'none'
) => {
  if (direction === 'none') {
    return data;
  }
  const compare = (objectA: T, objectB: T) => {
    const valueA = objectA[keyToSort];
    const valueB = objectB[keyToSort];

    if (valueA === valueB) {
      return 0;
    }

    if (valueA > valueB) {
      return direction === 'asc' ? 1 : -1;
    }
    return direction === 'asc' ? -1 : 1;
  };

  return data.slice().sort(compare);
};

const updateOnlyOneDocumentInState = (
  documents: DocumentItem[],
  updatedDocument: DocumentItem,
  sortKey: keyof DocumentItem,
  sortOrder: SortDirection
) => {
  const documentExists = documents.some((doc: DocumentItem) => doc.id === updatedDocument.id);
  const newDocsArr = [...documents];
  if (documentExists) {
    newDocsArr.map((document) => {
      if (document.id === updatedDocument.id) return updatedDocument;
      return document;
    });
  } else {
    newDocsArr.push(updatedDocument);
  }
  return sortByKeyAndOrder(newDocsArr, sortKey, sortOrder);
};

const deleteOnlyOneDocumentFromState = (documents: DocumentItem[], deletedDocument: DocumentItem) =>
  documents.filter((document) => document.id !== deletedDocument.id);

const getCurrentFolderId = (rootFolderId: string, folders: FolderItem[]) => {
  let currentFolderId = null;
  if (rootFolderId) {
    currentFolderId = rootFolderId;
  } else {
    folders.forEach((folder: FolderItem) => {
      if (folder.name === '/') {
        currentFolderId = folder.id;
      }
    });
  }
  return currentFolderId;
};

const updateFolderTree = (
  currentFolderTree: FolderTreeItem[] | null,
  updatedFolder: FolderItem,
  operation: FolderOperationType
) => {
  const newFolders = Array.from(currentFolderTree || []);
  let folderIndex = -1;

  newFolders.forEach((folder: FolderTreeItem, index) => {
    if (folder.id === updatedFolder.id) {
      folderIndex = index;
    }
  });
  if (folderIndex >= 0) {
    if (operation === FolderOperationType.UPDATE) {
      newFolders[folderIndex] = updatedFolder;
    } else if (operation === FolderOperationType.DELETE) {
      newFolders.splice(folderIndex, 1);
    }

    return newFolders;
  }
  return currentFolderTree;
};

const updateDocument = (
  currentDocuments: DocumentItem[],
  currentFolderId: string,
  updatedDocument: DocumentItem,
  operation: FolderOperationType,
  sortKey: keyof DocumentItem,
  sortOrder: SortDirection
) => {
  const newDocuments = Array.from(currentDocuments);
  if (currentFolderId === updatedDocument.folderId) {
    if (operation === FolderOperationType.MOVE) {
      newDocuments.push(updatedDocument);
    }
  } else if (operation === FolderOperationType.MOVE) {
    let documentIndex = -1;
    newDocuments.forEach((document: DocumentItem, index) => {
      if (document.id === updatedDocument.id) {
        documentIndex = index;
      }
    });
    if (documentIndex >= 0) {
      newDocuments.splice(documentIndex, 1);
    }
  }

  return sortByKeyAndOrder(newDocuments, sortKey, sortOrder);
};

const addDocument = (
  currentDocuments: DocumentItem[],
  newDocument: DocumentItem,
  sortKey: keyof DocumentItem,
  sortOrder: SortDirection
) => {
  const newDocuments = Array.from(currentDocuments);
  const changedGroupIndex = currentDocuments.findIndex(
    (type: DocumentItem) => type.id === newDocument.id
  );
  if (changedGroupIndex === -1) {
    newDocuments.unshift(newDocument);
  }
  return sortByKeyAndOrder(newDocuments, sortKey, sortOrder);
};

const updateFolderPath = (currentPath: FolderPathType, updatedFolder: FolderItem) => {
  const pathIds = currentPath.idPath.split('/');
  const pathNames = currentPath.namePath.split('/');
  let targetIndex = -1;
  for (let i = 0; i < pathIds.length; i += 1) {
    if (pathIds[i] === updatedFolder.id) {
      targetIndex = i;
      break;
    }
  }
  if (targetIndex >= 0) {
    pathNames[targetIndex] = updatedFolder.name;
    return {
      idPath: pathIds.join('/'),
      namePath: pathNames.join('/'),
    };
  }
  return currentPath;
};

function documentsStore(
  state: DocumentsStoreState = initialState,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any
  action: any // FIXME: fix this in next stage
): DocumentsStoreState {
  switch (action.type) {
    case documentsConstants.FETCH_FOLDER_TREE_REQUEST:
      return {
        ...state,
        fetchFoldersStatus: RequestStatus.PENDING,
        folderTree: initialState.folderTree,
      };
    case documentsConstants.FETCH_FOLDER_TREE_SUCCESS:
      return {
        ...state,
        fetchFoldersStatus: RequestStatus.IDLE,
        folderTree: action.folders,
        currentFolderId: getCurrentFolderId(action.rootFolderId, action.folders),
      };
    case documentsConstants.FETCH_FOLDER_TREE_FAILURE:
      return {
        ...state,
        fetchFoldersStatus: RequestStatus.ERROR,
      };
    case documentsConstants.FETCH_DOCUMENTS_SEARCH_REQUEST:
      return {
        ...state,
        documentsSearchFetchStatus: RequestStatus.PENDING,
      };
    case documentsConstants.FETCH_DOCUMENTS_SEARCH_SUCCESS:
      return {
        ...state,
        documentsSearchFetchStatus: RequestStatus.SUCCESS,
        quickSearchedDocuments: [...action.documents],
      };
    case documentsConstants.FETCH_DOCUMENTS_SEARCH_FAILURE:
      return {
        ...state,
        documentsSearchFetchStatus: RequestStatus.ERROR,
      };
    case documentsConstants.FETCH_FOLDERS_SEARCH_REQUEST:
      return {
        ...state,
        foldersSearchFetchStatus: RequestStatus.PENDING,
      };
    case documentsConstants.FETCH_FOLDERS_SEARCH_SUCCESS:
      return {
        ...state,
        foldersSearchFetchStatus: RequestStatus.SUCCESS,
        quickSearchedFolders: [...action.folders],
      };
    case documentsConstants.FETCH_FOLDERS_SEARCH_FAILURE:
      return {
        ...state,
        foldersSearchFetchStatus: RequestStatus.ERROR,
      };
    case documentsConstants.CREATE_FOLDER_REQUEST:
      return {
        ...state,
        createFolderStatus: RequestStatus.PENDING,
      };
    case documentsConstants.CREATE_FOLDER_SUCCESS:
      return {
        ...state,
        createFolderStatus: RequestStatus.SUCCESS,
      };
    case documentsConstants.CREATE_FOLDER_FAILURE:
      return {
        ...state,
        createFolderStatus: RequestStatus.ERROR,
      };
    case documentsConstants.FETCH_DOCUMENTS_REQUEST:
      return {
        ...state,
        fetchDocumentsStatus: RequestStatus.PENDING,
      };
    case documentsConstants.FETCH_DOCUMENTS_SUCCESS: {
      const { documentsQuery, hasMoreDocuments, documents } = action;

      return {
        ...state,
        fetchDocumentsStatus: RequestStatus.IDLE,
        documents: documentsQuery.offset === 0 ? documents : [...state.documents, ...documents],
        hasMoreDocuments,
        documentsQuery: {
          limit: documentsQuery.limit,
          offset: documentsQuery.offset,
        },
      };
    }
    case documentsConstants.FETCH_DOCUMENTS_FAILURE:
      return {
        ...state,
        fetchDocumentsStatus: RequestStatus.ERROR,
      };
    case documentsConstants.FETCH_FOLDER_PATH_REQUEST:
      return {
        ...state,
        fetchFolderPathStatus: RequestStatus.PENDING,
      };
    case documentsConstants.FETCH_FOLDER_PATH_SUCCESS:
      return {
        ...state,
        folderPath: action.path,
        fetchFolderPathStatus: RequestStatus.SUCCESS,
      };
    case documentsConstants.FETCH_FOLDER_PATH_FAILURE:
      return {
        ...state,
        fetchFolderPathStatus: RequestStatus.ERROR,
      };
    case documentsConstants.RENAME_FOLDER_REQUEST:
      return {
        ...state,
        renameFolderStatus: RequestStatus.PENDING,
      };
    case documentsConstants.RENAME_FOLDER_SUCCESS:
      return {
        ...state,
        renameFolderStatus: RequestStatus.SUCCESS,
      };
    case documentsConstants.RENAME_FOLDER_FAILURE:
      return {
        ...state,
        renameFolderStatus: RequestStatus.ERROR,
      };
    case documentsConstants.REMOVE_FOLDER_REQUEST:
      return {
        ...state,
        removeFolderStatus: RequestStatus.PENDING,
      };
    case documentsConstants.REMOVE_FOLDER_SUCCESS:
      return {
        ...state,
        removeFolderStatus: RequestStatus.SUCCESS,
      };
    case documentsConstants.REMOVE_FOLDER_FAILURE:
      return {
        ...state,
        removeFolderStatus: RequestStatus.ERROR,
      };
    case documentsConstants.WS_ADD_FOLDER: {
      const prev = state.folderTree ? state.folderTree : [];
      return {
        ...state,
        folderTree: [...prev, action.newFolder],
      };
    }
    case documentsConstants.WS_UPDATE_FOLDER:
      return {
        ...state,
        folderTree: updateFolderTree(
          state.folderTree,
          action.updatedFolder,
          FolderOperationType.UPDATE
        ),
        folderPath: updateFolderPath(state.folderPath, action.updatedFolder),
      };
    case documentsConstants.WS_DELETE_FOLDER:
      return {
        ...state,
        folderTree: updateFolderTree(
          state.folderTree,
          action.deletedFolder,
          FolderOperationType.DELETE
        ),
      };
    case documentsConstants.WS_MOVED_DOCUMENT:
      return {
        ...state,
        documents: updateDocument(
          state.documents,
          action.currentFolderId,
          action.movedFile,
          FolderOperationType.MOVE,
          action.sortKey,
          action.sortOrder
        ),
      };
    case documentsConstants.WS_CREATED_DOCUMENT:
      return {
        ...state,
        documents: addDocument(
          state.documents,
          action.documentData,
          action.sortKey,
          action.sortOrder
        ),
      };
    case documentsConstants.WS_UPDATED_DOCUMENT:
      return {
        ...state,
        documents: updateOnlyOneDocumentInState(
          state.documents,
          action.documentData,
          action.sortKey,
          action.sortOrder
        ),
      };
    case documentsConstants.WS_DELETE_DOCUMENT:
      return {
        ...state,
        documents: deleteOnlyOneDocumentFromState(state.documents, action.documentData),
      };
    case documentsConstants.MOVE_DOCUMENT_REQUEST:
      return {
        ...state,
        moveDocumentsStatus: RequestStatus.PENDING,
      };
    case documentsConstants.MOVE_DOCUMENT_SUCCESS:
      return {
        ...state,
        moveDocumentsStatus: RequestStatus.SUCCESS,
      };
    case documentsConstants.MOVE_DOCUMENT_FAILURE:
      return {
        ...state,
        moveDocumentsStatus: RequestStatus.ERROR,
      };
    case documentsConstants.SUBMIT_ADVANCED_SEARCH:
      return {
        ...state,
        advancedSearch: action.payload,
      };
    case documentsConstants.RESET_DRAFT_ADVANCED_SEARCH:
      return {
        ...state,
        draftAdvancedSearch: initialState.draftAdvancedSearch,
      };
    case documentsConstants.UPDATE_DRAFT_ADVANCED_SEARCH:
      return {
        ...state,
        draftAdvancedSearch: {
          ...state.draftAdvancedSearch,
          [action.payload.field]: action.payload.value,
        },
      };
    case documentsConstants.UPDATE_ADVANCED_SEARCH:
      return {
        ...state,
        advancedSearch: {
          ...state.advancedSearch,
          [action.payload.field]: action.payload.value,
        },
      };
    case documentsConstants.RESET_FIELD_DRAFT_ADVANCED_SEARCH: {
      const actionField: AdvancedSearchFields = action.payload.field;

      return {
        ...state,
        draftAdvancedSearch: {
          ...state.draftAdvancedSearch,
          [action.payload.field]: state.advancedSearch[actionField],
        },
      };
    }
    case documentsConstants.FETCH_DOCUMENTS_ADVANCED_SEARCH_REQUEST: {
      return {
        ...state,
        advancedSearchedDocumentsStatus: RequestStatus.PENDING,
      };
    }
    case documentsConstants.FETCH_DOCUMENTS_ADVANCED_SEARCH_SUCCESS: {
      const { documentsQuery, documents } = action;

      return {
        ...state,
        advancedSearchedDocumentsStatus: RequestStatus.SUCCESS,
        advancedSearchedDocuments:
          documentsQuery.offset === 0
            ? documents
            : [...state.advancedSearchedDocuments, ...documents],
        advancedSearchedDocumentsQuery: {
          hasMoreDocuments: documentsQuery.hasMoreDocuments,
          limit: documentsQuery.limit,
          offset: documentsQuery.offset,
        },
      };
    }
    case documentsConstants.FETCH_DOCUMENTS_ADVANCED_SEARCH_FAILURE: {
      return {
        ...state,
        advancedSearchedDocumentsStatus: RequestStatus.ERROR,
        advancedSearchedDocuments: initialState.advancedSearchedDocuments,
      };
    }
    case documentsConstants.FETCH_FOLDERS_ADVANCED_SEARCH_SUCCESS: {
      return {
        ...state,
        advancedSearchedFolders: action.payload,
      };
    }
    case documentsConstants.SET_SELECTED_FOLDERS: {
      return {
        ...state,
        selectedFolders: action.payload,
      };
    }
    case documentsConstants.CLEAR_STORE:
      return {
        ...initialState,
        quickSearchedDocuments: state.quickSearchedDocuments,
        quickSearchedFolders: state.quickSearchedFolders,
        advancedSearch: state.advancedSearch,
        draftAdvancedSearch: state.draftAdvancedSearch,
      };
    case documentsConstants.CLEAR_SEARCH:
      return {
        ...state,
        quickSearchedDocuments: [],
        documentsSearchFetchStatus: RequestStatus.IDLE,
        quickSearchedFolders: [],
        foldersSearchFetchStatus: RequestStatus.IDLE,
      };
    case documentsConstants.CLEAR_ADVANCED_SEARCH: {
      const excludeName = action.payload;
      return {
        ...state,
        advancedSearchedDocumentsStatus: initialState.advancedSearchedDocumentsStatus,
        advancedSearchedDocuments: initialState.advancedSearchedDocuments,
        advancedSearchedDocumentsQuery: initialState.advancedSearchedDocumentsQuery,
        advancedSearchedFolders: initialState.advancedSearchedFolders,
        advancedSearch: {
          ...initialState.advancedSearch,
          [AdvancedSearchFields.NAME]: excludeName
            ? state.advancedSearch[AdvancedSearchFields.NAME]
            : initialState.advancedSearch[AdvancedSearchFields.NAME],
        },
        draftAdvancedSearch: {
          ...initialState.draftAdvancedSearch,
          [AdvancedSearchFields.NAME]: excludeName
            ? state.draftAdvancedSearch[AdvancedSearchFields.NAME]
            : initialState.draftAdvancedSearch[AdvancedSearchFields.NAME],
        },
      };
    }

    case documentsConstants.CLEAR_FOLDER_PATH:
      return {
        ...state,
        folderPath: initialState.folderPath,
      };
    default:
      return state;
  }
}

export default documentsStore;
