import i18n from 'src/helpers/i18n';
import { SNACKBAR_VARIANT } from 'src/models/common.model';
import communicationsConstants from '../constants/communications.constants';
import communicationsService from '../services/communications.service';
import snackbarActions from './snackbar.actions';

const communicationsActions = {
  getUserNotificationChannelsSettings,
  updateNotificationChannel,
  getUnreadSystemMessages,
  getUnreadSystemMessagesCounter,
  updateUnreadSystemMessagesCounter,
  getReadSystemMessages,
  setNotificationSystemMessageAsRead,
  setAllNotificationSystemMessagesAsRead,
  clearCommunicationsStore,
};

function getUserNotificationChannelsSettings() {
  return (dispatch) => {
    dispatch({ type: communicationsConstants.GET_USER_NOTIFICATIONS_CHANNELS_REQUEST });
    communicationsService
      .getUserNotificationChannelsSettings()
      .then((response) => {
        dispatch({
          type: communicationsConstants.GET_USER_NOTIFICATIONS_CHANNELS_SUCCESS,
          channels: response?.data?.collection,
        });
      })
      .catch(() => {
        dispatch({ type: communicationsConstants.GET_USER_NOTIFICATIONS_CHANNELS_FAILURE });
        dispatch(
          snackbarActions.enqueueSnackbar(
            SNACKBAR_VARIANT.ERROR,
            i18n.t('snackbars.common.errorTitle'),
            i18n.t('userConfigurationPage.notifications.getUserChannels.toast.error')
          )
        );
      });
  };
}

function updateNotificationChannel(data, method, newChannel) {
  return (dispatch) => {
    dispatch({ type: communicationsConstants.UPDATE_NOTIFICATION_CHANNEL_REQUEST, data, method });
    communicationsService
      .updateNotificationChannel(data)
      .then(() => {
        dispatch({
          type: communicationsConstants.UPDATE_NOTIFICATION_CHANNEL_SUCCESS,
          newChannel,
        });

        dispatch(
          snackbarActions.enqueueSnackbar(
            SNACKBAR_VARIANT.SUCCESS,
            i18n.t('snackbars.common.successTitle'),
            i18n.t('userConfigurationPage.notifications.changeSettings.toast.success', {
              name: i18n.t(
                `userConfigurationPage.notifications.channels.${data.notificationTemplate}`
              ),
              method: i18n.t(`userConfigurationPage.notifications.services.${method}`),
            })
          )
        );
      })
      .catch(() => {
        dispatch({
          type: communicationsConstants.UPDATE_NOTIFICATION_CHANNEL_FAILURE,
          data,
          method,
        });
        dispatch(
          snackbarActions.enqueueSnackbar(
            SNACKBAR_VARIANT.ERROR,
            i18n.t('snackbars.common.errorTitle'),
            i18n.t('userConfigurationPage.notifications.changeSettings.toast.error')
          )
        );
      });
  };
}

function getUnreadSystemMessages({ offset, limit, replace = false }) {
  return (dispatch) => {
    dispatch({
      type: communicationsConstants.GET_UNREAD_USER_NOTIFICATION_SYSTEM_MESSAGES_REQUEST,
    });
    communicationsService
      .getUserNotificationSystemMessages({ offset, limit, read: false })
      .then((response) => {
        dispatch({
          type: communicationsConstants.GET_UNREAD_USER_NOTIFICATION_SYSTEM_MESSAGES_SUCCESS,
          data: response?.data?.systemMessageResponse,
          hasNextPage: response?.data?.hasMoreData,
          replace,
        });
      })
      .catch(() => {
        dispatch({
          type: communicationsConstants.GET_UNREAD_USER_NOTIFICATION_SYSTEM_MESSAGES_FAILURE,
        });
      });
  };
}

function getReadSystemMessages({ offset, limit }) {
  return (dispatch) => {
    dispatch({
      type: communicationsConstants.GET_READ_USER_NOTIFICATION_SYSTEM_MESSAGES_REQUEST,
    });
    communicationsService
      .getUserNotificationSystemMessages({ offset, limit, read: true })
      .then((response) => {
        dispatch({
          type: communicationsConstants.GET_READ_USER_NOTIFICATION_SYSTEM_MESSAGES_SUCCESS,
          data: response?.data?.systemMessageResponse,
          hasNextPage: response?.data?.hasMoreData,
        });
      })
      .catch(() => {
        dispatch({
          type: communicationsConstants.GET_READ_USER_NOTIFICATION_SYSTEM_MESSAGES_FAILURE,
        });
      });
  };
}

function updateUnreadSystemMessagesCounter(counter) {
  return (dispatch) => {
    dispatch({
      type: communicationsConstants.UPDATE_UNREAD_USER_NOTIFICATION_SYSTEM_MESSAGES_COUNTER,
      payload: counter,
    });
  };
}

function getUnreadSystemMessagesCounter() {
  return (dispatch) => {
    dispatch({
      type: communicationsConstants.GET_UNREAD_USER_NOTIFICATION_SYSTEM_MESSAGES_COUNTER_REQUEST,
    });
    communicationsService
      .getUserNotificationSystemMessagesCounter()
      .then((response) => {
        dispatch({
          type:
            communicationsConstants.GET_UNREAD_USER_NOTIFICATION_SYSTEM_MESSAGES_COUNTER_SUCCESS,
          data: response?.data,
        });
      })
      .catch(() => {
        dispatch({
          type:
            communicationsConstants.GET_UNREAD_USER_NOTIFICATION_SYSTEM_MESSAGES_COUNTER_FAILURE,
        });
      });
  };
}

function setNotificationSystemMessageAsRead(notificationId, callbackOnSuccess) {
  return (dispatch) => {
    dispatch({
      type: communicationsConstants.SET_USER_NOTIFICATION_AS_READ_REQUEST,
      notificationId,
    });
    communicationsService
      .setNotificationSystemMessageAsRead(notificationId)
      .then(() => {
        dispatch({
          type: communicationsConstants.SET_USER_NOTIFICATION_AS_READ_SUCCESS,
          notificationId,
        });
        if (typeof callbackOnSuccess === 'function') {
          dispatch(callbackOnSuccess);
        }
      })
      .catch(() => {
        dispatch({
          type: communicationsConstants.SET_USER_NOTIFICATION_AS_READ_FAILURE,
        });
        dispatch(
          snackbarActions.enqueueSnackbar(
            SNACKBAR_VARIANT.ERROR,
            i18n.t('snackbars.common.errorTitle'),
            i18n.t('notifications.setAsRead.toast.error')
          )
        );
      });
  };
}

function setAllNotificationSystemMessagesAsRead() {
  return (dispatch) => {
    dispatch({
      type: communicationsConstants.SET_USER_ALL_NOTIFICATIONS_AS_READ_REQUEST,
    });
    communicationsService
      .setNotificationSystemMessageAsRead([], true)
      .then(() => {
        dispatch({
          type: communicationsConstants.SET_USER_ALL_NOTIFICATIONS_AS_READ_SUCCESS,
        });
      })
      .catch(() => {
        dispatch({
          type: communicationsConstants.SET_USER_ALL_NOTIFICATIONS_AS_READ_FAILURE,
        });
        dispatch(
          snackbarActions.enqueueSnackbar(
            SNACKBAR_VARIANT.ERROR,
            i18n.t('snackbars.common.errorTitle'),
            i18n.t('notifications.setAsRead.toast.errorAll')
          )
        );
      });
  };
}

function clearCommunicationsStore() {
  return { type: communicationsConstants.CLEAR_COMMUNICATIONS_STORE };
}

export default communicationsActions;
