import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import { ExtendedGrid, Tag } from 'src/components';

import { useStyles } from '../styles';
import { getStatusName } from '../../../models/document.model';
import { DocumentStatus } from '../../../models/documents.model';

const DocumentStatusCell = React.forwardRef(
  ({ className, status, isOnlyToPreview, smallScreen, ...otherProps }, ref) => {
    const classes = useStyles();
    const { t } = useTranslation();
    // TODO: remove after decision
    const temporaryTagAlwaysVisible = true;

    const getStatusComponent = (text, color = 'default', icon = null) =>
      temporaryTagAlwaysVisible ? (
        <Tag color={color} classes={{ root: classes.tag }}>
          {text}
        </Tag>
      ) : (
        <Typography component="span" variant="body2">
          {text}
          {icon}
        </Typography>
      );

    const getContent = () => {
      const statusName = getStatusName({
        status,
        isShort: true,
        t,
      });

      if (isOnlyToPreview) {
        return getStatusComponent(
          getStatusName({
            status: DocumentStatus.READY_FOR_PREVIEW,
            isShort: true,
            t,
          }),
          'success'
        );
      }

      switch (status) {
        case DocumentStatus.ISSUING:
        case DocumentStatus.SIGNING:
          return getStatusComponent(statusName, 'info');
        case DocumentStatus.EDITING:
          return getStatusComponent(statusName, 'default');
        case DocumentStatus.VALID:
        case DocumentStatus.READY_FOR_PREVIEW:
        case DocumentStatus.FINISHED:
          return getStatusComponent(statusName, 'success');
        case DocumentStatus.INVALIDATED:
        case DocumentStatus.EXPIRED:
        case DocumentStatus.REJECTED:
          return getStatusComponent(statusName, 'error');
        case DocumentStatus.TEMPORARILY_INVALIDATE:
          return getStatusComponent(statusName, 'warning');
        default:
          return getStatusComponent(status[0].toUpperCase() + status.toLowerCase().slice(1));
      }
    };

    const Component = smallScreen ? 'div' : ExtendedGrid;
    const componentProps = smallScreen ? {} : { item: true, ...otherProps };
    const classNameProperties = clsx(smallScreen && classes.mobileCell, [
      classes.cell,
      classes.statusCell,
      status,
      className,
    ]);

    return (
      <Component ref={ref} className={classNameProperties} {...componentProps}>
        {getContent()}
      </Component>
    );
  }
);

DocumentStatusCell.propTypes = {
  className: PropTypes.string,
  status: PropTypes.string,
  isOnlyToPreview: PropTypes.bool,
  smallScreen: PropTypes.bool,
};

DocumentStatusCell.defaultProps = {
  className: '',
  isOnlyToPreview: false,
};

export default DocumentStatusCell;
