import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ palette }) =>
    createStyles({
      root: {},
      searchField: {
        backgroundColor: palette.background.paper,
      },
      clearIcon: {
        color: palette.text.disabled,
        cursor: 'pointer',
      },
      searchAdornmentIcon: {
        color: palette.text.disabled,
      },
    }),
  { index: 0 }
);
