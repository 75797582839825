import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import WebSocketHelper from 'src/helpers/WebSocketHelper';
import certificateCreatorActions from 'src/redux/actions/certificateCreator.actions';
import { CertificateSteps } from 'src/models/certificate.model';
import { DocumentType, userComActions } from '../../redux/actions/userCom.actions';

const CertificateCreatorWebsocket = () => {
  const wsCreate = useRef(null);
  const dispatch = useDispatch();
  const { currentIdentity } = useSelector((state) => state.identityStore, undefined);

  const { generatedCertFileList, createdCertificates } = useSelector(
    (state) => state.certificateCreatorStore,
    undefined
  );

  const workspaceId = currentIdentity?.workspace?.workspaceId;

  const onWebSocketMessage = (message) => {
    if (message.action === 'CREATE') {
      dispatch(certificateCreatorActions.addCreatedCertificates(message.documentId));
    }
  };

  useEffect(() => {
    if (workspaceId) {
      wsCreate.current = WebSocketHelper.subscribe(
        'document-dsl-service',
        `workspaces/${workspaceId}/document-updates`,
        (message) => {
          onWebSocketMessage(message);
        }
      );
    }

    return () => {
      WebSocketHelper.unsubscribe(wsCreate.current);
    };
  }, [workspaceId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      createdCertificates.length > 0 &&
      createdCertificates.length === Object.keys(generatedCertFileList).length
    ) {
      userComActions.document.docAdded(
        DocumentType.Certificate,
        createdCertificates.length,
        workspaceId
      );
      dispatch(
        certificateCreatorActions.setCreatorStep({
          currentStep: CertificateSteps.InvitePeople,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createdCertificates, generatedCertFileList, dispatch]);

  return null;
};

export default CertificateCreatorWebsocket;
