import { RequestStatus } from 'src/helpers/reduxReuquest.util';
import documentDownloadConstants from '../constants/documentDownload.constants';

const initialState = {
  documentDownloadStatus: RequestStatus.IDLE,
  downloadDocuments: [],
  filesToDownloadStatus: RequestStatus.IDLE,
};

const updateDownloadDocumentFromState = (documents, documentId) => {
  const documentsArray = [...documents];
  const index = documentsArray.indexOf(documentId);

  if (index !== -1) {
    return documentsArray;
  }

  return [...documentsArray, documentId];
};

const deleteDownloadDocumentFromState = (documents, documentId) => {
  const documentsArray = [...documents];
  const index = documentsArray.indexOf(documentId);

  if (index !== -1) {
    documentsArray.splice(index, 1);
  }
  return documentsArray;
};

function documentDownloadStore(state = initialState, action) {
  switch (action.type) {
    case documentDownloadConstants.DOWNLOAD_DOCUMENT_REQUEST:
      return {
        ...state,
        downloadDocuments: updateDownloadDocumentFromState(
          state.downloadDocuments,
          action.documentId
        ),
        documentDownloadStatus: RequestStatus.PENDING,
      };
    case documentDownloadConstants.DOWNLOAD_DOCUMENT_SUCCESS:
      return {
        ...state,
        documentDownloadStatus: RequestStatus.SUCCESS,
      };
    case documentDownloadConstants.DOWNLOAD_DOCUMENT_FAILURE:
      return {
        ...state,
        downloadDocuments: deleteDownloadDocumentFromState(
          state.downloadDocuments,
          action.documentId
        ),
        documentDownloadStatus: RequestStatus.ERROR,
      };

    case documentDownloadConstants.DOWNLOAD_DOCUMENT_FILE_REQUEST:
      return {
        ...state,
        filesToDownloadStatus: RequestStatus.PENDING,
      };
    case documentDownloadConstants.DOWNLOAD_DOCUMENT_FILE_SUCCESS:
      return {
        ...state,
        filesToDownloadStatus: RequestStatus.SUCCESS,
      };
    case documentDownloadConstants.DOWNLOAD_DOCUMENT_FILE_FAILURE:
      return {
        ...state,
        filesToDownloadStatus: RequestStatus.ERROR,
      };

    case documentDownloadConstants.WS_DOWNLOAD_DOCUMENT:
      return {
        ...state,
        downloadDocuments: deleteDownloadDocumentFromState(
          state.downloadDocuments,
          action.documentId
        ),
        documentDownloadStatus: RequestStatus.IDLE,
      };

    default:
      return state;
  }
}

export default documentDownloadStore;
