import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { CircularProgress, Paper } from '@material-ui/core';
import QRious from 'qrious';
import { useStyles } from './styles';

const QrImage = ({
  className: classNameProp,
  code,
  dimension,
  loaderSize,
  responsive,
  elevation,
  noImage,
  noImageUrl,
}) => {
  const classes = useStyles();

  const getQrContent = (qrCode, qrDimensions) => {
    if (!qrCode) {
      return '';
    }

    return new QRious({
      size: qrDimensions,
      level: 'H',
      value: qrCode,
    }).toDataURL('image/jpeg');
  };

  const getLoader = () => (
    <div className={classes.loader}>
      <div
        className={classes.loaderInner}
        style={{ marginTop: loaderSize * -0.5, marginLeft: loaderSize * -0.5 }}
      >
        <CircularProgress size={loaderSize} />
      </div>
    </div>
  );

  function getContent() {
    if (noImage || code) {
      let imgSrc;

      if (!noImage) {
        imgSrc = getQrContent(code, dimension);
      } else {
        imgSrc = noImageUrl;
      }

      return (
        <div
          className={clsx(classes.inside, responsive && classes.responsiveElement)}
          style={{ width: dimension }}
        >
          <img src={imgSrc} alt="" />
        </div>
      );
    }

    return (
      <div
        className={clsx(classes.inside, responsive && classes.responsiveElement)}
        style={{ width: dimension }}
      >
        {getLoader()}
      </div>
    );
  }

  return (
    <Paper
      square
      elevation={elevation}
      className={clsx(classes.root, responsive && classes.responsiveElement, classNameProp)}
      style={{ width: dimension }}
    >
      {getContent()}
    </Paper>
  );
};

QrImage.propTypes = {
  className: PropTypes.string,
  code: PropTypes.string.isRequired,
  dimension: PropTypes.number,
  loaderSize: PropTypes.number,
  responsive: PropTypes.bool,
  elevation: PropTypes.oneOf([
    0,
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
  ]),
  noImage: PropTypes.bool,
  noImageUrl: PropTypes.string,
};

QrImage.defaultProps = {
  dimension: 128,
  loaderSize: 32,
  responsive: false,
  elevation: 0,
  noImage: false,
  noImageUrl: 'images/frames/dis-qr.svg',
};

export default QrImage;
