import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Typography } from '@material-ui/core';
import { ExtendedGrid } from '../../index';
import { useStyles } from '../styles';

const TextCell = ({ className: classNameProp, value, ...otherProps }) => {
  const classes = useStyles();
  return (
    <ExtendedGrid item className={clsx(classes.cell, classNameProp)} {...otherProps}>
      <Typography variant="body1" component="span">
        {value}
      </Typography>
    </ExtendedGrid>
  );
};

TextCell.propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

TextCell.defaultProps = {
  className: '',
};

export default TextCell;
