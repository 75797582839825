import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Button, Typography, createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ typography, palette, spacing }) =>
    createStyles({
      timeCounter: {
        fontWeight: typography.fontWeightBold,
        marginLeft: spacing(0.5),
        color: palette.primary.main,
      },
      buttonSend: {
        padding: spacing(0.5),
        marginLeft: 0,
        boxShadow: 'none !important',
        fontFamily: 'inherit',
      },
    }),
  { index: 0 }
);

const TimerButton = ({
  cooldown,
  buttonLabel,
  buttonDisabled,
  onClick,
  awaitMessage,
  initTimestamp,
}) => {
  const classes = useStyles();
  const [baseTimestamp, setBaseTimestamp] = useState(initTimestamp || Date.now());
  const [timePassed, setTimePassed] = useState(Date.now() - baseTimestamp);

  useEffect(() => {
    if (initTimestamp) {
      setBaseTimestamp(initTimestamp);
    }
  }, [initTimestamp]);

  const intervalId = useRef(null);
  useEffect(() => {
    intervalId.current = setInterval(() => {
      setTimePassed(Date.now() - baseTimestamp);
    }, 500);

    return function cleanup() {
      clearInterval(intervalId.current);
    };
  });

  const getHumanTime = () => {
    const timeLeft = cooldown - timePassed;
    const minutes = Math.floor(timeLeft / 1000 / 60);
    const seconds = Math.floor(timeLeft / 1000 - minutes * 60);
    const pad = (n) => (n < 10 ? `0${n}` : n);
    return `${pad(minutes)}:${pad(seconds)}`;
  };

  const handleClick = () => {
    if (!initTimestamp) {
      setBaseTimestamp(Date.now());
    }
    onClick();
  };

  const isButtonActive = timePassed > cooldown;

  const getContent = () =>
    isButtonActive ? (
      <Button
        variant="text"
        size="small"
        type="button"
        onClick={handleClick}
        disabled={buttonDisabled}
        className={clsx(classes.timeCounter, classes.buttonSend)}
      >
        {buttonLabel}
      </Button>
    ) : (
      <Typography
        variant="body2"
        component="span"
        className={classes.timeCounter}
      >{` ${awaitMessage} ${getHumanTime()}`}</Typography>
    );

  return getContent();
};

TimerButton.propTypes = {
  cooldown: PropTypes.number,
  buttonLabel: PropTypes.string,
  buttonDisabled: PropTypes.bool,
  onClick: PropTypes.func,
  awaitMessage: PropTypes.string,
  initTimestamp: PropTypes.number,
};

export default TimerButton;
