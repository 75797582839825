import React, { createRef, forwardRef, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { TextField } from '@material-ui/core';
import { useStyles } from './styles';

const CodeInput = forwardRef(({ codeLength, onValueChange, onAcceptValue }, ref) => {
  const classes = useStyles();
  const defaultValue = Array(codeLength).join('.').split('.');
  const [codeValue, setCodeValue] = useState(defaultValue);
  const [isPasting, setIsPasting] = useState(false);

  const inputs = [];
  const inputRefs = [];

  const isNumeric = (str) =>
    (typeof str === 'number' || typeof str === 'string') && !Number.isNaN(str - parseFloat(str));

  const clearCode = () => {
    const newValue = defaultValue;
    setCodeValue(newValue);

    if (typeof onValueChange === 'function') {
      onValueChange(newValue.join(''));
    }
  };

  useImperativeHandle(ref, () => ({
    clearCode,
  }));

  const onInputChange = (value, index) => {
    if (!isPasting) {
      if (value.length < 2) {
        const newValue = Array.from(codeValue);
        newValue[index] = value;
        setCodeValue(newValue);

        if (typeof onValueChange === 'function') {
          onValueChange(newValue.join(''));
        }
      }
      if (value !== '') {
        if (index < codeLength - 1) {
          const inputRefNext = inputRefs[index + 1].current;
          if (inputRefNext) {
            inputRefNext.focus();
          }
        }
      } else if (index > 0) {
        const inputRefPrev = inputRefs[index - 1].current;
        if (inputRefPrev) {
          inputRefPrev.focus();
        }
      }
    } else {
      const inputRefCurrent = inputRefs[index].current;
      if (inputRefCurrent) {
        inputRefCurrent.value = '';

        const inputRefToFocus =
          inputRefs[value.length >= codeLength ? codeLength - 1 : value.length].current;

        if (inputRefToFocus) {
          inputRefToFocus.focus();
        }

        setIsPasting(false);
      }

      if (typeof onValueChange === 'function') {
        onValueChange(codeValue.join(''));
      }
    }
  };

  const onInputFocus = (event) => {
    if (event.target.value.length > 0) {
      event.target.select();
    }
  };

  const onKeyUp = (event, index) => {
    const { keyCode } = event;

    if (keyCode === 13 && onAcceptValue) {
      onAcceptValue(codeValue.join(''));
    }

    const inputRefCurrent = inputRefs[index].current;

    if (inputRefCurrent) {
      if (
        index > 0 &&
        ((keyCode === 8 && (inputRefCurrent.value === '' || inputRefCurrent.value === undefined)) ||
          keyCode === 37)
      ) {
        const inputRefPrev = inputRefs[index - 1].current;
        if (inputRefPrev) {
          inputRefPrev.focus();
        }
      }
    }
    if (index < codeLength - 1 && keyCode === 39) {
      const inputRefNext = inputRefs[index + 1].current;
      if (inputRefNext) {
        inputRefNext.focus();
      }
    }
  };

  const onKeyDown = (event) => {
    const { keyCode } = event;

    const canTypeSign =
      keyCode === 8 || keyCode === 9 || keyCode === 17 || keyCode === 46 || keyCode === 86
        ? true
        : !Number.isNaN(Number(event.key));

    if (!canTypeSign) {
      event.preventDefault();
    }
  };

  const onPaste = (event) => {
    setIsPasting(true);
    const clipboardData = event.clipboardData.getData('text/plain').replace(/\s/g, '');
    const valueToSetFromClipboard = [];

    if (isNumeric(clipboardData)) {
      const splitClipboardData = clipboardData.split(/(?!$)/u);
      for (let i = 0; i < codeLength; i += 1) {
        valueToSetFromClipboard[i] = splitClipboardData[i] || '';
      }

      return setCodeValue(valueToSetFromClipboard);
    }
    return false;
  };

  const renderSingleInput = (index, ref) => {
    const isAutofocus = index === 0;
    return (
      <div className={clsx(classes.inputContainer)} key={`input-code-${index}`}>
        <TextField
          inputRef={ref}
          onInput={(e) => {
            onInputChange(e.target.value, index);
          }}
          onFocus={(e) => {
            onInputFocus(e, index);
          }}
          onKeyUp={(e) => {
            onKeyUp(e, index);
          }}
          onKeyDown={(event) => {
            onKeyDown(event);
          }}
          onPaste={(e) => {
            onPaste(e);
          }}
          autoFocus={isAutofocus}
          value={codeValue[index]}
          fullWidth
          type="number"
          inputProps={{
            maxLength: 1,
          }}
          /* eslint-disable-next-line react/jsx-no-duplicate-props */
          InputProps={{
            classes: {
              root: clsx(
                classes.inputRoot,
                // hasErrors && classes.inputError,
                codeValue[index] !== '' && classes.inputFilled
              ),
              input: classes.input,
              focused: classes.inputFocus,
              notchedOutline: classes.notchedOutline,
            },
            endAdornment: <div className={classes.underline} />,
          }}
        />
      </div>
    );
  };

  for (let i = 0; i < codeLength; i += 1) {
    const newRef = createRef();
    inputs.push(renderSingleInput(i, newRef));
    inputRefs.push(newRef);
  }
  return (
    <div className={classes.root} ref={ref}>
      {inputs}
    </div>
  );
});

CodeInput.propTypes = {
  codeLength: PropTypes.number,
  onValueChange: PropTypes.func,
  onAcceptValue: PropTypes.func,
};

export default CodeInput;
