import React from 'react';
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon';

export default createSvgIcon(
  <>
    <path
      fill="#616161"
      d="M12.0005 24.001C18.6282 24.001 24.001 18.6282 24.001 12.0005C24.001 5.3728 18.6282 0 12.0005 0C5.3728 0 0 5.3728 0 12.0005C0 18.6282 5.3728 24.001 12.0005 24.001Z"
    />
    <path
      fill="#9E9E9E"
      d="M12.0005 19.6369C7.78243 19.6369 4.36408 16.2185 4.36408 12.0005C4.36408 7.78242 7.78243 4.36408 12.0005 4.36408C14.1085 4.36408 16.0174 5.2189 17.4002 6.60075L20.4859 3.51505C18.314 1.34316 15.3134 0 12.0005 0C5.37266 0 0 5.37266 0 12.0005C0 18.6283 5.37266 24.001 12.0005 24.001C15.3144 24.001 18.314 22.6578 20.4869 20.4869L17.4012 17.4012C16.0174 18.7821 14.1085 19.6369 12.0005 19.6369Z"
    />
    <path
      fill="#424242"
      d="M19.6367 12.0003H15.818C15.818 9.89227 14.1084 8.18164 11.9994 8.18164C9.89033 8.18164 8.18164 9.8913 8.18164 12.0003C8.18164 13.0273 8.58778 13.9585 9.24824 14.646L9.24534 14.6489L13.973 19.3766L13.974 19.3776C17.2347 18.5073 19.6367 15.5347 19.6367 12.0003Z"
    />
    <path
      fill="#616161"
      d="M24.0005 12H19.6364C19.6364 15.5344 17.2334 18.5069 13.9746 19.3772L17.3446 22.7472C21.29 20.7813 24.0005 16.7074 24.0005 12Z"
    />
    <path
      fillOpacity="0.1"
      fill="#212121"
      d="M12.0005 23.8635C5.39587 23.8635 0.03868 18.5276 0.000967005 11.9316C0.000967005 11.9548 0 11.9771 0 12.0003C0 18.6281 5.37266 24.0008 12.0005 24.0008C18.6283 24.0008 24.001 18.6281 24.001 12.0003C24.001 11.9771 24 11.9548 24 11.9316C23.9623 18.5276 18.6041 23.8635 12.0005 23.8635Z"
    />
    <path
      fillOpacity="0.05"
      fill="white"
      d="M13.9746 19.3772L14.081 19.4836C17.2856 18.5785 19.6364 15.633 19.6364 12.1363V12C19.6364 15.5344 17.2334 18.5069 13.9746 19.3772Z"
    />
    <path
      fill="#9E9E9E"
      d="M21.8199 10.9102H12.0009C11.3985 10.9102 10.9102 11.3985 10.9102 12.0009C10.9102 12.6034 11.3985 13.0917 12.0009 13.0917H21.8189C22.4213 13.0917 22.9097 12.6034 22.9097 12.0009C22.9106 11.3985 22.4223 10.9102 21.8199 10.9102Z"
    />
    <path
      fill="#BDBDBD"
      d="M21.8199 10.9102H12.0009C11.3985 10.9102 10.9102 11.3985 10.9102 12.0009C10.9102 12.6034 11.3985 13.0917 12.0009 13.0917H21.8189C22.4213 13.0917 22.9097 12.6034 22.9097 12.0009C22.9106 11.3985 22.4223 10.9102 21.8199 10.9102Z"
      opacity="0.5"
    />
    <path
      fill="#BDBDBD"
      d="M2.18136 12.8178C2.63318 12.8178 2.99945 12.4515 2.99945 11.9997C2.99945 11.5479 2.63318 11.1816 2.18136 11.1816C1.72955 11.1816 1.36328 11.5479 1.36328 11.9997C1.36328 12.4515 1.72955 12.8178 2.18136 12.8178Z"
    />
    <path
      fill="#BDBDBD"
      d="M11.9997 2.99945C12.4515 2.99945 12.8178 2.63318 12.8178 2.18136C12.8178 1.72955 12.4515 1.36328 11.9997 1.36328C11.5479 1.36328 11.1816 1.72955 11.1816 2.18136C11.1816 2.63318 11.5479 2.99945 11.9997 2.99945Z"
    />
    <path
      fill="#BDBDBD"
      d="M11.9997 22.6362C12.4515 22.6362 12.8178 22.2699 12.8178 21.8181C12.8178 21.3663 12.4515 21 11.9997 21C11.5479 21 11.1816 21.3663 11.1816 21.8181C11.1816 22.2699 11.5479 22.6362 11.9997 22.6362Z"
    />
    <path
      fill="#BDBDBD"
      d="M5.0466 5.87445C5.49841 5.87445 5.86468 5.50818 5.86468 5.05636C5.86468 4.60455 5.49841 4.23828 5.0466 4.23828C4.59478 4.23828 4.22852 4.60455 4.22852 5.05636C4.22852 5.50818 4.59478 5.87445 5.0466 5.87445Z"
    />
    <path
      fill="#BDBDBD"
      d="M5.0466 19.7729C5.49841 19.7729 5.86468 19.4066 5.86468 18.9548C5.86468 18.503 5.49841 18.1367 5.0466 18.1367C4.59478 18.1367 4.22852 18.503 4.22852 18.9548C4.22852 19.4066 4.59478 19.7729 5.0466 19.7729Z"
    />
    <path
      fill="#757575"
      d="M18.9548 19.7729C19.4066 19.7729 19.7729 19.4066 19.7729 18.9548C19.7729 18.503 19.4066 18.1367 18.9548 18.1367C18.503 18.1367 18.1367 18.503 18.1367 18.9548C18.1367 19.4066 18.503 19.7729 18.9548 19.7729Z"
    />
    <path
      fillOpacity="0.2"
      fill="white"
      d="M12.0009 11.0465H21.8189C22.3981 11.0465 22.872 11.4991 22.9058 12.0696C22.9068 12.0464 22.9097 12.0241 22.9097 12.0009C22.9097 11.3985 22.4213 10.9102 21.8189 10.9102H12.0009C11.3985 10.9102 10.9102 11.3985 10.9102 12.0009C10.9102 12.0241 10.9111 12.0464 10.914 12.0696C10.9479 11.4991 11.4207 11.0465 12.0009 11.0465Z"
    />
    <path
      fillOpacity="0.2"
      fill="#212121"
      d="M22.9058 12.0684C22.87 12.6379 22.3972 13.0914 21.8189 13.0914H12.0009C11.4217 13.0914 10.9479 12.6389 10.914 12.0684C10.9111 12.0916 10.9102 12.1138 10.9102 12.136C10.9102 12.7385 11.3985 13.2268 12.0009 13.2268H21.8189C22.4213 13.2268 22.9097 12.7385 22.9097 12.136C22.9097 12.1138 22.9087 12.0916 22.9058 12.0684Z"
    />
    <path
      fillOpacity="0.1"
      fill="#212121"
      d="M12.0016 4.50001C14.1097 4.50001 16.0186 5.35484 17.4014 6.73669L20.5538 3.58233C20.5306 3.55912 20.5083 3.53591 20.4861 3.51367L17.4014 6.59937C16.0186 5.21753 14.1097 4.3627 12.0016 4.3627C7.78358 4.3627 4.36523 7.78105 4.36523 11.9991C4.36523 12.0223 4.3662 12.0446 4.3662 12.0678C4.40295 7.88162 7.80679 4.50001 12.0016 4.50001Z"
    />
  </>,
  'GoogleAuthenticatorColor'
);
