import { createStyles, makeStyles } from '@material-ui/core';
import transitions from '@material-ui/core/styles/transitions';

export const useStyles = makeStyles(
  ({ palette }) =>
    createStyles({
      fieldWrapper: {
        display: 'flex',
      },
      dateInput: {
        '& input': {
          cursor: 'pointer',
          color: 'transparent !important',
          textShadow: `0 0 0 ${palette.text.primary}`,
        },
      },
      field: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        height: 48,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: palette.action.selected,
        borderRadius: 6,
        padding: '0 12px',
        transition: transitions.create(['background-color', 'color', 'box-shadow', 'border'], {
          duration: transitions.duration.short,
        }),
        cursor: 'pointer',

        '&:hover': {
          borderColor: palette.primary.main,
        },
      },
    }),
  { index: 0 }
);
