import React from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import AdvancedSearchModalTitle from './subcomponents/AdvancedSearchModalTitle';
import AdvancedSearchModalActions from './subcomponents/AdvancedSearchModalActions';
import NameField from './fields/NameField/NameField';
import DocumentTypeField from './fields/DocumentTypeField/DocumentTypeField';
import DocumentStatusField from './fields/DocumentStatusField/DocumentStatusField';
import DateField from './fields/DateField/DateField';
import { AdvancedSearchFields } from '../../models/documents.model';
import RecipientsField from './fields/RecipientsField/RecipientsField';
import LocationField from './fields/LocationField/LocationField';

type AdvancedSearchModalProps = {
  onClose: () => void;
};

const AdvancedSearchModal = ({ onClose }: AdvancedSearchModalProps): JSX.Element => (
  <Dialog open maxWidth="sm" onClose={onClose}>
    <AdvancedSearchModalTitle onClose={onClose} />
    <DialogContent>
      <NameField />
      <DocumentTypeField />
      <DocumentStatusField />
      <DateField type={AdvancedSearchFields.DATE_CREATED} />
      <DateField type={AdvancedSearchFields.DATE_MODIFIED} />
      <RecipientsField />
      <LocationField />
    </DialogContent>
    <AdvancedSearchModalActions onClose={onClose} />
  </Dialog>
);

export default AdvancedSearchModal;
