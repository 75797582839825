import i18n from 'src/helpers/i18n';
import { isMail } from './globalUtils';
import { RegexFormulas } from './regex';

export default function validate(values) {
  const errors = {};

  // email change form
  if (Object.keys(values).includes('email')) {
    if (!values.email) {
      errors.email = i18n.t('userConfigurationPage.basic.emailPage.errorEmpty');
    } else if (!isMail(values.email)) {
      errors.email = i18n.t('userConfigurationPage.basic.emailPage.errorFormat');
    }
  }

  // username change form
  if (Object.keys(values).includes('username')) {
    if (!values.username) {
      errors.username = i18n.t('userConfigurationPage.basic.usernamePage.errorEmpty');
    } else if (values.username.length < 3) {
      errors.username = i18n.t('userConfigurationPage.basic.usernamePage.errorFormat');
    }
  }

  // create workspace change form
  if (Object.keys(values).includes('workspaceName')) {
    if (!values.workspaceName) {
      errors.workspaceName = i18n.t('createWorkspace.errorEmpty');
    } else if (values.workspaceName.length < 3) {
      errors.workspaceName = i18n.t('createWorkspace.errorFormat');
    }
  }

  // password change form
  if (Object.keys(values).includes('oldPassword')) {
    if (!values.oldPassword) {
      errors.oldPassword = i18n.t('userConfigurationPage.basic.passwordPage.errorEmpty');
    }
    if (!values.newPassword) {
      errors.newPassword = i18n.t('userConfigurationPage.basic.passwordPage.errorEmpty');
    } else if (values.newPassword.length < 8) {
      errors.newPassword = i18n.t('userConfigurationPage.basic.passwordPage.errorTooShort');
    }
    if (!values.confirmPassword) {
      errors.confirmPassword = i18n.t('userConfigurationPage.basic.passwordPage.errorEmpty');
    }
    if (values.newPassword !== values.confirmPassword) {
      errors.newPassword = i18n.t('userConfigurationPage.basic.passwordPage.errorMismatch');
      errors.confirmPassword = i18n.t('userConfigurationPage.basic.passwordPage.errorMismatch');
    }
  }

  if (Object.keys(values).includes('folderName')) {
    if (!values.folderName) {
      errors.folderName = i18n.t('drivePage.foldersDialog.errorEmpty');
    } else if (!RegexFormulas.folderName.test(values.folderName)) {
      errors.folderName = i18n.t('drivePage.foldersDialog.addNew.invalidName');
    }
  }

  if (Object.keys(values).includes('workspaceNewName')) {
    if (!values.workspaceNewName || values.workspaceNewName.trim().length === 0) {
      errors.workspaceNewName = i18n.t('workspacePage.editModals.valueCannotBeEmpty');
    }
  }

  return errors;
}
