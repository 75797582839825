import i18n from 'src/helpers/i18n';
import { SNACKBAR_VARIANT } from 'src/models/common.model';
import identityService from '../services/identity.service';
import workspaceGroupsConstants from '../constants/workspaceGroups.constants';
import snackbarActions from './snackbar.actions';
import identityActions from './identity.actions';
import identityConstants from '../constants/identity.constants';

const fetchWorkspaceGroups = (workspaceId, force = false, page = 0, limit = 50, groupName) => (
  dispatch
) => {
  dispatch({ type: workspaceGroupsConstants.FETCH_WORKSPACE_GROUPS_REQUEST });
  identityService
    .fetchWorkspaceGroups(workspaceId, force, page, limit, groupName)
    .then((response) => {
      dispatch({
        type: workspaceGroupsConstants.FETCH_WORKSPACE_GROUPS_SUCCESS,
        workspaceGroups: response.data.collection,
        hasNextPage: response.data.hasNextPage,
        force,
      });
    })
    .catch(() => {
      dispatch({ type: workspaceGroupsConstants.FETCH_WORKSPACE_GROUPS_FAILURE });
      dispatch(
        snackbarActions.enqueueSnackbar(
          SNACKBAR_VARIANT.ERROR,
          i18n.t('snackbars.common.errorTitle'),
          i18n.t('workspacePage.groups.fetchMainStructureError.description')
        )
      );
    });
};

const searchWorkspaceGroups = (workspaceId, page = 0, limit = 50, groupName) => (dispatch) => {
  dispatch({ type: workspaceGroupsConstants.FETCH_WORKSPACE_GROUPS_SEARCH_REQUEST });
  identityService
    .searchWorkspaceGroups(workspaceId, page, limit, groupName)
    .then((response) => {
      dispatch({
        type: workspaceGroupsConstants.FETCH_WORKSPACE_GROUPS_SEARCH_SUCCESS,
        workspaceUsers: response.data.collection,
        hasNextPage: response.data.hasNextPage,
      });
    })
    .catch(() => {
      dispatch({ type: workspaceGroupsConstants.FETCH_WORKSPACE_GROUPS_SEARCH_FAILURE });
      dispatch(
        snackbarActions.enqueueSnackbar(
          SNACKBAR_VARIANT.ERROR,
          i18n.t('snackbars.common.errorTitle'),
          i18n.t('workspacePage.groups.fetchMainStructureError.description')
        )
      );
    });
};

const createWorkspaceGroup = (workspaceId, workspaceGroupData, callbackOnSuccess) => (dispatch) => {
  dispatch({ type: workspaceGroupsConstants.CREATE_NEW_WORKSPACE_GROUP_REQUEST });
  identityService
    .createWorkspaceGroup(workspaceId, workspaceGroupData)
    .then((response) => {
      dispatch({
        type: workspaceGroupsConstants.CREATE_NEW_WORKSPACE_GROUP_SUCCESS,
        data: response.data,
      });
      dispatch(identityActions.getWorkspaceStructure(workspaceId));
      dispatch(
        snackbarActions.enqueueSnackbar(
          SNACKBAR_VARIANT.SUCCESS,
          i18n.t('snackbars.common.successTitle'),
          i18n.t('workspacePage.groups.add.toast.success')
        )
      );
      if (typeof callbackOnSuccess === 'function') {
        dispatch(callbackOnSuccess);
      }
    })
    .catch(() => {
      dispatch({ type: workspaceGroupsConstants.CREATE_NEW_WORKSPACE_GROUP_FAILURE });
      dispatch(
        snackbarActions.enqueueSnackbar(
          SNACKBAR_VARIANT.ERROR,
          i18n.t('snackbars.common.errorTitle'),
          i18n.t('workspacePage.groups.add.toast.error')
        )
      );
    });
};

const deleteWorkspaceGroup = (workspaceId, groupId, callbackOnSuccess) => (dispatch) => {
  dispatch({ type: workspaceGroupsConstants.DELETE_WORKSPACE_GROUP_REQUEST });
  identityService
    .deleteWorkspaceGroup(groupId)
    .then((response) => {
      dispatch({
        type: workspaceGroupsConstants.DELETE_WORKSPACE_GROUP_SUCCESS,
        data: response,
        groupId,
      });
      dispatch({
        type: identityConstants.DELETE_WORKSPACE_GROUP_AFTER_REMOVING,
        groupId,
      });
      dispatch(
        snackbarActions.enqueueSnackbar(
          SNACKBAR_VARIANT.SUCCESS,
          i18n.t('snackbars.common.successTitle'),
          i18n.t('workspacePage.groups.deleteGroup.toast.success')
        )
      );
      if (typeof callbackOnSuccess === 'function') {
        dispatch(callbackOnSuccess);
      }
    })
    .catch(() => {
      dispatch({ type: workspaceGroupsConstants.DELETE_WORKSPACE_GROUP_FAILURE });
      dispatch(
        snackbarActions.enqueueSnackbar(
          SNACKBAR_VARIANT.ERROR,
          i18n.t('snackbars.common.errorTitle'),
          i18n.t('workspacePage.groups.deleteGroup.toast.error')
        )
      );
    });
};

const updateWorkspaceGroup = (workspaceId, groupId, workspaceGroupData, callbackOnSuccess) => (
  dispatch
) => {
  dispatch({ type: workspaceGroupsConstants.UPDATE_WORKSPACE_GROUP_REQUEST });
  identityService
    .updateWorkspaceGroup(groupId, workspaceGroupData)
    .then((response) => {
      dispatch({
        type: workspaceGroupsConstants.UPDATE_WORKSPACE_GROUP_SUCCESS,
        data: response,
      });
      dispatch(workspaceGroupsActions.fetchWorkspaceGroup(groupId));
      dispatch(
        snackbarActions.enqueueSnackbar(
          SNACKBAR_VARIANT.SUCCESS,
          i18n.t('snackbars.common.successTitle'),
          i18n.t('workspacePage.groups.rename.toast.success')
        )
      );
      if (typeof callbackOnSuccess === 'function') {
        dispatch(callbackOnSuccess);
      }
    })
    .catch(() => {
      dispatch({ type: workspaceGroupsConstants.UPDATE_WORKSPACE_GROUP_FAILURE });
      dispatch(
        snackbarActions.enqueueSnackbar(
          SNACKBAR_VARIANT.ERROR,
          i18n.t('snackbars.common.errorTitle'),
          i18n.t('workspacePage.groups.rename.toast.error')
        )
      );
    });
};

const addMembersToWorkspaceGroup = (groupId, identityIds) => (dispatch) => {
  dispatch({ type: workspaceGroupsConstants.ADD_MEMBER_TO_WORKSPACE_GROUP_REQUEST });
  identityService
    .addMembersToWorkspaceGroup(groupId, identityIds)
    .then((response) => {
      dispatch({
        type: workspaceGroupsConstants.ADD_MEMBER_TO_WORKSPACE_GROUP_SUCCESS,
        data: response,
        groupId,
      });
      dispatch(workspaceGroupsActions.fetchWorkspaceGroupMembers(groupId));
      dispatch(workspaceGroupsActions.fetchWorkspaceGroup(groupId));
    })
    .catch(() => {
      dispatch({ type: workspaceGroupsConstants.ADD_MEMBER_TO_WORKSPACE_GROUP_FAILURE });
      dispatch(
        snackbarActions.enqueueSnackbar(
          SNACKBAR_VARIANT.ERROR,
          i18n.t('snackbars.common.errorTitle'),
          i18n.t('workspacePage.groups.addMember.toast.error')
        )
      );
    });
};

const deleteMembersFromWorkspaceGroup = (groupId, identityIds) => (dispatch) => {
  dispatch({ type: workspaceGroupsConstants.DELETE_MEMBER_FROM_WORKSPACE_GROUP_REQUEST });
  identityService
    .deleteMembersFromWorkspaceGroup(groupId, identityIds)
    .then((response) => {
      dispatch({
        type: workspaceGroupsConstants.DELETE_MEMBER_FROM_WORKSPACE_GROUP_SUCCESS,
        data: response,
        groupId,
      });
      dispatch(workspaceGroupsActions.fetchWorkspaceGroupMembers(groupId));
      dispatch(workspaceGroupsActions.fetchWorkspaceGroup(groupId));
      dispatch(identityActions.getUserStructure());
    })
    .catch(() => {
      dispatch({ type: workspaceGroupsConstants.DELETE_MEMBER_FROM_WORKSPACE_GROUP_FAILURE });
      dispatch(
        snackbarActions.enqueueSnackbar(
          SNACKBAR_VARIANT.ERROR,
          i18n.t('snackbars.common.errorTitle'),
          i18n.t('workspacePage.groups.deleteMember.toast.error')
        )
      );
    });
};

const fetchWorkspaceGroup = (groupId, callbackOnSuccess) => (dispatch) => {
  dispatch({ type: workspaceGroupsConstants.FETCH_WORKSPACE_GROUP_REQUEST });
  identityService
    .fetchWorkspaceGroup(groupId)
    .then((response) => {
      dispatch({
        type: workspaceGroupsConstants.FETCH_WORKSPACE_GROUP_SUCCESS,
        data: response.data || [],
      });
      dispatch({
        type: identityConstants.UPDATE_WORKSPACE_GROUP_AFTER_FETCHING,
        data: response.data || [],
      });
      if (typeof callbackOnSuccess === 'function') {
        dispatch(callbackOnSuccess);
      }
    })
    .catch(() => {
      dispatch({ type: workspaceGroupsConstants.FETCH_WORKSPACE_GROUP_FAILURE });
      dispatch(
        snackbarActions.enqueueSnackbar(
          SNACKBAR_VARIANT.ERROR,
          i18n.t('snackbars.common.errorTitle'),
          i18n.t('workspacePage.groups.fetchGroupStructure.error')
        )
      );
    });
};

const fetchWorkspaceGroupMembers = (groupId, page = 0, limit = 100, memberIdentifier = '') => (
  dispatch
) => {
  dispatch({ type: workspaceGroupsConstants.FETCH_WORKSPACE_GROUP_MEMBERS_REQUEST });
  identityService
    .fetchWorkspaceGroupMembers(groupId, page, limit, memberIdentifier)
    .then((response) => {
      dispatch({
        type: workspaceGroupsConstants.FETCH_WORKSPACE_GROUP_MEMBERS_SUCCESS,
        members: response?.data?.collection,
      });
    })
    .catch(() => {
      dispatch({ type: workspaceGroupsConstants.FETCH_WORKSPACE_GROUP_MEMBERS_FAILURE });
      dispatch(
        snackbarActions.enqueueSnackbar(
          SNACKBAR_VARIANT.ERROR,
          i18n.t('snackbars.common.errorTitle'),
          i18n.t('workspacePage.groups.fetchGroupMembersStructure.error')
        )
      );
    });
};

const setActiveGroup = (activeGroup) => (dispatch) => {
  dispatch({
    type: workspaceGroupsConstants.SET_ACTIVE_GROUP,
    payload: activeGroup,
  });
};

const clearWorkspaceGroupsStore = () => ({
  type: workspaceGroupsConstants.CLEAR_WORKSPACE_GROUPS_STATE,
});

const workspaceGroupsActions = {
  fetchWorkspaceGroups,
  searchWorkspaceGroups,
  createWorkspaceGroup,
  deleteWorkspaceGroup,
  updateWorkspaceGroup,
  addMembersToWorkspaceGroup,
  deleteMembersFromWorkspaceGroup,
  fetchWorkspaceGroup,
  fetchWorkspaceGroupMembers,
  clearWorkspaceGroupsStore,
  setActiveGroup,
};

export default workspaceGroupsActions;
