import i18n from 'i18next';
import { RegexFormulas } from './regex';

const parseImportedData = (rawImportedCSV) => {
  const importedHeaders = rawImportedCSV ? [...rawImportedCSV[0]] : [];
  const importedBody = rawImportedCSV ? [...rawImportedCSV] : [];
  importedBody.shift();

  const mappedHeadersWithFields = importedBody.map((row) => {
    const resultRow = {};
    let lastNotEmptyHeader = '';

    importedHeaders.forEach((header, index) => {
      const nextHeader = importedHeaders[index + 1];
      if (header !== '') {
        lastNotEmptyHeader = header;
        // if value for header is base64, check length cause '2022' is valid for regex
        // or if value for header is 'attached_files'
        // or if next header has no name
        if (
          (row[index] &&
            RegexFormulas.base64.test(row[index].slice(0, 500)) &&
            row[index].length > 100 &&
            !nextHeader) ||
          header === 'attached_files' ||
          nextHeader === ''
        ) {
          resultRow[header] = [{ base64: row[index], name: i18n.t('dropzones.importedFile') }];
        } else {
          resultRow[header] = row[index];
        }
      } else if (resultRow[lastNotEmptyHeader] && row[index]) {
        resultRow[lastNotEmptyHeader].push({
          base64: row[index],
          name: i18n.t('dropzones.importedFile'),
        });
      }
    });
    return resultRow;
  });

  const headersWithNoArrayValues = importedHeaders.filter(
    (header) =>
      !mappedHeadersWithFields
        .map((item) => Object.keys(item).find((key) => Array.isArray(item[key])))
        .includes(header)
  );

  return {
    mappedHeadersWithFields,
    importedHeaders,
    importedBody,
    headersWithNoArrayValues,
  };
};

const createCertificateAttributeForTransactionPost = ({
  documentName = null,
  documentId,
  files,
  identityId,
  ownersGroupId,
}) => ({
  documentName,
  documentId,
  txnAttributes: {
    signatureLevel: 'MAIL_SMS',
    files,
    roles: {
      viewers: [],
      signers: [identityId],
      admins: [identityId, ownersGroupId].filter((el) => el),
      editors: [],
    },
    ordered: false,
  },
});

const CertificateHelper = {
  parseImportedData,
  createCertificateAttributeForTransactionPost,
};

export default CertificateHelper;
