import { Button, CircularProgress, Typography, useMediaQuery } from '@material-ui/core';
import { ListChoiceButton, SmallContentWithScrollbar } from 'src/components';
import { Download, Email } from 'src/components/CustomIcon';
import React, { useState, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import documentInvitiationActions from 'src/redux/actions/documentInvitiation.actions';
import { StepperContext, TRANSFER_OWNERSHIP_STEPS } from '../../context/SteperContext';
import { useStyles } from './styles';
import TransferOwnershipWebsocket from './TransferOwnershipWebsocket';

const TRANSFER_OPTIONS = {
  BY_EMAIL: 'BY_EMAIL',
  GENERATE_PDF: 'GENERATE_PDF',
};

const TransferOwnershipStep = () => {
  const { setCurrentStep } = useContext(StepperContext);
  const history = useHistory();
  const dispatch = useDispatch();
  const [option, setOption] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { id: documentId } = useParams();
  const { t } = useTranslation();

  const classes = useStyles();

  const handleChooseByEmail = () => {
    setOption(TRANSFER_OPTIONS.BY_EMAIL);
  };

  const handleChooseByPDF = () => {
    setOption(TRANSFER_OPTIONS.GENERATE_PDF);
  };

  const smallScreen = useMediaQuery(({ breakpoints }) => breakpoints.down('sm'));

  const handleSubmit = () => {
    if (option === TRANSFER_OPTIONS.GENERATE_PDF) {
      setIsLoading(true);
      dispatch(documentInvitiationActions.downloadTransferOwnershipPDF(documentId));
    }

    if (option === TRANSFER_OPTIONS.BY_EMAIL) {
      setCurrentStep(TRANSFER_OWNERSHIP_STEPS.TRANSFER_OWNERSHIP_BY_EMAIL);
    }
  };

  const documentDownloadCallback = () => {
    setTimeout(() => {
      setIsLoading(false);
      history.push(`/certificate/${documentId}`);
    }, 1000);
  };

  return (
    <>
      <TransferOwnershipWebsocket documentId={documentId} callback={documentDownloadCallback} />
      <SmallContentWithScrollbar
        startContent={
          <Typography variant={smallScreen ? 'h3' : 'h2'}>
            {t('transferOwnership.title')}
          </Typography>
        }
        endContent={
          <Button
            size="large"
            type="submit"
            fullWidth
            disabled={!option || isLoading}
            onClick={handleSubmit}
            endIcon={isLoading && <CircularProgress size={20} color="inherit" />}
          >
            {option === TRANSFER_OPTIONS.GENERATE_PDF ? t('common.generate') : t('common.continue')}
          </Button>
        }
      >
        <div className={classes.items}>
          <ListChoiceButton
            icon={<Email />}
            label={t('transferOwnership.steps.chooseTransferType.sendByEmail.title')}
            description={t('transferOwnership.steps.chooseTransferType.sendByEmail.caption')}
            onClick={handleChooseByEmail}
            selected={option === TRANSFER_OPTIONS.BY_EMAIL}
          />
          <ListChoiceButton
            icon={<Download />}
            label={t('transferOwnership.steps.chooseTransferType.generatePDF.title')}
            description={t('transferOwnership.steps.chooseTransferType.generatePDF.caption')}
            onClick={handleChooseByPDF}
            selected={option === TRANSFER_OPTIONS.GENERATE_PDF}
          />
        </div>
      </SmallContentWithScrollbar>
    </>
  );
};

export { TransferOwnershipStep };
