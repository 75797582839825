import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Badge, Fab, Tooltip, useMediaQuery } from '@material-ui/core';
import { ArrowDownSLine as ChevronDownIcon } from '../CustomIcon';
import { useStyles } from './styles';

const HeaderButton = ({
  className: classNameProp,
  classes: classesProp,
  component,
  badge,
  badgeContent,
  color,
  chevron,
  chevronDirection,
  icon,
  children,
  onClick,
  tooltip,
  variant,
}) => {
  const desktopDevice = useMediaQuery(({ breakpoints }) => breakpoints.up('md'));
  const classes = useStyles();

  const renderButton = () => (
    <Fab
      component={component}
      variant={variant}
      color={color}
      classes={{
        root: clsx(
          classes.root,
          classesProp && classesProp.root,
          {
            [classes[`${variant}Variant`]]: variant,
          },
          classNameProp && classNameProp
        ),
        sizeMedium: classes.mediumSize,
      }}
      onClick={onClick}
      size={desktopDevice ? 'large' : 'medium'}
    >
      {icon && (
        <Badge
          badgeContent={badgeContent}
          invisible={!badge}
          classes={{
            root: classes.iconWrapper,
            badge: classes.badge,
            dot: classes.dot,
          }}
        >
          {icon}
        </Badge>
      )}

      {variant === 'extended' && (
        <>
          {children && (
            <div className={clsx(classes.additionalContent, classes.textWrapper)}>{children}</div>
          )}
          {chevron && (
            <ChevronDownIcon
              className={clsx(
                classes.arrow,
                classes.additionalContent,
                chevronDirection === 'up' && classes.arrowExpanded
              )}
            />
          )}
        </>
      )}
    </Fab>
  );

  return <>{tooltip ? <Tooltip title={tooltip}>{renderButton()}</Tooltip> : renderButton()}</>;
};

HeaderButton.propTypes = {
  component: PropTypes.elementType,
  className: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object,
  children: PropTypes.node,
  color: PropTypes.oneOf(['default', 'primary', 'secondary']),
  chevron: PropTypes.bool,
  chevronDirection: PropTypes.oneOf(['down', 'up']),
  icon: PropTypes.node,
  onClick: PropTypes.func,
  tooltip: PropTypes.node,
  variant: PropTypes.oneOf(['round', 'extended']),
  badge: PropTypes.bool,
  badgeContent: PropTypes.node,
};

HeaderButton.defaultProps = {
  badge: false,
  chevron: false,
  chevronDirection: 'down',
  variant: 'round',
};

export default HeaderButton;
