import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ palette }) =>
    createStyles({
      root: {
        display: 'flex',
        flexDirection: 'row',
      },
      avatar: {
        border: `2px solid ${palette.background.default}`,
        boxSizing: 'content-box',
        marginLeft: -5,
        borderRadius: '50%',
        '&:first-child': {
          marginLeft: 0,
        },
      },
      extraAvatar: {
        color: `${palette.primary.contrastText} !important`,
      },
    }),
  { index: 0 }
);
