import communicationsConstants from 'src/redux/constants/communications.constants';
import { Channel, SystemMessage } from 'src/models/communications.model';
import { RequestStatus } from 'src/helpers/reduxReuquest.util';

type UpdateChannelsStatusesType = { [k: string]: RequestStatus };

const prepareUpdateChannelsMethodStatuses = (channels: Channel[]) => {
  const statusesObject: UpdateChannelsStatusesType = {};
  channels.forEach((item) => {
    statusesObject[`${item.notificationTemplate}`] = RequestStatus.IDLE;
  });
  return statusesObject;
};

const updateChannelMethodStatus = (
  channelsStatuses: UpdateChannelsStatusesType,
  template: string,
  status: RequestStatus
) => {
  const newChannelsStatuses = channelsStatuses;
  newChannelsStatuses[template] = status;
  return newChannelsStatuses;
};

const updateChannelMethod = (channels: Channel[], newChannel: Channel) => {
  const updatedIndex = channels.findIndex(
    (item) => item.notificationTemplate === newChannel.notificationTemplate
  );
  const newChannelsObject = [...channels];
  newChannelsObject[updatedIndex] = newChannel;

  return newChannelsObject;
};

const setSystemMessageAsRead = (systemMessages: SystemMessage[], notificationId: number) =>
  systemMessages.filter((item) => item.id !== notificationId);

export type CommunicationsState = {
  channels: Channel[];
  channelsStatus: RequestStatus;
  updateChannelsStatuses: UpdateChannelsStatusesType;
  setSystemAllMessagesAsRead: RequestStatus;
  setSystemMessageAsRead: RequestStatus;
  settingSystemMessageAsReadById: number | null;
  unreadSystemMessagesCounter: number;
  unreadSystemMessagesCounterStatus: RequestStatus;
  unreadSystemMessages: SystemMessage[];
  fetchUnreadSystemMessages: RequestStatus;
  readSystemMessages: SystemMessage[];
  fetchReadSystemMessages: RequestStatus;
  hasMoreUnreadSystemMessages: boolean;
  hasMoreReadSystemMessages: boolean;
};

const initialState = {
  channels: [],
  channelsStatus: RequestStatus.IDLE,
  updateChannelsStatuses: {},
  setSystemAllMessagesAsRead: RequestStatus.IDLE,
  setSystemMessageAsRead: RequestStatus.IDLE,
  settingSystemMessageAsReadById: null,
  unreadSystemMessagesCounter: 0,
  unreadSystemMessagesCounterStatus: RequestStatus.IDLE,
  unreadSystemMessages: [],
  fetchUnreadSystemMessages: RequestStatus.IDLE,
  readSystemMessages: [],
  fetchReadSystemMessages: RequestStatus.IDLE,
  hasMoreUnreadSystemMessages: false,
  hasMoreReadSystemMessages: false,
};

function communicationsStore(
  state: CommunicationsState = initialState,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any
  action: any // FIXME: fix this in next stage
): CommunicationsState {
  switch (action.type) {
    case communicationsConstants.GET_USER_NOTIFICATIONS_CHANNELS_REQUEST:
      return {
        ...state,
        channelsStatus: RequestStatus.PENDING,
      };
    case communicationsConstants.GET_USER_NOTIFICATIONS_CHANNELS_SUCCESS:
      return {
        ...state,
        channels: action.channels,
        channelsStatus: RequestStatus.SUCCESS,
        updateChannelsStatuses: prepareUpdateChannelsMethodStatuses(action.channels),
      };
    case communicationsConstants.GET_USER_NOTIFICATIONS_CHANNELS_FAILURE:
      return {
        ...state,
        channelsStatus: RequestStatus.ERROR,
      };

    case communicationsConstants.UPDATE_NOTIFICATION_CHANNEL_REQUEST:
      return {
        ...state,
        updateChannelsStatuses: updateChannelMethodStatus(
          state.updateChannelsStatuses || {},
          action.data.notificationTemplate,
          RequestStatus.PENDING
        ),
      };
    case communicationsConstants.UPDATE_NOTIFICATION_CHANNEL_SUCCESS: {
      return {
        ...state,
        updateChannelsStatuses: updateChannelMethodStatus(
          state.updateChannelsStatuses || {},
          action.newChannel.notificationTemplate,
          RequestStatus.SUCCESS
        ),
        channels: updateChannelMethod(state.channels || [], action.newChannel),
      };
    }

    case communicationsConstants.UPDATE_NOTIFICATION_CHANNEL_FAILURE:
      return {
        ...state,
        updateChannelsStatuses: updateChannelMethodStatus(
          state.updateChannelsStatuses || {},
          action.data.notificationTemplate,
          RequestStatus.ERROR
        ),
      };

    case communicationsConstants.GET_UNREAD_USER_NOTIFICATION_SYSTEM_MESSAGES_REQUEST:
      return {
        ...state,
        fetchUnreadSystemMessages: RequestStatus.PENDING,
      };
    case communicationsConstants.GET_UNREAD_USER_NOTIFICATION_SYSTEM_MESSAGES_SUCCESS:
      return {
        ...state,
        unreadSystemMessages: action.replace
          ? action.data
          : [...state.unreadSystemMessages, ...action.data],
        hasMoreUnreadSystemMessages: action.hasNextPage,
        fetchUnreadSystemMessages: RequestStatus.SUCCESS,
      };
    case communicationsConstants.GET_UNREAD_USER_NOTIFICATION_SYSTEM_MESSAGES_FAILURE:
      return {
        ...state,
        fetchUnreadSystemMessages: RequestStatus.ERROR,
      };

    case communicationsConstants.GET_UNREAD_USER_NOTIFICATION_SYSTEM_MESSAGES_COUNTER_REQUEST:
      return {
        ...state,
        unreadSystemMessagesCounterStatus: RequestStatus.PENDING,
      };
    case communicationsConstants.GET_UNREAD_USER_NOTIFICATION_SYSTEM_MESSAGES_COUNTER_SUCCESS:
      return {
        ...state,
        unreadSystemMessagesCounter: action.data,
        unreadSystemMessagesCounterStatus: RequestStatus.SUCCESS,
      };
    case communicationsConstants.GET_UNREAD_USER_NOTIFICATION_SYSTEM_MESSAGES_COUNTER_FAILURE:
      return {
        ...state,
        unreadSystemMessagesCounterStatus: RequestStatus.ERROR,
      };
    case communicationsConstants.UPDATE_UNREAD_USER_NOTIFICATION_SYSTEM_MESSAGES_COUNTER:
      return {
        ...state,
        unreadSystemMessagesCounter: action.payload,
      };

    case communicationsConstants.GET_READ_USER_NOTIFICATION_SYSTEM_MESSAGES_REQUEST:
      return {
        ...state,
        fetchReadSystemMessages: RequestStatus.PENDING,
      };
    case communicationsConstants.GET_READ_USER_NOTIFICATION_SYSTEM_MESSAGES_SUCCESS:
      return {
        ...state,
        readSystemMessages: [...state.readSystemMessages, ...action.data],
        hasMoreReadSystemMessages: action.hasNextPage,
        fetchReadSystemMessages: RequestStatus.SUCCESS,
      };
    case communicationsConstants.GET_READ_USER_NOTIFICATION_SYSTEM_MESSAGES_FAILURE:
      return {
        ...state,
        fetchReadSystemMessages: RequestStatus.ERROR,
      };

    case communicationsConstants.SET_USER_NOTIFICATION_AS_READ_REQUEST:
      return {
        ...state,
        settingSystemMessageAsReadById: action.notificationId,
        setSystemMessageAsRead: RequestStatus.PENDING,
      };
    case communicationsConstants.SET_USER_NOTIFICATION_AS_READ_SUCCESS: {
      // get message marked as read
      const readMessage = state.unreadSystemMessages.find(
        (item) => item.id === action.notificationId
      );
      // update read flag
      if (readMessage) readMessage.read = true;
      return {
        ...state,
        // Filter unread messages by selected id
        unreadSystemMessages: setSystemMessageAsRead(
          state.unreadSystemMessages,
          action.notificationId
        ),
        // Add selected message to read list
        readSystemMessages:
          readMessage && state.readSystemMessages.length > 0
            ? [readMessage, ...state.readSystemMessages]
            : [...state.readSystemMessages],
        settingSystemMessageAsReadById: null,
        unreadSystemMessagesCounter: state.unreadSystemMessagesCounter - 1,
        setSystemMessageAsRead: RequestStatus.SUCCESS,
      };
    }

    case communicationsConstants.SET_USER_NOTIFICATION_AS_READ_FAILURE:
      return {
        ...state,
        settingSystemMessageAsReadById: null,
        setSystemMessageAsRead: RequestStatus.ERROR,
      };

    case communicationsConstants.SET_USER_ALL_NOTIFICATIONS_AS_READ_REQUEST:
      return {
        ...state,
        setSystemAllMessagesAsRead: RequestStatus.PENDING,
      };
    case communicationsConstants.SET_USER_ALL_NOTIFICATIONS_AS_READ_SUCCESS:
      return {
        ...state,
        unreadSystemMessages: [],
        unreadSystemMessagesCounter: initialState.unreadSystemMessagesCounter,
        readSystemMessages:
          state.unreadSystemMessages.length > 0
            ? [
                ...state.unreadSystemMessages.map((message) => {
                  if (!message.read) {
                    message.read = true;
                  }
                  return message;
                }),
                ...state.readSystemMessages,
              ]
            : [],
        setSystemAllMessagesAsRead: RequestStatus.SUCCESS,
      };
    case communicationsConstants.SET_USER_ALL_NOTIFICATIONS_AS_READ_FAILURE:
      return {
        ...state,
        setSystemAllMessagesAsRead: RequestStatus.ERROR,
      };

    case communicationsConstants.CLEAR_COMMUNICATIONS_STORE:
      return {
        ...initialState,
        unreadSystemMessagesCounter: state.unreadSystemMessagesCounter,
      };

    default:
      return state;
  }
}

export default communicationsStore;
