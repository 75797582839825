import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';

const PasswordMeter = (props) => {
  const classes = useStyles({});
  const { value } = props;
  const { t } = useTranslation();
  /*
   * TODO: replace ratePassword when rating method is chosen
   * */
  const ratePassword = (pass) => {
    let score = 0;
    if (!pass) return score;

    // award every unique letter until 5 repetitions
    const letters = {};
    for (let i = 0; i < pass.length; i += 1) {
      letters[pass[i]] = (letters[pass[i]] || 0) + 1;
      score += 5.0 / letters[pass[i]];
    }

    // bonus points for mixing it up
    const variations = {
      digits: /\d/.test(pass),
      lower: /[a-z]/.test(pass),
      upper: /[A-Z]/.test(pass),
      nonWords: /\W/.test(pass),
    };

    let variationCount = 0;
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const check in variations) {
      variationCount += variations[check] === true ? 1 : 0;
    }
    score += (variationCount - 1) * 10;
    score = parseInt(score, 10);

    if (score > 80) return 3;
    if (score > 60) return 2;
    return 1;
  };

  const passwordRating = ratePassword(value);

  const getLabelClassByRating = () => {
    switch (passwordRating) {
      case 3:
        return 'strong';
      case 2:
        return 'medium';
      case 1:
        return 'easy';
      default:
        return 'none';
    }
  };

  const PasswordBar = ({ active, rating }) => (
    <div
      className={clsx(
        classes.bar,
        {
          [classes[`${String(rating)}Bar`]]: rating,
        },
        active && classes.activeBar
      )}
    />
  );

  PasswordBar.propTypes = {
    active: PropTypes.bool,
    rating: PropTypes.string,
  };

  const strengthKey = getLabelClassByRating();

  return (
    <Grid container spacing={2} alignItems="center" className={classes.root}>
      <Grid item xs={12} sm={8}>
        <div className={classes.bars}>
          <PasswordBar active={passwordRating > 0} rating="easy" />
          <PasswordBar active={passwordRating > 1} rating="medium" />
          <PasswordBar active={passwordRating > 2} rating="strong" />
        </div>
      </Grid>
      <Grid item xs="auto">
        <Typography
          variant="subtitle2"
          className={clsx({ [classes[`${strengthKey}StrengthText`]]: strengthKey })}
        >
          {t(`passwordMeter.label.${strengthKey}`)}
        </Typography>
      </Grid>
    </Grid>
  );
};

PasswordMeter.propTypes = {
  value: PropTypes.string,
};

export default PasswordMeter;
