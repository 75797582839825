import React, { useContext } from 'react';
import { StepperContext, TRANSFER_OWNERSHIP_STEPS } from '../context/SteperContext';
import { TransferByEmailStep, TransferOwnershipStep } from '../steps';

const StepResolver = () => {
  const { currentStep } = useContext(StepperContext);

  const Steps = {
    [TRANSFER_OWNERSHIP_STEPS.TRANSFER_OWNERSHIP]: <TransferOwnershipStep />,
    [TRANSFER_OWNERSHIP_STEPS.TRANSFER_OWNERSHIP_BY_EMAIL]: <TransferByEmailStep />,
  };

  return Steps[currentStep];
};

export default StepResolver;
