import { fade } from '@material-ui/core/styles';
import { makeStyles, createStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ breakpoints, palette, spacing, transitions }) =>
    createStyles({
      root: {},
      content: {
        [breakpoints.down('sm')]: {},
      },
      hiddenStep: {
        opacity: 0,
        visibility: 'hidden',
        position: 'fixed',
        pointerEvents: 'none',
        width: 0,
        height: 0,
      },
      title: {},
      subtitle: {
        [breakpoints.up('lg')]: {
          marginBottom: spacing(1),
        },
      },
      dropzoneWrapper: {
        [breakpoints.down('sm')]: {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          minHeight: '100%',
        },
      },
      dropzone: {
        position: 'relative',
        backgroundColor: palette.background.default,
        padding: spacing(5, 3),
        border: 'none !important',
        outline: 'none !important',
        boxSizing: 'border-box',
        borderRadius: 6,
        overflow: 'hidden',
        cursor: 'grabbing',
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
        maxWidth: breakpoints.values.sm,
        marginLeft: 'auto',
        marginRight: 'auto',
        transition: transitions.create(['background'], {
          duration: transitions.duration.short,
        }),
        background: `linear-gradient(to right, ${palette.primary.light} 33%, #fff 0%) top/14px 1px repeat-x,
         linear-gradient(${palette.primary.light} 33%, #fff 0%) right/1px 14px repeat-y, 
         linear-gradient(to right, ${palette.primary.light} 33%, #fff 0%) bottom/14px 1px repeat-x, 
         linear-gradient(${palette.primary.light} 33%, #fff 0%) left/1px 14px repeat-y`,
        '&:focus, &:active': {
          border: `0.5px dashed ${palette.primary.light}`,
        },
        [breakpoints.down('sm')]: {
          padding: spacing(4, 3),
        },
        [breakpoints.down('xs')]: {
          padding: spacing(3, 2),
          minHeight: breakpoints.values.xs * 0.75,
        },
      },
      dropzoneActive: {
        backgroundColor: palette.background.default,
        background: `linear-gradient(to right, #222 33%, #fff 0%) top/14px 1px repeat-x,
         linear-gradient(#222 33%, #fff 0%) right/1px 14px repeat-y, 
         linear-gradient(to right, #222 33%, #fff 0%) bottom/14px 1px repeat-x, 
         linear-gradient(#222 33%, #fff 0%) left/1px 14px repeat-y`,
      },
      dropzoneReject: {
        backgroundColor: fade(palette.common.red, 0.08),
        background: `linear-gradient(to right, ${fade(
          palette.common.red,
          0.28
        )} 33%, #fff 0%) top/14px 1px repeat-x,
         linear-gradient(${fade(palette.common.red, 0.28)} 33%, #fff 0%) right/1px 14px repeat-y, 
         linear-gradient(to right, ${fade(
           palette.common.red,
           0.28
         )} 33%, #fff 0%) bottom/14px 1px repeat-x, 
         linear-gradient(${fade(palette.common.red, 0.28)} 33%, #fff 0%) left/1px 14px repeat-y`,
      },
      dropzoneAccept: {
        backgroundColor: fade(palette.common.green, 0.08),
        background: `linear-gradient(to right, ${fade(
          palette.common.green,
          0.48
        )} 33%, #fff 0%) top/14px 1px repeat-x,
         linear-gradient(${fade(palette.common.green, 0.48)} 33%, #fff 0%) right/1px 14px repeat-y, 
         linear-gradient(to right, ${fade(
           palette.common.green,
           0.28
         )} 33%, #fff 0%) bottom/14px 1px repeat-x, 
         linear-gradient(${fade(palette.common.green, 0.48)} 33%, #fff 0%) left/1px 14px repeat-y`,
      },
      dropzoneDisabled: {
        opacity: 0.5,
        pointerEvents: 'none',
      },
      iconBox: {
        position: 'relative',
        marginBottom: spacing(3),
      },
      icon: {
        fontSize: 58,
      },
      textSmall: {
        fontSize: 12,
      },
      smallIconBox: {
        width: spacing(2.2),
        height: spacing(2.2),
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#fff',
        position: 'absolute',
        right: 6,
        bottom: 10,
      },
      smallIcon: {
        fontSize: 12,
        color: palette.primary.main,
      },
      alert: {
        marginBottom: spacing(2),
      },
    }),
  { index: 0 }
);
