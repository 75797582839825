import React from 'react';
import {
  Button,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RequestStatus } from 'src/helpers/reduxReuquest.util';
import MappingLayout from '../components/MappingLayout/MappingLayout';
import { useStyles } from './styles';
import certificateCreatorActions from '../../../../redux/actions/certificateCreator.actions';
import { CertificateSteps } from '../../../../models/certificate.model';
import CertificateHelper from '../../../../helpers/certificateHelper';

const MapMultipleData = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { templateDetails, templateDetailsStatus, rawImportedData, mappedFields } = useSelector(
    (state) => state.certificateCreatorStore,
    undefined
  );
  const { headersWithNoArrayValues } = CertificateHelper.parseImportedData(rawImportedData);

  const isLoading = templateDetailsStatus === RequestStatus.PENDING;

  const handleChange = (e) => {
    dispatch(
      certificateCreatorActions.setMappedFields({
        keyValuePair: { [e.target.name]: e.target.value },
      })
    );
  };

  const onImportClick = () => {
    dispatch(certificateCreatorActions.setDraftFromImportedData());

    dispatch(
      certificateCreatorActions.setCreatorStep({
        currentStep: CertificateSteps.ImportMultipleData,
      })
    );
  };

  return (
    <MappingLayout>
      <div className={classes.fieldsWrapper}>
        {isLoading ? (
          <div className={classes.loader}>
            <CircularProgress size={48} />
          </div>
        ) : (
          <>
            <Typography className={classes.title} variant="h2">
              {t('createCertificate.fieldMappings')}
            </Typography>
            {templateDetails &&
              Object.keys(templateDetails.fields).map((fieldName) => {
                const { label, required } = templateDetails.fields[fieldName];

                if (fieldName === 'attached_files') return null;

                return (
                  <FormControl key={`form_control_${fieldName}`}>
                    <Typography className={classes.selectLabel} color="textSecondary" mb={16}>
                      {label}
                    </Typography>
                    <Select
                      name={fieldName}
                      className={classes.select}
                      onChange={handleChange}
                      value={mappedFields[fieldName]}
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}
                    >
                      {!required && (
                        <MenuItem value="EMPTY_FIELD">
                          {t('createCertificate.ignoreThisField')}
                        </MenuItem>
                      )}
                      {headersWithNoArrayValues
                        .filter((key) => key !== '')
                        .map((item, index) => (
                          <MenuItem key={`menu_item_${index}`} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                );
              })}
          </>
        )}
      </div>
      <Button size="large" type="submit" className={classes.bottomButton} onClick={onImportClick}>
        {t('createCertificate.import')}
      </Button>
    </MappingLayout>
  );
};

export default MapMultipleData;
