import i18n from 'src/helpers/i18n';
import { SNACKBAR_VARIANT } from 'src/models/common.model';
import twoFactorConfigConstants from '../constants/twoFactorConfig.constants';
import twoFactorService from '../services/twoFactor.service';
import snackbarActions from './snackbar.actions';

const twoFactorConfigActions = {
  getUserTwoFactorConfig,
  changeActiveTwoFactorType,
  deleteTwoFactorType,
  initTwoFactorTypeChange,
  confirmTwoFactorTypeChange,
  clearConfirmTwoFactorStatus,
  clearStore,
};

function getUserTwoFactorConfig() {
  return (dispatch) => {
    dispatch({ type: twoFactorConfigConstants.GET_TWO_FACTOR_CONFIG_REQUEST });
    twoFactorService
      .getUserTwoFactorConfig()
      .then((response) => {
        if (response) {
          dispatch({ type: twoFactorConfigConstants.GET_TWO_FACTOR_CONFIG_SUCCESS, response });
        }
      })
      .catch((error) => {
        dispatch({ type: twoFactorConfigConstants.GET_TWO_FACTOR_CONFIG_ERROR, error });
        dispatch(
          snackbarActions.enqueueSnackbar(
            SNACKBAR_VARIANT.ERROR,
            i18n.t('snackbars.common.errorTitle'),
            `${i18n.t('snackbars.common.error')} ${error.code}.`
          )
        );
      });
  };
}

function changeActiveTwoFactorType(type) {
  return (dispatch) => {
    dispatch({ type: twoFactorConfigConstants.CHANGE_TWO_FACTOR_ACTIVE_TYPE_REQUEST });
    twoFactorService
      .changeActiveTwoFactorType(type)
      .then((response) => {
        if (response) {
          dispatch({
            type: twoFactorConfigConstants.CHANGE_TWO_FACTOR_ACTIVE_TYPE_SUCCESS,
            twoFactorActiveMethod: type,
          });
          dispatch(
            snackbarActions.enqueueSnackbar(
              SNACKBAR_VARIANT.SUCCESS,
              i18n.t('snackbars.common.successTitle'),
              i18n.t('snackbars.common.success')
            )
          );
        }
      })
      .catch((error) => {
        dispatch({ type: twoFactorConfigConstants.CHANGE_TWO_FACTOR_ACTIVE_TYPE_ERROR, error });
        dispatch(
          snackbarActions.enqueueSnackbar(
            SNACKBAR_VARIANT.ERROR,
            i18n.t('snackbars.common.errorTitle'),
            `${i18n.t('snackbars.common.error')} ${error.code}.`
          )
        );
      });
  };
}

function deleteTwoFactorType(type) {
  return (dispatch) => {
    dispatch({ type: twoFactorConfigConstants.DELETE_TWO_FACTOR_TYPE_REQUEST });
    twoFactorService
      .deleteTwoFactorType(type)
      .then((response) => {
        if (response) {
          dispatch({
            type: twoFactorConfigConstants.DELETE_TWO_FACTOR_TYPE_SUCCESS,
          });
          dispatch(
            snackbarActions.enqueueSnackbar(
              SNACKBAR_VARIANT.SUCCESS,
              i18n.t('snackbars.common.successTitle'),
              i18n.t('snackbars.common.success')
            )
          );
        }
      })
      .catch((error) => {
        dispatch({ type: twoFactorConfigConstants.DELETE_TWO_FACTOR_TYPE_ERROR, error });
        dispatch(
          snackbarActions.enqueueSnackbar(
            SNACKBAR_VARIANT.ERROR,
            i18n.t('snackbars.common.errorTitle'),
            `${i18n.t('snackbars.common.error')} ${error.code}.`
          )
        );
      });
  };
}

function initTwoFactorTypeChange(type) {
  return (dispatch) => {
    dispatch({ type: twoFactorConfigConstants.INIT_TWO_FACTOR_TYPE_CHANGE_REQUEST });
    twoFactorService
      .initTwoFactorTypeChange(type)
      .then((response) => {
        if (response) {
          dispatch({
            type: twoFactorConfigConstants.INIT_TWO_FACTOR_TYPE_CHANGE_SUCCESS,
            response,
            initTwoFactorMethod: type,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: twoFactorConfigConstants.INIT_TWO_FACTOR_TYPE_CHANGE_ERROR, error });
        dispatch(
          snackbarActions.enqueueSnackbar(
            SNACKBAR_VARIANT.ERROR,
            i18n.t('snackbars.common.errorTitle'),
            `${i18n.t('snackbars.common.error')} ${error.code}.`
          )
        );
      });
  };
}
function confirmTwoFactorTypeChange(type, code) {
  return (dispatch) => {
    dispatch({ type: twoFactorConfigConstants.CONFIRM_TWO_FACTOR_TYPE_CHANGE_REQUEST });
    twoFactorService
      .confirmTwoFactorTypeChange(type, code)
      .then((response) => {
        if (response) {
          dispatch({
            type: twoFactorConfigConstants.CONFIRM_TWO_FACTOR_TYPE_CHANGE_SUCCESS,
            twoFactorActiveMethod: type,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: twoFactorConfigConstants.CONFIRM_TWO_FACTOR_TYPE_CHANGE_ERROR, error });
        if (error.code !== 'CODE_VERIFICATION_FAILED') {
          dispatch(
            snackbarActions.enqueueSnackbar(
              SNACKBAR_VARIANT.ERROR,
              i18n.t('snackbars.common.errorTitle'),
              `${i18n.t('snackbars.common.error')} ${error.code}.`
            )
          );
        }
      });
  };
}

function clearConfirmTwoFactorStatus() {
  return { type: twoFactorConfigConstants.CLEAR_CONFIRM_TWO_FACTOR_TYPE_STATUS };
}
function clearStore() {
  return { type: twoFactorConfigConstants.CLEAR_STORE };
}

export default twoFactorConfigActions;
