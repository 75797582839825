import React, { MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment/moment';
import { Button, Typography } from '@material-ui/core';
import { ClearOutlined } from '@material-ui/icons';
import { useStyles } from './styles';
import FilterChip from './FilterChip';
import {
  AdvancedSearchFields,
  areAdvancedSearchObjectsDifferent,
  DocumentStatus,
  DocumentType,
  ExtraDocumentType,
} from '../../models/documents.model';
import DocumentTypeDropdown from '../DocumentTypeDropdown/DocumentTypeDropdown';
import { RootState } from '../../redux/reducers';
import documentsActions from '../../redux/actions/documents.actions';
import DocumentStatusDropdown from '../DocumentStatusDropdown/DocumentStatusDropdown';
import { ResponsiveDateRangePicker } from '../index';
import { DateDropdownType } from '../DateDropdown/DateDropdown';
import RecipientsDropdown from '../RecipientsDropdown/RecipientsDropdown';
import { UserManagerPerson } from '../../models/documentUsersManagement.model';
import DirectoryExplorer from '../DirectoryExplorer/DirectoryExplorer';
import { IdentitySelector } from '../../redux/selectors/identity.selector';
import ScrollableXaxis from '../ScrollableXaxis/ScrollableXaxis';
import { advancedSearchInitialState } from '../../redux/reducers/documents.reducer';

type DirectoryExplorerReturnType = {
  id: string;
  name: string;
};
const AdvancedSearchFilterBar = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const clearRef = React.useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const workspaceId = useSelector(IdentitySelector.selectWorkspaceId);
  const { advancedSearch, folderPath } = useSelector((state: RootState) => state.documentsStore);
  const showClearButton = areAdvancedSearchObjectsDifferent(
    advancedSearch,
    advancedSearchInitialState,
    [AdvancedSearchFields.NAME]
  );
  const dateCreated = advancedSearch[AdvancedSearchFields.DATE_CREATED];
  const currentLocation = advancedSearch[AdvancedSearchFields.LOCATION];
  const dateModified = advancedSearch[AdvancedSearchFields.DATE_MODIFIED];

  const onDocumentTypeSubmit = (types: Array<DocumentType | ExtraDocumentType>) => {
    dispatch(documentsActions.updateAdvancedSearch(AdvancedSearchFields.TYPE, types));
    dispatch(documentsActions.updateDraftAdvancedSearch(AdvancedSearchFields.TYPE, types));
  };
  const onDocumentStatusSubmit = (statuses: DocumentStatus[]) => {
    dispatch(documentsActions.updateAdvancedSearch(AdvancedSearchFields.STATUS, statuses));
    dispatch(documentsActions.updateDraftAdvancedSearch(AdvancedSearchFields.STATUS, statuses));
  };

  const onDateCreatedSubmit = (newDate: Array<moment.Moment | null>) => {
    dispatch(
      documentsActions.updateAdvancedSearch(AdvancedSearchFields.DATE_CREATED, {
        type: DateDropdownType.BETWEEN,
        from: newDate[0],
        to: newDate[1],
      })
    );
    dispatch(
      documentsActions.updateDraftAdvancedSearch(AdvancedSearchFields.DATE_CREATED, {
        type: DateDropdownType.BETWEEN,
        from: newDate[0],
        to: newDate[1],
      })
    );
  };
  const onDateModifiedSubmit = (newDate: Array<moment.Moment | null>) => {
    dispatch(
      documentsActions.updateAdvancedSearch(AdvancedSearchFields.DATE_MODIFIED, {
        type: DateDropdownType.BETWEEN,
        from: newDate[0],
        to: newDate[1],
      })
    );
    dispatch(
      documentsActions.updateDraftAdvancedSearch(AdvancedSearchFields.DATE_MODIFIED, {
        type: DateDropdownType.BETWEEN,
        from: newDate[0],
        to: newDate[1],
      })
    );
  };

  const onRecipientsSubmit = (recipients: UserManagerPerson[]) => {
    dispatch(documentsActions.updateAdvancedSearch(AdvancedSearchFields.RECIPIENTS, recipients));
    dispatch(
      documentsActions.updateDraftAdvancedSearch(AdvancedSearchFields.RECIPIENTS, recipients)
    );
  };
  const closeLocationExplorer = () => {
    setAnchorEl(null);
  };

  const handleLocationClick = (e: MouseEvent<HTMLElement>) => {
    if (e) setAnchorEl(e.currentTarget);
  };

  const handleLocationChange = (value: DirectoryExplorerReturnType) => {
    dispatch(
      documentsActions.updateDraftAdvancedSearch(AdvancedSearchFields.LOCATION, {
        idPath: value.id,
        namePath: value.name,
      })
    );
    dispatch(
      documentsActions.updateAdvancedSearch(AdvancedSearchFields.LOCATION, {
        idPath: value.id,
        namePath: value.name,
      })
    );
  };

  const onClearFilters = () => {
    dispatch(documentsActions.clearAdvancedSearch(true));
  };

  return (
    <>
      <Typography className={classes.title}>
        <strong>{t<string>('advancedSearch.titlePage')}</strong>
      </Typography>
      <div className={classes.filterBar}>
        <div
          style={{
            maxWidth: `calc(100% - ${clearRef.current ? clearRef.current.clientWidth + 15 : 0}px`,
          }}
        >
          <ScrollableXaxis>
            <div className={classes.wrapper}>
              <DocumentTypeDropdown
                onSubmit={onDocumentTypeSubmit}
                initialDocumentTypes={advancedSearch[AdvancedSearchFields.TYPE]}
              >
                <FilterChip variant={AdvancedSearchFields.TYPE} />
              </DocumentTypeDropdown>
              <DocumentStatusDropdown
                initialStatusTypes={advancedSearch[AdvancedSearchFields.STATUS]}
                onSubmit={onDocumentStatusSubmit}
              >
                <FilterChip variant={AdvancedSearchFields.STATUS} />
              </DocumentStatusDropdown>
              <ResponsiveDateRangePicker
                value={[dateCreated.from.toDate(), dateCreated.to.toDate()]}
                setValue={() => undefined}
                onApply={onDateCreatedSubmit}
                buttonComponent={<FilterChip variant={AdvancedSearchFields.DATE_CREATED} />}
              />
              <ResponsiveDateRangePicker
                value={[dateModified.from.toDate(), dateModified.to.toDate()]}
                setValue={() => undefined}
                onApply={onDateModifiedSubmit}
                buttonComponent={<FilterChip variant={AdvancedSearchFields.DATE_MODIFIED} />}
              />
              <RecipientsDropdown
                onSubmit={onRecipientsSubmit}
                initialRecipients={advancedSearch[AdvancedSearchFields.RECIPIENTS]}
              >
                <FilterChip variant={AdvancedSearchFields.RECIPIENTS} />
              </RecipientsDropdown>
              <div aria-hidden="true" onClick={handleLocationClick}>
                <FilterChip variant={AdvancedSearchFields.LOCATION} />
              </div>
              {anchorEl && (
                <DirectoryExplorer
                  initialFolderId={
                    currentLocation === null ? folderPath.idPath : currentLocation.idPath
                  }
                  hideCreateNew
                  workspaceId={workspaceId}
                  onClose={closeLocationExplorer}
                  handleChange={handleLocationChange}
                  anchorEl={anchorEl}
                  confirmText={t<string>('common.apply')}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                />
              )}
            </div>
          </ScrollableXaxis>
        </div>

        {showClearButton && (
          <div className={classes.clearWrapper} ref={clearRef}>
            <Button
              className={classes.clearButton}
              variant="text"
              size="small"
              color="default"
              startIcon={<ClearOutlined className={classes.clearIcon} />}
              onClick={onClearFilters}
            >
              <Typography variant="body1" noWrap color="textSecondary">
                {t<string>('common.clearAll')}
              </Typography>
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default AdvancedSearchFilterBar;
