import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Button, ListItem, Typography } from '@material-ui/core';
import { ExtendedGrid } from 'src/components';
import { PenOutlined as EditIcon, Delete as DeleteIcon } from 'src/components/CustomIcon';
import { useStyles } from './styles';

const BasicItem = ({
  className: classNameProp,
  children,
  label,
  onClick,
  onDeleteClick,
  icon,
  smallScreen,
  alignItems = 'center',
  oneRowMobile = false,
  buttonText,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <ListItem
      disableGutters
      classes={{
        root: clsx(classes.root, classNameProp),
        container: classes.rootContainer,
      }}
    >
      <ExtendedGrid
        container
        spacingX={2}
        spacingY={smallScreen ? 1 : 2}
        alignItemsXxs={alignItems}
      >
        <ExtendedGrid item xxs={oneRowMobile ? 'auto' : 12} md={4} lg={3}>
          <Typography
            variant={smallScreen ? 'body2' : 'h6'}
            color="textSecondary"
            component="label"
          >
            {label}
          </Typography>
        </ExtendedGrid>
        <ExtendedGrid item xs className={classes.colorOnHover}>
          {children}
        </ExtendedGrid>

        {!smallScreen && !!onClick && (
          <ExtendedGrid item xs="auto" textAlignXxs="right">
            <div className={classes.actionButtonsWrapper}>
              {!!onDeleteClick && (
                <Button
                  variant="text"
                  size="small"
                  endIcon={<DeleteIcon />}
                  classes={{
                    root: classes.actionButton,
                    endIcon: classes.actionButtonIcon,
                  }}
                  onClick={onDeleteClick}
                >
                  {t('common.delete')}
                </Button>
              )}
              <Button
                variant="text"
                size="small"
                endIcon={icon || <EditIcon />}
                classes={{
                  root: classes.actionButton,
                  endIcon: classes.actionButtonIcon,
                }}
                onClick={onClick}
              >
                {buttonText || t('common.edit')}
              </Button>
            </div>
          </ExtendedGrid>
        )}
      </ExtendedGrid>

      {smallScreen && onClick && (
        <div className={classes.mobileActionButtonWrapper}>
          <Button
            variant="text"
            size="small"
            endIcon={icon || <EditIcon />}
            classes={{
              root: classes.actionButton,
              endIcon: classes.actionButtonIcon,
            }}
            onClick={onClick}
          >
            {buttonText || t('common.edit')}
          </Button>
        </div>
      )}
    </ListItem>
  );
};

BasicItem.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  icon: PropTypes.node,
  label: PropTypes.string,
  onClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  smallScreen: PropTypes.bool,
  buttonText: PropTypes.string,
  alignItems: PropTypes.oneOf(['flex-start', 'center', 'flex-end', 'stretch', 'baseline']),
  oneRowMobile: PropTypes.bool,
};

export default BasicItem;
