import { DocumentStatus } from './documents.model';

export const DocumentRole = {
  ADMINS: 'admins',
  AUDITORS: 'auditors',
  EDITORS: 'editors',
  VIEWERS: 'viewers',
  SIGNERS: 'signers',
  HOLDER: 'holder',
};

export const mapToDocumentRole = (role) => {
  switch (role.toLowerCase()) {
    case 'admin':
      return DocumentRole.ADMINS;
    case 'auditor':
      return DocumentRole.AUDITORS;
    case 'editor':
      return DocumentRole.EDITORS;
    case 'viewer':
      return DocumentRole.VIEWERS;
    case 'signer':
      return DocumentRole.SIGNERS;
    case 'holders':
      return DocumentRole.HOLDER;
    default:
      return role.toLowerCase();
  }
};

export const rolesTranslations = (t) => ({
  [DocumentRole.ADMINS]: t('inviteToDocument.section.roles.types.admins'),
  [DocumentRole.AUDITORS]: t('inviteToDocument.section.roles.types.auditors'),
  [DocumentRole.EDITORS]: t('inviteToDocument.section.roles.types.editors'),
  [DocumentRole.VIEWERS]: t('inviteToDocument.section.roles.types.viewers'),
});

export const getStatusName = ({ status, isShort, t, isCertificate = false }) => {
  const prefix = isCertificate ? t('documentType.certificate') : t('documentType.document');
  const type = isShort ? null : prefix;
  switch (status) {
    case DocumentStatus.EDITING:
    case DocumentStatus.SIGNING:
    case DocumentStatus.ISSUING:
    case DocumentStatus.REJECTED:
    case DocumentStatus.FINISHED:
    case DocumentStatus.VALID:
    case DocumentStatus.INVALIDATED:
    case DocumentStatus.TEMPORARILY_INVALIDATE:
    case DocumentStatus.EXPIRED:
    case DocumentStatus.READY_FOR_PREVIEW:
      return t(`statuses.${status}.${isShort ? 'short' : 'long'}`, { type });
    default:
      return status;
  }
};

export const HistoryType = {
  CREATE: 'CREATE',
  ADD_ENTITY: 'ADD_ENTITY',
  DELETE_ENTITY: 'DELETE_ENTITY',
  DELETE_INVITATION: 'DELETE_INVITATION',
  DOWNLOAD: 'DOWNLOAD',
  REJECT: 'REJECT',
  RENAME: 'RENAME',
  SIGN: 'SIGN',
  SEND_INVITATION: 'SEND_INVITATION',
  VIEW: 'VIEW',
  VISIBILITY_CHANGE: 'VISIBILITY_CHANGE',
  CHANGE_SIGN_ORDER: 'CHANGE_SIGN_ORDER',
  CHANGE_HOLDER: 'CHANGE_HOLDER',
  INVALIDATE: 'INVALIDATE',
  INVALIDATE_TEMPORARY: 'INVALIDATE_TEMPORARY',
  EXPIRED: 'EXPIRED',
  VALIDATE: 'VALIDATE',
  TRANSFERABILITY_CHANGE: 'TRANSFERABILITY_CHANGE',
  TRANSFER_INVITATION_CREATED: 'TRANSFER_INVITATION_CREATED',
  TRANSFER_INVITATION_REVOKED: 'TRANSFER_INVITATION_REVOKED',
  GENERATE_PDF_INVITATION: 'GENERATE_PDF_INVITATION',
  DELETE_PDF_INVITATION: 'DELETE_PDF_INVITATION',
};
