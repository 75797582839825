import typography from '../typography';
import palette from '../palette';

export default {
  h1: {
    fontWeight: typography.fontWeightMedium,
    lineHeight: '1.5',
    color: 'inherit',
  },
  h2: {
    fontWeight: typography.fontWeightMedium,
    lineHeight: '1.5',
    color: 'inherit',
  },
  h3: {
    fontWeight: typography.fontWeightMedium,
    lineHeight: '1.5',
    color: 'inherit',
  },
  h4: {
    fontWeight: typography.fontWeightMedium,
    lineHeight: '1.5',
    color: 'inherit',
  },
  h5: {
    fontWeight: typography.fontWeightMedium,
    lineHeight: '1.5',
    color: 'inherit',
  },
  h6: {
    fontWeight: typography.fontWeightMedium,
    lineHeight: '1.5',
    color: 'inherit',
  },
  body1: {
    fontFamily: 'inherit',
    lineHeight: '1.5',
    color: 'inherit',
  },
  body2: {
    fontFamily: 'inherit',
    lineHeight: '1.6',
    color: 'inherit',
  },
  caption: {
    fontWeight: 'inherit',
    fontFamily: 'inherit',
    lineHeight: '1.6',
  },
  subtitle1: {
    fontFamily: 'inherit',
    lineHeight: '1.5',
    color: palette.text.secondary,
  },
  subtitle2: {
    fontFamily: 'inherit',
    lineHeight: '1.6',
    color: palette.text.secondary,
  },
  button: {},
};
