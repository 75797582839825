import { lighten } from '@material-ui/core/styles';
import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ breakpoints, spacing, typography, palette }) =>
    createStyles({
      root: {
        display: 'flex',
        alignItems: 'center',
      },
      spacingRoot: {
        paddingTop: spacing(1),
        paddingBottom: spacing(1),
        [breakpoints.up('lg')]: {
          paddingTop: spacing(1.5),
          paddingBottom: spacing(1.5),
        },
      },
      clickElement: {
        cursor: 'pointer',
        textAlign: 'left',
        width: `calc(100% + ${spacing(2)}px)`,
        paddingLeft: spacing(1),
        paddingRight: spacing(1),
        marginLeft: spacing(-1),
        marginRight: spacing(-1),
      },
      fullWidthRoot: {
        width: '100%',
      },
      iconWrapper: {},
      labels: {
        flex: 1,
        overflow: 'hidden',
        marginLeft: spacing(1),
        [breakpoints.up('lg')]: {
          marginLeft: spacing(1.5),
        },
        [breakpoints.up('xl')]: {
          marginLeft: spacing(2),
        },
        '& > *': {
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        },
      },
      text: {
        padding: '1px 0',
        lineHeight: 1.1,
        fontWeight: typography.fontWeightMedium,
      },
      additionalText: {
        fontWeight: typography.fontWeightRegular,
      },
      description: {
        display: 'block',
        marginTop: '0.25em',
        lineHeight: 1.3,
        fontWeight: typography.fontWeightRegular,
        '& .highlighted': {
          fontWeight: typography.fontWeightMedium,
          color: lighten(palette.primary.main, 0.3),
        },
      },
      iconButton: {
        marginRight: -6,
      },
      settingsIcon: {
        marginLeft: spacing(1),
      },
    }),
  { index: 0 }
);
