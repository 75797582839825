import spacing from '../spacing';
import breakpoints from '../breakpoints';

export default {
  root: {
    width: '100%',
    marginLeft: 'auto',
    boxSizing: 'border-box',
    marginRight: 'auto',
    paddingLeft: spacing(1),
    paddingRight: spacing(1),
    [breakpoints.up('sm')]: {
      paddingLeft: spacing(1.5),
      paddingRight: spacing(1.5),
    },
    [breakpoints.up('md')]: {
      paddingLeft: spacing(2),
      paddingRight: spacing(2),
    },
    [breakpoints.up('lg')]: {
      paddingLeft: spacing(2.5),
      paddingRight: spacing(2.5),
    },
  },
};
