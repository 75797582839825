import { RequestStatus } from 'src/helpers/reduxReuquest.util';
import authConstants from 'src/redux/constants/auth.constants';

const initialState = {
  apiKeys: [],
  apiKeysRequestStatus: RequestStatus.IDLE,
  permissions: [],
  permissionsStatus: RequestStatus.IDLE,
  createNewApiKeyStatus: RequestStatus.IDLE,
  deleteApiKeyStatus: RequestStatus.IDLE,
  deleteTokenRequestStatus: RequestStatus.IDLE,
  deleteUserAccountStatus: RequestStatus.IDLE,
  emailBeforeConfirmation: null,
  lastSendEmailTimestamp: 0,
  lastSendSmsTimestamp: 0,
  patchEmailRequestStatus: RequestStatus.IDLE,
  patchLanguageStatus: RequestStatus.IDLE,
  patchPasswordRequestStatus: RequestStatus.IDLE,
  patchPhoneRequestStatus: RequestStatus.IDLE,
  patchUsernameRequestStatus: RequestStatus.IDLE,
  phoneNumberBeforeConfirmation: null,
  postEmailRequestError: null,
  postEmailRequestStatus: RequestStatus.IDLE,
  postPhoneRequestError: null,
  postPhoneRequestStatus: RequestStatus.IDLE,
  tokenStatus: RequestStatus.IDLE,
  userProfile: null,
  userProfileRequestStatus: RequestStatus.IDLE,
};
function auth(state = initialState, action) {
  switch (action.type) {
    case authConstants.GET_API_KEYS_REQUEST:
      return {
        ...state,
        apiKeysRequestStatus: RequestStatus.PENDING,
      };
    case authConstants.GET_API_KEYS_SUCCESS:
      return {
        ...state,
        apiKeys: action.payload,
        apiKeysRequestStatus: RequestStatus.IDLE,
      };
    case authConstants.GET_API_KEYS_ERROR:
      return {
        ...state,
        apiKeysRequestStatus: RequestStatus.ERROR,
      };

    case authConstants.GET_PERMISSIONS_REQUEST:
      return {
        ...state,
        apiKeysRequestStatus: RequestStatus.PENDING,
      };
    case authConstants.GET_PERMISSIONS_SUCCESS:
      return {
        ...state,
        permissions: action.payload,
        permissionsStatus: RequestStatus.IDLE,
      };
    case authConstants.GET_PERMISSIONS_ERROR:
      return {
        ...state,
        apiKeysRequestStatus: RequestStatus.ERROR,
      };

    case authConstants.CREATE_API_KEY_REQUEST:
      return {
        ...state,
        createNewApiKeyStatus: RequestStatus.PENDING,
      };
    case authConstants.CREATE_API_KEY_SUCCESS:
      return {
        ...state,
        createNewApiKeyStatus: RequestStatus.IDLE,
      };
    case authConstants.CREATE_API_KEY_ERROR:
      return {
        ...state,
        createNewApiKeyStatus: RequestStatus.ERROR,
      };

    case authConstants.DELETE_API_KEY_REQUEST:
      return {
        ...state,
        deleteApiKeyStatus: RequestStatus.PENDING,
      };
    case authConstants.DELETE_API_KEY_SUCCESS:
      return {
        ...state,
        deleteApiKeyStatus: RequestStatus.IDLE,
      };
    case authConstants.DELETE_API_KEY_ERROR:
      return {
        ...state,
        deleteApiKeyStatus: RequestStatus.ERROR,
      };

    case authConstants.GET_USER_PROFILE_REQUEST:
      return {
        ...state,
        userProfileRequestStatus: RequestStatus.PENDING,
      };
    case authConstants.GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        userProfileRequestStatus: RequestStatus.SUCCESS,
        userProfile: action.response.data,
      };
    case authConstants.GET_USER_PROFILE_ERROR:
      return {
        ...state,
        userProfileRequestStatus: RequestStatus.ERROR,
      };
    case authConstants.PATCH_USER_PROFILE_USERNAME_REQUEST:
      return {
        ...state,
        patchUsernameRequestStatus: RequestStatus.PENDING,
      };
    case authConstants.PATCH_USER_PROFILE_USERNAME_SUCCESS:
      return {
        ...state,
        patchUsernameRequestStatus: RequestStatus.SUCCESS,
        userProfile: {
          ...state.userProfile,
          userName: action.updatedUsername,
        },
      };
    case authConstants.PATCH_USER_PROFILE_USERNAME_ERROR:
      return {
        ...state,
        patchUsernameRequestStatus: RequestStatus.ERROR,
      };
    case authConstants.PATCH_USER_PROFILE_PASSWORD_REQUEST:
      return {
        ...state,
        patchPasswordRequestStatus: RequestStatus.PENDING,
      };
    case authConstants.PATCH_USER_PROFILE_PASSWORD_SUCCESS:
      return {
        ...state,
        patchPasswordRequestStatus: RequestStatus.SUCCESS,
      };
    case authConstants.PATCH_USER_PROFILE_PASSWORD_ERROR:
      return {
        ...state,
        patchPasswordRequestStatus: RequestStatus.ERROR,
      };
    case authConstants.PATCH_USER_PROFILE_EMAIL_REQUEST:
      return {
        ...state,
        patchEmailRequestStatus: RequestStatus.PENDING,
      };
    case authConstants.PATCH_USER_PROFILE_EMAIL_SUCCESS:
      return {
        ...state,
        patchEmailRequestStatus: RequestStatus.SUCCESS,
        emailBeforeConfirmation: action.updatedEmail,
        lastSendEmailTimestamp: Date.now(),
      };
    case authConstants.PATCH_USER_PROFILE_EMAIL_ERROR:
      return {
        ...state,
        patchEmailRequestStatus: RequestStatus.ERROR,
      };
    case authConstants.POST_USER_PROFILE_EMAIL_REQUEST:
      return {
        ...state,
        postEmailRequestStatus: RequestStatus.PENDING,
      };
    case authConstants.POST_USER_PROFILE_EMAIL_SUCCESS:
      return {
        ...state,
        postEmailRequestStatus: RequestStatus.SUCCESS,
        postEmailRequestError: null,
        userProfile: {
          ...state.userProfile,
          email: state.emailBeforeConfirmation,
        },
        emailBeforeConfirmation: null,
      };
    case authConstants.POST_USER_PROFILE_EMAIL_ERROR:
      return {
        ...state,
        postEmailRequestStatus: RequestStatus.ERROR,
        postEmailRequestError: action.error.code,
      };
    case authConstants.PATCH_USER_PROFILE_PHONE_REQUEST:
      return {
        ...state,
        patchPhoneRequestStatus: RequestStatus.PENDING,
      };
    case authConstants.PATCH_USER_PROFILE_PHONE_SUCCESS:
      return {
        ...state,
        patchPhoneRequestStatus: RequestStatus.SUCCESS,
        phoneNumberBeforeConfirmation: action.updatedPhoneNumber,
        lastSendSmsTimestamp: Date.now(),
      };
    case authConstants.PATCH_USER_PROFILE_PHONE_ERROR:
      return {
        ...state,
        patchPhoneRequestStatus: RequestStatus.ERROR,
      };
    case authConstants.POST_USER_PROFILE_PHONE_REQUEST:
      return {
        ...state,
        postPhoneRequestStatus: RequestStatus.PENDING,
      };
    case authConstants.POST_USER_PROFILE_PHONE_SUCCESS:
      return {
        ...state,
        postPhoneRequestStatus: RequestStatus.SUCCESS,
        postPhoneRequestError: null,
        userProfile: {
          ...state.userProfile,
          ...state.phoneNumberBeforeConfirmation,
        },
        phoneNumberBeforeConfirmation: null,
      };
    case authConstants.POST_USER_PROFILE_PHONE_ERROR:
      return {
        ...state,
        postPhoneRequestStatus: RequestStatus.ERROR,
        postPhoneRequestError: action.error.code,
      };
    case authConstants.CHECK_TOKEN_STATUS_REQUEST:
      return {
        ...state,
        tokenStatus: RequestStatus.PENDING,
      };
    case authConstants.CHECK_TOKEN_STATUS_SUCCESS:
      return {
        ...state,
        tokenStatus: RequestStatus.SUCCESS,
      };
    case authConstants.CHECK_TOKEN_STATUS_FAILURE:
      return {
        ...state,
        tokenStatus: RequestStatus.ERROR,
      };
    case authConstants.DELETE_TOKEN_USER_REQUEST:
      return {
        ...state,
        deleteTokenRequestStatus: RequestStatus.PENDING,
      };
    case authConstants.DELETE_TOKEN_USER_SUCCESS:
      return {
        ...state,
        deleteTokenRequestStatus: RequestStatus.SUCCESS,
      };
    case authConstants.DELETE_TOKEN_USER_FAILURE:
      return {
        ...state,
        deleteTokenRequestStatus: RequestStatus.ERROR,
      };
    case authConstants.PATCH_USER_PROFILE_LANGUAGE_REQUEST:
      return {
        ...state,
        patchLanguageStatus: RequestStatus.PENDING,
      };
    case authConstants.PATCH_USER_PROFILE_LANGUAGE_SUCCESS:
      return {
        ...state,
        patchLanguageStatus: RequestStatus.SUCCESS,
        userProfile: {
          ...state.userProfile,
          language: action.newLanguage,
        },
      };
    case authConstants.PATCH_USER_PROFILE_LANGUAGE_FAILURE:
      return {
        ...state,
        patchLanguageStatus: RequestStatus.ERROR,
      };
    case authConstants.DELETE_USER_ACCOUNT_REQUEST:
      return {
        ...state,
        deleteUserAccountStatus: RequestStatus.PENDING,
      };
    case authConstants.DELETE_USER_ACCOUNT_SUCCESS:
      return {
        ...state,
        patchLanguageStatus: RequestStatus.SUCCESS,
      };
    case authConstants.DELETE_USER_ACCOUNT_FAILURE:
      return {
        ...state,
        patchLanguageStatus: RequestStatus.ERROR,
      };
    default:
      return state;
  }
}

export default auth;
