import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ spacing, palette }) =>
    createStyles({
      input: {
        marginBottom: spacing(2),
      },
      chip: {
        marginBottom: spacing(1),
        color: palette.primary.main,
        background: '#f2f7ff',
        '& svg': {
          color: palette.primary.main,
          '&:hover': {
            color: palette.primary.dark,
          },
        },
      },
      optionWrapper: {
        display: 'flex',
        alignItems: 'center',
        wordBreak: 'break-all',
      },
      partHighlight: {
        fontWeight: 700,
        color: palette.primary.main,
        textDecoration: 'underline',
      },
    }),
  { index: 0 }
);
