import React, { useEffect, useState } from 'react';
import {
  ButtonBase,
  Drawer,
  List,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
  useTheme,
} from '@material-ui/core';
import { MobileBottomMenuList } from 'src/components';
import {
  CertificateIcon,
  FileEdit as EsignIcon,
  FilesWithShield as DriveIcon,
  Plus as AddIcon,
} from 'src/components/CustomIcon';
import { TemplateDocumentType } from 'src/models/documents.model';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { CreateNewFolderOutlined } from '@material-ui/icons';
import AddNewFolderModal from 'src/components/AddNewFolderModal/AddNewFolderModal';
import documentsActions from 'src/redux/actions/documents.actions';
import { useDispatch, useSelector } from 'react-redux';
import { IdentitySelector } from 'src/redux/selectors/identity.selector';
import { RootState } from 'src/redux/reducers';
import identityActions from 'src/redux/actions/identity.actions';
import { useHistory } from 'react-router-dom';
import SubscriptionModal from 'src/components/SelectTemplateDropdown/SubscriptionModal';
import { useStyles } from './styles';

const SpeedDial = (): JSX.Element => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const { palette } = useTheme();
  const [addNewFolderDialogOpen, setAddNewFolderDialogOpen] = useState(false);
  const workspaceId = useSelector(IdentitySelector.selectWorkspaceId);
  const {
    workspaceWorkflows,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } = useSelector((state: any) => state.identityStore, undefined);
  const [
    subscriptionModalProduct,
    setSubscriptionModalProduct,
  ] = useState<TemplateDocumentType | null>(null);
  const { currentFolderId } = useSelector((state: RootState) => state.documentsStore, undefined);
  const history = useHistory();
  const classes = useStyles();
  const handleToggle = {
    close: () => {
      setOpen(false);
    },
    open: () => {
      setOpen(true);
    },
  };

  const options = [
    {
      label: t('selectTemplate.signedDocument.title'),
      description: t('selectTemplate.signedDocument.description'),
      icon: <EsignIcon style={{ color: palette.primary.light }} />,
      value: TemplateDocumentType.DOCUMENT_TO_BE_SIGNED,
    },
    {
      label: t('selectTemplate.durableMedium.title'),
      description: t('selectTemplate.durableMedium.description'),
      icon: <DriveIcon style={{ color: palette.common.purple }} />,
      value: TemplateDocumentType.DURABLE_MEDIA,
    },
    {
      label: t('selectTemplate.certificate.title'),
      description: t('selectTemplate.certificate.description'),
      icon: <CertificateIcon style={{ color: palette.common.green }} />,
      value: TemplateDocumentType.CERTIFICATES,
    },
  ];
  const renderButton = () => (
    <ButtonBase onClick={handleToggle.open} classes={{ root: classes.button }}>
      <AddIcon />
    </ButtonBase>
  );

  const onOptionClick = (value: TemplateDocumentType) => {
    if (!workspaceWorkflows.includes(value)) {
      setSubscriptionModalProduct(value);
    } else {
      const slugTemplate = value.toLowerCase().replaceAll('_', '-');
      history.push(`/document-creator/${slugTemplate}`);
    }
    setOpen(false);
  };

  useEffect(() => {
    if (workspaceId && open) {
      dispatch(identityActions.getWorkspacePermissions(workspaceId));
      dispatch(identityActions.fetchWorkspaceWorkflows(workspaceId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceId, open]);

  return (
    <div className={classes.root}>
      {renderButton()}
      <MobileBottomMenuList open={open} onClose={handleToggle.close} actions={[]} />
      <Drawer
        anchor="bottom"
        open={open}
        ModalProps={{
          onBackdropClick: handleToggle.close,
          keepMounted: true, // Better open performance on mobile.
        }}
        onClose={handleToggle.close}
      >
        <List>
          <MenuItem
            onClick={() => {
              setAddNewFolderDialogOpen(true);
              handleToggle.close();
            }}
          >
            <ListItemIcon>
              <CreateNewFolderOutlined style={{ color: palette.text.secondary }} />
            </ListItemIcon>

            <Typography color="textPrimary">{t<string>('sideBar.newFolder')}</Typography>
          </MenuItem>
          {options.map((option, index) => (
            <MenuItem
              classes={{
                root: clsx(classes.item, classes.listItem),
              }}
              key={index}
              onClick={() => {
                onOptionClick(option.value);
              }}
            >
              {option.icon && (
                <ListItemIcon classes={{ root: classes.listItemIcon }}>{option.icon}</ListItemIcon>
              )}
              <ListItemText classes={{ root: classes.listItemText }}>
                <Typography color="textPrimary">{option.label}</Typography>
                <Typography
                  color="textSecondary"
                  variant="body2"
                  className={classes.textDescription}
                >
                  {option.description}
                </Typography>
              </ListItemText>
            </MenuItem>
          ))}
        </List>
      </Drawer>
      {addNewFolderDialogOpen && (
        <AddNewFolderModal
          open={addNewFolderDialogOpen}
          onClose={() => setAddNewFolderDialogOpen(false)}
          onChange={(newFolderName) => {
            dispatch(documentsActions.createFolder(workspaceId, currentFolderId, newFolderName));
            setAddNewFolderDialogOpen(false);
          }}
        />
      )}
      {subscriptionModalProduct && (
        <SubscriptionModal
          templateDocument={subscriptionModalProduct}
          onClose={() => setSubscriptionModalProduct(null)}
        />
      )}
    </div>
  );
};

export default SpeedDial;
