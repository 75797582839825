import React from 'react';
import { UserManagerPerson } from 'src/models/documentUsersManagement.model';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import HighlightString from './HighlightString';
import { useUserItemDescriptionStyles } from './styles';

interface UserItemTitleProps {
  userData: UserManagerPerson;
  highlightString: string;
}

const UserItemDescription = ({
  userData,
  highlightString,
}: UserItemTitleProps): JSX.Element | null => {
  const classes = useUserItemDescriptionStyles();
  const { t } = useTranslation();
  const {
    isToken,
    isInvited,
    isInvitationToBeSend,
    isExternal,
    email,
    _name,
    workspaceName,
  } = userData;
  if (isToken) return null;

  const isNameSameAsMail = Boolean(_name) && Boolean(email) && _name === email;

  return (
    <>
      {isInvited || isInvitationToBeSend ? (
        <Typography className={classes.description} color="textSecondary" variant="body2">
          {t<string>('documentUserManager.invitationByMail')}
        </Typography>
      ) : (
        <>
          {/* Description: when external workspace and mail is different from name   */}
          {isExternal && !isNameSameAsMail && (
            <Typography className={classes.description} color="textSecondary" variant="body2">
              <HighlightString text={email || ''} highlightString={highlightString} />
              <span>{t<string>('documentUserManager.externalWorkspace')}</span>
              <HighlightString text={workspaceName || ''} highlightString={highlightString} />)
            </Typography>
          )}
          {/* Same workspace and mail different from name */}
          {!isExternal && !isNameSameAsMail && (
            <Typography className={classes.description} color="textSecondary" variant="body2">
              <HighlightString text={email || ''} highlightString={highlightString} />
            </Typography>
          )}
          {/* External workspace and mail is same as name */}
          {isExternal && isNameSameAsMail && (
            <Typography className={classes.description} color="textSecondary" variant="body2">
              <span> {t<string>('documentUserManager.externalWorkspace')}</span>
              <HighlightString text={workspaceName || ''} highlightString={highlightString} />)
            </Typography>
          )}
        </>
      )}
    </>
  );
};
export default UserItemDescription;
