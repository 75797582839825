import spacing from '../spacing';

export default {
  root: {
    height: 'auto',
    '& svg': {
      fontSize: spacing(2.5),
    },
  },
  positionEnd: {
    marginLeft: 8,
    marginRight: 2,
  },
  positionStart: {
    marginLeft: 2,
    marginRight: 8,
  },
};
