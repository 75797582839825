import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import documentManagementActions from 'src/redux/actions/documentManagement.actions';
import { useTranslation } from 'react-i18next';
import { ConfirmDialog } from 'src/components';
import { RequestStatus } from 'src/helpers/reduxReuquest.util';
import certificateManagementActions from 'src/redux/actions/certificateManagement.actions';
import { documentDisplaySelector } from 'src/redux/selectors/documentDisplay.selector';
import { IdentitySelector } from 'src/redux/selectors/identity.selector';
import documentInvitationActions from 'src/redux/actions/documentInvitiation.actions';
import DocumentUserManagerSelector from 'src/redux/selectors/documentUserManager.selector';
import snackbarActions from 'src/redux/actions/snackbar.actions';
import { SNACKBAR_VARIANT } from 'src/models/common.model';
import i18n from 'src/helpers/i18n';
import { DocumentNoDriveType, userComActions } from '../../../../../redux/actions/userCom.actions';

const SignDocument = ({ open, documentId, onClose }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { isOrderSignificant } = useSelector((state) => state.documentInvitationsStore, undefined);
  const calculatedSignersCount = useSelector(
    DocumentUserManagerSelector.selectCalculatedSignersCount,
    undefined
  );
  const { documentSigningStatus, documentSigners } = useSelector(
    (state) => state.documentManagementStore,
    undefined
  );
  const { workspaceId } = useSelector(IdentitySelector.selectWorkspaceDetails, undefined);
  const isCertificate = useSelector(documentDisplaySelector.isCertificate, undefined);

  const isPending = documentSigningStatus === RequestStatus.PENDING;

  // If Error 'INVALID_SIGNERS_COUNT' then update signersCount
  const updateSignersCount = () => {
    dispatch(
      snackbarActions.enqueueSnackbar(
        SNACKBAR_VARIANT.INFO,
        i18n.t('snackbars.common.infoTitle'),
        i18n.t('documentActions.signDocument.toast.waitForCountUpdate')
      )
    );
    dispatch(
      documentInvitationActions.updateSignersCount(
        documentId,
        isOrderSignificant,
        calculatedSignersCount,
        documentSigners,
        () =>
          // retry sign after timeout (time needed to update blockchain), retry again if still not updated
          dispatch(
            documentManagementActions.signDocument(
              documentId,
              () => {
                userComActions.document.docSigned(DocumentNoDriveType.ESign, workspaceId);
                onClose();
              },
              updateSignersCount
            )
          )
      )
    );
  };

  return (
    <ConfirmDialog
      open={open}
      isLoading={isPending}
      variant="success"
      title={t('documentActions.signDocument.header')}
      subtitle={t('documentActions.signDocument.description')}
      applyButtonText={t('documentActions.signDocument.sign')}
      cancelButtonText={t('common.cancel')}
      actionAccept={() => {
        if (isCertificate) {
          dispatch(
            certificateManagementActions.signCertificate({
              documentId,
              workspaceId,
              callback: onClose,
            })
          );
        } else {
          dispatch(
            documentManagementActions.signDocument(
              documentId,
              () => {
                userComActions.document.docSigned(DocumentNoDriveType.ESign, workspaceId);
                onClose();
              },
              updateSignersCount
            )
          );
        }
      }}
      actionCancel={onClose}
    />
  );
};

SignDocument.propTypes = {
  open: PropTypes.bool,
  documentId: PropTypes.string.isRequired,
  onClose: PropTypes.func,
};

SignDocument.defaultProps = {
  open: false,
};

export default SignDocument;
