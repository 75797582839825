import { fade } from '@material-ui/core/styles';
import palette from './palette';

export default {
  leftMenuWidth: 245,
  userMenuMaxWidth: 360,
  fullModalMenuWidth: 350,
  documentEditSidebarWidth: 490,
  headerMobileNavHeight: 76,
  headerNavHeight: 80,
  bottomNavigationHeight: 88,
  tooltipBackground: fade(palette.common.black, 0.6),
  userMenuHoverBackground: fade(palette.primary.main, 0.225),
  breadcrumbsMenuHeight: 40,
};
