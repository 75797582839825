import React from 'react';
import clsx from 'clsx';
import { useStyles } from './styles';

const RecipientItemStep = () => {
  const classes = useStyles();
  return <div className={clsx(classes.itemStep, `itemStepIndicator`)} />;
};

export default RecipientItemStep;
