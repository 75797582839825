import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { rootReducer } from './reducers';

const loggerMiddleware = createLogger();
const composeEnhancers =
  process.env.NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;
const debug = process.env.NODE_ENV !== 'production';
const middleware = [thunkMiddleware, debug && loggerMiddleware].filter(Boolean);
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middleware)));

export default store;
