import React from 'react';
import PropTypes from 'prop-types';
import { Button, Hidden } from '@material-ui/core';
import { ContentHeader, ExtendedGrid } from 'src/components';
import { useStyles } from './styles';

const HeaderAndButton = ({ children, subtitle, onButtonClick, buttonLabel, smallScreen }) => {
  const classes = useStyles();
  return (
    <ExtendedGrid container justifyContentMd="space-between">
      <Hidden smDown>
        <ExtendedGrid item xxs={12} md={8}>
          <ContentHeader subtitle={subtitle}>{children}</ContentHeader>
        </ExtendedGrid>
      </Hidden>
      <ExtendedGrid item xxs={12} md={4} textAlignMd="right">
        <Button
          fullWidth={smallScreen}
          size="large"
          onClick={onButtonClick}
          className={classes.searchButton}
        >
          {buttonLabel}
        </Button>
      </ExtendedGrid>
    </ExtendedGrid>
  );
};

HeaderAndButton.propTypes = {
  children: PropTypes.node,
  subtitle: PropTypes.string,
  onButtonClick: PropTypes.func,
  buttonLabel: PropTypes.string,
  smallScreen: PropTypes.bool,
};

export default HeaderAndButton;
