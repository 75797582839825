import React from 'react';
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon';

export default createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24">
    <path d="M19.9706 13.59L15.8306 11.75C15.7566 11.7229 15.6792 11.7061 15.6006 11.7H15.4506H15.2706C15.2026 11.7108 15.1356 11.7275 15.0706 11.75L10.9306 13.59C10.7548 13.6667 10.6048 13.7923 10.4984 13.9519C10.392 14.1115 10.3337 14.2983 10.3306 14.49C10.3518 15.2489 10.4354 16.0048 10.5806 16.75C10.6179 17.4697 10.8171 18.1716 11.1634 18.8036C11.5097 19.4356 11.9941 19.9812 12.5806 20.4C13.3574 20.923 14.1619 21.4037 14.9906 21.84H15.0406C15.1668 21.8958 15.3026 21.9263 15.4406 21.93C15.5236 21.9398 15.6075 21.9398 15.6906 21.93H15.7606H15.8906C16.7127 21.4609 17.5104 20.9503 18.2806 20.4C18.867 19.9812 19.3514 19.4356 19.6977 18.8036C20.044 18.1716 20.2432 17.4697 20.2806 16.75C20.4257 16.0048 20.5094 15.2489 20.5306 14.49C20.5309 14.3026 20.4786 14.1188 20.3796 13.9597C20.2806 13.8006 20.1389 13.6725 19.9706 13.59ZM18.3506 16.4C18.1206 17.72 17.9206 18.24 17.1706 18.74C16.4206 19.24 15.8306 19.59 15.4206 19.81C15.0106 19.58 14.4206 19.22 13.6706 18.74C12.9206 18.26 12.6706 17.74 12.4906 16.4C12.4006 15.87 12.3406 15.4 12.3106 15.13L15.4206 13.75L18.5306 15.13C18.5006 15.44 18.4406 15.87 18.3506 16.4Z" />
    <path d="M19.1806 7L17.4606 5.26L16.1806 4L14.5006 2.29C14.309 2.10727 14.0554 2.00368 13.7906 2H7.24062C6.38213 2.00264 5.55956 2.34484 4.95251 2.95189C4.34546 3.55893 4.00326 4.38151 4.00062 5.24V18.71C3.98409 19.5516 4.29675 20.3664 4.87203 20.9809C5.44731 21.5954 6.23976 21.9611 7.08062 22H8.89062C9.02423 22.0088 9.15819 21.989 9.28352 21.9419C9.40885 21.8948 9.52267 21.8214 9.61735 21.7267C9.71204 21.632 9.7854 21.5182 9.83253 21.3929C9.87965 21.2676 9.89946 21.1336 9.89062 21C9.90013 20.8754 9.88443 20.7502 9.84447 20.6318C9.8045 20.5134 9.7411 20.4043 9.65803 20.311C9.57496 20.2176 9.47393 20.142 9.36098 20.0886C9.24803 20.0351 9.12547 20.005 9.00062 20H7.18062C6.86581 19.9796 6.5706 19.8401 6.35509 19.6097C6.13957 19.3793 6.01997 19.0755 6.02062 18.76V5.24C6.02058 4.91457 6.14848 4.60216 6.37671 4.37018C6.60495 4.1382 6.91523 4.00525 7.24062 4H11.8506V8.61C11.8355 8.69601 11.8355 8.78399 11.8506 8.87C11.9092 9.08816 12.04 9.28006 12.2215 9.41443C12.4031 9.5488 12.6249 9.61773 12.8506 9.61H18.4606C18.7258 9.61 18.9802 9.50464 19.1677 9.31711C19.3553 9.12957 19.4606 8.87522 19.4606 8.61V7.68C19.457 7.426 19.3569 7.18288 19.1806 7ZM13.8506 4.48L16.9506 7.57H13.8506V4.48Z" />
  </svg>,
  'FilesWithShield'
);
