export default {
  root: {
    height: 'auto',
  },
  head: {
    height: '1.8rem',
  },
  footer: {
    height: '1.8rem',
  },
};
