import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ palette, spacing }) =>
    createStyles({
      root: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: spacing(0.5),
      },
      action: {
        paddingLeft: spacing(2),
        '& button': {
          paddingLeft: spacing(1),
          paddingRight: spacing(1),
          marginRight: spacing(-1),
          '& svg': {
            color: palette.text.disabled,
          },
        },
      },
    }),
  { index: 0 }
);
