import { lighten } from '@material-ui/core/styles';
import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ palette, spacing, shape }) =>
    createStyles({
      layer: {
        position: 'fixed',
        pointerEvents: 'none',
        zIndex: 100,
        top: 0,
        left: 0,
        width: 'auto',
        height: '100%',
        borderRadius: shape.borderRadius * 0.5,
      },
      dragItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        minHeight: 48,
        width: 'auto',
        padding: spacing(1.75, 1),
        marginBottom: 1,
        borderRadius: shape.borderRadius * 0.5,
        backgroundColor: lighten(palette.primary.light, 0.85),
      },
      dragItemCell: {
        marginRight: 5,
      },
    }),
  { index: 0 }
);
