import React from 'react';
import { tabType } from 'src/components/ButtonTabs/ButtonTabs';
import i18n from 'src/helpers/i18n';
import { Mail as UnreadIcon, DraftsOutlined as ReadIcon } from '@material-ui/icons';

export enum NotificationTabType {
  Unread,
  Read,
}

export const notificationTabs: tabType[] = [
  {
    label: i18n.t<string>('notifications.tabs.new'),
    icon: <UnreadIcon />,
    value: NotificationTabType.Unread,
  },
  {
    label: i18n.t<string>('notifications.tabs.read'),
    icon: <ReadIcon />,
    value: NotificationTabType.Read,
  },
];
