import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useStyles } from './styles';

const Gap = ({ className: classNameProp, size }) => {
  const classes = useStyles();
  return (
    <div
      className={clsx(
        classes.root,
        size && {
          [classes[size]]: size,
        },
        classNameProp
      )}
    />
  );
};

Gap.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(['default', 'mini', 'small', 'medium', 'large', 'cardHalf', 'card']),
};
Gap.defaultProps = {
  size: 'default',
};

export default Gap;
