import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ breakpoints }) =>
    createStyles({
      root: {
        width: '100%',
        textAlign: 'center',
        textTransform: 'uppercase',
      },
      button: {
        minWidth: breakpoints.values.xs / 2,
      },
    }),
  { index: 0 }
);
