import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Button, Checkbox, FormControlLabel, TextField, Typography } from '@material-ui/core';
import { CountrySelect, SmallContentWithScrollbar } from 'src/components';
import { useTranslation } from 'react-i18next';
import useForm from 'src/helpers/hooks/useForm';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  ({ palette, spacing, typography }) => ({
    content: {
      '& > *:not(:last-child)': {
        marginBottom: spacing(2),
      },
    },
    additionalLabel: {
      lineHeight: 'inherit',
    },
    checkboxRoot: {
      marginLeft: '-8px !important',
    },
    checkboxFormControlRoot: {
      marginRight: 0,
    },
    checkboxFormControlLabel: {
      fontSize: typography.subtitle2.fontSize,
      color: palette.text.secondary,
      textAlign: 'justify',
    },
    checkedCheckboxFormControlLabel: {
      color: palette.primary.main,
    },
  }),
  { index: 0 }
);

const EditWorkspace = (props) => {
  const { workspace, update, isPending } = props;
  const classes = useStyles({});
  const { t } = useTranslation();
  const [newWorkspace, updateWorkspace] = useState(workspace);
  const [representingCompany, setRepresentingCompany] = useState(false);

  const validateForm = (formData) => {
    const formErrors = {};

    if (!formData.address || formData.address.trim().length === 0) {
      formErrors.address = t('workspacePage.editModals.valueCannotBeEmpty');
    }

    if (!formData.postalCode || formData.postalCode.trim().length === 0) {
      formErrors.postalCode = t('workspacePage.editModals.valueCannotBeEmpty');
    }

    if (!formData.city || formData.city.trim().length === 0) {
      formErrors.city = t('workspacePage.editModals.valueCannotBeEmpty');
    }

    if (!formData.country || formData.country.trim().length === 0) {
      formErrors.country = t('workspacePage.editModals.valueCannotBeEmpty');
    }

    return formErrors;
  };

  const handleUpdate = () => {
    update({ ...workspace, data: { ...values } });
  };

  const { values, errors, handleChange, handleSubmit, setValue } = useForm(
    handleUpdate,
    validateForm,
    {
      fullName: workspace.data.fullName,
      country: workspace.data.country,
      address: workspace.data.address,
      postalCode: workspace.data.postalCode,
      city: workspace.data.city,
      number: workspace.data.number,
    }
  );

  const setCountry = (country) => {
    if (country) {
      updateWorkspace({ data: { countryCode: country.code } });
      setValue('country', country.label, { shouldDirty: true });
    } else {
      updateWorkspace({ data: { countryCode: '' } });
      setValue('country', '');
    }
  };

  const additionalLabel = (text) => (
    <Typography
      variant="subtitle2"
      component="span"
      color="secondary"
      className={classes.additionalLabel}
    >
      ({text})
    </Typography>
  );

  return (
    <SmallContentWithScrollbar
      endContent={
        <Button size="large" type="submit" fullWidth disabled={isPending || !representingCompany}>
          {t('workspacePage.editModals.btnChangeData')}
        </Button>
      }
      hasForm
      formProps={{
        noValidate: true,
        autoComplete: 'off',
        onSubmit: representingCompany ? handleSubmit : undefined,
      }}
      classes={{ content: classes.content }}
    >
      <TextField
        placeholder={t('workspacePage.editModals.labelNameCompany')}
        label={
          <>
            {t('workspacePage.editModals.labelNameCompany')}&nbsp;
            {additionalLabel(t('workspacePage.editModals.labelOptional'))}
          </>
        }
        fullWidth
        name="fullName"
        value={values.fullName}
        onChange={handleChange}
        disabled={isPending}
        error={errors.fullName?.length > 0}
        helperText={errors.fullName}
      />

      <CountrySelect
        label={t('workspacePage.editModals.labelCountry')}
        country={values.country}
        name="country"
        countryCode={newWorkspace.data.countryCode}
        changeCountry={setCountry}
        disabled={isPending}
        error={errors.country?.length > 0}
        helperText={errors.country}
      />

      <TextField
        placeholder={t('workspacePage.editModals.labelAddress')}
        label={t('workspacePage.editModals.labelAddress')}
        fullWidth
        name="address"
        value={values.address}
        onChange={handleChange}
        disabled={isPending}
        error={errors.address?.length > 0}
        helperText={errors.address}
      />

      <TextField
        placeholder={t('workspacePage.editModals.labelPostcode')}
        label={t('workspacePage.editModals.labelPostcode')}
        fullWidth
        name="postalCode"
        value={values.postalCode}
        onChange={handleChange}
        disabled={isPending}
        error={errors.postalCode?.length > 0}
        helperText={errors.postalCode}
      />

      <TextField
        placeholder={t('workspacePage.editModals.labelCity')}
        label={t('workspacePage.editModals.labelCity')}
        fullWidth
        name="city"
        value={values.city}
        onChange={handleChange}
        disabled={isPending}
        error={errors.city?.length > 0}
        helperText={errors.city}
      />

      <TextField
        placeholder={t('workspacePage.editModals.labelVat')}
        label={
          <>
            {t('workspacePage.editModals.labelVat')}&nbsp;
            {additionalLabel(t('workspacePage.editModals.labelOptional'))}
          </>
        }
        fullWidth
        name="number"
        value={values.number}
        onChange={handleChange}
        disabled={isPending}
        error={errors.number?.length > 0}
        helperText={errors.number}
      />

      <FormControlLabel
        control={
          <Checkbox
            checked={representingCompany}
            onChange={() => setRepresentingCompany(!representingCompany)}
            classes={{
              root: classes.checkboxRoot,
            }}
          />
        }
        label={t('workspacePage.editModals.labelRepresentingCompany')}
        classes={{
          root: classes.checkboxFormControlRoot,
          label: clsx(
            classes.checkboxFormControlLabel,
            representingCompany && classes.checkedCheckboxFormControlLabel
          ),
        }}
      />
    </SmallContentWithScrollbar>
  );
};

EditWorkspace.propTypes = {
  workspace: PropTypes.shape({
    name: PropTypes.string,
    data: PropTypes.shape({
      fullName: PropTypes.string,
      country: PropTypes.string,
      address: PropTypes.string,
      postalCode: PropTypes.string,
      city: PropTypes.string,
      number: PropTypes.string,
    }),
  }),
  update: PropTypes.func,
  isPending: PropTypes.bool,
};

export default EditWorkspace;
