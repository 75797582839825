import React from 'react';
import { useSelector } from 'react-redux';
import WaitingForBlockchain from 'src/components/WaitingForBlockchain/WaitingForBlockchain';
import { CertificateSteps } from 'src/models/certificate.model';
import SelectQuantityType from './SelectQuantityType/SelectQuantityType';
import MapSingleFields from './MapSingleFields/MapSingleFields';
import ImportMultipleData from './ImportMultipleData/ImportMultipleData';
import MapMultipleData from './MapMultipleData/MapMultipleData';
import UploadAdditionalDocuments from './UploadAdditionalDocuments';
import SelectTemplateType from './SelectTemplateType/SelectTemplateType';
import TemplatePreview from './TemplatePreview/TemplatePreview';
import ChooseLocation from './ChooseLocation/ChooseLocation';
import InvitePeople from './InvitePeople/InvitePeople';
import CreateFilesForCertProgressScreen from './CreateFilesForCertProgressScreen/CreateFilesForCertProgressScreen';

const StepsResolver = () => {
  const { currentStep } = useSelector((state) => state.certificateCreatorStore, undefined);

  const CertificateCreatorSteps = {
    [CertificateSteps.SelectTemplateType]: <SelectTemplateType />,
    [CertificateSteps.SelectQuantityType]: <SelectQuantityType />,
    [CertificateSteps.MapSingleFields]: <MapSingleFields />,
    [CertificateSteps.TemplatePreview]: <TemplatePreview />,
    [CertificateSteps.ImportMultipleData]: <ImportMultipleData />,
    [CertificateSteps.MapMultipleData]: <MapMultipleData />,
    [CertificateSteps.ChooseLocation]: <ChooseLocation />,
    [CertificateSteps.UploadAdditionalDocuments]: <UploadAdditionalDocuments />,
    [CertificateSteps.InvitePeople]: <InvitePeople />,
    [CertificateSteps.ProgressScreen]: <WaitingForBlockchain />,
    [CertificateSteps.CreatingCertificatesProgressScreen]: <CreateFilesForCertProgressScreen />,
  };

  return CertificateCreatorSteps[currentStep];
};

export default StepsResolver;
