import { Box, Button, Modal, Typography, useMediaQuery } from '@material-ui/core';
import { EVENT_TYPES } from 'src/customEvents';
import React, { useState, useRef, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { fromEvent } from 'rxjs';

import { useStyles } from './styles';

const CertificationCreationModal = () => {
  const [open, setOpen] = useState(false);
  const subRef = useRef(null);
  const { t } = useTranslation();

  const successHandler = (event) => {
    console.log('event.detail -', event.detail);
    setOpen(true);
  };

  const errorHandler = (error) => {
    throw error;
  };

  useEffect(() => {
    subRef.current = fromEvent(document, EVENT_TYPES.OPEN_CERTIFICATE_ERROR_MODAL).subscribe({
      next: successHandler,
      error: errorHandler,
    });

    return () => {
      subRef.current?.unsubscribe();
    };
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const classes = useStyles();

  const smallScreen = useMediaQuery(({ breakpoints }) => breakpoints.down('sm'));

  return (
    <Modal open={open} onClose={handleClose}>
      <Box className={classes.modal}>
        <Typography variant={smallScreen ? 'h3' : 'h2'} className={classes.header}>
          {t('createCertificate.exceedLimit')}
        </Typography>
        <Typography
          id="modal-modal-description"
          sx={{ mt: 2 }}
          className={classes.message}
          variant="body1"
        >
          {t('createCertificate.exceedLimitMessage')}
        </Typography>

        <div>
          <Button
            size="large"
            type="button"
            fullWidth
            className={classes.errorButton}
            color="error"
          >
            {t('createCertificate.exceedLimitConfirmation')}
          </Button>
          <Button size="large" type="button" fullWidth variant="text" onClick={handleClose}>
            {t('common.cancel')}
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default CertificationCreationModal;
