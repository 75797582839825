import { RootState } from 'src/redux/reducers';
import {
  DocumentInvitationMemberRole,
  DocumentMember,
  DocumentMemberRole,
  DocumentUserAction,
  InvitedDocumentMember,
  UserManagerPerson,
} from 'src/models/documentUsersManagement.model';
import { SingleEntity, WorkspaceGroup, WorkspaceUser } from 'src/models/identity.model';
import { IdentitySelector } from './identity.selector';

const invitationMemberRoleToDocumentMemberRole = (
  role: DocumentInvitationMemberRole | DocumentMemberRole
): DocumentMemberRole => {
  switch (role) {
    case DocumentInvitationMemberRole.ADMINS:
      return DocumentMemberRole.ADMIN;
    case DocumentInvitationMemberRole.SIGNERS:
      return DocumentMemberRole.SIGNER;
    case DocumentInvitationMemberRole.EDITORS:
      return DocumentMemberRole.EDITOR;
    case DocumentInvitationMemberRole.AUDITORS:
      return DocumentMemberRole.AUDITOR;
    case DocumentInvitationMemberRole.VIEWERS:
      return DocumentMemberRole.VIEWER;
    case DocumentInvitationMemberRole.HOLDER:
      return DocumentMemberRole.HOLDER;
    default:
      return role;
  }
};

export const documentMemberRoleToInvitationRole = (
  role: DocumentMemberRole | undefined
): DocumentInvitationMemberRole => {
  switch (role) {
    case DocumentMemberRole.ADMIN:
      return DocumentInvitationMemberRole.ADMINS;
    case DocumentMemberRole.SIGNER:
      return DocumentInvitationMemberRole.SIGNERS;
    case DocumentMemberRole.EDITOR:
      return DocumentInvitationMemberRole.EDITORS;
    case DocumentMemberRole.AUDITOR:
      return DocumentInvitationMemberRole.AUDITORS;
    case DocumentMemberRole.VIEWER:
      return DocumentInvitationMemberRole.VIEWERS;
    case DocumentMemberRole.HOLDER:
      return DocumentInvitationMemberRole.HOLDER;
    default:
      return DocumentInvitationMemberRole.VIEWERS;
  }
};

// Remove disable rule when all state typed
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getUserDetails = (state: any, documentMember: DocumentMember): UserManagerPerson => {
  const { identityStore, documentUsersManagerStore } = state;
  const { workspaceUsers, workspaceGroups } = identityStore;

  // check in workspace users
  const matchedUserIdentity = workspaceUsers?.find(
    (user: WorkspaceUser) => user.identityId === documentMember.id
  );

  if (matchedUserIdentity) {
    // assign value for UserManagerPerson
    return {
      _id: matchedUserIdentity.identityId,
      email: matchedUserIdentity.email,
      contactEmail: matchedUserIdentity.contactEmail,
      _name: matchedUserIdentity.name,
      active: matchedUserIdentity.active,
      role: invitationMemberRoleToDocumentMemberRole(documentMember.role),
      _status: documentMember.status,
      isGroup: false,
      isInvited: false,
      isInvitationToBeSend: false,
      isExternal: false,
    };
  }
  // check in workspace groups
  const matchedGroupIdentity = workspaceGroups.find(
    (group: WorkspaceGroup) => group.info.groupId === documentMember.id
  );
  if (matchedGroupIdentity) {
    // assign value for UserManagerPerson
    return {
      _id: matchedGroupIdentity.info.groupId,
      creatorId: matchedGroupIdentity.info.creatorId,
      _name: matchedGroupIdentity.info.name,
      description: matchedGroupIdentity.info.description,
      active: matchedGroupIdentity.info.active,
      role: invitationMemberRoleToDocumentMemberRole(documentMember.role),
      _status: documentMember.status,
      isGroup: true,
      isInvited: false,
      isInvitationToBeSend: false,
      isExternal: false,
    };
  }
  const { externalGroupEntities, externalContactEntities } = documentUsersManagerStore;
  if (externalContactEntities[documentMember.id] || externalGroupEntities[documentMember.id]) {
    const contactData = externalContactEntities[documentMember.id];
    // check identities from contact book
    if (contactData) {
      return {
        _id: contactData.identityId,
        _name: contactData.identityName,
        email: contactData.email,
        active: contactData.active,
        role: invitationMemberRoleToDocumentMemberRole(documentMember.role),
        _status: documentMember.status,
        isGroup: false,
        isInvited: false,
        isInvitationToBeSend: false,
        isExternal: true,
        workspaceId: contactData.workspaceId,
        workspaceName: contactData.workspaceName,
      };
    }
    const groupData = externalGroupEntities[documentMember.id];
    // check groups from contact book
    if (groupData) {
      return {
        _id: groupData.groupId,
        active: groupData.active,
        role: invitationMemberRoleToDocumentMemberRole(documentMember.role),
        _status: documentMember.status,
        _name: groupData.groupName,
        isGroup: true,
        isInvited: false,
        isInvitationToBeSend: false,
        isExternal: true,
        workspaceId: groupData.workspaceId,
        workspaceName: groupData.workspaceName,
      };
    }
  }

  return {
    _id: undefined,
    email: undefined,
    contactEmail: undefined,
    _name: undefined,
    active: false,
    role: DocumentMemberRole.VIEWER,
    _status: undefined,
    isGroup: false,
    isInvited: false,
    isInvitationToBeSend: false,
    isExternal: false,
  };
};

const selectInvitedDocumentMembersWithDetails = (state: RootState): UserManagerPerson[] =>
  state.documentUsersManagerStore.invitedDocumentMembers.map(
    (invitedMember: InvitedDocumentMember) => ({
      email: invitedMember.mail || '',
      active: true,
      role: invitationMemberRoleToDocumentMemberRole(invitedMember.role),
      isToken: invitedMember.mail === null,
      isGroup: false,
      isInvited: true,
      isInvitationToBeSend: false,
      isExternal: false,
    })
  );

const selectSearchedEntitiesWithDetails = (state: RootState): UserManagerPerson[] => {
  const currentWorkspace = IdentitySelector.selectWorkspaceId(state);
  return state.documentUsersManagerStore.searchedEntities.map((entity: SingleEntity) => ({
    active: entity.active,
    email: entity.email,
    _id: entity.entityId,
    _name: entity.entityName,
    isGroup: entity.group,
    workspaceId: entity.workspaceId,
    workspaceName: entity.workspaceName,
    isInvited: false,
    isInvitationToBeSend: false,
    isExternal: entity.workspaceId !== currentWorkspace,
  }));
};

const selectSignersInOrder = (state: RootState): DocumentMember[] => {
  const signers = state.documentUsersManagerStore.documentMembers.filter(
    (member) => invitationMemberRoleToDocumentMemberRole(member.role) === DocumentMemberRole.SIGNER
  );
  const idsOrder = state.documentUsersManagerStore.documentSigners.ids;

  const sortFunc = (a: DocumentMember, b: DocumentMember) =>
    idsOrder.indexOf(a.id) - idsOrder.indexOf(b.id);

  return signers.sort(sortFunc);
};

const selectDocumentHolder = (state: RootState): DocumentMember[] =>
  state.documentUsersManagerStore.documentMembers.filter(
    (member) => invitationMemberRoleToDocumentMemberRole(member.role) === DocumentMemberRole.HOLDER
  );

const selectOtherRoles = (state: RootState): DocumentMember[] =>
  state.documentUsersManagerStore.documentMembers.filter(
    (member) =>
      invitationMemberRoleToDocumentMemberRole(member.role) !== DocumentMemberRole.HOLDER &&
      invitationMemberRoleToDocumentMemberRole(member.role) !== DocumentMemberRole.SIGNER
  );

const selectSignersInOrderWithDetails = (state: RootState): UserManagerPerson[] => {
  const sortedSigners = selectSignersInOrder(state);
  const invitedSignersWithDetails = selectInvitedDocumentMembersWithDetails(state).filter(
    (member) => member.role === DocumentMemberRole.SIGNER
  );

  return [
    ...sortedSigners.map((item) => getUserDetails(state, item)),
    ...invitedSignersWithDetails,
  ];
};

const selectOtherRolesWithDetails = (state: RootState): UserManagerPerson[] => {
  const otherRoles = selectOtherRoles(state);
  const invitedOtherRolesWithDetails = selectInvitedDocumentMembersWithDetails(state).filter(
    (member) =>
      member.role !== DocumentMemberRole.SIGNER && member.role !== DocumentMemberRole.HOLDER
  );

  return [
    ...otherRoles.map((item) => getUserDetails(state, item)),
    ...invitedOtherRolesWithDetails,
  ];
};

const selectHoldersWithDetails = (state: RootState): UserManagerPerson[] => {
  const holders = selectDocumentHolder(state);
  const invitedHoldersWithDetails = selectInvitedDocumentMembersWithDetails(state).filter(
    (member) => member.role === DocumentMemberRole.HOLDER
  );

  return [...holders.map((item) => getUserDetails(state, item)), ...invitedHoldersWithDetails];
};

const hasDocumentActionPermission = (state: RootState, action: DocumentUserAction): boolean =>
  state.documentUsersManagerStore.availableDocumentActions.includes(action);

type selectDocumentActionType = {
  canRejectDocCert: boolean;
  canEditFileDoc: boolean;
  canAddSignerDocCert: boolean;
  canAddSignerByInvitationDocCert: boolean;
  canAddUserDocCert: boolean;
  canModifyRoleDocCert: boolean;
  canModifySignersDocCert: boolean;
  canSignDocCert: boolean;
  canRejectSignatureDoc: boolean;
  canLinkDoc: boolean;
  canUnlinkDoc: boolean;
  canViewDoc: boolean;
  canRenameDoc: boolean;
  canUpdateTagsDoc: boolean;
  canSetTransferabilityCert: boolean;
  canInvalidateTemporarilyCert: boolean;
  canValidateCert: boolean;
  canInvalidateCert: boolean;
  canChangeHolderCert: boolean;
};

const selectDocumentAction = (state: RootState): selectDocumentActionType => ({
  canRejectDocCert: hasDocumentActionPermission(state, DocumentUserAction.RejectDocument),
  canEditFileDoc: hasDocumentActionPermission(state, DocumentUserAction.EditFile),
  canAddSignerDocCert: hasDocumentActionPermission(state, DocumentUserAction.AddSigner),
  canAddSignerByInvitationDocCert: hasDocumentActionPermission(
    state,
    DocumentUserAction.AddSignerByInvitation
  ),
  canAddUserDocCert: hasDocumentActionPermission(state, DocumentUserAction.AddUser),
  canModifyRoleDocCert: hasDocumentActionPermission(state, DocumentUserAction.ModifyRole),
  canModifySignersDocCert: hasDocumentActionPermission(state, DocumentUserAction.ModifySigners),
  canSignDocCert: hasDocumentActionPermission(state, DocumentUserAction.Sign),
  canRejectSignatureDoc: hasDocumentActionPermission(state, DocumentUserAction.RejectSignature),
  canLinkDoc: hasDocumentActionPermission(state, DocumentUserAction.LinkDocument),
  canUnlinkDoc: hasDocumentActionPermission(state, DocumentUserAction.UnlinkDocument),
  canViewDoc: hasDocumentActionPermission(state, DocumentUserAction.View),
  canRenameDoc: hasDocumentActionPermission(state, DocumentUserAction.RenameDocument),
  canUpdateTagsDoc: hasDocumentActionPermission(state, DocumentUserAction.UpdateTags),
  canSetTransferabilityCert: hasDocumentActionPermission(
    state,
    DocumentUserAction.SetTransferability
  ),
  canInvalidateTemporarilyCert: hasDocumentActionPermission(
    state,
    DocumentUserAction.InvalidateTemporarilyDocument
  ),
  canValidateCert: hasDocumentActionPermission(state, DocumentUserAction.ValidateDocument),
  canInvalidateCert: hasDocumentActionPermission(state, DocumentUserAction.InvalidateDocument),
  canChangeHolderCert: hasDocumentActionPermission(state, DocumentUserAction.ChangeHolder),
});

const selectCalculatedSignersCount = (state: RootState): number => {
  const {
    documentSigners: { ids },
    invitedDocumentMembers,
  } = state.documentUsersManagerStore;
  return (
    invitedDocumentMembers.filter(
      (el) => invitationMemberRoleToDocumentMemberRole(el.role) === DocumentMemberRole.SIGNER
    ).length + ids.length
  );
};

const selectDraftSignersCount = (state: RootState): number => {
  const { draftRoles } = state.documentUsersManagerStore;
  return draftRoles[DocumentMemberRole.SIGNER].length;
};

const selectDraftOtherRolesCount = (state: RootState): number => {
  const { draftRoles } = state.documentUsersManagerStore;
  const draftHoldersCount = draftRoles[DocumentMemberRole.HOLDER].length;
  const draftAdminsCount = draftRoles[DocumentMemberRole.ADMIN].length;
  const draftAuditorsCount = draftRoles[DocumentMemberRole.AUDITOR].length;
  const draftEditorsCount = draftRoles[DocumentMemberRole.EDITOR].length;
  const draftViewersCount = draftRoles[DocumentMemberRole.VIEWER].length;

  return (
    draftHoldersCount +
    draftAdminsCount +
    draftAuditorsCount +
    draftEditorsCount +
    draftViewersCount
  );
};

const selectRecipientsUpdated = (state: RootState, roles: DocumentMemberRole[]): boolean => {
  // Destructure the needed values from the state.
  const {
    documentUsersManagerStore: {
      documentMembers,
      invitedDocumentMembers,
      draftRoles,
      draftTransferability,
    },
    documentDisplayStore: { document },
  } = state;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const documentInfo: any = document as any;

  // Get the transferable attribute from the document object.
  const transferable = documentInfo?.additionalAttributes?.transferable;
  // Function to count the number of a certain role in a set.
  const rolesCountInSet = (
    set: Array<DocumentMember | InvitedDocumentMember>,
    role: DocumentMemberRole
  ) => set.filter((el) => invitationMemberRoleToDocumentMemberRole(el.role) === role).length;

  // Check if there are roles in the draft for the roles we care about.
  const draftUpdated = roles.some(
    (role) => draftRoles[invitationMemberRoleToDocumentMemberRole(role)].length > 0
  );

  // If there are no roles in the draft, return true.
  if (!draftUpdated) return true;

  // Function to check if the number of a role in draftRoles is the same as in documentMembers and invitedDocumentMembers.
  const isRoleCountSame = (role: DocumentMemberRole) =>
    draftRoles[invitationMemberRoleToDocumentMemberRole(role)].length ===
    rolesCountInSet(documentMembers, role) + rolesCountInSet(invitedDocumentMembers, role);

  // Check if the number of each role in draftRoles is the same as in documentMembers and invitedDocumentMembers
  // and if the transferable property is either undefined or the same as in draftTransferability.
  return (
    roles.every(isRoleCountSame) &&
    (transferable === undefined || draftTransferability === transferable)
  );
};

const DocumentUserManagerSelector = {
  selectSignersInOrder,
  selectSignersInOrderWithDetails,
  selectDocumentHolder,
  selectHoldersWithDetails,
  selectOtherRoles,
  selectOtherRolesWithDetails,
  selectSearchedEntitiesWithDetails,
  selectDocumentAction,
  selectCalculatedSignersCount,
  selectRecipientsUpdated,
  selectDraftSignersCount,
  selectDraftOtherRolesCount,
};

export default DocumentUserManagerSelector;
