import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Fab } from '@material-ui/core';
import clsx from 'clsx';
import { useStyles } from './styles';

type ScrollableXaxisProps = {
  children: JSX.Element;
};

const ScrollableXaxis = ({ children }: ScrollableXaxisProps): JSX.Element => {
  const classes = useStyles();
  const elementRef = useRef(null);
  const [leftArrowDisabled, setLeftArrowDisabled] = useState(true);
  const [rightArrowDisabled, setRightArrowDisabled] = useState(true);
  const element = elementRef.current as HTMLDivElement | null;

  const updateArrows = useCallback(() => {
    if (element === null) return;

    if (element.scrollLeft === 0) {
      setLeftArrowDisabled(true);
    } else {
      setLeftArrowDisabled(false);
    }

    if (element.clientWidth + element.scrollLeft === element.scrollWidth) {
      setRightArrowDisabled(true);
    } else {
      setRightArrowDisabled(false);
    }
  }, [element]);
  const handleHorizantalScroll = (
    element: HTMLDivElement | null,
    speed: number,
    distance: number,
    step: number
  ) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      if (element === null) return;

      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
      updateArrows();
    }, speed);
  };

  useEffect(() => {
    updateArrows();
    window.addEventListener('resize', updateArrows);
    return () => window.removeEventListener('resize', updateArrows);
  }, [element?.scrollWidth, updateArrows]);

  return (
    <div className={classes.wrapper}>
      <Fab
        color="primary"
        size="small"
        className={clsx(classes.leftArrow, leftArrowDisabled && classes.disabledArrow)}
        disabled={leftArrowDisabled}
        onClick={() => {
          handleHorizantalScroll(elementRef.current, 25, 100, -10);
        }}
      >
        {`<`}
      </Fab>
      <div ref={elementRef} className={classes.scrollContent}>
        {children}
      </div>
      <Fab
        className={clsx(classes.rightArrow, rightArrowDisabled && classes.disabledArrow)}
        color="primary"
        size="small"
        disabled={rightArrowDisabled}
        onClick={() => {
          handleHorizantalScroll(elementRef.current, 25, 100, 10);
        }}
      >
        {`>`}
      </Fab>
    </div>
  );
};

export default ScrollableXaxis;
