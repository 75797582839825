function getToken(mode = 'token') {
  const tokenData = localStorage.getItem(mode);
  if (tokenData) {
    const token = JSON.parse(tokenData);
    if (token.expiresAt < Date.now()) {
      localStorage.removeItem(mode);
      return false;
    }
    return token;
  }
  return false;
}

function getAnyToken() {
  const token = getToken();
  return token || getToken('shortToken');
}

function updateToken(token, mode = 'token') {
  localStorage.setItem(mode, JSON.stringify(token));
}

const tokenHelper = {
  getToken,
  getAnyToken,
  updateToken,
};

export default tokenHelper;
