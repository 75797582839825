import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { ButtonBase, Fab, Radio, Tooltip, Typography } from '@material-ui/core';
import { Info as InfoIcon } from 'src/components/CustomIcon';
import { useStyles } from './styles';

const ListChoiceButton = React.forwardRef(
  (
    {
      className: classNameProp,
      fullWidth,
      icon,
      label,
      additionalLabel,
      description,
      hasModalInfo,
      onClick,
      onMoreInfoClick,
      disableFocusRipple,
      value,
      selected,
      disabled,
      iconColor,
      iconVariant,
      radio,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const Component = !disabled ? ButtonBase : 'div';
    const infoButtonRef = React.createRef();

    const additionalButtonProps = !disabled
      ? {
          onClick: (event) => {
            if (
              (hasModalInfo &&
                event.target !== infoButtonRef.current &&
                typeof onClick === 'function') ||
              !hasModalInfo
            ) {
              onClick();
            }
          },
          value,
          disabled,
          raised: String(selected),
          focusRipple: !disableFocusRipple,
        }
      : {};

    return (
      <Component
        ref={ref}
        className={clsx(
          classes.root,
          classNameProp,
          disabled ? classes.disabled : classes.clickable,
          selected && classes.selected,
          {
            [classes.fullWidth]: fullWidth,
          }
        )}
        {...additionalButtonProps}
      >
        {icon && (
          <div
            className={clsx(
              classes.icon,
              iconVariant && classes.iconVariant,
              selected && !iconColor && classes.iconSelected
            )}
            style={iconColor && { color: iconColor }}
          >
            {icon}
          </div>
        )}

        <div className={classes.content}>
          {label && (
            <div className={classes.label}>
              <Typography variant="h6" color={selected || !radio ? 'textPrimary' : 'textSecondary'}>
                <strong>{label}</strong>
                {additionalLabel && (
                  <>
                    {' '}
                    <em>{`(${additionalLabel})`}</em>
                  </>
                )}
              </Typography>

              {hasModalInfo && typeof onMoreInfoClick === 'function' && (
                <Tooltip title={t('selectionSignature.tooltip')}>
                  <Fab
                    ref={infoButtonRef}
                    className={clsx(classes.informationModalOpen)}
                    component="div"
                    size="small"
                    onClick={() => onMoreInfoClick()}
                  >
                    <InfoIcon />
                  </Fab>
                </Tooltip>
              )}
            </div>
          )}
          {description && (
            <Typography
              variant="subtitle2"
              component="span"
              className={clsx(label && classes.description)}
            >
              {description}
            </Typography>
          )}
        </div>

        {radio && (
          <div>
            <Radio checked={selected} />
          </div>
        )}
      </Component>
    );
  }
);

ListChoiceButton.propTypes = {
  className: PropTypes.string,
  label: PropTypes.node,
  additionalLabel: PropTypes.string,
  description: PropTypes.string,
  hasModalInfo: PropTypes.bool,
  fullWidth: PropTypes.bool,
  icon: PropTypes.node,
  iconColor: PropTypes.string,
  iconVariant: PropTypes.bool,
  onClick: PropTypes.func,
  onMoreInfoClick: PropTypes.func,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  radio: PropTypes.bool,
  disableFocusRipple: PropTypes.bool,
};

ListChoiceButton.defaultProps = {
  disableFocusRipple: false,
  disabled: false,
  radio: false,
  selected: false,
  hasModalInfo: false,
};

export default ListChoiceButton;
