import React, { MouseEvent, useEffect, useState } from 'react';
import { Button, Checkbox, Divider, ListItemIcon, Menu, MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { DocumentStatus } from 'src/models/documents.model';
import { useStyles } from './styles';
import DocumentStatusChip from '../DocumentStatusChip/DocumentStatusChip';

type DocumentStatusDropdownProps = {
  children: JSX.Element;
  initialStatusTypes: DocumentStatus[];
  onSubmit: (statuses: DocumentStatus[]) => void;
};

const DocumentStatusDropdown = ({
  initialStatusTypes,
  onSubmit,
  children,
}: DocumentStatusDropdownProps): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [editingStatusTypes, setEditingStatusTypes] = useState(initialStatusTypes);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const allSelected = Object.values(DocumentStatus).length === editingStatusTypes.length;

  const someSelected = editingStatusTypes.length > 0;

  const handleClick = (e: MouseEvent<HTMLElement>) => {
    if (e) setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setEditingStatusTypes(initialStatusTypes);
  };

  const handleChange = (docsStatus: DocumentStatus) => {
    if (editingStatusTypes.includes(docsStatus))
      setEditingStatusTypes(editingStatusTypes.filter((el) => el !== docsStatus));
    else setEditingStatusTypes([...editingStatusTypes, docsStatus]);
  };

  const handleSelectAll = () => {
    setEditingStatusTypes(allSelected ? [] : Object.values(DocumentStatus));
  };

  const onSubmitClick = () => {
    onSubmit(editingStatusTypes);
    setAnchorEl(null);
  };

  const isOpen = Boolean(anchorEl);

  useEffect(() => {
    setEditingStatusTypes(initialStatusTypes);
  }, [initialStatusTypes]);

  return (
    <>
      <div aria-hidden="true" onClick={handleClick}>
        {children}
      </div>
      <Menu
        open={isOpen}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        autoFocus={false}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handleClose}
      >
        <MenuItem className={classes.menuItemAll} onClick={handleSelectAll} divider={false}>
          <ListItemIcon>
            <Checkbox
              color="primary"
              indeterminate={someSelected && !allSelected}
              checked={allSelected}
            />
          </ListItemIcon>
          {t('common.selectAll')}
        </MenuItem>
        <Divider />
        <div className={classes.itemsWrapper}>
          {Object.values(DocumentStatus).map((status, index) => (
            <>
              <MenuItem
                key={`${status}_${index}`}
                onClick={() => handleChange(status)}
                divider={false}
              >
                <ListItemIcon>
                  <Checkbox color="primary" checked={editingStatusTypes.includes(status)} />
                </ListItemIcon>
                <DocumentStatusChip status={status} />
              </MenuItem>
              <Divider />
            </>
          ))}
        </div>

        <div className={classes.menuFooter}>
          <Button variant="text" onClick={handleClose} size="small">
            {t<string>('common.cancel')}
          </Button>
          <Button size="small" onClick={onSubmitClick}>
            {t<string>('common.apply')}
          </Button>
        </div>
      </Menu>
    </>
  );
};

export default DocumentStatusDropdown;
