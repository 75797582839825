import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import documentCreationActions from 'src/redux/actions/documentCreation.actions';
import { RequestStatus } from 'src/helpers/reduxReuquest.util';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { moduleName as dslService } from 'src/redux/services/documentDSL.service';
import { StepUpload, StepList } from './steps';
import { useStyles } from './styles';
import { DropzoneStep, DropzoneVariant, getMaxSize } from './helpers';
import { moduleName as documentService } from '../../redux/services/documentCore.service';

const DropZone = ({
  smallScreen,
  creatorDocumentStepper,
  handleFileChange,
  uploadStepEndContent,
  variant,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const dispatch = useDispatch();
  const isCertificate = variant === DropzoneVariant.CERTIFICATE;
  const apiService = isCertificate ? dslService : documentService;

  const getUploadTokenStatus = useSelector(
    (state) => state.documentCreationStore.getUploadTokenStatus,
    undefined
  );
  const uploadTokens = useSelector((state) => state.documentCreationStore.uploadTokens, undefined);
  const filesListDropzone = useSelector(
    (state) => state.documentCreationStore.filesListDropzone,
    undefined
  );
  const templateDocument = useSelector(
    (state) => state.documentCreationStore.templateDocument,
    undefined
  );
  const [activeStep, setStep] = useState(
    filesListDropzone.length > 0 ? DropzoneStep.LIST : DropzoneStep.UPLOAD
  );

  const avatarUploadMode = variant === DropzoneVariant.AVATAR;
  const MAX_SIZE = getMaxSize(variant);

  useEffect(() => {
    if (getUploadTokenStatus === RequestStatus.SUCCESS) {
      if (uploadTokens.length === filesListDropzone.length) {
        filesListDropzone.forEach((file) => {
          if (file.progress === 0) {
            uploadFile(file);
          }
        });
      }
    }
    if (getUploadTokenStatus === RequestStatus.ERROR) {
      if (uploadTokens.length !== filesListDropzone.length) {
        filesListDropzone.forEach((file, index) => {
          if (file.progress === 0) {
            deleteFile(index);
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadTokens, getUploadTokenStatus]);

  useEffect(() => {
    if (!templateDocument && !avatarUploadMode) {
      const documentTemplateType = location.pathname
        .split('/')
        .pop()
        .toUpperCase()
        .replaceAll('-', '_');
      dispatch(documentCreationActions.setTemplateDocument(documentTemplateType));
      // handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateDocument]);

  const setProgressUpload = (percent, token) => {
    dispatch(documentCreationActions.setProgressUpload(percent, token));
  };

  const uploadFile = (file) => {
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        const percent = Math.floor((loaded * 100) / total);
        setProgressUpload(percent, file.token);
      },
    };
    if (file) {
      dispatch(documentCreationActions.uploadFile(file.file, file.token, file.size, config));
    }
  };

  const deleteFile = (index) => {
    if (index !== -1) {
      dispatch(documentCreationActions.deleteFileListDropzone(index));
    }
  };

  const addFile = (file) => {
    avatarUploadMode ? handleFileChange(file) : changeStep(DropzoneStep.LIST);
  };

  const changeStep = (type) => {
    setStep(type);
  };

  const handleDrop = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles && rejectedFiles.length) {
      rejectedFiles.forEach((file) => {
        file.errors.forEach((error) => {
          if (error.code === 'file-too-large') {
            openSnackbar(
              RequestStatus.ERROR,
              `${t('dropzones.file')} ${file.file.name} ${t('dropzones.greaterThan')} ${
                MAX_SIZE / 1000000
              } mb.`
            );
          }
        });
      });
    }

    acceptedFiles.forEach((file, index) => {
      if (!avatarUploadMode) {
        getUploadToken();
        const splitName = file.name.split('.');
        const newFile = {
          name: splitName.slice(0, -1).join('.'),
          type: `.${splitName.pop()}`,
          size: file.size,
          progress: 0,
          file,
        };
        dispatch(documentCreationActions.addFileListDropzone(newFile));
        if (index === acceptedFiles.length - 1) {
          addFile(acceptedFiles);
        }
      } else {
        addFile(file);
      }
    });
  };

  const getUploadToken = () => {
    dispatch(documentCreationActions.getUploadToken(apiService));
  };

  const openSnackbar = (type, content) => {
    const options = {
      variant: type,
      message: content,
    };
    enqueueSnackbar(JSON.stringify(options));
  };

  return (
    <>
      <StepUpload
        classes={clsx(activeStep === DropzoneStep.LIST && classes.hiddenStep)}
        smallScreen={smallScreen}
        endContent={uploadStepEndContent}
        variant={variant}
        handleDrop={handleDrop}
      />
      <StepList
        deleteFile={deleteFile}
        changeStep={changeStep}
        uploadFile={uploadFile}
        handleDrop={handleDrop}
        creatorDocumentStepper={creatorDocumentStepper}
        className={clsx(
          (activeStep === DropzoneStep.UPLOAD || avatarUploadMode) && classes.hiddenStep
        )}
        smallScreen={smallScreen}
        variant={variant}
      />
    </>
  );
};

DropZone.propTypes = {
  smallScreen: PropTypes.bool,
  creatorDocumentStepper: PropTypes.func,
  handleFileChange: PropTypes.func,
  uploadStepEndContent: PropTypes.node,
  variant: PropTypes.string.isRequired,
};

export default DropZone;
