import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Avatar, AvatarWithBadge, ExtendedGrid } from 'src/components';
import ContactHelper from 'src/helpers/contactHelper';
import { useStyles } from '../styles';

const BadgeCell = ({ className: classNameProp, person, size, ...otherProps }) => {
  const classes = useStyles();
  const user = ContactHelper.getEntityData(person);
  const userName = user ? user.name : '-';
  return (
    <ExtendedGrid item {...otherProps} className={clsx(classes.cell, classNameProp)}>
      {(!user || user.isFromOrganisation) && (
        <Avatar
          tooltip={`${userName}`}
          size={size}
          className={classes.badge}
          src={
            user
              ? `${process.env.REACT_APP_AVATAR_URL}/identity/${user?.identityId}?kind=SMALL`
              : null
          }
        >
          {userName}
        </Avatar>
      )}
      {user && !user.isFromOrganisation && (
        <AvatarWithBadge
          tooltip={userName}
          size={size}
          src={
            user
              ? `${process.env.REACT_APP_AVATAR_URL}/identity/${user?.identityId}?kind=SMALL`
              : null
          }
          badgeSrc={
            user
              ? `${process.env.REACT_APP_AVATAR_URL}/workspace/${user?.workspaceId}?kind=SMALL`
              : null
          }
          badgeChildren={user.workspaceName}
          badgeTooltip={user.workspaceName}
        >
          {userName}
        </AvatarWithBadge>
      )}
    </ExtendedGrid>
  );
};

BadgeCell.propTypes = {
  className: PropTypes.string,
  person: PropTypes.string,
  size: PropTypes.oneOf(['large', 'medium', 'small', 'mini']),
};

BadgeCell.defaultProps = {
  size: 'medium',
};

export default BadgeCell;
