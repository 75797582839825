import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ palette, shape, spacing, typography }) =>
    createStyles({
      root: {
        width: '100%',
        padding: spacing(2, 0),
        position: 'relative',
        '&:last-child': {
          paddingBottom: 0,
        },
        '&:not($hideDots)': {
          marginBottom: spacing(2),
          '&:before, &:after': {
            content: "''",
            display: 'block',
            position: 'absolute',
            left: 24,
            width: 2,
            height: 2,
            marginLeft: '1px',
            borderRadius: '50%',
            backgroundColor: 'currentColor',
            boxShadow: '0 6px 0 0 currentColor',
          },
          '&:before': {
            top: 'calc(100% - 2px)',
          },
          '&:after': {
            top: 'calc(100% + 10px)',
          },
        },
      },
      hideDots: {
        marginBottom: spacing(1),
      },
      icon: {
        width: 48,
        height: 48,
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&:before': {
          content: "''",
          display: 'block',
          position: 'absolute',
          top: 0,
          left: 0,
          width: 'inherit',
          height: 'inherit',
          backgroundColor: 'currentColor',
          borderRadius: shape.borderRadius,
          opacity: 0.05,
        },
      },
      title: {
        fontWeight: typography.fontWeightMedium,
        '& strong': {
          color: palette.primary.main,
        },
      },
      timeText: {
        display: 'inline',
        marginLeft: '0',
      },
      entriesDividerText: {
        marginBottom: spacing(1),
      },
      defaultColor: {
        color: palette.text.secondary,
      },
      errorColor: {
        color: palette.error.main,
      },
      warningColor: {
        color: palette.warning.main,
      },
      infoColor: {
        color: palette.primary.light,
      },
      primaryColor: {
        color: palette.primary.main,
      },
      successColor: {
        color: palette.success.main,
      },
    }),
  { index: 0 }
);
