import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ spacing, breakpoints }) =>
    createStyles({
      root: {
        width: '100%',
        clear: 'both',
      },
      mini: {
        height: spacing(0.5),
        [breakpoints.up('lg')]: {
          height: spacing(0.75),
        },
      },
      small: {
        height: spacing(1),
      },
      default: {
        height: spacing(2),
      },
      medium: {
        height: spacing(2),
        [breakpoints.up('md')]: {
          height: spacing(2.5),
        },
        [breakpoints.up('lg')]: {
          height: spacing(3.5),
        },
      },
      large: {
        height: spacing(2.5),
        [breakpoints.up('md')]: {
          height: spacing(3),
        },
        [breakpoints.up('lg')]: {
          height: spacing(5),
        },
      },
      card: {
        height: spacing(1.5),
        [breakpoints.up('md')]: {
          height: spacing(3),
        },
        [breakpoints.up('lg')]: {
          height: spacing(5),
        },
      },
      cardHalf: {
        height: spacing(1.5),
        [breakpoints.up('md')]: {
          height: spacing(2),
        },
        [breakpoints.up('lg')]: {
          height: spacing(2.5),
        },
      },
    }),
  { index: 0 }
);
