import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

const CustomLink = (props) => {
  const { children } = props;
  return (
    <Link {...props} component={RouterLink}>
      {children}
    </Link>
  );
};

CustomLink.propTypes = {
  to: PropTypes.string,
  children: PropTypes.shape({}),
  classes: PropTypes.objectOf(PropTypes.shape()),
  color: PropTypes.oneOf([
    'initial',
    'primary',
    'secondary',
    'textPrimary',
    'textSecondary',
    'error',
  ]),
  component: PropTypes.elementType,
  TypographyClasses: PropTypes.objectOf(PropTypes.shape()),
  underline: PropTypes.oneOf(['none', 'hover', 'always']),
  variant: PropTypes.string,
};

CustomLink.defaultProps = {
  color: 'primary',
  underline: 'hover',
  variant: 'inherit',
};

export default CustomLink;
