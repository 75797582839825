import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IdentitySelector } from 'src/redux/selectors/identity.selector';
import BillingActions from 'src/redux/actions/billing.actions';
import { RootState } from 'src/redux/reducers';
import { BillingProductType } from 'src/models/billing.model';
import SubscriptionProduct from './SubscriptionProduct';
import { ContentHeading } from '../../subcomponents';

const Subscription = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const workspaceId = useSelector(IdentitySelector.selectWorkspaceId);
  const { subscriptions } = useSelector((state: RootState) => state.billingStore);
  const esignProduct = subscriptions.find((el) => el.productType === BillingProductType.EsignDrive);
  const certProduct = subscriptions.find(
    (el) => el.productType === BillingProductType.Certificates
  );

  useEffect(() => {
    if (workspaceId) {
      dispatch(BillingActions.fetchSubscriptions(workspaceId));
      dispatch(BillingActions.fetchCustomer(workspaceId));
    }
  }, [dispatch, workspaceId]);

  return (
    <div>
      <ContentHeading>{t<string>('workspacePage.drawer.subscriptionProducts')}</ContentHeading>
      <SubscriptionProduct product={esignProduct} type={BillingProductType.EsignDrive} />
      <SubscriptionProduct product={certProduct} type={BillingProductType.Certificates} />
    </div>
  );
};

export default Subscription;
