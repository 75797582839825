import React from 'react';
import { createStyles, makeStyles, Paper } from '@material-ui/core';

export const useStyles = makeStyles(
  () =>
    createStyles({
      itemsWrapper: {
        borderRadius: 8,
        marginBottom: 18,
        width: '100%',
      },
      buttonWrapper: {
        display: 'flex',
        justifyContent: 'center',
      },
    }),
  { index: 0 }
);

interface ItemsWrapperProps {
  children: JSX.Element;
  actionButtons?: JSX.Element;
}

const ItemsWrapper = ({ children, actionButtons }: ItemsWrapperProps): JSX.Element => {
  const classes = useStyles();
  return (
    <>
      <Paper elevation={10} className={classes.itemsWrapper}>
        {children}
      </Paper>
      <div className={classes.buttonWrapper}>{actionButtons && actionButtons}</div>
    </>
  );
};
export default ItemsWrapper;
