import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ custom, palette, spacing, breakpoints }) =>
  createStyles(
    {
      title: {
        padding: spacing(1.5, 0, 3.5),
        fontSize: 16,
      },
      root: {
        '& *': {
          userSelect: 'none',
          '-webkit-user-select': 'none !important' /* Chrome, Opera, Safari */,
          '-moz-user-select': 'none !important' /* Firefox 2+ */,
          '-ms-user-select': 'none !important' /* IE 10+ */,
        },
      },
      datePickerRoot: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        height: custom.breadcrumbsMenuHeight,
        position: 'sticky',
        top: 0,
        left: 0,
        zIndex: 2,
        backgroundColor: palette.background.paper,
        '&:before': {
          content: "''",
          display: 'block',
          position: 'absolute',
          top: spacing(-2),
          bottom: 0,
          left: spacing(-2),
          right: spacing(-2),
          backgroundColor: 'inherit',
        },
        '& > *': {
          position: 'relative',
        },
      },
      header: {
        position: 'sticky',
        left: 0,
        backgroundColor: palette.background.paper,
        zIndex: 1,
        top: -16,
      },
      loader: {
        position: 'absolute',
      },
      noDocumentsBanner: {
        '& img': {
          [breakpoints.up('md')]: {
            marginTop: custom.breadcrumbsMenuHeight + spacing(1),
          },
        },
      },
    },
    { index: 0 }
  )
);
