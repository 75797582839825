import { Button, TextField, Typography, useMediaQuery } from '@material-ui/core';
import { SmallContentWithScrollbar } from 'src/components';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { DocumentRole } from 'src/models/document.model';
import certificateManagementActions from 'src/redux/actions/certificateManagement.actions';
import documentManagementActions from 'src/redux/actions/documentManagement.actions';
import { moduleName as dslService } from 'src/redux/services/documentDSL.service';
import { object, string } from 'yup';
import { useStyles } from './styles';

const validateSchema = object({
  Email: string().email().required(),
});

const TransferByEmailStep = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const classes = useStyles();

  const smallScreen = useMediaQuery(({ breakpoints }) => breakpoints.down('sm'));

  const handleValidation = async () => {
    try {
      await validateSchema.validate({ Email: email });
    } catch (err) {
      setError(err.message);
    }
  };

  const handleChange = (e) => {
    setError('');
    setEmail(e.target.value);
  };

  const handleSubmit = () => {
    const callback = () => {
      history.goBack();
    };

    dispatch(
      certificateManagementActions.transferOwnership(id, DocumentRole.HOLDER, email, callback)
    );
  };

  useEffect(() => {
    dispatch(documentManagementActions.getDocumentInvitations(id, dslService));
  }, [id, dispatch]);

  return (
    <SmallContentWithScrollbar
      startContent={
        <Typography variant={smallScreen ? 'h3' : 'h2'}>{t('transferOwnership.title')}</Typography>
      }
      endContent={
        <Button
          size="large"
          type="submit"
          fullWidth
          onClick={handleSubmit}
          disabled={Boolean(error) || !email}
        >
          {t('transferOwnership.submit')}
        </Button>
      }
    >
      <div className={classes.items}>
        <Typography>{t('transferOwnership.steps.transferByEmail.title')}</Typography>
        <TextField
          placeholder="E-mail address"
          variant="outlined"
          value={email}
          onChange={handleChange}
          error={Boolean(error)}
          helperText={error}
          onBlur={handleValidation}
        />
      </div>
    </SmallContentWithScrollbar>
  );
};

export { TransferByEmailStep };
