import { fade } from '@material-ui/core/styles';
import spacing from '../spacing';
import palette from '../palette';

export default {
  root: {
    backgroundColor: palette.primary.dark,
    borderBottom: `1px solid ${palette.divider}`,
    color: fade(palette.common.white, 0.5),
    padding: spacing(2),
  },
  dateTitleContainer: {
    position: 'relative',
  },
  toolbarLandscape: {
    minWidth: 200,
  },
};
