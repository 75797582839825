import transitions from '@material-ui/core/styles/transitions';

export default {
  root: {
    bottom: 0,
    right: 0,
    top: 0,
    left: 0,
    margin: 0,
    padding: 0,
    transition: transitions.create(['background-color', 'color', 'box-shadow', 'border'], {
      duration: transitions.duration.short,
    }),
  },
  legend: {
    display: 'none',
  },
  legendLabelled: {
    display: 'none',
  },
  legendNotched: {
    maxWidth: 1000,
  },
};
