import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  () =>
    createStyles({
      suffix: {
        marginLeft: 8,
      },
    }),
  { index: 0 }
);
