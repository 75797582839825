import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ palette }) =>
    createStyles({
      folderIcon: {
        fontSize: 16,
        marginRight: 6,
      },
      esignIcon: {
        fontSize: 16,
        marginRight: 6,
        color: palette.common.purple,
      },
      driveIcon: {
        fontSize: 16,
        marginRight: 6,
        color: palette.common.green,
      },
      certificateIcon: {
        fontSize: 16,
        marginRight: 6,
        color: palette.primary.light,
      },
      text: {
        display: 'inline-flex',
        alignItems: 'center',
      },
    }),
  { index: 0 }
);
