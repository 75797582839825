import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import WebSocketHelper from 'src/helpers/WebSocketHelper';
import { useTranslation } from 'react-i18next';
import { SNACKBAR_VARIANT } from 'src/models/common.model';
import snackbarActions from 'src/redux/actions/snackbar.actions';
import documentDownloadActions from '../../../../../redux/actions/documentDownload.actions';

const TransferOwnershipWebsocket = ({ documentId, callback = () => undefined }) => {
  const ws = useRef(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onWebSocketMessage = (message) => {
    if (message.downloadToken) {
      dispatch(
        documentDownloadActions.downloadDocumentFile(documentId, message.downloadToken, callback)
      );
    } else if (message.type === 'ERROR') {
      const resultCode = message?.data?.result?.code;
      const messagePart = resultCode ? `_${resultCode}` : '';
      dispatch(
        snackbarActions.enqueueSnackbar(
          JSON.stringify({
            variant: SNACKBAR_VARIANT.ERROR,
            message: t(`drivePage.action.error.${message.code}${messagePart}`),
          })
        )
      );
    } else {
      console.warn(`Unknown action type [${message.type}]`, message);
    }
  };

  useEffect(() => {
    if (documentId) {
      ws.current = WebSocketHelper.subscribe(
        'document-service',
        `document/download/${documentId}`,
        (message) => {
          onWebSocketMessage(message);
        }
      );
    }

    return () => {
      WebSocketHelper.unsubscribe(ws.current);
    };
  }, [documentId]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};
TransferOwnershipWebsocket.propTypes = {
  documentId: PropTypes.string,
  callback: PropTypes.func,
};

export default TransferOwnershipWebsocket;
