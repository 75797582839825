import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, createStyles, makeStyles, Typography } from '@material-ui/core';
import { AddAndRemoveUsers, SmallContentWithScrollbar } from 'src/components';
import { useDispatch } from 'react-redux';
import identityActions from 'src/redux/actions/identity.actions';
import { isMail } from '../../../../../helpers/globalUtils';

const useStyles = makeStyles(
  ({ spacing }) =>
    createStyles({
      addNextButton: {
        textAlign: 'left',
        justifyContent: 'flex-start',
      },
      content: {
        '& > *:not(:last-child)': {
          marginBottom: spacing(0.75),
        },
      },
      innerContent: {
        margin: `0 0 ${spacing(-1)}px 0`,
      },
    }),
  { index: 0 }
);

const InviteUsers = ({ workspaceId }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [clearSignersInput, setClearSignersInput] = useState(false);
  const [invitedSigners, setInvitedSigners] = useState([]);

  const handleSendInvitations = () => {
    if (invitedSigners.length) {
      const emailsArray = invitedSigners.map((user) => user.email);
      dispatch(identityActions.inviteUserToWorkspace(workspaceId, emailsArray));
    }
  };

  const handleRoleDelete = (roleId) => {
    setInvitedSigners(invitedSigners.filter((invitedRole) => invitedRole.id !== roleId));
  };

  const handleNewEmailAdd = (emailRole) => {
    if (isMail(emailRole.email)) {
      if (!invitedSigners.find((role) => role.name === emailRole.email)) {
        setClearSignersInput(true);
        setInvitedSigners([...invitedSigners, emailRole]);
      }
    }
  };

  useEffect(() => {
    if (clearSignersInput) {
      setClearSignersInput(false);
    }
  }, [clearSignersInput]);

  return (
    <SmallContentWithScrollbar
      startContent={<Typography>{t('workspacePage.groups.invite.description')}</Typography>}
      endContent={
        <Button
          size="large"
          fullWidth
          disabled={invitedSigners.length === 0}
          onClick={handleSendInvitations}
        >
          {t('common.invite')}
        </Button>
      }
      classes={{
        content: classes.content,
      }}
    >
      <AddAndRemoveUsers
        documentInvitationMode
        items={invitedSigners}
        classes={{ root: classes.innerContent }}
        availableItems={[]}
        clearInput={clearSignersInput}
        placeholder={t('inviteToDocument.section.roles.addPeopleOrGroups')}
        noResultsText={t('workspacePage.groups.manageUsers.noResults')}
        onAddNewEmailClick={(emailRole) => handleNewEmailAdd(emailRole)}
        onDeleteClick={(roleId) => handleRoleDelete(roleId)}
      />
    </SmallContentWithScrollbar>
  );
};

InviteUsers.propTypes = {
  workspaceId: PropTypes.string,
};

export default InviteUsers;
