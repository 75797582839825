import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import clsx from 'clsx';
import {
  Card,
  CardContent,
  FormControlLabel,
  LinearProgress,
  List,
  ListItem,
  Switch,
  Theme,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import identityActions from 'src/redux/actions/identity.actions';
import { IdentitySelector } from 'src/redux/selectors/identity.selector';
import { RootState } from 'src/redux/reducers';
import { RequestStatus } from 'src/helpers/reduxReuquest.util';
import { ConfirmDialog, FullScreenDialog } from 'src/components';
import documentActions from 'src/redux/actions/documents.actions';
import { WorkspaceUser } from 'src/models/workspaceUser.model';
import { DocumentStatus } from 'src/models/documents.model';
import { DeleteUser, InviteUsers } from './editDialogs';
import { HeaderAndButton, Search, UserItem } from '../../subcomponents';
import { useStyles } from '../styles';

enum EditedSubpage {
  Invite,
  Delete,
}

const Users = (): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const workspaceID = useSelector(IdentitySelector.selectWorkspaceId, undefined);
  const smallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const [showDeletedUsers, setShowDeletedUsers] = useState<boolean>(false);
  const [repeatCheckDocumentsUser, setRepeatCheckDocumentsUser] = useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const activeIdentity = useSelector((state: any) => state.identityStore.currentIdentity);
  const currentIdentityId = useSelector(IdentitySelector.selectCurrentIdentityId);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const groupAllId = activeIdentity.groups.find((group: any) => group.name === 'All')?.groupId;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { fetchDocumentsStatus, documents } = useSelector((state: any) => state.documentsStore);
  const {
    workspaceUsers,
    workspaceInvitedUsers,
    fetchWorkspaceUsersStatus,
    fetchWorkspaceInvitedUsersStatus,
    hasNextPage,
    pageIndex,
    deleteUserFromWorkspaceStatus,
    deleteUserInvitationFromWorkspaceStatus,
    inviteUsersToWorkspaceStatus,
    searchWorkspaceUsersStatus,
  } = useSelector((state: RootState) => state.workspaceUserStore);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const { enqueueSnackbar } = useSnackbar();
  const [actionUser, setActionUser] = useState<WorkspaceUser | null>(null);
  const [editedSubpage, setEditedSubpage] = useState<EditedSubpage | null>(null);
  const loading =
    deleteUserInvitationFromWorkspaceStatus === RequestStatus.PENDING ||
    fetchWorkspaceUsersStatus === RequestStatus.PENDING ||
    searchWorkspaceUsersStatus === RequestStatus.PENDING ||
    fetchDocumentsStatus === RequestStatus.PENDING ||
    fetchWorkspaceInvitedUsersStatus === RequestStatus.PENDING ||
    deleteUserFromWorkspaceStatus === RequestStatus.PENDING;
  const isUserInvitation = actionUser && !actionUser.identityId;

  const filteredWorkspaceUsers = workspaceUsers.filter((el) => {
    if (showDeletedUsers) return true;
    return el.active;
  });

  const filteredInvitedUsers = workspaceInvitedUsers.filter((el) =>
    el.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const fetchMoreData = () => {
    if (hasNextPage && pageIndex > 0 && workspaceUsers.length) {
      dispatch(identityActions.getWorkspaceGroupUsers(groupAllId, pageIndex, 10, searchTerm));
    }
  };

  const onDeleteUserConfirm = () => {
    if (actionUser && actionUser.identityId) {
      checkDeleteUserHasDocuments();
    } else {
      dispatch(identityActions.deleteUserInvitationFromWorkspace(workspaceID, actionUser?.email));
    }
  };

  const checkDeleteUserHasDocuments = () => {
    if (actionUser)
      dispatch(
        documentActions.fetchAnyDocuments(workspaceID, 10, 0, 'updatedAt', 'ASC', false, '', '', [
          actionUser.identityId,
        ])
      );
  };

  const handleDeleteUser = (check?: boolean) => {
    if (check) {
      setRepeatCheckDocumentsUser(true);
      checkDeleteUserHasDocuments();
    } else if (actionUser) {
      deleteDialogToggle.close();
      handleBack();
      dispatch(identityActions.deleteUserFromWorkspace(workspaceID, actionUser.identityId));
    }
  };

  const onDeleteClick = (user: WorkspaceUser) => {
    deleteDialogToggle.open();
    setActionUser(user);
  };

  const deleteDialogToggle = {
    open: () => {
      setDeleteDialogOpen(true);
    },
    close: () => {
      setDeleteDialogOpen(false);
    },
  };

  const handleBack = () => {
    setEditedSubpage(null);
  };
  const toggleShowDeletedUsers = () => setShowDeletedUsers(!showDeletedUsers);
  const openSnackbar = (type: string, content: string) => {
    const options = {
      variant: type,
      message: content,
    };
    enqueueSnackbar(JSON.stringify(options));
  };
  const showInfo = () => {
    openSnackbar('info', t('workspacePage.users.checkDelete.infoText'));
  };

  useEffect(() => {
    if (fetchDocumentsStatus === RequestStatus.IDLE && actionUser?.identityId) {
      if (
        !documents.filter(
          (document: { status: string }) => document?.status !== DocumentStatus.REJECTED
        ).length
      ) {
        handleDeleteUser();
      } else {
        if (repeatCheckDocumentsUser) {
          showInfo();
        }
        setEditedSubpage(EditedSubpage.Delete);
      }
      deleteDialogToggle.close();
      setRepeatCheckDocumentsUser(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchDocumentsStatus]);

  useEffect(() => {
    if (deleteUserFromWorkspaceStatus !== RequestStatus.IDLE) {
      setActionUser(null);
    }
  }, [deleteUserFromWorkspaceStatus]);

  // Fetch users after success removing
  useEffect(() => {
    if (deleteUserFromWorkspaceStatus !== RequestStatus.SUCCESS) {
      setTimeout(() => {
        dispatch(identityActions.getWorkspaceGroupUsers(groupAllId, 0, 50));
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteUserFromWorkspaceStatus]);

  // Fetch invited users after removing user, close dialog
  useEffect(() => {
    if (deleteUserInvitationFromWorkspaceStatus !== RequestStatus.SUCCESS) {
      setTimeout(() => {
        dispatch(identityActions.getWorkspaceInvitedUsers(workspaceID));
        dispatch(identityActions.getWorkspaceGroupUsers(groupAllId, 0));
      }, 1000);
      deleteDialogToggle.close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteUserInvitationFromWorkspaceStatus]);

  // Fetch invited users after success add, and close modal
  useEffect(() => {
    if (inviteUsersToWorkspaceStatus === RequestStatus.SUCCESS) {
      dispatch(identityActions.getWorkspaceInvitedUsers(workspaceID));
      handleBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inviteUsersToWorkspaceStatus]);

  // Fetch users on search change
  useEffect(() => {
    dispatch(identityActions.getWorkspaceGroupUsers(groupAllId, 0, 100, searchTerm));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  useEffect(() => {
    const scrollElement = document.getElementById('fullScreenDialogContent');

    const hasVerticalScrollbar =
      scrollElement && scrollElement?.scrollHeight > scrollElement?.clientHeight;

    if (hasNextPage && pageIndex > 0 && !hasVerticalScrollbar) {
      fetchMoreData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasNextPage, workspaceUsers]);

  // Initial load users
  useEffect(() => {
    dispatch(identityActions.getWorkspaceInvitedUsers(workspaceID));
    dispatch(identityActions.getWorkspaceGroupUsers(groupAllId, 0));

    return () => {
      dispatch(identityActions.clearWorkspaceUsersStore());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const editPages = {
    [EditedSubpage.Invite]: {
      title: t('workspacePage.groups.invite.header'),
      Component: <InviteUsers workspaceId={workspaceID} />,
    },
    [EditedSubpage.Delete]: {
      title: t('workspacePage.users.checkDelete.header'),
      Component: (
        <DeleteUser
          repeatCheckDocumentsUser={repeatCheckDocumentsUser}
          handleDeleteUser={handleDeleteUser}
        />
      ),
    },
  };

  const renderEditPage = (variant: EditedSubpage) => editPages[variant].Component;
  const setTitle = (variant: EditedSubpage) => editPages[variant].title;

  const renderSummaryPage = () => (
    <>
      <HeaderAndButton
        subtitle={t('workspacePage.users.subheader')}
        onButtonClick={() => setEditedSubpage(EditedSubpage.Invite)}
        smallScreen={smallScreen}
        buttonLabel={t('workspacePage.users.inviteUser')}
      >
        {t<string>('workspacePage.drawer.userManagementUser')}
      </HeaderAndButton>
      <div className={classes.searchWrapper}>
        <Search
          className={classes.searchInput}
          inputLabel={`${t('common.search')}...`}
          handleSetSearchTerm={(val) => setSearchTerm(val)}
          valueInput={searchTerm}
          loading={loading}
        />
        <FormControlLabel
          labelPlacement="end"
          control={<Switch checked={showDeletedUsers} onChange={toggleShowDeletedUsers} />}
          label={
            <Typography color="textSecondary">
              {t<string>('workspacePage.users.deleteUser.toggleDeleted')}
            </Typography>
          }
        />
      </div>
      <Card variant="outlined" className={classes.root}>
        <InfiniteScroll
          dataLength={filteredWorkspaceUsers.length}
          next={fetchMoreData}
          hasMore={hasNextPage}
          scrollThreshold={0.9}
          style={{
            overflow: 'hidden',
          }}
          loader={loading && <LinearProgress />}
          scrollableTarget="fullScreenDialogContent"
        >
          <CardContent>
            <List>
              {!smallScreen && (
                <ListItem
                  divider={false}
                  classes={{
                    root: clsx(classes.listHeader, classes.listItemRoot),
                    container: classes.listItemContainer,
                  }}
                >
                  <div className={classes.headerWrapper}>
                    <Typography variant="subtitle2">
                      {t<string>('workspacePage.users.tableHeader.user')}
                    </Typography>
                    <Typography variant="subtitle2">{t<string>('common.actions')}</Typography>
                  </div>
                </ListItem>
              )}
              {[...filteredInvitedUsers, ...filteredWorkspaceUsers].map((i, index) => (
                <UserItem
                  key={`${index}_${i.email}`}
                  user={i}
                  onDeleteClick={() => onDeleteClick(i)}
                  canDelete={
                    (currentIdentityId !== i.identityId && i.active) || i.identityId === undefined
                  }
                />
              ))}
            </List>
          </CardContent>
          {loading && <LinearProgress />}
        </InfiniteScroll>
      </Card>

      <ConfirmDialog
        variant="error"
        open={deleteDialogOpen}
        title={
          isUserInvitation
            ? t('workspacePage.users.deleteUserInvitation.header')
            : t('workspacePage.users.deleteUser.header')
        }
        subtitle={
          isUserInvitation
            ? t<string>('workspacePage.users.deleteUserInvitation.description')
            : t<string>('workspacePage.users.deleteUser.description')
        }
        applyButtonText={
          isUserInvitation
            ? t('workspacePage.users.deleteUserInvitation.delete')
            : t('workspacePage.users.deleteUser.delete')
        }
        cancelButtonText={t('common.cancel')}
        actionAccept={onDeleteUserConfirm}
        actionCancel={deleteDialogToggle.close}
        isLoading={loading}
      />
    </>
  );

  return (
    <>
      {editedSubpage === null ? (
        renderSummaryPage()
      ) : (
        <FullScreenDialog
          isOpen
          onBack={handleBack}
          onClose={handleBack}
          title={setTitle(editedSubpage)}
        >
          {renderEditPage(editedSubpage)}
        </FullScreenDialog>
      )}
    </>
  );
};

export default Users;
