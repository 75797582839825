import React, { MouseEvent, useEffect, useState } from 'react';
import { Divider, ListItemIcon, Menu, MenuItem, Typography, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import { CreateNewFolderOutlined } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/redux/reducers';
import {
  CertificateIcon,
  FileEdit as EsignIcon,
  FilesWithShield as DriveIcon,
} from 'src/components/CustomIcon';
import { useStyles } from './styles';
import documentCreationActions from '../../redux/actions/documentCreation.actions';
import identityActions from '../../redux/actions/identity.actions';
import { TemplateDocumentType } from '../../models/documents.model';
import { RequestStatus } from '../../helpers/reduxReuquest.util';
import { ErrorCode } from '../../models/errors.model';
import documentsActions from '../../redux/actions/documents.actions';
import AddNewFolderModal from '../AddNewFolderModal/AddNewFolderModal';
import SubscriptionModal from './SubscriptionModal';
import billingActions from '../../redux/actions/billing.actions';

type SelectTemplateDropdownProps = {
  children: JSX.Element;
};

const SelectTemplateDropdown = ({ children }: SelectTemplateDropdownProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const { palette } = useTheme();
  const { currentFolderId } = useSelector((state: RootState) => state.documentsStore, undefined);
  const [addNewFolderDialogOpen, setAddNewFolderDialogOpen] = useState(false);
  const [
    subscriptionModalProduct,
    setSubscriptionModalProduct,
  ] = useState<TemplateDocumentType | null>(null);
  const {
    currentIdentity,
    workspaceWorkflows,
    workspaceWorkflowsStatus,
    workspaceWorkflowsErrorCode,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } = useSelector((state: any) => state.identityStore, undefined);
  const { setTrialSubscriptionStatus } = useSelector((state: RootState) => state.billingStore);
  const workspaceId = currentIdentity?.workspace?.workspaceId;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const options = [
    {
      label: t('selectTemplate.certificate.title'),
      description: t('selectTemplate.certificate.description'),
      icon: <CertificateIcon style={{ color: palette.common.green }} />,
      value: TemplateDocumentType.CERTIFICATES,
    },
    {
      label: t('selectTemplate.signedDocument.title'),
      description: t('selectTemplate.signedDocument.description'),
      icon: <EsignIcon style={{ color: palette.primary.light }} />,
      value: TemplateDocumentType.DOCUMENT_TO_BE_SIGNED,
    },
    {
      label: t('selectTemplate.durableMedium.title'),
      description: t('selectTemplate.durableMedium.description'),
      icon: <DriveIcon style={{ color: palette.common.purple }} />,
      value: TemplateDocumentType.DURABLE_MEDIA,
    },
  ];

  const goToCreatePage = (value: TemplateDocumentType) => {
    const slugTemplate = value.toLowerCase().replaceAll('_', '-');
    history.push(`/document-creator/${slugTemplate}`);
  };

  const onOptionClick = (value: TemplateDocumentType) => {
    if (!workspaceWorkflows.includes(value)) {
      setSubscriptionModalProduct(value);
      setAnchorEl(null);
    } else {
      goToCreatePage(value);
      setAnchorEl(null);
    }
  };

  const handleClick = (e: MouseEvent<HTMLElement>) => {
    if (e) setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    dispatch(documentCreationActions.setTemplateDocument(null));
    setAnchorEl(null);
  };

  useEffect(() => {
    if (
      (workspaceId && Boolean(anchorEl)) ||
      setTrialSubscriptionStatus === RequestStatus.SUCCESS
    ) {
      dispatch(identityActions.getWorkspacePermissions(workspaceId));
      dispatch(identityActions.fetchWorkspaceWorkflows(workspaceId));

      if (subscriptionModalProduct) {
        dispatch(billingActions.resetSetTrialSubscription());
        setTimeout(() => {
          goToCreatePage(subscriptionModalProduct);
        }, 300);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceId, anchorEl, setTrialSubscriptionStatus]);

  const isOpen = Boolean(anchorEl);

  return (
    <>
      {subscriptionModalProduct && (
        <SubscriptionModal
          templateDocument={subscriptionModalProduct}
          onClose={() => setSubscriptionModalProduct(null)}
        />
      )}
      <div aria-hidden="true" onClick={handleClick}>
        {children}
      </div>
      <Menu
        open={isOpen}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        autoFocus={false}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: -15,
          horizontal: 'left',
        }}
        onClose={handleClose}
      >
        {workspaceWorkflowsErrorCode === ErrorCode.IDENTITY_NOT_READY && (
          <Alert severity="warning" className={classes.alert}>
            <Typography>{t<string>('selectTemplate.identityAlert')}</Typography>
          </Alert>
        )}
        <MenuItem
          divider={false}
          onClick={() => {
            setAddNewFolderDialogOpen(true);
            setAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <CreateNewFolderOutlined style={{ color: palette.text.secondary }} />
          </ListItemIcon>

          <Typography color="textPrimary">{t<string>('sideBar.newFolder')}</Typography>
        </MenuItem>
        <Divider />
        {options.map((option, index) => (
          <MenuItem
            divider={false}
            key={`${option.value}_${index}`}
            disabled={workspaceWorkflowsStatus !== RequestStatus.SUCCESS}
            onClick={() => {
              onOptionClick(option.value);
            }}
            className={classes.optionItem}
          >
            <ListItemIcon className={classes.optionIcon}>{option.icon}</ListItemIcon>
            <div className={classes.textWrapper}>
              <Typography color="textPrimary"> {option.label}</Typography>
              <Typography color="textSecondary" variant="body2" className={classes.textDescription}>
                {option.description}
              </Typography>
            </div>
          </MenuItem>
        ))}
      </Menu>
      {addNewFolderDialogOpen && (
        <AddNewFolderModal
          open={addNewFolderDialogOpen}
          onClose={() => setAddNewFolderDialogOpen(false)}
          onChange={(newFolderName) => {
            dispatch(documentsActions.createFolder(workspaceId, currentFolderId, newFolderName));
            setAddNewFolderDialogOpen(false);
          }}
        />
      )}
    </>
  );
};

export default SelectTemplateDropdown;
