import { Button, TextField } from '@material-ui/core';
import { CheckRounded } from '@material-ui/icons';
import React from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { func } from 'prop-types';
import { useStyles } from './styles';
import { RegexFormulas } from '../../helpers/regex';

const DirectoryExplorerCreateInput = ({ onSubmit }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const validationSchema = yup.object({
    name: yup
      .string(t('directoryExplorer.enterName'))
      .matches(RegexFormulas.folderName, t('validation.folderName'))
      .required(t('validation.required')),
  });
  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} className={classes.form}>
      <TextField
        className={classes.input}
        autoFocus
        autocomplete="off"
        name="name"
        placeholder={t('directoryExplorer.inputPlaceholder')}
        value={formik.values.name}
        onChange={formik.handleChange}
        error={formik.touched.name && Boolean(formik.errors.name)}
        helperText={formik.touched.name && formik.errors.name}
        InputProps={{
          endAdornment: (
            <Button className={classes.inputButton} type="submit">
              <CheckRounded />
            </Button>
          ),
          classes: {
            root: classes.inputRoot,
          },
        }}
      />
    </form>
  );
};

DirectoryExplorerCreateInput.propTypes = {
  onSubmit: func,
};

export default DirectoryExplorerCreateInput;
