import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ palette, spacing }) =>
    createStyles({
      root: {
        color: palette.text.primary,
        padding: spacing(2, 0),
        '&:first-child': {
          paddingTop: 0,
        },
        '&:last-child': {
          paddingBottom: 0,
        },
        '& [class^="Tag-root-"], & + [class*="Tag-root-"], & .tag-item': {
          margin: `1px ${spacing(1)}px 1px 0`,
        },
      },
      listItemContainer: {
        '&:first-child': {
          marginTop: spacing(-2),
        },
        '&:last-child': {
          marginBottom: spacing(-2),
        },
        '& $root': {
          paddingRight: 32,
        },
      },
    }),
  { index: 0 }
);
