import React, { KeyboardEvent, ChangeEvent, MouseEvent, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Checkbox,
  Divider,
  Fab,
  ListItemIcon,
  Menu,
  MenuItem,
  TextField,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import documentUserManagerActions from 'src/redux/actions/documentUserManager.actions';
import { IdentitySelector } from 'src/redux/selectors/identity.selector';
import DocumentUserManagerSelector from 'src/redux/selectors/documentUserManager.selector';
import { UserManagerPerson } from 'src/models/documentUsersManagement.model';
import { Search as SearchIcon } from 'src/components/CustomIcon';
import { useStyles } from './styles';
import { AvatarWithBadge } from '../index';
import UserItemTitle from '../DocumentUsersManager/subcomponents/UserItem/UserItemTitle';
import UserItemDescription from '../DocumentUsersManager/subcomponents/UserItem/UserItemDescription';

type DocumentTypeDropdownProps = {
  children: JSX.Element;
  onSubmit: (types: UserManagerPerson[]) => void;
  initialRecipients: UserManagerPerson[];
};

const RecipientsDropdown = ({
  children,
  onSubmit,
  initialRecipients,
}: DocumentTypeDropdownProps): JSX.Element => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const workspaceId = useSelector(IdentitySelector.selectWorkspaceId);
  const searchedEntities = useSelector(
    DocumentUserManagerSelector.selectSearchedEntitiesWithDetails,
    undefined
  );
  const [hover, setHover] = useState(-1);
  const [selectedRecipients, setSelectedRecipients] = useState(initialRecipients);
  const [searchTerm, setSearchTerm] = useState('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const allSelected =
    selectedRecipients.length > 0 && searchedEntities.length === selectedRecipients.length;

  const someSelected = selectedRecipients.length > 0;

  const handleClick = (e: MouseEvent<HTMLElement>) => {
    if (e) setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setSearchTerm('');
    setAnchorEl(null);
    setSelectedRecipients(initialRecipients);
  };

  const handleChange = (recipient: UserManagerPerson) => {
    if (selectedRecipients.find((el) => el._id === recipient._id))
      setSelectedRecipients(selectedRecipients.filter((el) => el._id !== recipient._id));
    else setSelectedRecipients([...selectedRecipients, recipient]);
  };

  const handleSelectAll = () => {
    setSelectedRecipients(allSelected ? [] : searchedEntities);
  };

  const onSubmitClick = () => {
    onSubmit(selectedRecipients);
    setSearchTerm('');
    setAnchorEl(null);
  };

  const onSearchChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    setSearchTerm(event.target.value);

  const onKeyDown = (event: KeyboardEvent<HTMLElement>) => {
    event.stopPropagation();
  };
  const handleMouseEnter = (index: number) => {
    setHover(index);
  };

  const handleMouseLeave = () => {
    setHover(-1);
  };
  const isOpen = Boolean(anchorEl);
  const selectedIds = useMemo(() => selectedRecipients.map((el) => el._id), [selectedRecipients]);

  useEffect(() => {
    if (workspaceId) dispatch(documentUserManagerActions.getEntitiesByName(workspaceId, '', 100));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSelectedRecipients(initialRecipients);
  }, [initialRecipients]);

  return (
    <>
      <div aria-hidden="true" onClick={handleClick}>
        {children}
      </div>
      <Menu
        key="recipients-dropdown"
        open={isOpen}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        autoFocus={false}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handleClose}
      >
        <TextField
          onChange={onSearchChange}
          onKeyDown={onKeyDown}
          className={classes.searchInput}
          variant="standard"
          autoFocus
          InputProps={{
            startAdornment: (
              <Fab size="small">
                <SearchIcon />
              </Fab>
            ),
          }}
        />
        <Divider />
        <MenuItem onClick={handleSelectAll} divider={false}>
          <ListItemIcon>
            <Checkbox
              color="primary"
              indeterminate={someSelected && !allSelected}
              checked={allSelected}
            />
          </ListItemIcon>
          {t('common.selectAll')}
        </MenuItem>
        <Divider />
        <div className={classes.itemsWrapper} style={{ width: anchorEl?.offsetWidth }}>
          {searchedEntities
            .filter(
              (el) =>
                el._name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                el.email?.toLowerCase().includes(searchTerm.toLowerCase())
            )
            .map((el, index) => {
              const checked = selectedIds.includes(el._id);
              const showCheckbox = checked || hover === index;
              return (
                <>
                  <MenuItem
                    key={`${index}_${el._id}_item`}
                    onClick={() => handleChange(el)}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                    divider={false}
                  >
                    <div className={classes.avatarCheckboxWrapper}>
                      <Checkbox
                        checked={checked}
                        className={classes.listCheckbox}
                        style={{ display: showCheckbox ? 'block' : 'none' }}
                      />
                      <div style={{ display: !showCheckbox ? 'block' : 'none' }}>
                        <AvatarWithBadge
                          size="medium"
                          src={`${process.env.REACT_APP_AVATAR_URL}/identity/${el._id}?kind=SMALL`}
                          alt={el.email || el._name}
                        >
                          {el.email || el._name}
                        </AvatarWithBadge>
                      </div>
                    </div>

                    <div className={classes.textWrapper}>
                      <UserItemTitle
                        userData={el}
                        highlightString={searchTerm}
                        showTitleAdditionalTexts={false}
                      />
                      <UserItemDescription userData={el} highlightString={searchTerm} />
                    </div>
                  </MenuItem>
                  <Divider key={`${index}_${el._id}_divider`} />
                </>
              );
            })}
        </div>
        <div className={classes.menuFooter}>
          <Button variant="text" onClick={handleClose} size="small">
            {t<string>('common.cancel')}
          </Button>
          <Button size="small" onClick={onSubmitClick}>
            {t<string>('common.apply')}
          </Button>
        </div>
      </Menu>
    </>
  );
};

export default RecipientsDropdown;
