import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ breakpoints, palette, spacing }) =>
    createStyles({
      root: {
        width: '100%',
        [breakpoints.down('sm')]: {
          position: 'relative',
        },
      },
      topElement: {
        marginBottom: spacing(2),
        [breakpoints.up('lg')]: {
          marginBottom: spacing(4),
        },
      },
      createdItemsList: {
        '& > div': {
          overflow: 'hidden',
        },
      },
      createdItemCell: {
        '&:not(:last-child)': {
          borderBottom: `1px solid ${palette.divider}`,
          [breakpoints.up('xs')]: {
            borderBottom: 0,
          },
        },
      },
      createdItem: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        paddingLeft: 40 + spacing(0.5),
        '& $createdItemIcon': {
          position: 'absolute',
          left: 0,
          top: '50%',
          transform: `translateY(-50%)`,
          width: 40,
          height: 40,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          '& svg': {
            width: 20,
            height: 20,
          },
        },
        '& > div': {
          width: '100%',
        },
      },
      createdItemIcon: {},
      entriesList: {},
      entriesListSpacingBottom: {
        [breakpoints.up('md')]: {
          paddingBottom: spacing(1),
        },
      },
      hideShowFullWrapper: {
        position: 'sticky',
        left: 0,
        backgroundColor: palette.background.paper,
        zIndex: 2,
        '&:before': {
          content: "''",
          display: 'block',
          position: 'absolute',
          left: 0,
          width: '100%',
          height: spacing(2),
          backgroundColor: 'inherit',
        },
      },
      hideFullWrapper: {
        top: spacing(2),
        paddingBottom: spacing(2),
        '&:before': {
          bottom: '100%',
        },
      },
      showFullWrapper: {
        bottom: spacing(2),
        paddingTop: spacing(2),
        '&:before': {
          top: '100%',
        },
      },
      loaderFile: {
        position: 'absolute',
        zIndex: 3,
        width: '100%',
        left: 0,
        bottom: 0,
        [breakpoints.down('sm')]: {
          bottom: -12,
        },
      },
      noItems: {
        padding: spacing(1.5, 0),
        textAlign: 'center',
      },
      inputDisabled: {
        backgroundColor: 'transparent !important',
      },
      historyChipDisabled: {
        opacity: '1 !important',
        '& $historyChipDeleteButton': {
          display: 'none',
        },
      },
      historyChipDeleteButton: {},
      textButton: { cursor: 'pointer' },
    }),
  { index: 0 }
);
