import spacing from '../spacing';
import breakpoints from '../breakpoints';

export default {
  root: {
    paddingTop: spacing(2),
    paddingLeft: spacing(2),
    paddingRight: spacing(2),
    paddingBottom: spacing(2),
    '&:last-child': {
      paddingBottom: spacing(2),
    },
    [breakpoints.up('sm')]: {
      paddingTop: spacing(2),
      paddingLeft: spacing(2),
      paddingRight: spacing(2),
      paddingBottom: spacing(2),
    },
    [breakpoints.up('md')]: {
      paddingTop: spacing(3),
      paddingLeft: spacing(3),
      paddingRight: spacing(3),
      paddingBottom: spacing(3),
      '&:last-child': {
        paddingBottom: spacing(3),
      },
    },
    [breakpoints.up('lg')]: {
      paddingTop: spacing(5),
      paddingLeft: spacing(5),
      paddingRight: spacing(5),
      paddingBottom: spacing(5),
      '&:last-child': {
        paddingBottom: spacing(5),
      },
      '& + [class^="MuiCardActions-root"], & + [class*="MuiCardActions-root"]': {
        marginTop: spacing(-2),
      },
    },
  },
};
