import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, Container } from '@material-ui/core';
import { RequestStatus } from 'src/helpers/reduxReuquest.util';
import { useStyles } from './styles';
import certificateCreatorActions from '../../../../redux/actions/certificateCreator.actions';

const TemplatePreview = () => {
  const dispatch = useDispatch();
  const { selectedTemplate, templatePreviewImage, templatePreviewImageStatus } = useSelector(
    (state) => state.certificateCreatorStore,
    undefined
  );
  const { currentIdentity } = useSelector((state) => state.identityStore, undefined);
  const workspaceId = currentIdentity?.workspace?.workspaceId;
  const isLoading = templatePreviewImageStatus === RequestStatus.PENDING;

  useEffect(() => {
    if (selectedTemplate) {
      dispatch(
        certificateCreatorActions.fetchTemplatePreview({
          template: selectedTemplate,
          workspaceId,
        })
      );
    }
  }, [selectedTemplate, workspaceId, dispatch]);

  const classes = useStyles();

  return (
    <div className={classes.containerWrapper}>
      <Container className={classes.container}>
        <div className={classes.previewWrapper}>
          {isLoading ? (
            <CircularProgress size={48} />
          ) : (
            <img className={classes.previewImage} src={templatePreviewImage} alt="certificate" />
          )}
        </div>
      </Container>
    </div>
  );
};

export default TemplatePreview;
