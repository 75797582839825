import React from 'react';
import PropTypes from 'prop-types';
import { Hidden } from '@material-ui/core';
import { ContentHeader } from 'src/components';

const ContentHeading = ({ children, subtitle }) => (
  <Hidden smDown>
    <ContentHeader subtitle={subtitle}>{children}</ContentHeader>
  </Hidden>
);

ContentHeading.propTypes = {
  children: PropTypes.node,
  subtitle: PropTypes.string,
};

export default ContentHeading;
