import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Drawer, useMediaQuery } from '@material-ui/core';
import { ContentContainer, HeaderButton, MenuSidebar } from 'src/components';
import { Menu as OpenIcon } from 'src/components/CustomIcon';
import { useStyles } from './styles';

const FullScreenDialogContentWrapper = ({ className: classNameProp, children, menuLinks }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const smallScreen = useMediaQuery(({ breakpoints }) => breakpoints.down('sm'));

  const [open, setOpen] = useState(false);

  const drawerToggle = {
    open: () => {
      setOpen(true);
    },
    close: () => {
      setOpen(false);
    },
    toggle: () => {
      setOpen(!open);
    },
  };

  return (
    <div className={clsx(classes.root, classNameProp)}>
      <Drawer
        variant={smallScreen ? 'temporary' : 'permanent'}
        open={smallScreen ? open : true}
        anchor="left"
        classes={{
          root: classes.drawerRoot,
          paper: classes.drawerPaper,
        }}
        ModalProps={{
          onBackdropClick: drawerToggle.close,
          keepMounted: true,
        }}
      >
        <MenuSidebar
          items={menuLinks}
          onToggle={drawerToggle.toggle}
          onClose={drawerToggle.close}
        />
      </Drawer>

      <main className={classes.content}>
        {smallScreen && (
          <ContentContainer vertical={false} className={classes.backButton}>
            <HeaderButton
              color="primary"
              onClick={drawerToggle.toggle}
              icon={<OpenIcon />}
              tooltip={t('common.openMenu')}
            />
          </ContentContainer>
        )}

        {children}
      </main>
    </div>
  );
};

FullScreenDialogContentWrapper.propTypes = {
  className: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  children: PropTypes.node,
  // eslint-disable-next-line react/forbid-prop-types
  menuLinks: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default FullScreenDialogContentWrapper;
