import { RequestStatus } from 'src/helpers/reduxReuquest.util';
import twoFactorConfigConstants from '../constants/twoFactorConfig.constants';

const initialState = {
  changeTwoFactorActiveTypeRequestStatus: RequestStatus.IDLE,
  confirmTwoFactorTypeChangeRequestError: null,
  confirmTwoFactorTypeChangeRequestStatus: RequestStatus.IDLE,
  deleteTwoFactorTypeRequestError: null,
  deleteTwoFactorTypeRequestStatus: RequestStatus.IDLE,
  getTwoFactorConfigRequestError: null,
  getTwoFactorConfigRequestStatus: RequestStatus.IDLE,
  initTwoFactorMethod: null,
  initTwoFactorTypeChangeRequestError: null,
  initTwoFactorTypeChangeRequestStatus: RequestStatus.IDLE,
  initTwoFactorTypeConfig: null,
  twoFactorActiveMethod: null,
  twoFactorConfig: null,
};

export default function twoFactorConfig(state = initialState, action) {
  switch (action.type) {
    case twoFactorConfigConstants.GET_TWO_FACTOR_CONFIG_REQUEST:
      return {
        ...state,
        getTwoFactorConfigRequestStatus: RequestStatus.PENDING,
      };
    case twoFactorConfigConstants.GET_TWO_FACTOR_CONFIG_SUCCESS:
      return {
        ...state,
        getTwoFactorConfigRequestStatus: RequestStatus.SUCCESS,
        twoFactorConfig: action.response.data?.collection,
        twoFactorActiveMethod: action.response.data?.collection?.find((type) => type.active).type,
      };
    case twoFactorConfigConstants.GET_TWO_FACTOR_CONFIG_ERROR:
      return {
        ...state,
        getTwoFactorConfigRequestStatus: RequestStatus.ERROR,
        getTwoFactorConfigRequestError: action.error,
      };
    case twoFactorConfigConstants.CHANGE_TWO_FACTOR_ACTIVE_TYPE_REQUEST:
      return {
        ...state,
        changeTwoFactorActiveTypeRequestStatus: RequestStatus.PENDING,
      };
    case twoFactorConfigConstants.CHANGE_TWO_FACTOR_ACTIVE_TYPE_SUCCESS:
      return {
        ...state,
        changeTwoFactorActiveTypeRequestStatus: RequestStatus.SUCCESS,
        twoFactorActiveMethod: action.twoFactorActiveMethod,
      };
    case twoFactorConfigConstants.CHANGE_TWO_FACTOR_ACTIVE_TYPE_ERROR:
      return {
        ...state,
        changeTwoFactorActiveTypeRequestStatus: RequestStatus.ERROR,
        changeTwoFactorActiveTypeRequestError: action.error,
      };
    case twoFactorConfigConstants.DELETE_TWO_FACTOR_TYPE_REQUEST:
      return {
        ...state,
        deleteTwoFactorTypeRequestStatus: RequestStatus.PENDING,
      };
    case twoFactorConfigConstants.DELETE_TWO_FACTOR_TYPE_SUCCESS:
      return {
        ...state,
        deleteTwoFactorTypeRequestStatus: RequestStatus.SUCCESS,
      };
    case twoFactorConfigConstants.DELETE_TWO_FACTOR_TYPE_ERROR:
      return {
        ...state,
        deleteTwoFactorTypeRequestStatus: RequestStatus.ERROR,
        deleteTwoFactorTypeRequestError: action.error,
      };
    case twoFactorConfigConstants.INIT_TWO_FACTOR_TYPE_CHANGE_REQUEST:
      return {
        ...state,
        initTwoFactorTypeChangeRequestStatus: RequestStatus.PENDING,
      };
    case twoFactorConfigConstants.INIT_TWO_FACTOR_TYPE_CHANGE_SUCCESS:
      return {
        ...state,
        initTwoFactorTypeChangeRequestStatus: RequestStatus.SUCCESS,
        initTwoFactorTypeConfig: action.response.data,
        initTwoFactorMethod: action.initTwoFactorMethod,
      };
    case twoFactorConfigConstants.INIT_TWO_FACTOR_TYPE_CHANGE_ERROR:
      return {
        ...state,
        initTwoFactorTypeChangeRequestStatus: RequestStatus.ERROR,
        initTwoFactorTypeChangeRequestError: action.error,
      };
    case twoFactorConfigConstants.CONFIRM_TWO_FACTOR_TYPE_CHANGE_REQUEST:
      return {
        ...state,
        confirmTwoFactorTypeChangeRequestStatus: RequestStatus.PENDING,
      };
    case twoFactorConfigConstants.CONFIRM_TWO_FACTOR_TYPE_CHANGE_SUCCESS:
      return {
        ...state,
        twoFactorActiveMethod: action.twoFactorActiveMethod,
        confirmTwoFactorTypeChangeRequestStatus: RequestStatus.SUCCESS,
      };
    case twoFactorConfigConstants.CONFIRM_TWO_FACTOR_TYPE_CHANGE_ERROR:
      return {
        ...state,
        confirmTwoFactorTypeChangeRequestStatus: RequestStatus.ERROR,
        confirmTwoFactorTypeChangeRequestError: action.error.code,
      };
    case twoFactorConfigConstants.CLEAR_CONFIRM_TWO_FACTOR_TYPE_STATUS:
      return {
        ...state,
        confirmTwoFactorTypeChangeRequestStatus: RequestStatus.IDLE,
        confirmTwoFactorTypeChangeRequestError: null,
      };
    case twoFactorConfigConstants.CLEAR_STORE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
