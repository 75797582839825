export const DropzoneVariant = {
  AVATAR: 'avatar',
  DOCUMENT: 'document',
  CERTIFICATE: 'certificate',
};
export const DropzoneStep = {
  UPLOAD: 'upload',
  LIST: 'list',
};

export const getAcceptableFiles = (variant) => {
  switch (variant) {
    case DropzoneVariant.AVATAR:
      return 'image/jpeg, image/png';
    case DropzoneVariant.DOCUMENT:
    case DropzoneVariant.CERTIFICATE:
      return 'application/pdf, .zip, .doc, .docx, image/jpeg, image/png, application/x-zip-compressed,multipart/x-zip';
    default:
      return '';
  }
};

export const getMaxSize = (variant) => {
  switch (variant) {
    case DropzoneVariant.AVATAR:
      return 10485760;
    case DropzoneVariant.DOCUMENT:
    case DropzoneVariant.CERTIFICATE:
      return 1073741824;
    default:
      return 0;
  }
};
