import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Button, ButtonBase, Fab, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { SmallContentWithScrollbar } from 'src/components';
import {
  FileColor as FileColorIcon,
  Plus as PlusIcon,
  Check as SuccessIcon,
  Delete as DeleteIcon,
} from 'src/components/CustomIcon';
import { RequestStatus } from 'src/helpers/reduxReuquest.util';
import { useSelector } from 'react-redux';
import Dropzone from 'react-dropzone';
import LinearProgressWithLabel from './subcomponents';
import { useStyles } from './styles';
import { DropzoneStep, DropzoneVariant, getAcceptableFiles, getMaxSize } from '../../helpers';

const StepList = ({
  className: classesProp,
  deleteFile,
  changeStep,
  smallScreen,
  creatorDocumentStepper,
  variant,
  handleDrop,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { uploadFileStatus, filesListDropzone } = useSelector(
    (state) => state.documentCreationStore,
    undefined
  );
  const acceptableFiles = getAcceptableFiles(variant);
  const MAX_SIZE = getMaxSize(variant);
  const avatarUploadMode = variant === DropzoneVariant.AVATAR;
  const uploadedFileList = filesListDropzone.filter(
    (file) => file.uploadStatus === RequestStatus.SUCCESS
  );

  return (
    <SmallContentWithScrollbar
      classes={{ root: clsx(classes.root, classesProp), content: classes.content }}
      scrollableContent
      size="large"
      startContent={
        <>
          <Typography
            variant={smallScreen ? 'h3' : 'h1'}
            gutterBottom={!smallScreen}
            align={smallScreen ? 'left' : 'center'}
            className={classes.title}
          >
            {t('dropzones.uploadListTitle')}
          </Typography>
          {!smallScreen && (
            <Typography variant="body1" align="center" className={classes.subtitle}>
              {t('dropzones.uploadListSubTitle')}
            </Typography>
          )}
        </>
      }
      endContent={
        <div className={classes.actionButtons}>
          {smallScreen && (
            <Button
              className={classes.actionButton}
              fullWidth
              size="large"
              color="secondary"
              onClick={() => changeStep(DropzoneStep.UPLOAD)}
            >
              {filesListDropzone.length ? t('common.addAnotherFile') : t('common.addFile')}
            </Button>
          )}
          <Button
            className={classes.actionButton}
            fullWidth
            size="large"
            onClick={() => creatorDocumentStepper(2, filesListDropzone)}
            disabled={uploadedFileList.length === 0 || uploadFileStatus === RequestStatus.PENDING}
          >
            {
              {
                [DropzoneVariant.AVATAR]: t('common.createDocument'),
                [DropzoneVariant.DOCUMENT]: t('common.createDocument'),
                [DropzoneVariant.CERTIFICATE]: t('createCertificate.continue'),
              }[variant]
            }
          </Button>
        </div>
      }
    >
      <div className={classes.uploadItems}>
        {filesListDropzone.map((item, key) => (
          <div className={classes.uploadItem} key={key}>
            <div className={classes.iconBox}>
              <FileColorIcon className={classes.icon} />
              <Typography variant="body2" align="center" noWrap>
                {item.type}
              </Typography>
              {!smallScreen && item.token && (
                <ButtonBase className={classes.deleteDesktopButton} onClick={() => deleteFile(key)}>
                  <DeleteIcon />
                </ButtonBase>
              )}
            </div>
            <div className={classes.uploadItemContent}>
              <div className={classes.uploadItemTitle}>
                <Typography
                  variant="h6"
                  align={smallScreen ? 'left' : 'center'}
                  className={classes.uploadName}
                  noWrap={!smallScreen}
                >
                  {item.name}
                </Typography>
                {Math.round(item.progress) === 100 &&
                  item.uploadStatus === RequestStatus.SUCCESS && (
                    <SuccessIcon className={classes.checkIcon} />
                  )}
              </div>

              {item.uploadStatus !== RequestStatus.ERROR ? (
                <LinearProgressWithLabel value={item.progress} />
              ) : (
                <Typography variant="body2" color="error" align={smallScreen ? 'left' : 'center'}>
                  {t('dropzones.errorUploadFileText')}
                </Typography>
              )}
            </div>
            {smallScreen && item.token && (
              <Fab size="small" className={classes.deleteButton} onClick={() => deleteFile(key)}>
                <DeleteIcon />
              </Fab>
            )}
          </div>
        ))}

        {!smallScreen && (
          <div className={classes.uploadItem}>
            <Dropzone
              onDrop={handleDrop}
              accept={acceptableFiles}
              maxSize={MAX_SIZE}
              // disabled={!isHavePermissions}
              classes={{ isDragActive: classes.iconBox }}
              multiple={!avatarUploadMode}
            >
              {({ getRootProps, getInputProps }) => (
                <ButtonBase {...getRootProps()} className={classes.addItem}>
                  <input {...getInputProps()} />
                  <div className={clsx(classes.iconAdd)}>
                    <PlusIcon />
                  </div>
                  <div className={classes.uploadItemTitle}>
                    <Typography
                      variant="h6"
                      align="center"
                      color="textPrimary"
                      className={classes.uploadName}
                    >
                      {filesListDropzone.length ? t('common.addAnotherFile') : t('common.addFile')}
                    </Typography>
                  </div>
                </ButtonBase>
              )}
            </Dropzone>
          </div>
        )}
      </div>
    </SmallContentWithScrollbar>
  );
};

StepList.propTypes = {
  deleteFile: PropTypes.func,
  className: PropTypes.string,
  changeStep: PropTypes.func,
  smallScreen: PropTypes.bool,
  creatorDocumentStepper: PropTypes.func,
  handleDrop: PropTypes.func,
  variant: PropTypes.oneOf([
    DropzoneVariant.AVATAR,
    DropzoneVariant.DOCUMENT,
    DropzoneVariant.CERTIFICATE,
  ]).isRequired,
};

export default StepList;
