enum documentCreationConstants {
  UPLOAD_FILE_REQUEST = 'UPLOAD_FILE_REQUEST',
  UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS',
  UPLOAD_FILE_FAILURE = 'UPLOAD_FILE_FAILURE',

  GET_UPLOAD_TOKEN_REQUEST = 'GET_UPLOAD_TOKEN_REQUEST',
  GET_UPLOAD_TOKEN_SUCCESS = 'GET_UPLOAD_TOKEN_SUCCESS',
  GET_UPLOAD_TOKEN_FAILURE = 'GET_UPLOAD_TOKEN_FAILURE',

  CREATE_NEW_DOCUMENT_REQUEST = 'CREATE_NEW_DOCUMENT_REQUEST',
  CREATE_NEW_DOCUMENT_SUCCESS = 'CREATE_NEW_DOCUMENT_SUCCESS',
  CREATE_NEW_DOCUMENT_FAILURE = 'CREATE_NEW_DOCUMENT_FAILURE',

  ADD_FILE_DROPZONE = 'ADD_FILE_DROPZONE',

  DELETE_FILE_DROPZONE = 'DELETE_FILE_DROPZONE',

  SET_PROGRESS_UPLOAD_FILE = 'SET_PROGRESS_UPLOAD_FILE',

  SET_TEMPLATE_DOCUMENT = 'SET_TEMPLATE_DOCUMENT',

  SET_SIGNATURE_DOCUMENT = 'SET_SIGNATURE_DOCUMENT',

  CLEAR_DOCUMENT_CREATOR_STORE = 'CLEAR_DOCUMENT_CREATOR_STORE',
}

export default documentCreationConstants;
