import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, IconButton, Typography, createStyles, makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { CloseRounded as CloseIcon } from '@material-ui/icons';
import {
  CodeInput,
  ContentContainer,
  Gap,
  SmallContentWithScrollbar,
  TimerButton,
} from 'src/components';

export const useStyles = makeStyles(
  ({ spacing }) =>
    createStyles({
      header: {
        marginBottom: spacing(0.5),
      },
      alert: {
        marginTop: spacing(1),
      },
      timeCounter: {
        display: 'flex',
        alignItems: 'center',
        minHeight: spacing(3),
        lineHeight: 1.3,
        '& > *': {
          lineHeight: 'inherit',
        },
      },
    }),
  { index: 0 }
);

const TwoFactorForm = ({
  method,
  lastSendTimestamp,
  isSending,
  isReSending,
  hasErrors,
  onSend,
  onReSend,
  onClearErrors,
}) => {
  const [authCode, setAuthCode] = useState('');
  const { t } = useTranslation();
  const classes = useStyles();

  const codeInputsRef = useRef(null);

  /*
   * Cool down for resend
   * */
  const retryTimeout = 30000;

  /*
   * Config for methods, that can resend codes
   * */
  const methodsWithTimer = ['PHONE', 'EMAIL', 'phoneConfirm', 'emailConfirm'];

  const methodHasTimer = methodsWithTimer.indexOf(method) !== -1;

  const basicTranslations = {
    header: t(`twoFactor.${method}.header`),
    desc: t(`twoFactor.${method}.desc`),
    label: t(`twoFactor.${method}.label`),
    sendButton: t(`twoFactor.common.sendButton`),
    havingTrouble: t(`twoFactor.common.havingTrouble`),
    contactSupport: t(`twoFactor.common.contactSupport`),
    errorMessage: t(`twoFactor.common.errorMessage`),
  };

  const translations = Object.assign(
    basicTranslations,
    methodHasTimer && {
      hasNotArrived: t(`twoFactor.${method}.hasNotArrived`),
      retryTimer: t(`twoFactor.${method}.retryTimerText`),
      retryButton: t(`twoFactor.${method}.retryButton`),
    }
  );

  return (
    <SmallContentWithScrollbar
      endContent={
        <Button
          size="large"
          fullWidth
          type="button"
          onClick={() => onSend(authCode)}
          disabled={isSending || !authCode}
        >
          {t('common.verify')}
        </Button>
      }
    >
      <ContentContainer size="small" horizontal={false} vertical="bottom">
        <Typography variant="h2" classes={{ root: classes.header }}>
          {translations.header}
        </Typography>
        <Typography>{translations.desc}</Typography>
      </ContentContainer>

      <ContentContainer size="small" horizontal={false} vertical="top">
        <Typography variant="button">{translations.label}</Typography>
      </ContentContainer>

      {hasErrors && (
        <Alert
          classes={{ root: classes.alert }}
          severity="error"
          icon={false}
          action={
            <IconButton
              size="small"
              onClick={() => {
                onClearErrors();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {translations.errorMessage}
        </Alert>
      )}
      <ContentContainer horizontal={false} size="small">
        <CodeInput
          ref={codeInputsRef}
          codeLength={6}
          onValueChange={(newValue) => {
            setAuthCode(newValue);
          }}
          onAcceptValue={(code) => {
            onSend(code);
          }}
          hasErrors={hasErrors}
        />
      </ContentContainer>

      {methodHasTimer && (
        <div className={classes.timeCounter}>
          <Typography variant="body2" component="span">
            {`${translations.hasNotArrived} `}
          </Typography>

          <TimerButton
            cooldown={retryTimeout}
            buttonLabel={translations.retryButton}
            awaitMessage={` ${translations.retryTimer}`}
            buttonDisabled={isReSending}
            onClick={() => {
              onReSend();
              codeInputsRef.current.clearCode();
            }}
            initTimestamp={lastSendTimestamp}
          />
        </div>
      )}

      <Gap />
    </SmallContentWithScrollbar>
  );
};

TwoFactorForm.propTypes = {
  method: PropTypes.string.isRequired,
  lastSendTimestamp: PropTypes.number,
  isSending: PropTypes.bool,
  isReSending: PropTypes.bool,
  hasErrors: PropTypes.bool,
  onSend: PropTypes.func,
  onReSend: PropTypes.func,
  onClearErrors: PropTypes.func,
};

export default TwoFactorForm;
