import typography from '../typography';
import palette from '../palette';
import breakpoints from '../breakpoints';

export default {
  root: {
    color: palette.text.secondary,
    fontSize: typography.htmlFontSize,
    lineHeight: 'inherit',
    height: 'auto',
    minHeight: 48,
    transition: 'all .3s',
    [breakpoints.up('sm')]: {
      minHeight: 48,
    },
    '&:hover': {
      color: palette.primary.main,
      backgroundColor: palette.action.hover,
    },
    '&$selected, &$selected:hover': {
      color: palette.primary.main,
      backgroundColor: palette.action.selected,
    },
    '&:hover .MuiTypography-colorTextPrimary': {
      color: palette.primary.main,
    },
  },
};
