import React from 'react';
import { DropZone } from 'src/components';
import { useTranslation } from 'react-i18next';
import certificateCreatorActions from 'src/redux/actions/certificateCreator.actions';
import { CertificateSteps } from 'src/models/certificate.model';
import { useDispatch } from 'react-redux';
import { Button, useMediaQuery } from '@material-ui/core';
import { DropzoneVariant } from '../../../components/DropZone/helpers';

const UploadAdditionalDocuments = () => {
  const { t } = useTranslation();
  const smallScreen = useMediaQuery(({ breakpoints }) => breakpoints.down('sm'));
  const dispatch = useDispatch();

  const onContinueClick = () => {
    dispatch(
      certificateCreatorActions.setCreatorStep({
        currentStep: CertificateSteps.ChooseLocation,
      })
    );
  };

  return (
    <>
      <DropZone
        smallScreen={smallScreen}
        variant={DropzoneVariant.CERTIFICATE}
        creatorDocumentStepper={onContinueClick}
        uploadStepEndContent={
          <Button fullWidth size="large" onClick={() => onContinueClick()}>
            {t('createCertificate.continueWithoutFiles')}
          </Button>
        }
      />
    </>
  );
};

export default UploadAdditionalDocuments;
