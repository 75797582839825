import React, { MouseEvent, useEffect, useState } from 'react';
import { Button, Checkbox, Divider, ListItemIcon, Menu, MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  FileEdit as EsignIcon,
  FilesWithShield as DriveIcon,
  CertificateIcon,
  Folder as FolderIcon,
} from 'src/components/CustomIcon';
import { DocumentType, ExtraDocumentType } from 'src/models/documents.model';
import { useStyles } from './styles';

type DocumentTypeDropdownProps = {
  children: JSX.Element;
  onSubmit: (types: Array<DocumentType | ExtraDocumentType>) => void;
  initialDocumentTypes: Array<DocumentType | ExtraDocumentType>;
};

const DocumentTypeDropdown = ({
  children,
  onSubmit,
  initialDocumentTypes,
}: DocumentTypeDropdownProps): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [editingDocumentTypes, setEditingDocumentTypes] = useState(initialDocumentTypes);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const allSelected =
    editingDocumentTypes.includes(ExtraDocumentType.FOLDER) &&
    editingDocumentTypes.includes(DocumentType.DSL) &&
    editingDocumentTypes.includes(DocumentType.DRIVE) &&
    editingDocumentTypes.includes(DocumentType.ESIGN);

  const someSelected =
    editingDocumentTypes.includes(ExtraDocumentType.FOLDER) ||
    editingDocumentTypes.includes(DocumentType.DSL) ||
    editingDocumentTypes.includes(DocumentType.DRIVE) ||
    editingDocumentTypes.includes(DocumentType.ESIGN);

  const handleClick = (e: MouseEvent<HTMLElement>) => {
    if (e) setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setEditingDocumentTypes(initialDocumentTypes);
  };

  const handleChange = (docType: DocumentType | ExtraDocumentType) => {
    if (editingDocumentTypes.includes(docType))
      setEditingDocumentTypes(editingDocumentTypes.filter((el) => el !== docType));
    else setEditingDocumentTypes([...editingDocumentTypes, docType]);
  };

  const handleSelectAll = () => {
    setEditingDocumentTypes(
      allSelected
        ? []
        : [ExtraDocumentType.FOLDER, DocumentType.ESIGN, DocumentType.DSL, DocumentType.DRIVE]
    );
  };

  const onSubmitClick = () => {
    onSubmit(editingDocumentTypes);
    setAnchorEl(null);
  };

  useEffect(() => {
    setEditingDocumentTypes(initialDocumentTypes);
  }, [initialDocumentTypes]);

  const isOpen = Boolean(anchorEl);

  return (
    <>
      <div aria-hidden="true" onClick={handleClick}>
        {children}
      </div>
      <Menu
        open={isOpen}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        autoFocus={false}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handleClose}
      >
        <MenuItem className={classes.menuItem} divider={false} onClick={handleSelectAll}>
          <ListItemIcon>
            <Checkbox
              color="primary"
              indeterminate={someSelected && !allSelected}
              checked={allSelected}
            />
          </ListItemIcon>
          {t('common.selectAll')}
        </MenuItem>
        <Divider />
        <MenuItem divider={false} onClick={() => handleChange(ExtraDocumentType.FOLDER)}>
          <ListItemIcon>
            <Checkbox checked={editingDocumentTypes.includes(ExtraDocumentType.FOLDER)} />
          </ListItemIcon>
          <FolderIcon className={classes.folderIcon} />
          {t<string>('common.folders')}
        </MenuItem>
        <MenuItem divider={false} onClick={() => handleChange(DocumentType.ESIGN)}>
          <ListItemIcon>
            <Checkbox checked={editingDocumentTypes.includes(DocumentType.ESIGN)} />
          </ListItemIcon>
          <EsignIcon className={classes.esignIcon} />
          {t<string>('common.esign')}
        </MenuItem>
        <MenuItem divider={false} onClick={() => handleChange(DocumentType.DRIVE)}>
          <ListItemIcon>
            <Checkbox checked={editingDocumentTypes.includes(DocumentType.DRIVE)} />
          </ListItemIcon>
          <DriveIcon className={classes.driveIcon} />
          {t<string>('common.drive')}
        </MenuItem>
        <MenuItem divider={false} onClick={() => handleChange(DocumentType.DSL)}>
          <ListItemIcon>
            <Checkbox checked={editingDocumentTypes.includes(DocumentType.DSL)} />
          </ListItemIcon>
          <CertificateIcon className={classes.certificateIcon} />
          {t<string>('common.certificates')}
        </MenuItem>
        <Divider />

        <div className={classes.menuFooter}>
          <Button variant="text" onClick={handleClose} size="small">
            {t<string>('common.cancel')}
          </Button>
          <Button size="small" onClick={onSubmitClick}>
            {t<string>('common.apply')}
          </Button>
        </div>
      </Menu>
    </>
  );
};

export default DocumentTypeDropdown;
