import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { Fab, Tooltip, Typography } from '@material-ui/core';
import { ArrowLeftSLine as BackIcon } from 'src/components/CustomIcon';

import { useStyles } from './styles';

const LinkBehavior = React.forwardRef((props, ref) => <RouterLink ref={ref} {...props} />);

const MobileBreadcrumbs = ({ className: classNameProp, folderPath }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const parsePath = (path) => {
    const parsedPath = [];
    const ids = path.idPath.split('/');
    const names = path.namePath.split('/');

    const lastIds = ids.slice(Math.max(ids.length - 2, 0));
    const lastNames = names.slice(Math.max(names.length - 2, 0));

    for (let i = 0; i < lastIds.length; i += 1) {
      const name = lastNames[i] !== '' ? lastNames[i] : '';

      const folderData = {
        name,
        tooltip:
          name !== ''
            ? t('drivePage.foldersTable.backToFolder', { name })
            : t('drivePage.foldersTable.backToRoot'),
        path: ids.length > 2 ? `/${lastIds[i]}` : '',
        isActive: i + 1 === lastIds.length,
      };
      parsedPath.push(folderData);
    }
    return parsedPath;
  };

  const renderPath = () => {
    const path = parsePath(folderPath);
    return path.map((folder) => {
      if (folder.isActive) {
        return (
          <Typography key={`bread-${folder.id}`} color="textPrimary" variant="h5">
            {folder.name}
          </Typography>
        );
      }

      return (
        <Tooltip key={`m-bread-${folder.id}`} title={folder.tooltip}>
          <Fab
            component={LinkBehavior}
            to={`/drive${folder.path}`}
            size="medium"
            className={classes.button}
          >
            <BackIcon />
          </Fab>
        </Tooltip>
      );
    });
  };

  return folderPath.idPath.split('/').length > 1 ? (
    <div className={clsx(classes.root, classNameProp)}>{renderPath()}</div>
  ) : null;
};

MobileBreadcrumbs.propTypes = {
  className: PropTypes.string,
  folderPath: PropTypes.shape({
    idPath: PropTypes.string.isRequired,
    namePath: PropTypes.string.isRequired,
  }).isRequired,
};

MobileBreadcrumbs.defaultProps = {};

export default MobileBreadcrumbs;
