import React from 'react';
import {
  Badge,
  Button,
  Checkbox,
  CircularProgress,
  Fab,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import { AttachFile, DeleteForever } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { RequestStatus } from 'src/helpers/reduxReuquest.util';
import { useTranslation } from 'react-i18next';
import { FieldArray, useFormikContext } from 'formik';
import certificateCreatorActions from 'src/redux/actions/certificateCreator.actions';
import { useStyles } from '../styles';
import MapField from '../../components/MapField/MapField';
import DropzoneButton from '../../components/DropzoneButton/DropzoneButton';

const ImportDataTable = () => {
  const [anchorEl, setAnchorEl] = React.useState({ anchor: null, index: null });
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { errors, touched } = useFormikContext();
  const {
    selectedDraftImportedData,
    templateDetails,
    templateDetailsStatus,
    draftImportedData,
  } = useSelector((state) => state.certificateCreatorStore, undefined);

  if (templateDetailsStatus === RequestStatus.PENDING || !templateDetails)
    return (
      <div className={classes.loader}>
        <CircularProgress size={48} />
      </div>
    );

  // Update table data {index: index in arr, key: field name, value: new item value}
  const onTableInputChange = ({ index, key, value }) => {
    dispatch(certificateCreatorActions.updateDraftImportedData({ index, key, value }));
  };

  const onFileRemove = ({ index, key, value, fileIndex }) => {
    const newValue = value.filter((_, idx) => idx !== fileIndex);
    dispatch(
      certificateCreatorActions.updateDraftImportedData({
        index,
        key,
        value: newValue,
      })
    );

    if (!newValue || newValue.length === 0) {
      handleCloseFileMenu();
    }
  };

  const onAddNewRow = () => dispatch(certificateCreatorActions.addNewRowToDraftImportedData());

  const onCheckboxClick = (selected) =>
    dispatch(certificateCreatorActions.updateSelectedDraftImportedData(selected));

  const handleOpenFileMenu = (event, index) => {
    setAnchorEl({ anchor: event.currentTarget, index });
  };
  const handleCloseFileMenu = () => {
    setAnchorEl({ anchor: null, index: null });
  };

  return (
    <Paper className={classes.tableWrapper}>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.cellSelect}>
                <Checkbox
                  checked={
                    selectedDraftImportedData.length === draftImportedData.length &&
                    draftImportedData.length > 0
                  }
                  onChange={() =>
                    onCheckboxClick(
                      selectedDraftImportedData.length === draftImportedData.length
                        ? []
                        : draftImportedData.map((_, index) => index)
                    )
                  }
                />
              </TableCell>
              {Object.keys(templateDetails?.fields)
                ?.sort((a, b) =>
                  templateDetails.fields[a].order > templateDetails.fields[b].order ? 1 : -1
                )
                .map((fieldKey) => (
                  <TableCell
                    style={{
                      minWidth: fieldKey === 'attached_files' ? 'unset' : '150px',
                    }}
                    className={classes.th}
                    key={`header_${fieldKey}`}
                  >
                    {templateDetails.fields[fieldKey].label}{' '}
                    {templateDetails.fields[fieldKey].required ? '*' : ''}
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              <FieldArray
                name="friends"
                render={() => (
                  <>
                    {draftImportedData.map((row, index) => (
                      <TableRow
                        key={`draftImportedData_${index}`}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell key="cell_select_all" className={classes.cellSelect}>
                          <Checkbox
                            checked={selectedDraftImportedData.includes(index)}
                            onChange={() => {
                              if (selectedDraftImportedData.includes(index)) {
                                onCheckboxClick([
                                  ...selectedDraftImportedData.filter((el) => el !== index),
                                ]);
                              } else {
                                onCheckboxClick([...selectedDraftImportedData, index]);
                              }
                            }}
                          />
                        </TableCell>

                        {templateDetails &&
                          Object.keys(templateDetails?.fields)
                            ?.sort((a, b) =>
                              templateDetails.fields[a].order > templateDetails.fields[b].order
                                ? 1
                                : -1
                            )
                            .map((field, fIndex) => (
                              <TableCell
                                key={`cell_${index}_${field}_${fIndex}`}
                                component="th"
                                scope="row"
                              >
                                {field === 'attached_files' ? (
                                  <div className={classes.filesWrapper}>
                                    {Array.isArray(row[field]) && row[field].length > 0 && (
                                      <>
                                        <Tooltip
                                          title={t('documentActions.layers.fileList')}
                                          placement="top"
                                        >
                                          <div>
                                            <Badge
                                              id="basic-menu"
                                              onClick={(e) => handleOpenFileMenu(e, index)}
                                              anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                              }}
                                              badgeContent={row[field] ? row[field].length : 0}
                                            >
                                              <Fab size="small" color="primary">
                                                <AttachFile />
                                              </Fab>
                                            </Badge>
                                          </div>
                                        </Tooltip>
                                        <Menu
                                          id="basic-menu"
                                          anchorEl={anchorEl.anchor}
                                          open={
                                            anchorEl.index === index && Boolean(anchorEl.anchor)
                                          }
                                          onClose={(e) => handleCloseFileMenu(e, index)}
                                          MenuListProps={{
                                            'aria-labelledby': 'basic-button',
                                          }}
                                        >
                                          {row[field] &&
                                            row[field].map((file, fileIndex) => (
                                              <MenuItem
                                                onClick={() =>
                                                  onFileRemove({
                                                    index,
                                                    key: field,
                                                    value: row[field],
                                                    fileIndex,
                                                  })
                                                }
                                              >
                                                <ListItemIcon>
                                                  <DeleteForever fontSize="small" />
                                                </ListItemIcon>
                                                <ListItemText>
                                                  {file.name
                                                    ? file.name
                                                    : t('dropzones.importedFileWithIndex', {
                                                        value: fileIndex + 1,
                                                      })}
                                                </ListItemText>
                                              </MenuItem>
                                            ))}
                                        </Menu>
                                      </>
                                    )}
                                    <Tooltip title={t('common.addFile')} placement="top">
                                      <div>
                                        <DropzoneButton
                                          onDropSuccess={(e) => {
                                            onTableInputChange({
                                              index,
                                              key: field,
                                              value: [
                                                ...(Array.isArray(row[field]) ? row[field] : []),
                                                ...e,
                                              ],
                                            });
                                          }}
                                        />
                                      </div>
                                    </Tooltip>
                                  </div>
                                ) : (
                                  <MapField
                                    key={`field_${index}`}
                                    index={index}
                                    value={row[field]}
                                    name={field}
                                    format={templateDetails.fields[field].format}
                                    placeholder={templateDetails.fields[field].placeholder}
                                    error={
                                      touched?.fields?.[index]?.[field] &&
                                      Boolean(errors.fields?.[index]?.[field])
                                    }
                                    helperText={
                                      touched?.fields?.[index]?.[field] &&
                                      errors.fields?.[index]?.[field]
                                    }
                                    onFieldChange={(name, value) =>
                                      onTableInputChange({
                                        index,
                                        key: field,
                                        value,
                                      })
                                    }
                                  />
                                )}
                              </TableCell>
                            ))}
                      </TableRow>
                    ))}
                  </>
                )}
              />

              <TableRow
                key="new_row_add"
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row" />
                <TableCell component="th" scope="row">
                  <Button variant="text" onClick={onAddNewRow}>
                    {t('createCertificate.addNew')}
                  </Button>
                </TableCell>
              </TableRow>
            </>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default ImportDataTable;
