import { AxiosPromise } from 'axios';
import axios from 'src/helpers/axios';
import { parseError } from 'src/helpers/parseErrors';
import { API_MODULE } from 'src/models/common.model';
import { DocumentInvitationMemberRole } from 'src/models/documentUsersManagement.model';

type deleteInvitationByMailType = {
  service: API_MODULE;
  email: string;
  role: DocumentInvitationMemberRole;
  documentId: string;
};

const deleteInvitationByMail = ({
  documentId,
  role,
  email,
  service,
}: deleteInvitationByMailType): AxiosPromise =>
  axios
    .delete(
      `/rest/${service}/documents/${documentId}/roles/${role}/invitations?mail=${encodeURIComponent(
        email
      )}`
    )
    .then((response) => response)
    .catch((error) => parseError(error));

const documentInvitationsTsServices = {
  deleteInvitationByMail,
};

export default documentInvitationsTsServices;
