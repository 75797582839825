import React from 'react';
import { sortableElement } from 'react-sortable-hoc';
import PropTypes from 'prop-types';
import { ListItem } from '@material-ui/core';
import DragHandle from './DragHandle';

const SortableItem = sortableElement(({ allowItemsSorting, className, children }) => (
  <ListItem divider={false} style={{ zIndex: 100000 }} className={className}>
    {allowItemsSorting && <DragHandle />}
    {children}
  </ListItem>
));
SortableItem.propTypes = {
  children: PropTypes.node,
  allowItemsSorting: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

export default SortableItem;
