import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, TextField } from '@material-ui/core';
import { useStyles } from '../styles';

const AutocompleteInput = ({ params, placeholder, loading, focused }) => {
  const classes = useStyles();
  return (
    <TextField
      {...params}
      placeholder={placeholder}
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <>
            {loading && focused && <CircularProgress size={24} className={classes.inputLoader} />}
            {params.InputProps.endAdornment}
          </>
        ),
      }}
    />
  );
};

AutocompleteInput.propTypes = {
  params: PropTypes.shape({
    InputProps: PropTypes.shape({
      endAdornment: PropTypes.node,
    }),
  }),
  placeholder: PropTypes.string,
  loading: PropTypes.bool,
  focused: PropTypes.bool,
};

export default AutocompleteInput;
