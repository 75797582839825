import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ spacing }) =>
    createStyles({
      foldersTitle: {
        marginTop: spacing(3),
        marginBottom: spacing(2),
      },
      documentsTitle: {
        marginTop: spacing(3),
      },
      root: {
        '& *': {
          userSelect: 'none',
          '-webkit-user-select': 'none !important' /* Chrome, Opera, Safari */,
          '-moz-user-select': 'none !important' /* Firefox 2+ */,
          '-ms-user-select': 'none !important' /* IE 10+ */,
        },
      },
    }),
  { index: 0 }
);
