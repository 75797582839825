import { lighten } from '@material-ui/core/styles';
import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ breakpoints, palette, shadows, spacing, transitions }) =>
    createStyles({
      root: {
        zIndex: 10,
        position: 'sticky',
        top: `calc(100% - ${spacing(7)}px)`,
        left: 0,
        marginBottom: spacing(-7),
        display: 'flex',
        justifyContent: 'flex-end',
        transform: `translateX(${spacing(0.5)}px) translateY(${spacing(0.5)}px)`,
        [breakpoints.up('xs')]: {
          transform: 'none',
        },
      },
      button: {
        width: spacing(7),
        height: spacing(7),
        backgroundColor: palette.primary.main,
        color: palette.primary.contrastText,
        borderRadius: '50%',
        transition: transitions.create(['background-color', 'box-shadow', 'color', 'opacity'], {
          duration: transitions.duration.short,
        }),
        '&:hover': {
          backgroundColor: lighten(palette.primary.main, 0.075),
          boxShadow: shadows[6],
        },
        '&:focus, &:active': {
          backgroundColor: palette.primary.dark,
          boxShadow: shadows[15],
        },
        '&[disabled]': {
          backgroundColor: palette.action.disabledBackground,
          color: palette.action.disabled,
        },
      },
      item: {
        paddingLeft: spacing(2),
        paddingRight: spacing(2),
      },
      listItem: {
        minHeight: spacing(10),
        '&:not(:hover):not(:focus)': {
          '& $listItemText': {
            color: palette.text.primary,
          },
        },
      },
      listItemIcon: {
        marginRight: spacing(2),
        marginTop: -22,
      },
      listItemText: {
        whiteSpace: 'normal',
        lineHeight: 1.3,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      },
      listItemSecondaryAction: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
        right: spacing(2),
      },
      textDescription: {
        marginTop: spacing(0.5),
      },
    }),
  { index: 0 }
);
