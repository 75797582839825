export enum ErrorCode {
  IDENTITY_NOT_READY = 'IDENTITY_NOT_READY', // identity is not ready, not created by backend yet
  TOO_MANY_REQUESTS = 'TOO_MANY_REQUESTS', // too many requests in a short period of time
  NON_EMPTY_WORKSPACE_ONLY_OWNER = 'NON_EMPTY_WORKSPACE_ONLY_OWNER', // workspace owner can't be empty, occurs when user is the only one admin
  CONFLICT = 'CONFLICT', // subscription customer already exists
}

export type ErrorResponse = {
  code: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
  message: string;
};
