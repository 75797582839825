import { RequestStatus } from 'src/helpers/reduxReuquest.util';
import { documentUsersManagerConstants } from 'src/redux/constants/documentUsersManager.constants';
import {
  DocumentMember,
  DocumentMemberRole,
  DocumentSigner,
  DocumentUserAction,
  InvitedDocumentMember,
} from 'src/models/documentUsersManagement.model';
import { SingleEntity } from 'src/models/identity.model';
import { ExternalContactGroup, ExternalContactIdentity } from 'src/models/contacts.model';

export type DraftRolesType = { [key in DocumentMemberRole]: string[] };
export type DocumentUsersManagerState = {
  invitedDocumentMembers: InvitedDocumentMember[];
  invitedDocumentMembersStatus: RequestStatus;
  documentMembers: DocumentMember[];
  documentMembersStatus: RequestStatus;
  availableRoles: DocumentMemberRole[];
  availableRolesStatus: RequestStatus;
  documentSigners: DocumentSigner;
  documentSignersStatus: RequestStatus;
  searchedEntities: SingleEntity[];
  searchedEntitiesStatus: RequestStatus;
  externalContactEntities: { [key: string]: ExternalContactIdentity } | Record<string, never>;
  externalGroupEntities: { [key: string]: ExternalContactGroup } | Record<string, never>;
  externalEntitiesStatus: RequestStatus;
  availableDocumentActions: DocumentUserAction[];
  availableDocumentActionsStatus: RequestStatus;
  draftRoles: DraftRolesType;
  draftTransferability: boolean | null;
  signersChanged: boolean;
  otherRecipientsChanged: boolean;
};

const initialState = {
  invitedDocumentMembers: [],
  invitedDocumentMembersStatus: RequestStatus.IDLE,
  documentMembers: [],
  documentMembersStatus: RequestStatus.IDLE,
  availableRoles: [],
  availableRolesStatus: RequestStatus.IDLE,
  documentSigners: {
    ids: [],
    isOrderSignificant: false,
    requiredSignersCount: 0,
  },
  documentSignersStatus: RequestStatus.IDLE,
  searchedEntities: [],
  searchedEntitiesStatus: RequestStatus.IDLE,
  externalContactEntities: {},
  externalGroupEntities: {},
  externalEntitiesStatus: RequestStatus.IDLE,
  availableDocumentActions: [],
  availableDocumentActionsStatus: RequestStatus.IDLE,
  draftTransferability: null,
  draftRoles: {
    [DocumentMemberRole.HOLDER]: [],
    [DocumentMemberRole.SIGNER]: [],
    [DocumentMemberRole.VIEWER]: [],
    [DocumentMemberRole.EDITOR]: [],
    [DocumentMemberRole.AUDITOR]: [],
    [DocumentMemberRole.ADMIN]: [],
  },
  signersChanged: false,
  otherRecipientsChanged: false,
};

function documentUsersManagerStore(
  state: DocumentUsersManagerState = initialState,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any
  action: any // FIXME: fix this in next stage
): DocumentUsersManagerState {
  switch (action.type) {
    case documentUsersManagerConstants.FETCH_DOCUMENT_MEMBERS_REQUEST:
      return {
        ...state,
        documentMembersStatus: RequestStatus.PENDING,
      };
    case documentUsersManagerConstants.FETCH_DOCUMENT_MEMBERS_SUCCESS: {
      return {
        ...state,
        documentMembers: action.payload,
        documentMembersStatus: RequestStatus.IDLE,
      };
    }
    case documentUsersManagerConstants.FETCH_DOCUMENT_MEMBERS_FAILURE: {
      return {
        ...state,
        documentMembersStatus: RequestStatus.ERROR,
      };
    }

    case documentUsersManagerConstants.FETCH_AVAILABLE_ROLES_REQUEST:
      return {
        ...state,
        availableRolesStatus: RequestStatus.PENDING,
      };
    case documentUsersManagerConstants.FETCH_AVAILABLE_ROLES_SUCCESS: {
      return {
        ...state,
        availableRoles: action.payload,
        availableRolesStatus: RequestStatus.IDLE,
      };
    }
    case documentUsersManagerConstants.FETCH_AVAILABLE_ROLES_FAILURE: {
      return {
        ...state,
        availableRolesStatus: RequestStatus.ERROR,
      };
    }

    case documentUsersManagerConstants.FETCH_DOCUMENT_SIGNERS_REQUEST:
      return {
        ...state,
        documentSignersStatus: RequestStatus.PENDING,
      };
    case documentUsersManagerConstants.FETCH_DOCUMENT_SIGNERS_SUCCESS: {
      return {
        ...state,
        documentSigners: {
          ids: action.payload.documentSigners,
          requiredSignersCount: action.payload.requiredSignersCount,
          isOrderSignificant: action.payload.isOrderSignificant,
        },
        documentSignersStatus: RequestStatus.IDLE,
      };
    }
    case documentUsersManagerConstants.FETCH_DOCUMENT_SIGNERS_FAILURE: {
      return {
        ...state,
        documentSignersStatus: RequestStatus.ERROR,
      };
    }

    case documentUsersManagerConstants.FETCH_DOCUMENT_INVITATIONS_REQUEST:
      return {
        ...state,
        invitedDocumentMembersStatus: RequestStatus.PENDING,
      };
    case documentUsersManagerConstants.FETCH_DOCUMENT_INVITATIONS_SUCCESS: {
      return {
        ...state,
        invitedDocumentMembers: action.payload,
        invitedDocumentMembersStatus: RequestStatus.IDLE,
      };
    }
    case documentUsersManagerConstants.FETCH_DOCUMENT_INVITATIONS_FAILURE: {
      return {
        ...state,
        invitedDocumentMembersStatus: RequestStatus.ERROR,
      };
    }

    case documentUsersManagerConstants.FETCH_ENTITIES_BY_NAME_REQUEST:
      return {
        ...state,
        searchedEntitiesStatus: RequestStatus.PENDING,
      };
    case documentUsersManagerConstants.FETCH_ENTITIES_BY_NAME_SUCCESS: {
      return {
        ...state,
        searchedEntities: action.payload,
        searchedEntitiesStatus: RequestStatus.IDLE,
      };
    }
    case documentUsersManagerConstants.FETCH_ENTITIES_BY_NAME_FAILURE: {
      return {
        ...state,
        searchedEntitiesStatus: RequestStatus.ERROR,
      };
    }

    case documentUsersManagerConstants.FETCH_DOCUMENT_ENTITIES_REQUEST:
      return {
        ...state,
        externalEntitiesStatus: RequestStatus.PENDING,
      };
    case documentUsersManagerConstants.FETCH_DOCUMENT_ENTITIES_SUCCESS: {
      return {
        ...state,
        externalContactEntities: action.payload.contacts,
        externalGroupEntities: action.payload.groups,
        externalEntitiesStatus: RequestStatus.IDLE,
      };
    }
    case documentUsersManagerConstants.FETCH_DOCUMENT_ENTITIES_FAILURE: {
      return {
        ...state,
        externalEntitiesStatus: RequestStatus.ERROR,
      };
    }

    case documentUsersManagerConstants.FETCH_AVAILABLE_DOCUMENT_ACTIONS_REQUEST:
      return {
        ...state,
        availableDocumentActionsStatus: RequestStatus.PENDING,
      };
    case documentUsersManagerConstants.FETCH_AVAILABLE_DOCUMENT_ACTIONS_SUCCESS: {
      return {
        ...state,
        availableDocumentActions: action.payload,
        availableDocumentActionsStatus: RequestStatus.SUCCESS,
      };
    }
    case documentUsersManagerConstants.FETCH_AVAILABLE_DOCUMENT_ACTIONS_FAILURE: {
      return {
        ...state,
        availableDocumentActionsStatus: RequestStatus.ERROR,
      };
    }
    case documentUsersManagerConstants.UPDATE_DRAFT_ROLES: {
      return {
        ...state,
        draftRoles: {
          ...state.draftRoles,
          [action.payload.role]: action.payload.ids,
        },
      };
    }
    case documentUsersManagerConstants.UPDATE_DRAFT_TRANSFERABILITY: {
      return {
        ...state,
        draftTransferability: action.payload,
      };
    }
    case documentUsersManagerConstants.UPDATE_SIGNERS_CHANGED: {
      return {
        ...state,
        signersChanged: action.payload,
      };
    }
    case documentUsersManagerConstants.UPDATE_OTHER_RECIPIENTS_CHANGED: {
      return {
        ...state,
        otherRecipientsChanged: action.payload,
      };
    }

    case documentUsersManagerConstants.CLEAR:
      return {
        ...initialState,
        availableDocumentActions: state.availableDocumentActions,
        draftRoles: state.draftRoles,
        draftTransferability: state.draftTransferability,
        otherRecipientsChanged: state.otherRecipientsChanged,
        signersChanged: state.signersChanged,
      };

    default:
      return state;
  }
}

export default documentUsersManagerStore;
