import { makeStyles, createStyles } from '@material-ui/core';
import custom from 'src/theme/options/custom';

export const useStyles = makeStyles(
  ({ palette, breakpoints }) =>
    createStyles({
      content: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        position: 'relative',
        height: '100%',
        width: '100%',
      },
      centerWrapper: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        width: '100%',
      },
      drawer: {
        position: 'relative',
        width: custom.documentEditSidebarWidth,
        maxWidth: '40%',
        flexShrink: 0,
        overflow: 'hidden',

        [breakpoints.down('sm')]: {
          maxWidth: '100%',
          height: '93%',
          margin: '0 auto',
        },
      },
      drawerPaper: {
        position: 'relative',
        width: '100%',
        borderLeft: `1px solid ${palette.divider}`,
        overflowX: 'hidden',
        overflowY: 'auto',
        flex: 'auto',
        padding: 40,
        [breakpoints.down('sm')]: {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          padding: '20px 0',
          height: '100%',
        },
      },
      wrapper: {
        display: 'flex',
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
      },
    }),
  { index: 0 }
);
