import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { Fab, InputAdornment } from '@material-ui/core';
import { func } from 'prop-types';
import { Upload } from 'src/components/CustomIcon';
import snackbarActions from 'src/redux/actions/snackbar.actions';
import { SNACKBAR_VARIANT } from 'src/models/common.model';
import { acceptableFileTypes, dropzoneErrors, maxFiles, maxSize } from './config';
import { toBase64 } from '../../../../../helpers/globalUtils';

const DropzoneButton = ({ onDropSuccess }) => {
  const dispatch = useDispatch();

  const onDrop = useCallback(
    async (acceptedFiles) => {
      const files = await Promise.all(
        acceptedFiles.map(async (file) => ({
          base64: await toBase64(file),
          name: file.name,
        }))
      );
      onDropSuccess(files.map((file) => ({ ...file, base64: file.base64.split(',')[1] })));
    },
    [onDropSuccess]
  );

  const onDropRejected = useCallback(
    (rejectedFiles) => {
      if (rejectedFiles && rejectedFiles.length) {
        rejectedFiles
          .map((el) => el.errors)
          .flat()
          .map((err) => err.code)
          .filter((v, i, a) => a.indexOf(v) === i)
          .forEach((error) => {
            dispatch(
              snackbarActions.enqueueSnackbar(SNACKBAR_VARIANT.ERROR, dropzoneErrors[error])
            );
          });
      }
    },
    [dispatch]
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptableFileTypes,
    maxFiles,
    maxSize,
    onDrop,
    onDropRejected,
    multiple: true,
  });

  return (
    <InputAdornment {...getRootProps()} position="end">
      <input {...getInputProps()} />
      <Fab size="small" color="primary">
        <Upload />
      </Fab>
    </InputAdornment>
  );
};

DropzoneButton.propTypes = {
  onDropSuccess: func,
};

export default DropzoneButton;
