import React from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Typography } from '@material-ui/core';
import { ContentContainer, ExtendedGrid } from 'src/components';
import clsx from 'clsx';
import { useStyles } from './styles';

const MethodItem = ({
  className: classNameProp,
  icon,
  name,
  header,
  subtitle,
  actionButtonText,
  additionalActions,
  inActive,
  onClick,
  disabled,
}) => {
  const hasAdditionalActions = !!additionalActions;
  const classes = useStyles();
  return (
    <Card
      variant="outlined"
      className={clsx(
        classes.root,
        disabled && classes.disabled,
        inActive && classes.inActive,
        classNameProp
      )}
    >
      <ContentContainer size="medium">
        <ExtendedGrid container spacing={2} alignItemsXxs="center">
          <ExtendedGrid
            item
            xxs={12}
            sm={12}
            md={12}
            lg={3}
            textAlignXxs="center"
            justifyContentXxs="center"
            justifyContentMd="flex-start"
            textAlignLg="left"
          >
            <div className={classes.nameBox}>
              {icon && <div className={classes.icon}>{icon}</div>}
              {name && (
                <Typography
                  className={clsx(classes.mediumText, classes.colorOnHover)}
                  variant="h6"
                  color="initial"
                  component="label"
                >
                  {name}
                </Typography>
              )}
            </div>
          </ExtendedGrid>

          <ExtendedGrid
            item
            xxs={12}
            sm={12}
            md={12}
            lg={hasAdditionalActions ? 4 : 5}
            xl={4}
            textAlignXxs="center"
            textAlignMd="center"
            textAlignLg="left"
          >
            {header && (
              <Typography variant="body2" gutterBottom={!!subtitle}>
                <strong>{header}</strong>
              </Typography>
            )}
            {subtitle && <Typography variant="body2">{subtitle}</Typography>}
          </ExtendedGrid>

          <ExtendedGrid item xxs={12} sm={12} md textAlignXxs="center" textAlignLg="right">
            {hasAdditionalActions && (
              <div className={classes.additionalActionButtonsWrapper}>
                {additionalActions.map((action, index) => (
                  <Button
                    key={index}
                    variant="text"
                    size="small"
                    endIcon={action.icon}
                    classes={{
                      root: classes.additionalActionButton,
                      endIcon: classes.additionalActionButtonIcon,
                    }}
                    onClick={action.onClick}
                  >
                    {action.name}
                  </Button>
                ))}
              </div>
            )}
            <Button className={classes.actionButton} onClick={onClick} disabled={disabled}>
              {actionButtonText}
            </Button>
          </ExtendedGrid>
        </ExtendedGrid>
      </ContentContainer>
    </Card>
  );
};

MethodItem.propTypes = {
  className: PropTypes.string,
  inActive: PropTypes.bool,
  icon: PropTypes.node,
  name: PropTypes.string,
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  subtitle: PropTypes.string,
  actionButtonText: PropTypes.string,
  additionalActions: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.node,
      name: PropTypes.string,
      onClick: PropTypes.func,
    })
  ),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

MethodItem.defaultProps = {
  inActive: false,
  disabled: false,
};

export default MethodItem;
