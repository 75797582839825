export default [
  'none',
  '0 1px 3px 0 rgba(0, 0, 0, 0.04), 0 1px 1px 1px rgba(0, 0, 0, 0.024), 0 2px 0 -1px rgba(0, 0, 0, 0.02)',
  '0 1px 5px 0 rgba(0, 0, 0, 0.04), 0 2px 3px 1px rgba(0, 0, 0, 0.024), 0 3px 0 -2px rgba(0, 0, 0, 0.02)',
  '0 1px 8px 0 rgba(0, 0, 0, 0.04), 0 3px 5px 1px rgba(0, 0, 0, 0.024), 0 3px 1px -2px rgba(0, 0, 0, 0.02)',
  '0 2px 4px -1px rgba(0, 0, 0, 0.04), 0 4px 6px 1px rgba(0, 0, 0, 0.024), 0 1px 8px 0 rgba(0, 0, 0, 0.02)',
  '0 3px 5px -1px rgba(0, 0, 0, 0.04), 0 5px 10px 1px rgba(0, 0, 0, 0.024), 0 1px 12px 0 rgba(0, 0, 0, 0.02)',
  '0 3px 5px -1px rgba(0, 0, 0, 0.04), 0 6px 12px 1px rgba(0, 0, 0, 0.024), 0 1px 16px 0 rgba(0, 0, 0, 0.02)',
  '0 4px 5px -2px rgba(0, 0, 0, 0.04), 0 6px 12px 2px rgba(0, 0, 0, 0.024), 0 2px 14px 1px rgba(0, 0, 0, 0.02)',
  '0 5px 5px -3px rgba(0, 0, 0, 0.04), 0 7px 12px 2px rgba(0, 0, 0, 0.024), 0 3px 12px 2px rgba(0, 0, 0, 0.02)',
  '0 5px 6px -3px rgba(0, 0, 0, 0.04), 0 7px 14px 2px rgba(0, 0, 0, 0.024), 0 3px 14px 2px rgba(0, 0, 0, 0.02)',
  '0 6px 6px -3px rgba(0, 0, 0, 0.04), 0 8px 17px 2px rgba(0, 0, 0, 0.024), 0 4px 16px 3px rgba(0, 0, 0, 0.02)',
  '0 6px 7px -4px rgba(0, 0, 0, 0.04), 0 8px 18px 2px rgba(0, 0, 0, 0.024), 0 4px 18px 3px rgba(0, 0, 0, 0.02)',
  '0 7px 8px -4px rgba(0, 0, 0, 0.04), 0 9px 21px 3px rgba(0, 0, 0, 0.024), 0 5px 20px 4px rgba(0, 0, 0, 0.02)',
  '0 7px 8px -4px rgba(0, 0, 0, 0.04), 0 9px 25px 3px rgba(0, 0, 0, 0.024), 0 5px 22px 4px rgba(0, 0, 0, 0.02)',
  '0 7px 9px -4px rgba(0, 0, 0, 0.04), 0 10px 26px 3px rgba(0, 0, 0, 0.024), 0 5px 24px 4px rgba(0, 0, 0, 0.02)',
  '0 8px 9px -5px rgba(0, 0, 0, 0.04), 0 11px 27px 3px rgba(0, 0, 0, 0.024), 0 6px 26px 5px rgba(0, 0, 0, 0.02)',
  '0 8px 10px -5px rgba(0, 0, 0, 0.04), 0 12px 29px 3px rgba(0, 0, 0, 0.024), 0 6px 28px 5px rgba(0, 0, 0, 0.02)',
  '0 8px 11px -5px rgba(0, 0, 0, 0.04), 0 13px 31px 3px rgba(0, 0, 0, 0.024), 0 6px 30px 5px rgba(0, 0, 0, 0.02)',
  '0 9px 11px -5px rgba(0, 0, 0, 0.04), 0 14px 33px 3px rgba(0, 0, 0, 0.024), 0 7px 32px 6px rgba(0, 0, 0, 0.02)',
  '0 9px 12px -6px rgba(0, 0, 0, 0.04), 0 15px 35px 3px rgba(0, 0, 0, 0.024), 0 7px 34px 6px rgba(0, 0, 0, 0.02)',
  '0 10px 13px -6px rgba(0, 0, 0, 0.04), 0 16px 37px 4px rgba(0, 0, 0, 0.024), 0 8px 36px 7px rgba(0, 0, 0, 0.02)',
  '0 10px 13px -6px rgba(0, 0, 0, 0.04), 0 17px 40px 4px rgba(0, 0, 0, 0.024), 0 8px 38px 7px rgba(0, 0, 0, 0.02)',
  '0 10px 14px -6px rgba(0, 0, 0, 0.04), 0 18px 42px 4px rgba(0, 0, 0, 0.024), 0 8px 40px 7px rgba(0, 0, 0, 0.02)',
  '0 11px 14px -7px rgba(0, 0, 0, 0.04), 0 19px 43px 4px rgba(0, 0, 0, 0.024), 0 9px 42px 8px rgba(0, 0, 0, 0.02)',
  '0 11px 15px -7px rgba(0, 0, 0, 0.04), 0 20px 46px 4px rgba(0, 0, 0, 0.024), 0 9px 44px 8px rgba(0, 0, 0, 0.02)',
];
