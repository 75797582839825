import { makeStyles, createStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ palette }) =>
    createStyles({
      label: {
        marginBottom: 8,
      },
      formControl: {
        marginBottom: 12,
        width: '100%',
        '& .MuiFormControl-root': {
          width: '100%',
        },
      },
      buttonUploadActive: {
        color: `${palette.text.primary}!important`,
      },
      buttonUpload: {
        textAlign: 'left',
        padding: 12,
        justifyContent: 'space-between',
        minHeight: 48,
        borderRadius: 6,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: palette.greySimple.light,
        color: palette.greySimple.main,
        fontSize: '1rem',
        fontWeight: 400,
        '& .MuiButton-endIcon': {
          color: 'rgba(0, 0, 0, 0.54)!important',
        },
        '&:hover': {
          borderColor: palette.primary.main,
          boxShadow: 'none',
        },
      },
      buttonUploadError: {
        borderColor: palette.error.main,
      },
    }),
  { index: 0 }
);
