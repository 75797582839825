import React from 'react';
import { Fab, Tooltip, Typography, useMediaQuery } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { AvatarAndTextItem } from 'src/components';
import { DocumentRole } from 'src/models/document.model';
import { renderOptionDescription } from '../helpers';
import RoleSelection from './RoleSelection';
import { Close as RemoveIcon } from '../../CustomIcon';
import { useStyles } from '../styles';

const AddAndRemoveUsersListItem = ({
  user,
  onDeleteClick,
  currentIdentityId,
  workspaceId,
  documentInvitationMode,
  authorAction,
  disableInviting,
  onRoleUpdate,
  availableRoles,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const mobileScreen = useMediaQuery(({ breakpoints }) => breakpoints.down('xs'));

  const { name, email, identityId /* src */, isAuthor, id, role, invitedRole, hideRemove } = {
    ...user,
    id: user?.id || user?.identityId,
  };

  return (
    <>
      <AvatarAndTextItem
        classes={{
          root: classes.addedListItemContent,
          additionalText: classes.addedListItemAdditionalContent,
        }}
        size="medium"
        description={renderOptionDescription({
          option: {
            ...user,
            id: user?.id || user?.identityId,
          },
          workspaceId,
          documentInvitationMode,
        })}
        text={name !== email ? name : email}
        additionalText={currentIdentityId === identityId ? `(${t('common.you')})` : null}
        mainImageSrc={
          identityId
            ? `${process.env.REACT_APP_AVATAR_URL}/identity/${identityId}?kind=SMALL`
            : null
        }
      />
      <div className={classes.addedListItemAction}>
        {isAuthor && (
          <div className={classes.authorBox}>
            {authorAction}
            {!mobileScreen && (
              <Typography variant="body2" component="div">
                {t('common.author')}
              </Typography>
            )}
          </div>
        )}
        {role && availableRoles && (
          <div className={classes.authorBox}>
            <RoleSelection
              role={role}
              availableRoles={availableRoles}
              handleRoleChange={(role) => onRoleUpdate(id, role)}
              active={!invitedRole}
            />
          </div>
        )}
        {!disableInviting && !isAuthor && !hideRemove && (
          <Tooltip title={t('common.remove')}>
            <Fab
              size="small"
              color="primary"
              className={classes.removeButton}
              onClick={
                typeof onDeleteClick === 'function'
                  ? () => {
                      !invitedRole
                        ? onDeleteClick(id, false, role || DocumentRole.SIGNERS)
                        : onDeleteClick(name, true, role || DocumentRole.SIGNERS);
                    }
                  : undefined
              }
            >
              <RemoveIcon />
            </Fab>
          </Tooltip>
        )}
      </div>
    </>
  );
};

AddAndRemoveUsersListItem.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    identityId: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    isAuthor: PropTypes.bool,
    role: PropTypes.string,
    invitedRole: PropTypes.bool,
  }),
  onDeleteClick: PropTypes.func,
  onRoleUpdate: PropTypes.func,
  availableRoles: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  currentIdentityId: PropTypes.string,
  documentInvitationMode: PropTypes.bool,
  disableInviting: PropTypes.bool,
  authorAction: PropTypes.node,
  workspaceId: PropTypes.string,
};

export default AddAndRemoveUsersListItem;
