enum twoFactorConfigConstants {
  GET_TWO_FACTOR_CONFIG_REQUEST = 'GET_TWO_FACTOR_CONFIG_REQUEST',
  GET_TWO_FACTOR_CONFIG_SUCCESS = 'GET_TWO_FACTOR_CONFIG_SUCCESS',
  GET_TWO_FACTOR_CONFIG_ERROR = 'GET_TWO_FACTOR_CONFIG_ERROR',

  CHANGE_TWO_FACTOR_ACTIVE_TYPE_REQUEST = 'CHANGE_TWO_FACTOR_ACTIVE_TYPE_REQUEST',
  CHANGE_TWO_FACTOR_ACTIVE_TYPE_SUCCESS = 'CHANGE_TWO_FACTOR_ACTIVE_TYPE_SUCCESS',
  CHANGE_TWO_FACTOR_ACTIVE_TYPE_ERROR = 'CHANGE_TWO_FACTOR_ACTIVE_TYPE_ERROR',

  DELETE_TWO_FACTOR_TYPE_REQUEST = 'DELETE_TWO_FACTOR_TYPE_REQUEST',
  DELETE_TWO_FACTOR_TYPE_SUCCESS = 'DELETE_TWO_FACTOR_TYPE_SUCCESS',
  DELETE_TWO_FACTOR_TYPE_ERROR = 'DELETE_TWO_FACTOR_TYPE_ERROR',

  INIT_TWO_FACTOR_TYPE_CHANGE_REQUEST = 'INIT_TWO_FACTOR_TYPE_CHANGE_REQUEST',
  INIT_TWO_FACTOR_TYPE_CHANGE_SUCCESS = 'INIT_TWO_FACTOR_TYPE_CHANGE_SUCCESS',
  INIT_TWO_FACTOR_TYPE_CHANGE_ERROR = 'INIT_TWO_FACTOR_TYPE_CHANGE_ERROR',

  CONFIRM_TWO_FACTOR_TYPE_CHANGE_REQUEST = 'CONFIRM_TWO_FACTOR_TYPE_CHANGE_REQUEST',
  CONFIRM_TWO_FACTOR_TYPE_CHANGE_SUCCESS = 'CONFIRM_TWO_FACTOR_TYPE_CHANGE_SUCCESS',
  CONFIRM_TWO_FACTOR_TYPE_CHANGE_ERROR = 'CONFIRM_TWO_FACTOR_TYPE_CHANGE_ERROR',

  CLEAR_CONFIRM_TWO_FACTOR_TYPE_STATUS = 'CLEAR_CONFIRM_TWO_FACTOR_TYPE_STATUS',
  CLEAR_STORE = 'CLEAR_STORE',
}

export default twoFactorConfigConstants;
