import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import identityActions from 'src/redux/actions/identity.actions';
import avatarActions from 'src/redux/actions/avatar.actions';
import { Card, CardContent, List, Typography, useMediaQuery } from '@material-ui/core';
import { Avatar, FullScreenDialog } from 'src/components';
import { Copy as CopyIcon } from 'src/components/CustomIcon';
import { BasicItem } from 'src/pages/UserConfiguration/subpages/Basic/subcomponents';
import { AvatarEdit } from 'src/pages/UserConfiguration/subpages/Basic/editDialogs';
import usePrevious from 'src/helpers/hooks/usePrevious';
import { RequestStatus } from 'src/helpers/reduxReuquest.util';
import { SNACKBAR_VARIANT } from 'src/models/common.model';
import snackbarActions from 'src/redux/actions/snackbar.actions';
import { ContentHeading } from '../../subcomponents';
import { EditName, DeleteAvatar } from './editDialogs';
import { useStyles } from '../styles';

const Settings = () => {
  const { t } = useTranslation();
  const smallScreen = useMediaQuery(({ breakpoints }) => breakpoints.down('sm'));
  const dispatch = useDispatch();
  const classes = useStyles();
  const currentIdentity = useSelector((state) => state.identityStore.currentIdentity);
  const workspaceInfo = useSelector((state) => state.identityStore.workspaceInfo);
  const workspaceInfoStatus = useSelector((state) => state.identityStore.workspaceInfoStatus);
  const updateWorkspaceInfoStatus = useSelector(
    (state) => state.identityStore.updateWorkspaceInfoStatus
  );
  const workspaceDetails = useSelector((state) => state.identityStore.workspaceDetails);
  const updateWorkspaceDetailsStatus = useSelector(
    (state) => state.identityStore.updateWorkspaceDetailsStatus
  );
  const putWorkspaceAvatarRequestStatus = useSelector(
    (state) => state.avatarStore.putWorkspaceAvatarRequestStatus
  );
  const structureIdentity = useSelector((state) => state.identityStore.structure);
  const workspaceAvatar = useSelector((state) => state.avatarStore.workspaceAvatar);

  const availableIdentities = structureIdentity
    ? structureIdentity.filter((structure) => structure.identity.active)
    : structureIdentity;

  const [editedSubpage, setEditedSubpage] = useState(null);
  const [deleteAvatarDialogOpen, setDeleteAvatarDialogOpen] = useState(false);
  const [titleDialog, setTitleDialog] = useState('Title');
  const [workspaceData, updateWorkspaceData] = useState({
    name: '',
  });

  useEffect(() => {
    if (currentIdentity) {
      dispatch(identityActions.fetchWorkspaceInfo(currentIdentity?.workspace?.workspaceId));
    }
  }, [dispatch, currentIdentity]);

  useEffect(() => {
    if (currentIdentity) {
      dispatch(avatarActions.getWorkspaceAvatar(currentIdentity?.workspace?.workspaceId));
    }
  }, [dispatch, currentIdentity]);

  const prevInfoStatus = usePrevious(workspaceInfoStatus);
  const prevDetailsStatus = usePrevious(updateWorkspaceDetailsStatus);
  const prevUpdateInfoStatus = usePrevious(updateWorkspaceInfoStatus);
  const prevPutWorkspaceAvatarRequestStatus = usePrevious(putWorkspaceAvatarRequestStatus);

  useEffect(() => {
    if (prevInfoStatus === RequestStatus.PENDING && workspaceInfoStatus === RequestStatus.SUCCESS) {
      updateWorkspaceData({
        name: workspaceDetails.name,
      });
    }
  }, [
    workspaceData,
    workspaceInfo,
    updateWorkspaceData,
    workspaceDetails,
    prevInfoStatus,
    workspaceInfoStatus,
  ]);
  const editWorkspaceData = (workspace, type) => {
    const workspaceId = currentIdentity?.workspace?.workspaceId;
    if (type === 'name') {
      dispatch(
        identityActions.updateWorkspaceDetails(workspaceId, {
          name: workspace.name,
          description: '',
        })
      );
    }
  };

  const copyWorkspaceId = () => {
    dispatch(
      snackbarActions.enqueueSnackbar(SNACKBAR_VARIANT.INFO, t('workspacePage.settings.copied'))
    );
    navigator.clipboard.writeText(workspaceDetails.workspaceId).then();
  };

  useEffect(() => {
    if (prevDetailsStatus === RequestStatus.PENDING) {
      if (updateWorkspaceDetailsStatus === RequestStatus.SUCCESS) {
        handleBack();
        updateWorkspaceData({
          ...workspaceData,
          name: workspaceDetails.name,
        });
      }
    }
  }, [
    updateWorkspaceDetailsStatus,
    prevDetailsStatus,
    t,
    dispatch,
    workspaceData,
    workspaceDetails,
  ]);

  useEffect(() => {
    if (prevUpdateInfoStatus === RequestStatus.PENDING) {
      if (updateWorkspaceInfoStatus === RequestStatus.SUCCESS) {
        handleBack();
        updateWorkspaceData({
          ...workspaceData,
        });
      }
    }
  }, [updateWorkspaceInfoStatus, dispatch, prevUpdateInfoStatus, t, workspaceData, workspaceInfo]);

  useEffect(() => {
    if (prevPutWorkspaceAvatarRequestStatus === RequestStatus.PENDING) {
      if (putWorkspaceAvatarRequestStatus === RequestStatus.SUCCESS) {
        handleBack();
        dispatch(avatarActions.getWorkspaceAvatar(currentIdentity?.workspace?.workspaceId));
        avatarActions.refreshAvatars(
          availableIdentities.map((structure) => structure.workspace.workspaceId),
          'workspace'
        );
      }
    }
  }, [
    dispatch,
    prevPutWorkspaceAvatarRequestStatus,
    putWorkspaceAvatarRequestStatus,
    currentIdentity,
    availableIdentities,
  ]);

  const deleteAvatarDialogToggle = {
    open: () => {
      setDeleteAvatarDialogOpen(true);
    },
    close: () => {
      setDeleteAvatarDialogOpen(false);
    },
  };

  const handleBack = () => {
    setEditedSubpage(null);
  };

  const updateWorkspaceAvatar = (name, value) => {
    dispatch(avatarActions.updateWorkspaceAvatar(currentIdentity?.workspace?.workspaceId, value));
  };

  const renderEditPage = (name) => {
    switch (name) {
      case 'avatar':
        return (
          <AvatarEdit
            updateBasicData={updateWorkspaceAvatar}
            isLoading={putWorkspaceAvatarRequestStatus === RequestStatus.PENDING}
          />
        );
      case 'name':
        return (
          <EditName
            workspace={workspaceData}
            update={(data) => editWorkspaceData(data, 'name')}
            isPending={updateWorkspaceDetailsStatus === RequestStatus.PENDING}
          />
        );
      default:
        return null;
    }
  };

  const renderSummaryPage = () => (
    <div>
      <ContentHeading>{t('workspacePage.drawer.basicSettings')}</ContentHeading>

      <Card variant="outlined" className={classes.root}>
        <CardContent>
          <List>
            <BasicItem
              smallScreen={smallScreen}
              label={t('workspacePage.settings.logo')}
              oneRowMobile
              onClick={() => {
                setEditedSubpage('avatar');
                setTitleDialog(t('workspacePage.editModals.title3'));
              }}
              onDeleteClick={workspaceAvatar ? deleteAvatarDialogToggle.open : null}
            >
              <Avatar
                size="medium"
                src={
                  currentIdentity && workspaceAvatar
                    ? `${process.env.REACT_APP_AVATAR_URL}/workspace/${currentIdentity?.workspace?.workspaceId}?kind=SMALL`
                    : null
                }
              >
                {workspaceData.name}
              </Avatar>
            </BasicItem>

            <BasicItem
              smallScreen={smallScreen}
              label={t('workspacePage.settings.name')}
              onClick={() => {
                setEditedSubpage('name');
                setTitleDialog(t('workspacePage.editModals.title1'));
              }}
            >
              <Typography>
                <strong>{workspaceData.name}</strong>
              </Typography>
            </BasicItem>
            <BasicItem
              smallScreen={smallScreen}
              label={t('workspacePage.settings.workspaceId')}
              onClick={copyWorkspaceId}
              icon={<CopyIcon />}
              buttonText={t('workspacePage.settings.copy')}
            >
              <Typography>
                <strong>{workspaceDetails.workspaceId}</strong>
              </Typography>
            </BasicItem>
          </List>
        </CardContent>
      </Card>
      <DeleteAvatar
        open={deleteAvatarDialogOpen}
        onClose={deleteAvatarDialogToggle.close}
        workspaceId={currentIdentity?.workspace?.workspaceId}
      />
    </div>
  );

  return (
    <>
      {renderSummaryPage()}
      <FullScreenDialog
        isOpen={!!editedSubpage}
        onBack={handleBack}
        onClose={handleBack}
        title={titleDialog}
      >
        {editedSubpage && (
          <div className={classes.fullScreenDialog}>
            <div className={classes.content}>{renderEditPage(editedSubpage)}</div>
          </div>
        )}
      </FullScreenDialog>
    </>
  );
};

export default Settings;
