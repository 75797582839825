import React, { MouseEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Menu, MenuItem } from '@material-ui/core';

export enum DateDropdownType {
  ANY = 'any',
  TODAY = 'today',
  YESTERDAY = 'yesterday',
  LAST_7_DAYS = 'last7Days',
  LAST_30_DAYS = 'last30Days',
  BETWEEN = 'between',
}
type DateDropdownProps = {
  selectedItem: DateDropdownType;
  children: JSX.Element;
  onSelect: (newSelected: DateDropdownType) => void;
};

const DateDropdown = ({ selectedItem, onSelect, children }: DateDropdownProps): JSX.Element => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const options = useMemo(
    () => [
      {
        value: DateDropdownType.ANY,
        label: t('advancedSearch.dateOptions.anyTime'),
        divider: true,
      },
      {
        value: DateDropdownType.TODAY,
        label: t('advancedSearch.dateOptions.today'),
        divider: false,
      },
      {
        value: DateDropdownType.YESTERDAY,
        label: t('advancedSearch.dateOptions.yesterday'),
        divider: true,
      },
      {
        value: DateDropdownType.LAST_7_DAYS,
        label: t('advancedSearch.dateOptions.last7Days'),
        divider: false,
      },
      {
        value: DateDropdownType.LAST_30_DAYS,
        label: t('advancedSearch.dateOptions.last30Days'),
        divider: true,
      },
      {
        value: DateDropdownType.BETWEEN,
        label: t('advancedSearch.dateOptions.between'),
        divider: false,
      },
    ],
    [t]
  );

  const handleClick = (e: MouseEvent<HTMLElement>) => {
    if (e) setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onItemClick = (val: DateDropdownType) => {
    onSelect(val);
    handleClose();
  };

  const isOpen = Boolean(anchorEl);

  return (
    <>
      <div aria-hidden="true" onClick={handleClick}>
        {children}
      </div>
      <Menu
        open={isOpen}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        autoFocus={false}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handleClose}
      >
        {options.map((option) => (
          <>
            <MenuItem
              style={{ width: anchorEl?.offsetWidth }}
              key={option.value}
              divider={false}
              selected={selectedItem === option.value}
              onClick={() => onItemClick(option.value)}
            >
              {option.label}
            </MenuItem>
            {option.divider && <Divider />}
          </>
        ))}
      </Menu>
    </>
  );
};

export default DateDropdown;
