import React from 'react';
import { Button, createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ palette }) =>
    createStyles({
      button: {
        backgroundColor: 'transparent',
        color: palette.secondary.dark,
        transition: 'color .3s ease',
        '&:hover': {
          backgroundColor: 'transparent',
          color: palette.primary.main,
          boxShadow: 'unset',
        },
      },
    }),
  { index: 0 }
);

interface SectionButtonProps {
  icon?: JSX.Element;
  text: string;
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
}

const SectionButton = ({ icon, text, onClick }: SectionButtonProps): JSX.Element => {
  const classes = useStyles();
  return (
    <Button className={classes.button} startIcon={icon} color="default" onClick={onClick}>
      {text}
    </Button>
  );
};

export default SectionButton;
