import i18n from 'src/helpers/i18n';
import cookieHelper from 'src/helpers/cookieHelper';
import { SNACKBAR_VARIANT } from 'src/models/common.model';
import documentManagementConstants from '../constants/documentManagement.constants';
import documentCoreService from '../services/documentCore.service';
import blockchainExplorerService from '../services/blockchainExplorer.service';
import identityActions from './identity.actions';
import snackbarActions from './snackbar.actions';
import { documentUsersManagerConstants } from '../constants/documentUsersManager.constants';

const documentManagementActions = {
  renameDocument,
  signDocument,
  rejectDocument,
  getHistoryDocument,
  reloadDocumentHistory,
  getDocumentMembers,
  getDocumentInvitations,
  getDocumentMembersByRole,
  getSignatures,
  getDocumentCard,
  websocketUpdate,
  clearDocumentHistory,
  clearDocumentManagementStore,
};

function renameDocument({ documentId, name, callbackOnSuccess, service }) {
  return (dispatch) => {
    dispatch({ type: documentManagementConstants.RENAME_DOCUMENT_REQUEST });
    documentCoreService
      .renameDocument(documentId, name, service)
      .then(() => {
        dispatch({
          type: documentManagementConstants.RENAME_DOCUMENT_SUCCESS,
          name,
        });
        dispatch(
          snackbarActions.enqueueSnackbar(
            SNACKBAR_VARIANT.SUCCESS,
            i18n.t('snackbars.common.successTitle'),
            i18n.t('documentActions.renameDocument.toast.success')
          )
        );
        if (typeof callbackOnSuccess === 'function') {
          dispatch(callbackOnSuccess);
        }
      })
      .catch((error) => {
        dispatch({ type: documentManagementConstants.RENAME_DOCUMENT_FAILURE });
        dispatch(
          snackbarActions.enqueueSnackbar(
            SNACKBAR_VARIANT.ERROR,
            i18n.t('snackbars.common.errorTitle'),
            `${i18n.t('snackbars.common.error')} ${error.code}.`
          )
        );
      });
  };
}

function signDocument(documentId, callbackOnSuccess, invalidSignersCountCallback) {
  return (dispatch) => {
    dispatch({ type: documentManagementConstants.SIGN_DOCUMENT_REQUEST });
    documentCoreService
      .signDocument(documentId)
      .then((response) => {
        dispatch({
          type: documentManagementConstants.SIGN_DOCUMENT_SUCCESS,
          data: response.data,
        });
        dispatch(identityActions.sendTransactionFromNewDocument(response.data.txn));
        dispatch(
          snackbarActions.enqueueSnackbar(
            SNACKBAR_VARIANT.INFO,
            i18n.t('snackbars.common.infoTitle'),
            i18n.t('documentActions.signDocument.toast.commissioned')
          )
        );
        if (typeof callbackOnSuccess === 'function') {
          dispatch(callbackOnSuccess);
        }
      })
      .catch((error) => {
        if (error.code === 'INVALID_SIGNERS_COUNT') {
          // callback for updating signers count
          invalidSignersCountCallback();
        } else {
          dispatch({ type: documentManagementConstants.SIGN_DOCUMENT_FAILURE });
          dispatch(
            snackbarActions.enqueueSnackbar(
              SNACKBAR_VARIANT.ERROR,
              i18n.t('snackbars.common.errorTitle'),
              `${i18n.t('snackbars.common.error')} ${error.code}.`
            )
          );
        }
      });
  };
}

function rejectDocument(documentId, reason, callbackOnSuccess) {
  return (dispatch) => {
    dispatch({ type: documentManagementConstants.REJECT_DOCUMENT_REQUEST });
    documentCoreService
      .rejectDocument(documentId, reason)
      .then((response) => {
        dispatch({
          type: documentManagementConstants.REJECT_DOCUMENT_SUCCESS,
          data: response.data,
        });
        dispatch(identityActions.sendTransactionFromNewDocument(response.data.txn));
        dispatch(
          snackbarActions.enqueueSnackbar(
            SNACKBAR_VARIANT.INFO,
            i18n.t('snackbars.common.infoTitle'),
            i18n.t('documentActions.rejectDocument.toast.commissioned')
          )
        );
        if (typeof callbackOnSuccess === 'function') {
          dispatch(callbackOnSuccess);
        }
      })
      .catch((error) => {
        dispatch({ type: documentManagementConstants.REJECT_DOCUMENT_FAILURE });
        dispatch(
          snackbarActions.enqueueSnackbar(
            SNACKBAR_VARIANT.ERROR,
            i18n.t('snackbars.common.errorTitle'),
            `${i18n.t('snackbars.common.error')} ${error.code}.`
          )
        );
      });
  };
}

function getHistoryDocument({
  documentId,
  workspaceId,
  limit,
  offset,
  performedAction,
  rangeDate,
  service,
}) {
  return (dispatch) => {
    dispatch({ type: documentManagementConstants.GET_HISTORY_DOCUMENT_REQUEST });
    documentCoreService
      .getHistoryDocument(
        documentId,
        workspaceId,
        limit,
        offset,
        performedAction,
        rangeDate,
        service
      )
      .then((response) => {
        dispatch({
          type: documentManagementConstants.GET_HISTORY_DOCUMENT_SUCCESS,
          history: response.data,
        });
      })
      .catch((error) => {
        dispatch({ type: documentManagementConstants.GET_HISTORY_DOCUMENT_FAILURE });
        dispatch(
          snackbarActions.enqueueSnackbar(
            SNACKBAR_VARIANT.ERROR,
            i18n.t('snackbars.common.errorTitle'),
            `${i18n.t('snackbars.common.error')} ${error.code}.`
          )
        );
      });
  };
}

function reloadDocumentHistory() {
  return (dispatch) => {
    dispatch({ type: documentManagementConstants.RELOAD_DOCUMENT_HISTORY });
  };
}

function getDocumentMembers(documentId, service) {
  return (dispatch) => {
    dispatch({ type: documentManagementConstants.GET_DOCUMENT_MEMBERS_REQUEST });
    dispatch({ type: documentUsersManagerConstants.FETCH_DOCUMENT_MEMBERS_REQUEST });
    documentCoreService
      .getDocumentMembers(documentId, service)
      .then((response) => {
        // Provide data to new user manager reducer
        dispatch({
          type: documentUsersManagerConstants.FETCH_DOCUMENT_MEMBERS_SUCCESS,
          payload: response.data.collection ? response.data.collection : response.data,
        });
        dispatch({
          type: documentManagementConstants.GET_DOCUMENT_MEMBERS_SUCCESS,
          members: response.data.collection ? response.data : { collection: response.data },
        });
      })
      .catch((error) => {
        dispatch({ type: documentManagementConstants.GET_DOCUMENT_MEMBERS_FAILURE });
        dispatch({ type: documentUsersManagerConstants.FETCH_DOCUMENT_MEMBERS_FAILURE });

        dispatch(
          snackbarActions.enqueueSnackbar(
            SNACKBAR_VARIANT.ERROR,
            i18n.t('snackbars.common.errorTitle'),
            `${i18n.t('snackbars.common.error')} ${error.code}.`
          )
        );
      });
  };
}

function getDocumentInvitations(documentId, service) {
  return (dispatch) => {
    dispatch({ type: documentManagementConstants.GET_DOCUMENT_INVITATIONS_REQUEST });
    dispatch({ type: documentUsersManagerConstants.FETCH_DOCUMENT_INVITATIONS_REQUEST });
    documentCoreService
      .getDocumentInvitations(documentId, service)
      .then((response) => {
        // Provide data to new user manager reducer
        dispatch({
          type: documentUsersManagerConstants.FETCH_DOCUMENT_INVITATIONS_SUCCESS,
          payload: response.data.collection,
        });
        dispatch({
          type: documentManagementConstants.GET_DOCUMENT_INVITATIONS_SUCCESS,
          members: response.data,
        });
      })
      .catch((error) => {
        dispatch({ type: documentManagementConstants.GET_DOCUMENT_INVITATIONS_FAILURE });
        dispatch({ type: documentUsersManagerConstants.FETCH_DOCUMENT_INVITATIONS_FAILURE });

        dispatch(
          snackbarActions.enqueueSnackbar(
            SNACKBAR_VARIANT.ERROR,
            i18n.t('snackbars.common.errorTitle'),
            `${i18n.t('snackbars.common.error')} ${error.code}.`
          )
        );
      });
  };
}

function getDocumentMembersByRole(documentId, role, service) {
  return (dispatch) => {
    dispatch({ type: documentManagementConstants.GET_DOCUMENT_MEMBERS_BY_ROLE_REQUEST });
    documentCoreService
      .getDocumentMembersByRole(documentId, role, service)
      .then((response) => {
        dispatch({
          type: documentManagementConstants.GET_DOCUMENT_MEMBERS_BY_ROLE_SUCCESS,
          roleType: role,
          members: response.data?.ids
            ? {
                collection: response.data?.ids || [],
                totalRowCount: response.data?.ids.length || 0,
              }
            : response.data,
        });
      })
      .catch((error) => {
        dispatch({ type: documentManagementConstants.GET_DOCUMENT_MEMBERS_BY_ROLE_FAILURE });
        dispatch(
          snackbarActions.enqueueSnackbar(
            SNACKBAR_VARIANT.ERROR,
            i18n.t('snackbars.common.errorTitle'),
            `${i18n.t('snackbars.common.error')} ${error.code}.`
          )
        );
      });
  };
}

function getSignatures(documentId) {
  return (dispatch) => {
    dispatch({ type: documentManagementConstants.GET_SIGNATURES_REQUEST });
    documentCoreService
      .getSignatures(documentId)
      .then((response) => {
        dispatch({
          type: documentManagementConstants.GET_SIGNATURES_SUCCESS,
          signatures: response?.data,
        });
      })
      .catch((error) => {
        dispatch({ type: documentManagementConstants.GET_SIGNATURES_FAILURE });
        dispatch(
          snackbarActions.enqueueSnackbar(
            SNACKBAR_VARIANT.ERROR,
            i18n.t('snackbars.common.errorTitle'),
            `${i18n.t('snackbars.common.error')} ${error.code}.`
          )
        );
      });
  };
}

function getDocumentCard(documentId) {
  return (dispatch) => {
    dispatch({ type: documentManagementConstants.GET_DOCUMENT_CARD_REQUEST });
    const cookieLang = cookieHelper.getCookie('lang');
    blockchainExplorerService
      .getDocumentCard(documentId, cookieLang)
      .then((response) => {
        dispatch({ type: documentManagementConstants.GET_DOCUMENT_CARD_SUCCESS });

        const download = (data, fileName) => {
          // IE11 support
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            const blob = new Blob([data], { type: 'application/pdf' });
            window.navigator.msSaveOrOpenBlob(blob, fileName);
          } else {
            // other browsers
            const url = window.URL.createObjectURL(data);
            const link = document.createElement('a');
            link.href = url;
            link.download = fileName;
            link.click();
            link.remove();
            setTimeout(() => window.URL.revokeObjectURL(url), 100);
          }
        };

        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const filename = filenameRegex
          .exec(response.headers['content-disposition'])[1]
          .replaceAll('"', '');

        download(response.data, filename);
      })
      .catch((error) => {
        dispatch({ type: documentManagementConstants.GET_DOCUMENT_CARD_FAILURE });
        dispatch(
          snackbarActions.enqueueSnackbar(
            SNACKBAR_VARIANT.ERROR,
            i18n.t('snackbars.common.errorTitle'),
            `${i18n.t('snackbars.common.error')} ${error}.`
          )
        );
      });
  };
}

function websocketUpdate(data) {
  return { type: documentManagementConstants.WS_UPDATE_DOCUMENT_MANAGEMENT, data };
}

function clearDocumentHistory() {
  return { type: documentManagementConstants.CLEAR_DOCUMENT_HISTORY };
}

function clearDocumentManagementStore() {
  return { type: documentManagementConstants.CLEAR_DOCUMENT_MANAGEMENT_STORE };
}

export default documentManagementActions;
