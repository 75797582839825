import { makeStyles, createStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ palette }) =>
    createStyles({
      modal: {
        height: '370px',
        width: '400px',
        borderRadius: '6px',
        background: palette.common.white,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-around',
        boxSizing: 'border-box',
      },

      header: {
        textAlign: 'center',
        marginBottom: '15px',
      },
      message: {
        textAlign: 'center',
        color: palette.error.main,
        marginBottom: '20px',
      },
      errorButton: {
        marginBottom: '10px',
        backgroundColor: palette.error.main,
        color: palette.common.white,
        border: `1px solid ${palette.error.main}`,

        '&:hover': {
          backgroundColor: palette.error.main,
          borderColor: palette.common.white,
          boxShadow: 'none',
        },
        '&:active': {
          backgroundColor: palette.error.main,
          borderColor: palette.common.white,
        },
      },
    }),
  { index: 0 }
);
