import {
  amber,
  blue,
  cyan,
  deepOrange,
  deepPurple,
  green,
  indigo,
  lightBlue,
  lightGreen,
  lime,
  orange,
  pink,
  purple,
  red,
  teal,
  yellow,
} from '@material-ui/core/colors';

const colors = [
  red[600],
  pink[600],
  purple[600],
  deepPurple[600],
  indigo[600],
  blue[600],
  lightBlue[600],
  cyan[600],
  teal[600],
  green[600],
  lightGreen[600],
  lime[600],
  yellow[600],
  amber[600],
  orange[600],
  deepOrange[600],
];

export const getColorFromText = (text: string): string => {
  const charCodes = String(text)
    .split('')
    .map((char) => char.charCodeAt(0))
    .join('');

  return colors[parseInt(charCodes, 10) % colors.length];
};

export const getInitials = (text: string): string => {
  const splitText = text.replace(/[^a-zA-Z0-9À-ž ]/g, '').split(/\s+/);
  return `${splitText[0].charAt(0).toUpperCase()}${
    splitText?.length > 1 ? splitText[1].charAt(0).toUpperCase() : ''
  }`;
};
