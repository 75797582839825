import i18n from 'src/helpers/i18n';
import { SNACKBAR_VARIANT } from 'src/models/common.model';
import avatarService from '../services/avatar.service';
import avatarConstants from '../constants/avatar.constants';
import snackbarActions from './snackbar.actions';
import { userComActions, WorkspaceDataChangedType } from './userCom.actions';

const avatarActions = {
  getUserAvatar,
  refreshAvatars,
  updateUserAvatar,
  deleteUserAvatar,
  getWorkspaceAvatar,
  updateWorkspaceAvatar,
  deleteWorkspaceAvatar,
};

function getUserAvatar(id) {
  return (dispatch) => {
    dispatch({ type: avatarConstants.GET_USER_AVATAR_REQUEST });
    avatarService
      .getUserAvatar(id)
      .then(() => {
        dispatch({
          type: avatarConstants.GET_USER_AVATAR_SUCCESS,
        });
      })
      .catch(() => {
        dispatch({ type: avatarConstants.GET_USER_AVATAR_ERROR });
      });
  };
}

function refreshAvatars(identities, type) {
  const avatarPromises = identities.map((id) =>
    type === 'identity' ? avatarService.getIdentityAvatar(id) : avatarService.getWorkspaceAvatar(id)
  );
  Promise.all(avatarPromises);
}

function updateUserAvatar(updatedAvatar) {
  return (dispatch) => {
    dispatch({ type: avatarConstants.PUT_USER_AVATAR_REQUEST });
    avatarService
      .uploadUserAvatar(updatedAvatar)
      .then(() => {
        dispatch({ type: avatarConstants.PUT_USER_AVATAR_SUCCESS });
        dispatch(
          snackbarActions.enqueueSnackbar(
            SNACKBAR_VARIANT.SUCCESS,
            i18n.t('snackbars.common.successTitle'),
            i18n.t('snackbars.common.success')
          )
        );
      })
      .catch((error) => {
        dispatch({ type: avatarConstants.PUT_USER_AVATAR_ERROR, error });
        dispatch(
          snackbarActions.enqueueSnackbar(
            SNACKBAR_VARIANT.ERROR,
            i18n.t('snackbars.common.errorTitle'),
            `${i18n.t('snackbars.common.error')} ${error.code}.`
          )
        );
      });
  };
}

function deleteUserAvatar(callbackOnSuccess) {
  return (dispatch) => {
    dispatch({ type: avatarConstants.DELETE_USER_AVATAR_REQUEST });
    avatarService
      .deleteUserAvatar()
      .then(() => {
        dispatch({ type: avatarConstants.DELETE_USER_AVATAR_SUCCESS });
        dispatch(
          snackbarActions.enqueueSnackbar(
            SNACKBAR_VARIANT.SUCCESS,
            i18n.t('snackbars.common.successTitle'),
            i18n.t('userConfigurationPage.basic.avatarDelete.toast.success')
          )
        );
        if (typeof callbackOnSuccess === 'function') {
          dispatch(callbackOnSuccess);
        }
      })
      .catch((error) => {
        dispatch({ type: avatarConstants.DELETE_USER_AVATAR_ERROR, error });
        dispatch(
          snackbarActions.enqueueSnackbar(
            SNACKBAR_VARIANT.ERROR,
            i18n.t('snackbars.common.errorTitle'),
            i18n.t('userConfigurationPage.basic.avatarDelete.toast.error')
          )
        );
      });
  };
}

function getWorkspaceAvatar(workspaceId) {
  return (dispatch) => {
    dispatch({ type: avatarConstants.GET_WORKSPACE_AVATAR_REQUEST });
    avatarService
      .getWorkspaceAvatar(workspaceId)
      .then(() => {
        dispatch({
          type: avatarConstants.GET_WORKSPACE_AVATAR_SUCCESS,
        });
      })
      .catch(() => {
        dispatch({ type: avatarConstants.GET_WORKSPACE_AVATAR_ERROR });
      });
  };
}

function updateWorkspaceAvatar(workspaceId, updatedAvatar) {
  return (dispatch) => {
    dispatch({ type: avatarConstants.PUT_WORKSPACE_AVATAR_REQUEST });
    avatarService
      .uploadWorkspaceAvatar(workspaceId, updatedAvatar)
      .then(() => {
        userComActions.workspace.dataChanged(WorkspaceDataChangedType.Logo);
        dispatch({ type: avatarConstants.PUT_WORKSPACE_AVATAR_SUCCESS });
        dispatch(
          snackbarActions.enqueueSnackbar(
            SNACKBAR_VARIANT.SUCCESS,
            i18n.t('snackbars.common.successTitle'),
            i18n.t('snackbars.common.success')
          )
        );
      })
      .catch((error) => {
        dispatch({ type: avatarConstants.PUT_WORKSPACE_AVATAR_ERROR, error });
        dispatch(
          snackbarActions.enqueueSnackbar(
            SNACKBAR_VARIANT.ERROR,
            i18n.t('snackbars.common.errorTitle'),
            `${i18n.t('snackbars.common.error')} ${error.code}.`
          )
        );
      });
  };
}

function deleteWorkspaceAvatar(workspaceId, callbackOnSuccess) {
  return (dispatch) => {
    dispatch({ type: avatarConstants.DELETE_WORKSPACE_AVATAR_REQUEST });
    avatarService
      .deleteWorkspaceAvatar(workspaceId)
      .then(() => {
        dispatch({ type: avatarConstants.DELETE_WORKSPACE_AVATAR_SUCCESS });
        dispatch(
          snackbarActions.enqueueSnackbar(
            SNACKBAR_VARIANT.SUCCESS,
            i18n.t('snackbars.common.successTitle'),
            i18n.t('workspacePage.settings.logoDelete.toast.success')
          )
        );
        if (typeof callbackOnSuccess === 'function') {
          dispatch(callbackOnSuccess);
        }
      })
      .catch((error) => {
        dispatch({ type: avatarConstants.DELETE_USER_AVATAR_ERROR, error });
        dispatch(
          snackbarActions.enqueueSnackbar(
            SNACKBAR_VARIANT.ERROR,
            i18n.t('snackbars.common.errorTitle'),
            i18n.t('workspacePage.settings.logoDelete.toast.error')
          )
        );
      });
  };
}

export default avatarActions;
