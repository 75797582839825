import { fade } from '@material-ui/core/styles';
import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ breakpoints, palette, spacing }) =>
    createStyles({
      imageAppStore: {
        height: 30,
        marginRight: spacing(0.5),
      },
      imagePlay: {
        height: 30,
      },
      userWrapper: {
        maxWidth: `calc(100% - 40px)`,
      },
      appImageWrapper: {
        justifyContent: 'space-between',
      },
      workspaceItem: {
        width: '100% !important',
        margin: '0 !important',
        '& .MuiAvatar-root': {
          borderRadius: '25%',
        },
      },
      root: {
        maxWidth: '100%',
        '& .small-item': {
          paddingTop: 2,
          paddingBottom: 2,
          paddingLeft: spacing(4),
          minHeight: 38,
        },

        '& .MuiAccordion-root': {
          margin: 0,
        },

        '& .MuiAccordionSummary-content.Mui-expanded': {
          margin: 0,
        },
        '& .MuiAccordionSummary-root.Mui-expanded': {
          minHeight: 38,
          position: 'sticky',
          top: 0,
          backgroundColor: palette.background.paper,
          zIndex: 1,
        },
      },
      availableWorkspaces: {
        paddingLeft: spacing(4),
      },
      accordionDetailsWrapper: {
        display: 'flex',
        flexDirection: 'column',
        padding: spacing(0, 2),
        '& li': {
          paddingLeft: spacing(4),
        },
      },
      accordionDetails: {
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
      },
      accordionTitle: {
        padding: spacing(0, 2.5),
      },
      currentWorkspaceText: {
        paddingLeft: spacing(1.5),
        width: 240,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      currentWorkspace: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
      },
      drawerPaper: {
        top: 0,
        bottom: 0,
        height: '100%',
        width: '100%',
        [breakpoints.up('sm')]: {
          maxWidth: 360,
          overflow: 'hidden',
        },
      },
      top: {
        justifyContent: 'flex-end',
      },
      content: {
        width: '100%',
        flex: 1,
        overflowY: 'auto',
        padding: 0,
      },
      bottom: {
        [breakpoints.up('lg')]: {
          paddingTop: `${spacing(2)}px !important`,
        },
      },
      closeButton: {
        marginLeft: 'auto',
        [breakpoints.up('lg')]: {
          marginRight: -12,
        },
      },
      newButton: {
        width: `calc(100% + ${spacing(2)}px)`,
        marginLeft: spacing(-1),
        marginRight: spacing(-1),
        padding: spacing(1),
        justifyContent: 'start',
      },
      newButtonIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: spacing(5),
        height: spacing(5),
        borderRadius: '50%',
        color: palette.primary.main,
        backgroundColor: fade(palette.primary.light, 0.1),
        marginTop: 0,
        marginBottom: 0,
        marginLeft: 0,
        marginRight: spacing(1),
        [breakpoints.down('sm')]: {
          width: spacing(4),
          height: spacing(4),
        },
        [breakpoints.up('lg')]: {
          marginRight: spacing(1.5),
        },
        [breakpoints.up('xl')]: {
          marginRight: spacing(2),
        },
      },
      logout: {
        color: `${palette.error.main} !important`,
        '&:hover p, p': {
          color: `${palette.error.main} !important`,
        },
      },
    }),
  { index: 0 }
);
