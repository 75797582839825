enum billingConstants {
  FETCH_SUBSCRIPTIONS_REQUEST = 'billingConstants/FETCH_SUBSCRIPTIONS_REQUEST',
  FETCH_SUBSCRIPTIONS_SUCCESS = 'billingConstants/FETCH_SUBSCRIPTIONS_SUCCESS',
  FETCH_SUBSCRIPTIONS_FAILURE = 'billingConstants/FETCH_SUBSCRIPTIONS_FAILURE',

  SET_TRIAL_SUBSCRIPTION_REQUEST = 'billingConstants/SET_TRIAL_SUBSCRIPTION_REQUEST',
  SET_TRIAL_SUBSCRIPTION_SUCCESS = 'billingConstants/SET_TRIAL_SUBSCRIPTION_SUCCESS',
  SET_TRIAL_SUBSCRIPTION_FAILURE = 'billingConstants/SET_TRIAL_SUBSCRIPTION_FAILURE',
  SET_TRIAL_SUBSCRIPTION_IDLE = 'billingConstants/SET_TRIAL_SUBSCRIPTION_IDLE',

  FETCH_CUSTOMER_REQUEST = 'billingConstants/FETCH_CUSTOMER_REQUEST',
  FETCH_CUSTOMER_SUCCESS = 'billingConstants/FETCH_CUSTOMER_SUCCESS',
  FETCH_CUSTOMER_FAILURE = 'billingConstants/FETCH_CUSTOMER_FAILURE',

  SET_CUSTOMER_REQUEST = 'billingConstants/SET_CUSTOMER_REQUEST',
  SET_CUSTOMER_SUCCESS = 'billingConstants/SET_CUSTOMER_SUCCESS',
  SET_CUSTOMER_FAILURE = 'billingConstants/SET_CUSTOMER_FAILURE',

  RESET_BILLING = 'billingConstants/RESET_BILLING',
}

export default billingConstants;
