import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Drawer, useMediaQuery } from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Info, Preview } from 'src/components/CustomIcon';
import { ActionsTabs } from 'src/pages/DocumentActions/subcomponents';
import TemplatePreview from '../../TemplatePreview/TemplatePreview';

import { useStyles } from './styles';

const MappingLayout = ({ children }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [selectedActionTab, setSelectedActionTab] = useState('preview');

  const smallDevice = useMediaQuery(({ breakpoints }) => breakpoints.down('sm'));
  const miniDevice = useMediaQuery(({ breakpoints }) => breakpoints.down('xs'));

  const handleChangeTab = (event, newValue) => {
    setSelectedActionTab(newValue);
  };

  useEffect(() => {
    if (!smallDevice && selectedActionTab === 'preview') {
      setSelectedActionTab('info');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const availableTabs = [
    {
      name: t('documentActions.tabs.preview.title'),
      icon: <Preview />,
      value: 'preview',
    },

    {
      name: t('documentActions.tabs.info.title'),
      icon: <Info />,
      value: 'info',
    },
  ];

  const renderTabs = (() => {
    switch (selectedActionTab) {
      case 'preview':
        return <TemplatePreview />;

      case 'info':
      default:
        return (
          <Drawer
            variant="permanent"
            anchor="right"
            className={classes.drawer}
            classes={{
              paper: clsx(classes.drawerPaper, 'scrollElementDrawer'),
            }}
          >
            {children}
          </Drawer>
        );
    }
  })();

  if (smallDevice) {
    return (
      <>
        <ActionsTabs
          value={selectedActionTab}
          onChange={handleChangeTab}
          tabs={availableTabs}
          showIcon={!miniDevice}
        />
        {renderTabs}
      </>
    );
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.content}>
        <TemplatePreview />
      </div>

      <Drawer
        variant="permanent"
        anchor="right"
        className={classes.drawer}
        classes={{
          paper: clsx(classes.drawerPaper, 'scrollElementDrawer'),
        }}
      >
        {children}
      </Drawer>
    </div>
  );
};

MappingLayout.propTypes = {
  children: PropTypes.node,
};

export default MappingLayout;
