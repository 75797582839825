import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ breakpoints, palette, spacing, zIndex }) =>
    createStyles({
      root: {
        display: 'flex',
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        overflow: 'hidden',
        backgroundColor: palette.background.paper,
        zIndex: zIndex.modal - 10,
      },
      main: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        position: 'relative',
        height: '100%',
        width: '100%',
      },
      mobileMain: {},
      content: {
        flex: 1,
        position: 'relative',
        backgroundColor: palette.background.default,
        padding: spacing(2),
        overflowX: 'hidden',
        overflowY: 'auto',
        [breakpoints.up('md')]: {
          padding: `${spacing(2)}px ${spacing(3)}px ${spacing(3)}px ${spacing(3)}px`,
        },
        [breakpoints.up('lg')]: {
          padding: `${spacing(2)}px ${spacing(5)}px ${spacing(3)}px ${spacing(5)}px`,
        },
      },
      mobileBottomContent: {},
      documentCardButton: {
        marginBottom: `${spacing(1)}px`,
      },
      afterText: {
        fontSize: 12,
        color: palette.greySimple.dark,
        paddingLeft: 8,
      },
    }),
  { index: 0 }
);
