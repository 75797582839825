import { makeStyles, createStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ breakpoints }) =>
    createStyles({
      contentWrapper: {
        maxWidth: 690,
      },
      bottomButton: {
        minWidth: 388,
        display: 'flex',
        margin: '0 auto',
        [breakpoints.down('sm')]: {
          minWidth: 300,
          marginTop: 12,
        },
      },
      loaderWrapper: {
        height: 50,
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      },
      pageWrapper: {
        '& #fullScreenDialogContent': {
          position: 'relative',
        },
      },
    }),
  { index: 0 }
);
