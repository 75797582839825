import { RequestStatus } from 'src/helpers/reduxReuquest.util';
import workspaceGroupsConstants from '../constants/workspaceGroups.constants';

const initialState = {
  activeGroup: {},
  addMembersToWorkspaceGroupStatus: RequestStatus.IDLE,
  deleteMembersFromWorkspaceGroupStatus: RequestStatus.IDLE,
  deleteWorkspaceGroupStatus: RequestStatus.IDLE,
  fetchGroupStructureStatus: RequestStatus.IDLE,
  fetchWorkspaceGroupStatus: RequestStatus.IDLE,
  fetchWorkspaceGroupsStatus: RequestStatus.IDLE,
  groupMembers: [],
  hasNextPage: false,
  pageIndex: 0,
  searchWorkspaceGroupsStatus: RequestStatus.IDLE,
  updateWorkspaceGroupStatus: RequestStatus.IDLE,
  workspaceGroups: [],
};

const addOnlyOneGroupToState = (groups, data) =>
  [{ ...data, active: true }].concat(groups).sort((a, b) => (a.name > b.name ? 1 : -1));

export const deactivatedGroup = (groups, groupId) =>
  groups.map((group) => {
    if (group.info.groupId === groupId) {
      group.info.active = false;
    }
    return group;
  });

export const updateOnlyOneGroupInState = (groups, data) => {
  const changedGroupIndex = groups.findIndex(
    (type) => (type?.info?.groupId ? type?.info?.groupId : type?.groupId) === data?.groupId
  );
  const newWorkspaceGroupsArray = [...groups];
  if (!newWorkspaceGroupsArray[changedGroupIndex]) return newWorkspaceGroupsArray;

  if (newWorkspaceGroupsArray[changedGroupIndex]?.info) {
    newWorkspaceGroupsArray[changedGroupIndex].info.name = data.name;
    newWorkspaceGroupsArray[changedGroupIndex].info.description = data.description;
  } else {
    newWorkspaceGroupsArray[changedGroupIndex].name = data.name;
    newWorkspaceGroupsArray[changedGroupIndex].description = data.description;
  }

  return newWorkspaceGroupsArray;
};

export const deleteOnlyOneGroupFromState = (groups, groupId) => {
  const changedGroupIndex = groups.findIndex(
    (type) => (type?.info?.groupId ? type?.info?.groupId : type?.groupId) === groupId
  );
  const groupsArray = [...groups];
  return groupsArray.reduce((prev, x, i) => prev.concat(i !== changedGroupIndex ? [x] : []), []);
};

export default function workspaceGroupsStore(state = initialState, action) {
  switch (action.type) {
    case workspaceGroupsConstants.FETCH_WORKSPACE_GROUPS_REQUEST:
      return {
        ...state,
        fetchWorkspaceGroupsStatus: RequestStatus.PENDING,
      };
    case workspaceGroupsConstants.FETCH_WORKSPACE_GROUPS_SUCCESS:
      return {
        ...state,
        fetchWorkspaceGroupsStatus: RequestStatus.SUCCESS,
        hasNextPage: action.hasNextPage,
        pageIndex: action.hasNextPage ? state.pageIndex + 1 : state.pageIndex,
        workspaceGroups:
          action.force === true
            ? [...action.workspaceGroups]
            : [...state.workspaceGroups, ...action.workspaceGroups],
      };
    case workspaceGroupsConstants.FETCH_WORKSPACE_GROUPS_FAILURE:
      return {
        ...state,
        fetchWorkspaceGroupsStatus: RequestStatus.ERROR,
      };

    case workspaceGroupsConstants.FETCH_WORKSPACE_GROUPS_SEARCH_REQUEST:
      return {
        ...state,
        searchWorkspaceGroupsStatus: RequestStatus.PENDING,
      };
    case workspaceGroupsConstants.FETCH_WORKSPACE_GROUPS_SEARCH_SUCCESS:
      return {
        ...state,
        searchWorkspaceGroupsStatus: RequestStatus.SUCCESS,
        pageIndex: 0,
        workspaceGroups: [...action.workspaceUsers],
      };
    case workspaceGroupsConstants.FETCH_WORKSPACE_GROUPS_SEARCH_FAILURE:
      return {
        ...state,
        searchWorkspaceGroupsStatus: RequestStatus.ERROR,
      };

    case workspaceGroupsConstants.CREATE_NEW_WORKSPACE_GROUP_REQUEST:
      return {
        ...state,
        createWorkspaceGroupStatus: RequestStatus.PENDING,
      };
    case workspaceGroupsConstants.CREATE_NEW_WORKSPACE_GROUP_SUCCESS:
      return {
        ...state,
        workspaceGroups: addOnlyOneGroupToState(state.workspaceGroups, action.data),
        createWorkspaceGroupStatus: RequestStatus.SUCCESS,
      };
    case workspaceGroupsConstants.CREATE_NEW_WORKSPACE_GROUP_FAILURE:
      return {
        ...state,
        createWorkspaceGroupStatus: RequestStatus.ERROR,
      };

    case workspaceGroupsConstants.DELETE_WORKSPACE_GROUP_REQUEST:
      return {
        ...state,
        deleteWorkspaceGroupStatus: RequestStatus.PENDING,
      };
    case workspaceGroupsConstants.DELETE_WORKSPACE_GROUP_SUCCESS:
      return {
        ...state,
        workspaceGroups: deleteOnlyOneGroupFromState(state.workspaceGroups, action.groupId),
        deleteWorkspaceGroupStatus: RequestStatus.SUCCESS,
      };
    case workspaceGroupsConstants.DELETE_WORKSPACE_GROUP_FAILURE:
      return {
        ...state,
        deleteWorkspaceGroupStatus: RequestStatus.ERROR,
      };

    case workspaceGroupsConstants.UPDATE_WORKSPACE_GROUP_REQUEST:
      return {
        ...state,
        updateWorkspaceGroupStatus: RequestStatus.PENDING,
      };
    case workspaceGroupsConstants.UPDATE_WORKSPACE_GROUP_SUCCESS:
      return {
        ...state,
        updateWorkspaceGroupStatus: RequestStatus.SUCCESS,
      };
    case workspaceGroupsConstants.UPDATE_WORKSPACE_GROUP_FAILURE:
      return {
        ...state,
        updateWorkspaceGroupStatus: RequestStatus.ERROR,
      };

    case workspaceGroupsConstants.ADD_MEMBER_TO_WORKSPACE_GROUP_REQUEST:
      return {
        ...state,
        addMembersToWorkspaceGroupStatus: RequestStatus.PENDING,
      };
    case workspaceGroupsConstants.ADD_MEMBER_TO_WORKSPACE_GROUP_SUCCESS:
      return {
        ...state,
        addMembersToWorkspaceGroupStatus: RequestStatus.SUCCESS,
      };
    case workspaceGroupsConstants.ADD_MEMBER_TO_WORKSPACE_GROUP_FAILURE:
      return {
        ...state,
        addMembersToWorkspaceGroupStatus: RequestStatus.ERROR,
      };

    case workspaceGroupsConstants.DELETE_MEMBER_FROM_WORKSPACE_GROUP_REQUEST:
      return {
        ...state,
        deleteMembersFromWorkspaceGroupStatus: RequestStatus.PENDING,
      };
    case workspaceGroupsConstants.DELETE_MEMBER_FROM_WORKSPACE_GROUP_SUCCESS:
      return {
        ...state,
        deleteMembersFromWorkspaceGroupStatus: RequestStatus.SUCCESS,
      };
    case workspaceGroupsConstants.DELETE_MEMBER_FROM_WORKSPACE_GROUP_FAILURE:
      return {
        ...state,
        deleteMembersFromWorkspaceGroupStatus: RequestStatus.ERROR,
      };

    case workspaceGroupsConstants.FETCH_WORKSPACE_GROUP_REQUEST:
      return {
        ...state,
        fetchWorkspaceGroupStatus: RequestStatus.PENDING,
      };
    case workspaceGroupsConstants.FETCH_WORKSPACE_GROUP_SUCCESS:
      return {
        ...state,
        workspaceGroups: updateOnlyOneGroupInState(state.workspaceGroups, action.data),
        fetchWorkspaceGroupStatus: RequestStatus.SUCCESS,
      };
    case workspaceGroupsConstants.FETCH_WORKSPACE_GROUP_FAILURE:
      return {
        ...state,
        fetchWorkspaceGroupStatus: RequestStatus.ERROR,
      };

    case workspaceGroupsConstants.FETCH_WORKSPACE_GROUP_MEMBERS_REQUEST:
      return {
        ...state,
        fetchGroupStructureStatus: RequestStatus.PENDING,
      };
    case workspaceGroupsConstants.FETCH_WORKSPACE_GROUP_MEMBERS_SUCCESS:
      return {
        ...state,
        fetchGroupStructureStatus: RequestStatus.SUCCESS,
        groupMembers: action.members,
      };
    case workspaceGroupsConstants.FETCH_WORKSPACE_GROUP_MEMBERS_FAILURE:
      return {
        ...state,
        fetchGroupStructureStatus: RequestStatus.ERROR,
      };
    case workspaceGroupsConstants.SET_ACTIVE_GROUP:
      return {
        ...state,
        activeGroup: action.payload,
      };
    case workspaceGroupsConstants.CLEAR_WORKSPACE_GROUPS_STATE:
      return initialState;

    default:
      return state;
  }
}
