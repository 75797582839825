import { createStyles, makeStyles } from '@material-ui/core';
import transitions from '@material-ui/core/styles/transitions';

export const useStyles = makeStyles(
  ({ palette }) =>
    createStyles({
      title: {
        display: 'flex',
        alignItems: 'center',
      },
      titleText: {
        flex: 1,
      },
      fieldWrapper: {
        display: 'flex',
        marginBottom: 16,
        alignItems: 'center',
      },
      fieldTitle: {
        width: '100%',
        maxWidth: 180,
      },
      fieldContent: {
        width: '100%',
      },
      textField: {
        width: '100%',
      },
      fieldInputArrowDown: {
        position: 'absolute',
        width: 16,
        height: 16,
        lineHeight: 1,
        right: 12,
        top: '50%',
        transform: 'translateY(-50%)',
        color: palette.secondary.main,
        transition: transitions.create(['color', 'transform', 'opacity'], {
          duration: transitions.duration.short,
        }),
      },
      fieldInputContent: {
        width: '100%',
        position: 'relative',

        '& > div': {
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          height: 48,
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: palette.action.selected,
          borderRadius: 6,
          padding: '0 12px',
          transition: transitions.create(['background-color', 'color', 'box-shadow', 'border'], {
            duration: transitions.duration.short,
          }),
          cursor: 'pointer',

          '&:hover': {
            borderColor: palette.primary.main,
          },
        },
      },
    }),
  { index: 0 }
);
