enum documentManagementConstants {
  RENAME_DOCUMENT_REQUEST = 'RENAME_DOCUMENT_REQUEST',
  RENAME_DOCUMENT_SUCCESS = 'RENAME_DOCUMENT_SUCCESS',
  RENAME_DOCUMENT_FAILURE = 'RENAME_DOCUMENT_FAILURE',

  SIGN_DOCUMENT_REQUEST = 'SIGN_DOCUMENT_REQUEST',
  SIGN_DOCUMENT_SUCCESS = 'SIGN_DOCUMENT_SUCCESS',
  SIGN_DOCUMENT_FAILURE = 'SIGN_DOCUMENT_FAILURE',

  REJECT_DOCUMENT_REQUEST = 'REJECT_DOCUMENT_REQUEST',
  REJECT_DOCUMENT_SUCCESS = 'REJECT_DOCUMENT_SUCCESS',
  REJECT_DOCUMENT_FAILURE = 'REJECT_DOCUMENT_FAILURE',

  GET_HISTORY_DOCUMENT_REQUEST = 'GET_HISTORY_DOCUMENT_REQUEST',
  GET_HISTORY_DOCUMENT_SUCCESS = 'GET_HISTORY_DOCUMENT_SUCCESS',
  GET_HISTORY_DOCUMENT_FAILURE = 'GET_HISTORY_DOCUMENT_FAILURE',

  RELOAD_DOCUMENT_HISTORY = 'RELOAD_DOCUMENT_HISTORY',

  GET_DOCUMENT_MEMBERS_BY_ROLE_REQUEST = 'GET_DOCUMENT_MEMBERS_BY_ROLE_REQUEST',
  GET_DOCUMENT_MEMBERS_BY_ROLE_SUCCESS = 'GET_DOCUMENT_MEMBERS_BY_ROLE_SUCCESS',
  GET_DOCUMENT_MEMBERS_BY_ROLE_FAILURE = 'GET_DOCUMENT_MEMBERS_BY_ROLE_FAILURE',

  GET_SIGNATURES_REQUEST = 'GET_SIGNATURES_REQUEST',
  GET_SIGNATURES_SUCCESS = 'GET_SIGNATURES_SUCCESS',
  GET_SIGNATURES_FAILURE = 'GET_SIGNATURES_FAILURE',

  GET_DOCUMENT_MEMBERS_REQUEST = 'GET_DOCUMENT_MEMBERS_REQUEST',
  GET_DOCUMENT_MEMBERS_SUCCESS = 'GET_DOCUMENT_MEMBERS_SUCCESS',
  GET_DOCUMENT_MEMBERS_FAILURE = 'GET_DOCUMENT_MEMBERS_FAILURE',

  GET_DOCUMENT_INVITATIONS_REQUEST = 'GET_DOCUMENT_INVITATIONS_REQUEST',
  GET_DOCUMENT_INVITATIONS_SUCCESS = 'GET_DOCUMENT_INVITATIONS_SUCCESS',
  GET_DOCUMENT_INVITATIONS_FAILURE = 'GET_DOCUMENT_INVITATIONS_FAILURE',

  UPDATE_DOCUMENT_SIGNERS = 'UPDATE_DOCUMENT_SIGNERS',

  GET_DOCUMENT_CARD_REQUEST = 'GET_DOCUMENT_CARD_REQUEST',
  GET_DOCUMENT_CARD_SUCCESS = 'GET_DOCUMENT_CARD_SUCCESS',
  GET_DOCUMENT_CARD_FAILURE = 'GET_DOCUMENT_CARD_FAILURE',

  WS_UPDATE_DOCUMENT_MANAGEMENT = 'WS_UPDATE_DOCUMENT_MANAGEMENT',

  CLEAR_DOCUMENT_HISTORY = 'CLEAR_DOCUMENT_HISTORY',

  CLEAR_DOCUMENT_MANAGEMENT_STORE = 'CLEAR_DOCUMENT_MANAGEMENT_STORE',
}

export default documentManagementConstants;
