import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TwoFactorForm from 'src/components/TwoFactorModal/subcomponents/TwoFactorForm';
import { useSelector, useDispatch } from 'react-redux';
import twoFactorConfigActions from 'src/redux/actions/twoFactorConfig.actions';
import { RequestStatus } from 'src/helpers/reduxReuquest.util';

const GoogleAuthenticatorStep2 = (props) => {
  const { nextHandle } = props;
  const dispatch = useDispatch();
  const [hasErrors, setHasErrors] = useState(false);

  const confirmTwoFactorTypeChangeRequestStatus = useSelector(
    (state) => state.twoFactorConfig.confirmTwoFactorTypeChangeRequestStatus
  );
  const confirmTwoFactorTypeChangeRequestError = useSelector(
    (state) => state.twoFactorConfig.confirmTwoFactorTypeChangeRequestError
  );
  const initTwoFactorMethod = useSelector((state) => state.twoFactorConfig.initTwoFactorMethod);

  useEffect(
    () => () => {
      dispatch(twoFactorConfigActions.clearConfirmTwoFactorStatus());
    },
    [dispatch]
  );

  useEffect(() => {
    if (
      confirmTwoFactorTypeChangeRequestStatus === RequestStatus.ERROR &&
      confirmTwoFactorTypeChangeRequestError === 'CODE_VERIFICATION_FAILED'
    ) {
      setHasErrors(true);
    }
    if (confirmTwoFactorTypeChangeRequestStatus === RequestStatus.SUCCESS) {
      nextHandle();
    }
  }, [confirmTwoFactorTypeChangeRequestStatus, confirmTwoFactorTypeChangeRequestError, nextHandle]);

  const handleSetCode = (confirmationCode) => {
    dispatch(
      twoFactorConfigActions.confirmTwoFactorTypeChange(initTwoFactorMethod, confirmationCode)
    );
  };

  return (
    <TwoFactorForm
      method="GOOGLE"
      hasErrors={hasErrors}
      onClearErrors={() => {
        setHasErrors(false);
      }}
      onSend={(confirmationCode) => {
        handleSetCode(confirmationCode);
      }}
    />
  );
};

GoogleAuthenticatorStep2.propTypes = {
  nextHandle: PropTypes.func,
};

export default GoogleAuthenticatorStep2;
