import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useStyles } from './styles';

const SmallContentWithScrollbar = ({
  className: classNameProp,
  classes: classesProp,
  scrollableContent,
  size,
  startContent,
  children,
  endContent,
  hasForm,
  formProps,
}) => {
  const classes = useStyles();

  const Component = hasForm ? 'form' : 'div';
  const additionalProps = hasForm ? formProps : undefined;

  return (
    <Component
      className={clsx(
        classes.root,
        {
          [classes[`${size}RootSize`]]: size,
        },
        scrollableContent && classes.rootWithScrollableContent,
        classNameProp,
        classesProp && classesProp.root
      )}
      {...additionalProps}
    >
      {startContent && <div className={classes.startContent}>{startContent}</div>}

      <div
        className={clsx(
          classes.content,
          scrollableContent && classes.scrollableContent,
          !startContent && classes.noPaddingTop,
          !endContent && classes.noPaddingBottom,
          classesProp && classesProp.content
        )}
      >
        {children}
      </div>

      {endContent && <div className={classes.endContent}>{endContent}</div>}
    </Component>
  );
};

SmallContentWithScrollbar.propTypes = {
  scrollableContent: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large', 'big']),
  className: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object,
  startContent: PropTypes.node,
  children: PropTypes.node,
  endContent: PropTypes.node,
  hasForm: PropTypes.bool,
  formProps: PropTypes.shape({}),
};

SmallContentWithScrollbar.defaultProps = {
  scrollableContent: false,
  size: 'small',
  hasForm: false,
};

export default SmallContentWithScrollbar;
