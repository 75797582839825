import React, { useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import authActions from 'src/redux/actions/auth.actions';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Divider,
  Drawer,
  Fab,
  Link,
  ListItemIcon,
  MenuItem,
  Theme,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import {
  AvatarAndTextItem,
  ContentContainer,
  HeaderButton,
  TopBarContainer,
  CreateWorkspace,
} from 'src/components';
import identityActions from 'src/redux/actions/identity.actions';
import { RootState } from 'src/redux/reducers';
import {
  ArrowDownSLine as ArrowDownIcon,
  Close as CloseIcon,
  Help,
  Logout as LogoutIcon,
  Plus as PlusIcon,
  Settings as SettingsIcon,
  Users as UsersIcon,
  UserSettings as UserSettingsIcon,
  Wallet,
} from 'src/components/CustomIcon';
import { lighten } from '@material-ui/core/styles';
import { useStyles } from './styles';
import { getColorFromText, getInitials } from '../../helpers/globalUtilsTS';
import { IdentitySelector } from '../../redux/selectors/identity.selector';
import { userComActions } from '../../redux/actions/userCom.actions';

interface HeaderUserDrawerProps {
  opened: boolean;
  onClose: () => void;
}
const HeaderMenuMobile = ({ opened, onClose }: HeaderUserDrawerProps): JSX.Element => {
  const smallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const classes = useStyles();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const activeIdentity = useSelector((state: RootState) => state.identityStore.currentIdentity);
  const isOwner = useSelector((state: RootState) => state.identityStore.isOwner);
  const workspaceId = useSelector(IdentitySelector.selectWorkspaceId);
  const availableWorkspaces = useSelector(IdentitySelector.selectAvailableWorkspaces, undefined);
  const currentWorkspace = useSelector(IdentitySelector.selectWorkspaceDetails, undefined);
  const [createWorkspaceDialogOpen, setCreateWorkspaceDialogOpen] = useState(false);
  const initials = getInitials(currentWorkspace?.name || ' ');
  const colorByInitials = getColorFromText(initials);

  const avatarSize = !smallScreen ? 'large' : 'medium';
  const userText = activeIdentity?.identity.name || activeIdentity?.identity.email || '';
  const userDescription =
    activeIdentity?.identity.name &&
    activeIdentity?.identity.name !== activeIdentity?.identity.email
      ? activeIdentity?.identity.email
      : undefined;

  const handleLogout = () => {
    dispatch(authActions.deleteToken());
  };
  const openCreateWorkspaceDialog = () => {
    setCreateWorkspaceDialogOpen(true);
  };
  const changeWorkspace = (id: string) => {
    if (typeof onClose === 'function') {
      onClose();
    }
    dispatch(
      identityActions.switchWorkspace(id, () => {
        history.push('/');
        return undefined;
      })
    );
  };

  const goToUserSettings = () => {
    history.push(`/user-settings/basic/settings`);
    onClose();
  };

  const goToWorkspaceSettings = () => {
    history.push(`/workspace-settings/basic/settings`);
    onClose();
  };
  const goToWorkspaceUsers = () => {
    history.push(`/workspace-settings/user-management/users`);
    onClose();
  };
  const goToWorkspaceBilling = () => {
    history.push(`/workspace-settings/subscription/products`);
    onClose();
  };

  const isEn = i18n.language === 'EN';

  const onChatSupport = () => {
    userComActions.openChat();
    onClose();
  };

  const onSubmitRequest = () => {
    if (workspaceId) userComActions.user.submitRequest(workspaceId);
    onClose();
  };

  const openPage = (url: string) => {
    window.open(url, '_blank');
    onClose();
  };

  const expandIcon = (
    <Fab size="small" color="primary">
      <ArrowDownIcon />
    </Fab>
  );

  return (
    <>
      {typeof onClose === 'function' && (
        <Drawer
          anchor="right"
          open={opened}
          variant="temporary"
          classes={{
            paper: clsx(classes.drawerPaper),
          }}
          onClose={onClose}
          className={classes.root}
        >
          <TopBarContainer className={classes.top}>
            <AvatarAndTextItem
              text={userText}
              className={classes.userWrapper}
              description={userDescription}
              spacing
              size="large"
              mainImageSrc={
                activeIdentity
                  ? `${process.env.REACT_APP_AVATAR_URL}/identity/${activeIdentity?.identity.identityId}?kind=SMALL`
                  : null
              }
            />
            <HeaderButton
              color="primary"
              className={classes.closeButton}
              onClick={onClose}
              icon={<CloseIcon />}
              tooltip={t<string>('common.close')}
            />
          </TopBarContainer>

          <ContentContainer vertical={false} className={classes.content}>
            <MenuItem divider={false} onClick={goToUserSettings}>
              <ListItemIcon>
                <UserSettingsIcon />
              </ListItemIcon>
              <Typography color="textPrimary">
                {t<string>('headerUser.accountSettings')}{' '}
              </Typography>
            </MenuItem>
            <Divider />
            <Accordion elevation={0}>
              <AccordionSummary expandIcon={expandIcon}>
                {currentWorkspace && (
                  <div className={classes.currentWorkspace}>
                    <Avatar
                      sizes="small"
                      variant="rounded"
                      src={`${process.env.REACT_APP_AVATAR_URL}/workspace/${workspaceId}?kind=SMALL`}
                      style={{
                        color: colorByInitials,
                        backgroundColor: lighten(colorByInitials, 0.825),
                      }}
                    >
                      {initials}
                    </Avatar>
                    <Typography className={classes.currentWorkspaceText} variant="body1">
                      {currentWorkspace.name}
                    </Typography>
                  </div>
                )}
              </AccordionSummary>
              <Divider />
              <AccordionDetails className={classes.accordionDetails}>
                {isOwner && (
                  <>
                    <MenuItem
                      className="small-item"
                      divider={false}
                      onClick={goToWorkspaceSettings}
                    >
                      <ListItemIcon>
                        <SettingsIcon />
                      </ListItemIcon>
                      <Typography variant="body1" color="textPrimary">
                        {t<string>('sideBar.settings')}
                      </Typography>
                    </MenuItem>
                    <MenuItem className="small-item" divider={false} onClick={goToWorkspaceUsers}>
                      <ListItemIcon>
                        <UsersIcon />
                      </ListItemIcon>
                      <Typography variant="body1" color="textPrimary">
                        {t<string>('sideBar.members')}
                      </Typography>
                    </MenuItem>
                    <MenuItem className="small-item" divider={false} onClick={goToWorkspaceBilling}>
                      <ListItemIcon>
                        <Wallet />
                      </ListItemIcon>
                      <Typography variant="body1" color="textPrimary">
                        {t<string>('sideBar.billing')}
                      </Typography>
                    </MenuItem>
                    <Divider />
                  </>
                )}
                <MenuItem
                  className="small-item"
                  divider={false}
                  onClick={openCreateWorkspaceDialog}
                >
                  <ListItemIcon>
                    <PlusIcon />
                  </ListItemIcon>
                  <Typography variant="body1" color="textPrimary">
                    {t<string>('sideBar.newWorkspace')}
                  </Typography>
                </MenuItem>
                <Divider />
                <div className={classes.availableWorkspaces}>
                  {availableWorkspaces && availableWorkspaces?.length > 1 ? (
                    availableWorkspaces
                      .filter((item) => item.workspace.workspaceId !== workspaceId)
                      .map((item, index) => (
                        <AvatarAndTextItem
                          key={index}
                          description={item.identity.name}
                          text={item.workspace.name}
                          spacing
                          size={avatarSize}
                          className={classes.workspaceItem}
                          mainImageSrc={`${process.env.REACT_APP_AVATAR_URL}/workspace/${item.workspace.workspaceId}?kind=SMALL`}
                          tooltip={t<string>('common.changeIdentity')}
                          isButton
                          onClick={() => {
                            changeWorkspace(item.workspace.workspaceId);
                          }}
                        />
                      ))
                  ) : (
                    <Typography variant="subtitle2" component="div" align="center">
                      {t<string>('userDrawer.otherIdentitiesEmpty')}
                    </Typography>
                  )}
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion elevation={0}>
              <AccordionSummary expandIcon={expandIcon}>
                <ListItemIcon>
                  <Help />
                </ListItemIcon>
                <Typography color="textPrimary">{t<string>('headerHelp.help')}</Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetailsWrapper}>
                <MenuItem divider={false} onClick={() => openPage('https://docs.doxychain.com')}>
                  <Typography color="textPrimary">{t<string>('headerHelp.helpDocs')} </Typography>
                </MenuItem>
                <MenuItem
                  divider={false}
                  onClick={() => openPage('https://www.doxychain.com/tutorials')}
                >
                  <Typography color="textPrimary">{t<string>('headerHelp.tutorials')}</Typography>
                </MenuItem>
                <MenuItem
                  divider={false}
                  onClick={() => openPage('https://www.doxychain.com/faqs')}
                >
                  <Typography color="textPrimary">{t<string>('headerHelp.faq')}</Typography>
                </MenuItem>
                <Divider />
                <MenuItem divider={false} onClick={onChatSupport}>
                  <Typography color="textPrimary">{t<string>('headerHelp.chatSupport')}</Typography>
                </MenuItem>
                <MenuItem divider={false} onClick={onSubmitRequest}>
                  <Typography color="textPrimary">{t<string>('headerHelp.request')}</Typography>
                </MenuItem>
                <Divider />
                <MenuItem
                  divider={false}
                  onClick={() =>
                    openPage(
                      isEn
                        ? 'https://www.doxychain.com/legal#terms'
                        : 'https://www.doxychain.com/legal#terms-pol'
                    )
                  }
                >
                  <Typography color="textPrimary">{t<string>('headerHelp.terms')}</Typography>
                </MenuItem>
                <MenuItem
                  divider={false}
                  onClick={() =>
                    openPage(
                      isEn
                        ? 'https://www.doxychain.com/legal#privacy'
                        : 'https://www.doxychain.com/legal#privacy-pol'
                    )
                  }
                >
                  <Typography color="textPrimary">{t<string>('headerHelp.privacy')}</Typography>
                </MenuItem>
                <Divider />
                <MenuItem divider={false} className={classes.appImageWrapper}>
                  <Link href="https://apps.apple.com/pl/app/doxychain/id1579677845" target="_blank">
                    <img src="/images/AppStore.png" alt="App Store" />
                  </Link>
                </MenuItem>
                <Divider />
                <MenuItem divider={false} className={classes.appImageWrapper}>
                  <Link
                    href="https://play.google.com/store/apps/details?id=com.doxychain.DoxyChain"
                    target="_blank"
                  >
                    <img
                      className={classes.imagePlay}
                      src="/images/GooglePlay.png"
                      alt="Google Play"
                    />
                  </Link>
                </MenuItem>
              </AccordionDetails>
            </Accordion>
            <Divider />
            <MenuItem className={classes.logout} onClick={handleLogout}>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <Typography color="textPrimary">{t<string>('headerUser.logout')} </Typography>
            </MenuItem>
          </ContentContainer>
        </Drawer>
      )}

      {createWorkspaceDialogOpen && (
        <CreateWorkspace isOpen handleClose={() => setCreateWorkspaceDialogOpen(false)} />
      )}
    </>
  );
};

export default HeaderMenuMobile;
