import axios from 'src/helpers/axios';
import { parseError } from 'src/helpers/parseErrors';

const moduleName = 'document-service';

const documentsService = {
  getDocumentSigners,
  replaceDocumentSigners,
  replaceDocumentRoles,
  inviteRoleByMail,
  deleteRoleByMail,
};

function getDocumentSigners(documentId, service = moduleName) {
  return axios
    .get(`/rest/${service}/documents/${documentId}/roles/signers`)
    .then((response) => response)
    .catch((error) => parseError(error));
}

function replaceDocumentSigners(documentId, isOrderSignificant = false, requiredSignersCount, ids) {
  const signers = {
    isOrderSignificant,
    requiredSignersCount,
    ids,
  };

  return axios
    .put(
      `/rest/${moduleName}/documents/${documentId}/roles/signers`,
      { signers },
      { withPermissions: ['DOCUMENT_SERVICE_USER_PUT_ROLES'] }
    )
    .then((response) => response)
    .catch((error) => parseError(error));
}

function replaceDocumentRoles(documentId, role, membersIds) {
  return axios
    .put(
      `/rest/${moduleName}/documents/${documentId}/roles/${role}`,
      { membersIds },
      { withPermissions: ['DOCUMENT_SERVICE_USER_PUT_ROLES'] }
    )
    .then((response) => response)
    .catch((error) => parseError(error));
}

function inviteRoleByMail(documentId, role, email) {
  return axios
    .post(
      `/rest/${moduleName}/documents/${documentId}/roles/${role}/invite`,
      { email },
      { withPermissions: ['DOCUMENT_SERVICE_USER_POST_DOCUMENT_INVITE'] }
    )
    .then((response) => response)
    .catch((error) => parseError(error));
}

function deleteRoleByMail(documentId, role, email) {
  return axios
    .delete(
      `/rest/${moduleName}/documents/${documentId}/roles/${role}/invitations?mail=${encodeURIComponent(
        email
      )}`,
      {
        withPermissions: [' DOCUMENT_SERVICE_USER_DELETE_DOCUMENT_INVITE'],
      }
    )
    .then((response) => response)
    .catch((error) => parseError(error));
}

export default documentsService;
