import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ custom, palette, spacing, breakpoints }) =>
    createStyles({
      foldersTitle: {
        marginBottom: spacing(2),
      },
      documentsTitle: {
        marginTop: spacing(3),
      },
      root: {
        '& *': {
          userSelect: 'none',
          '-webkit-user-select': 'none !important' /* Chrome, Opera, Safari */,
          '-moz-user-select': 'none !important' /* Firefox 2+ */,
          '-ms-user-select': 'none !important' /* IE 10+ */,
        },
      },
      appBar: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
      },
      breadcrumbs: {
        position: 'sticky',
        top: 0,
        left: 0,
        zIndex: 2,
        '& > *': {
          position: 'relative',
        },
      },
      header: {
        position: 'sticky',
        left: 0,
        backgroundColor: palette.background.paper,
        zIndex: 1,
        top: 20,
      },
      firstHeader: {},
      secondHeader: {},
      inFolderHeader: {},
      loader: {
        position: 'absolute',
        zIndex: 1300,
      },
      noDocumentsBanner: {
        '& img': {
          [breakpoints.up('md')]: {
            marginTop: custom.breadcrumbsMenuHeight + spacing(1),
          },
        },
      },
    }),
  { index: 0 }
);
