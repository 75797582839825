import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Dialog, duration } from '@material-ui/core';
import { DialogTitle, DialogContent } from './subcomponents';

import { useStyles } from './styles';

const FullScreenDialog = ({
  className: classNameProp,
  isOpen,
  children,
  onClose,
  onBack,
  title,
  titleLeftSpace,
  backgroundVariant,
  withLogo,
  transitionDuration,
}) => {
  const classes = useStyles();
  return (
    <Dialog
      disableEnforceFocus
      fullScreen
      open={isOpen}
      classes={{
        paper: backgroundVariant === 'grey' && classes.greyBackground,
      }}
      className={clsx(classes.root, classNameProp)}
      transitionDuration={transitionDuration}
    >
      <DialogTitle onClose={onClose} onBack={onBack} withLogo={withLogo} leftSpace={titleLeftSpace}>
        {title}
      </DialogTitle>
      <DialogContent className={classes.content} id="fullScreenDialogContent">
        {children}
      </DialogContent>
    </Dialog>
  );
};

FullScreenDialog.propTypes = {
  className: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  isOpen: PropTypes.bool,
  children: PropTypes.node,
  onClose: PropTypes.func,
  onBack: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  backgroundVariant: PropTypes.oneOf(['default', 'grey']),
  titleLeftSpace: PropTypes.bool,
  withLogo: PropTypes.bool,
  transitionDuration: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({
      appear: PropTypes.number,
      enter: PropTypes.number,
      exit: PropTypes.number,
    }),
  ]),
};

FullScreenDialog.defaultProps = {
  titleLeftSpace: false,
  backgroundVariant: 'default',
  transitionDuration: { enter: duration.enteringScreen, exit: duration.leavingScreen },
};

export default FullScreenDialog;
