import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Button, Drawer, Typography, useMediaQuery } from '@material-ui/core';
import { Plus as PlusIcon } from 'src/components/CustomIcon';
import { ContentContainer, Logo, MenuNavigationList } from 'src/components';
import { AppMenuMobile } from '../index';
import { SidenavLinks, MoreLinks, SidenavMobileLinks } from '../../Links';
import { useStyles } from './styles';
import SelectTemplateDropdown from '../../../../components/SelectTemplateDropdown/SelectTemplateDropdown';
import SidebarWorkspaceSwitch from '../SidebarWorkspaceSwitch/SidebarWorkspaceSwitch';

const AppMenuSidebar = ({ className: classNameProp }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const desktopScreen = useMediaQuery(({ breakpoints }) => breakpoints.up('md'));

  return (
    <>
      {desktopScreen ? (
        <Drawer
          open
          variant="permanent"
          anchor="left"
          classes={{
            root: clsx(classes.drawerRoot, classNameProp),
            paper: clsx(classes.drawerPaper),
          }}
        >
          <ContentContainer size="medium" className={classes.topContent}>
            <SidebarWorkspaceSwitch />
            <SelectTemplateDropdown>
              <Button
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                className={classes.addButton}
                startIcon={<PlusIcon />}
              >
                {t('sideBar.addDocument.shortText')}
              </Button>
            </SelectTemplateDropdown>
          </ContentContainer>

          <ContentContainer
            vertical="bottom"
            horizontal={false}
            size="medium"
            className={classes.mainContent}
          >
            <MenuNavigationList
              items={SidenavLinks(t)}
              size="medium"
              variant="inverted"
              className={classes.menuDesktop}
            />
            <div className={classes.logo}>
              <Typography variant="caption" className={classes.logoText}>
                {t('sideBar.powered')}
              </Typography>
              <Logo size="default" variant="inverted" />
            </div>
          </ContentContainer>
        </Drawer>
      ) : (
        <AppMenuMobile
          items={[...SidenavMobileLinks(t)]}
          moreItems={[...MoreLinks(t)]}
          classes={{
            item: classes.navItem,
            itemActive: classes.navItemActive,
            itemIcon: classes.navItemIcon,
          }}
        />
      )}
    </>
  );
};

AppMenuSidebar.propTypes = {
  className: PropTypes.string,
};

export default AppMenuSidebar;
