import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormControlLabel, Switch, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import DocumentUserManagerSelector from 'src/redux/selectors/documentUserManager.selector';
import { documentInvitationsSelector } from 'src/redux/selectors/documentInvitations.selector';
import Section from '../subcomponents/Section';
import InfoTooltip from '../subcomponents/InfoTooltip';
import { UsersManagerContext, UsersManagerContextType } from '../subcomponents/UsersManagerContext';
import ConfirmPublicAccessModal from '../subcomponents/confirmPublicAccessModal';

const UsersManagerAdvancedSettings = (): JSX.Element => {
  const { t } = useTranslation();
  const {
    isDraftPublicAccess,
    isDraftTransferable,
    updateDraftTransferable,
    updateDraftPublicAccess,
  } = useContext(UsersManagerContext) as UsersManagerContextType;
  const { canSetTransferabilityCert } = useSelector(
    DocumentUserManagerSelector.selectDocumentAction,
    undefined
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const documentInfo = useSelector((state: any) => state.documentDisplayStore.document, undefined);

  const isPending = useSelector(
    documentInvitationsSelector.selectIsAdvancedSettingsPending,
    undefined
  );
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);

  const toggleIsPublic = () => {
    if (isDraftPublicAccess) {
      updateDraftPublicAccess(false);
    } else {
      setShowConfirmationModal(true);
    }
  };

  const toggleTransferability = () => updateDraftTransferable(!isDraftTransferable);

  useEffect(() => {
    if (documentInfo?.id) {
      if (documentInfo?.additionalAttributes?.transferable !== undefined) {
        updateDraftTransferable(documentInfo?.additionalAttributes?.transferable);
      }
      if (documentInfo?.publicFile !== undefined) {
        updateDraftPublicAccess(documentInfo?.publicFile);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentInfo]);

  return (
    <>
      <Section
        hideDivider
        title={t<string>('documentUserManager.advancedSettings.title')}
        leftContent={
          <>
            <FormControlLabel
              labelPlacement="end"
              disabled={isPending}
              control={
                <Switch size="small" checked={isDraftPublicAccess} onChange={toggleIsPublic} />
              }
              label={
                <div>
                  <Typography color="textSecondary" variant="body1">
                    {t<string>('documentUserManager.advancedSettings.publicAccess.title')}
                    <InfoTooltip
                      message={t<string>(
                        'documentUserManager.advancedSettings.publicAccess.tooltip'
                      )}
                    />
                  </Typography>
                </div>
              }
            />
            <div style={{ marginTop: 8 }} />
            <FormControlLabel
              disabled={isPending}
              labelPlacement="end"
              control={
                <Switch
                  size="small"
                  disabled={!canSetTransferabilityCert}
                  checked={isDraftTransferable}
                  onChange={toggleTransferability}
                />
              }
              label={
                <div>
                  <Typography color="textSecondary" variant="body1">
                    {t<string>('documentUserManager.advancedSettings.transferability.title')}
                    <InfoTooltip
                      message={t<string>(
                        'documentUserManager.advancedSettings.transferability.tooltip'
                      )}
                    />
                  </Typography>
                </div>
              }
            />
          </>
        }
      />
      <ConfirmPublicAccessModal
        open={showConfirmationModal}
        handleClose={() => setShowConfirmationModal(false)}
        callback={() => updateDraftPublicAccess(true)}
      />
    </>
  );
};
export default UsersManagerAdvancedSettings;
