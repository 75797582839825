import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ breakpoints, mixins, palette, spacing, typography }) =>
    createStyles({
      drawerPaper: {
        width: 'inherit',
        maxWidth: 'inherit',
        overflowY: 'unset',
        maxHeight: '100vh',
      },
      top: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        ...mixins.toolbar,
      },
      closeButton: {
        [breakpoints.up('lg')]: {
          marginRight: -12,
        },
      },
      mainContent: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        flex: 1,
        overflowY: 'auto',
        paddingBottom: spacing(11),
        [breakpoints.up('lg')]: {
          paddingBottom: spacing(11),
        },
      },
      list: {
        maxWidth: 510,
        width: '510px',
        overflowY: 'unset',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        [breakpoints.down('xs')]: {
          width: '100vw',
          maxWidth: '100%',
        },
      },
      listTop: {
        marginBottom: spacing(2),
      },
      fullList: {
        width: 'auto',
        maxWidth: 450,
      },
      tabs: {
        background: palette.background.paper,
        zIndex: 2,
        position: 'sticky',
        top: 0,
        left: 0,
        paddingBottom: spacing(1),
        [breakpoints.up('sm')]: {
          marginLeft: spacing(-1),
          marginRight: spacing(-1),
        },
        [breakpoints.up('md')]: {
          marginLeft: spacing(-1.5),
          marginRight: spacing(-1.5),
        },
        [breakpoints.up('lg')]: {
          marginLeft: spacing(-2),
          marginRight: spacing(-2),
        },
        '& $tab:not(:last-child)': {
          borderRight: `1px solid ${palette.divider}`,
        },
      },
      tab: {
        fontSize: typography.subtitle2.fontSize,
      },
      selectedTab: {
        boxShadow: 'none !important',
      },
      header: {
        position: 'sticky',
        top: 48 + spacing(2),
        background: palette.background.paper,
        zIndex: 2,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        minHeight: 36 + spacing(1),
        marginLeft: spacing(-1),
        marginRight: spacing(-1),
        padding: `${spacing(0.5)}px ${spacing(1)}px`,
      },
      listNotifications: {
        '&:not(:last-child)': {
          paddingBottom: spacing(2),
          [breakpoints.up('md')]: {
            paddingBottom: spacing(3),
          },
        },
      },
      noContent: {
        minHeight: 'inherit',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '& > *': {
          display: 'block',
          margin: '0 auto',
        },
        '& > *:not(:last-child)': {
          marginBottom: spacing(1.5),
        },
      },
      clearButtonWrapper: {
        position: 'sticky',
        top: 48 + spacing(1),
        zIndex: 3,
      },
      clearButton: {
        position: 'absolute',
        top: 0,
        right: 0,
        minHeight: 36,
        paddingLeft: spacing(1),
        paddingRight: spacing(1),
        marginRight: spacing(-1),
        transform: `translateY(${spacing(0.5)}px)`,
      },
      tabPanel: {
        position: 'relative',
        display: 'flex',
        flex: 1,
        '& > div': {
          minHeight: '100%',
          width: '100%',
        },
      },
      newNotification: {
        color: palette.warning.main,
        animation: '$ring 7s ease-in-out infinite',
        transformOrigin: 'top center',
      },

      '@keyframes ring': {
        '0%': { transform: 'rotate(0)' },
        '1%': { transform: 'rotate(30deg)' },
        '3%': { transform: 'rotate(-28deg)' },
        '5%': { transform: 'rotate(34deg)' },
        '7%': { transform: 'rotate(-32deg)' },
        '9%': { transform: 'rotate(30deg)' },
        '11%': { transform: 'rotate(-28deg)' },
        '13%': { transform: 'rotate(26deg)' },
        '15%': { transform: 'rotate(-24deg)' },
        '17%': { transform: 'rotate(22deg)' },
        '19%': { transform: 'rotate(-20deg)' },
        '21%': { transform: 'rotate(18deg)' },
        '23%': { transform: 'rotate(-16deg)' },
        '25%': { transform: 'rotate(14deg)' },
        '27%': { transform: 'rotate(-12deg)' },
        '29%': { transform: 'rotate(10deg)' },
        '31%': { transform: 'rotate(-8deg)' },
        '33%': { transform: 'rotate(6deg)' },
        '35%': { transform: 'rotate(-4deg)' },
        '37%': { transform: 'rotate(2deg)' },
        '39%': { transform: 'rotate(-1deg)' },
        '41%': { transform: 'rotate(1deg)' },
        '43%': { transform: 'rotate(0)' },
        '100%': { transform: 'rotate(0)' },
      },
    }),
  { index: 0 }
);
