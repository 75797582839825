import { makeStyles, createStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ palette, breakpoints }) =>
    createStyles({
      notification: {
        opacity: 0,
        pointerEvents: 'none',
        borderRadius: '6px',
        borderColor: palette.success.main,
        marginBottom: '20px',
        transition: 'opacity 0.4s',
        [breakpoints.down('sm')]: {
          borderRadius: 0,
          marginBottom: 0,
        },
        '&.visible': {
          opacity: '1',
          pointerEvents: 'initial',
          transition: 'opacity 0.4s',
        },

        '&.error': {
          border: '1px solid',
          borderColor: palette.error.main,
        },
        '&.warning': {
          border: '1px solid',
          borderColor: palette.warning.main,
        },
        '&.info': {
          border: '1px solid',
          borderColor: palette.info.main,
        },
      },
      notificationHeader: {
        display: 'flex',
        alignItem: 'center',
        justifyContent: 'space-between',
        borderRadius: '6px',
        padding: '20px',
        backgroundColor: palette.success.main,
        color: palette.common.white,
        border: `1px solid`,
        borderColor: 'inherit',
        transition: 'border-radius 0.8s',
        boxSizing: 'border-box',
        fontWeight: '500',
        [breakpoints.down('sm')]: {
          borderRadius: 0,
        },
        '&.error': {
          backgroundColor: palette.error.main,
        },
        '&.warning': {
          backgroundColor: palette.warning.main,
        },
        '&.info': {
          backgroundColor: palette.info.main,
        },
      },
      notificationContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        borderRadius: '6px',

        height: 'auto',
        boxSizing: 'border-box',
        padding: '0 20px',
        transition: 'height .4s, border-color 0.8s',
        overflow: 'hidden',
      },

      notificationContentAvatar: {
        margin: '20px 0',
      },

      notificationContentMessage: {
        marginBottom: '20px',
      },
    }),
  { index: 0 }
);
