export const FileColsConfig = (
  t,
  nameSortable = true,
  statusSortable = true,
  updatedAtSortable = true
) => [
  {
    title: t('drivePage.documentsTable.fileName'),
    sortable: nameSortable,
    dataKey: 'name',
    type: 'text',
    colProps: {
      className: 'titleCell',
      xxs: 12,
      sm: true,
      md: true,
      orderXxs: 1,
    },
  },
  {
    title: t('drivePage.documentsTable.status'),
    sortable: statusSortable,
    dataKey: 'status',
    type: 'document-status',
    colProps: {
      xxs: 0,
      md: 3,
      textAlignXxs: 'right',
      textAlignMd: 'left',
      orderSm: 4,
      orderMd: 2,
    },
  },
  {
    title: t('drivePage.documentsTable.author'),
    sortable: false,
    dataKey: 'author',
    type: 'person-badge',
    colProps: {
      xxs: 0,
      sm: 'auto',
      md: 1,
      xl: 2,
      textAlignXxs: 'center',
      orderXxs: 2,
      orderSm: 3,
      orderMd: 3,
    },
  },
  {
    title: t('drivePage.documentsTable.updatedAt'),
    sortable: updatedAtSortable,
    dataKey: 'updatedAt',
    type: 'time-ago',
    colProps: {
      xxs: 12,
      sm: 'auto',
      md: 3,
      lg: 2,
      orderXxs: 3,
      orderSm: 2,
      orderMd: 4,
    },
  },
];

export const FolderColsConfig = (t) => [
  {
    title: t('drivePage.documentsTable.fileName'),
    dataKey: 'name',
    type: 'text',
    colProps: {
      className: 'titleCell',
      xxs: 12,
    },
  },
];

export default { FileColsConfig, FolderColsConfig };
