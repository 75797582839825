import { makeStyles, createStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ palette, breakpoints }) =>
    createStyles({
      containerWrapper: {
        background: palette.background.default,
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,

        [breakpoints.down('sm')]: {
          top: '50px',
        },
      },
      container: {
        height: '100%',
      },
      previewWrapper: {
        paddingTop: 16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      },
      previewImage: {
        width: '100%',
        height: '100%',
        objectFit: 'scale-down',
      },
    }),
  { index: 0 }
);
