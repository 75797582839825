import typography from '../typography';
import spacing from '../spacing';
import palette from '../palette';

export default {
  root: {
    padding: spacing(1.5, 1),

    '&:first-child': {
      paddingLeft: 0,
    },
    '&:last-child': {
      paddingRight: 0,
    },
  },
  head: {
    paddingTop: spacing(0.5),
    paddingBottom: spacing(0.5),
    fontSize: 12,
    fontWeight: typography.fontWeightRegular,
    textTransform: 'none',
    color: palette.text.secondary,
    lineHeight: 1,
  },
  stickyHeader: {
    backgroundColor: palette.common.white,
  },
  body: {
    fontSize: typography.htmlFontSize,
    color: 'inherit',
  },
  footer: {
    paddingTop: spacing(0.5),
    paddingBottom: spacing(0.5),
    fontSize: 12,
    fontWeight: typography.fontWeightRegular,
  },
  sizeSmall: {
    padding: spacing(0.5),
    '&:first-child': {
      paddingLeft: 0,
    },
    '&:last-child': {
      paddingRight: 0,
    },
  },
};
