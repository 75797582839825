import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Plus as PlusIcon } from 'src/components/CustomIcon';
import { useTranslation } from 'react-i18next';
import { Divider, FormControlLabel, Switch, Typography } from '@material-ui/core';
import { SortableContainer, SortableItem } from 'src/components/Sortable';
import { ConfirmDialog } from 'src/components';
import { IdentitySelector } from 'src/redux/selectors/identity.selector';
import { RootState } from 'src/redux/reducers';
import { DocumentMemberRole, UserManagerPerson } from 'src/models/documentUsersManagement.model';
import Section from '../subcomponents/Section';
import ItemsWrapper from '../subcomponents/ItemsWrapper';
import SectionButton from '../subcomponents/SectionButton';

import UserItem from '../subcomponents/UserItem/UserItem';

import InfoTooltip from '../subcomponents/InfoTooltip';
import { UsersManagerContext, UsersManagerContextType } from '../subcomponents/UsersManagerContext';
import { RequestStatus } from '../../../helpers/reduxReuquest.util';
import UserItemSkeleton from '../subcomponents/UserItem/UserItemSkeleton';
import { documentManagementSelector } from '../../../redux/selectors/documentManagement.selector';
import { arrayMoveImmutable } from '../../Sortable/sortableUtils';
import { useUserItemStyles } from '../subcomponents/UserItem/styles';
import AddNewItem from '../subcomponents/UserItem/AddNewItem';
import DocumentUserManagerSelector from '../../../redux/selectors/documentUserManager.selector';
import documentUserManagerActions from '../../../redux/actions/documentUserManager.actions';
import usePrevious from '../../../helpers/hooks/usePrevious';

type onSortEndParams = { oldIndex: number; newIndex: number };

const UsersManagerSigners = (): JSX.Element => {
  const dispatch = useDispatch();
  const userItemClasses = useUserItemStyles();
  const { t } = useTranslation();
  const hasSigningOrderPermission = useSelector(
    IdentitySelector.hasSigningOrderPermission,
    undefined
  );
  const { canAddSignerByInvitationDocCert, canModifySignersDocCert } = useSelector(
    DocumentUserManagerSelector.selectDocumentAction,
    undefined
  );
  const hasSomeSigning = useSelector(documentManagementSelector.hasDocumentAnySigning, undefined);
  const {
    documentSigners: { isOrderSignificant },
    documentMembersStatus,
    externalEntitiesStatus,
    searchedEntitiesStatus,
    documentSignersStatus,
  } = useSelector((state: RootState) => state.documentUsersManagerStore, undefined);
  const workspaceId = useSelector(IdentitySelector.selectWorkspaceId, undefined);
  const searchedEntities = useSelector(
    DocumentUserManagerSelector.selectSearchedEntitiesWithDetails,
    undefined
  );
  const {
    draftSigners,
    updateDraftSigners,
    updateDraftIsOrderSignificant,
    isDraftIsOrderSignificant,
  } = useContext(UsersManagerContext) as UsersManagerContextType;

  const [showSigningOrderConfirmModal, setShowSigningOrderConfirmModal] = useState<boolean>(false);
  const [focusInput, setFocusInput] = useState<boolean>(false);
  const [showAddNewItem, setShowAddNewItem] = useState<boolean>(false);

  const prevState = usePrevious({ documentSignersStatus });
  const isLoading =
    documentMembersStatus === RequestStatus.PENDING ||
    externalEntitiesStatus === RequestStatus.PENDING;

  const isAutocompleteLoading = searchedEntitiesStatus === RequestStatus.PENDING;

  const isSortable =
    !hasSomeSigning &&
    hasSigningOrderPermission &&
    isDraftIsOrderSignificant &&
    canModifySignersDocCert;

  // remove groups, already added items and external if signing order
  const autocompleteItems = searchedEntities.filter((entity) => {
    const isSearchable = !entity.isGroup && !draftSigners.map((el) => el._id).includes(entity._id);

    if (isDraftIsOrderSignificant) return !entity.isExternal && isSearchable;

    return isSearchable;
  });

  // Can remove item if canModifySigners and (is at least on user, or is some invitation)
  const canRemove = (signer: UserManagerPerson) =>
    canModifySignersDocCert &&
    (Boolean(draftSigners.filter((el) => el._id).length > 1) ||
      signer.isInvited ||
      signer.isInvitationToBeSend);

  // skeleton while loading
  const skeleton = useMemo(
    () => (
      <>
        <UserItemSkeleton />
        <Divider />
      </>
    ),
    []
  );

  // on "Set signing order" switch click
  const toggleIsOrderSignificant = () => {
    const showInfoModal =
      !isDraftIsOrderSignificant &&
      draftSigners.some(
        (signer) => signer.isExternal || signer.isInvited || signer.isInvitationToBeSend
      );

    // check if user is from workspace, otherwise info modal
    if (showInfoModal) {
      setShowSigningOrderConfirmModal(true);
    } else {
      updateDraftIsOrderSignificant(!isDraftIsOrderSignificant);
    }
  };

  // remove selectedValue
  const onSignerRemove = (index: number) => {
    updateDraftSigners(draftSigners.filter((item, idx) => idx !== index));
  };

  const confirmSetSigningOrder = () => {
    // update signers list
    updateDraftSigners(
      draftSigners.filter(
        (signer) => !(signer.isExternal || signer.isInvited || signer.isInvitationToBeSend)
      )
    );
    setShowSigningOrderConfirmModal(false);
    updateDraftIsOrderSignificant(true);
  };

  // Action after sort end === drag end;
  const onSortEnd = ({ oldIndex, newIndex }: onSortEndParams) => {
    const sorted = arrayMoveImmutable(draftSigners, oldIndex, newIndex);
    updateDraftSigners(sorted);
  };

  // Action updates draft state when user select entity from autocomplete list
  const onAddSigner = (val: UserManagerPerson) => {
    val.role = DocumentMemberRole.SIGNER;
    updateDraftSigners([...draftSigners, val]);
  };

  // Close, hide autocomplete list
  const onInputBlur = () => {
    setShowAddNewItem(false);
    setFocusInput(false);
    if (workspaceId) dispatch(documentUserManagerActions.getEntitiesByName(workspaceId, ''));
  };

  // Fetch entities by value from autocomplete
  const fetchWorkspaceEntities = (e: React.ChangeEvent<HTMLElement | unknown>, value: string) => {
    if (workspaceId) dispatch(documentUserManagerActions.getEntitiesByName(workspaceId, value));
  };

  // On add new item button click, fetches initial entities from contact book
  const onAddNewSignerClick = () => {
    setShowAddNewItem(true);
    setFocusInput(true);
    if (workspaceId) dispatch(documentUserManagerActions.getEntitiesByName(workspaceId, ''));
  };

  // set draft / prepared data when data is fetched
  useEffect(() => {
    if (
      prevState?.documentSignersStatus === RequestStatus.PENDING &&
      documentSignersStatus === RequestStatus.IDLE
    ) {
      updateDraftIsOrderSignificant(isOrderSignificant);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentSignersStatus, prevState]);

  return (
    <>
      <Section
        title={t<string>('documentUserManager.signers.title')}
        description={t('documentUserManager.signers.description')}
        leftContent={
          hasSigningOrderPermission ? (
            <FormControlLabel
              disabled={!canModifySignersDocCert}
              labelPlacement="end"
              control={
                <Switch
                  size="small"
                  checked={isDraftIsOrderSignificant}
                  onChange={toggleIsOrderSignificant}
                />
              }
              label={
                <div>
                  <Typography color="textSecondary" variant="body2">
                    {t<string>('documentUserManager.signers.setSigningOrder')}
                    <InfoTooltip
                      message={t<string>('documentUserManager.signers.setSigningOrderTooltip')}
                    />
                  </Typography>
                </div>
              }
            />
          ) : (
            <></>
          )
        }
        rightContent={
          <>
            <ItemsWrapper
              actionButtons={
                canModifySignersDocCert && canAddSignerByInvitationDocCert && !showAddNewItem ? (
                  <SectionButton
                    icon={<PlusIcon />}
                    onClick={onAddNewSignerClick}
                    text={t<string>('documentUserManager.signers.addNewSigner')}
                  />
                ) : undefined
              }
            >
              {isLoading ? (
                skeleton
              ) : (
                <>
                  <SortableContainer onSortEnd={onSortEnd} useDragHandle lockAxis="y">
                    {draftSigners.map((signer: UserManagerPerson, index: number) => (
                      <>
                        <SortableItem
                          index={index}
                          className={userItemClasses.sortable}
                          key={`item_${signer._id || signer.email}_${index}`}
                          allowItemsSorting={isSortable}
                        >
                          <UserItem
                            userData={signer}
                            canRemove={canRemove(signer)}
                            onRemove={() => onSignerRemove(index)}
                          />
                        </SortableItem>

                        {index !== draftSigners.length - 1 && <Divider />}
                      </>
                    ))}
                  </SortableContainer>

                  {showAddNewItem && (
                    <AddNewItem
                      isLoading={isAutocompleteLoading}
                      focused={focusInput}
                      onInputBlur={onInputBlur}
                      onInputChange={fetchWorkspaceEntities}
                      onAddClick={onAddSigner}
                      items={autocompleteItems}
                      addedItems={draftSigners}
                      canInviteByMail={
                        !isDraftIsOrderSignificant && canAddSignerByInvitationDocCert
                      }
                    />
                  )}
                </>
              )}
            </ItemsWrapper>
            {!canAddSignerByInvitationDocCert ||
              (!canModifySignersDocCert && (
                <div style={{ paddingLeft: 16, paddingRight: 16 }}>
                  <Typography variant="body1">
                    {t<string>('documentUserManager.signers.blockedInfo')}
                    <InfoTooltip
                      message={t<string>('documentUserManager.signers.blockedInfoTooltip')}
                    />
                  </Typography>
                </div>
              ))}
          </>
        }
      />
      <ConfirmDialog
        open={showSigningOrderConfirmModal}
        variant="primary"
        subtitle={t<string>('inviteToDocument.section.signers.orderSignificantConfirmModal')}
        applyButtonText={t('common.yes')}
        cancelButtonText={t('common.no')}
        actionAccept={confirmSetSigningOrder}
        actionCancel={() => setShowSigningOrderConfirmModal(false)}
      />
    </>
  );
};

export default UsersManagerSigners;
