import { RequestStatus } from 'src/helpers/reduxReuquest.util';
import { BillingProduct } from 'src/models/billing.model';
import BillingConstants from '../constants/billing.constants';

type BillingStateType = {
  subscriptions: BillingProduct[];
  fetchSubscriptionsStatus: RequestStatus;
  setTrialSubscriptionStatus: RequestStatus;
  setCustomerStatus: RequestStatus;
  isCustomerCreated: boolean;
};

const initialState: BillingStateType = {
  subscriptions: [],
  fetchSubscriptionsStatus: RequestStatus.IDLE,
  setTrialSubscriptionStatus: RequestStatus.IDLE,
  setCustomerStatus: RequestStatus.IDLE,
  isCustomerCreated: false,
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any
function billingStore(state: BillingStateType = initialState, action: any): BillingStateType {
  switch (action.type) {
    case BillingConstants.FETCH_SUBSCRIPTIONS_REQUEST:
      return {
        ...state,
        fetchSubscriptionsStatus: RequestStatus.PENDING,
      };
    case BillingConstants.FETCH_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        fetchSubscriptionsStatus: RequestStatus.SUCCESS,
        subscriptions: action.payload,
      };
    case BillingConstants.FETCH_SUBSCRIPTIONS_FAILURE:
      return {
        ...state,
        fetchSubscriptionsStatus: RequestStatus.ERROR,
        subscriptions: [],
      };
    case BillingConstants.SET_TRIAL_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        setTrialSubscriptionStatus: RequestStatus.PENDING,
      };
    case BillingConstants.SET_TRIAL_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        setTrialSubscriptionStatus: RequestStatus.SUCCESS,
      };
    case BillingConstants.SET_TRIAL_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        setTrialSubscriptionStatus: RequestStatus.ERROR,
      };
    case BillingConstants.SET_TRIAL_SUBSCRIPTION_IDLE:
      return {
        ...state,
        setTrialSubscriptionStatus: RequestStatus.IDLE,
      };
    case BillingConstants.FETCH_CUSTOMER_SUCCESS:
      return {
        ...state,
        isCustomerCreated: true,
      };
    case BillingConstants.FETCH_CUSTOMER_REQUEST:
    case BillingConstants.FETCH_CUSTOMER_FAILURE:
      return {
        ...state,
        isCustomerCreated: false,
      };
    case BillingConstants.SET_CUSTOMER_REQUEST:
      return {
        ...state,
        setCustomerStatus: RequestStatus.PENDING,
      };
    case BillingConstants.SET_CUSTOMER_SUCCESS:
      return {
        ...state,
        setCustomerStatus: RequestStatus.SUCCESS,
      };
    case BillingConstants.SET_CUSTOMER_FAILURE:
      return {
        ...state,
        setCustomerStatus: RequestStatus.ERROR,
      };
    case BillingConstants.RESET_BILLING:
      return initialState;
    default:
      return state;
  }
}

export default billingStore;
