import { darken, lighten } from '@material-ui/core/styles';
import palette from '../palette';

const getColor = function getColor(color) {
  return palette.type === 'light' ? lighten(color, 0.75) : darken(color, 0.5);
};

export default {
  bar: {
    transition: 'transform 0.15s linear',
  },
  bar1Determinate: {
    transition: 'transform 0.15s linear',
  },
  bar1Buffer: {
    transition: 'transform 0.15s linear',
  },
  bar2Buffer: {
    transition: 'transform 0.15s linear',
  },
  colorPrimary: {
    backgroundColor: getColor(palette.primary.main),
  },
};
