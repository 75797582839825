import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import Img from 'react-image';
import clsx from 'clsx';
import { useStyles } from './styles';

const EmptyBanner = ({
  children,
  bottomContent,
  src,
  title,
  titleVariant,
  description,
  buttonLabel,
  disabledImage,
  onClick,
  onLoad,
}) => {
  const [didMount, setDidMount] = useState(false);
  const classes = useStyles();
  useEffect(() => {
    setDidMount(true);
    return () => setDidMount(false);
  }, []);

  if (!didMount) {
    return null;
  }
  return (
    <div className={classes.root}>
      <Box textAlign="center" className={classes.inner}>
        {src && (
          <Img
            useSuspense={false}
            src={src}
            onLoad={onLoad}
            className={classes.image}
            container={(children) => (
              <div className={clsx(disabledImage ? classes.disabled : classes.imageContainer)}>
                {children}
              </div>
            )}
            loader={!disabledImage && <CircularProgress className={classes.imageLoader} />}
          />
        )}
        <div>
          {children}

          {title && (
            <Typography
              className={classes.title}
              variant={titleVariant}
              component="h4"
              gutterBottom
            >
              <strong>{title}</strong>
            </Typography>
          )}
          {description && (
            <Typography component="p" color="textSecondary">
              {description}
            </Typography>
          )}
          {bottomContent}
        </div>

        {onClick && buttonLabel && (
          <Button onClick={onClick} size="large" className={classes.button}>
            {buttonLabel}
          </Button>
        )}
      </Box>
    </div>
  );
};

EmptyBanner.propTypes = {
  children: PropTypes.node,
  bottomContent: PropTypes.node,
  src: PropTypes.string,
  title: PropTypes.string,
  titleVariant: PropTypes.oneOfType([
    PropTypes.oneOf([
      'body1',
      'body2',
      'button',
      'caption',
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
      'inherit',
      'overline',
      'subtitle1',
      'subtitle2',
    ]),
    PropTypes.string,
  ]),
  description: PropTypes.string,
  buttonLabel: PropTypes.string,
  disabledImage: PropTypes.bool,
  onClick: PropTypes.func,
  onLoad: PropTypes.func,
};

EmptyBanner.defaultProps = {
  titleVariant: 'h1',
};

export default EmptyBanner;
