export enum SNACKBAR_VARIANT {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
}

export enum API_MODULE {
  DocumentDslService = 'document-dsl-service',
  DocumentService = 'document-service',
  DocumentIndexingService = 'document-indexing-service',
  IdentityService = 'identity-service',
  BillingService = 'billing-service',
  AvatarService = 'avatar-service',
  BlockchainExplorer = 'blockchain-explorer',
  CommunicationsModule = 'communications-module',
  FilesModule = 'files-module',
}
