const isUserInSpecifiedGroup = (activeIdentity, rolesArray) => {
  if (!activeIdentity) return false;
  const inGroup = activeIdentity?.groups.find(
    (group) => group.active && rolesArray.filter((key) => group?.groupId === key)?.length > 0
  );
  return !!inGroup;
};

const hasDocumentAnySigning = (state) =>
  state.documentManagementStore.documentMembers.some((member) => member.status === 'SIGNED');

const isDocumentAdmin = (state) => {
  const { admins } = state.documentManagementStore.roles;
  const activeIdentity = state.identityStore.currentIdentity;

  return (
    admins?.length > 0 &&
    (admins.filter((key) => activeIdentity?.identity?.identityId === key)?.length > 0 ||
      isUserInSpecifiedGroup(activeIdentity, admins))
  );
};

const isDocumentEditor = (state) => {
  const { editors } = state.documentManagementStore.roles;
  const activeIdentity = state.identityStore.currentIdentity;

  return (
    editors?.length > 0 &&
    (editors.filter((key) => activeIdentity?.identity?.identityId === key)?.length > 0 ||
      isUserInSpecifiedGroup(activeIdentity, editors))
  );
};

const isDocumentSigner = (state) => {
  const { signers } = state.documentManagementStore.roles;
  const activeIdentity = state.identityStore.currentIdentity;

  return (
    signers?.length > 0 &&
    signers.filter((key) => activeIdentity?.identity?.identityId === key)?.length > 0
  );
};

export const documentManagementSelector = {
  hasDocumentAnySigning,
  isDocumentAdmin,
  isDocumentEditor,
  isDocumentSigner,
};
