import { fade } from '@material-ui/core/styles';
import palette from '../palette';

export default {
  root: {
    '&:first-child $rangeIntervalDayPreview': {
      borderLeftColor: palette.divider,
    },
    '&:last-child $rangeIntervalDayPreview': {
      borderRightColor: palette.divider,
    },
  },
  rangeIntervalDayHighlight: {
    borderRadius: 0,
    color: palette.primary.contrastText,
    backgroundColor: fade(palette.primary.light, 0.15),
  },
  day: {
    transform: 'none',
    '& > *': {
      transform: 'none',
    },
  },
  rangeIntervalDayHighlightStart: {},
  rangeIntervalDayHighlightEnd: {},
  dayOutsideRangeInterval: {},
  dayInsideRangeInterval: {
    color: palette.getContrastText(fade(palette.primary.light, 0.6)),
  },
  notSelectedDate: {
    backgroundColor: 'transparent',
  },
  rangeIntervalPreview: {
    border: '2px solid transparent',
  },
  rangeIntervalDayPreview: {
    borderRadius: 0,
    border: `2px dashed ${palette.bottomLineColor}`,
    borderLeftColor: 'transparent',
    borderRightColor: 'transparent',
    '&$rangeIntervalDayPreviewStart': {
      borderLeftColor: palette.bottomLineColor,
    },
    '&$rangeIntervalDayPreviewEnd': {
      borderRightColor: palette.bottomLineColor,
    },
  },
  rangeIntervalDayPreviewStart: {},
  rangeIntervalDayPreviewEnd: {},
};
