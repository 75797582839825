import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  Button,
  Fab,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Avatar, AvatarAndTextItem, AvatarGroup, ExtendedGrid } from 'src/components';
import { MoreHor as ActionsIcon } from 'src/components/CustomIcon';
import { useStyles } from '../styles';

const GroupItem = ({ item, actions, smallScreen }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();
  const { groupId, name } = item;
  const workspaceGroups = useSelector((state) => state.identityStore.workspaceGroups);
  const groupMembers = useMemo(() => {
    if (workspaceGroups?.length) {
      const groupIndex = workspaceGroups.findIndex((type) => type.info.groupId === groupId);
      return workspaceGroups[groupIndex]?.members || [];
    }
    return [];
  }, [groupId, workspaceGroups]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <ListItem classes={{ root: classes.listItemRoot, container: classes.listItemContainer }}>
      <ExtendedGrid container spacingX={2} spacingY={smallScreen ? 1 : 2} alignItemsXxs="center">
        <ExtendedGrid item xxs={12} xs={6} sm={5} md={4} lg={3} className={classes.colorOnHover}>
          <AvatarAndTextItem text={name} size="medium" />
        </ExtendedGrid>

        <ExtendedGrid item xxs={12} xs={6} sm={7} md={4}>
          <div className={classes.membersCell}>
            {!smallScreen && (
              <Typography>{groupMembers?.filter((member) => member.active).length || 0}</Typography>
            )}
            <AvatarGroup size="small">
              {groupMembers.map((member, index) => {
                const { identityId, name, active } = member;
                return (
                  active && (
                    <Avatar
                      key={`${index}-${identityId}`}
                      src={`${process.env.REACT_APP_AVATAR_URL}/identity/${identityId}?kind=SMALL`}
                    >
                      {name}
                    </Avatar>
                  )
                );
              })}
            </AvatarGroup>
          </div>
        </ExtendedGrid>
        {!smallScreen && (
          <ExtendedGrid item xxs textAlignXxs="right">
            <div className={classes.actionButtonsWrapper}>
              {actions.map(
                (action, index) =>
                  action.showButton && (
                    <Button
                      key={index}
                      variant="text"
                      size="small"
                      endIcon={action.icon}
                      classes={{
                        root: classes.actionButton,
                        endIcon: classes.actionButtonIcon,
                      }}
                      onClick={action.onClick}
                    >
                      {action.name}
                    </Button>
                  )
              )}
            </div>
          </ExtendedGrid>
        )}
      </ExtendedGrid>

      {smallScreen && (
        <ListItemSecondaryAction classes={{ root: classes.mobileActionButtonWrapper }}>
          <Tooltip title={t('common.actions')}>
            <Fab size="small" onClick={handleClick}>
              <ActionsIcon />
            </Fab>
          </Tooltip>

          <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
            {actions.map((action, index) => (
              <MenuItem
                key={index}
                onClick={() => {
                  handleClose();
                  action.onClick();
                }}
              >
                <ListItemIcon>{action.icon}</ListItemIcon>
                {action.name}
              </MenuItem>
            ))}
          </Menu>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};

GroupItem.propTypes = {
  item: PropTypes.shape({
    groupId: PropTypes.string,
    name: PropTypes.string,
  }),
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.node,
      name: PropTypes.string,
      onClick: PropTypes.func,
    })
  ),
  smallScreen: PropTypes.bool,
};

export default GroupItem;
