import React from 'react';

import { Time as TimeIcon, Drive as DriveIcon } from 'src/components/CustomIcon';

export const SidenavLinks = (t) => [
  {
    label: t('sideBar.drive'),
    url: '/drive',
    icon: <DriveIcon />,
  },
  {
    label: t('sideBar.recent'),
    url: '/recent',
    icon: <TimeIcon />,
  },
];

export const SidenavMobileLinks = (t) => [
  {
    label: t('sideBar.drive'),
    url: '/drive',
    icon: <DriveIcon />,
  },
  {
    label: t('sideBar.recent'),
    url: '/recent',
    icon: <TimeIcon />,
  },
];

export const MoreLinks = () => [];

export default { SidenavLinks, MoreLinks };
