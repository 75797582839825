import { Button, Box, Slider, Typography, CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { DropZone } from 'src/components';
import { DropzoneVariant } from '../DropZone/helpers';

const AvatarUpload = ({ handleUpdate, isLoading }) => {
  const { t } = useTranslation();
  let editor = '';
  const [picture, setPicture] = useState({
    cropperOpen: false,
    img: null,
    zoom: 2,
    croppedImg: '',
  });

  const handleSlider = (event, value) => {
    setPicture({
      ...picture,
      zoom: value,
    });
  };

  const setEditorRef = (ed) => {
    editor = ed;
  };

  const handleFileChange = (file) => {
    setPicture({
      ...picture,
      img: file,
      cropperOpen: true,
    });
  };

  const handleUploadAvatar = () => {
    if (setEditorRef) {
      const canvasScaled = editor.getImage();
      const croppedImg = canvasScaled.toDataURL();
      setPicture({
        ...picture,
        croppedImg,
      });
      handleUpdate(croppedImg.slice(5));
    }
  };

  return (
    <>
      {picture.cropperOpen ? (
        <>
          <Box display="flex" justifyContent="center">
            <Box display="flex" alignItems="center" flexDirection="column" gridGap="10px">
              <Typography>{t('userConfigurationPage.basic.avatarPage.title')}</Typography>
              <AvatarEditor
                ref={setEditorRef}
                image={picture.img}
                width={200}
                height={200}
                border={50}
                borderRadius={250}
                color={[255, 255, 255, 0.6]}
                rotate={0}
                scale={picture.zoom}
              />
              <Slider
                aria-label="raceSlider"
                value={picture.zoom}
                min={1}
                max={10}
                step={0.1}
                onChange={handleSlider}
              />
            </Box>
          </Box>
          <Button
            size="large"
            type="submit"
            fullWidth
            style={{ marginTop: 24 }}
            onClick={handleUploadAvatar}
            disabled={isLoading}
            endIcon={isLoading && <CircularProgress size={20} color="inherit" />}
          >
            {t('userConfigurationPage.basic.avatarPage.button')}
          </Button>
        </>
      ) : (
        <DropZone
          variant={DropzoneVariant.AVATAR}
          handleFileChange={handleFileChange}
          uploadStepTitle={t('dropzones.uploadPhotosTitle')}
        />
      )}
    </>
  );
};

AvatarUpload.propTypes = {
  handleUpdate: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default AvatarUpload;
