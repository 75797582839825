import spacing from '../spacing';

export default {
  root: {
    marginLeft: 0,
    '& .MuiRadio-root, & .MuiCheckbox-root': {
      marginLeft: (14 - spacing(1)) * -1,
    },
  },
  label: {
    lineHeight: 1.3,
  },
  labelPlacementStart: {
    marginRight: 0,
    '& > .MuiSwitch-root': {
      marginLeft: spacing(1),
      '&.MuiSwitch-sizeSmall': {
        marginLeft: spacing(0.5),
      },
    },
  },
  labelPlacementTop: {
    marginLeft: 0,
    marginRight: 0,
  },
  labelPlacementBottom: {
    marginLeft: 0,
    marginRight: 0,
  },
};
