import i18n from 'src/helpers/i18n';
import { SNACKBAR_VARIANT } from 'src/models/common.model';
import documentCreationConstants from '../constants/documentCreation.constants';
import documentCoreService from '../services/documentCore.service';
import filesModuleService from '../services/filesModule.service';
import snackbarActions from './snackbar.actions';

const documentCreationActions = {
  uploadFile,
  clearDocumentCreatorStore,
  getUploadToken,
  createNewDocument,
  addFileListDropzone,
  deleteFileListDropzone,
  setProgressUpload,
  setTemplateDocument,
  setSignatureDocument,
};

function uploadFile(file, token, fileSize, config) {
  return (dispatch) => {
    dispatch({ type: documentCreationConstants.UPLOAD_FILE_REQUEST });
    filesModuleService
      .uploadFile(file, token, fileSize, config)
      .then((response) => {
        dispatch({
          type: documentCreationConstants.UPLOAD_FILE_SUCCESS,
          folders: response,
          token,
        });
      })
      .catch(() => {
        dispatch({ type: documentCreationConstants.UPLOAD_FILE_FAILURE, token });
      });
  };
}

function getUploadToken(service) {
  return (dispatch) => {
    dispatch({ type: documentCreationConstants.GET_UPLOAD_TOKEN_REQUEST });
    documentCoreService
      .getUploadToken(service)
      .then((response) => {
        dispatch({
          type: documentCreationConstants.GET_UPLOAD_TOKEN_SUCCESS,
          data: response.data,
        });
      })
      .catch((error) => {
        dispatch({ type: documentCreationConstants.GET_UPLOAD_TOKEN_FAILURE });
        dispatch(
          snackbarActions.enqueueSnackbar(
            SNACKBAR_VARIANT.ERROR,
            i18n.t('snackbars.common.errorTitle'),
            `${i18n.t('snackbars.common.error')} ${error.code}.`
          )
        );
      });
  };
}

function createNewDocument(workspaceId, name, documentType, fileId, signatureLevel, folderId) {
  return (dispatch) => {
    dispatch({ type: documentCreationConstants.CREATE_NEW_DOCUMENT_REQUEST });
    documentCoreService
      .createNewDocument(workspaceId, name, documentType, fileId, signatureLevel, folderId)
      .then((response) => {
        dispatch({
          type: documentCreationConstants.CREATE_NEW_DOCUMENT_SUCCESS,
          data: response.data,
        });
      })
      .catch((error) => {
        dispatch({ type: documentCreationConstants.CREATE_NEW_DOCUMENT_FAILURE });
        dispatch(
          snackbarActions.enqueueSnackbar(
            SNACKBAR_VARIANT.ERROR,
            i18n.t('snackbars.common.errorTitle'),
            `${i18n.t('snackbars.common.error')} ${error.code}.`
          )
        );
      });
  };
}

function clearDocumentCreatorStore() {
  return { type: documentCreationConstants.CLEAR_DOCUMENT_CREATOR_STORE };
}

function addFileListDropzone(file) {
  return { type: documentCreationConstants.ADD_FILE_DROPZONE, file };
}

function deleteFileListDropzone(index) {
  return { type: documentCreationConstants.DELETE_FILE_DROPZONE, index };
}

function setProgressUpload(progress, token) {
  return { type: documentCreationConstants.SET_PROGRESS_UPLOAD_FILE, progress, token };
}

function setTemplateDocument(template) {
  return { type: documentCreationConstants.SET_TEMPLATE_DOCUMENT, template };
}

function setSignatureDocument(signature) {
  return { type: documentCreationConstants.SET_SIGNATURE_DOCUMENT, signature };
}

export default documentCreationActions;
