import { fade, lighten } from '@material-ui/core/styles';
import { makeStyles, createStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ breakpoints, palette, shadows, shape, spacing, transitions, typography }) =>
    createStyles({
      root: {
        textAlign: 'center',
      },
      content: {
        [breakpoints.down('sm')]: {
          flex: 1,
        },
      },
      title: {},
      subtitle: {
        marginBottom: spacing(2),
        padding: spacing(0, 2),
      },
      uploadList: {
        marginBottom: spacing(6),
        [breakpoints.down('sm')]: {
          marginBottom: spacing(6),
        },
      },
      uploadItems: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        width: '100%',
        [breakpoints.down('sm')]: {
          flexDirection: 'column',
          minHeight: '100%',
        },
      },
      uploadItem: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        padding: spacing(2, 0),
        width: '100%',
        '&:not(:last-child)': {
          borderBottom: `1px solid ${palette.divider}`,
        },
        [breakpoints.down('sm')]: {
          '&:first-child': {
            paddingTop: 0,
          },
          '&:last-child': {
            paddingBottom: spacing(1),
          },
        },
        [breakpoints.up('md')]: {
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'normal',
          width: '25%',
          padding: spacing(2, 1),
          '&:not(:last-child)': {
            borderBottom: 0,
          },
        },
        '&:hover': {
          '& $deleteDesktopButton': {
            opacity: 1,
          },
        },
      },
      uploadItemContent: {
        width: `calc(100% - 60px - 32px - ${spacing(2 * 2)}px)`,
        [breakpoints.up('sm')]: {
          width: `calc(100% - 68px - 32px - ${spacing(2 * 2)}px)`,
        },
        [breakpoints.up('md')]: {
          width: '100%',
        },
      },
      uploadItemTitle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        margin: `${spacing(2)}px 0 ${spacing(1)}px 0`,
        [breakpoints.down('sm')]: {
          marginTop: spacing(0),
          marginBottom: spacing(1),
          display: 'flex',
          justifyContent: 'flex-start',
        },
      },
      uploadName: {
        lineHeight: 1.3,
        [breakpoints.down('sm')]: {
          overflow: 'hidden',
          whiteSpace: 'normal',
          textOverflow: 'ellipsis',
        },
      },
      checkIcon: {
        display: 'inline-flex',
        color: palette.common.green,
        fontSize: 14,
        minWidth: 14,
        marginLeft: spacing(1),
      },
      iconBox: {
        width: 60,
        minWidth: 60,
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        backgroundColor: palette.secondary.light,
        borderRadius: shape.borderRadius,
        padding: spacing(0.5, 1),
        marginRight: spacing(2),
        transition: transitions.create(['box-shadow'], {
          duration: transitions.duration.short,
        }),
        [breakpoints.up('xs')]: {
          width: 68,
          minWidth: 68,
        },
        [breakpoints.up('md')]: {
          width: '100%',
          minWidth: 'initial',
          marginRight: 0,
          padding: spacing(1, 2),
        },
      },
      icon: {
        fontSize: spacing(4),
        [breakpoints.up('xs')]: {
          fontSize: spacing(5),
        },
        [breakpoints.up('md')]: {
          fontSize: spacing(7),
        },
      },
      valueText: {
        fontWeight: typography.fontWeightMedium,
      },
      valueTextSuccess: {
        color: palette.common.green,
      },
      barBox: {
        borderRadius: 4,
      },
      progressEnd: {
        backgroundColor: `${palette.common.green} !important`,
      },
      addItem: {
        color: palette.primary.main,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        height: '100%',
        borderRadius: shape.borderRadius,
      },
      iconAdd: {
        position: 'relative',
        zIndex: 1,
        color: palette.greySimple.main,
        width: spacing(8),
        height: spacing(8),
        margin: '0 auto',
        backgroundColor: fade(palette.background.paper, 0.5),
        border: `1px solid ${palette.divider}`,
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: spacing(1),
        transform: `translateY(${spacing(0.65)}px)`,
      },
      deleteDesktopButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        width: '100%',
        borderRadius: 'inherit',
        boxShadow: `0 0 0 1px ${palette.primary.main}`,
        opacity: 0,
        transition: transitions.create(['opacity'], {
          duration: transitions.duration.short,
        }),
        '& svg': {
          backgroundColor: palette.error.main,
          color: palette.error.contrastText,
          borderRadius: '50%',
          width: 32,
          height: 32,
          padding: 6,
          boxShadow: shadows[6],
        },
      },
      deleteButton: {
        backgroundColor: palette.error.main,
        marginLeft: spacing(2),
        transition: transitions.create(['opacity', 'color', 'background-color'], {
          duration: transitions.duration.short,
        }),
        '&:hover': {
          backgroundColor: lighten(palette.error.main, 0.075),
        },
        '&:focus, &:active': {
          backgroundColor: palette.error.dark,
        },
        '& svg': {
          color: palette.common.white,
        },
      },
      actionButtons: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        [breakpoints.up('sm')]: {
          flexDirection: 'row',
          justifyContent: 'center',
        },
        '& > *:not(:last-child)': {
          marginBottom: spacing(1),
          [breakpoints.up('sm')]: {
            marginBottom: 0,
            marginRight: spacing(2),
          },
        },
      },
      actionButton: {
        maxWidth: breakpoints.values.xs,
      },
      textAdd: {
        margin: spacing(0),
        marginTop: spacing(2),
      },
    }),
  { index: 0 }
);
