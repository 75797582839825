import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, IconButton, InputAdornment, TextField } from '@material-ui/core';
import { Visibility, VisibilityOff } from 'src/components/CustomIcon';
import { PasswordMeter, SmallContentWithScrollbar } from 'src/components';
import { useTranslation } from 'react-i18next';
import useForm from 'src/helpers/hooks/useForm';
import validate from 'src/helpers/formValidationRules';
import { useStyles } from './styles';

const PasswordEdit = ({ updateBasicData }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [oldPasswordVisible, setOldPasswordVisible] = useState(false);
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const handleUpdate = () => {
    updateBasicData('password', {
      oldPassword: values.oldPassword,
      newPassword: values.newPassword,
    });
  };

  const { values, errors, handleChange, handleSubmit } = useForm(handleUpdate, validate, {
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  return (
    <SmallContentWithScrollbar
      endContent={
        <Button size="large" type="submit" fullWidth>
          {t('userConfigurationPage.basic.passwordPage.button')}
        </Button>
      }
      hasForm
      formProps={{
        noValidate: true,
        autoComplete: 'off',
        onSubmit: handleSubmit,
      }}
      classes={{ content: classes.content }}
    >
      <TextField
        name="oldPassword"
        placeholder={t('userConfigurationPage.basic.passwordPage.currentPwdInputPlaceholder')}
        label={t('userConfigurationPage.basic.passwordPage.currentPwdInputLabel')}
        fullWidth
        error={errors.oldPassword?.length > 0}
        helperText={errors.oldPassword}
        type={oldPasswordVisible ? 'text' : 'password'}
        value={values.oldPassword || ''}
        onChange={handleChange}
        InputProps={{
          classes: { root: classes.inputPassword },
          endAdornment: (
            <InputAdornment position="start">
              <IconButton size="small" onClick={() => setOldPasswordVisible(!oldPasswordVisible)}>
                {oldPasswordVisible ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <div>
        <TextField
          name="newPassword"
          placeholder={t('userConfigurationPage.basic.passwordPage.newPwdInputPlaceholder')}
          label={t('userConfigurationPage.basic.passwordPage.newPwdInputLabel')}
          fullWidth
          error={errors.newPassword?.length > 0}
          helperText={errors.newPassword}
          type={newPasswordVisible ? 'text' : 'password'}
          value={values.newPassword || ''}
          onChange={handleChange}
          InputProps={{
            classes: { root: classes.inputPassword },
            endAdornment: (
              <InputAdornment position="start">
                <IconButton size="small" onClick={() => setNewPasswordVisible(!newPasswordVisible)}>
                  {newPasswordVisible ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <div className={classes.passwordMeter}>
          <PasswordMeter value={values.newPassword} />
        </div>
      </div>

      <TextField
        name="confirmPassword"
        placeholder={t('userConfigurationPage.basic.passwordPage.newPwdInputPlaceholder')}
        label={t('userConfigurationPage.basic.passwordPage.repeatedPwdInputLabel')}
        fullWidth
        error={errors.confirmPassword?.length > 0}
        helperText={errors.confirmPassword}
        type={confirmPasswordVisible ? 'text' : 'password'}
        value={values.confirmPassword || ''}
        onChange={handleChange}
        InputProps={{
          classes: { root: classes.inputPassword },
          endAdornment: (
            <InputAdornment position="start">
              <IconButton
                size="small"
                onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}
              >
                {confirmPasswordVisible ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </SmallContentWithScrollbar>
  );
};

PasswordEdit.propTypes = {
  updateBasicData: PropTypes.func,
};

export default PasswordEdit;
