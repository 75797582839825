import spacing from '../spacing';

export default {
  root: {
    color: 'inherit',
    minWidth: 20,
    marginRight: spacing(1.5),
    '& > svg': {
      fontSize: 20,
    },
  },
};
