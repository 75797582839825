import { ActionWithPayload } from './redux.util';

export enum RequestStatus {
  IDLE = 'idle',
  SUCCESS = 'success',
  PENDING = 'pending',
  ERROR = 'Error',
}

export interface ActionWithRequestStatus<T extends string, P> extends ActionWithPayload<T, P> {
  requestNames?: string[];
  requestStatus: RequestStatus;
}

export function createActionInProgress<T extends string, P>(
  type: T,
  payload: P,
  ...requestNames: string[]
): ActionWithRequestStatus<T, P> {
  return createAction(type, payload, [...requestNames], RequestStatus.PENDING);
}

export function createActionSuccess<T extends string, P>(
  type: T,
  payload: P,
  ...requestNames: string[]
): ActionWithRequestStatus<T, P> {
  return createAction(type, payload, [...requestNames], RequestStatus.SUCCESS);
}

export function createActionFailure<T extends string, P>(
  type: T,
  payload: P,
  ...requestNames: string[]
): ActionWithRequestStatus<T, P> {
  return createAction(type, payload, [...requestNames], RequestStatus.ERROR);
}

function createAction<T extends string, P>(
  type: T,
  payload: P,
  requestNames: string[],
  status: RequestStatus
): ActionWithRequestStatus<T, P> {
  return { type, payload, requestNames, requestStatus: status };
}
