import React from 'react';
import PropTypes from 'prop-types';
import { ListItemIcon, ListItemSecondaryAction, Menu, MenuItem } from '@material-ui/core';
import { useStyles } from './styles';

const TopBarOptionsMenu = ({ open, anchorEl, onClose, options }) => {
  const classes = useStyles();
  if (options?.length) {
    return (
      <Menu
        id="simple-menu"
        MenuListProps={{
          classes: {
            root: classes.menuRoot,
          },
        }}
        open={open}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        keepMounted
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {options.map((option, index) => (
          <MenuItem
            key={index}
            disabled={option.disabled}
            onClick={() => {
              option.onClick();
              onClose();
            }}
          >
            {option.icon && <ListItemIcon>{option.icon}</ListItemIcon>}
            {option.name}

            {option.secondaryIcon && (
              <ListItemSecondaryAction classes={{ root: classes.listItemSecondaryAction }}>
                {option.secondaryIcon}
              </ListItemSecondaryAction>
            )}
          </MenuItem>
        ))}
      </Menu>
    );
  }
  return null;
};

TopBarOptionsMenu.propTypes = {
  open: PropTypes.bool.isRequired,
  anchorEl: PropTypes.shape({}),
  onClose: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      icon: PropTypes.node,
      secondaryIcon: PropTypes.node,
      disabled: PropTypes.bool,
      onClick: PropTypes.func,
    })
  ),
};

TopBarOptionsMenu.defaultProps = {
  anchorEl: null,
};

export default TopBarOptionsMenu;
