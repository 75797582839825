import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ palette }) =>
    createStyles({
      icon: {
        color: palette.secondary.main,
        marginRight: 10,
        width: 20,
      },
    }),
  { index: 0 }
);
