import axios from 'src/helpers/axios';
import { parseError } from 'src/helpers/parseErrors';

export const moduleName = 'identity-service';

const identityService = {
  getWorkspaceStructure,
  fetchWorkspaceInfo,
  updateWorkspaceInfo,
  updateWorkspaceDetails,

  getWorkspaceGroupUsers,
  getWorkspaceInvitedUsers,
  searchWorkspaceGroupUsers,
  getUserStructure,
  createWorkspace,
  inviteUserToWorkspace,
  deleteUserFromWorkspace,
  deleteUserInvitationFromWorkspace,
  sendTransaction,

  fetchWorkspaceGroups,
  searchWorkspaceGroups,
  createWorkspaceGroup,
  deleteWorkspaceGroup,
  updateWorkspaceGroup,
  addMembersToWorkspaceGroup,
  deleteMembersFromWorkspaceGroup,
  fetchWorkspaceGroup,
  fetchWorkspaceGroupMembers,
  fetchWorkspaceEntities,

  switchContactEmail,

  getWorkspacePermissions,
};

function getWorkspaceStructure(workspaceId) {
  return axios
    .get(`/rest/${moduleName}/structure/workspaces/${workspaceId}`, {
      withPermissions: ['IDENTITY_SERVICE_USER_GET_WORKSPACE_STRUCTURE'],
    })
    .then((response) => response.data)
    .catch((error) => parseError(error));
}

function fetchWorkspaceInfo(workspaceId) {
  return axios
    .get(`/rest/${moduleName}/workspaces/${workspaceId}/info`)
    .then((response) => response.data)
    .catch((error) => parseError(error));
}

function updateWorkspaceInfo(workspaceId, newData) {
  return axios
    .put(`/rest/${moduleName}/workspaces/${workspaceId}/info`, newData)
    .then((response) => response.data)
    .catch((error) => parseError(error));
}

function updateWorkspaceDetails(workspaceId, newWorkspaceDetails) {
  return axios
    .put(`/rest/${moduleName}/workspaces/${workspaceId}`, newWorkspaceDetails)
    .then((response) => response.data)
    .catch((error) => parseError(error));
}

function getWorkspaceGroupUsers(groupId, page = 0, limit = 10, value = '') {
  const queryObj = {
    page,
    limit,
    memberIdentifier: value,
    sortBy: [{ name: 'name', order: 'ASC' }],
  };
  const query = encodeURIComponent(JSON.stringify(queryObj));
  return axios
    .get(`/rest/${moduleName}/structure/groups/${groupId}/members?query=${query}`, {
      withPermissions: ['IDENTITY_SERVICE_USER_GET_GROUP_MEMBERS'],
    })
    .then((response) => response.data)
    .catch((error) => parseError(error));
}

function getWorkspaceInvitedUsers(workspaceId) {
  return axios
    .get(`/rest/${moduleName}/invitations/${workspaceId}`)
    .then((response) => response.data)
    .catch((error) => parseError(error));
}

function searchWorkspaceGroupUsers(groupId, page = 0, limit = 50, value = '') {
  const queryObj = { page, limit, memberIdentifier: value };
  const query = encodeURIComponent(JSON.stringify(queryObj));
  return axios
    .get(`/rest/${moduleName}/structure/groups/${groupId}/members?query=${query}`, {
      withPermissions: ['IDENTITY_SERVICE_USER_GET_GROUP_MEMBERS'],
    })
    .then((response) => response.data)
    .catch((error) => parseError(error));
}

function getUserStructure() {
  return axios
    .get(`/rest/${moduleName}/structure`, {
      withPermissions: ['IDENTITY_SERVICE_USER_GET_USER_STRUCTURE'],
    })
    .then((response) => response.data)
    .catch((error) => parseError(error));
}

function createWorkspace(newWorkspaceData) {
  return axios
    .post(`/rest/${moduleName}/workspaces`, newWorkspaceData, {
      withPermissions: ['IDENTITY_SERVICE_USER_POST_WORKSPACES'],
    })
    .then((response) => response.data)
    .catch((error) => parseError(error));
}

function inviteUserToWorkspace(workspaceId, usersData) {
  const data = {
    emails: usersData,
  };
  return axios
    .post(`/rest/${moduleName}/workspaces/${workspaceId}/invite`, data, {
      withPermissions: ['IDENTITY_SERVICE_USER_POST_INVITATIONS'],
    })
    .then((response) => response)
    .catch((error) => parseError(error));
}

function deleteUserInvitationFromWorkspace(workspaceId, email) {
  return axios
    .delete(`/rest/${moduleName}/invitations/${workspaceId}`, {
      data: {
        email,
      },
    })
    .then((response) => response)
    .catch((error) => parseError(error));
}

function deleteUserFromWorkspace(workspaceId, identityId) {
  return axios
    .delete(`/rest/${moduleName}/workspaces/${workspaceId}/members/${identityId}`)
    .then((response) => response)
    .catch((error) => parseError(error));
}

function sendTransaction(txn) {
  const data = {
    incompleteTransaction: `${txn}`,
  };
  return axios
    .post(`/rest/${moduleName}/transactions`, data, {
      withPermissions: ['IDENTITY_SERVICE_USER_POST_TRANSACTIONS'],
    })
    .then((response) => response)
    .catch((error) => parseError(error));
}

function fetchWorkspaceGroups(
  workspaceId,
  force = false,
  page = 0,
  limit = 50,
  groupName = '',
  active = true
) {
  const query = force
    ? ''
    : `?query=${encodeURIComponent(
        JSON.stringify({ page, limit, groupName, active, sortBy: [{ name: 'name', order: 'ASC' }] })
      )}`;
  return axios
    .get(`/rest/${moduleName}/structure/workspaces/${workspaceId}/groups${query}`, {
      withPermissions: ['IDENTITY_SERVICE_USER_GET_WORKSPACE_GROUPS'],
    })
    .then((response) => response.data)
    .catch((error) => parseError(error));
}

function searchWorkspaceGroups(workspaceId, page, limit, groupName = '', active = true) {
  const query = encodeURIComponent(
    JSON.stringify({ page, limit, groupName, active, sortBy: [{ name: 'name', order: 'ASC' }] })
  );
  return axios
    .get(`/rest/${moduleName}/structure/workspaces/${workspaceId}/groups?query=${query}`, {
      withPermissions: ['IDENTITY_SERVICE_USER_GET_WORKSPACE_GROUPS'],
    })
    .then((response) => response.data)
    .catch((error) => parseError(error));
}

function createWorkspaceGroup(workspaceId, newWorkspaceGroupData) {
  return axios
    .post(`/rest/${moduleName}/workspaces/${workspaceId}/groups`, newWorkspaceGroupData, {
      withPermissions: ['IDENTITY_SERVICE_USER_POST_GROUPS'],
    })
    .then((response) => response.data)
    .catch((error) => parseError(error));
}

function deleteWorkspaceGroup(groupId) {
  return axios
    .delete(`/rest/${moduleName}/groups/${groupId}`, {
      withPermissions: ['IDENTITY_SERVICE_USER_DELETE_GROUPS'],
    })
    .then((response) => response.data)
    .catch((error) => parseError(error));
}

function updateWorkspaceGroup(groupId, newWorkspaceGroupData) {
  return axios
    .put(`/rest/${moduleName}/groups/${groupId}`, newWorkspaceGroupData, {
      withPermissions: ['IDENTITY_SERVICE_USER_PUT_GROUPS'],
    })
    .then((response) => response.data)
    .catch((error) => parseError(error));
}

function addMembersToWorkspaceGroup(groupId, identityIds) {
  return axios
    .post(
      `/rest/${moduleName}/groups/${groupId}/members`,
      { identityIds },
      {
        withPermissions: ['IDENTITY_SERVICE_USER_POST_GROUP_MEMBERSHIP'],
      }
    )
    .then((response) => response.data)
    .catch((error) => parseError(error));
}

function deleteMembersFromWorkspaceGroup(groupId, identityIds) {
  return axios
    .delete(`/rest/${moduleName}/groups/${groupId}/members`, {
      data: {
        identityIds,
      },
      withPermissions: ['IDENTITY_SERVICE_USER_DELETE_GROUP_MEMBERSHIP'],
    })
    .then((response) => response.data)
    .catch((error) => parseError(error));
}

function fetchWorkspaceGroup(groupId) {
  return axios
    .get(`/rest/${moduleName}/groups/${groupId}`, {
      withPermissions: ['IDENTITY_SERVICE_USER_GET_GROUPS'],
    })
    .then((response) => response.data)
    .catch((error) => parseError(error));
}

function fetchWorkspaceGroupMembers(
  groupId,
  page = 0,
  limit = 100,
  memberIdentifier = '',
  active = true
) {
  const query = encodeURIComponent(JSON.stringify({ page, limit, memberIdentifier, active }));

  return axios
    .get(`/rest/${moduleName}/structure/groups/${groupId}/members?query=${query}`, {
      withPermissions: ['IDENTITY_SERVICE_USER_GET_GROUP_MEMBERS'],
    })
    .then((response) => response.data)
    .catch((error) => parseError(error));
}

function fetchWorkspaceEntities(
  workspaceId,
  entityName,
  limit = 20,
  page = 0,
  sortBy = [{ name: 'entityName', order: 'ASC' }],
  workspaceName = '',
  entityTypes = ['IDENTITY', 'GROUP', 'CONTACT_BOOK_IDENTITY', 'CONTACT_BOOK_GROUP'],
  active = true
) {
  const query = encodeURIComponent(
    JSON.stringify({ entityName, active, limit, page, sortBy, workspaceName, entityTypes })
  );

  return axios
    .get(`/rest/${moduleName}/workspaces/${workspaceId}/entities?query=${query}`, {
      withPermissions: ['IDENTITY_SERVICE_USER_GET_WORKSPACE_ENTITIES'],
    })
    .then((response) => response.data)
    .catch((error) => parseError(error));
}

function switchContactEmail() {
  return axios
    .put(`/rest/${moduleName}/identities/contact-email/switch`, null, {
      withPermissions: ['IDENTITY_SERVICE_USER_PUT_SWITCH_CONTACT_EMAIL'],
    })
    .then((response) => response.data)
    .catch((error) => parseError(error));
}

function getWorkspacePermissions(workspaceId) {
  return axios
    .get(`/rest/${moduleName}/workspaces/${workspaceId}/permissions`, {
      withPermissions: ['IDENTITY_SERVICE_USER_GET_WORKSPACE_PERMISSIONS'],
    })
    .then((response) => response.data)
    .catch((error) => parseError(error));
}

export default identityService;
