import { BillingProductType } from 'src/models/billing.model';
import { TemplateDocumentType } from '../../models/documents.model';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    userengage: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    UE: any;
  }
}

window.userengage = window.userengage || {};
window.UE = window.UE || {};

export enum PhoneConfirmationStepType {
  Launched = '1_launched',
  Completed = '2_completed',
}
export enum WorkspaceSelectedType {
  Team = 'team',
  Personal = 'personal',
}

export enum WorkspaceDataChangedType {
  Logo = 'logo',
  Name = 'name',
}
export enum DocumentType {
  ESign = 'esign',
  Drive = 'drive',
  Certificate = 'certificate',
}

export enum DocumentNoDriveType {
  ESign = 'esign',
  Certificate = 'certificate',
}

export enum SubscriptionChangeType {
  TrialExtension = 'trial_extension',
  PurchaseDuringTrial = 'purchase_during_trial',
  PurchaseAfterTrial = 'purchase_after_trial',
  Renewal = 'renewal',
  Cancellation = 'cancellation',
  NewPurchase = 'new_purchase',
  Upgrade = 'upgrade',
}

export const templateTypeToDocumentType = (type: TemplateDocumentType): DocumentType => {
  switch (type) {
    case TemplateDocumentType.DOCUMENT_TO_BE_SIGNED:
      return DocumentType.ESign;
    case TemplateDocumentType.DURABLE_MEDIA:
      return DocumentType.Drive;
    case TemplateDocumentType.CERTIFICATES:
    default:
      return DocumentType.Certificate;
  }
};

const openChat = (): void => window.userengage('widget.open');

const document = {
  docAdded: (type: DocumentType, number: number, workspaceId: string): void =>
    window.userengage('event.doc_added', {
      type,
      number,
      workspaceId,
    }),
  docSigned: (type: DocumentNoDriveType, workspaceId: string): void =>
    window.userengage('event.doc_signed', {
      type,
      workspaceId,
    }),
  docRejected: (type: DocumentNoDriveType, workspaceId: string): void =>
    window.userengage('event.doc_rejected', {
      type,
      workspaceId,
    }),
  docSignersUpdated: (type: DocumentNoDriveType, number: number, workspaceId: string): void =>
    window.userengage('event.doc_signers_updated', {
      type,
      number,
      workspaceId,
    }),
  docRecipientsUpdated: (type: DocumentType, workspaceId: string): void =>
    window.userengage('event.doc_recipients_updated', {
      type,
      workspaceId,
    }),
  docDownloaded: (type: DocumentType, workspaceId: string): void =>
    window.userengage('event.doc_downloaded', {
      type,
      workspaceId,
    }),
  docCardDownloaded: (type: DocumentType, workspaceId: string): void =>
    window.userengage('event.doc_card_downloaded', {
      type,
      workspaceId,
    }),
};

const workspace = {
  dataMemberInvited: (number: number): void =>
    window.userengage('event.workspace_member_invited', {
      number,
    }),
  dataChanged: (type: WorkspaceDataChangedType): void =>
    window.userengage('event.workspace_data_changed', {
      type,
    }),
  typeSelected: (type: WorkspaceSelectedType, name: string): void =>
    window.userengage('event.workspace_type_selected', {
      type,
      name,
    }),
};

const user = {
  submitRequest: (workspaceId: string): void =>
    window.userengage('event.user_submit_request', {
      workspaceId,
    }),
  userJoinedWorkspace: (activeSubscriptions: BillingProductType[], workspaceId: string): void =>
    window.userengage('event.user_joined_workspace', {
      active_subscriptions: activeSubscriptions,
      workspaceId,
    }),
};

const subscription = {
  subTrialStarted: (productType: BillingProductType, workspaceId: string): void =>
    window.userengage('event.sub_trial_started', {
      product: productType,
      workspaceId,
    }),

  subChangesNeeded: (
    productType: BillingProductType,
    type: SubscriptionChangeType,
    workspaceId: string
  ): void =>
    window.userengage('event.sub_changes_needed', {
      product: productType,
      type,
      workspaceId,
    }),
};

export const userComActions = {
  openChat,
  subscription,
  user,
  document,
  workspace,
};
