import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from '../translations/en.json';
import pl from '../translations/pl.json';
import cookieHelper from './cookieHelper';

const customLanguageDetector = {
  type: 'languageDetector',
  async: false,
  init(services, detectorOptions, i18nextOptions) {
    this.supportedLngs = i18nextOptions.supportedLngs;
    this.failbackLng = i18nextOptions.failbackLng;
  },
  detect() {
    let lang = this.failbackLng;
    let foundLang = false;

    const cookieLang = cookieHelper.getCookie('lang');
    if (this.supportedLngs.indexOf(cookieLang.toLowerCase()) !== -1) {
      lang = cookieLang.toLowerCase();
      foundLang = true;
    }

    if (!foundLang) {
      let browserLang = window.navigator.language;
      if (browserLang.length > 4) {
        browserLang = browserLang.substr(0, 2);
      }
      lang = browserLang;
    }
    return lang;
  },
  cacheUserLanguage() {
    return undefined;
  },
};

i18n
  .use(customLanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['pl', 'en'],
    resources: {
      en: {
        translation: en,
      },
      pl: {
        translation: pl,
      },
    },
    fallbackLng: 'en',
    debug: process.env.NODE_ENV !== 'production',
    interpolation: {
      escapeValue: false,
    },
    cleanCode: true,
    react: {
      wait: true,
      bindI18n: 'languageChanged loaded',
      bindStore: 'added removed',
      nsMode: 'default',
    },
  });

export default i18n;
