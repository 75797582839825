import React from 'react';
import { DocumentStatus } from 'src/models/documents.model';
import { Tag } from 'src/components';
import { useTranslation } from 'react-i18next';

type DocumentStatusChipProps = {
  status: DocumentStatus;
};

type DocumentStatusColorVariantType = {
  [k in DocumentStatus]: string;
};

const DocumentStatusColorVariant: DocumentStatusColorVariantType = {
  [DocumentStatus.ISSUING]: 'info',
  [DocumentStatus.SIGNING]: 'info',
  [DocumentStatus.EDITING]: 'default',
  [DocumentStatus.VALID]: 'success',
  [DocumentStatus.READY_FOR_PREVIEW]: 'success',
  [DocumentStatus.FINISHED]: 'success',
  [DocumentStatus.INVALIDATED]: 'error',
  [DocumentStatus.EXPIRED]: 'error',
  [DocumentStatus.REJECTED]: 'error',
  [DocumentStatus.TEMPORARILY_INVALIDATE]: 'warning',
};

const DocumentStatusChip = ({ status }: DocumentStatusChipProps): JSX.Element => {
  const { t } = useTranslation();
  const color = DocumentStatusColorVariant[status];

  return <Tag color={color}>{t<string>(`statuses.${status}.short`)}</Tag>;
};

export default DocumentStatusChip;
