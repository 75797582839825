import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FullScreenDialog, SmallContentWithScrollbar, AutocompleteWithChip } from 'src/components';
import { Button, TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { AutocompleteWithChipType } from 'src/components/AutocompleteWithChip/AutocompleteWithChip';
import authActions from 'src/redux/actions/auth.actions';
import i18n from 'src/helpers/i18n';
import { RootState } from 'src/redux/reducers';
import { useStyles } from '../styles';

type AddKeyDialogProps = {
  isOpen: boolean;
  onClose: () => void;
};

const validationSchema = yup.object({
  keyName: yup.string().required(i18n.t<string>('validation.required')),
  permissions: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.string(),
        name: yup.string(),
      })
    )
    .min(1, i18n.t<string>('validation.required'))
    .required(i18n.t<string>('validation.required')),
});

interface initialValuesType {
  keyName: string;
  permissions: AutocompleteWithChipType[];
}
const AddKeyDialog = ({ isOpen, onClose }: AddKeyDialogProps): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { permissions } = useSelector((state: RootState) => state.authStore, undefined);

  const mappedItems: AutocompleteWithChipType[] = permissions.map((item: string) => ({
    id: item,
    name: item,
  }));

  const initialValues: initialValuesType = {
    keyName: '',
    permissions: [],
  };
  const formik = useFormik<initialValuesType>({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      dispatch(
        authActions.createNewApiKey({
          permissions: values.permissions.map((permission) => permission.id),
          name: values.keyName,
        })
      );
    },
  });

  return (
    <FullScreenDialog
      isOpen={isOpen}
      onBack={onClose}
      onClose={onClose}
      title={t<string>('userConfigurationPage.apiKeys.addNewKeyTitle')}
    >
      <SmallContentWithScrollbar
        scrollableContent
        hasForm
        formProps={{
          onSubmit: formik.handleSubmit,
        }}
        size="medium"
        endContent={
          <Button color="primary" variant="contained" fullWidth type="submit">
            {t<string>('userConfigurationPage.apiKeys.addKey')}
          </Button>
        }
      >
        <TextField
          fullWidth
          className={classes.textField}
          id="keyName"
          name="keyName"
          autoFocus
          label={t<string>('userConfigurationPage.apiKeys.keyName')}
          placeholder={t<string>('userConfigurationPage.apiKeys.keyNamePlaceholder')}
          value={formik.values.keyName}
          onChange={(e) => formik.setFieldValue('keyName', e.target.value.replace(' ', '_'))}
          error={formik.touched.keyName && Boolean(formik.errors.keyName)}
        />
        <div className={classes.textField}>
          <AutocompleteWithChip
            label={t<string>('userConfigurationPage.apiKeys.selectKeyPermission')}
            placeholder={t<string>('userConfigurationPage.apiKeys.selectKeyPermission')}
            items={mappedItems}
            name="permissions"
            defaultValues={formik.values.permissions}
            onChange={(value) => formik.setFieldValue('permissions', value)}
            error={formik.touched.permissions && Boolean(formik.errors.permissions)}
            helperText={formik.touched.permissions ? (formik.errors.permissions as string) : ''}
          />
        </div>
      </SmallContentWithScrollbar>
    </FullScreenDialog>
  );
};

export default AddKeyDialog;
