import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ spacing }) =>
    createStyles({
      menu: {
        paddingTop: spacing(0.75),
        paddingBottom: spacing(0.75),
        paddingRight: 0,
      },
      link: {
        display: 'flex',
      },
      imageAppStore: {
        height: 30,
        marginRight: spacing(0.5),
      },
      imagePlay: {
        height: 30,
      },
      appImageWrapper: {
        justifyContent: 'space-between',
      },
    }),
  { index: 0 }
);
