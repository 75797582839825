import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  ListItem,
  Theme,
  useMediaQuery,
  Grid,
  Tooltip,
  Chip,
  Typography,
  Fab,
} from '@material-ui/core';
import clsx from 'clsx';
import { Delete as DeleteIcon } from 'src/components/CustomIcon';
import { WorkspaceUser } from 'src/models/workspaceUser.model';
import { AvatarWithBadge } from 'src/components';
import { RegexFormulas } from 'src/helpers/regex';
import { useStyles } from '../styles';

type UserItemProps = {
  user: WorkspaceUser;
  onDeleteClick: () => void;
  canDelete: boolean;
};

const UserItem = ({ user, onDeleteClick, canDelete }: UserItemProps): JSX.Element => {
  const { t } = useTranslation();
  const smallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const classes = useStyles();
  const isInvited = user.identityId === undefined;
  const isDeleted = !(user.active || user.identityId === undefined);

  const avatarSrc = useMemo(
    () =>
      user.identityId && RegexFormulas.uuidV4.test(user.identityId)
        ? `${process.env.REACT_APP_AVATAR_URL}/identity/${user.identityId}?kind=SMALL`
        : null,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const userMail = user.name && user.name !== user.email ? user.email : undefined;
  return (
    <ListItem
      classes={{
        root: clsx(classes.listItemRoot),
      }}
    >
      <Grid container className={classes.listItemContainer}>
        <Grid item className={classes.userDataWrapper}>
          <AvatarWithBadge size="medium" src={avatarSrc} alt={user.name}>
            {user.name}
          </AvatarWithBadge>
          <div className={classes.textWrapper}>
            <div className={classes.titleWrapper}>
              {isInvited && (
                <Tooltip title={t<string>('workspacePage.users.sentInvitationTooltip')}>
                  <Chip
                    className={classes.invitedChip}
                    size="small"
                    label={t<string>('common.invitationByMail')}
                  />
                </Tooltip>
              )}
              {isDeleted && (
                <Tooltip title={t<string>('workspacePage.users.deletedInvitationTooltip')}>
                  <Chip
                    className={classes.deletedChip}
                    size="small"
                    label={t<string>('common.removed')}
                  />
                </Tooltip>
              )}
              <Typography variant="body1">{user.name || user.email}</Typography>
            </div>

            {userMail && (
              <Typography color="textSecondary" variant="body2">
                {userMail}
              </Typography>
            )}
          </div>
        </Grid>
        {!smallScreen && canDelete && (
          <div className={classes.actionButtonsWrapper}>
            <Button
              variant="text"
              size="small"
              endIcon={<DeleteIcon />}
              classes={{
                root: classes.actionButton,
                endIcon: classes.actionButtonIcon,
              }}
              onClick={onDeleteClick}
            >
              {t<string>('common.delete')}
            </Button>
          </div>
        )}
        {smallScreen && canDelete && (
          <Fab style={{ marginLeft: 'auto' }} size="small" color="primary" onClick={onDeleteClick}>
            <DeleteIcon />
          </Fab>
        )}
      </Grid>
    </ListItem>
  );
};

export default UserItem;
