import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import breakpoints from './options/breakpoints';
import mixins from './options/mixins';
import overrides from './options/overrides';
import palette from './options/palette';
import props from './options/props';
import shadows from './options/shadows';
import spacing from './options/spacing';
import typography from './options/typography';
import custom from './options/custom';

const theme = createMuiTheme({
  shape: {
    borderRadius: 6,
  },
  breakpoints,
  mixins,
  overrides,
  palette,
  props,
  shadows,
  spacing,
  typography,
  custom,
});

export default theme;
