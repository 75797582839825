import breakpoints from './breakpoints';
import custom from './custom';

export default {
  toolbar: {
    minHeight: custom.headerMobileNavHeight,
    [breakpoints.up('md')]: {
      minHeight: custom.headerNavHeight,
    },
  },
};
