import {
  DocumentInvitationMemberRole,
  UserManagerPerson,
} from 'src/models/documentUsersManagement.model';
import { API_MODULE, SNACKBAR_VARIANT } from 'src/models/common.model';
import documentInvitiationsService from 'src/redux/services/documentInvitations.service';
import i18n from '../../helpers/i18n';
import snackbarActions from './snackbar.actions';
import { documentMemberRoleToInvitationRole } from '../selectors/documentUserManager.selector';
import { AppDispatch } from '../storeTs';
import certificateCreatorActions from './certificateCreator.actions';
import documentDSLService from '../services/documentDSL.service';
import identityActions from './identity.actions';
import documentInvitationConstants from '../constants/documentInvitiation.constants';
import documentInvitationsTsServices from '../services/documentInvitationsTs.service';
import { DocumentRole } from '../../models/document.model';
import identityService from '../services/identity.service';
import certificateCreatorConstants from '../constants/certificateCreator.constants';

const genericErrorHelper = (msg: string) =>
  snackbarActions.enqueueSnackbar(
    SNACKBAR_VARIANT.ERROR,
    i18n.t('snackbars.common.errorTitle'),
    msg
  );

type inviteRolesByMailType = {
  documentIds: string[];
  service: API_MODULE.DocumentDslService | API_MODULE.DocumentService;
  users: UserManagerPerson[];
  successCallback?: () => void;
};

type parsedInvitationsType = {
  email: string | undefined;
  role: DocumentInvitationMemberRole;
  documentId: string;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const inviteRolesByMail = ({
  documentIds,
  users,
  service,
  successCallback,
}: inviteRolesByMailType) => {
  const parsedInvitations: parsedInvitationsType[] = users
    .map((user: UserManagerPerson) => [
      ...documentIds.map((documentId) => ({
        email: user.email,
        role: documentMemberRoleToInvitationRole(user.role),
        documentId,
      })),
    ])
    .flat();

  return (dispatch: AppDispatch) => {
    if (documentIds.length > 1) {
      // TODO: check for batch
      dispatch(
        certificateCreatorActions.inviteMultipleByRole({
          collection: parsedInvitations,
        })
      );
    } else if (service === API_MODULE.DocumentDslService) {
      // invite by mail for certificates
      const invitationPromises = parsedInvitations.map((user) =>
        documentDSLService.inviteRoleByMail(user.documentId, user.role, user.email)
      );
      dispatch({ type: documentInvitationConstants.INVITE_USERS_BY_MAIL_REQUEST });
      Promise.allSettled(invitationPromises)
        .then((results) => {
          dispatch({ type: documentInvitationConstants.INVITE_USERS_BY_MAIL_SUCCESS, results });
          // list of success results
          const successPromises = (results.filter(
            (c) => c.status === 'fulfilled'
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ) as PromiseFulfilledResult<any>[]).map((c) => c.value);
          // list of failed results
          const rejectedResults = (results.filter(
            (c) => c.status === 'rejected'
          ) as PromiseRejectedResult[]).map((c) => c.reason);

          // List of txn (tansactions) promises
          const txnPromises = successPromises
            .map((promise) => promise?.data?.data?.collection)
            .flat()
            .map((el) => el.txn)
            .filter((el) => el)
            .map((txn) => identityService.sendTransaction(txn));

          // list of success mails sent
          const emailsSend = successPromises.map(
            (promise) => ` ${JSON.parse(promise.config.data)?.email}`
          );

          // Show error if some
          if (successPromises?.length === 0 && rejectedResults?.length > 0) {
            dispatch({ type: documentInvitationConstants.INVITE_USERS_BY_MAIL_ERROR });
            dispatch(genericErrorHelper(i18n.t('inviteToDocument.invitationEmailsError')));
          }

          // Send transactions
          Promise.all(txnPromises)
            .then(() => {
              if (successCallback) successCallback();
              if (txnPromises.length === results.length) {
                dispatch(
                  snackbarActions.enqueueSnackbar(
                    SNACKBAR_VARIANT.SUCCESS,
                    i18n.t('snackbars.common.successTitle'),
                    i18n.t('inviteToDocument.invitationsEmailsSuccess')
                  )
                );
              } else {
                dispatch(
                  snackbarActions.enqueueSnackbar(
                    'info',
                    i18n.t('snackbars.common.infoTitle'),
                    `${i18n.t('inviteToDocument.invitationsEmailsInfo')} ${emailsSend.map(
                      (item) => item
                    )}.`
                  )
                );
              }
            })
            .catch(() => {
              dispatch({ type: documentInvitationConstants.INVITE_USERS_BY_MAIL_ERROR });
              dispatch(genericErrorHelper(i18n.t('inviteToDocument.invitationEmailsError')));
            });
        })
        .catch(() => {
          dispatch({ type: documentInvitationConstants.INVITE_USERS_BY_MAIL_ERROR });
          dispatch(genericErrorHelper(i18n.t('inviteToDocument.invitationEmailsError')));
        });
    } else {
      const invitationPromises = parsedInvitations.map((user) =>
        documentInvitiationsService.inviteRoleByMail(user.documentId, user.role, user.email)
      );
      dispatch({ type: documentInvitationConstants.INVITE_USERS_BY_MAIL_REQUEST });
      Promise.allSettled(invitationPromises)
        .then((results) => {
          dispatch({ type: documentInvitationConstants.INVITE_USERS_BY_MAIL_SUCCESS, results });
          const fulfilledPromises = (results.filter(
            (c: { status: string }) => c.status === 'fulfilled'
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ) as PromiseFulfilledResult<any>[]).map((c) => c.value);
          const failedResults = (results.filter(
            (c) => c.status === 'rejected'
          ) as PromiseRejectedResult[]).map((c) => c.reason);

          if (fulfilledPromises?.length === 0 && failedResults?.length > 0) {
            dispatch({ type: documentInvitationConstants.INVITE_USERS_BY_MAIL_ERROR });
            dispatch(genericErrorHelper(i18n.t('inviteToDocument.invitationEmailsError')));
          }

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const filteredResults = results.filter((result: any) => result?.value?.data?.data?.txn);

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const txnPromises = filteredResults.map((promise: any) =>
            identityService.sendTransaction(promise?.value?.data?.data.txn)
          );

          if (txnPromises?.length > 0) {
            Promise.all(txnPromises)
              .then(() => {
                if (successCallback) successCallback();
                if (txnPromises.length === filteredResults.length) {
                  dispatch(
                    snackbarActions.enqueueSnackbar(
                      SNACKBAR_VARIANT.SUCCESS,
                      i18n.t('snackbars.common.successTitle'),
                      i18n.t('inviteToDocument.invitationsRolesSendSuccess')
                    )
                  );
                } else {
                  dispatch(
                    snackbarActions.enqueueSnackbar(
                      SNACKBAR_VARIANT.SUCCESS,
                      i18n.t('snackbars.common.successTitle'),
                      i18n.t('inviteToDocument.invitationsRolesSendPartialSuccess')
                    )
                  );
                }
              })
              .catch(() => {
                dispatch({ type: documentInvitationConstants.INVITE_USERS_BY_MAIL_ERROR });
                dispatch(genericErrorHelper(i18n.t('inviteToDocument.invitationsRolesSendError')));
              });
          }
        })
        .catch(() => {
          dispatch({ type: documentInvitationConstants.INVITE_USERS_BY_MAIL_ERROR });
          dispatch(genericErrorHelper(i18n.t('inviteToDocument.invitationEmailsError')));
        });
    }
  };
};

type inviteMultipleByRoleDSLType = {
  collection: parsedInvitationsType[];
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const inviteMultipleByRoleDSL = ({ collection }: inviteMultipleByRoleDSLType) => (
  dispatch: AppDispatch
) => {
  dispatch({ type: documentInvitationConstants.INVITE_USERS_BY_MAIL_REQUEST });
  documentDSLService
    .inviteMultipleByRole(collection)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .then((response: any) => {
      if (response) {
        const txnCollection = response?.data?.data?.collection;

        if (txnCollection) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          txnCollection.forEach((item: any) => {
            if (item.txn) dispatch(identityActions.sendTransactionFromNewCertificate(item.txn));

            dispatch(
              snackbarActions.enqueueSnackbar(
                SNACKBAR_VARIANT.SUCCESS,
                i18n.t('snackbars.common.successTitle'),
                i18n.t('inviteToDocument.invitationsRolesSendSuccess')
              )
            );
          });

          dispatch({ type: documentInvitationConstants.INVITE_USERS_BY_MAIL_SUCCESS });
        }
      }
    })
    .catch((error) => {
      dispatch({ type: documentInvitationConstants.INVITE_USERS_BY_MAIL_ERROR });
      dispatch(
        snackbarActions.enqueueSnackbar(
          SNACKBAR_VARIANT.ERROR,
          i18n.t('snackbars.common.errorTitle'),
          `${i18n.t('snackbars.common.error')} ${error.code}.`
        )
      );
    });
};

type updateDocumentRolesType = {
  documentId: string;
  admins?: string[];
  auditors?: string[];
  editors?: string[];
  viewers?: string[];
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const updateDocumentRoles = ({
  documentId,
  admins,
  auditors,
  editors,
  viewers,
}: updateDocumentRolesType) =>
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  (dispatch: AppDispatch) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const documentRolesPromises: any[] = [];
    if (admins)
      documentRolesPromises.push(
        documentInvitiationsService.replaceDocumentRoles(documentId, DocumentRole.ADMINS, admins)
      );
    if (auditors)
      documentRolesPromises.push(
        documentInvitiationsService.replaceDocumentRoles(
          documentId,
          DocumentRole.AUDITORS,
          auditors
        )
      );
    if (editors)
      documentRolesPromises.push(
        documentInvitiationsService.replaceDocumentRoles(documentId, DocumentRole.EDITORS, editors)
      );
    if (viewers)
      documentRolesPromises.push(
        documentInvitiationsService.replaceDocumentRoles(documentId, DocumentRole.VIEWERS, viewers)
      );

    dispatch({ type: documentInvitationConstants.REPLACE_DOCUMENT_ROLES_REQUEST });
    Promise.allSettled(documentRolesPromises)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((response: any) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const filteredResults = response.filter((result: any) => result?.value?.data?.data?.txn);
        const fulfilledPromises = (response.filter(
          (c: { status: string }) => c.status === 'fulfilled'
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ) as PromiseFulfilledResult<any>[]).map((c) => c.value);
        const rejectedPromises = (response.filter(
          (c: { status: string }) => c.status === 'rejected'
        ) as PromiseRejectedResult[]).map((c) => c.reason);

        const txnPromises = filteredResults.map(
          (promise: { value: { data: { data: { txn?: string } } } }) =>
            identityService.sendTransaction(promise.value.data.data.txn)
        );

        if (fulfilledPromises?.length === 0 && rejectedPromises?.length > 0) {
          dispatch({ type: documentInvitationConstants.REPLACE_DOCUMENT_ROLES_ERROR });
          dispatch(genericErrorHelper(i18n.t('inviteToDocument.invitationsRolesSendError')));
        }

        if (txnPromises?.length > 0) {
          Promise.all(txnPromises)
            .then(() => {
              if (txnPromises.length === filteredResults.length) {
                dispatch({ type: documentInvitationConstants.REPLACE_DOCUMENT_ROLES_SUCCESS });
                dispatch(
                  snackbarActions.enqueueSnackbar(
                    SNACKBAR_VARIANT.SUCCESS,
                    i18n.t('snackbars.common.successTitle'),
                    i18n.t('inviteToDocument.invitationsRolesSendSuccess')
                  )
                );
              } else {
                dispatch({
                  type: documentInvitationConstants.REPLACE_DOCUMENT_ROLES_PARTIAL_SUCCESS,
                });
                dispatch(
                  snackbarActions.enqueueSnackbar(
                    SNACKBAR_VARIANT.SUCCESS,
                    i18n.t('snackbars.common.successTitle'),
                    i18n.t('inviteToDocument.invitationsRolesSendPartialSuccess')
                  )
                );
              }
            })
            .catch(() => {
              dispatch({ type: documentInvitationConstants.REPLACE_DOCUMENT_ROLES_ERROR });
              dispatch(genericErrorHelper(i18n.t('inviteToDocument.invitationsRolesSendError')));
            });
        }
      })
      .catch(() => {
        dispatch({ type: documentInvitationConstants.REPLACE_DOCUMENT_ROLES_ERROR });
        dispatch(genericErrorHelper(i18n.t('inviteToDocument.invitationsRolesSendError')));
      });
  };

type updateCertificateRolesType = {
  documentIds: string[];
  workspaceId: string;
  admins: string[];
  auditors: string[];
  editors: string[];
  viewers: string[];
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const updateCertificateRoles = ({
  workspaceId,
  documentIds,
  admins,
  editors,
  viewers,
}: updateCertificateRolesType) =>
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  (dispatch: AppDispatch) => {
    dispatch({ type: certificateCreatorConstants.UPDATE_CERTIFICATE_ROLES_REQUEST });
    documentDSLService
      .updateCertificateRoles({ workspaceId, admins, documentIds, editors, viewers })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((response: any) => {
        dispatch({ type: certificateCreatorConstants.UPDATE_CERTIFICATE_ROLES_SUCCESS });
        if (response) {
          const txn = response?.data?.data?.txn;
          if (txn) {
            dispatch(identityActions.sendTransactionFromNewCertificate(txn));
            dispatch(
              snackbarActions.enqueueSnackbar(
                SNACKBAR_VARIANT.SUCCESS,
                i18n.t('snackbars.common.successTitle'),
                i18n.t('inviteToDocument.invitationsRolesSendSuccess')
              )
            );
          }
        }
      })
      .catch((error) => {
        dispatch({ type: certificateCreatorConstants.UPDATE_CERTIFICATE_ROLES_FAILURE });
        dispatch(
          snackbarActions.enqueueSnackbar(
            SNACKBAR_VARIANT.ERROR,
            i18n.t('snackbars.common.errorTitle'),
            `${i18n.t('snackbars.common.error')} ${error.code}.`
          )
        );
      });
  };

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const deleteRolesByMail = ({ documentIds, users, service }: inviteRolesByMailType) => (
  dispatch: AppDispatch
) => {
  dispatch({ type: documentInvitationConstants.DELETE_USERS_MAIL_INVITATION_REQUEST });

  const parsedInvitations: parsedInvitationsType[] = users
    .map((user: UserManagerPerson) => [
      ...documentIds.map((documentId) => ({
        email: user.email,
        role: documentMemberRoleToInvitationRole(user.role),
        documentId,
      })),
    ])
    .flat();

  const deleteInvitationPromises = parsedInvitations.map((user) =>
    documentInvitationsTsServices.deleteInvitationByMail({
      documentId: user.documentId,
      email: user.email || '',
      role: user.role,
      service,
    })
  );

  Promise.allSettled(deleteInvitationPromises)
    .then((results) => {
      // dispatch({ type: documentInvitationConstants.INVITE_USERS_BY_MAIL_SUCCESS, results });
      const allValues = (results.filter(
        (c) => c.status === 'fulfilled'
      ) as PromiseFulfilledResult<unknown>[]).map((c) => c.value);
      const failedResults = (results.filter(
        (c) => c.status === 'rejected'
      ) as PromiseRejectedResult[]).map((c) => c.reason);

      if (allValues?.length === 0 && failedResults?.length > 0) {
        dispatch({ type: documentInvitationConstants.DELETE_USERS_MAIL_INVITATION_ERROR });
        dispatch(genericErrorHelper(i18n.t('inviteToDocument.deleteInvitationEmailsError')));
      }

      /*
       * TODO: Restore when functionality will be introduced to blockchain
       * */
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      // const txnPromises = allValues.map((promise:any) =>
      //   identityService.sendTransaction(promise?.value?.data?.data?.txn)
      // );
      // Promise.all(txnPromises)
      //   .catch(() => {
      //     dispatch({ type: documentInvitationConstants.DELETE_USERS_MAIL_INVITATION_ERROR });
      //     dispatch(genericErrorHelper(i18n.t('inviteToDocument.deleteInvitationEmailsError')));
      //   });
    })
    .catch(() => {
      dispatch({ type: documentInvitationConstants.DELETE_USERS_MAIL_INVITATION_ERROR });
      dispatch(genericErrorHelper(i18n.t('inviteToDocument.deleteInvitationEmailsError')));
    });
};

const documentInvitationsTsActions = {
  inviteRolesByMail,
  inviteMultipleByRoleDSL,
  updateDocumentRoles,
  updateCertificateRoles,
  deleteRolesByMail,
};

export default documentInvitationsTsActions;
