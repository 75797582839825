import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { ButtonBase, Collapse, Typography } from '@material-ui/core';
import { ArrowDownSLine as ArrowDownIcon } from 'src/components/CustomIcon';
import { useStyles } from './styles';

const CollapseItem = ({ className: classNameProp, children, title, openOnStart, alwaysOpen }) => {
  const [opened, setOpen] = useState(openOnStart);
  const classes = useStyles();
  const handleClick = () => {
    setOpen(!opened);
  };

  return (
    <div className={clsx(classes.root, (alwaysOpen || opened) && classes.rootOpen, classNameProp)}>
      <ButtonBase
        component="div"
        onClick={alwaysOpen ? undefined : handleClick}
        disableRipple={!!alwaysOpen}
        classes={{
          root: clsx(classes.header, alwaysOpen && classes.notClickable),
        }}
      >
        <Typography>{title}</Typography>

        {!alwaysOpen && (
          <div className={classes.headerIcon}>
            {' '}
            <ArrowDownIcon />
          </div>
        )}
      </ButtonBase>

      <Collapse in={alwaysOpen === true ? alwaysOpen : opened} timeout="auto" unmountOnExit>
        <div className={classes.content}>{children}</div>
      </Collapse>
    </div>
  );
};

CollapseItem.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  title: PropTypes.node,
  alwaysOpen: PropTypes.bool,
  openOnStart: PropTypes.bool,
};

CollapseItem.defaultProps = {
  openOnStart: false,
  alwaysOpen: false,
};

export default CollapseItem;
