import React from 'react';
import PropTypes from 'prop-types';
import { Button, TextField, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useForm from 'src/helpers/hooks/useForm';
import validate from 'src/helpers/formValidationRules';
import { SmallContentWithScrollbar } from 'src/components';

const EditWorkspace = (props) => {
  const { workspace, update, isPending } = props;
  const { t } = useTranslation();
  const changeName = () => {
    update({ name: values.workspaceNewName });
  };

  const { values, errors, handleChange, handleSubmit } = useForm(changeName, validate, {
    workspaceNewName: workspace.name,
  });

  return (
    <SmallContentWithScrollbar
      startContent={
        <Typography variant="body1">{t('workspacePage.editModals.subtitle1')}</Typography>
      }
      endContent={
        <Button size="large" type="submit" fullWidth disabled={isPending}>
          {t('workspacePage.editModals.btnChangeName')}
        </Button>
      }
      hasForm
      formProps={{
        noValidate: true,
        autoComplete: 'off',
        onSubmit: handleSubmit,
      }}
    >
      <TextField
        name="workspaceNewName"
        placeholder={t('workspacePage.editModals.labelName')}
        fullWidth
        value={values.workspaceNewName}
        onChange={handleChange}
        disabled={isPending}
        error={errors.workspaceNewName?.length > 0}
        helperText={errors.workspaceNewName}
      />
    </SmallContentWithScrollbar>
  );
};

EditWorkspace.propTypes = {
  workspace: PropTypes.shape({
    name: PropTypes.string,
    data: PropTypes.shape({}),
  }),
  update: PropTypes.func,
  isPending: PropTypes.bool,
};

export default EditWorkspace;
