import i18n from '../../helpers/i18n';
import { isMail } from '../../helpers/globalUtils';

export const extendOptionsByIdAndFirstLetter = (object) =>
  object.map((option) => {
    const firstLetter = option.name[0].toUpperCase();
    return {
      ...option,
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      id: option?.id || option?.identityId,
    };
  });

export const renderOptionDescription = ({ option, documentInvitationMode, workspaceId }) => {
  let description = option.name;
  if (documentInvitationMode) {
    // group
    if (option.group) {
      // group from workspace
      if (option.workspaceId === workspaceId) {
        description = `${i18n.t('inviteToDocument.entitiesList.group')}`;
      }
      // group from external workspace
      else {
        description = `${i18n.t(
          'inviteToDocument.entitiesList.groupOutsideOrganization'
        )}: <span class="highlighted">${option.workspaceName}</span>`;
      }
    }
    // new mail invitation, on typing from keyboard
    else if (option.inputValue) {
      description = i18n.t('inviteToDocument.entitiesList.mailInvitationToBeSend');
    }
    // mail invitation, field email describes type of invitation
    else if (typeof option.id !== 'string') {
      description = option.email;
    }
    // user from workspace
    else if (option.workspaceId === workspaceId) {
      // user from workspace with name different from mail
      if (option.name !== option.email) {
        description = `${option.email} (${i18n
          .t('inviteToDocument.entitiesList.user')
          .toLowerCase()})`;
      }
      // user from workspace with same name and email
      else {
        description = `${i18n.t('inviteToDocument.entitiesList.user')}`;
      }
    }
    // user from outside workspace
    else if (option.workspaceId !== workspaceId) {
      // user from outside workspace with name different from email
      if (option.name !== option.email) {
        description = `${option.email} (${i18n
          .t('inviteToDocument.entitiesList.outsideOrganization')
          .toLowerCase()}: <span class="highlighted">${option.workspaceName}</span>)`;
      }
      // user from outside workspace with name same as email
      else {
        description = `${i18n.t(
          'inviteToDocument.entitiesList.outsideOrganization'
        )}: <span class="highlighted">${option.workspaceName}</strong>`;
      }
    }
  }
  return description;
};

export const filterOptionsFunctions = {
  isMail: (value) => isMail(value),
  isNotEmptyString: (value) => value !== '',
  isNameAndMailNotRepeated: ({ items, value, allowDuplicatedForDiffIds = false }) =>
    allowDuplicatedForDiffIds
      ? !items.some((e) => (e.name === value || e.email === value) && !e.identityId)
      : !items.some((e) => e.name === value || e.email === value),
};
