import { createStyles, makeStyles } from '@material-ui/core';
import { lighten } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  ({ breakpoints, palette, spacing, transitions }) =>
    createStyles({
      invitedChip: {
        color: palette.info.main,
        backgroundColor: lighten(palette.info.main, 0.9),
        marginLeft: 6,
        order: 2,

        [breakpoints.down('md')]: {
          maxWidth: 'fit-content',
          marginLeft: 0,
          marginBottom: 4,
          order: 0,
        },
      },
      deletedChip: {
        color: palette.error.main,
        background: lighten(palette.error.main, 0.9),
        marginLeft: 6,
        order: 2,

        [breakpoints.down('md')]: {
          maxWidth: 'fit-content',
          marginLeft: 0,
          marginBottom: 4,
          order: 0,
        },
      },
      textWrapper: {
        paddingLeft: spacing(2),
        flex: 1,
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        marginRight: 'auto',
      },
      titleWrapper: {
        display: 'flex',

        [breakpoints.down('md')]: {
          display: 'grid',
          '& p': {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          },
        },
      },
      root: {},
      listItemContainer: {
        [breakpoints.down('md')]: {
          flexWrap: 'nowrap',
          alignItems: 'center',
        },
      },
      listItemRoot: {
        color: palette.text.primary,
        padding: spacing(2, 0),
        [breakpoints.up('md')]: {
          '&:first-child': {
            paddingTop: 0,
          },
          '&:last-child': {
            paddingBottom: 0,
          },
        },
        '&:hover': {
          color: 'inherit',
          '& $actionButton': {
            color: palette.text.primary,
          },
          '& $colorOnHover, & $actionButtonIcon': {
            color: `${palette.primary.main} !important`,
          },
        },
      },
      listItemDeleted: {
        opacity: 0.5,
        pointerEvents: 'none',
      },
      userDataWrapper: {
        display: 'flex',
        alignItems: 'center',
        transition: transitions.create(['color'], {
          duration: transitions.duration.short,
        }),
      },
      actionButtonsWrapper: {
        textAlign: 'right',
        marginLeft: 'auto',

        [breakpoints.down('md')]: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
        },
      },
      actionButton: {
        paddingLeft: spacing(1.25),
        paddingRight: spacing(1.25),
      },
      actionButtonIcon: {
        transition: 'inherit',
        color: `${palette.text.secondary} !important`,
      },
      mobileActionButtonWrapper: {
        display: 'flex',
        flexDirection: 'column',
        right: spacing(-1),
      },
      membersCell: {
        display: 'flex',
        alignItems: 'center',
        '& > *:first-child': {
          minWidth: spacing(2.5),
          [breakpoints.up('md')]: {
            minWidth: spacing(3),
          },
        },
        '& > *:not(:last-child)': {
          marginRight: spacing(0.5),
          [breakpoints.up('md')]: {
            marginRight: spacing(1),
          },
        },
      },
    }),
  { index: 0 }
);
