enum documentDisplayConstants {
  GET_DOCUMENT_REQUEST = 'GET_DOCUMENT_REQUEST',
  GET_DOCUMENT_SUCCESS = 'GET_DOCUMENT_SUCCESS',
  GET_DOCUMENT_FAILURE = 'GET_DOCUMENT_FAILURE',

  GET_DOWNLOAD_TOKEN_REQUEST = 'GET_DOWNLOAD_TOKEN_REQUEST',
  GET_DOWNLOAD_TOKEN_SUCCESS = 'GET_DOWNLOAD_TOKEN_SUCCESS',
  GET_DOWNLOAD_TOKEN_FAILURE = 'GET_DOWNLOAD_TOKEN_FAILURE',

  DOWNLOAD_FILE_REQUEST = 'DOWNLOAD_FILE_REQUEST',
  DOWNLOAD_FILE_SUCCESS = 'DOWNLOAD_FILE_SUCCESS',
  DOWNLOAD_FILE_FAILURE = 'DOWNLOAD_FILE_FAILURE',

  ADD_EMPTY_FILE = 'ADD_EMPTY_FILE',
  SET_ACTIVE_FILE_INDEX = 'SET_ACTIVE_FILE_INDEX',

  CLEAR_FILES_AND_TOKENS = 'CLEAR_FILES_AND_TOKENS',
  CLEAR_DOCUMENT_DISPLAY_STORE = 'CLEAR_DOCUMENT_DISPLAY_STORE',

  WS_UPDATE_DOCUMENT = 'WS_UPDATE_DOCUMENT',

  DOCUMENT_SIGN = 'DOCUMENT_SIGN',
  DOCUMENT_REJECT = 'DOCUMENT_REJECT',
  DOCUMENT_INVALIDATE = 'DOCUMENT_INVALIDATE',
  DOCUMENT_INVALIDATE_TEMPORARILY = 'DOCUMENT_INVALIDATE_TEMPORARILY',
  DOCUMENT_VALIDATE = 'DOCUMENT_VALIDATE',
  DOCUMENT_SET_TRANSFERABILITY = 'DOCUMENT_SET_TRANSFERABILITY',
}

export default documentDisplayConstants;
