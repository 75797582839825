import React from 'react';
import PropTypes from 'prop-types';
import { Tab, Tabs, useMediaQuery, useTheme } from '@material-ui/core';
import { useStyles } from './styles';

function a11yProps(index, classes) {
  return {
    id: `auto-tab-${index}`,
    'aria-controls': `auto-tabpanel-${index}`,
    classes: {
      root: classes.tabRoot,
      selected: classes.selectedTab,
      disabled: classes.disabledTab,
    },
  };
}

const ActionsTabs = ({ value, tabs, showIcon, onChange }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { breakpoints } = theme;
  const miniScreen = useMediaQuery(`(max-width: ${breakpoints.values.xs - 1}px)`);

  return (
    <div className={classes.root}>
      <Tabs
        value={value}
        onChange={onChange}
        variant={miniScreen ? 'scrollable' : 'fullWidth'}
        classes={{
          root: classes.tabsRoot,
          scroller: classes.tabsScroller,
        }}
      >
        {tabs.map((tab, index) => (
          <Tab
            value={tab.value}
            key={index}
            label={tab.name}
            icon={showIcon && tab.icon}
            {...a11yProps(index, classes)}
          />
        ))}
      </Tabs>
    </div>
  );
};

ActionsTabs.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      icon: PropTypes.node,
      name: PropTypes.string,
    })
  ),
  showIcon: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

ActionsTabs.defaultProps = {
  showIcon: true,
};

export default ActionsTabs;
