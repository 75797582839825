import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ breakpoints, mixins, palette, spacing }) =>
    createStyles({
      root: {
        height: 'auto',
        flexDirection: 'row',
        alignItems: 'center',
        [breakpoints.down('sm')]: {
          borderBottom: `1px solid ${palette.divider}`,
        },
        ...mixins.toolbar,
      },
      mainContent: {
        display: 'flex',
        alignItems: 'center',
        flex: 1,
      },
      searchField: {
        backgroundColor: palette.background.default,
        flexGrow: 1,
      },
      headerTitle: {
        flex: 1,
      },
      actions: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
      },
      actionsLeft: {
        paddingRight: spacing(1),
        '& > *': {
          '&:not(:last-child)': {
            marginRight: spacing(0.5),
            [breakpoints.up('md')]: {
              marginRight: spacing(1),
            },
          },
        },
      },
      actionsRight: {
        marginLeft: spacing(1),
      },
      toolbar: {
        position: 'relative',
        justifyContent: 'space-between',
      },
    }),
  { index: 0 }
);
