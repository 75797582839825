import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ palette }) =>
    createStyles({
      root: {},
      responsive: {
        maxWidth: '100%',
      },
      inside: {
        backgroundColor: palette.background.paper,
        position: 'relative',
        paddingBottom: '100%',
        '& img': {
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
        },
      },
      loader: {
        zIndex: 1,
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: palette.background.paper,
      },
      loaderInner: {
        position: 'absolute',
        top: '50%',
        left: '50%',
      },
    }),
  { index: 0 }
);
