import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import WebSocketHelper from 'src/helpers/WebSocketHelper';
import documentDisplayActions from 'src/redux/actions/documentDisplay.actions';
import { moduleName as documentDSLService } from 'src/redux/services/documentDSL.service';
import documentManagementActions from 'src/redux/actions/documentManagement.actions';
import documentInvitationActions from 'src/redux/actions/documentInvitiation.actions';
import snackbarActions from 'src/redux/actions/snackbar.actions';
import { SNACKBAR_VARIANT } from 'src/models/common.model';
import i18n from 'src/helpers/i18n';

const MAP_WS_ACTION_TO_MESSAGE = {
  TRANSFERABILITY_CHANGE: 'documentActions.transferabilityChange.toast.success',
  VALIDATE: 'documentActions.validateDocument.toast.success',
  INVALIDATE_TEMPORARY: 'documentActions.temporarilyInvalidateDocument.toast.success',
  INVALIDATE: 'documentActions.invalidateDocument.toast.success',
  SIGN: 'documentActions.signDocument.toast.success',
  REJECT: 'documentActions.rejectDocument.toast.success',
  CHANGE_HOLDER: 'documentActions.transferOwnership.toast.success',
  MEMBERS_CHANGE: 'workspacePage.groups.fetchGroupMembersStructure.success',
};

const CertificateActionsWebsocket = () => {
  const ws = useRef(null);
  const { document: documentInfo } = useSelector((state) => state.documentDisplayStore, undefined);
  const dispatch = useDispatch();
  const { currentIdentity } = useSelector((state) => state.identityStore, undefined);
  const workspaceId = currentIdentity?.workspace?.workspaceId;

  const onWebSocketMessage = (message) => {
    if (message.documentId === documentInfo.id) {
      switch (message.action) {
        case 'EXPIRED':
        case 'TRANSFERABILITY_CHANGE':
        case 'VALIDATE':
        case 'INVALIDATE_TEMPORARY':
        case 'INVALIDATE':
        case 'SIGN':
        case 'REJECT':
          if (MAP_WS_ACTION_TO_MESSAGE[message.action]) {
            dispatch(
              snackbarActions.enqueueSnackbar(
                SNACKBAR_VARIANT.SUCCESS,
                i18n.t('snackbars.common.successTitle'),
                i18n.t(MAP_WS_ACTION_TO_MESSAGE[message.action])
              )
            );
          }

          dispatch(documentDisplayActions.getDocument(message.documentId, documentDSLService));
          dispatch(documentManagementActions.reloadDocumentHistory());

          break;
        case 'CHANGE_HOLDER':
        case 'MEMBERS_CHANGE':
          if (MAP_WS_ACTION_TO_MESSAGE[message.action]) {
            dispatch(
              snackbarActions.enqueueSnackbar(
                SNACKBAR_VARIANT.SUCCESS,
                i18n.t('snackbars.common.successTitle'),
                i18n.t(MAP_WS_ACTION_TO_MESSAGE[message.action])
              )
            );
          }
          dispatch(
            documentManagementActions.getDocumentMembers(message.documentId, documentDSLService)
          );
          dispatch(
            documentManagementActions.getDocumentInvitations(message.documentId, documentDSLService)
          );
          dispatch(documentManagementActions.reloadDocumentHistory());

          break;
        case 'SIGNATURE_CHANGES': {
          dispatch(
            documentInvitationActions.getDocumentSigners(message.documentId, documentDSLService)
          );
          dispatch(
            documentManagementActions.getDocumentInvitations(message.documentId, documentDSLService)
          );
          dispatch(documentManagementActions.reloadDocumentHistory());

          break;
        }
        default: {
          console.warn(`Unknown code [${message.code}]`, message);
        }
      }
    }
  };

  useEffect(() => {
    if (workspaceId && documentInfo.id) {
      ws.current = WebSocketHelper.subscribe(
        'document-dsl-service',
        `workspaces/${workspaceId}/document-updates`,
        (message) => {
          onWebSocketMessage(message);
        }
      );
    }

    return () => {
      WebSocketHelper.unsubscribe(ws.current);
    };
  }, [workspaceId, documentInfo]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

export default CertificateActionsWebsocket;
