import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  () =>
    createStyles({
      menuRoot: {
        minWidth: 180,
      },
      listItemSecondaryAction: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
      },
    }),
  { index: 0 }
);
