import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { HeaderButton } from 'src/components';
import { Close as CloseIcon } from 'src/components/CustomIcon';
import { useStyles } from '../styles';
import { Search as SearchIcon } from '../../../../../components/CustomIcon';

type PermissionsDialogProps = {
  permissions: string[] | null;
  onClose: () => void;
};

const PermissionsDialog = ({ permissions, onClose }: PermissionsDialogProps): JSX.Element => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [inputValue, setInputValue] = useState<string>('');

  return (
    <Dialog open={Boolean(permissions && permissions?.length > 0)} onClose={onClose}>
      <DialogTitle disableTypography classes={{ root: classes.dialogTitleWrapper }}>
        <Typography variant="h3">
          {t<string>('userConfigurationPage.apiKeys.permissions')}
        </Typography>

        {onClose && (
          <HeaderButton
            color="primary"
            onClick={onClose}
            icon={<CloseIcon className={classes.closeIconColor} />}
            tooltip={t<string>('common.close')}
          />
        )}
      </DialogTitle>
      <DialogContent>
        <TextField
          className={classes.permissionInput}
          fullWidth
          autoFocus
          placeholder={t<string>('common.search')}
          value={inputValue}
          onChange={(event) => setInputValue(event.target.value)}
          InputProps={{
            endAdornment: (
              <Box display="flex" alignItems="center">
                <InputAdornment position="end">
                  <SearchIcon className={classes.iconColor} />
                </InputAdornment>
              </Box>
            ),
          }}
        />
        {permissions !== null &&
          permissions.map((permission) => {
            const matches = match(permission, inputValue, {
              insideWords: true,
              findAllOccurrences: true,
            });
            const parts = parse(permission, matches);
            if (!permission.toLowerCase().includes(inputValue.toLowerCase())) return null;

            return (
              <Chip
                className={classes.chip}
                key={`chip_${permission}`}
                label={
                  <Typography variant="body2" component="span">
                    {parts.map((part, index) => (
                      <span
                        key={index}
                        className={part.highlight ? classes.partHighlight : undefined}
                      >
                        {part.text}
                      </span>
                    ))}
                  </Typography>
                }
              />
            );
          })}
      </DialogContent>
    </Dialog>
  );
};

export default PermissionsDialog;
