export enum Permission {
  DOCUMENT_SERVICE_USER_GET_FILE_UPLOAD = 'DOCUMENT_SERVICE_USER_GET_FILE_UPLOAD',
  DOCUMENT_SERVICE_USER_POST_DOCUMENTS = 'DOCUMENT_SERVICE_USER_POST_DOCUMENTS',
  DOCUMENT_SERVICE_USER_SIGN_ORDER = 'DOCUMENT_SERVICE_USER_SIGN_ORDER',
}

export const PermissionList = [
  Permission.DOCUMENT_SERVICE_USER_GET_FILE_UPLOAD,
  Permission.DOCUMENT_SERVICE_USER_POST_DOCUMENTS,
  Permission.DOCUMENT_SERVICE_USER_SIGN_ORDER,
];

export interface WorkspaceUser {
  active: boolean;
  addedAt: number;
  contactEmail: string;
  email: string;
  identityId: string;
  language: string;
  name: string;
}

export interface WorkspaceDetailsInfo {
  active: boolean;
  creatorId: string;
  description: string;
  groupId: string;
  name: string;
}

export interface WorkspaceGroup {
  info: WorkspaceDetailsInfo;
  members: WorkspaceUser[];
}

export interface SingleEntity {
  active: boolean;
  email: string;
  entityId: string;
  entityName: string;
  group: boolean;
  workspaceId: string;
  workspaceName: string;
}

export interface StructureGroup {
  active: boolean;
  addedAt: number;
  description: string;
  groupId: string;
  name: string;
}
export interface StructureIdentity {
  active: boolean;
  addedAt: number;
  contactEmail: string;
  email: string;
  identityId: string;
  language: string;
  name: string;
}

export interface StructureWorkspace {
  description: string;
  name: string;
  workspaceId: string;
}

export interface WorkspaceInfo {
  workspaceId: string;
  companyName?: string;
  vatId?: string;
  country?: string;
  city?: string;
  address?: string;
  postcode?: string;
}

export type StructureItemType = {
  identity: StructureIdentity;
  groups: StructureGroup[];
  workspace: StructureWorkspace;
};
