enum authConstants {
  GET_USER_PROFILE_REQUEST = 'GET_USER_PROFILE_REQUEST',
  GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS',
  GET_USER_PROFILE_ERROR = 'GET_USER_PROFILE_ERROR',

  GET_PERMISSIONS_REQUEST = 'GET_PERMISSIONS_REQUEST',
  GET_PERMISSIONS_SUCCESS = 'GET_PERMISSIONS_SUCCESS',
  GET_PERMISSIONS_ERROR = 'GET_PERMISSIONS_ERROR',

  GET_API_KEYS_REQUEST = 'GET_API_KEYS_REQUEST',
  GET_API_KEYS_SUCCESS = 'GET_API_KEYS_SUCCESS',
  GET_API_KEYS_ERROR = 'GET_API_KEYS_ERROR',

  CREATE_API_KEY_REQUEST = 'CREATE_API_KEY_REQUEST',
  CREATE_API_KEY_SUCCESS = 'CREATE_API_KEY_SUCCESS',
  CREATE_API_KEY_ERROR = 'CREATE_API_KEY_ERROR',

  DELETE_API_KEY_REQUEST = 'DELETE_API_KEY_REQUEST',
  DELETE_API_KEY_SUCCESS = 'DELETE_API_KEY_SUCCESS',
  DELETE_API_KEY_ERROR = 'DELETE_API_KEY_ERROR',

  PATCH_USER_PROFILE_USERNAME_REQUEST = 'PATCH_USER_PROFILE_USERNAME_REQUEST',
  PATCH_USER_PROFILE_USERNAME_SUCCESS = 'PATCH_USER_PROFILE_USERNAME_SUCCESS',
  PATCH_USER_PROFILE_USERNAME_ERROR = 'PATCH_USER_PROFILE_USERNAME_ERROR',

  PATCH_USER_PROFILE_PASSWORD_REQUEST = 'PATCH_USER_PROFILE_PASSWORD_REQUEST',
  PATCH_USER_PROFILE_PASSWORD_SUCCESS = 'PATCH_USER_PROFILE_PASSWORD_SUCCESS',
  PATCH_USER_PROFILE_PASSWORD_ERROR = 'PATCH_USER_PROFILE_PASSWORD_ERROR',

  PATCH_USER_PROFILE_EMAIL_REQUEST = 'PATCH_USER_PROFILE_EMAIL_REQUEST',
  PATCH_USER_PROFILE_EMAIL_SUCCESS = 'PATCH_USER_PROFILE_EMAIL_SUCCESS',
  PATCH_USER_PROFILE_EMAIL_ERROR = 'PATCH_USER_PROFILE_EMAIL_ERROR',

  PATCH_USER_PROFILE_PHONE_REQUEST = 'PATCH_USER_PROFILE_PHONE_REQUEST',
  PATCH_USER_PROFILE_PHONE_SUCCESS = 'PATCH_USER_PROFILE_PHONE_SUCCESS',
  PATCH_USER_PROFILE_PHONE_ERROR = 'PATCH_USER_PROFILE_PHONE_ERROR',

  POST_USER_PROFILE_EMAIL_REQUEST = 'POST_USER_PROFILE_EMAIL_REQUEST',
  POST_USER_PROFILE_EMAIL_SUCCESS = 'POST_USER_PROFILE_EMAIL_SUCCESS',
  POST_USER_PROFILE_EMAIL_ERROR = 'POST_USER_PROFILE_EMAIL_ERROR',

  POST_USER_PROFILE_PHONE_REQUEST = 'POST_USER_PROFILE_PHONE_REQUEST',
  POST_USER_PROFILE_PHONE_SUCCESS = 'POST_USER_PROFILE_PHONE_SUCCESS',
  POST_USER_PROFILE_PHONE_ERROR = 'POST_USER_PROFILE_PHONE_ERROR',

  CHECK_TOKEN_STATUS_REQUEST = 'CHECK_TOKEN_STATUS_REQUEST',
  CHECK_TOKEN_STATUS_SUCCESS = 'CHECK_TOKEN_STATUS_SUCCESS',
  CHECK_TOKEN_STATUS_FAILURE = 'CHECK_TOKEN_STATUS_FAILURE',

  DELETE_TOKEN_USER_REQUEST = 'DELETE_TOKEN_USER_REQUEST',
  DELETE_TOKEN_USER_SUCCESS = 'DELETE_TOKEN_USER_SUCCESS',
  DELETE_TOKEN_USER_FAILURE = 'DELETE_TOKEN_USER_FAILURE',

  PATCH_USER_PROFILE_LANGUAGE_REQUEST = 'PATCH_USER_PROFILE_LANGUAGE_REQUEST',
  PATCH_USER_PROFILE_LANGUAGE_SUCCESS = 'PATCH_USER_PROFILE_LANGUAGE_SUCCESS',
  PATCH_USER_PROFILE_LANGUAGE_FAILURE = 'PATCH_USER_PROFILE_LANGUAGE_FAILURE',

  DELETE_USER_ACCOUNT_REQUEST = 'DELETE_USER_ACCOUNT_REQUEST',
  DELETE_USER_ACCOUNT_SUCCESS = 'DELETE_USER_ACCOUNT_SUCCESS',
  DELETE_USER_ACCOUNT_FAILURE = 'DELETE_USER_ACCOUNT_FAILURE',
}

export default authConstants;
