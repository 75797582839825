import { useCallback, useEffect, useState } from 'react';
import documentsService from 'src/redux/services/documents.service';

const useFolderStructure = (workspaceId, folderId, refetch) => {
  const [requestState, setRequestState] = useState({
    isLoading: false,
    error: null,
    folderStructure: null,
    documents: null,
  });

  const fetchStructure = useCallback(async () => {
    try {
      setRequestState((state) => ({
        ...state,
        error: null,
        isLoading: true,
      }));

      const folderStructureResponse = await documentsService.fetchFolderTree(
        workspaceId,
        folderId,
        2
      );

      const rootFolderId =
        folderId === 'root'
          ? folderStructureResponse.data.collection.find((f) => f.isRoot)?.id
          : folderId;
      const documentsInFolder = await documentsService.getDocumentsInFolder(
        workspaceId,
        rootFolderId
      );

      const dataWithLeafs = folderStructureResponse.data.collection.map((folder) => {
        const hasChildrenInCurrentSet = !!folderStructureResponse.data.collection.find(
          (f) => f.parent === folder.id
        );

        return {
          ...folder,
          isLeaf: !hasChildrenInCurrentSet,
        };
      });

      setRequestState((state) => ({
        ...state,
        isLoading: false,
        folderStructure: dataWithLeafs,
        documents: documentsInFolder.data.collection,
      }));
    } catch (err) {
      setRequestState((state) => ({
        ...state,
        isLoading: false,
        error: err.message,
      }));
    }
  }, [folderId, workspaceId]);

  useEffect(() => {
    fetchStructure().then();
  }, [workspaceId, folderId, fetchStructure]);

  useEffect(() => {
    if (refetch) {
      fetchStructure().then();
    }
  }, [refetch, fetchStructure]);

  const currentFolder = requestState.folderStructure?.find((folder) => {
    if (folderId === 'root') {
      return folder.isRoot;
    }

    return folderId === folder.id;
  });

  return [requestState, currentFolder];
};

export { useFolderStructure };
