import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState } from 'src/redux/reducers';
import { Button, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useStyles } from './styles';
import authActions from '../../redux/actions/auth.actions';

const DiffMailInfo = (): JSX.Element => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { documentInvitationData } = useSelector(
    (state: RootState) => state.documentJoinStore,
    undefined
  );
  const handleLogout = () => {
    dispatch(
      authActions.deleteToken(() => {
        window.location.href = `${process.env.REACT_APP_AUTH_FRONT_URL}${language}?email=${documentInvitationData?.invitedEmail}`;
      })
    );
  };
  return (
    <div className={clsx(classes.differentMailCol, classes.differentMailContent)}>
      <div className={classes.differentMailContent}>
        <Typography variant="h2" className={classes.differentMailTitle}>
          {t<string>('differentMailInfo.title')}
        </Typography>
        <Typography variant="body1" color="textPrimary">
          {t<string>('differentMailInfo.invitedMail')}
        </Typography>
        <Typography variant="body1" color="primary">
          <strong>{documentInvitationData?.invitedEmail}</strong>
        </Typography>
        <Button
          className={classes.differentMailButton}
          size="large"
          type="submit"
          onClick={handleLogout}
        >
          {t<string>('differentMailInfo.button')}
        </Button>
      </div>
    </div>
  );
};

export default DiffMailInfo;
