import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const UserComUrlChange = (): null => {
  const location = useLocation();

  useEffect(() => {
    if (window.UE) {
      window.UE.pageHit();
    }
  }, [location, location.pathname]);

  return null;
};

export default UserComUrlChange;
