export default {
  paperAnchorDockedLeft: {
    borderRight: 'none',
  },
  paperAnchorDockedTop: {
    borderBottom: 'none',
  },
  paperAnchorDockedRight: {
    borderLeft: 'none',
  },
  paperAnchorDockedBottom: {
    borderTop: 'none',
  },
};
