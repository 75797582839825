enum documentJoinConstants {
  GET_DOCUMENT_INVITATION_REQUEST = 'GET_DOCUMENT_INVITATION_REQUEST',
  GET_DOCUMENT_INVITATION_SUCCESS = 'GET_DOCUMENT_INVITATION_SUCCESS',
  GET_DOCUMENT_INVITATION_FAILURE = 'GET_DOCUMENT_INVITATION_FAILURE',

  ACCEPT_DOCUMENT_INVITATION_REQUEST = 'ACCEPT_DOCUMENT_INVITATION_REQUEST',
  ACCEPT_DOCUMENT_INVITATION_SUCCESS = 'ACCEPT_DOCUMENT_INVITATION_SUCCESS',
  ACCEPT_DOCUMENT_INVITATION_FAILURE = 'ACCEPT_DOCUMENT_INVITATION_FAILURE',

  CLEAR_DOCUMENT_INVITATION_STORE = 'CLEAR_DOCUMENT_INVITATION_STORE',
}

export default documentJoinConstants;
