import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Fab,
  Grid,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/redux/reducers';
import { DateTimeFormats, FormatDate } from 'src/helpers/dateTimeFormats';
import { ApiKeys } from 'src/models/auth.model';
import snackbarActions from 'src/redux/actions/snackbar.actions';
import { RequestStatus } from 'src/helpers/reduxReuquest.util';
import { SNACKBAR_VARIANT } from 'src/models/common.model';
import { Delete as DeleteIcon, Key as KeyIcon } from 'src/components/CustomIcon';
import authActions from 'src/redux/actions/auth.actions';
import usePrevious from 'src/helpers/hooks/usePrevious';
import { ConfirmDialog } from 'src/components';
import { useStyles } from '../styles';
import PermissionsDialog from './PermissionsDialog';

const ApiKeysTableHeader = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Grid container spacing={2} className={classes.tableHead}>
      <Grid xs={2}>
        <Typography variant="body2"> {t<string>('userConfigurationPage.apiKeys.name')}</Typography>
      </Grid>
      <Grid xs={5} lg={4}>
        {t<string>('userConfigurationPage.apiKeys.publicKey')}
      </Grid>
      <Grid xs={3} lg={2}>
        {t<string>('userConfigurationPage.apiKeys.creationDate')}
      </Grid>
      <Grid xs={2} lg={4} />
    </Grid>
  );
};

interface ApiKeysTableRowProps {
  name: string;
  publicKey: string;
  created?: number;
  onShowPermissionsClick?: () => void;
  onRemoveClick?: () => void;
}

const ApiKeysTableRow = ({
  name,
  publicKey,
  created,
  onShowPermissionsClick,
  onRemoveClick,
}: ApiKeysTableRowProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const mediumScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const copyPublicKey = () => {
    dispatch(
      snackbarActions.enqueueSnackbar(SNACKBAR_VARIANT.INFO, t('workspacePage.settings.copied'))
    );
    navigator.clipboard.writeText(publicKey).then();
  };

  return (
    <Grid container spacing={2} className={classes.tableRow}>
      <Grid xs={2}>{name}</Grid>
      <Grid xs={5} lg={4} onClick={copyPublicKey} className={classes.clickable}>
        {publicKey}
      </Grid>
      <Grid xs={3} lg={2} className={classes.date}>
        {created
          ? FormatDate({
              date: new Date(created),
              timeFormat: DateTimeFormats.timeShort,
              dateFormat: DateTimeFormats.dateShort,
            })
          : '-'}
      </Grid>
      <Grid xs={2} lg={4} justify="flex-end">
        {onShowPermissionsClick &&
          (mediumScreen ? (
            <Tooltip title={t<string>('userConfigurationPage.apiKeys.showPermissions')}>
              <Fab color="primary" size="small" onClick={onShowPermissionsClick}>
                <KeyIcon />
              </Fab>
            </Tooltip>
          ) : (
            <Button
              size="small"
              variant="text"
              color="primary"
              endIcon={<KeyIcon />}
              onClick={onShowPermissionsClick}
            >
              {t<string>('userConfigurationPage.apiKeys.showPermissions')}
            </Button>
          ))}
        {onRemoveClick &&
          (mediumScreen ? (
            <Tooltip title={t<string>('common.remove')}>
              <Fab
                style={{ marginLeft: 16, marginRight: 8 }}
                color="primary"
                size="small"
                onClick={onRemoveClick}
              >
                <DeleteIcon />
              </Fab>
            </Tooltip>
          ) : (
            <Button
              size="small"
              variant="text"
              color="primary"
              endIcon={<DeleteIcon />}
              onClick={onRemoveClick}
            >
              {t<string>('common.remove')}
            </Button>
          ))}
      </Grid>
    </Grid>
  );
};

const ApiKeysTable = (): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { apiKeys, apiKeysRequestStatus, deleteApiKeyStatus } = useSelector(
    (state: RootState) => state.authStore,
    undefined
  );
  const [permissionToPreview, setPermissionToPreview] = useState<string[] | null>(null);
  const [apiKeyToRemove, setApiKeyToRemove] = useState<ApiKeys | null>(null);
  const prevState = usePrevious({
    deleteApiKeyStatus,
  });

  const onShowPermissionsClick = (permissions: string[]) => setPermissionToPreview(permissions);

  const onRemoveClick = (apiKey: ApiKeys) => setApiKeyToRemove(apiKey);

  const removeApiKey = () => {
    if (apiKeyToRemove) dispatch(authActions.deleteApiKey(apiKeyToRemove?.publicKey));
  };

  useEffect(() => {
    if (
      prevState &&
      prevState.deleteApiKeyStatus === RequestStatus.PENDING &&
      deleteApiKeyStatus === RequestStatus.IDLE
    ) {
      setApiKeyToRemove(null);
      dispatch(authActions.getApiKeys());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteApiKeyStatus]);

  return (
    <>
      <Card variant="outlined" className={classes.keysTable}>
        <CardContent className={classes.tableContent}>
          <ApiKeysTableHeader />
          {apiKeysRequestStatus === RequestStatus.PENDING && <CircularProgress />}
          {apiKeys.length === 0 && <ApiKeysTableRow publicKey="-" name="-" />}
          {apiKeys.map((apiKey: ApiKeys) => (
            <ApiKeysTableRow
              key={`key_${apiKey.publicKey}`}
              publicKey={apiKey.publicKey}
              created={apiKey.created}
              name={apiKey.name}
              onShowPermissionsClick={() => onShowPermissionsClick(apiKey.permissions)}
              onRemoveClick={() => onRemoveClick(apiKey)}
            />
          ))}
        </CardContent>
      </Card>
      {permissionToPreview !== null && (
        <PermissionsDialog
          permissions={permissionToPreview}
          onClose={() => setPermissionToPreview(null)}
        />
      )}
      <ConfirmDialog
        open={apiKeyToRemove !== null}
        variant="error"
        title={t<string>('userConfigurationPage.apiKeys.deleteKey')}
        subtitle={
          <Trans
            values={{ value: apiKeyToRemove?.name }}
            i18nKey="userConfigurationPage.apiKeys.deleteKeySubtitle"
            components={{ strong: <strong />, br: <br /> }}
          />
        }
        applyButtonText={t<string>('userConfigurationPage.apiKeys.deleteKey')}
        cancelButtonText={t('common.cancel')}
        actionAccept={removeApiKey}
        actionCancel={() => setApiKeyToRemove(null)}
      />
    </>
  );
};

export default ApiKeysTable;
