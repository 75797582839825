import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ breakpoints, palette, transitions }) =>
    createStyles({
      root: { textAlign: 'center' },
      content: {
        height: '100%',
        [breakpoints.down('sm')]: {
          flex: 1,
        },
      },
      contentWrapper: {
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: -40,
      },
      description: {
        whiteSpace: 'break-spaces',
        color: palette.secondary.dark,
      },
      titleWrapper: {
        position: 'relative',
        marginTop: -60,
        width: '100%',
      },
      title: {
        opacity: 0,
        display: 'none',
        textAlign: 'center',
        transition: transitions.create(['opacity'], {
          duration: transitions.duration.standard,
        }),
      },
      titleActive: {
        opacity: 1,
        display: 'block',
      },
    }),
  { index: 0 }
);
