import React from 'react';
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon';

export default createSvgIcon(
  <>
    <g clipPath="url(#clip0_6814_7379)">
      <path
        d="M17.498 5.48965V5.5H14.0332C12.9091 5.5002 11.9978 4.58728 11.998 3.46112L11.998 0H12.0082L17.498 5.48965Z"
        fill="#64D095"
      />
      <path
        d="M17.5 5.48496V18C17.5002 19.1047 16.613 20.0004 15.5185 20H4.4815C3.38703 20.0004 2.49961 19.1047 2.5 18V2C2.49961 0.895313 3.38703 -0.000390497 4.4815 1.27718e-07H12.041L12.05 0L12.1401 3.40508C12.1397 4.50977 13.0271 5.40527 14.1216 5.40508H17.495V5.48496H17.5Z"
        fill="#16B15C"
      />
      <rect x="5" y="9.1665" width="6.66667" height="1.25" rx="0.625" fill="white" />
      <path
        d="M5 13.125C5 12.7798 5.27982 12.5 5.625 12.5H14.375C14.7202 12.5 15 12.7798 15 13.125C15 13.4702 14.7202 13.75 14.375 13.75H5.625C5.27982 13.75 5 13.4702 5 13.125Z"
        fill="white"
      />
      <path
        d="M5 16.4585C5 16.1133 5.27982 15.8335 5.625 15.8335H14.375C14.7202 15.8335 15 16.1133 15 16.4585C15 16.8037 14.7202 17.0835 14.375 17.0835H5.625C5.27982 17.0835 5 16.8037 5 16.4585Z"
        fill="white"
      />
      <path
        d="M11.5984 17.5006C10.861 15.8119 11.3204 13.8395 12.7277 12.6504C14.1351 11.4613 16.1563 11.3378 17.6982 12.3467C19.2399 13.3557 19.9357 15.2575 19.4094 17.0233C18.8832 18.789 17.2596 19.9995 15.4172 19.9998C13.7592 20.005 12.2574 19.0222 11.5984 17.5006Z"
        fill="white"
      />
      <path
        d="M14.9578 17.4498L14.9573 17.4498C14.8623 17.4501 14.7694 17.4145 14.694 17.348L13.5448 16.3313C13.3604 16.168 13.3295 15.8669 13.4786 15.661C13.6256 15.458 13.8901 15.4272 14.0715 15.5878L14.0715 15.5878L14.8898 16.3122L14.9268 16.345L14.96 16.3083L16.7276 14.3537C16.893 14.1708 17.1592 14.1708 17.3246 14.3537C17.4927 14.5395 17.4927 14.843 17.3246 15.0288L17.3246 15.0288L15.2562 17.316C15.2562 17.316 15.2561 17.3161 15.2561 17.3161C15.1757 17.4033 15.0684 17.4507 14.9578 17.4498Z"
        fill="#16B15C"
        stroke="white"
        strokeWidth="0.1"
      />
      <path
        d="M8.23941 6.37296C8.1903 6.37306 8.14267 6.35641 8.10436 6.32585L7.58091 5.90709C7.48784 5.83255 7.47287 5.69656 7.54741 5.60348C7.62196 5.51041 7.75794 5.49545 7.85102 5.56999L8.22371 5.86836L9.02878 5.06329C9.11317 4.9789 9.2501 4.9789 9.33447 5.06329C9.41885 5.14767 9.41886 5.28461 9.33447 5.36898L8.39227 6.31119C8.35154 6.35118 8.29657 6.37338 8.23941 6.37296Z"
        fill="#16B15C"
      />
    </g>
    <defs>
      <clipPath id="clip0_6814_7379">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </>,
  'CertificateFileIcon'
);
