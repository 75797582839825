import React from 'react';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/redux/reducers';
import { AdvancedSearchFields, DocumentType, ExtraDocumentType } from 'src/models/documents.model';
import {
  FileEdit as EsignIcon,
  FilesWithShield as DriveIcon,
  CertificateIcon,
  Folder as FolderIcon,
} from 'src/components/CustomIcon';
import DocumentTypeDropdown from 'src/components/DocumentTypeDropdown/DocumentTypeDropdown';
import documentsActions from 'src/redux/actions/documents.actions';
import { useStyles } from './styles';
import Field from '../../subcomponents/AdvancedSearchModalField';

const DocumentTypeField = (): JSX.Element => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const draftDocumentTypes = useSelector(
    (state: RootState) => state.documentsStore.draftAdvancedSearch[AdvancedSearchFields.TYPE]
  );
  const isEmpty = draftDocumentTypes.length === 0;
  const onSubmit = (editingDocumentTypes: Array<DocumentType | ExtraDocumentType>) => {
    dispatch(
      documentsActions.updateDraftAdvancedSearch(AdvancedSearchFields.TYPE, editingDocumentTypes)
    );
  };
  return (
    <Field title={t<string>('advancedSearch.fields.documentType.label')} withInputWrapper>
      <DocumentTypeDropdown onSubmit={onSubmit} initialDocumentTypes={draftDocumentTypes}>
        <>
          {isEmpty && (
            <Typography>{t<string>('advancedSearch.fields.documentType.placeholder')}</Typography>
          )}
          {draftDocumentTypes.map((docType, index) => {
            if (docType === ExtraDocumentType.FOLDER)
              return (
                <Typography className={classes.text}>
                  {index > 0 && ', '}
                  <FolderIcon
                    style={{ marginLeft: index > 0 ? 4 : 0 }}
                    className={classes.folderIcon}
                  />
                  {t<string>('common.folders')}
                </Typography>
              );
            if (docType === DocumentType.ESIGN)
              return (
                <Typography className={classes.text}>
                  {index > 0 && ', '}
                  <EsignIcon
                    style={{ marginLeft: index > 0 ? 4 : 0 }}
                    className={classes.esignIcon}
                  />
                  {t<string>('common.esign')}
                </Typography>
              );
            if (docType === DocumentType.DRIVE)
              return (
                <Typography className={classes.text}>
                  {index > 0 && ', '}
                  <DriveIcon
                    style={{ marginLeft: index > 0 ? 4 : 0 }}
                    className={classes.driveIcon}
                  />
                  {t<string>('common.drive')}
                </Typography>
              );
            if (docType === DocumentType.DSL)
              return (
                <Typography className={classes.text}>
                  {index > 0 && ', '}
                  <CertificateIcon
                    style={{ marginLeft: index > 0 ? 4 : 0 }}
                    className={classes.certificateIcon}
                  />
                  {t<string>('common.certificates')}
                </Typography>
              );
            return null;
          })}
        </>
      </DocumentTypeDropdown>
    </Field>
  );
};

export default DocumentTypeField;
