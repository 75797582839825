import React, { useState } from 'react';
import { UserManagerPerson } from 'src/models/documentUsersManagement.model';

export type UsersManagerContextType = {
  isDraftPublicAccess: boolean;
  isDraftTransferable: boolean;
  isDraftIsOrderSignificant: boolean;
  draftHolders: UserManagerPerson[];
  draftSigners: UserManagerPerson[];
  draftOtherRoles: UserManagerPerson[];
  updateDraftPublicAccess: (isPublic: boolean) => void;
  updateDraftTransferable: (isTransferable: boolean) => void;
  updateDraftIsOrderSignificant: (isOrder: boolean) => void;
  updateDraftHolders: (items: UserManagerPerson[]) => void;
  updateDraftSigners: (items: UserManagerPerson[]) => void;
  updateDraftOtherRoles: (items: UserManagerPerson[]) => void;
};

export const UsersManagerContext = React.createContext<UsersManagerContextType | null>(null);

type Props = {
  children: JSX.Element;
};

const UsersManagerContextProvider = ({ children }: Props): JSX.Element => {
  const [draftHolders, setDraftHolders] = useState<UserManagerPerson[]>([]);
  const [draftSigners, setDraftSigners] = useState<UserManagerPerson[]>([]);
  const [draftOtherRoles, setDraftOtherRoles] = useState<UserManagerPerson[]>([]);
  const [isDraftPublicAccess, setIsPublicAccess] = useState<boolean>(false);
  const [isDraftTransferable, setIsDraftTransferable] = useState<boolean>(false);
  const [isDraftIsOrderSignificant, setIsDraftIsOrderSignificant] = useState<boolean>(false);

  const updateDraftHolders = (holders: UserManagerPerson[]) => setDraftHolders(holders);
  const updateDraftSigners = (singers: UserManagerPerson[]) => setDraftSigners(singers);
  const updateDraftOtherRoles = (otherRoles: UserManagerPerson[]) => setDraftOtherRoles(otherRoles);
  const updateDraftPublicAccess = (isPublicAccess: boolean) => setIsPublicAccess(isPublicAccess);
  const updateDraftTransferable = (transferable: boolean) => setIsDraftTransferable(transferable);
  const updateDraftIsOrderSignificant = (transferable: boolean) =>
    setIsDraftIsOrderSignificant(transferable);

  return (
    <UsersManagerContext.Provider
      value={{
        draftHolders,
        draftSigners,
        draftOtherRoles,
        isDraftPublicAccess,
        isDraftTransferable,
        isDraftIsOrderSignificant,
        updateDraftHolders,
        updateDraftSigners,
        updateDraftOtherRoles,
        updateDraftPublicAccess,
        updateDraftTransferable,
        updateDraftIsOrderSignificant,
      }}
    >
      {children}
    </UsersManagerContext.Provider>
  );
};

export default UsersManagerContextProvider;
