import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ConfirmDialog } from 'src/components';

const SigningBannerAfterTextModal = ({ open = false, onClose, description = '' }) => {
  const { t } = useTranslation();

  return (
    <ConfirmDialog
      open={open}
      variant="success"
      subtitle={description}
      cancelButtonText={t('common.close')}
      actionCancel={onClose}
    />
  );
};

SigningBannerAfterTextModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  description: PropTypes.string,
};

export default SigningBannerAfterTextModal;
