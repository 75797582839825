import store from 'src/redux/store';
import contactsActions from '../redux/actions/contacts.actions';

const ContactHelper = {
  getEntityData,
  checkIfIsInProgress,
  addContactsToQueue,
  checkIfUserBelongsToDocument,
};

function getEntityData(entityId) {
  const { identityStore, contactsStore } = store.getState();
  const { workspaceUsers, workspaceGroups } = identityStore;
  const matchedUserIdentity = workspaceUsers.find((user) => user.identityId === entityId);
  if (matchedUserIdentity) {
    return { ...matchedUserIdentity, isFromOrganisation: true, isGroup: false };
  }
  const matchedGroupIdentity = workspaceGroups.find((group) => group.info.groupId === entityId);
  if (matchedGroupIdentity) {
    return { ...matchedGroupIdentity.info, isFromOrganisation: true, isGroup: true };
  }
  const { contacts, groups, contactsAvailable } = contactsStore;
  if (contactsAvailable.indexOf(entityId) !== -1) {
    const contactData = contacts[entityId];
    if (contactData) {
      return {
        ...contactData,
        name: contactData.identityName !== undefined ? contactData.identityName : contactData.email,
        email: contactData.email,
        isFromOrganisation: false,
        isGroup: false,
      };
    }
    const groupData = groups[entityId];
    if (groupData) {
      return {
        ...groupData,
        name: groupData.groupName,
        isFromOrganisation: false,
        isGroup: true,
      };
    }
  }
  return false;
}

function checkIfIsInProgress(id) {
  const { contactsStore } = store.getState();
  const { contactsAvailable, contactsQueue, fetchingContacts } = contactsStore;
  return (
    contactsAvailable.indexOf(id) !== -1 ||
    contactsQueue.indexOf(id) !== -1 ||
    fetchingContacts.indexOf(id) !== -1
  );
}

function addContactsToQueue(ids) {
  store.dispatch(contactsActions.addMissingToQueue(ids));
}

function getAllUserEntities() {
  const { identityStore } = store.getState();
  const { currentIdentity } = identityStore;
  const userEntities = [];
  if (currentIdentity) {
    userEntities.push(currentIdentity.identity.identityId);
    currentIdentity.groups.forEach((group) => {
      if (group.active) {
        userEntities.push(group.groupId);
      }
    });
  }
  return userEntities;
}

function checkIfUserBelongsToDocument(document) {
  const allUserEntities = getAllUserEntities();
  let belongs = false;
  if (document) {
    const roleKeys = Object.keys(document.roles);
    let allRolesUsers = [];
    roleKeys.forEach((role) => {
      allRolesUsers = [...allRolesUsers, ...document.roles[role]];
    });
    allUserEntities.forEach((userEntity) => {
      if (allRolesUsers.indexOf(userEntity) !== -1) {
        belongs = true;
      }
    });
  }
  return belongs;
}

export default ContactHelper;
