import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ palette }) =>
    createStyles({
      adornmentContainer: {
        position: 'absolute',
        width: 24,
        height: 24,
        top: 12,
        right: 12,
      },
      validIcon: {
        color: palette.success.main,
      },
      invalidIcon: {
        color: palette.error.main,
      },
    }),
  { index: 0 }
);
