import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ transitions }) =>
    createStyles({
      wrapper: {
        position: 'relative',
        display: 'flex',
        marginLeft: -24,
        marginRight: -24,
        paddingLeft: 24,
        paddingRight: 24,
      },
      content: {
        display: 'flex',
        flexDirection: 'column',
      },
      scrollContent: {
        overflow: 'hidden',
      },
      leftArrow: {
        position: 'absolute',
        left: 12,
        zIndex: 1,
        transition: transitions.create(['color', 'transform', 'opacity'], {
          duration: transitions.duration.short,
        }),
      },
      rightArrow: {
        position: 'absolute',
        right: 12,
        zIndex: 1,
        transition: transitions.create(['color', 'transform', 'opacity'], {
          duration: transitions.duration.short,
        }),
      },
      disabledArrow: {
        opacity: 0,
        zIndex: -1,
      },
      arrow: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        width: 24,
        zIndex: 1,
      },
    }),
  { index: 0 }
);
