export default {
  adornedStart: {
    '& > svg': {
      fontSize: 18,
      marginRight: 6,
    },
  },
  adornedEnd: {
    '& > svg': {
      fontSize: 18,
      marginLeft: 6,
    },
  },
};
