import { hasPermission } from 'src/components/PermissionResolver/permissionUtils';
import {
  Permission,
  StructureIdentity,
  StructureItemType,
  WorkspaceDetailsInfo,
} from 'src/models/identity.model';
import { RootState } from '../reducers';

const hasSigningOrderPermission = (state: RootState): boolean =>
  hasPermission({
    workspacePermissions: state.identityStore.workspacePermissions,
    permission: Permission.DOCUMENT_SERVICE_USER_SIGN_ORDER,
  });

const selectWorkspaceDetails = (state: RootState): WorkspaceDetailsInfo | null =>
  state.identityStore.workspaceDetails;
const selectWorkspaceId = (state: RootState): string | undefined =>
  state.identityStore.currentIdentity?.workspace?.workspaceId;
const selectCurrentIdentityId = (state: RootState): string | undefined =>
  state.identityStore.currentIdentity?.identity?.identityId;
const selectCurrentIdentity = (state: RootState): StructureIdentity | undefined =>
  state.identityStore.currentIdentity?.identity;

const selectCurrentIdentityOwnersGroupId = (state: RootState): string | undefined =>
  state.identityStore?.workspaceGroups.find((group) => group?.info?.name === 'Owners')?.info
    ?.groupId;
const selectAvailableWorkspaces = (state: RootState): StructureItemType[] =>
  state.identityStore?.structure?.filter((structure) => structure.identity.active) || [];
export const IdentitySelector = {
  hasSigningOrderPermission,
  selectWorkspaceDetails,
  selectWorkspaceId,
  selectCurrentIdentityOwnersGroupId,
  selectCurrentIdentityId,
  selectCurrentIdentity,
  selectAvailableWorkspaces,
};
