import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useForm from 'src/helpers/hooks/useForm';
import validate from 'src/helpers/formValidationRules';
import { Button, TextField, Typography } from '@material-ui/core';
import { SmallContentWithScrollbar } from 'src/components';

const EmailEdit = (props) => {
  const { email, updateBasicData } = props;
  const { t } = useTranslation();

  const handleUpdate = () => {
    updateBasicData('email', values.email);
  };

  const { values, errors, handleChange, handleSubmit } = useForm(handleUpdate, validate, {
    email,
  });

  return (
    <SmallContentWithScrollbar
      startContent={<Typography>{t('userConfigurationPage.basic.emailPage.title')}</Typography>}
      endContent={
        <Button size="large" type="submit" fullWidth>
          {t('userConfigurationPage.basic.emailPage.button')}
        </Button>
      }
      hasForm
      formProps={{
        noValidate: true,
        autoComplete: 'off',
        onSubmit: handleSubmit,
      }}
    >
      <TextField
        name="email"
        placeholder={t('userConfigurationPage.basic.emailPage.placeholder')}
        fullWidth
        autoFocus
        value={values.email || ''}
        onChange={handleChange}
        error={errors.email?.length > 0}
        helperText={errors.email}
      />
    </SmallContentWithScrollbar>
  );
};

EmailEdit.propTypes = {
  email: PropTypes.string,
  updateBasicData: PropTypes.func,
};

export default EmailEdit;
