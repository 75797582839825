import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  CircularProgress,
  Fab,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import TimeAgo from 'timeago-react';
import { useHistory } from 'react-router-dom';
import {
  CertificateIcon,
  Check as CheckIcon,
  File as FileIcon,
  User as UserIcon,
  Workspaces as WorkspaceIcon,
} from 'src/components/CustomIcon';
import { ConfirmDialog } from 'src/components/index';
import { DateTimeFormats, FormatDate } from 'src/helpers/dateTimeFormats';
import { SystemMessage, SystemMessageType } from 'src/models/communications.model';
import communicationsActions from 'src/redux/actions/communications.actions';
import { IdentitySelector } from 'src/redux/selectors/identity.selector';
import { useStyles } from './styles';
import identityActions from '../../../redux/actions/identity.actions';

interface HeaderNotificationsItemProps {
  notification: SystemMessage;
  live: boolean;
  isPending: boolean;
  timeRangeAgo?: number;
  goToSystemMessageSubjectCallback?: () => void;
}

const HeaderNotificationsItem = ({
  live,
  notification,
  isPending,
  timeRangeAgo = 86400000,
  goToSystemMessageSubjectCallback,
}: HeaderNotificationsItemProps): JSX.Element => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { language } = i18n;
  const classes = useStyles();
  const { content, createdAt, id, read } = notification;
  const translationKey = 'TRANSLATE_';
  let messageContent = content;
  const type = Date.now() - createdAt <= timeRangeAgo ? 'ago' : 'date';
  const date = new Date(createdAt);
  const workspaceId = useSelector(IdentitySelector.selectWorkspaceId, undefined);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const structure = useSelector((state: any) => state.identityStore?.structure, undefined);

  const [isSetAsRead, setIsSetAsRead] = useState<boolean>(false);
  const [systemMessageSubjectToConfirm, setSystemMessageSubjectToConfirm] = useState<
    SystemMessage | undefined
  >(undefined);

  const systemMessageSubjectToConfirmTitle = systemMessageSubjectToConfirm
    ? structure?.find(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (item: any) => item?.workspace?.workspaceId === systemMessageSubjectToConfirm?.workspaceId
      ).workspace.name
    : '-';

  if (content.includes(translationKey)) {
    const splitText = content.split(' ');
    messageContent = '';

    splitText.forEach((item) => {
      if (item.includes(translationKey)) {
        messageContent += `${t(`notifications.messages.${item.replace('.', '')}`)}${
          item.includes('.') ? '.' : ' '
        }`;
      } else {
        messageContent += `${item} `;
      }
    });
  }

  // Mark single notification as read
  const markAsRead = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setIsSetAsRead(true);
    setTimeout(() => {
      dispatch(communicationsActions.setNotificationSystemMessageAsRead(id));
    }, 600);
  };

  // Change workspace and on callback redirect to document
  const onSystemMessageWorkspaceChange = () => {
    if (systemMessageSubjectToConfirm) {
      dispatch(
        identityActions.switchWorkspace(systemMessageSubjectToConfirm.workspaceId, () => {
          if (systemMessageSubjectToConfirm.type === SystemMessageType.Certificate) {
            history.push(`/certificate/${systemMessageSubjectToConfirm.documentId}`);
          } else if (systemMessageSubjectToConfirm.type === SystemMessageType.Document) {
            history.push(`/document/${systemMessageSubjectToConfirm.documentId}`);
          } else if (systemMessageSubjectToConfirm.type === SystemMessageType.Workspace) {
            history.push(`/workspace-settings/basic/settings`);
          }

          if (goToSystemMessageSubjectCallback) goToSystemMessageSubjectCallback();

          return undefined;
        })
      );
    }
  };

  // redirect to subject of system message (settings, certificate, document)
  const goToSystemMessageSubject = () => {
    // set notification for dialog workspace change
    if (notification.workspaceId !== '' && notification.workspaceId !== workspaceId) {
      setSystemMessageSubjectToConfirm(notification);
      return;
    }
    switch (notification.type) {
      case SystemMessageType.User:
        history.push('/user-settings');
        break;
      case SystemMessageType.Certificate:
        history.push(`/certificate/${notification.documentId}`);
        break;
      case SystemMessageType.Document:
        history.push(`/document/${notification.documentId}`);
        break;
      case SystemMessageType.Workspace:
        history.push(`/workspace-settings/basic/settings`);
        break;
      default:
        break;
    }

    // let animation finish
    if (goToSystemMessageSubjectCallback) goToSystemMessageSubjectCallback();
  };

  return (
    <>
      <div
        aria-hidden="true"
        className={clsx(
          classes.root,
          read ? classes.read : classes.notRead,
          isSetAsRead && classes.pending
        )}
        onClick={goToSystemMessageSubject}
      >
        <ListItemIcon>
          <div className={classes.avatar}>
            {notification.type === SystemMessageType.Certificate && <CertificateIcon />}
            {notification.type === SystemMessageType.Document && <FileIcon />}
            {notification.type === SystemMessageType.User && <UserIcon />}
            {notification.type === SystemMessageType.Workspace && <WorkspaceIcon />}
          </div>
        </ListItemIcon>
        <ListItemText
          classes={{ multiline: classes.textMultiline }}
          primary={
            <Typography variant="body2" color="textPrimary" className={classes.notificationContent}>
              {messageContent}
            </Typography>
          }
          secondary={
            <Typography
              component="span"
              variant="subtitle2"
              color="textSecondary"
              className={classes.date}
            >
              {type === 'ago' && (
                <TimeAgo datetime={createdAt} locale={language.toLowerCase()} live={live} />
              )}
              {type === 'date' && (
                <FormatDate
                  date={date}
                  dateFormat={DateTimeFormats.dateLong}
                  timeFormat={DateTimeFormats.timeLong}
                />
              )}
            </Typography>
          }
        />
        {!read && (
          <Tooltip title={t<string>('notifications.setAsRead.setOne')}>
            <Fab className={classes.checkIconWrapper} onClick={markAsRead}>
              {isPending ? <CircularProgress thickness={1.5} /> : <CheckIcon color="primary" />}
            </Fab>
          </Tooltip>
        )}
      </div>

      {/* DIALOG to confirm notification which refers to other workspace */}
      {systemMessageSubjectToConfirm !== undefined && (
        <ConfirmDialog
          open
          title={t<string>('notifications.workspaceModal.title')}
          subtitle={
            <Trans
              values={{
                name:
                  notification.type === SystemMessageType.Certificate
                    ? t('documentType.certificate')
                    : t('documentType.document'),
                workspace: systemMessageSubjectToConfirmTitle,
              }}
              i18nKey="notifications.workspaceModal.subtitle"
              components={{ strong: <strong /> }}
            />
          }
          applyButtonText={t<string>('notifications.workspaceModal.title')}
          cancelButtonText={t<string>('common.close')}
          actionAccept={onSystemMessageWorkspaceChange}
          actionCancel={() => setSystemMessageSubjectToConfirm(undefined)}
        />
      )}
    </>
  );
};

export default HeaderNotificationsItem;
