import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { ExtendedGrid } from 'src/components';
import { CircularProgress } from '@material-ui/core';
import authActions from 'src/redux/actions/auth.actions';
import { ContentHeading } from 'src/pages/WorkspaceConfiguration/subcomponents';
import { RequestStatus } from 'src/helpers/reduxReuquest.util';
import { DateTimeFormats, FormatDate } from 'src/helpers/dateTimeFormats';
import { Item } from './subcomponents';

const Identity = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const structureStatus = useSelector((state) => state.identityStore.structureStatus, undefined);
  const structure = useSelector((state) => state.identityStore.structure, undefined);
  const currentIdentityId = useSelector(
    (state) => state.identityStore.currentIdentity?.identity.identityId
  );

  /**
   * Temporary fetch mail from user profile until identity
   * service will be notified about user profile mail change
   */
  const userProfile = useSelector((state) => state.authStore.userProfile);

  useEffect(() => {
    if (!userProfile) {
      dispatch(authActions.getUserProfile());
    }
  }, [dispatch, userProfile]);

  const parseIdentityStructure = () =>
    structure.map((identityStructure) => ({
      data: {
        id: identityStructure.identity.identityId,
        name: identityStructure.workspace.name,
      },
      date: (
        <FormatDate
          date={identityStructure.identity.addedAt}
          dateFormat={DateTimeFormats.dateLong}
          timeFormat={DateTimeFormats.timeShort}
        />
      ),
      emails: [
        identityStructure.identity.contactEmail,
        ...(userProfile.email !== identityStructure.identity.contactEmail
          ? [userProfile.email]
          : []),
      ],
      groups: identityStructure.groups.map((group) => group.name),
    }));

  const loader = () => (
    <ExtendedGrid container alignItemsXxs="center" justifyContentXxs="center">
      <ExtendedGrid item>
        <CircularProgress />
      </ExtendedGrid>
    </ExtendedGrid>
  );

  return (
    <>
      <ContentHeading subtitle={t('userConfigurationPage.identity.description')}>
        {t('userConfigurationPage.identity.header')}
      </ContentHeading>

      {structureStatus === RequestStatus.SUCCESS &&
      structure.length > 0 &&
      currentIdentityId &&
      userProfile
        ? parseIdentityStructure().map((item, index) => (
            <Item
              key={index}
              item={item}
              disabledEmail={currentIdentityId !== item.data.id}
              openOnStart={currentIdentityId === item.data.id}
            />
          ))
        : loader()}
    </>
  );
};

export default Identity;
