import transitions from '@material-ui/core/styles/transitions';
import spacing from '../spacing';

export default {
  root: {
    transformOrigin: 'top left',
    transition: transitions.create(['all'], {
      duration: transitions.duration.short,
    }),
  },
  formControl: {
    transform: 'translate(0, 24px) scale(1)',
  },
  marginDense: {
    transform: 'translate(0, 21px) scale(1)',
  },
  shrink: {
    position: 'relative',
    transform: 'none',
    transition: 'none',
  },
  outlined: {
    transform: 'translate(12px, 17px) scale(1.165)',
    '&$marginDense': {
      transform: 'translate(12px, 17px) scale(1.165)',
    },
    '&$shrink': {
      transform: 'none',
      marginBottom: spacing(0.75),
    },
  },
};
