import spacing from '../spacing';
import breakpoints from '../breakpoints';

export default {
  root: {
    paddingTop: spacing(2),
    paddingLeft: spacing(2),
    paddingRight: spacing(2),
    paddingBottom: 0,
    [breakpoints.up('sm')]: {
      paddingTop: spacing(2),
      paddingLeft: spacing(2),
      paddingRight: spacing(2),
      paddingBottom: 0,
    },
    [breakpoints.up('md')]: {
      paddingTop: spacing(3),
      paddingLeft: spacing(3),
      paddingRight: spacing(3),
    },
    [breakpoints.up('lg')]: {
      paddingTop: spacing(5),
      paddingLeft: spacing(5),
      paddingRight: spacing(5),
      paddingBottom: 0,
      '& + [class^="MuiCardContent-root"], & + [class*="MuiCardContent-root"]': {
        marginTop: spacing(-1),
      },
    },
  },
  action: {
    marginTop: 0,
    marginRight: 0,
    alignSelf: 'center',
  },
};
