import React from 'react';
import PropTypes from 'prop-types';
import { ListItem, Typography } from '@material-ui/core';
import { ExtendedGrid } from 'src/components';
import { useStyles } from './styles';

const ItemWrapper = ({ children, label, smallScreen, alignItems }) => {
  const classes = useStyles();

  return (
    <ListItem
      divider={false}
      classes={{ root: classes.root, container: classes.listItemContainer }}
    >
      <ExtendedGrid container spacingX={2} spacingY={1} alignItemsXxs={alignItems}>
        <ExtendedGrid item xxs={12} md={4} lg={3}>
          <Typography
            variant={smallScreen ? 'body2' : 'body1'}
            color="textSecondary"
            component="label"
          >
            {label}
          </Typography>
        </ExtendedGrid>
        <ExtendedGrid item xxs>
          {children}
        </ExtendedGrid>
      </ExtendedGrid>
    </ListItem>
  );
};

ItemWrapper.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
  smallScreen: PropTypes.bool,
  alignItems: PropTypes.oneOf(['flex-start', 'center', 'flex-end', 'stretch', 'baseline']),
};

ItemWrapper.defaultProps = {
  alignItems: 'center',
};

export default ItemWrapper;
