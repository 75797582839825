import { Typography, useMediaQuery } from '@material-ui/core';
import { CheckCircleOutline } from '@material-ui/icons';
import clsx from 'clsx';

import UserAvatarAndTextItem from 'src/components/UserAvatarAndTextItem';
import {
  Close as CloseIcon,
  HourglassProgress,
  Stopwatch as StopwatchIcon,
} from 'src/components/CustomIcon';
import { getStatusName } from 'src/models/document.model';
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ContactHelper from 'src/helpers/contactHelper';
import { documentDisplaySelector } from 'src/redux/selectors/documentDisplay.selector';
import { DocumentStatus, TemplateDocumentType } from 'src/models/documents.model';
import { useStyles } from './styles';

const DocumentStatusNotification = () => {
  const { status, rejection, documentType } = useSelector(
    (state) => state.documentDisplayStore.document,
    undefined
  );
  const smallDevice = useMediaQuery(({ breakpoints }) => breakpoints.down('sm'));
  const hasUserSignedDocument = useSelector(
    documentDisplaySelector.hasUserSignedDocument,
    undefined
  );
  const isCertificate = useSelector(documentDisplaySelector.isCertificate, undefined);

  const { t } = useTranslation();
  const readyForPreview = documentType === TemplateDocumentType.DURABLE_MEDIA;
  const isFinished = status === DocumentStatus.FINISHED;
  const isRejected = status === DocumentStatus.REJECTED;
  const isTemporaryInvalidated = status === DocumentStatus.TEMPORARILY_INVALIDATE;
  const isInvalidated = [DocumentStatus.INVALIDATED, DocumentStatus.REJECTED].includes(status);
  const isExpired = status === DocumentStatus.EXPIRED;
  const isValid = status === DocumentStatus.VALID;
  const showForSigning =
    hasUserSignedDocument && [DocumentStatus.ISSUING, DocumentStatus.SIGNING].includes(status);

  const visible =
    isFinished ||
    isRejected ||
    isInvalidated ||
    readyForPreview ||
    isValid ||
    isExpired ||
    isTemporaryInvalidated ||
    showForSigning;
  const classes = useStyles();

  const notificationsStyles = clsx(classes.notification, {
    visible,
    error: isInvalidated || isRejected || isExpired,
    warning: isTemporaryInvalidated,
    info: showForSigning,
  });

  const notificationContentStyles = clsx(classes.notificationContent);

  const notificationHeaderStyles = clsx(classes.notificationHeader, {
    error: isInvalidated || isRejected || isExpired,
    warning: isTemporaryInvalidated,
    info: showForSigning,
  });

  if (!visible) {
    return null;
  }

  const entity =
    (isInvalidated || isTemporaryInvalidated) && ContactHelper.getEntityData(rejection?.id);

  const getIcon = (status, readyForPreview) => {
    if (readyForPreview) return <CheckCircleOutline />;
    switch (status) {
      case DocumentStatus.ISSUING:
      case DocumentStatus.SIGNING:
        return <HourglassProgress />;
      case DocumentStatus.FINISHED:
      case DocumentStatus.READY_FOR_PREVIEW:
      case DocumentStatus.VALID:
        return <CheckCircleOutline />;
      case DocumentStatus.EXPIRED:
      case DocumentStatus.INVALIDATED:
      case DocumentStatus.REJECTED:
        return <CloseIcon />;

      case DocumentStatus.TEMPORARILY_INVALIDATE:
        return <StopwatchIcon />;
      default:
        return null;
    }
  };

  return (
    <div className={notificationsStyles}>
      <div className={notificationHeaderStyles}>
        <Typography variant="h5">
          {readyForPreview
            ? getStatusName({
                status: DocumentStatus.READY_FOR_PREVIEW,
                isShort: false,
                t,
                isCertificate,
              })
            : getStatusName({
                status,
                isShort: false,
                t,
                isCertificate,
              })}
        </Typography>
        {getIcon(status, readyForPreview)}
      </div>
      {!smallDevice && (isInvalidated || isTemporaryInvalidated) && (
        <div className={notificationContentStyles}>
          <div className={classes.notificationContentAvatar}>
            <UserAvatarAndTextItem
              showSettingsIcon={false}
              description={entity?.contactEmail}
              text={entity?.name}
              mainImageSrc={
                entity && !entity.isGroup
                  ? `${process.env.REACT_APP_AVATAR_URL}/identity/${entity.identityId}?kind=SMALL`
                  : null
              }
            />
          </div>
          <Typography className={classes.notificationContentMessage}>
            {rejection?.reason}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default DocumentStatusNotification;
