import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Card, CardContent, CardHeader, Collapse, Fab } from '@material-ui/core';
import {
  ArrowTopSLine as ArrowTopIcon,
  ArrowDownSLine as ArrowDownIcon,
} from 'src/components/CustomIcon';

import { useStyles } from './styles';

const CardCollapseItem = ({ className: classNameProp, children, title, openOnStart }) => {
  const [opened, setOpen] = useState(openOnStart);
  const classes = useStyles();
  const handleClick = () => {
    setOpen(!opened);
  };

  return (
    <Card
      variant="outlined"
      className={clsx(classes.root, opened && classes.rootOpen, classNameProp)}
    >
      <CardHeader
        onClick={handleClick}
        disableTypography
        title={title}
        action={
          <Fab
            size="small"
            color="primary"
            classes={{
              root: classes.headerRootButton,
            }}
          >
            {opened ? <ArrowTopIcon /> : <ArrowDownIcon />}
          </Fab>
        }
        classes={{
          root: classes.headerRoot,
        }}
      />
      <Collapse in={opened} timeout="auto" unmountOnExit>
        <CardContent>{children}</CardContent>
      </Collapse>
    </Card>
  );
};

CardCollapseItem.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  title: PropTypes.node,
  openOnStart: PropTypes.bool,
};

CardCollapseItem.defaultProps = {
  openOnStart: false,
};

export default CardCollapseItem;
