import contactsConstants from '../constants/contacts.constants';
import contactsService from '../services/contacts.service';

const contactsActions = { initializeContacts, addMissingToQueue, getEntities };

function getEntities(workspaceId, entitiesIds) {
  return (dispatch) => {
    dispatch({ type: contactsConstants.CONTACTS_FETCH_ENTITIES_REQUEST, ids: entitiesIds });
    contactsService
      .getEntities(workspaceId, entitiesIds)
      .then((response) => {
        const parsedContacts = {};
        const parsedGroups = {};
        response?.data?.identities?.forEach((contactInfo) => {
          parsedContacts[contactInfo.identityId] = contactInfo;
        });
        response?.data?.groups?.forEach((contactInfo) => {
          parsedGroups[contactInfo.groupId] = contactInfo;
        });
        dispatch({
          type: contactsConstants.CONTACTS_FETCH_ENTITIES_SUCCESS,
          workspaceId,
          contacts: parsedContacts,
          groups: parsedGroups,
          initialIds: entitiesIds,
        });
      })
      .catch(() => {
        dispatch({ type: contactsConstants.CONTACTS_FETCH_ENTITIES_FAILURE });
      });
  };
}

function initializeContacts() {
  return { type: contactsConstants.INITIALIZE_CONTACTS };
}

function addMissingToQueue(ids) {
  return { type: contactsConstants.CONTACTS_ADD_TO_QUEUE, ids };
}

export default contactsActions;
