import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Fab, Typography, Button, Tooltip } from '@material-ui/core';
import { AvatarWithBadge } from '../index';
import { Settings as SettingsIcon } from '../CustomIcon';
import { useStyles } from './styles';

const UserAvatarAndTextItem = ({
  classes: classesProp,
  className: classNameProp,
  spacing,
  isButton,
  fullWidth,
  size,
  text,
  additionalText,
  additionalTextArray,
  description,
  mainImageAlt,
  mainImageSrc,
  badgeChildren,
  badgeImageAlt,
  badgeImageSrc,
  onClick,
  onClickButtonProps,
  tooltip,
  showSettingsIcon,
}) => {
  const classes = useStyles();
  const Component = isButton ? Button : 'div';
  const displayDescription = description && text !== description;
  const displayLabels = text || additionalText || displayDescription;

  const renderComponent = () => (
    <Component
      className={clsx(
        classes.root,
        fullWidth && classes.fullWidthRoot,
        spacing && classes.spacingRoot,
        isButton && classes.clickElement,
        classesProp && classesProp.root,
        classNameProp
      )}
      variant="text"
      onClick={isButton && onClick}
    >
      <div className={classes.iconWrapper}>
        <AvatarWithBadge
          size={size}
          src={mainImageSrc}
          alt={mainImageAlt}
          badgeChildren={badgeChildren}
          badgeAlt={badgeImageAlt}
          badgeSrc={badgeImageSrc}
        >
          {text}
        </AvatarWithBadge>
      </div>

      {displayLabels && (
        <div className={clsx(classes.labels, classesProp && classesProp.labels)}>
          {(additionalTextArray || text || additionalText) && (
            <Typography
              variant="body1"
              className={clsx(classes.text, classesProp && classesProp.text)}
            >
              {text}
              {additionalText && (
                <>
                  &nbsp;
                  <span
                    className={clsx(
                      classes.additionalText,
                      classesProp && classesProp.additionalText
                    )}
                  >
                    {additionalText}
                  </span>
                </>
              )}
            </Typography>
          )}
          {additionalTextArray && (
            <>
              {additionalTextArray.map((item, index) => (
                <span
                  key={index}
                  className={clsx(
                    item.classes,
                    classes.additionalText,
                    classesProp && classesProp.additionalText
                  )}
                >
                  {item.text}&nbsp;
                </span>
              ))}
            </>
          )}
          {displayDescription && (
            <Typography
              variant="subtitle2"
              component="span"
              className={clsx(classes.description)}
              color="textSecondary"
              dangerouslySetInnerHTML={{
                __html: description,
              }}
            />
          )}
        </div>
      )}

      {showSettingsIcon && <SettingsIcon className={classes.settingsIcon} />}

      {onClick && !isButton && (
        <Fab
          size="small"
          color={onClickButtonProps.color}
          className={classes.iconButton}
          onClick={onClick}
        >
          {onClickButtonProps.icon}
        </Fab>
      )}
    </Component>
  );
  return (
    <>{tooltip ? <Tooltip title={tooltip}>{renderComponent()}</Tooltip> : renderComponent()}</>
  );
};

UserAvatarAndTextItem.propTypes = {
  className: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object,
  fullWidth: PropTypes.bool,
  size: PropTypes.oneOf(['large', 'medium', 'small', 'mini']),
  text: PropTypes.string,
  additionalText: PropTypes.string,
  additionalTextArray: PropTypes.arrayOf(PropTypes.shape({})),
  description: PropTypes.string,
  mainImageAlt: PropTypes.string,
  mainImageSrc: PropTypes.string,
  badgeChildren: PropTypes.node,
  badgeImageAlt: PropTypes.string,
  badgeImageSrc: PropTypes.string,
  spacing: PropTypes.bool,
  isButton: PropTypes.bool,
  tooltip: PropTypes.node,
  onClickButtonProps: PropTypes.shape({
    icon: PropTypes.node,
    color: PropTypes.oneOf(['inherit', 'primary', 'secondary', 'default']),
  }),
  onClick: PropTypes.func,
  showSettingsIcon: PropTypes.bool,
};

UserAvatarAndTextItem.defaultProps = {
  spacing: false,
  mainImageAlt: '',
  badgeImageAlt: '',
  fullWidth: false,
  onClickButtonProps: {
    icon: <SettingsIcon />,
    color: 'default',
  },
};

export default UserAvatarAndTextItem;
