import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ palette, shadows, shape, spacing, transitions, typography }) =>
    createStyles({
      root: {
        width: '100%',
        textAlign: 'left',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        borderRadius: shape.borderRadius,
        padding: spacing(2),
        transition: transitions.create(['background-color', 'box-shadow', 'border', 'color'], {
          duration: transitions.duration.short,
        }),
      },
      clickable: {
        border: `1px solid ${palette.divider}`,
        '&:hover:not($selected), &:focus:not($selected)': {
          textDecoration: 'none',
          borderColor: palette.bottomLineColor,
          boxShadow: shadows[6],
          '@media (hover: none)': {
            backgroundColor: 'transparent',
          },
          '&[disabled]': {
            backgroundColor: 'transparent',
          },
        },
      },
      selected: {
        borderColor: palette.primary.main,
        boxShadow: shadows[12],
      },
      notActive: {
        opacity: 0.1,
        pointerEvents: 'none',
      },
      disabled: {
        opacity: 0.1,
        pointerEvents: 'none',
      },
      content: {
        flex: 1,
      },
      label: {
        display: 'flex',
        alignItems: 'center',
        '& em': {
          fontStyle: 'inherit',
          fontWeight: typography.fontWeightRegular,
          color: palette.primary.main,
          fontSize: typography.subtitle2.fontSize,
        },
      },
      description: {
        display: 'block',
        marginTop: spacing(0.5),
      },
      icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 48,
        minWidth: 48,
        height: 48,
        marginRight: spacing(2),
        color: palette.text.disabled,
        transition: transitions.create(['color'], {
          duration: transitions.duration.short,
        }),
        '& svg': {
          display: 'block',
        },
      },
      iconSelected: {
        color: palette.primary.main,
      },
      iconVariant: {
        borderRadius: shape.borderRadius,
        backgroundColor: palette.background.default,
      },
      fullWidth: {
        width: '100%',
      },
      informationModalOpen: {
        width: spacing(3),
        minWidth: spacing(3),
        height: spacing(3),
        minHeight: spacing(3),
        margin: `${spacing(-0.5)}px ${spacing(0.5)}px`,
        '& *': {
          pointerEvents: 'none',
        },
        '& svg': {
          width: spacing(2),
          height: spacing(2),
          color: palette.text.disabled,
        },
        '&:hover, &:focus': {
          '& svg': {
            color: palette.primary.main,
          },
        },
      },
    }),
  { index: 0 }
);
