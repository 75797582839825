import { Drive as DriveIcon, ViewBoxes, Wallet } from 'src/components/CustomIcon';
import React from 'react';

export const Links = (t, full) =>
  full
    ? [
        {
          label: t('workspacePage.drawer.basic'),
          url: '/workspace-settings/basic',
          icon: <ViewBoxes />,
          children: [
            {
              label: t('workspacePage.drawer.basicSummary'),
              url: '/workspace-settings/basic/summary',
            },
            {
              label: t('workspacePage.drawer.basicSettings'),
              url: '/workspace-settings/basic/settings',
            },
          ],
        },
        {
          label: t('workspacePage.drawer.userManagement'),
          url: '/workspace-settings/user-management',
          icon: <DriveIcon />,
          children: [
            {
              label: t('workspacePage.drawer.userManagementUser'),
              url: '/workspace-settings/user-management/users',
            },
            {
              label: t('workspacePage.drawer.userManagementGroup'),
              url: '/workspace-settings/user-management/groups',
            },
          ],
        },
        {
          label: t('workspacePage.drawer.subscription'),
          url: '/workspace-settings/subscription',
          icon: <Wallet />,
          children: [
            {
              label: t('workspacePage.drawer.subscriptionProducts'),
              url: '/workspace-settings/subscription/products',
            },
          ],
        },
      ]
    : [
        {
          label: t('workspacePage.drawer.basic'),
          url: '/workspace-settings/basic',
          icon: <ViewBoxes />,
          children: [
            {
              label: t('workspacePage.drawer.basicSettings'),
              url: '/workspace-settings/basic/settings',
            },
          ],
        },
        {
          label: t('workspacePage.drawer.userManagement'),
          url: '/workspace-settings/user-management',
          icon: <DriveIcon />,
          children: [
            {
              label: t('workspacePage.drawer.userManagementUser'),
              url: '/workspace-settings/user-management/users',
            },
            {
              label: t('workspacePage.drawer.userManagementGroup'),
              url: '/workspace-settings/user-management/groups',
            },
          ],
        },
        {
          label: t('workspacePage.drawer.subscription'),
          url: '/workspace-settings/subscription',
          icon: <Wallet />,
          children: [
            {
              label: t('workspacePage.drawer.subscriptionProducts'),
              url: '/workspace-settings/subscription/products',
            },
          ],
        },
      ];

export default Links;
