import { keys as breakpointKeys } from '@material-ui/core/styles/createBreakpoints';
import { createStyles, makeStyles } from '@material-ui/core';

breakpointKeys.unshift('xxs');

export const SPACINGS = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
export const GRID_SIZES = ['auto', true, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const generateGrid = (globalStyles, breakpoints, breakpoint) => {
  const styles = {};

  GRID_SIZES.forEach((size) => {
    const key = `item-${breakpoint}-${size}`;

    if (size >= 0 && size <= 12) {
      if (size !== 0) {
        const width = `${Math.round((size / 12) * 10e7) / 10e5}%`;

        styles[`item-offset-${breakpoint}-${size}`] = { marginLeft: width };
        styles[`item-order-${breakpoint}-${size}`] = { order: size };
        styles[key] = {
          display: 'block',
          flexBasis: width,
          flexGrow: 0,
          maxWidth: width,
        };
      }
      if (size === 0) {
        styles[`item-offset-${breakpoint}-${size}`] = { marginLeft: 0 };
        styles[key] = { display: 'none' };
      }
    }

    if (size === true) {
      styles[key] = {
        display: 'block',
        flexBasis: 0,
        flexGrow: 1,
        maxWidth: '100%',
      };
      return;
    }

    if (size === 'auto') {
      styles[key] = {
        display: 'block',
        flexBasis: 'auto',
        flexGrow: 0,
        maxWidth: 'none',
      };
    }
  });

  // multi-line alignment in main axis
  styles[`align-content-${breakpoint}-stretch`] = { alignContent: 'stretch' };
  styles[`align-content-${breakpoint}-center`] = { alignContent: 'center' };
  styles[`align-content-${breakpoint}-flex-start`] = { alignContent: 'flex-start' };
  styles[`align-content-${breakpoint}-flex-end`] = { alignContent: 'flex-end' };
  styles[`align-content-${breakpoint}-space-between`] = { alignContent: 'space-between' };
  styles[`align-content-${breakpoint}-space-around`] = { alignContent: 'space-around' };

  // alignment in cross axis
  styles[`align-items-${breakpoint}-stretch`] = { alignItems: 'stretch' };
  styles[`align-items-${breakpoint}-center`] = { alignItems: 'center' };
  styles[`align-items-${breakpoint}-flex-start`] = { alignItems: 'flex-start' };
  styles[`align-items-${breakpoint}-flex-end`] = { alignItems: 'flex-end' };
  styles[`align-items-${breakpoint}-baseline`] = { alignItems: 'baseline' };

  // alignment in main axis
  styles[`justify-content-${breakpoint}-center`] = { justifyContent: 'center' };
  styles[`justify-content-${breakpoint}-flex-start`] = { justifyContent: 'flex-start' };
  styles[`justify-content-${breakpoint}-flex-end`] = { justifyContent: 'flex-end' };
  styles[`justify-content-${breakpoint}-space-between`] = { justifyContent: 'space-between' };
  styles[`justify-content-${breakpoint}-space-around`] = { justifyContent: 'space-around' };
  styles[`justify-content-${breakpoint}-space-evenly`] = {
    justifyContent: 'space-evenly',
  };

  // layout (direction)
  styles[`direction-${breakpoint}-column`] = { flexDirection: 'column' };
  styles[`direction-${breakpoint}-column-reverse`] = { flexDirection: 'column-reverse' };
  styles[`direction-${breakpoint}-row`] = { flexDirection: 'row' };
  styles[`direction-${breakpoint}-row-reverse`] = { flexDirection: 'row-reverse' };

  // wrapping
  styles[`wrap-${breakpoint}-wrap`] = { flexWrap: 'wrap' };
  styles[`wrap-${breakpoint}-nowrap`] = { flexWrap: 'nowrap' };
  styles[`wrap-${breakpoint}-wrap-reverse`] = { flexWrap: 'wrap-reverse' };

  // text alignment
  styles[`text-align-${breakpoint}-left`] = { textAlign: 'left' };
  styles[`text-align-${breakpoint}-right`] = { textAlign: 'right' };
  styles[`text-align-${breakpoint}-center`] = { textAlign: 'center' };
  styles[`text-align-${breakpoint}-justify`] = { textAlign: 'justify' };
  styles[`text-align-${breakpoint}-inherit`] = { textAlign: 'inherit' };
  styles[`text-align-${breakpoint}-unset`] = { textAlign: 'unset' };

  // No need for a media query for the first size.
  if (breakpoint === 'xxs') {
    Object.assign(globalStyles, styles);
  } else {
    // eslint-disable-next-line no-param-reassign
    globalStyles[breakpoints.up(breakpoint)] = styles;
  }
};

const getOffset = (val, div = 1) => {
  const parse = parseFloat(val);
  return `${parse / div}${String(val).replace(String(parse), '') || 'px'}`;
};

const generateGutter = (space) => {
  const styles = {};

  SPACINGS.forEach((spacingItem) => {
    const themeSpacing = space(spacingItem);

    if (themeSpacing === 0) {
      return;
    }

    styles[`spacing-${spacingItem}`] = {
      margin: `-${getOffset(themeSpacing, 2)}`,
      width: `calc(100% + ${getOffset(themeSpacing)})`,
      '& > $item': {
        padding: getOffset(themeSpacing, 2),
      },
    };

    styles[`spacing-x-${spacingItem}`] = {
      marginLeft: `-${getOffset(themeSpacing, 2)}`,
      marginRight: `-${getOffset(themeSpacing, 2)}`,
      width: `calc(100% + ${getOffset(themeSpacing)})`,
      '& > $item': {
        paddingLeft: getOffset(themeSpacing, 2),
        paddingRight: getOffset(themeSpacing, 2),
      },
    };

    styles[`spacing-y-${spacingItem}`] = {
      marginTop: `-${getOffset(themeSpacing, 2)}`,
      marginBottom: `-${getOffset(themeSpacing, 2)}`,
      '& > $item': {
        paddingTop: getOffset(themeSpacing, 2),
        paddingBottom: getOffset(themeSpacing, 2),
      },
    };
  });

  return styles;
};

export const useStyles = makeStyles(
  ({ breakpoints, spacing }) =>
    createStyles({
      root: {},
      container: {
        boxSizing: 'border-box',
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
      },
      item: {
        boxSizing: 'border-box',
        margin: '0', // For instance, it's useful when used with a `figure` element.
      },
      zeroMinWidth: {
        minWidth: 0,
      },
      ...generateGutter(spacing),
      ...breakpointKeys.reduce((accumulator, key) => {
        // Use side effect over immutability for better performance.
        generateGrid(accumulator, breakpoints, key);
        return accumulator;
      }, {}),
    }),
  { index: 0 }
);
