import React, { ChangeEvent } from 'react';
import { TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/redux/reducers';
import { AdvancedSearchFields } from 'src/models/documents.model';
import documentsActions from 'src/redux/actions/documents.actions';
import Field from '../../subcomponents/AdvancedSearchModalField';

import { useStyles } from '../../styles';

const NameField = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();

  const nameValue = useSelector(
    (state: RootState) => state.documentsStore.draftAdvancedSearch[AdvancedSearchFields.NAME]
  );

  const onChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    dispatch(
      documentsActions.updateDraftAdvancedSearch(AdvancedSearchFields.NAME, event.target.value)
    );

  return (
    <Field title={t<string>('advancedSearch.fields.name.label')}>
      <TextField
        className={classes.textField}
        onChange={onChange}
        value={nameValue}
        placeholder={t('advancedSearch.fields.name.placeholder')}
      />
    </Field>
  );
};

export default NameField;
