import React from 'react';
import { ArrowDownSLine as ChevronDownIcon } from 'src/components/CustomIcon';
import { useStyles } from '../styles';

type AdvancedSearchModalFieldProps = {
  title: string;
  children: JSX.Element;
  withInputWrapper?: boolean;
};

const AdvancedSearchModalField = ({
  title,
  children,
  withInputWrapper = false,
}: AdvancedSearchModalFieldProps): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.fieldWrapper}>
      <div className={classes.fieldTitle}>{title}</div>

      {withInputWrapper ? (
        <div className={classes.fieldInputContent}>
          {children} <ChevronDownIcon className={classes.fieldInputArrowDown} />
        </div>
      ) : (
        <div className={classes.fieldContent}>{children}</div>
      )}
    </div>
  );
};

export default AdvancedSearchModalField;
