import { RequestStatus } from 'src/helpers/reduxReuquest.util';
import contactsConstants from '../constants/contacts.constants';

const initialState = {
  contactBookEntitiesFetchStatus: RequestStatus.IDLE,
  contacts: {},
  contactsAvailable: [],
  contactsQueue: [],
  fetchingContacts: [],
  groups: {},
};

const addUniqueItemsToArray = (currentInProgress, toAdd) => {
  const finalProgress = [...currentInProgress];
  toAdd.forEach((contactId) => {
    if (currentInProgress.indexOf(contactId) === -1) {
      finalProgress.push(contactId);
    }
  });
  return finalProgress;
};

const removeItemsFromArray = (currentInProgress, toRemove) => {
  const finalArray = [];
  currentInProgress.forEach((item) => {
    if (toRemove.indexOf(item) === -1) {
      finalArray.push(item);
    }
  });
  return finalArray;
};

function contactsStore(state = initialState, action) {
  switch (action.type) {
    case contactsConstants.INITIALIZE_CONTACTS:
      return {
        ...initialState,
      };
    case contactsConstants.CONTACTS_ADD_TO_QUEUE:
      return {
        ...state,
        contactsQueue: addUniqueItemsToArray(
          state.contactsQueue,
          removeItemsFromArray(action.ids, [...state.fetchingContacts, ...state.contactsAvailable]) // filter out contacts that are already available and in progress
        ), // and then add unique ids to queue
      };
    case contactsConstants.CONTACTS_FETCH_ENTITIES_REQUEST:
      return {
        ...state,
        fetchingContacts: addUniqueItemsToArray(state.fetchingContacts, action.ids),
        contactsQueue: removeItemsFromArray(state.contactsQueue, action.ids),
        contactBookEntitiesFetchStatus: RequestStatus.PENDING,
      };
    case contactsConstants.CONTACTS_FETCH_ENTITIES_SUCCESS: {
      const newEntityContacts = { ...state.contacts, ...action.contacts };
      const newEntityGroups = { ...state.groups, ...action.groups };
      const entitiesFound = [...Object.keys(action.contacts), ...Object.keys(action.groups)];
      action.initialIds.forEach((initialItem) => {
        if (entitiesFound.indexOf(initialItem) === -1) {
          entitiesFound[initialItem] = {
            identityId: initialItem,
            email: initialItem,
            workspaceName: initialItem,
          }; // if contact is not found, then we have to assume that, it's unavailable anymore, so we need to add dummy
          entitiesFound.push(initialItem);
        }
      });
      return {
        ...state,
        contactBookEntitiesFetchStatus: RequestStatus.SUCCESS,
        contacts: newEntityContacts,
        groups: newEntityGroups,
        contactsAvailable: addUniqueItemsToArray(state.contactsAvailable, entitiesFound),
        fetchingContacts: removeItemsFromArray(state.fetchingContacts, action.initialIds),
      };
    }
    case contactsConstants.CONTACTS_FETCH_ENTITIES_FAILURE: {
      return {
        ...state,
        contactBookEntitiesFetchStatus: RequestStatus.ERROR,
      };
    }
    default:
      return state;
  }
}

export default contactsStore;
