import React from 'react';
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon';

export default createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.9999 12.14C20.9466 11.0341 20.4697 9.99118 19.6682 9.22739C18.8667 8.4636 17.802 8.03754 16.6949 8.03754C15.5877 8.03754 14.523 8.4636 13.7215 9.22739C12.92 9.99118 12.4432 11.0341 12.3899 12.14C12.3976 13.1111 12.7523 14.0475 13.3899 14.78V20.28C13.3895 20.4674 13.4418 20.6512 13.5408 20.8103C13.6398 20.9694 13.7816 21.0975 13.9499 21.18C14.1187 21.26 14.3065 21.2912 14.492 21.27C14.6776 21.2488 14.8535 21.1761 14.9999 21.06L16.6899 19.69L18.3699 21.06C18.5485 21.2033 18.7709 21.281 18.9999 21.28C19.1478 21.2789 19.294 21.2483 19.4299 21.19C19.6016 21.1082 19.7464 20.9792 19.8474 20.818C19.9484 20.6568 20.0013 20.4702 19.9999 20.28V14.77C20.6361 14.0405 20.9907 13.1078 20.9999 12.14ZM16.6999 9.99999C17.2866 9.98626 17.8555 10.2023 18.2852 10.6021C18.7149 11.0019 18.9714 11.5538 18.9999 12.14C18.9613 12.7251 18.7017 13.2736 18.2737 13.6744C17.8457 14.0751 17.2812 14.2981 16.6949 14.2981C16.1085 14.2981 15.5441 14.0751 15.1161 13.6744C14.6881 13.2736 14.4285 12.7251 14.3899 12.14C14.4159 11.5511 14.673 10.9962 15.1054 10.5956C15.5378 10.195 16.1107 9.981 16.6999 9.99999ZM17.3399 17.66C17.1617 17.5155 16.9393 17.4366 16.7099 17.4366C16.4805 17.4366 16.258 17.5155 16.0799 17.66L15.3899 18.21V16.09C15.8094 16.2294 16.2479 16.3036 16.6899 16.31C17.1352 16.3034 17.5769 16.2292 17.9999 16.09V18.18L17.3399 17.66Z" />
    <path d="M20 7H4C3.73478 7 3.48043 6.89464 3.29289 6.70711C3.10536 6.51957 3 6.26522 3 6C3 5.73478 3.10536 5.48043 3.29289 5.29289C3.48043 5.10536 3.73478 5 4 5H20C20.2652 5 20.5196 5.10536 20.7071 5.29289C20.8946 5.48043 21 5.73478 21 6C21 6.26522 20.8946 6.51957 20.7071 6.70711C20.5196 6.89464 20.2652 7 20 7Z" />
    <path d="M10.43 11H4C3.73478 11 3.48043 10.8946 3.29289 10.7071C3.10536 10.5196 3 10.2652 3 10C3 9.73478 3.10536 9.48043 3.29289 9.29289C3.48043 9.10536 3.73478 9 4 9H10.39C10.6552 9 10.9096 9.10536 11.0971 9.29289C11.2846 9.48043 11.39 9.73478 11.39 10C11.39 10.2652 11.2846 10.5196 11.0971 10.7071C10.9096 10.8946 10.6552 11 10.39 11H10.43Z" />
    <path d="M10.43 15H4C3.73478 15 3.48043 14.8946 3.29289 14.7071C3.10536 14.5196 3 14.2652 3 14C3 13.7348 3.10536 13.4804 3.29289 13.2929C3.48043 13.1054 3.73478 13 4 13H10.39C10.6552 13 10.9096 13.1054 11.0971 13.2929C11.2846 13.4804 11.39 13.7348 11.39 14C11.39 14.2652 11.2846 14.5196 11.0971 14.7071C10.9096 14.8946 10.6552 15 10.39 15H10.43Z" />
  </svg>,
  'CertificateIcon'
);
