import axios from 'src/helpers/axios';
import { parseError } from 'src/helpers/parseErrors';

const moduleName = 'document-dsl-service';

function checkCreatedFileIds(documentIds) {
  return axios
    .get(`/rest/${moduleName}/documents/file-ids?documentIds=${documentIds}`)
    .then((response) => response)
    .catch((error) => parseError(error));
}

function fetchWorkspaceWorkflows(workspaceId) {
  return axios
    .get(`/rest/${moduleName}/workspaces/${workspaceId}/workflows`)
    .then((response) => response)
    .catch((error) => parseError(error));
}

function fetchTemplateList(workspaceId) {
  return axios
    .get(`/rest/${moduleName}/workspaces/${workspaceId}/templates`)
    .then((response) => response)
    .catch((error) => parseError(error));
}

function fetchTemplateListThumbnailToken({ workspaceId, templateId, templateThumbnailId }) {
  return axios
    .get(
      `/rest/${moduleName}/workspaces/${workspaceId}/templates/${templateId}/previews/${templateThumbnailId}`
    )
    .then((response) => response)
    .catch((error) => parseError(error));
}

function fetchTemplateDetails({ workspaceId, templateId }) {
  return axios
    .get(`/rest/${moduleName}/workspaces/${workspaceId}/templates/${templateId}`)
    .then((response) => response)
    .catch((error) => parseError(error));
}

function createCertificate({ workspaceId, templateId, certificateList }) {
  return axios
    .post(`/rest/${moduleName}/workspaces/${workspaceId}/templates/${templateId}`, {
      certificatesData: certificateList,
    })
    .then((response) => response)
    .catch((error) => parseError(error));
}
function sendCertificateDocuments({ workspaceId, body, folderId }) {
  return axios
    .post(`/rest/${moduleName}/workspaces/${workspaceId}/documents?folderId=${folderId}`, body)
    .then((response) => response)
    .catch((error) => parseError(error));
}

function updateCertificateRoles({
  workspaceId,
  documentIds,
  admins = [],
  editors = [],
  viewers = [],
}) {
  const body = {
    mutationName: 'modify_role',
    documentMutations: documentIds.map((documentId) => ({
      documentId,
      txnAttributes: {
        roles: {
          admins,
          editors,
          viewers,
        },
      },
    })),
  };
  return axios
    .post(`/rest/${moduleName}/workspaces/${workspaceId}/documents-mutation`, body)
    .then((response) => response)
    .catch((error) => parseError(error));
}

function signCertificate({ workspaceId, documentId }) {
  const body = {
    mutationName: 'sign',
    documentMutations: [
      {
        documentId,
      },
    ],
  };

  return axios
    .post(`/rest/${moduleName}/workspaces/${workspaceId}/documents-mutation`, body)
    .then((response) => response)
    .catch((error) => parseError(error));
}

function updateCertificateSigner({ workspaceId, documentIds, signers = [], isOrderSignificant }) {
  const body = {
    mutationName: 'modify_signers',
    documentMutations: documentIds.map((documentId) => ({
      documentId,
      txnAttributes: {
        signers,
        ordered: isOrderSignificant,
      },
    })),
  };

  return axios
    .post(`/rest/${moduleName}/workspaces/${workspaceId}/documents-mutation`, body)
    .then((response) => response)
    .catch((error) => parseError(error));
}

function rejectCertificate({ workspaceId, documentId, reason }) {
  const body = {
    mutationName: 'reject_document',
    documentMutations: [
      {
        documentId,
        txnAttributes: {
          reason,
        },
      },
    ],
  };

  return axios
    .post(`/rest/${moduleName}/workspaces/${workspaceId}/documents-mutation`, body)
    .then((response) => response)
    .catch((error) => parseError(error));
}

function invalidateCertificate({ workspaceId, documentId, reason }) {
  const body = {
    mutationName: 'invalidate_document',
    documentMutations: [
      {
        documentId,
        txnAttributes: {
          reason,
        },
      },
    ],
  };

  return axios
    .post(`/rest/${moduleName}/workspaces/${workspaceId}/documents-mutation`, body)
    .then((response) => response)
    .catch((error) => parseError(error));
}

function invalidateTemporarilyCertificate({ workspaceId, documentId, reason }) {
  const body = {
    mutationName: 'invalidate_temporarily_document',
    documentMutations: [
      {
        documentId,
        txnAttributes: {
          reason,
        },
      },
    ],
  };

  return axios
    .post(`/rest/${moduleName}/workspaces/${workspaceId}/documents-mutation`, body)
    .then((response) => response)
    .catch((error) => parseError(error));
}

function validateCertificate({ workspaceId, documentId, reason }) {
  const body = {
    mutationName: 'validate_document',
    documentMutations: [
      {
        documentId,
        txnAttributes: {
          reason,
        },
      },
    ],
  };

  return axios
    .post(`/rest/${moduleName}/workspaces/${workspaceId}/documents-mutation`, body)
    .then((response) => response)
    .catch((error) => parseError(error));
}

function setTransferabilityCertificate({ workspaceId, documentId, transferable }) {
  const body = {
    mutationName: 'set_transferability',
    documentMutations: [
      {
        documentId,
        txnAttributes: {
          transferable,
        },
      },
    ],
  };

  return axios
    .post(`/rest/${moduleName}/workspaces/${workspaceId}/documents-mutation`, body)
    .then((response) => response)
    .catch((error) => parseError(error));
}

function setCertificateAccessLevel({ documentId, isPublic }) {
  const body = {
    documentsAccess: [
      {
        documentId,
        publicAccess: isPublic,
      },
    ],
  };

  return axios
    .post(`/rest/${moduleName}/documents/access-level`, body)
    .then((response) => response)
    .catch((error) => parseError(error));
}

function checkAvailableMutations(documentId, service = moduleName) {
  return axios
    .get(`/rest/${service}/mutation/${documentId}`)
    .then((response) => response)
    .catch((error) => parseError(error));
}

function inviteRoleByMail(documentId, role, email) {
  return axios
    .post(
      `/rest/${moduleName}/documents/${documentId}/roles/${role}/invite`,
      { emails: [email] },
      { withPermissions: ['DOCUMENT_SERVICE_USER_POST_DOCUMENT_INVITE'] }
    )
    .then((response) => response)
    .catch((error) => parseError(error));
}

function inviteMultipleByRole(collection) {
  return axios
    .post(`/rest/${moduleName}/invitations/invite`, { collection })
    .then((response) => response)
    .catch((error) => parseError(error));
}

function generateHolderInvitationToken(documentId) {
  return axios
    .post(`/rest/${moduleName}/documents/${documentId}/roles/holder/invitation-token`, {})
    .then((response) => response)
    .catch((error) => parseError(error));
}

function deleteRoleByMail(documentId, role, email) {
  return axios
    .delete(
      `/rest/${moduleName}/documents/${documentId}/roles/${role}/invitations?mail=${encodeURIComponent(
        JSON.stringify(email)
      )}`
    )
    .then((response) => response)
    .catch((error) => parseError(error));
}

function transferOwnership(documentId, role, email) {
  return axios
    .post(
      `/rest/${moduleName}/invitations/invite`,
      {
        collection: [
          {
            email,
            role,
            documentId,
          },
        ],
      },
      { withPermissions: ['DOCUMENT_SERVICE_USER_POST_DOCUMENT_INVITE'] }
    )
    .then((response) => response)
    .catch((error) => parseError(error));
}

const MUTATION_NAMES = {
  MODIFY_ROLE: 'modify_role',
  CHANGE_HOLDER: 'change_holder',
  MODIFY_SIGNERS: 'modify_signers',
  SIGN: 'sign',
  REJECT_DOCUMENT: 'reject_document',
  INVALIDATE_DOCUMENT: 'invalidate_document',
  INVALIDATE_TEMPORARILY_DOCUMENT: 'invalidate_temporarily_document',
  VALIDATE_DOCUMENT: 'validate_document',
  SET_TRANSFERABILITY: 'set_transferability',
};

const documentDSLServices = {
  checkAvailableMutations,
  checkCreatedFileIds,
  createCertificate,
  deleteRoleByMail,
  fetchTemplateDetails,
  fetchTemplateList,
  fetchTemplateListThumbnailToken,
  fetchWorkspaceWorkflows,
  invalidateCertificate,
  invalidateTemporarilyCertificate,
  inviteRoleByMail,
  rejectCertificate,
  sendCertificateDocuments,
  setCertificateAccessLevel,
  setTransferabilityCertificate,
  signCertificate,
  transferOwnership,
  updateCertificateRoles,
  updateCertificateSigner,
  inviteMultipleByRole,
  validateCertificate,
  generateHolderInvitationToken,
};

export { documentDSLServices as default, MUTATION_NAMES, moduleName };
