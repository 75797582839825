import { createStyles, makeStyles } from '@material-ui/core';
import { fade } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  ({ spacing, palette }) =>
    createStyles({
      currentWorkspace: {
        position: 'relative',
        zIndex: 2,
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        '&:hover:after': {
          opacity: 1,
        },
        '&:after': {
          content: '" "',
          opacity: 0,
          position: 'absolute',
          top: -spacing(1),
          left: -spacing(1),
          width: 'calc(100% + 16px)',
          height: 'calc(100% + 16px)',
          backgroundColor: fade(palette.common.white, 0.1),
          borderRadius: spacing(0.75),
          zIndex: -1,
          transition: '.3s ease-in-out',
        },
      },
      currentWorkspaceOpen: {
        color: palette.text.primary,
        '&:after': {
          backgroundColor: palette.action.hover,
          width: 'calc(245px - 16px)',
          height: 'calc(100% + 16px)',
        },
      },
      arrowsIcon: {
        position: 'absolute',
        top: 12,
        right: -6,
      },
      arrowIconOpen: {
        right: -30,
      },
      currentWorkspaceText: {
        fontWeight: 600,
        paddingLeft: spacing(1.5),
        paddingRight: spacing(2),
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: '15px',
      },
      currentWorkspaceTextOpen: {
        paddingRight: 0,
      },
      workspacesWrapper: {
        maxHeight: 'calc(100vh - 300px)',
        overflowY: 'scroll',
      },
      workspaceText: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        paddingLeft: spacing(1.5),
        paddingRight: spacing(1.5),
      },
      wrapper: {
        position: 'relative',
      },
      content: {
        zIndex: 1,
        position: 'absolute',
        top: -spacing(2),
        left: -spacing(2),
        display: 'flex',
        flexDirection: 'column',
        opacity: 0,
        visibility: 'hidden',
        transition: '.3s ease-in-out',
        width: 245,
        paddingTop: spacing(10),
        paddingBottom: spacing(1),
        boxShadow: '0px 20px 65px rgba(31, 39, 55, 0.1)',
        backgroundColor: palette.common.white,
        borderRadius: spacing(0.75),
        '& .MuiMenuItem-root:hover': {
          '& .MuiTypography-colorTextPrimary': {
            color: palette.primary.main,
          },
        },
        '& .small-item': {
          paddingTop: 2,
          paddingBottom: 2,
          minHeight: 38,
        },
      },
      contentOpen: {
        visibility: 'visible',
        opacity: 1,
      },
      mainAvatar: {
        height: spacing(6),
        width: spacing(6),
        borderRadius: 15,
      },
      avatar: {
        borderRadius: 15,
      },
    }),
  { index: 0 }
);
