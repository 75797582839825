import React from 'react';
import { DocumentMemberRole, UserManagerPerson } from 'src/models/documentUsersManagement.model';
import { Chip, Tooltip, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { documentDisplaySelector } from 'src/redux/selectors/documentDisplay.selector';
import { IdentitySelector } from 'src/redux/selectors/identity.selector';
import HighlightString from './HighlightString';
import { useUserItemTitleStyles } from './styles';

interface UserItemTitleProps {
  userData: UserManagerPerson;
  highlightString: string;
  showTitleAdditionalTexts?: boolean;
}

const UserItemTitle = ({
  userData,
  highlightString,
  showTitleAdditionalTexts = true,
}: UserItemTitleProps): JSX.Element | null => {
  const classes = useUserItemTitleStyles();
  const { t } = useTranslation();
  const isAuthor = useSelector(documentDisplaySelector.selectDocumentAuthorId) === userData._id;
  const isYou = useSelector(IdentitySelector.selectCurrentIdentityId) === userData._id;
  const isSentInvitation = userData.isInvited;
  const isNewInvitation = userData.isInvitationToBeSend;
  const isCurrentHolder =
    userData.role === DocumentMemberRole.HOLDER && !isSentInvitation && !isNewInvitation;
  const { isGroup, isToken } = userData;

  return (
    <div className={clsx(classes.title, !showTitleAdditionalTexts && 'itemTitleHover')}>
      <Typography variant="h6">
        {isToken ? (
          t<string>('common.generatedToken')
        ) : (
          <HighlightString
            text={userData._name || userData.email || ''}
            highlightString={highlightString}
          />
        )}
      </Typography>
      {!isToken && showTitleAdditionalTexts && (
        <>
          {isAuthor && (
            <Typography variant="body1" className={classes.author}>
              ({t<string>('common.author')})
            </Typography>
          )}
          {isYou && (
            <Typography variant="body1" className={classes.you}>
              ({t<string>('common.you')})
            </Typography>
          )}
          {isGroup && (
            <Typography variant="body1" className={classes.group}>
              ({t<string>('common.groupLowerCase')})
            </Typography>
          )}
          {isSentInvitation && (
            <Tooltip title={t<string>('documentUserManager.sentInvitationTooltip')}>
              <Chip className={classes.sentChip} size="small" label={t<string>('common.sent')} />
            </Tooltip>
          )}
          {isNewInvitation && (
            <Tooltip title={t<string>('documentUserManager.newInvitationTooltip')}>
              <Chip className={classes.pendingChip} size="small" label={t<string>('common.new')} />
            </Tooltip>
          )}
          {isCurrentHolder && (
            <Tooltip title={t<string>('documentUserManager.currentOwnerTooltip')}>
              <Chip
                className={classes.sentChip}
                size="small"
                label={t<string>('documentUserManager.currentOwner')}
              />
            </Tooltip>
          )}
        </>
      )}
    </div>
  );
};
export default UserItemTitle;
