import typography from '../typography';
import palette from '../palette';

export default {
  root: {
    display: 'flex',
    alignItems: 'center',
    color: `${palette.text.primary} !important`,
    fontSize: typography.htmlFontSize,
    lineHeight: 'inherit',
    height: 'auto',
    minHeight: 48,
    outline: 0,
  },
  gutters: {
    paddingLeft: 12,
    paddingRight: 12,
  },
  sticky: {
    backgroundColor: palette.background.paper,
  },
};
