import React from 'react';
import { Button, useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Papa from 'papaparse';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import certificateCreatorActions from 'src/redux/actions/certificateCreator.actions';
import { CertificateSteps } from 'src/models/certificate.model';
import { Delete, Download, Preview, Publish } from 'src/components/CustomIcon';
import { useStyles } from '../styles';

const ActionButtons = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { selectedDraftImportedData, draftImportedData, templateDetails } = useSelector(
    (state) => state.certificateCreatorStore,
    undefined
  );

  const smallDevice = useMediaQuery(({ breakpoints }) => breakpoints.down('sm'));

  const onFileAdd = (files) => {
    Papa.parse(files, {
      newline: '\n',
      complete: (results) => {
        dispatch(
          certificateCreatorActions.setRawImportedData({
            importedData: results.data.map((rows) => rows.map((el) => el.replace('\r', ''))),
          })
        );
        dispatch(
          certificateCreatorActions.setCreatorStep({
            currentStep: CertificateSteps.MapMultipleData,
          })
        );
      },
    });
  };

  const onPreviewClick = () =>
    dispatch(
      certificateCreatorActions.setCreatorStep({
        currentStep: CertificateSteps.TemplatePreview,
      })
    );

  const parseToCSV = () => {
    if (templateDetails) {
      const headers = Object.keys(templateDetails.fields);
      const hasDraftData = draftImportedData.length > 0;
      const maxNrOfFiles = hasDraftData
        ? Math.max(...draftImportedData.map((el) => el.attached_files.length))
        : 0;
      if (hasDraftData && maxNrOfFiles > 0) headers.push(...Array(maxNrOfFiles - 1).fill(''));
      const body = draftImportedData.map((el) => {
        const result = [];

        Object.keys(templateDetails.fields).forEach((key) => {
          if (Object.prototype.toString.call(el[key]) === '[object Date]') {
            result.push(moment(el[key]).format('yyyy-MM-DD'));
          } else if (Array.isArray(el[key])) {
            result.push(...el[key].map((el) => el.base64));
          } else {
            result.push(el[key]);
          }
        });
        return result;
      });
      const csv = Papa.unparse([headers, ...body]);
      const blob = new Blob([csv], { type: 'text/csv' });
      const filename = 'UserExport.csv';
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = filename;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  const onDeleteSelected = () =>
    dispatch(certificateCreatorActions.deleteSelectedDraftImportedData());

  return (
    <div className={classes.actionButtonsWrapper}>
      <Button
        htmlFor="csv_upload"
        component="label"
        className={classes.actionButton}
        startIcon={smallDevice ? undefined : <Publish />}
      >
        {smallDevice
          ? t('createCertificate.importFromCSVShort')
          : t('createCertificate.importFromCSV')}
      </Button>
      <input
        type="file"
        id="csv_upload"
        accept="text/csv"
        onChange={(e) => onFileAdd(e.target.files[0])}
        className={classes.fileInput}
      />
      <Button
        className={classes.actionButton}
        color="secondary"
        onClick={onPreviewClick}
        startIcon={smallDevice ? null : <Preview />}
      >
        {smallDevice ? <Preview /> : t('createCertificate.templatePreview')}
      </Button>
      <Button
        className={classes.buttonText}
        onClick={parseToCSV}
        variant="text"
        startIcon={smallDevice ? null : <Download />}
      >
        {smallDevice ? <Download /> : t('createCertificate.downloadPreformattedCSV')}
      </Button>
      <Button
        onClick={onDeleteSelected}
        disabled={selectedDraftImportedData.length === 0}
        endIcon={smallDevice ? null : <Delete />}
        className={classes.actionButtonRight}
        variant="text"
      >
        {smallDevice ? <Delete /> : t('createCertificate.delete')}
      </Button>
    </div>
  );
};

export default ActionButtons;
