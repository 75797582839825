import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ spacing, palette }) =>
    createStyles({
      menuFooter: {
        display: 'flex',
        alignItems: 'center',
        padding: 8,
        justifyContent: 'space-between',
      },
      itemsWrapper: {
        minWidth: 350,
        maxHeight: 250,
        overflowY: 'scroll',
      },
      searchInput: {
        padding: '6px 12px',
        width: '100%',
        '& div:before, div:after': {
          display: 'none',
        },
      },
      textWrapper: {
        paddingLeft: spacing(2),
        flex: 1,
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        marginRight: 'auto',
        '& .highlight': {
          fontWeight: 700,
          color: palette.primary.main,
          textDecoration: 'underline',
        },
      },
      listCheckbox: {
        marginRight: -4,
      },
      avatarCheckboxWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 34,
      },
    }),
  { index: 0 }
);
