import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { AddAndRemoveUsers, ConfirmDialog } from 'src/components';
import { useDispatch, useSelector } from 'react-redux';
import workspaceGroupsActions from 'src/redux/actions/workspaceGroups.actions';
import identityActions from 'src/redux/actions/identity.actions';
import { RequestStatus } from 'src/helpers/reduxReuquest.util';

const ManageGroupUsers = ({ group, availableMembers, workspaceId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { groupId, name } = group;
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [didMount, setDidMount] = useState(false);
  const [activeIdentityId, setActiveIdentityId] = useState('');
  const { groupMembers } = useSelector((state) => state.workspaceGroupsStore, undefined);

  useEffect(() => {
    setDidMount(true);
    return () => {
      setDidMount(false);
      setActiveIdentityId('');
    };
  }, []);

  useEffect(() => {
    if (groupId) {
      dispatch(workspaceGroupsActions.fetchWorkspaceGroupMembers(groupId, 0, 100));
    }
  }, [dispatch, groupId]);

  const { fetchGroupStructureStatus } = useSelector((state) => state.workspaceGroupsStore);
  const addMembersToGroupStatus = useSelector(
    (state) => state.workspaceGroupsStore.addMembersToWorkspaceGroupStatus
  );
  const deleteMembersFromGroupStatus = useSelector(
    (state) => state.workspaceGroupsStore.deleteMembersFromWorkspaceGroupStatus
  );
  const workspaceGroups = useSelector((state) => state.workspaceGroupsStore);
  const members = useMemo(() => workspaceGroups?.groupMembers || [], [
    workspaceGroups?.groupMembers,
  ]);

  const fetchGroupStructureStatusPending = useMemo(
    () => fetchGroupStructureStatus === RequestStatus.PENDING,
    [fetchGroupStructureStatus]
  );
  const isPending = useMemo(
    () =>
      fetchGroupStructureStatusPending ||
      deleteMembersFromGroupStatus === RequestStatus.PENDING ||
      addMembersToGroupStatus === RequestStatus.PENDING,
    [addMembersToGroupStatus, deleteMembersFromGroupStatus, fetchGroupStructureStatusPending]
  );

  const clearInput = useMemo(() => addMembersToGroupStatus === RequestStatus.PENDING, [
    addMembersToGroupStatus,
  ]);

  const confirmDialogToggle = {
    open: (identityId) => {
      setActiveIdentityId(identityId);
      setConfirmDialogOpen(true);
    },
    close: () => {
      setConfirmDialogOpen(false);
    },
  };

  useEffect(() => {
    if (fetchGroupStructureStatus === RequestStatus.SUCCESS && groupId && workspaceId && didMount) {
      dispatch(identityActions.updateMembersWorkspaceGroup(groupId, members));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchGroupStructureStatus]);

  const memberActions = {
    add: () => {
      dispatch(workspaceGroupsActions.addMembersToWorkspaceGroup(groupId, [activeIdentityId]));
      confirmDialogToggle.close();
    },
    delete: (identityId) => {
      dispatch(workspaceGroupsActions.deleteMembersFromWorkspaceGroup(groupId, [identityId]));
      setActiveIdentityId('');
    },
  };

  return (
    <>
      <AddAndRemoveUsers
        isSmall
        items={
          fetchGroupStructureStatusPending
            ? []
            : members.map((member) => {
                member.hideRemove = name === 'Owners' && groupMembers.length <= 1;
                return member;
              }) || []
        }
        disabled={isPending}
        isPending={isPending}
        clearInput={clearInput}
        availableItems={
          availableMembers.filter(
            (user) => members?.map((e) => e.identityId).indexOf(user.identityId) === -1
          ) || []
        }
        placeholder={t('workspacePage.groups.manageUsers.inputPlaceholder')}
        noResultsText={t('workspacePage.groups.manageUsers.noResults')}
        onAddClick={confirmDialogToggle.open}
        onDeleteClick={memberActions.delete}
      />
      <ConfirmDialog
        variant="information"
        open={confirmDialogOpen}
        subtitle={t('workspacePage.groups.manageUsers.confirmDescription')}
        applyButtonText={t('common.yes')}
        cancelButtonText={t('common.no')}
        actionAccept={memberActions.add}
        actionCancel={confirmDialogToggle.close}
      />
    </>
  );
};

ManageGroupUsers.propTypes = {
  group: PropTypes.shape({
    groupId: PropTypes.string,
    name: PropTypes.string,
    membersAmount: PropTypes.number,
  }),
  workspaceId: PropTypes.string,
  availableMembers: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string,
      identityId: PropTypes.string,
      name: PropTypes.string,
    })
  ),
};

export default ManageGroupUsers;
