import { Box, Button, Modal, Typography, useMediaQuery } from '@material-ui/core';
import { bool, func } from 'prop-types';

import React from 'react';

import { useTranslation } from 'react-i18next';

import { useStyles } from './styles';

const ConfirmPublicAccessModal = ({ callback, open, handleClose }) => {
  const { t } = useTranslation();

  const classes = useStyles();

  const handleSubmit = () => {
    handleClose();
    callback();
  };

  const smallScreen = useMediaQuery(({ breakpoints }) => breakpoints.down('sm'));

  return (
    <Modal open={open} onClose={handleClose}>
      <Box className={classes.modal}>
        <Typography variant={smallScreen ? 'h3' : 'h2'} className={classes.header}>
          {t('inviteToDocument.section.advancedSettings.modal.header')}
        </Typography>
        <Typography
          id="modal-modal-description"
          sx={{ mt: 2 }}
          className={classes.message}
          variant="body1"
        >
          {t('inviteToDocument.section.advancedSettings.modal.text')}
        </Typography>
        <Typography
          id="modal-modal-description"
          sx={{ mt: 2 }}
          className={classes.message}
          variant="body1"
        >
          {t('inviteToDocument.section.advancedSettings.modal.confirmation')}
        </Typography>

        <div>
          <Button
            size="large"
            type="button"
            fullWidth
            className={classes.button}
            onClick={handleSubmit}
          >
            {t('inviteToDocument.section.advancedSettings.modal.submit')}
          </Button>
          <Button size="large" type="button" fullWidth variant="text" onClick={handleClose}>
            {t('common.cancel')}
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

ConfirmPublicAccessModal.propTypes = {
  callback: func,
  handleClose: func,
  open: bool,
};

export default ConfirmPublicAccessModal;
