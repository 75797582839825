import i18n from 'src/helpers/i18n';
import { SNACKBAR_VARIANT } from 'src/models/common.model';
import { DocumentRole } from 'src/models/document.model';
import certificateManagementConstants from 'src/redux/constants/certificateManagement.constants';
import documentDSLServices, { moduleName } from 'src/redux/services/documentDSL.service';
import documentDisplayActions from './documentDisplay.actions';
import identityActions from './identity.actions';
import snackbarActions from './snackbar.actions';
import { DocumentNoDriveType, userComActions } from './userCom.actions';

const certificateManagementActions = {
  deleteRolesByMail,
  invalidateCertificate,
  invalidateTemporarilyCertificate,
  rejectCertificate,
  setCertificateAccessLevel,
  setCertificateTransferability,
  signCertificate,
  transferOwnership,
  validateCertificate,
};

const genericErrorHelper = (msg) =>
  snackbarActions.enqueueSnackbar(
    SNACKBAR_VARIANT.ERROR,
    i18n.t('snackbars.common.errorTitle'),
    msg
  );

function signCertificate({ documentId, workspaceId, callback }) {
  return (dispatch) => {
    dispatch({ type: certificateManagementConstants.DOCUMENT_SIGN_REQUEST });

    documentDSLServices
      .signCertificate({ workspaceId, documentId })
      .then((response) => {
        dispatch({
          type: certificateManagementConstants.DOCUMENT_SIGN_SUCCESS,
          data: response.data,
        });

        if (response.data.data.txn) {
          dispatch(
            identityActions.sendTransactionFromNewDocument(response.data.data.txn, () => {
              userComActions.document.docSigned(DocumentNoDriveType.Certificate, workspaceId);
            })
          );

          dispatch(
            snackbarActions.enqueueSnackbar(
              SNACKBAR_VARIANT.INFO,
              i18n.t('snackbars.common.infoTitle'),
              i18n.t('documentActions.signDocument.toast.commissioned')
            )
          );
        }

        if (callback && typeof callback === 'function') {
          dispatch(callback);
        }
      })
      .catch((error) => {
        dispatch({ type: certificateManagementConstants.DOCUMENT_SIGN_ERROR });
        dispatch(
          snackbarActions.enqueueSnackbar(
            SNACKBAR_VARIANT.ERROR,
            i18n.t('snackbars.common.errorTitle'),
            `${i18n.t('snackbars.common.error')} ${error.code}.`
          )
        );
      });
  };
}

function rejectCertificate({ documentId, workspaceId, reason, callback }) {
  return (dispatch) => {
    dispatch({ type: certificateManagementConstants.DOCUMENT_REJECT_REQUEST });

    documentDSLServices
      .rejectCertificate({ workspaceId, documentId, reason })
      .then((response) => {
        dispatch({
          type: certificateManagementConstants.DOCUMENT_REJECT_SUCCESS,
          data: response.data,
        });

        if (response.data.data.txn) {
          dispatch(
            identityActions.sendTransactionFromNewDocument(response.data.data.txn, () => {
              userComActions.document.docRejected(DocumentNoDriveType.Certificate, workspaceId);
            })
          );

          dispatch(
            snackbarActions.enqueueSnackbar(
              SNACKBAR_VARIANT.INFO,
              i18n.t('snackbars.common.infoTitle'),
              i18n.t('documentActions.rejectDocument.toast.commissioned')
            )
          );
        }

        if (callback && typeof callback === 'function') {
          dispatch(callback);
        }
      })
      .catch((error) => {
        dispatch({ type: certificateManagementConstants.DOCUMENT_REJECT_ERROR });
        dispatch(
          snackbarActions.enqueueSnackbar(
            SNACKBAR_VARIANT.ERROR,
            i18n.t('snackbars.common.errorTitle'),
            `${i18n.t('snackbars.common.error')} ${error.code}.`
          )
        );
      });
  };
}

function invalidateCertificate(documentId, workspaceId, reason, callback) {
  return (dispatch) => {
    dispatch({ type: certificateManagementConstants.DOCUMENT_INVALIDATE_REQUEST });

    documentDSLServices
      .invalidateCertificate({ workspaceId, documentId, reason })
      .then((response) => {
        dispatch({
          type: certificateManagementConstants.DOCUMENT_INVALIDATE_SUCCESS,
          data: response.data,
        });

        if (response.data.data.txn) {
          dispatch(identityActions.sendTransactionFromNewDocument(response.data.data.txn));

          dispatch(
            snackbarActions.enqueueSnackbar(
              SNACKBAR_VARIANT.INFO,
              i18n.t('snackbars.common.infoTitle'),
              i18n.t('documentActions.invalidateDocument.toast.commissioned')
            )
          );
        }

        if (callback && typeof callback === 'function') {
          dispatch(callback);
        }
      })
      .catch((error) => {
        dispatch({ type: certificateManagementConstants.DOCUMENT_SIGN_ERROR });
        dispatch(
          snackbarActions.enqueueSnackbar(
            SNACKBAR_VARIANT.ERROR,
            i18n.t('snackbars.common.errorTitle'),
            `${i18n.t('snackbars.common.error')} ${error.code}.`
          )
        );
      });
  };
}

function invalidateTemporarilyCertificate(documentId, workspaceId, reason, callback) {
  return (dispatch) => {
    dispatch({ type: certificateManagementConstants.DOCUMENT_INVALIDATE_TEMPORARILY_REQUEST });

    documentDSLServices
      .invalidateTemporarilyCertificate({ workspaceId, documentId, reason })
      .then((response) => {
        dispatch({
          type: certificateManagementConstants.DOCUMENT_INVALIDATE_TEMPORARILY_SUCCESS,
          data: response.data,
        });

        if (response.data.data.txn) {
          dispatch(identityActions.sendTransactionFromNewDocument(response.data.data.txn));

          dispatch(
            snackbarActions.enqueueSnackbar(
              SNACKBAR_VARIANT.INFO,
              i18n.t('snackbars.common.infoTitle'),
              i18n.t('documentActions.temporarilyInvalidateDocument.toast.commissioned')
            )
          );
        }

        if (callback && typeof callback === 'function') {
          dispatch(callback);
        }
      })
      .catch((error) => {
        dispatch({ type: certificateManagementConstants.DOCUMENT_INVALIDATE_TEMPORARILY_ERROR });
        dispatch(
          snackbarActions.enqueueSnackbar(
            SNACKBAR_VARIANT.ERROR,
            i18n.t('snackbars.common.errorTitle'),
            `${i18n.t('snackbars.common.error')} ${error.code}.`
          )
        );
      });
  };
}

function validateCertificate(documentId, workspaceId, reason, callback = () => undefined) {
  return (dispatch) => {
    dispatch({ type: certificateManagementConstants.DOCUMENT_VALIDATE_REQUEST });

    documentDSLServices
      .validateCertificate({ workspaceId, documentId, reason })
      .then((response) => {
        dispatch({
          type: certificateManagementConstants.DOCUMENT_VALIDATE_SUCCESS,
          data: response.data,
        });

        if (response.data.data.txn) {
          dispatch(identityActions.sendTransactionFromNewDocument(response.data.data.txn));

          dispatch(
            snackbarActions.enqueueSnackbar(
              SNACKBAR_VARIANT.INFO,
              i18n.t('snackbars.common.infoTitle'),
              i18n.t('documentActions.validateDocument.toast.commissioned')
            )
          );
        }

        if (callback && typeof callback === 'function') {
          dispatch(callback);
        }
      })
      .catch((error) => {
        dispatch({ type: certificateManagementConstants.DOCUMENT_VALIDATE_ERROR });
        dispatch(
          snackbarActions.enqueueSnackbar(
            SNACKBAR_VARIANT.ERROR,
            i18n.t('snackbars.common.errorTitle'),
            `${i18n.t('snackbars.common.error')} ${error.code}.`
          )
        );
      });
  };
}

function setCertificateTransferability(documentId, workspaceId, transferable, callback) {
  return (dispatch) => {
    dispatch({ type: certificateManagementConstants.DOCUMENT_SET_TRANSFERABILITY_REQUEST });

    documentDSLServices
      .setTransferabilityCertificate({ workspaceId, documentId, transferable })
      .then((response) => {
        dispatch({
          type: certificateManagementConstants.DOCUMENT_SET_TRANSFERABILITY_SUCCESS,
          data: response.data,
        });

        if (response.data.data.txn) {
          dispatch(identityActions.sendTransactionFromNewDocument(response.data.data.txn));

          dispatch(
            snackbarActions.enqueueSnackbar(
              SNACKBAR_VARIANT.INFO,
              i18n.t('snackbars.common.infoTitle'),
              i18n.t('documentActions.transferabilityChange.toast.commissioned')
            )
          );
        }

        if (callback && typeof callback === 'function') {
          dispatch(callback);
        }
      })
      .catch((error) => {
        dispatch({ type: certificateManagementConstants.DOCUMENT_INVALIDATE_TEMPORARILY_ERROR });
        dispatch(
          snackbarActions.enqueueSnackbar(
            SNACKBAR_VARIANT.ERROR,
            i18n.t('snackbars.common.errorTitle'),
            `${i18n.t('snackbars.common.error')} ${error.code}.`
          )
        );
      });
  };
}

function setCertificateAccessLevel(documentId, isPublic, callback) {
  return (dispatch) => {
    dispatch({ type: certificateManagementConstants.DOCUMENT_SET_ACCESS_LEVEL_REQUEST });

    documentDSLServices
      .setCertificateAccessLevel({ documentId, isPublic })
      .then((response) => {
        dispatch({
          type: certificateManagementConstants.DOCUMENT_SET_ACCESS_LEVEL_SUCCESS,
          data: response.data,
        });

        dispatch(
          snackbarActions.enqueueSnackbar(
            SNACKBAR_VARIANT.SUCCESS,
            i18n.t('snackbars.common.successTitle'),
            i18n.t('documentActions.visibilityChange.toast.success')
          )
        );

        dispatch(documentDisplayActions.getDocument(documentId, moduleName));
        if (callback && typeof callback === 'function') {
          dispatch(callback);
        }
      })
      .catch(() => {
        dispatch(
          snackbarActions.enqueueSnackbar(
            SNACKBAR_VARIANT.ERROR,
            i18n.t('snackbars.common.infoTitle'),
            i18n.t('documentActions.visibilityChange.toast.error')
          )
        );
        dispatch({ type: certificateManagementConstants.DOCUMENT_SET_ACCESS_LEVEL_ERROR });
      });
  };
}

function deleteRolesByMail(documentId, users) {
  const deletionPromises = users.map((user) =>
    documentDSLServices.deleteRoleByMail(documentId, user?.role || DocumentRole.SIGNERS, user.email)
  );

  return (dispatch) => {
    dispatch({ type: certificateManagementConstants.DELETE_USERS_MAIL_INVITATION_REQUEST });
    Promise.allSettled(deletionPromises)
      .then((response) => {
        dispatch({
          type: certificateManagementConstants.DELETE_USERS_MAIL_INVITATION_SUCCESS,
          response,
        });
        const fulfilledPromises = response.filter((result) => result.status === 'fulfilled');
        const rejectedPromises = response.filter((result) => result.status === 'rejected');

        if (fulfilledPromises?.length === 0 && rejectedPromises?.length > 0) {
          dispatch({ type: certificateManagementConstants.INVITE_USERS_BY_MAIL_ERROR });
          dispatch(genericErrorHelper(i18n.t('inviteToDocument.deleteInvitationEmailsError')));
        }
        fulfilledPromises
          .map((c) => c.value.data.data.collecton)
          .flat()
          .forEach((c) => {
            if (c?.txn) {
              dispatch(identityActions.sendTransactionFromNewDocument(c.txn));
            }
          });
      })
      .catch(() => {
        dispatch({ type: certificateManagementConstants.INVITE_USERS_BY_MAIL_ERROR });
        dispatch(genericErrorHelper(i18n.t('inviteToDocument.deleteInvitationEmailsError')));
      });
  };
}

function transferOwnership(documentId, role, email, callback) {
  return (dispatch) => {
    dispatch({ type: certificateManagementConstants.TRANSFER_OWNERSHIP_REQUEST });

    documentDSLServices
      .transferOwnership(documentId, role, email)
      .then((response) => {
        dispatch({
          type: certificateManagementConstants.TRANSFER_OWNERSHIP_SUCCESS,
          data: response.data,
        });
        if (response.data?.data?.collection?.length > 0) {
          response.data.data?.collection?.forEach((c) => {
            if (c.txn) {
              dispatch(identityActions.sendTransactionFromNewDocument(c.txn));
            }
          });

          dispatch(
            snackbarActions.enqueueSnackbar(
              SNACKBAR_VARIANT.SUCCESS,
              i18n.t('snackbars.common.infoTitle'),
              i18n.t('documentActions.transferOwnership.toast.success')
            )
          );
        }
        if (callback && typeof callback === 'function') {
          dispatch(callback);
        }
      })
      .catch(() => {
        dispatch(
          snackbarActions.enqueueSnackbar(
            SNACKBAR_VARIANT.ERROR,
            i18n.t('snackbars.common.infoTitle'),
            i18n.t('documentActions.transferOwnership.toast.error')
          )
        );
        dispatch({ type: certificateManagementConstants.TRANSFER_OWNERSHIP_ERROR });
      });
  };
}

export default certificateManagementActions;
