import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import communicationsActions from 'src/redux/actions/communications.actions';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  ListItem,
  Typography,
} from '@material-ui/core';
import { ExtendedGrid } from 'src/components';
import { RequestStatus } from 'src/helpers/reduxReuquest.util';
import { useStyles } from './styles';

const NotificationItem = ({
  className: classNameProp,
  item,
  services,
  alignItems,
  subItem,
  smallScreen,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { notificationTemplate } = item;

  const channelUpdatingStatus = useSelector(
    (state) => state.communicationsStore.updateChannelsStatuses[notificationTemplate]
  );

  const handleChange = (event, service) => {
    const newProperties = {
      notificationTemplate,
      email: service.type === 'email' ? event.target.checked : item.email,
      sms: service.type === 'sms' ? event.target.checked : item.sms,
      systemMessage: service.type === 'systemMessage' ? event.target.checked : item.systemMessage,
    };

    dispatch(
      communicationsActions.updateNotificationChannel(newProperties, service.type, {
        ...item,
        ...newProperties,
      })
    );
  };

  const isUpdatePending = channelUpdatingStatus === RequestStatus.PENDING;

  return (
    <ListItem
      disableGutters
      classes={{
        root: clsx(
          classes.root,
          subItem && classes.withSubtitle,
          isUpdatePending && classes.updating,
          classNameProp
        ),
      }}
    >
      <ExtendedGrid
        container
        spacingX={2}
        spacingY={smallScreen ? 0 : 2}
        alignItemsXxs={alignItems}
      >
        <ExtendedGrid item xxs={12} md={4}>
          <Typography className={classes.title} color="textPrimary">
            <strong>
              {t(`userConfigurationPage.notifications.channels.${notificationTemplate}`)}
            </strong>
          </Typography>
        </ExtendedGrid>

        {services.map((service, index) => (
          <ExtendedGrid key={index} item xxs={12} sm textAlignXxs="left" textAlignMd="center">
            <FormControl>
              <FormGroup>
                <FormControlLabel
                  className={classes.label}
                  control={
                    <Checkbox
                      checked={item[service.type]}
                      disabled={!item[`${service.type}CanBeChanged`]}
                      disableRipple
                      onChange={(event) => handleChange(event, service)}
                      name={`${item.notificationTemplate.toLowerCase()}__${service.type}-checkbox`}
                    />
                  }
                  label={
                    smallScreen ? <Typography component="span">{service.title}</Typography> : null
                  }
                />
              </FormGroup>
            </FormControl>
          </ExtendedGrid>
        ))}
      </ExtendedGrid>
    </ListItem>
  );
};

NotificationItem.propTypes = {
  className: PropTypes.string,
  item: PropTypes.shape({
    createdAt: PropTypes.number,
    updatedAt: PropTypes.number,
    email: PropTypes.bool,
    sms: PropTypes.bool,
    smsCanBeChanged: PropTypes.bool,
    systemMessage: PropTypes.bool,
    systemMessageCanBeChanged: PropTypes.bool,
    notificationTemplate: PropTypes.string,
  }),
  services: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  subItem: PropTypes.bool,
  alignItems: PropTypes.oneOf(['left', 'center', 'right']),
  smallScreen: PropTypes.bool,
};

NotificationItem.defaultProps = {
  subItem: false,
  alignItems: 'center',
};

export default NotificationItem;
