import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  CircularProgress,
  createStyles,
  makeStyles,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { DataTable, SmallContentWithScrollbar } from 'src/components';
import { useDispatch, useSelector } from 'react-redux';
import { FileColsConfig } from 'src/helpers/fileAndFolderColsConfig';
import { useTranslation } from 'react-i18next';
import documentActions from 'src/redux/actions/documents.actions';
import { RequestStatus } from 'src/helpers/reduxReuquest.util';

const useStyles = makeStyles(
  ({ spacing }) =>
    createStyles({
      content: {
        marginBottom: spacing(2),
      },
      buttonBox: {
        display: 'flex',
        justifyContent: 'center',
      },
    }),
  { index: 0 }
);

const DeleteUser = ({ handleDeleteUser, repeatCheckDocumentsUser }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const smallScreen = useMediaQuery(({ breakpoints }) => breakpoints.down('sm'));
  const [didMount, setDidMount] = useState(false);
  const [filesColConfig, setFilesColConfig] = useState(false);
  const { fetchDocumentsStatus, documents } = useSelector((state) => state.documentsStore);
  const { deleteUserFromWorkspaceStatus } = useSelector((state) => state.workspaceUserStore);
  const hasAnyDocument = documents?.length > 0;
  const selectedFilesRows = [];
  const sortKey = 'createdAt';
  const sortOrder = 'desc';
  const iconWidth = 40;
  const isLoadingFiles = fetchDocumentsStatus === RequestStatus.PENDING;
  const isPending =
    deleteUserFromWorkspaceStatus === RequestStatus.PENDING || repeatCheckDocumentsUser;

  useEffect(() => {
    setDidMount(true);
    return () => {
      setDidMount(false);
      dispatch(documentActions.clearDocumentsStore());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (didMount) {
      setFilesColConfig(FileColsConfig(t, true, true, true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [didMount]);

  const deleteUserItemtyList = () => {
    handleDeleteUser(true);
  };

  const getDocTypePath = (row) => {
    if (row.documentType.includes('dsl')) return 'certificate';
    return 'document';
  };

  return (
    <SmallContentWithScrollbar
      size="big"
      startContent={<Typography>{t('workspacePage.users.checkDelete.description')}</Typography>}
      endContent={
        <div className={classes.buttonBox}>
          <Button
            size="large"
            onClick={deleteUserItemtyList}
            disabled={isPending}
            endIcon={isPending && <CircularProgress size={20} color="inherit" />}
          >
            {t('workspacePage.users.checkDelete.btn')}
          </Button>
        </div>
      }
      classes={{
        content: classes.content,
      }}
    >
      {hasAnyDocument && filesColConfig && (
        <DataTable
          rows={documents}
          cols={filesColConfig}
          title={t('drivePage.documentsTable.title')}
          iconWidth={iconWidth}
          withTableHeader
          headerTextWidthLikeIcon={false}
          onClick={(row) => {
            if (!smallScreen) {
              const docTypePath = getDocTypePath(row);
              window.open(`/${docTypePath}/${row.id}`, '_blank');
            }
          }}
          onDblClick={(row) => {
            if (!smallScreen) {
              const docTypePath = getDocTypePath(row);
              window.open(`/${docTypePath}/${row.id}`, '_blank');
            }
          }}
          selectedRows={selectedFilesRows}
          sortBy={sortKey}
          sortOrder={sortOrder}
          isLoading={isLoadingFiles}
          paginationType="infinite"
          defaultIcon="file"
          smallScreen={smallScreen}
          disableHeader={smallScreen}
          noItems={{
            label: t('drivePage.documentsTable.noItems'),
          }}
        />
      )}
    </SmallContentWithScrollbar>
  );
};

DeleteUser.propTypes = {
  repeatCheckDocumentsUser: PropTypes.bool,
  handleDeleteUser: PropTypes.func,
};

export default DeleteUser;
