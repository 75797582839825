import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ConfirmDialog } from 'src/components';

const ReloadDocument = ({ open, actionAccept, onClose }) => {
  const { t } = useTranslation();

  return (
    <ConfirmDialog
      open={open}
      variant="primary"
      title={t('documentActions.reloadDocument.header')}
      subtitle={t('documentActions.reloadDocument.description')}
      applyButtonText={t('documentActions.reloadDocument.apply')}
      cancelButtonText={t('common.cancel')}
      actionAccept={actionAccept}
      actionCancel={onClose}
    />
  );
};

ReloadDocument.propTypes = {
  open: PropTypes.bool,
  actionAccept: PropTypes.func,
  onClose: PropTypes.func,
};

ReloadDocument.defaultProps = {
  open: false,
};

export default ReloadDocument;
