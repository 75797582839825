import {
  Layers as BasicIcon,
  Notification as NotificationsIcon,
  Shield as SecurityIcon,
  User as UserIcon,
  Key as KeyIcon,
} from 'src/components/CustomIcon';
import React from 'react';

export const Links = (t) => [
  {
    label: t('userConfigurationPage.basic.header'),
    url: '/user-settings/basic',
    icon: <BasicIcon />,
  },
  {
    label: t('userConfigurationPage.identity.header'),
    url: '/user-settings/identity',
    icon: <UserIcon />,
  },
  {
    label: t('userConfigurationPage.security.header'),
    url: '/user-settings/security',
    icon: <SecurityIcon />,
  },
  {
    label: t('userConfigurationPage.notifications.header'),
    url: '/user-settings/notifications',
    icon: <NotificationsIcon />,
  },
  {
    label: t('userConfigurationPage.apiKeys.apiKeysTitle'),
    url: '/user-settings/api-keys',
    icon: <KeyIcon />,
  },
];

export default Links;
