import React, { useEffect } from 'react';
import identityActions from 'src/redux/actions/identity.actions';
import OutsideClickHandler from 'react-outside-click-handler';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IdentitySelector } from 'src/redux/selectors/identity.selector';
import { Avatar, Divider, ListItemIcon, MenuItem, Typography } from '@material-ui/core';
import { getColorFromText, getInitials } from 'src/helpers/globalUtilsTS';
import { lighten } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import {
  Plus as PlusIcon,
  Settings as SettingsIcon,
  Users as UsersIcon,
  Wallet,
} from 'src/components/CustomIcon';
import { UnfoldLessOutlined, UnfoldMoreOutlined } from '@material-ui/icons';
import { CreateWorkspace } from 'src/components';
import { useStyles } from './styles';
import { RootState } from '../../../../redux/reducers';
import avatarActions from '../../../../redux/actions/avatar.actions';

const SidebarWorkspaceSwitch = (): JSX.Element | null => {
  const { t } = useTranslation();
  const [showWorkspaceDropdown, setShowWorkspaceDropdown] = React.useState(false);
  const [createWorkspaceDialogOpen, setCreateWorkspaceDialogOpen] = React.useState(false);
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const availableWorkspaces = useSelector(IdentitySelector.selectAvailableWorkspaces, undefined);
  const currentWorkspace = useSelector(IdentitySelector.selectWorkspaceDetails, undefined);
  const { workspaceAvatar } = useSelector((state: RootState) => state.avatarStore);
  const { isOwner } = useSelector((state: RootState) => state.identityStore, undefined);
  const currentIdentity = useSelector((state: RootState) => state.identityStore.currentIdentity);
  const workspaceId = useSelector(IdentitySelector.selectWorkspaceId);
  const changeWorkspace = (id: string) => {
    dispatch(
      identityActions.switchWorkspace(id, () => {
        history.push('/');

        return undefined;
      })
    );
    closeDropdown();
  };

  const goToSettings = () => {
    history.push(`/workspace-settings/basic/settings`);
    closeDropdown();
  };
  const goToUsers = () => {
    history.push(`/workspace-settings/user-management/users`);
    closeDropdown();
  };
  const goToBilling = () => {
    history.push(`/workspace-settings/subscription/products`);
    closeDropdown();
  };
  const closeDropdown = () => setShowWorkspaceDropdown(false);
  const closeCreateWorkspaceDialog = () => setCreateWorkspaceDialogOpen(false);
  const openCreateWorkspaceDialog = () => {
    setCreateWorkspaceDialogOpen(true);
    closeDropdown();
  };

  useEffect(() => {
    if (currentIdentity) {
      dispatch(avatarActions.getWorkspaceAvatar(currentIdentity?.workspace?.workspaceId));
    }
  }, [currentIdentity, dispatch]);

  if (!currentWorkspace) return null;

  const initials = getInitials(currentWorkspace.name);
  const colorByInitials = getColorFromText(initials);
  return (
    <>
      <div className={classes.wrapper}>
        <div
          onClick={() => setShowWorkspaceDropdown(!showWorkspaceDropdown)}
          aria-hidden="true"
          className={clsx(
            classes.currentWorkspace,
            showWorkspaceDropdown && classes.currentWorkspaceOpen
          )}
        >
          <Avatar
            className={classes.mainAvatar}
            sizes="large"
            variant="rounded"
            src={
              currentIdentity && workspaceAvatar
                ? `${process.env.REACT_APP_AVATAR_URL}/workspace/${currentIdentity?.workspace?.workspaceId}?kind=SMALL`
                : undefined
            }
            style={{
              color: colorByInitials,
              backgroundColor: lighten(colorByInitials, 0.825),
            }}
          >
            {initials}
          </Avatar>
          <Typography
            className={clsx(
              classes.currentWorkspaceText,
              showWorkspaceDropdown && classes.currentWorkspaceTextOpen
            )}
            variant="h5"
          >
            {currentWorkspace.name}
            <div
              className={clsx(
                classes.arrowsIcon,
                showWorkspaceDropdown && classes.arrowIconOpen,
                'toggleButton'
              )}
            >
              {showWorkspaceDropdown ? <UnfoldLessOutlined /> : <UnfoldMoreOutlined />}
            </div>
          </Typography>
        </div>

        <div className={clsx(classes.content, showWorkspaceDropdown && classes.contentOpen)}>
          <OutsideClickHandler
            onOutsideClick={(e: MouseEvent) => {
              const target = e.target as HTMLButtonElement;
              const isButton = Boolean(target && target.getElementsByClassName('toggleButton')[0]);
              if (!isButton) closeDropdown();
            }}
          >
            <Divider />
            {isOwner && (
              <>
                <MenuItem className="small-item" divider={false} onClick={goToSettings}>
                  <ListItemIcon>
                    <SettingsIcon />
                  </ListItemIcon>
                  <Typography variant="body1" color="textPrimary">
                    {t<string>('sideBar.settings')}
                  </Typography>
                </MenuItem>
                <MenuItem className="small-item" divider={false} onClick={goToUsers}>
                  <ListItemIcon>
                    <UsersIcon />
                  </ListItemIcon>
                  <Typography variant="body1" color="textPrimary">
                    {t<string>('sideBar.members')}
                  </Typography>
                </MenuItem>
                <MenuItem className="small-item" divider={false} onClick={goToBilling}>
                  <ListItemIcon>
                    <Wallet />
                  </ListItemIcon>
                  <Typography variant="body1" color="textPrimary">
                    {t<string>('sideBar.billing')}
                  </Typography>
                </MenuItem>
                <Divider />
              </>
            )}
            <div className={classes.workspacesWrapper}>
              {availableWorkspaces
                .filter((workspace) => workspace.workspace.workspaceId !== workspaceId) // remove current workspace
                .map((workspace) => {
                  const text = getInitials(workspace.workspace.name);
                  const color = getColorFromText(text);
                  return (
                    <MenuItem
                      onClick={() => changeWorkspace(workspace.workspace.workspaceId)}
                      divider={false}
                    >
                      <Avatar
                        className={classes.avatar}
                        sizes="large"
                        variant="rounded"
                        src={`${process.env.REACT_APP_AVATAR_URL}/workspace/${workspace.workspace.workspaceId}?kind=SMALL`}
                        style={{
                          color,
                          backgroundColor: lighten(color, 0.825),
                        }}
                      >
                        {text}
                      </Avatar>
                      <Typography
                        className={classes.workspaceText}
                        variant="body1"
                        color="textPrimary"
                      >
                        {workspace.workspace.name}
                      </Typography>
                    </MenuItem>
                  );
                })}
            </div>
            <Divider />
            <MenuItem className="small-item" divider={false} onClick={openCreateWorkspaceDialog}>
              <ListItemIcon>
                <PlusIcon />
              </ListItemIcon>
              <Typography variant="body1" color="textPrimary">
                {t<string>('sideBar.newWorkspace')}
              </Typography>
            </MenuItem>
          </OutsideClickHandler>
        </div>
      </div>

      {createWorkspaceDialogOpen && (
        <CreateWorkspace isOpen handleClose={closeCreateWorkspaceDialog} />
      )}
    </>
  );
};

export default SidebarWorkspaceSwitch;
