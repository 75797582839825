export default {
  fontFamily: "'Inter', 'Arial', sans-serif",
  htmlFontSize: 14,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 600,
  h1: {
    fontFamily: "'Poppins', 'Inter', 'Arial', sans-serif",
    lineHeight: 1.5,
    color: 'inherit',
    letterSpacing: 0,
    fontSize: '2rem',
  },
  h2: {
    fontFamily: "'Poppins', 'Inter', 'Arial', sans-serif",
    lineHeight: 1.5,
    color: 'inherit',
    letterSpacing: 0,
    fontSize: '1.715rem',
  },
  h3: {
    fontFamily: "'Poppins', 'Inter', 'Arial', sans-serif",
    lineHeight: 1.5,
    color: 'inherit',
    letterSpacing: '0.01em',
    fontSize: '1.43rem',
  },
  h4: {
    fontFamily: "'Poppins', 'Inter', 'Arial', sans-serif",
    lineHeight: 1.5,
    color: 'inherit',
    letterSpacing: '0.01em',
    fontSize: '1.285rem',
  },
  h5: {
    fontFamily: "'Poppins', 'Inter', 'Arial', sans-serif",
    lineHeight: 1.5,
    color: 'inherit',
    letterSpacing: '0.01em',
    fontSize: '1.15rem',
  },
  h6: {
    fontFamily: "'Poppins', 'Inter', 'Arial', sans-serif",
    lineHeight: 1.5,
    color: 'inherit',
    letterSpacing: '0.01em',
    fontSize: '1rem',
  },
  body1: {
    fontWeight: 'inherit',
    fontFamily: 'inherit',
    lineHeight: 1.5,
    color: 'inherit',
    letterSpacing: '0.01em',
    fontSize: '1rem',
  },
  body2: {
    fontWeight: 'inherit',
    fontFamily: 'inherit',
    lineHeight: 1.6,
    color: 'inherit',
    letterSpacing: '0.012em',
    fontSize: 12,
  },
  caption: {
    fontWeight: 'inherit',
    fontFamily: 'inherit',
    lineHeight: 1.6,
    letterSpacing: '0.015em',
    fontSize: 10,
  },
  subtitle1: {
    fontWeight: 'inherit',
    fontFamily: 'inherit',
    lineHeight: 1.6,
    color: 'inherit',
    letterSpacing: '0.012em',
    fontSize: '1rem',
  },
  subtitle2: {
    fontWeight: 'inherit',
    fontFamily: 'inherit',
    lineHeight: 1.6,
    color: 'inherit',
    letterSpacing: '0.015em',
    fontSize: 12,
  },
  overline: {
    fontWeight: 'inherit',
    fontFamily: 'inherit',
    lineHeight: 1.5,
    fontSize: 12,
    textTransform: 'uppercase',
  },
  button: {
    textTransform: 'none',
    fontFamily: "'Poppins', 'Inter', 'Arial', sans-serif",
    color: 'inherit',
    fontSize: '1.0715rem',
  },
};
