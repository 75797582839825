import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ breakpoints, palette, shape, spacing }) =>
    createStyles({
      root: {
        borderRadius: shape.borderRadius,
        flexGrow: 1,
        width: '100%',
        backgroundColor: palette.background.default,
        [breakpoints.down('sm')]: {
          borderTop: `1px solid ${palette.divider}`,
          backgroundColor: palette.background.paper,
        },
      },
      tabsRoot: {
        padding: 0,
        minHeight: 48,
        [breakpoints.up('md')]: {
          minHeight: 48 + spacing(2),
          padding: spacing(1),
        },
      },
      tabsScroller: {
        [breakpoints.up('md')]: {
          margin: spacing(-1),
          padding: spacing(1),
        },
      },
      tabRoot: {
        paddingLeft: spacing(2),
        paddingRight: spacing(2),
        [breakpoints.down('xs')]: {
          minWidth: '25%',
        },
        [breakpoints.down('sm')]: {
          fontFamily: 'inherit',
          borderRadius: 0,
          '&$selectedTab': {
            boxShadow: 'none',
          },
        },
      },
      disabledTab: {
        display: 'none',
      },
      selectedTab: {},
    }),
  { index: 0 }
);
