import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ palette, spacing, transitions, typography }) =>
    createStyles({
      root: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'nowrap',
        width: '100%',
        padding: spacing(1.5, 0),
        position: 'relative',
        cursor: 'pointer',
        transition: transitions.create(['color'], {
          duration: transitions.duration.short,
        }),
        '&::before': {
          content: '""',
          height: '100%',
          width: '2px',
          position: 'absolute',
          top: '50%',
          left: 7,
          zIndex: 0,
        },
        '&:last-child': {
          '&::before': { display: 'none' },
        },
        '&.waitingColor': {
          '& .itemStepIndicator': {
            backgroundColor: palette.common.white,
            border: `2px solid ${palette.greySimple.main}`,
          },
          '&::before': { backgroundColor: palette.greySimple.main },
          '&:first-child': {
            '& .itemStepIndicator': {
              backgroundColor: palette.common.white,
              border: `2px solid ${palette.success.main}`,
            },
          },
        },

        '&.successColor': {
          '& .itemStepIndicator': {
            backgroundColor: palette.success.main,
            border: `2px solid ${palette.success.main}`,
          },
          '&::before': { backgroundColor: palette.success.main },
        },

        '&.defaultColor': {
          '& .itemStepIndicator': {
            backgroundColor: palette.common.white,
            border: `2px solid ${palette.greySimple.main}`,
          },
        },
        '&.errorColor': {
          '& .itemStepIndicator': {
            backgroundColor: palette.common.white,
            border: `2px solid ${palette.error.main}`,
          },
          '&::before': { backgroundColor: palette.greySimple.main },
        },
        '&:hover, &:focus': {
          color: palette.primary.main,
        },
        '&:first-child': {
          '& .waitingColor': {
            backgroundColor: palette.common.white,
            border: `2px solid ${palette.success.main}`,
          },
        },
      },
      deleteItem: {
        pointerEvents: 'none',
        '& [class*="UserAvatarAndTextItem-iconWrapper"]': {
          opacity: 0.5,
          filter: 'grayscale(50%)',
        },
        '& p, & span': {
          fontWeight: typography.fontWeightRegular,
          color: palette.text.disabled,
        },
      },
      user: {
        flex: 1,
        overflow: 'hidden',
      },
      tag: {
        marginLeft: spacing(1),
        minWidth: 100,
        textAlign: 'center',
        justifyContent: 'center',
      },
      tooltip: {
        display: 'flex',
        alignItems: 'center',
        '& > div': {
          display: 'inherit',
          alignItems: 'inherit',
          '&:not(:last-child)': {
            marginRight: spacing(1.5),
          },
        },
        '& svg': {
          width: 14,
          height: 14,
          marginRight: spacing(0.5),
        },
      },
      additionalUserText: {
        fontSize: typography.subtitle2.fontSize,
      },
      authorText: {
        color: palette.warning.main,
      },
      youText: {
        color: palette.text.secondary,
      },
      additionalInfo: {
        display: 'block',
        marginTop: spacing(0.5),
      },
      weightMedium: {
        fontWeight: typography.fontWeightMedium,
      },
      itemStep: {
        width: 16,
        height: 16,
        borderRadius: 16,
        marginRight: 9,
        zIndex: 1,
      },
    }),
  { index: 0 }
);
