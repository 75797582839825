import React, { createContext, useState } from 'react';
import { node } from 'prop-types';

const StepperContext = createContext({});

export const TRANSFER_OWNERSHIP_STEPS = {
  TRANSFER_OWNERSHIP: 'TRANSFER_OWNERSHIP',
  TRANSFER_OWNERSHIP_BY_EMAIL: 'TRANSFER_OWNERSHIP_BY_EMAIL',
};

const StepperContextProvider = ({ children }) => {
  const [currentStep, setCurrentStep] = useState(TRANSFER_OWNERSHIP_STEPS.TRANSFER_OWNERSHIP);

  return (
    <StepperContext.Provider value={{ currentStep, setCurrentStep }}>
      {children}
    </StepperContext.Provider>
  );
};

StepperContextProvider.propTypes = {
  children: node,
};

export { StepperContext, StepperContextProvider };
