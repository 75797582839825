import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ breakpoints, custom, palette, spacing }) =>
    createStyles({
      drawer: {
        position: 'relative',
        width: custom.documentEditSidebarWidth,
        maxWidth: '40%',
        flexShrink: 0,
        overflow: 'hidden',
      },
      drawerPaper: {
        position: 'relative',
        width: '100%',
        borderLeft: `1px solid ${palette.divider}`,
        overflowX: 'hidden',
        overflowY: 'auto',
        flex: 'auto',
      },
      drawerPaperNotScrollable: {
        overflowY: 'hidden',
      },
      contentWrapper: {
        position: 'relative',
        padding: spacing(2),
        paddingBottom: spacing(10),
        [breakpoints.up('md')]: {
          padding: spacing(2, 3),
          paddingBottom: spacing(10),
        },
        [breakpoints.up('lg')]: {
          padding: spacing(2, 5),
          paddingBottom: spacing(10),
        },
      },
      content: {
        width: '100%',
      },
      contentTop: {
        paddingBottom: spacing(2),
        [breakpoints.up('lg')]: {
          paddingBottom: spacing(3),
        },
      },
      contentBottom: {},
      childrenWrapper: {},
    }),
  { index: 0 }
);
