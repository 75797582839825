import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

export default createBreakpoints({
  keys: ['xs', 'sm', 'md', 'lg', 'xl'],
  values: {
    xs: 400,
    sm: 640,
    md: 960,
    lg: 1280,
    xl: 1520,
  },
});
