import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DocumentUserManagerSelector from 'src/redux/selectors/documentUserManager.selector';
import { DocumentMemberRole, UserManagerPerson } from 'src/models/documentUsersManagement.model';
import { useTranslation } from 'react-i18next';
import { EmailOutlined as EmailIcon } from '@material-ui/icons';
import { Download as DownloadIcon } from 'src/components/CustomIcon';
import { IdentitySelector } from 'src/redux/selectors/identity.selector';
import documentUserManagerActions from 'src/redux/actions/documentUserManager.actions';
import { RootState } from 'src/redux/reducers';
import { RequestStatus } from 'src/helpers/reduxReuquest.util';
import { Divider } from '@material-ui/core';
import { API_MODULE } from 'src/models/common.model';
import documentInvitiationActions from 'src/redux/actions/documentInvitiation.actions';
import Section from '../subcomponents/Section';
import ItemsWrapper from '../subcomponents/ItemsWrapper';
import UserItem from '../subcomponents/UserItem/UserItem';
import AddNewItem from '../subcomponents/UserItem/AddNewItem';
import InfoTooltip from '../subcomponents/InfoTooltip';
import SectionButton from '../subcomponents/SectionButton';
import { UsersManagerContext, UsersManagerContextType } from '../subcomponents/UsersManagerContext';
import UserItemSkeleton from '../subcomponents/UserItem/UserItemSkeleton';
import TransferOwnershipWebsocket from '../../../pages/TransferOwnership/components/steps/TransferOwnership/TransferOwnershipWebsocket';
import { useStyles } from './styles';

interface UserManagerHolderProps {
  documentId: string;
}

const UsersManagerHolder = ({ documentId }: UserManagerHolderProps): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { searchedEntitiesStatus, documentMembersStatus, externalEntitiesStatus } = useSelector(
    (state: RootState) => state.documentUsersManagerStore,
    undefined
  );
  const searchedEntities = useSelector(
    DocumentUserManagerSelector.selectSearchedEntitiesWithDetails,
    undefined
  );
  const { draftHolders, updateDraftHolders } = useContext(
    UsersManagerContext
  ) as UsersManagerContextType;
  const { canChangeHolderCert } = useSelector(
    DocumentUserManagerSelector.selectDocumentAction,
    undefined
  );
  const [focusInput, setFocusInput] = useState<boolean>(false);
  const [isGenerateTokenLoading, setIsGenerateTokenLoading] = useState<boolean>(false);
  const workspaceId = useSelector(IdentitySelector.selectWorkspaceId, undefined);
  const isAutocompleteLoading = searchedEntitiesStatus === RequestStatus.PENDING;
  const hasHolders = draftHolders.length > 0;

  const isLoading =
    documentMembersStatus === RequestStatus.PENDING ||
    externalEntitiesStatus === RequestStatus.PENDING;

  // Close, hide autocomplete list, fetch contact members for next open
  const onInputBlur = () => {
    setFocusInput(false);
    if (workspaceId) dispatch(documentUserManagerActions.getEntitiesByName(workspaceId, ''));
  };

  // Fetch entities by value from autocomplete
  const fetchWorkspaceEntities = (e: React.ChangeEvent<HTMLElement | unknown>, value: string) => {
    if (workspaceId) dispatch(documentUserManagerActions.getEntitiesByName(workspaceId, value));
  };

  // Action updates draft state when user click remove button / icon
  const onHolderRemove = (val: UserManagerPerson) => {
    updateDraftHolders(draftHolders.filter((item) => item._id !== val._id));
  };

  // Action updates draft state when user select entity from autocomplete list
  const onAddHolder = (val: UserManagerPerson) => {
    val.role = DocumentMemberRole.HOLDER;
    val.isInvitationToBeSend = true;
    val._name = val.email;
    updateDraftHolders([val]);
  };

  // On add new item button click, fetches initial entities from contact book
  const onAddNewItem = () => {
    if (workspaceId) dispatch(documentUserManagerActions.getEntitiesByName(workspaceId, ''));
    setFocusInput(true);
  };

  // request to get transfer invitation token which triggers download from websocket
  const onGeneratePDF = () => {
    setIsGenerateTokenLoading(true);
    dispatch(documentInvitiationActions.downloadTransferOwnershipPDF(documentId));
  };

  // Callback after token is downloaded. Fetch data to refresh UI
  const documentDownloadCallback = () => {
    setTimeout(() => {
      dispatch(
        documentUserManagerActions.getDocumentMembers(documentId, API_MODULE.DocumentDslService)
      );
      dispatch(
        documentUserManagerActions.getDocumentInvitations(documentId, API_MODULE.DocumentDslService)
      );
      setIsGenerateTokenLoading(false);
    }, 1000);

    return undefined;
  };

  return (
    <>
      <TransferOwnershipWebsocket documentId={documentId} callback={documentDownloadCallback} />
      <Section
        classesOverride={{
          rightContentWrapper: classes.centerHolder,
        }}
        title={t<string>('documentUserManager.owners.title')}
        description={
          <>
            {t<string>('documentUserManager.owners.description')}
            <InfoTooltip message={t<string>('documentUserManager.owners.descriptionTooltip')} />
          </>
        }
        rightContent={
          <ItemsWrapper
            actionButtons={
              canChangeHolderCert && !hasHolders && !isGenerateTokenLoading && !isLoading ? (
                <>
                  <SectionButton
                    icon={<EmailIcon />}
                    onClick={onAddNewItem}
                    text={t<string>('documentUserManager.owners.sendByMail')}
                  />
                  <SectionButton
                    icon={<DownloadIcon />}
                    onClick={onGeneratePDF}
                    text={t<string>('documentUserManager.owners.generatePDF')}
                  />
                </>
              ) : undefined
            }
          >
            {isLoading || isGenerateTokenLoading ? (
              <>
                <UserItemSkeleton />
                <Divider />
              </>
            ) : (
              <>
                {hasHolders &&
                  draftHolders.map((holder: UserManagerPerson, index: number) => (
                    <UserItem
                      key={`item_${holder._id || holder.email}_${index}`}
                      userData={holder}
                      canRemove={
                        canChangeHolderCert && (holder.isInvitationToBeSend || holder.isInvited)
                      }
                      onRemove={onHolderRemove}
                    />
                  ))}
                {focusInput && (
                  <AddNewItem
                    isLoading={isAutocompleteLoading}
                    focused={focusInput}
                    onInputBlur={onInputBlur}
                    onAddClick={onAddHolder}
                    items={searchedEntities.filter((entity) => !entity.isGroup)} // filter out groups
                    onInputChange={fetchWorkspaceEntities}
                  />
                )}
              </>
            )}
          </ItemsWrapper>
        }
      />
    </>
  );
};
export default UsersManagerHolder;
