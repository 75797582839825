import { Typography } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

import { AvatarAndTextItem } from '../../index';
import { renderOptionDescription } from '../helpers';
import { useStyles } from '../styles';

const AutocompleteOption = ({
  option,
  documentInvitationMode,
  workspaceId,
  currentIdentityId,
  additionalText,
}) => {
  const { name, type, identityId, group, inputValue } = option;
  const classes = useStyles();

  return (
    <>
      <AvatarAndTextItem
        classes={{
          root: classes.avatarAndItemRoot,
          additionalText: classes.addedListItemAdditionalContent,
        }}
        size="medium"
        text={name}
        description={renderOptionDescription({
          option,
          workspaceId,
          documentInvitationMode,
        })}
        additionalText={currentIdentityId === identityId ? additionalText : null}
        mainImageSrc={
          identityId && !group && !inputValue
            ? `${process.env.REACT_APP_AVATAR_URL}/identity/${identityId}?kind=SMALL`
            : null
        }
      />
      {type && (
        <Typography variant="subtitle2" color="secondary">
          {type}
        </Typography>
      )}
    </>
  );
};

AutocompleteOption.propTypes = {
  option: PropTypes.shape({
    contactEmail: PropTypes.string,
    email: PropTypes.string,
    identityId: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    group: PropTypes.bool,
    inputValue: PropTypes.string,
  }),
  documentInvitationMode: PropTypes.bool,
  currentIdentityId: PropTypes.string,
  workspaceId: PropTypes.string,
  additionalText: PropTypes.string,
};

export default AutocompleteOption;
