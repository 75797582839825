import certificateCreatorConstants from 'src/redux/constants/certificateCreator.constants';
import { CertificateQuantityType, CertificateSteps } from 'src/models/certificate.model';
import { RequestStatus } from 'src/helpers/reduxReuquest.util';
import { similarity } from 'src/helpers/globalUtils';
import CertificateHelper from 'src/helpers/certificateHelper';
import i18n from 'src/helpers/i18n';

const initialState = {
  currentStep: CertificateSteps.SelectTemplateType,
  stepsHistory: [CertificateSteps.SelectTemplateType],
  templateListStatus: RequestStatus.IDLE,
  templateList: undefined,
  templateListTokensStatus: RequestStatus.IDLE,
  templateListTokens: {},
  selectedTemplate: undefined,
  templatePreviewImageStatus: RequestStatus.IDLE,
  templatePreviewImage: undefined,
  currentLocation: undefined,
  certificateQuantityType: CertificateQuantityType.Single,
  templateDetailsStatus: RequestStatus.IDLE,
  templateDetails: undefined,
  rawImportedData: [],
  draftImportedData: [],
  selectedDraftImportedData: [],
  mappedFields: undefined,
  draftSingleData: undefined,
  sentForGeneratingList: [],
  sentForGeneratingListStatus: RequestStatus.IDLE,
  generatedCertFileList: {},
  createdCertificates: [],
  multipleHolders: {},
};

function certificateCreatorStore(state = initialState, action) {
  switch (action.type) {
    case certificateCreatorConstants.SET_CREATOR_STEP: {
      return {
        ...state,
        currentStep: action.payload,
        stepsHistory: [...state.stepsHistory, action.payload],
      };
    }
    case certificateCreatorConstants.SET_CREATOR_STEP_BACK: {
      state.stepsHistory.pop();

      return {
        ...state,
        currentStep: state.stepsHistory.slice(-1)[0],
        stepsHistory: [...state.stepsHistory],
      };
    }
    case certificateCreatorConstants.SET_SELECTED_TEMPLATE_ID: {
      return {
        ...state,
        selectedTemplate: action.payload,
      };
    }
    case certificateCreatorConstants.SET_CERTIFICATE_QUANTITY_TYPE: {
      return {
        ...state,
        certificateQuantityType: action.payload,
      };
    }
    case certificateCreatorConstants.SET_RAW_IMPORTED_DATA: {
      let mappedFields = {};

      const { headersWithNoArrayValues } = CertificateHelper.parseImportedData(action.payload);

      // initial map imported header to template headers
      if (state.templateDetails && headersWithNoArrayValues) {
        Object.keys(state.templateDetails.fields).forEach((field) => {
          const mostSimilarArr = headersWithNoArrayValues.map((item) => similarity(item, field));
          const max = Math.max(...mostSimilarArr);
          const maxIndex = mostSimilarArr.indexOf(max);
          if (field === 'attached_files') {
            mappedFields[field] = [];
          } else {
            mappedFields[field] = headersWithNoArrayValues[maxIndex];
            headersWithNoArrayValues.splice(maxIndex, 1);
          }
        });
      } else {
        mappedFields = undefined;
      }
      return {
        ...state,
        rawImportedData: action.payload,
        mappedFields,
      };
    }

    // UPDATE MAPPED FIELDS
    case certificateCreatorConstants.UPDATE_DRAFT_IMPORTED_DATA: {
      const { index, key, value } = action.payload;
      const data = [...state.draftImportedData];
      data[index][key] = value;
      return {
        ...state,
        draftImportedData: [...data],
      };
    }

    // Set mapped draft from imported data [[][]] => [{key: value}]
    case certificateCreatorConstants.SET_DRAFT_FROM_IMPORTED_DATA: {
      const { mappedHeadersWithFields } = CertificateHelper.parseImportedData(
        state.rawImportedData
      );
      const draftData = mappedHeadersWithFields.map((item) => {
        const res = {};
        Object.keys(state.mappedFields).forEach((field) => {
          res[field] = item[state.mappedFields[field]];
        });
        Object.keys(item).forEach((key) => {
          if (Array.isArray(item[key])) {
            res[key] = item[key];
          }
        });
        return res;
      });

      return {
        ...state,
        draftImportedData: [...draftData],
      };
    }

    // Add new row to draft
    case certificateCreatorConstants.ADD_NEW_ROW_TO_DRAFT_IMPORTED_DATA: {
      const newRow = {};
      if (state.templateDetails) {
        Object.keys(state.templateDetails.fields).forEach((field) => {
          if (field === 'attached_files') newRow[field] = [];
          else newRow[field] = '';
        });
      }

      const draftImportedData =
        Object.keys(newRow).length !== 0
          ? [...state.draftImportedData, newRow]
          : state.draftImportedData;

      return {
        ...state,
        draftImportedData,
      };
    }

    // Update selected rows in for imported data
    case certificateCreatorConstants.UPDATE_SELECTED_DRAFT_IMPORTED_DATA: {
      return {
        ...state,
        selectedDraftImportedData: action.payload,
      };
    }

    // Delete selected row in for imported data
    case certificateCreatorConstants.DELETED_SELECTED_DRAFT_IMPORTED_DATA: {
      return {
        ...state,
        selectedDraftImportedData: [],
        draftImportedData: state.draftImportedData.filter(
          (row, index) => !state.selectedDraftImportedData.includes(index)
        ),
      };
    }

    // SET MAPPED FIELDS
    case certificateCreatorConstants.SET_MAPPED_FIELDS: {
      return {
        ...state,
        mappedFields: { ...state.mappedFields, ...action.payload },
      };
    }

    // AVAILABLE TEMPLATES LIST
    case certificateCreatorConstants.FETCH_TEMPLATE_LIST_REQUEST: {
      return {
        ...state,
        templateListStatus: RequestStatus.PENDING,
        templateList: undefined,
      };
    }
    case certificateCreatorConstants.FETCH_TEMPLATE_LIST_SUCCESS: {
      return {
        ...state,
        templateListStatus: RequestStatus.SUCCESS,
        templateList: action.payload,
      };
    }
    case certificateCreatorConstants.FETCH_TEMPLATE_LIST_FAILURE: {
      return {
        ...state,
        templateListStatus: RequestStatus.ERROR,
        templateList: undefined,
      };
    }
    // AVAILABLE TEMPLATES LIST
    case certificateCreatorConstants.FETCH_TEMPLATE_LIST_THUMBNAIL_TOKEN_REQUEST: {
      return {
        ...state,
        templateListTokensStatus: RequestStatus.PENDING,
        templateListTokens: {},
      };
    }
    case certificateCreatorConstants.FETCH_TEMPLATE_LIST_THUMBNAIL_TOKEN_SUCCESS: {
      return {
        ...state,
        templateListTokensStatus: RequestStatus.SUCCESS,
        templateListTokens: { ...state.templateListTokens, ...action.payload },
      };
    }
    case certificateCreatorConstants.FETCH_TEMPLATE_LIST_THUMBNAIL_TOKEN_FAILURE: {
      return {
        ...state,
        templateListTokensStatus: RequestStatus.ERROR,
        templateListTokens: {},
      };
    }

    // TEMPLATE PREVIEW IMAGE
    case certificateCreatorConstants.FETCH_TEMPLATE_PREVIEW_REQUEST: {
      return {
        ...state,
        templatePreviewImageStatus: RequestStatus.PENDING,
        templatePreviewImage: undefined,
      };
    }
    case certificateCreatorConstants.FETCH_TEMPLATE_PREVIEW_SUCCESS: {
      return {
        ...state,
        templatePreviewImageStatus: RequestStatus.SUCCESS,
        templatePreviewImage: action.payload,
      };
    }
    case certificateCreatorConstants.FETCH_TEMPLATE_PREVIEW_FAILURE: {
      return {
        ...state,
        templatePreviewImageStatus: RequestStatus.ERROR,
        templatePreviewImage: undefined,
      };
    }

    // TEMPLATE PREVIEW IMAGE
    case certificateCreatorConstants.FETCH_TEMPLATE_FIELDS_REQUEST: {
      return {
        ...state,
        templateDetailsStatus: RequestStatus.PENDING,
        templateDetails: undefined,
        draftSingleData: undefined,
      };
    }
    case certificateCreatorConstants.FETCH_TEMPLATE_FIELDS_SUCCESS: {
      const data = {};

      Object.keys(action.payload.fields).forEach((key) => {
        if (action.payload.fields[key].order === undefined) action.payload.fields[key].order = 0;
      });
      action.payload.fields = {
        holderMailMulti: {
          label: i18n.t('createCertificate.holderMailField.label'),
          placeholder: i18n.t('createCertificate.holderMailField.placeholder'),
          required: false,
          order: -1,
          type: 'string',
        },
        documentName: {
          label: i18n.t('createCertificate.documentNameField.label'),
          placeholder: i18n.t('createCertificate.documentNameField.placeholder'),
          required: false,
          order: -2,
          type: 'string',
        },
        ...action.payload.fields,
      };

      Object.keys(action.payload.fields).forEach((key) => {
        data[key] = '';
      });
      action.payload.fields = {
        ...action.payload.fields,
        attached_files: {
          label: i18n.t('createCertificate.attachedFiles.label'),
          required: false,
          order: 20,
          type: 'string',
          format: 'base64',
        },
      };

      return {
        ...state,
        templateDetailsStatus: RequestStatus.SUCCESS,
        templateDetails: action.payload,
        draftSingleData: data,
      };
    }
    case certificateCreatorConstants.UPDATE_DRAFT_SINGLE_FIELDS: {
      return {
        ...state,
        draftSingleData: { ...state.draftSingleData, ...action.payload },
      };
    }
    case certificateCreatorConstants.FETCH_TEMPLATE_FIELDS_FAILURE: {
      return {
        ...state,
        templateDetailsStatus: RequestStatus.ERROR,
        templateDetails: undefined,
        draftSingleData: undefined,
      };
    }

    case certificateCreatorConstants.SET_CURRENT_LOCATION: {
      return {
        ...state,
        currentLocation: action.payload,
      };
    }
    case certificateCreatorConstants.CREATE_NEW_CERTIFICATE_FILE_REQUEST: {
      return {
        ...state,
        sentForGeneratingList: [],
        sentForGeneratingListStatus: RequestStatus.PENDING,
      };
    }
    case certificateCreatorConstants.CREATE_NEW_CERTIFICATE_FILE_SUCCESS: {
      return {
        ...state,
        sentForGeneratingList: action.payload,
        sentForGeneratingListStatus: RequestStatus.SUCCESS,
      };
    }
    case certificateCreatorConstants.CREATE_NEW_CERTIFICATE_FILE_FAILURE: {
      return {
        ...state,
        sentForGeneratingList: [],
        sentForGeneratingListStatus: RequestStatus.ERROR,
      };
    }
    case certificateCreatorConstants.UPDATE_NEW_CERTIFICATE_FILE_CREATED_LIST: {
      return {
        ...state,
        generatedCertFileList: action.payload,
      };
    }
    case certificateCreatorConstants.SET_MULTIPLE_HOLDERS: {
      return {
        ...state,
        multipleHolders: action.payload,
      };
    }
    case certificateCreatorConstants.ADD_CREATED_CERTIFICATE: {
      return {
        ...state,
        createdCertificates: [...state.createdCertificates, action.payload],
      };
    }

    case certificateCreatorConstants.RESET_FOR_NEW_TEMPLATE:
      return {
        ...initialState,
        templateListTokensStatus: state.templateListTokensStatus,
        templateListTokens: state.templateListTokens,
        templateList: state.templateList,
        templateListStatus: state.templateListStatus,
        stepsHistory: state.stepsHistory,
        currentStep: state.currentStep,
      };
    case certificateCreatorConstants.CLEAR:
      return initialState;
    default:
      return state;
  }
}

export default certificateCreatorStore;
