import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { useAddNewItemStyles } from './styles';

const UserItemSkeleton = (): JSX.Element => {
  const classes = useAddNewItemStyles();

  return (
    <div className={classes.wrapper}>
      <Skeleton variant="circle" height={40} width={40} />
      <div className={classes.textWrapper}>
        <Skeleton className={classes.inputSkeleton} variant="text" height={24} />
        <Skeleton variant="text" height={16} width={150} />
      </div>
    </div>
  );
};

export default UserItemSkeleton;
