import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ breakpoints, palette, shape, spacing, typography }) =>
    createStyles({
      topBar: {
        borderBottom: `1px solid ${palette.divider}`,
      },
      topBarContainer: {},
      topBarBackButton: {},
      topBarOptions: {
        textAlign: 'right',
        '& > *:not(:first-child)': {
          marginLeft: spacing(0.5),
          [breakpoints.up('md')]: {
            marginLeft: spacing(1),
          },
        },
      },
      topBarOptionButton: {
        borderRadius: shape.borderRadius,
      },
      topBarTitleWrapper: {
        flex: 1,
      },
      title: {
        marginLeft: spacing(-1),
        marginRight: spacing(-1),
        backgroundColor: palette.background.default,
        [breakpoints.up('xs')]: {
          marginLeft: 0,
          marginRight: 0,
        },
      },
      titleInput: {
        fontSize: typography.h6.fontSize,
        fontFamily: typography.h6.fontFamily,
        borderRadius: shape.borderRadius,
        [breakpoints.down('xs')]: {
          paddingLeft: spacing(1),
          paddingRight: spacing(1),
        },
        [breakpoints.up('sm')]: {
          fontWeight: typography.h6.fontWeight,
          letterSpacing: typography.h6.letterSpacing,
        },
      },
      disabledTitleInput: {
        '& $titleInput': {
          color: palette.text.primary,
          backgroundColor: `${palette.background.default} !important`,
        },
        '& $inputNotchedOutline': {
          borderColor: 'transparent !important',
        },
      },
      inputNotchedOutline: {
        '&:not(:hover), &:not(:focus)': {
          borderColor: 'transparent',
        },
      },
      infoWithIcon: {
        display: 'flex',
        alignItems: 'center',
        '& svg': {
          marginRight: spacing(0.75),
          fontSize: 18,
        },
      },
      errorIcon: {
        color: palette.error.main,
        cursor: 'default',
      },
    }),
  { index: 0 }
);
