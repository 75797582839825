import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Box, CircularProgress, InputAdornment, TextField } from '@material-ui/core';
import { ClearOutlined } from '@material-ui/icons';
import { ContentContainer } from 'src/components';
import { Search as SearchIcon } from 'src/components/CustomIcon';
import { useStyles } from './styles';

const Search = ({
  className: classNameProp,
  handleSetSearchTerm,
  inputLabel,
  valueInput,
  loading,
}) => {
  const classes = useStyles();
  return (
    <ContentContainer
      horizontal={false}
      vertical="bottom"
      className={clsx(classes.root, classNameProp)}
    >
      <TextField
        fullWidth
        placeholder={inputLabel}
        value={valueInput}
        onChange={(event) => handleSetSearchTerm(event.target.value)}
        className={classes.searchField}
        InputProps={{
          endAdornment: (
            <Box display="flex" alignItems="center">
              {loading && <CircularProgress size={24} />}
              {valueInput.length > 0 ? (
                <InputAdornment
                  onClick={() => handleSetSearchTerm('')}
                  className={classes.clearIcon}
                  position="end"
                >
                  <ClearOutlined />
                </InputAdornment>
              ) : (
                <InputAdornment className={classes.searchAdornmentIcon} position="end">
                  <SearchIcon />
                </InputAdornment>
              )}
            </Box>
          ),
        }}
      />
    </ContentContainer>
  );
};

Search.propTypes = {
  className: PropTypes.string,
  handleSetSearchTerm: PropTypes.func,
  inputLabel: PropTypes.string,
  valueInput: PropTypes.string,
  loading: PropTypes.bool,
};

export default Search;
