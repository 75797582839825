import spacing from '../spacing';
import breakpoints from '../breakpoints';

export default {
  root: {
    width: '100%',
    maxWidth: breakpoints.values.md,
    left: 0,
    right: 0,
    '& > *': {
      width: '100%',
      [breakpoints.down('xs')]: {
        borderRadius: '0 !important',
      },
    },
    [breakpoints.up('sm')]: {
      left: spacing(1.5),
      right: spacing(1.5),
      '& > *': {
        width: `calc(100% - ${spacing(3)}px)`,
      },
    },
    [breakpoints.up('md')]: {
      left: spacing(3),
      right: spacing(3),
      '& > *': {
        width: `calc(100% - ${spacing(6)}px)`,
      },
    },
  },
  anchorOriginTopCenter: {
    top: 0,
    [breakpoints.up('sm')]: {
      top: spacing(1.5),
      left: '50%',
      right: 'auto',
      transform: 'translateX(-50%)',
    },
    [breakpoints.up('md')]: {
      top: spacing(3),
    },
  },
  anchorOriginBottomCenter: {
    bottom: 0,
    [breakpoints.up('sm')]: {
      bottom: spacing(1.5),
      left: '50%',
      right: 'auto',
      transform: 'translateX(-50%)',
    },
    [breakpoints.up('md')]: {
      bottom: spacing(3),
    },
  },
  anchorOriginTopRight: {
    top: 0,
    right: 0,
    [breakpoints.up('sm')]: {
      left: 'auto',
      top: spacing(1.5),
      right: spacing(1.5),
    },
    [breakpoints.up('md')]: {
      top: spacing(3),
      right: spacing(3),
    },
  },
  anchorOriginBottomRight: {
    bottom: 0,
    right: 0,
    [breakpoints.up('sm')]: {
      left: 'auto',
      bottom: spacing(1.5),
      right: spacing(1.5),
    },
    [breakpoints.up('md')]: {
      bottom: spacing(3),
      right: spacing(3),
    },
  },
  anchorOriginTopLeft: {
    top: 0,
    left: 0,
    [breakpoints.up('sm')]: {
      right: 'auto',
      top: spacing(1.5),
      left: spacing(1.5),
    },
    [breakpoints.up('md')]: {
      top: spacing(3),
      left: spacing(3),
    },
  },
  anchorOriginBottomLeft: {
    bottom: 0,
    left: 0,
    [breakpoints.up('sm')]: {
      right: 'auto',
      bottom: spacing(1.5),
      left: spacing(1.5),
    },
    [breakpoints.up('md')]: {
      bottom: spacing(3),
      left: spacing(3),
    },
  },
};
