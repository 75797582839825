import React from 'react';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';

interface HighlightStringProps {
  text: string;
  highlightString: string;
}

const HighlightString = ({ text, highlightString }: HighlightStringProps): JSX.Element => {
  const matches = match(text, highlightString, {
    insideWords: true,
    findAllOccurrences: true,
  });
  const parts = parse(text, matches);

  return (
    <>
      {parts.map((part, index) => (
        <span key={index} className={part.highlight ? 'highlight' : undefined}>
          {part.text}
        </span>
      ))}
    </>
  );
};

export default HighlightString;
