import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ breakpoints, palette, spacing }) =>
    createStyles({
      root: {
        padding: spacing(2),
        '& > *:not(:last-child)': {
          marginBottom: spacing(2),
        },
        [breakpoints.down('sm')]: {
          border: 'none',
          borderTop: `1px solid ${palette.divider}`,
          borderRadius: 0,
        },
        [breakpoints.up('md')]: {
          padding: spacing(2.5, 2),
        },
        [breakpoints.up('lg')]: {
          marginTop: spacing(2),
          marginBottom: spacing(3),
        },
      },
      user: {
        [breakpoints.down('sm')]: {
          maxWidth: '65%!important',
          '& + *': {
            minWidth: '35%!important',
          },
        },
      },
      button: {
        minHeight: 48,
        padding: spacing(0.75, 1.25),
        [breakpoints.up('md')]: {
          padding: spacing(0.75, 1.75),
        },
      },
    }),
  { index: 0 }
);
