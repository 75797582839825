import React, { useEffect, useRef, useState } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import 'src/helpers/i18n';
import cookieHelper from 'src/helpers/cookieHelper';
import WebSocketHelper from 'src/helpers/WebSocketHelper';
import moment from 'moment';
import { SnackbarProvider } from 'notistack';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import { useDispatch, useSelector } from 'react-redux';
import {
  ContentContainer,
  CreateWorkspace,
  FullPageLoader,
  SnackbarItem,
  TwoFactorModal,
  CertificationCreationModal,
} from 'src/components';
import {
  DocumentActions as DocumentActionsPage,
  DocumentCreator as DocumentCreatorPage,
  JoinToDocument as JoinToDocumentPage,
  Recent as RecentPage,
  UserConfiguration as UserConfigurationPage,
  DrivePage,
  WorkspaceConfiguration as WorkspaceConfigurationPage,
  TransferOwnership as TransferOwnershipPage,
} from 'src/pages';

import authActions from 'src/redux/actions/auth.actions';
import contactsActions from 'src/redux/actions/contacts.actions';
import documentDownloadActions from 'src/redux/actions/documentDownload.actions';
import identityActions from 'src/redux/actions/identity.actions';
import { RequestStatus } from 'src/helpers/reduxReuquest.util';
import { useTranslation } from 'react-i18next';
import { AppHeader, AppMenuSidebar, Notifier } from './subcomponents';
import { useStyles } from './styles';
import SearchPage from '../SearchPage/SearchPage';
import UserComUrlChange from './subcomponents/UserComUrlChange/UserComUrlChange';
import SubscriptionWatcher from './subcomponents/SubscriptionWatcher/SubscriptionWatcher';

const snackbarOptions = (optionsString) => {
  if (optionsString === undefined) return {};
  return JSON.parse(optionsString);
};

const App = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { i18n } = useTranslation();
  const { tokenStatus, userProfileRequestStatus, userProfile } = useSelector(
    (state) => state.authStore,
    undefined
  );
  const {
    structure,
    structureStatus,
    workspaceDetails,
    currentIdentity,
    workspacePermissionsStatus,
  } = useSelector((state) => state.identityStore, undefined);
  const { downloadDocuments } = useSelector((state) => state.documentDownloadStore, undefined);
  const { contactsQueue } = useSelector((state) => state.contactsStore, undefined);
  const downloadDocumentSubscriptionIds = useRef([]);
  const [authorized, setAuthorized] = useState(false);
  const [isCreateWorkspaceDialogOpen, setIsCreateWorkspaceDialogOpen] = useState(false);
  const identityIsEmpty = structureStatus === RequestStatus.SUCCESS && structure.length === 0;
  const structureError = structureStatus === RequestStatus.ERROR;
  const identityAndWorkspaceLoaded =
    currentIdentity !== undefined &&
    currentIdentity !== null &&
    workspaceDetails !== undefined &&
    workspaceDetails !== null;

  const setCurrentIdentity = () => {
    const workspaceIdUrl = new URLSearchParams(window.location.search).get('workspaceId');

    const identityFromLocalStorage = cookieHelper.getCookie('activeIdentity');
    let identityData = false;
    const availableIdentities = structure
      ? structure.filter((structureItem) => structureItem.identity.active)
      : structure;

    if (workspaceIdUrl) {
      identityData = availableIdentities.find(
        (item) => workspaceIdUrl && item.workspace.workspaceId === workspaceIdUrl
      );
    }

    if (!identityData) {
      availableIdentities.forEach((item) => {
        if (item.identity.identityId === identityFromLocalStorage) {
          identityData = item;
        }
      });
    }

    if (!identityData) {
      [identityData] = availableIdentities;
    }

    cookieHelper.setCookie('activeIdentity', identityData?.identity?.identityId);
    if (identityData?.workspace) {
      const workspaceId = identityData?.workspace?.workspaceId;
      dispatch(identityActions.getWorkspaceStructure(workspaceId));
      dispatch(contactsActions.initializeContacts(workspaceId));
      dispatch(identityActions.getWorkspacePermissions(workspaceId));
      setIsCreateWorkspaceDialogOpen(false);
    } else {
      setIsCreateWorkspaceDialogOpen(true);
    }
    dispatch(identityActions.setCurrentIdentity(identityData));
  };

  const getDownloadFileSubscriptionId = (documentId) =>
    downloadDocumentSubscriptionIds.current.find((x) => x.id === documentId);

  const handleDocumentDownload = (documentId, message) => {
    if (message?.downloadToken) {
      dispatch(documentDownloadActions.downloadDocumentFile(documentId, message.downloadToken));
    } else {
      console.warn(`Unknown action for document download (${documentId})`);
    }

    downloadDocumentUnsubscribe(documentId);
    dispatch(documentDownloadActions.websocketDownloadDocument(documentId));
  };

  const downloadDocumentSubscribe = (documentId) => {
    if (getDownloadFileSubscriptionId(documentId) === undefined) {
      downloadDocumentSubscriptionIds.current.push({
        id: documentId,
        subscription: WebSocketHelper.subscribe(
          'document-service',
          `document/download/${documentId}`,
          (message) => {
            handleDocumentDownload(documentId, message);
          }
        ),
      });
    }
  };

  const downloadDocumentUnsubscribe = (documentId) => {
    const subId = getDownloadFileSubscriptionId(documentId);
    if (subId !== undefined) {
      WebSocketHelper.unsubscribe(subId.subscription);
      subId.subscription = false;
      downloadDocumentSubscriptionIds.current.splice(subId, 1);
    }
  };

  useEffect(() => {
    dispatch(authActions.checkTokenStatus(['AUTH_USER_GET_PROFILE']));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tokenStatus === RequestStatus.ERROR) {
      window.location.href = process.env.REACT_APP_AUTH_FRONT_URL;
    } else if (tokenStatus === RequestStatus.SUCCESS) {
      if (!authorized) {
        setAuthorized(true);
        dispatch(identityActions.getUserStructure());
        dispatch(authActions.getUserProfile());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenStatus]);

  useEffect(() => {
    if (userProfileRequestStatus === RequestStatus.SUCCESS) {
      const cookieLang = cookieHelper.getCookie('lang');
      if (
        i18n.language.toLowerCase() !== userProfile.language.toLowerCase() &&
        cookieLang.toLowerCase() !== i18n.language.toLowerCase()
      ) {
        moment.locale(userProfile.language.toLowerCase());
        i18n.changeLanguage(userProfile.language.toLowerCase()).then();
      }
      if (cookieLang && cookieLang !== '') {
        if (cookieLang.toLowerCase() !== userProfile.language.toLowerCase()) {
          dispatch(authActions.patchLanguage(cookieLang));
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfileRequestStatus]);

  useEffect(() => {
    if (structureStatus === RequestStatus.SUCCESS) {
      setCurrentIdentity();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [structureStatus]);

  useEffect(() => {
    if (contactsQueue.length > 0) {
      const workspaceId = currentIdentity?.workspace?.workspaceId;
      dispatch(contactsActions.getEntities(workspaceId, contactsQueue));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactsQueue, currentIdentity]);

  useEffect(() => {
    if (
      workspacePermissionsStatus === RequestStatus.IDLE &&
      currentIdentity?.workspace?.workspaceId
    ) {
      dispatch(identityActions.getWorkspacePermissions(currentIdentity?.workspace?.workspaceId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspacePermissionsStatus, currentIdentity]);

  useEffect(() => {
    if (downloadDocuments && downloadDocuments.length > 0) {
      downloadDocuments.forEach((item) => {
        downloadDocumentSubscribe(item);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadDocuments]);

  useEffect(() => {
    downloadDocumentSubscriptionIds.current.forEach((subId) => {
      downloadDocumentUnsubscribe(subId.id);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SnackbarProvider
      dense={false}
      autoHideDuration={5000}
      maxSnack={4}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      classes={{
        containerRoot: classes.snackbarContainerRoot,
        anchorOriginTopCenter: classes.snackbarAnchorOriginTopCenter,
        collapseContainer: classes.snackbarCollapseContainer,
        collapseWrapper: classes.snackbarCollapseWrapper,
        wrappedRoot: classes.snackbarWrappedRoot,
      }}
      content={(index, message) => (
        <SnackbarItem key={index} id={index} options={snackbarOptions(message)} />
      )}
    >
      <Notifier />
      <Router>
        <div className={classes.root}>
          {!authorized ? (
            <FullPageLoader
              open
              transitionDuration={{ enter: 0, exit: 300 }}
              classes={{ root: classes.authorizationLoader }}
            />
          ) : (
            <main className={classes.main}>
              <DndProvider
                backend={TouchBackend}
                options={{
                  enableMouseEvents: true,
                }}
              >
                <AppHeader className={classes.appHeader} />

                {identityAndWorkspaceLoaded && (
                  <ContentContainer className={classes.content}>
                    <CertificationCreationModal />
                    <Switch>
                      <Route path="/document/:id" component={DocumentActionsPage} />
                      <Route path="/certificate/:id" component={DocumentActionsPage} />
                      <Route path="/transfer-ownership/:id" component={TransferOwnershipPage} />
                      <Route path="/document-creator/:type" component={DocumentCreatorPage} />
                      <Route
                        path="/join-to-document/:invitationId"
                        component={JoinToDocumentPage}
                      />
                      <Route
                        path="/join-to-certificate/:invitationId"
                        component={JoinToDocumentPage}
                      />
                      <Route path="/recent" component={RecentPage} />
                      <Route path="/user-settings" component={UserConfigurationPage} />
                      <Route path="/drive/:folderId" component={DrivePage} />
                      <Route path="/drive" component={DrivePage} />
                      <Route path="/search" component={SearchPage} />
                      <Route path="/workspace-settings" component={WorkspaceConfigurationPage} />
                      <Redirect from="*" to="/drive" />
                    </Switch>
                  </ContentContainer>
                )}

                <CreateWorkspace
                  isOpen={identityIsEmpty || structureError || isCreateWorkspaceDialogOpen}
                  showLogout={isCreateWorkspaceDialogOpen}
                  noIdentity={identityIsEmpty}
                  structureError={structureError}
                  transitionDuration={{ enter: 0, exit: 300 }}
                />

                <AppMenuSidebar variant="bottom" />

                <TwoFactorModal />

                <FullPageLoader
                  open={
                    isCreateWorkspaceDialogOpen === false &&
                    (!authorized || !identityAndWorkspaceLoaded)
                  }
                  transitionDuration={{ enter: 0, exit: 300 }}
                  classes={{ root: classes.authorizationLoader }}
                />
              </DndProvider>
            </main>
          )}
          <UserComUrlChange />
          <SubscriptionWatcher />
        </div>
      </Router>
    </SnackbarProvider>
  );
};

export default App;
