import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import documentManagementActions from 'src/redux/actions/documentManagement.actions';
import { AppBar, InputAdornment, OutlinedInput, Tooltip, useMediaQuery } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import {
  ConfirmDialog,
  ContentContainer,
  HeaderButton,
  MobileBottomMenuList,
  TopBarContainer,
} from 'src/components';
import {
  ArrowLeftSLine as ChevronLeftIcon,
  MoreHor as MoreHorizontalIcon,
  Alert as ErrorIcon,
} from 'src/components/CustomIcon';
import { RequestStatus } from 'src/helpers/reduxReuquest.util';
import { TopBarOptionsMenu } from '../index';
import { useStyles } from './styles';
import { moduleName as dslService } from '../../../../redux/services/documentDSL.service';
import { moduleName as documentService } from '../../../../redux/services/documentCore.service';

const TopBar = ({
  children,
  documentId,
  title,
  disableChangeTitle,
  mainOptions,
  onBack,
  rightContent,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const smallScreen = useMediaQuery(({ breakpoints }) => breakpoints.down('sm'));
  const dispatch = useDispatch();
  const history = useHistory();
  const isCertificate = history.location.pathname.includes('certificate');
  const apiService = isCertificate ? dslService : documentService;
  const [didMount, setDidMount] = useState(false);
  const [optionsAnchorEl, setOptionsAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [value, setValue] = useState(null);
  const [error, setError] = useState(false);
  const [canMakeError, setCanMakeError] = useState(true);
  const [open, setOpen] = useState(false);
  const handleToggle = {
    close: () => {
      setOpen(false);
      setOptionsAnchorEl(null);
    },
    open: (event) => {
      if (hasAnyOptions) {
        setOpen(true);
        setOptionsAnchorEl(event.currentTarget);
      } else {
        setOpenDialog(true);
      }
    },
  };

  const REGEX_PATTERN = '^[^/\\\\*%:;<>"|]*$';
  const MAX_LENGTH = 50;

  const documentRenameStatus = useSelector(
    (state) => state.documentManagementStore.documentRenameStatus
  );

  const hasAnyOptions = mainOptions && mainOptions.length > 0;

  const isPending = documentRenameStatus === RequestStatus.PENDING;
  const isDisabled = isPending || disableChangeTitle;

  useMemo(() => {
    if (title) {
      setValue(title);
    }
  }, [title]);

  useEffect(() => {
    setDidMount(true);
    return () => setDidMount(false);
  }, []);

  if (!didMount) {
    return null;
  }
  return (
    <>
      <AppBar className={classes.topBar} position="relative">
        <TopBarContainer size="medium" className={classes.topBarContainer}>
          <HeaderButton
            className={classes.topBarBackButton}
            color="primary"
            icon={<ChevronLeftIcon />}
            tooltip={t('common.back')}
            onClick={onBack}
          />

          <ContentContainer size="medium" vertical={false} className={classes.topBarTitleWrapper}>
            {title && documentId && (
              <OutlinedInput
                value={value}
                placeholder={t('documentActions.renameDocument.placeholder')}
                disabled={isDisabled}
                error={error}
                endAdornment={
                  error && (
                    <InputAdornment position="end">
                      <Tooltip title={t('documentActions.renameDocument.invalidFormat')}>
                        <ErrorIcon className={classes.errorIcon} />
                      </Tooltip>
                    </InputAdornment>
                  )
                }
                fullWidth
                classes={{
                  root: classes.title,
                  input: classes.titleInput,
                  notchedOutline: classes.inputNotchedOutline,
                  disabled: classes.disabledTitleInput,
                }}
                inputProps={{ pattern: REGEX_PATTERN }}
                onChange={(event) => {
                  const targetValue = event.target.value;
                  if (!canMakeError) {
                    setError(!targetValue.match(REGEX_PATTERN) || targetValue.length > MAX_LENGTH);
                  }
                  setValue(targetValue);
                }}
                onBlur={() => {
                  const currentValue = value.trim();
                  const isValueCorrect =
                    currentValue.match(REGEX_PATTERN) && currentValue.length <= MAX_LENGTH;

                  if (isValueCorrect) {
                    setError(false);
                    setCanMakeError(true);

                    if (currentValue !== title) {
                      dispatch(
                        documentManagementActions.renameDocument({
                          documentId,
                          name: currentValue,
                          service: apiService,
                        })
                      );
                    }
                  } else {
                    setError(true);
                    if (canMakeError) {
                      setCanMakeError(false);
                    }
                  }
                  setValue(currentValue);
                }}
              />
            )}
          </ContentContainer>
          {rightContent || null}
          {mainOptions !== undefined && (
            <div className={classes.topBarOptions}>
              <HeaderButton
                classes={{
                  root: classes.topBarOptionButton,
                }}
                icon={<MoreHorizontalIcon />}
                tooltip={t('common.options')}
                onClick={handleToggle.open}
              />
              {hasAnyOptions && (
                <>
                  {smallScreen ? (
                    <MobileBottomMenuList
                      actions={mainOptions}
                      open={open}
                      onClose={handleToggle.close}
                    />
                  ) : (
                    <TopBarOptionsMenu
                      anchorEl={optionsAnchorEl}
                      open={Boolean(optionsAnchorEl)}
                      onClose={handleToggle.close}
                      options={mainOptions}
                    />
                  )}
                </>
              )}
            </div>
          )}
        </TopBarContainer>

        {children}
      </AppBar>

      <ConfirmDialog
        open={!hasAnyOptions && openDialog}
        title={t('documentActions.noOptionsDialog.header')}
        subtitle={t('documentActions.noOptionsDialog.description')}
        applyButtonText={t('documentActions.noOptionsDialog.confirm')}
        actionAccept={() => setOpenDialog(false)}
      />
    </>
  );
};

TopBar.propTypes = {
  rightContent: PropTypes.node,
  children: PropTypes.node,
  documentId: PropTypes.string,
  title: PropTypes.string,
  disableChangeTitle: PropTypes.bool,
  mainOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      icon: PropTypes.node,
      onClick: PropTypes.func,
    })
  ),
  onBack: PropTypes.func,
};

TopBar.defaultProps = {
  disableChangeTitle: false,
};

export default TopBar;
