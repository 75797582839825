import { Box, LinearProgress, Typography } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { useStyles } from '../styles';

const LinearProgressWithLabel = ({ value = 0 }) => {
  const classes = useStyles();
  const isProgressEnd = value === 100;
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress
          className={classes.barBox}
          classes={{ bar: clsx(isProgressEnd && classes.progressEnd) }}
          variant="determinate"
          value={value}
        />
      </Box>
      <Box minWidth={35}>
        <Typography
          variant="body2"
          color={isProgressEnd ? 'initial' : 'primary'}
          className={clsx(classes.valueText, isProgressEnd && classes.valueTextSuccess)}
        >{`${Math.round(value)}%`}</Typography>
      </Box>
    </Box>
  );
};

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number,
};

export default LinearProgressWithLabel;
