import { makeStyles, createStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ spacing, palette }) =>
    createStyles({
      card: {
        marginBottom: spacing(3),
      },
      textCol: {
        paddingRight: spacing(9),
      },
      divider: {
        marginBottom: spacing(2),
        marginTop: spacing(2),
      },
      buttonsWrapper: {
        display: 'flex',
      },
      wideButton: {
        minWidth: 240,
        marginRight: spacing(1),
      },
      infoCol: {},
      cancelAt: {
        marginTop: spacing(3),
      },
      content: {
        display: 'flex',
      },
      notStarted: {
        color: palette.info.main,
        fontWeight: 500,
      },
      trial: {
        color: palette.success.main,
        fontWeight: 500,
      },
      trialCompleted: {
        color: palette.error.main,
        fontWeight: 500,
      },
      active: {
        color: palette.success.main,
        fontWeight: 500,
      },
      completed: {
        color: palette.error.main,
        fontWeight: 500,
      },
      bar: {
        height: spacing(3),
        width: 200,
        borderRadius: 20,
      },
      innerBar: {
        borderRadius: 20,
      },
      barWrapper: {
        marginTop: spacing(3),
        position: 'relative',
      },
      barText: {
        position: 'absolute',
        left: 0,
        zIndex: 1,
        color: palette.common.white,
        paddingLeft: spacing(1),
        fontSize: 12,
        fontWeight: 600,
        top: 0,
        lineHeight: '24px',
      },
    }),
  { index: 0 }
);
