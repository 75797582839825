import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SidenavLinks, MoreLinks } from 'src/pages/App/Links';
import { AppBar, Typography, useMediaQuery } from '@material-ui/core';
import { TopBarContainer, HeaderSearch } from 'src/components';
import HeaderNotifications from 'src/components/HeaderNotifications/HeaderNotifications';
import { useStyles } from './styles';
import HeaderHelp from './subcomponents/HeaderHelp/HeaderHelp';
import HeaderUser from './subcomponents/HeaderUser/HeaderUser';

const AppHeader = ({
  className: classNameProp,
  isMainHeader,
  disableTitle,
  additionalLeftAction,
  additionalRightAction,
}) => {
  const classes = useStyles();
  const location = useLocation();
  const { t } = useTranslation();
  const [didMount, setDidMount] = useState(false);

  const smallScreen = useMediaQuery(({ breakpoints }) => breakpoints.down('sm'));
  useEffect(() => {
    setDidMount(true);
    return () => setDidMount(false);
  }, []);

  const titles = [...SidenavLinks(t), ...MoreLinks(t)];
  const extractTitle = () => titles.find((link) => link.url === location.pathname)?.label;
  const title = extractTitle();

  const pathnameSplit = location.pathname.split('/');
  const isVaultHeader = pathnameSplit[1] === 'drive';
  const isDocumentHeader = pathnameSplit[1] === 'document';
  const isNotRootVault = isVaultHeader && pathnameSplit[2] !== undefined;
  const isVaultAndNotRoot = isVaultHeader && isNotRootVault;
  const showSearchEngine =
    (!isMainHeader && isVaultAndNotRoot) ||
    (isMainHeader && !isVaultAndNotRoot) ||
    (isMainHeader && !smallScreen);

  if (!didMount) {
    return null;
  }

  return (
    <AppBar position="relative" className={clsx(classes.root, classNameProp)}>
      <TopBarContainer className={classes.toolbar}>
        {(additionalLeftAction || (smallScreen && !disableTitle)) && (
          <div className={classes.mainContent}>
            {additionalLeftAction && (
              <div className={clsx(classes.actions, classes.actionsLeft)}>
                {additionalLeftAction}
              </div>
            )}

            {smallScreen && !disableTitle && (
              <Typography variant="h5" className={classes.headerTitle}>
                <strong>{title}</strong>
              </Typography>
            )}
          </div>
        )}

        {showSearchEngine && didMount && !isDocumentHeader && isMainHeader && (
          <HeaderSearch
            smallScreen={smallScreen}
            showOpenButton={showSearchEngine && smallScreen}
          />
        )}

        <div className={clsx(classes.actions, classes.actionsRight)}>
          {isMainHeader && (
            <>
              <HeaderNotifications />
              <HeaderHelp />
              <HeaderUser />
            </>
          )}

          {additionalRightAction}
        </div>
      </TopBarContainer>
    </AppBar>
  );
};

AppHeader.propTypes = {
  className: PropTypes.string,
  isMainHeader: PropTypes.bool,
  disableTitle: PropTypes.bool,
  additionalLeftAction: PropTypes.node,
  additionalRightAction: PropTypes.node,
};

AppHeader.defaultProps = {
  isMainHeader: true,
  disableTitle: false,
};

export default AppHeader;
