import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { AvatarGroup } from '@material-ui/lab';
import { Avatar } from 'src/components';
import { useStyles } from './styles';

const SPACINGS = {
  small: -16,
  medium: null,
};

const UserAvatarGroup = React.forwardRef(
  ({ children: childrenProp, className, max = 5, spacing = 'medium', size }, ref) => {
    const classes = useStyles();
    const clampedMax = max < 2 ? 2 : max;

    const children = React.Children.toArray(childrenProp).filter((child) =>
      React.isValidElement(child)
    );

    const extraAvatars = children.length > clampedMax ? children.length - clampedMax + 1 : 0;

    const marginLeft = spacing && SPACINGS[spacing] !== undefined ? SPACINGS[spacing] : -spacing;

    return (
      <div className={clsx(classes.root, className)} ref={ref}>
        {children.slice(0, children.length - extraAvatars).map((child) =>
          React.cloneElement(child, {
            className: clsx(child.props.className, classes.avatar),
            style: {
              marginLeft,
              ...child.props.style,
            },
            size,
          })
        )}
        {extraAvatars ? (
          <Avatar
            size={size}
            extraAvatars={extraAvatars}
            color="info"
            className={clsx(classes.avatar, classes.extraAvatar)}
            style={{
              marginLeft,
            }}
          >
            {`+${extraAvatars}`}
          </Avatar>
        ) : null}
      </div>
    );
  }
);

UserAvatarGroup.propTypes = {
  ...AvatarGroup.props,
  size: PropTypes.oneOf(['large', 'medium', 'small', 'mini']),
};

UserAvatarGroup.defaultProps = {
  size: 'large',
};

export default UserAvatarGroup;
