import { createStyles, makeStyles } from '@material-ui/core';
import palette from '../../../../theme/options/palette';

export const useAddNewItemStyles = makeStyles(
  ({ spacing }) =>
    createStyles({
      wrapper: {
        display: 'flex',
        padding: spacing(2),
        alignItems: 'center',
      },
      textWrapper: {
        width: '100%',
        paddingLeft: spacing(2),
        display: 'flex',
        flexDirection: 'column',
      },
      inputSkeleton: {
        transform: 'unset',
        marginBottom: 4,
        width: '100%',
        maxWidth: 600,
      },
      autocompleteOption: {
        padding: 0,
        '&>div': {
          paddingTop: 4,
          paddingBottom: 4,
          minHeight: 54,
          width: '100%',
        },
      },
      groupHeader: {
        minHeight: 32,
        borderBottom: `1px solid ${palette.secondary.light}`,
      },
      input: {},
      inputRoot: {
        padding: '0 12px!important',
        minHeight: '24px!important',
        width: '100%',
        maxWidth: 600,
        '& input': {
          padding: 0,
        },
      },
    }),
  { index: 0 }
);

export const useUserItemStyles = makeStyles(
  ({ spacing, palette }) =>
    createStyles({
      sortable: {
        display: 'block',
        padding: 0,
        color: 'unset',
        marginLeft: 0,
      },
      wrapper: {
        display: 'flex',
        paddingLeft: spacing(2),
        paddingRight: spacing(2),
        minHeight: 76,
        alignItems: 'center',
        transition: 'all .3s',
        background: palette.background.paper,
        borderRadius: 8,
        '& .avatarWrapper .MuiAvatar-root': {
          transition: 'all .3s',
        },
        '&:hover .itemTitleHover': {
          color: palette.primary.main,
        },
        '&:hover .avatarWrapper .MuiAvatar-root': {
          boxShadow: '2px 5px 12px -4px rgb(0 0 0 / 20%)',
        },
        '&.hoverEnabled:hover': {
          background: palette.action.hover,
        },
      },
      textWrapper: {
        paddingLeft: spacing(2),
        flex: 1,
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        marginRight: 'auto',
        '& .highlight': {
          fontWeight: 700,
          color: palette.primary.main,
          textDecoration: 'underline',
        },
      },
      title: {
        fontWeight: 500,
      },
      removeButton: {
        color: palette.text.secondary,
        '&:hover': {
          color: palette.primary.main,
        },
      },
    }),
  { index: 0 }
);

export const useUserItemTitleStyles = makeStyles(
  ({ palette }) =>
    createStyles({
      title: {
        display: 'flex',
        transition: 'color .3s ease',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        '& *': {
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        },
      },
      author: {
        marginLeft: 6,
        color: palette.warning.main,
      },
      you: {
        marginLeft: 6,
        color: palette.text.secondary,
      },
      group: {
        marginLeft: 6,
        color: palette.common.purple,
      },
      sentChip: {
        color: palette.common.purple,
        background: '#fef7fd',
        marginLeft: 6,
      },
      pendingChip: {
        color: '#FFCE51',
        background: '#fffdf6',
        marginLeft: 6,
      },
    }),
  { index: 0 }
);

export const useUserItemDescriptionStyles = makeStyles(
  () =>
    createStyles({
      description: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      },
    }),
  { index: 0 }
);

export const useUserItemRolesStyles = makeStyles(
  ({ palette, spacing, transitions }) =>
    createStyles({
      menuWrapper: {
        color: palette.text.primary,
        flexDirection: 'column',
        alignItems: 'flex-start',

        '&:hover, &.Mui-selected': {
          transition: 'all .3s',
          backgroundColor: palette.action.hover,
        },
        '&:hover, &.Mui-selected:hover': {
          backgroundColor: palette.action.hover,
          transition: 'all .3s',
        },
      },
      title: {
        marginBottom: 4,
      },
      description: {
        color: palette.text.secondary,
        whiteSpace: 'normal',
        maxWidth: 280,
      },
      button: {
        color: palette.secondary.dark,
        '&:hover': {
          color: palette.primary.main,
        },
      },
      opened: {
        color: palette.primary.main,
        '& $icon': {
          transform: 'rotate(-180deg)',
        },
      },
      icon: {
        width: 16,
        height: 16,
        marginLeft: spacing(-0.5),
        lineHeight: 1,
        color: 'inherit',
        transition: transitions.create(['color', 'transform', 'opacity'], {
          duration: transitions.duration.short,
        }),
      },
    }),
  { index: 0 }
);
