import DocumentStatusNotification from 'src/components/DocumentStatusNotification';
import React from 'react';
import { useMediaQuery } from '@material-ui/core';

const DocumentStatusFrame = () => {
  const smallDevice = useMediaQuery(({ breakpoints }) => breakpoints.down('sm'));

  return (
    !smallDevice && (
      <div style={{ margin: '20px 0' }}>
        <DocumentStatusNotification />
      </div>
    )
  );
};

export default DocumentStatusFrame;
