import spacing from '../spacing';
import palette from '../palette';

export default {
  badge: {
    height: spacing(2),
    minWidth: spacing(2),
    fontSize: 8,
    borderRadius: spacing(1),
    background: palette.secondary.main,
    padding: 0,
  },
  dot: {
    height: spacing(1),
    minWidth: spacing(1),
    padding: 0,
    borderRadius: '50%',
  },
  anchorOriginTopRightRectangle: {
    top: spacing(0.25),
    right: spacing(0.25),
    transform: 'scale(1) translate(50%, -50%)',
    transformOrigin: '100% 0%',
    '&$invisible': {
      transform: 'scale(0) translate(50%, -50%)',
    },
  },
  anchorOriginBottomRightRectangle: {
    bottom: spacing(0.25),
    right: spacing(0.25),
    transform: 'scale(1) translate(50%, 50%)',
    transformOrigin: '100% 100%',
    '&$invisible': {
      transform: 'scale(0) translate(50%, 50%)',
    },
  },
  anchorOriginTopLeftRectangle: {
    top: spacing(0.25),
    left: spacing(0.25),
    transform: 'scale(1) translate(-50%, -50%)',
    transformOrigin: '0% 0%',
    '&$invisible': {
      transform: 'scale(0) translate(-50%, -50%)',
    },
  },
  anchorOriginBottomLeftRectangle: {
    bottom: spacing(0.25),
    left: spacing(0.25),
    transform: 'scale(1) translate(-50%, 50%)',
    transformOrigin: '0% 100%',
    '&$invisible': {
      transform: 'scale(0) translate(-50%, 50%)',
    },
  },
};
