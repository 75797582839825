enum avatarConstants {
  GET_USER_AVATAR_REQUEST = 'GET_USER_AVATAR_REQUEST',
  GET_USER_AVATAR_SUCCESS = 'GET_USER_AVATAR_SUCCESS',
  GET_USER_AVATAR_ERROR = 'GET_USER_AVATAR_ERROR',

  PUT_USER_AVATAR_REQUEST = 'PUT_USER_AVATAR_REQUEST',
  PUT_USER_AVATAR_SUCCESS = 'PUT_USER_AVATAR_SUCCESS',
  PUT_USER_AVATAR_ERROR = 'PUT_USER_AVATAR_ERROR',

  DELETE_USER_AVATAR_REQUEST = 'DELETE_USER_AVATAR_REQUEST',
  DELETE_USER_AVATAR_SUCCESS = 'DELETE_USER_AVATAR_SUCCESS',
  DELETE_USER_AVATAR_ERROR = 'DELETE_USER_AVATAR_ERROR',

  GET_WORKSPACE_AVATAR_REQUEST = 'GET_WORKSPACE_AVATAR_REQUEST',
  GET_WORKSPACE_AVATAR_SUCCESS = 'GET_WORKSPACE_AVATAR_SUCCESS',
  GET_WORKSPACE_AVATAR_ERROR = 'GET_WORKSPACE_AVATAR_ERROR',

  PUT_WORKSPACE_AVATAR_REQUEST = 'PUT_WORKSPACE_AVATAR_REQUEST',
  PUT_WORKSPACE_AVATAR_SUCCESS = 'PUT_WORKSPACE_AVATAR_SUCCESS',
  PUT_WORKSPACE_AVATAR_ERROR = 'PUT_WORKSPACE_AVATAR_ERROR',

  DELETE_WORKSPACE_AVATAR_REQUEST = 'DELETE_WORKSPACE_AVATAR_REQUEST',
  DELETE_WORKSPACE_AVATAR_SUCCESS = 'DELETE_WORKSPACE_AVATAR_SUCCESS',
  DELETE_WORKSPACE_AVATAR_ERROR = 'DELETE_WORKSPACE_AVATAR_ERROR',
}

export default avatarConstants;
