import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  () =>
    createStyles({
      root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        overflow: 'hidden',
        width: 110,
        height: 25,
        marginTop: -2,
        marginBottom: -2,
        '& img': {
          display: 'block',
          margin: 'auto',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: 'auto',
          height: 'auto',
          maxWidth: '100%',
          minHeight: '100%',
        },
      },
      large: {
        width: 148,
        height: 34,
        marginTop: -3,
        marginBottom: -3,
      },
      loader: {},
    }),
  { index: 0 }
);
