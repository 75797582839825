import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ breakpoints, spacing }) =>
    createStyles({
      root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        margin: '0 auto',
        minHeight: '100%',
      },
      rootWithScrollableContent: {
        height: '100%',
      },
      scrollableContent: {
        overflowX: 'hidden',
        overflowY: 'auto',
      },
      smallRootSize: {
        maxWidth: breakpoints.values.xs,
      },
      mediumRootSize: {
        maxWidth: breakpoints.values.sm - 100,
      },
      largeRootSize: {
        maxWidth: breakpoints.values.md,
      },
      bigRootSize: {
        maxWidth: breakpoints.values.lg,
      },
      startContent: {
        paddingBottom: spacing(1),
      },
      content: {
        padding: spacing(1, 2, 1, 3),
        marginLeft: spacing(-3),
        width: `calc(100% + ${spacing(5)}px)`,
        [breakpoints.up('md')]: {
          paddingTop: spacing(2),
          paddingBottom: spacing(2),
        },
      },
      endContent: {
        paddingTop: spacing(1),
      },
      noPaddingTop: {
        paddingTop: 0,
      },
      noPaddingBottom: {
        paddingBottom: 0,
      },
    }),
  { index: 0 }
);
