import { RequestStatus } from 'src/helpers/reduxReuquest.util';
import documentDisplayConstants from '../constants/documentDisplay.constants';

const initialState = {
  activeFileIndex: -1,
  allFilesLoadedStatus: false,
  document: {},
  downloadFileStatus: RequestStatus.IDLE,
  downloadFiles: [],
  downloadTokens: [],
  getDocumentStatus: RequestStatus.IDLE,
  getDownloadTokenStatus: RequestStatus.IDLE,
};

const renderTokens = (state, tokens, indexFile) => {
  if (state.activeFileIndex !== indexFile) {
    return [];
  }
  return tokens.map((item) => ({
    token: item.data.data.token,
    page: parseFloat(item.config.url.split('=')[2]),
    indexFile,
  }));
};

const renderDocument = (document) => {
  document.files = document?.files?.sort((a, b) => (a.index > b.index ? 1 : -1)) || [];
  return document;
};

const addEmptyFile = (state, indexFile) => {
  const emptyFile = [
    {
      file: '',
      page: 0,
      indexFile,
    },
  ];
  if (state.document.files) {
    return emptyFile.filter((item) => item.page <= state.document.files[item.indexFile].pages);
  }

  return emptyFile;
};

const renderFiles = (state, files, indexFile) => {
  if (state.activeFileIndex !== indexFile) {
    return [];
  }

  const filesMap = files.map((item, index) => ({
    file: URL.createObjectURL(item.data),
    page:
      state.downloadFiles.filter((item) => item.indexFile === state.activeFileIndex).length + index,
    indexFile,
  }));

  return indexFile > -1
    ? filesMap.filter((item) => item.page < state.document.files[item.indexFile].pages)
    : filesMap;
};

const checkAllFilesLoaded = (state, files) => {
  let allFilesCount = 0;
  state.document?.files?.forEach((file) => {
    if (file.pages === 0) {
      allFilesCount += 1;
    } else {
      allFilesCount += file.pages;
    }
  });
  return state.downloadFiles.length + files.length === allFilesCount;
};

const updateDocumentByWebSocket = (documentState, newDocumentData) => {
  const newDocumentState = documentState;
  newDocumentState.name = newDocumentData.name;
  newDocumentState.tags = newDocumentData.tags;
  newDocumentState.updatedAt = newDocumentData.updatedAt;
  newDocumentState.status = newDocumentData.status;

  return newDocumentState;
};

function documentManagementStore(state = initialState, action) {
  switch (action.type) {
    case documentDisplayConstants.GET_DOCUMENT_REQUEST:
      return {
        ...state,
        getDocumentStatus: RequestStatus.PENDING,
      };
    case documentDisplayConstants.GET_DOCUMENT_SUCCESS:
      return {
        ...state,
        getDocumentStatus: RequestStatus.SUCCESS,
        document: renderDocument(action.data),
        activeFileIndex: action.data?.files?.length ? action.data.files[0].index : -1,
      };
    case documentDisplayConstants.GET_DOCUMENT_FAILURE:
      return {
        ...state,
        getDocumentStatus: RequestStatus.ERROR,
      };
    case documentDisplayConstants.GET_DOWNLOAD_TOKEN_REQUEST:
      return {
        ...state,
        getDownloadTokenStatus: RequestStatus.PENDING,
      };
    case documentDisplayConstants.GET_DOWNLOAD_TOKEN_SUCCESS:
      return {
        ...state,
        getDownloadTokenStatus: RequestStatus.SUCCESS,
        downloadTokens: [
          ...state.downloadTokens,
          ...renderTokens(state, action.data, action.index),
        ],
      };
    case documentDisplayConstants.GET_DOWNLOAD_TOKEN_FAILURE:
      return {
        ...state,
        getDownloadTokenStatus: RequestStatus.ERROR,
      };
    case documentDisplayConstants.DOWNLOAD_FILE_REQUEST:
      return {
        ...state,
        downloadFileStatus: RequestStatus.PENDING,
      };
    case documentDisplayConstants.DOWNLOAD_FILE_SUCCESS:
      return {
        ...state,
        downloadFileStatus: RequestStatus.SUCCESS,
        downloadFiles: [
          ...state.downloadFiles,
          ...renderFiles(state, action.folders, action.index),
        ],
        allFilesLoadedStatus: checkAllFilesLoaded(state, action.folders),
      };
    case documentDisplayConstants.DOWNLOAD_FILE_FAILURE:
      return {
        ...state,
        downloadFileStatus: RequestStatus.ERROR,
      };

    case documentDisplayConstants.ADD_EMPTY_FILE:
      return {
        ...state,
        downloadFiles: [...state.downloadFiles, ...addEmptyFile(state, action.index)],
        allFilesLoadedStatus: checkAllFilesLoaded(state, addEmptyFile(state, action.index)),
      };
    case documentDisplayConstants.SET_ACTIVE_FILE_INDEX:
      return {
        ...state,
        activeFileIndex: action.index,
      };
    case documentDisplayConstants.CLEAR_FILES_AND_TOKENS:
      return {
        ...state,
        getDownloadTokenStatus: RequestStatus.IDLE,
        downloadFileStatus: RequestStatus.IDLE,
        downloadFiles: [],
        downloadTokens: [],
        activeFileIndex: action.index >= 0 ? action.index : -1,
      };

    case documentDisplayConstants.WS_UPDATE_DOCUMENT:
      return {
        ...state,
        document: updateDocumentByWebSocket(state.document, action.documentData),
      };

    case documentDisplayConstants.CLEAR_DOCUMENT_DISPLAY_STORE:
      return {
        ...initialState,
      };

    default:
      return state;
  }
}

export default documentManagementStore;
