import palette from '../palette';
import spacing from '../spacing';

export default {
  root: {
    borderRadius: spacing(0.75),
  },
  grouped: {},
  groupedHorizontal: {
    '&:not(:first-child)': {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
    '&:not(:last-child)': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  groupedOutlinedHorizontal: {
    '&:not(:first-child)': {
      '& *[class*="MuiOutlinedInput-notchedOutline"]': {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderLeftColor: `${palette.bottomLineColor} !important`,
      },
    },
    '&:not(:last-child)': {
      '& *[class*="MuiOutlinedInput-notchedOutline"]': {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      },
    },
  },
};
