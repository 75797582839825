import React from 'react';
import PropTypes from 'prop-types';
import { ListItem } from '@material-ui/core';
import DataTableRow from 'src/components/DataTable/DataTableRow';

import { useStyles } from './styles';

const HeaderSearchItem = ({ item, defaultIcon, cols, smallScreen, iconWidth, rowClasses }) => {
  const tileRowsOnMobile = smallScreen;
  const classes = useStyles();
  if (tileRowsOnMobile) {
    rowClasses.push(classes.itemTileRow);
  }

  return (
    <ListItem
      classes={{ root: classes.listItemRoot, container: classes.listItemContainer }}
      component="div"
      disableGutters
    >
      <DataTableRow
        className={classes.dataTableRow}
        cols={cols}
        row={item}
        rowClasses={rowClasses}
        iconWidth={iconWidth}
        smallScreen={smallScreen}
        tile={tileRowsOnMobile}
        defaultIcon={defaultIcon}
      />
    </ListItem>
  );
};

HeaderSearchItem.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string,
    category: PropTypes.string,
    group: PropTypes.string,
    author: PropTypes.string,
    avatar: PropTypes.string,
    status: PropTypes.string,
    createdAt: PropTypes.number,
    updatedAt: PropTypes.number,
  }),
  defaultIcon: PropTypes.string,
  cols: PropTypes.arrayOf(PropTypes.shape()),
  smallScreen: PropTypes.bool,
  iconWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['auto'])]),
  rowClasses: PropTypes.arrayOf(PropTypes.string),
};

HeaderSearchItem.defaultProps = {
  iconWidth: 'auto',
  rowClasses: [],
};

export default HeaderSearchItem;
