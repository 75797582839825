import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Img from 'react-image';
import { CustomLink } from 'src/components';
import { CircularProgress } from '@material-ui/core';
import { useStyles } from './styles';

const Logo = ({ className: classNameProp, variant, size }) => {
  const classes = useStyles();
  const [didMount, setDidMount] = useState(false);
  const largeSize = size === 'large';

  useEffect(() => {
    setDidMount(true);
    return () => setDidMount(false);
  }, []);

  if (!didMount) {
    return null;
  }
  return (
    <CustomLink to="/">
      <Img
        useSuspense={false}
        src={variant === 'default' ? '/images/logo.svg' : '/images/logo_white.svg'}
        container={(children) => (
          <div className={clsx(classes.root, largeSize && classes.large, classNameProp)}>
            {children}
          </div>
        )}
        loader={
          <div className={classes.root}>
            <div className={classes.loader}>
              <CircularProgress size={16} />
            </div>
          </div>
        }
      />
    </CustomLink>
  );
};

Logo.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(['default', 'inverted']),
  size: PropTypes.oneOf(['default', 'large']),
};
Logo.defaultProps = {
  variant: 'default',
  size: 'default',
};

export default Logo;
