import React, { useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { RootState } from 'src/redux/reducers';
import { BillingProductStatus, BillingProductType } from '../../models/billing.model';
import { TemplateDocumentType } from '../../models/documents.model';
import { useSubscriptionModalStyles } from './styles';
import { SubscriptionChangeType, userComActions } from '../../redux/actions/userCom.actions';
import billingActions from '../../redux/actions/billing.actions';
import { IdentitySelector } from '../../redux/selectors/identity.selector';
import { RequestStatus } from '../../helpers/reduxReuquest.util';

interface SubscriptionModalProps {
  onClose: () => void;
  templateDocument: TemplateDocumentType | null;
}
const SubscriptionModal = ({ onClose, templateDocument }: SubscriptionModalProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isCustomerCreated, setCustomerStatus, setTrialSubscriptionStatus } = useSelector(
    (state: RootState) => state.billingStore
  );
  const { isOwner } = useSelector((state: RootState) => state.identityStore, undefined);
  const workspaceId = useSelector(IdentitySelector.selectWorkspaceId);
  const classes = useSubscriptionModalStyles();
  const { subscriptions } = useSelector((state: RootState) => state.billingStore);
  const esignProduct = subscriptions.find((el) => el.productType === BillingProductType.EsignDrive);
  const certProduct = subscriptions.find(
    (el) => el.productType === BillingProductType.Certificates
  );
  const [startTrialLoading, setStartTrialLoading] = useState<boolean>(false);
  const workspaceDetails = useSelector(IdentitySelector.selectWorkspaceDetails);
  const workspaceName = workspaceDetails?.name || '';
  const isCustomerCreating = setCustomerStatus === RequestStatus.PENDING;

  const selectedProduct =
    templateDocument === TemplateDocumentType.CERTIFICATES ? certProduct : esignProduct;
  const productType =
    templateDocument === TemplateDocumentType.CERTIFICATES
      ? BillingProductType.Certificates
      : BillingProductType.EsignDrive;

  // on Start trial clicked
  const onStartTrial = () => {
    setStartTrialLoading(true);
    if (!workspaceId) return;
    if (isCustomerCreated) {
      userComActions.subscription.subTrialStarted(productType, workspaceId);
      dispatch(billingActions.setTrialSubscription(workspaceId, productType));
    } else {
      dispatch(
        billingActions.setCustomer(workspaceId, workspaceName, () => {
          userComActions.subscription.subTrialStarted(productType, workspaceId);
          dispatch(billingActions.setTrialSubscription(workspaceId, productType));
        })
      );
    }
  };

  const onStartChatWithSales = () => {
    if (!workspaceId) return;
    if (selectedProduct && selectedProduct.status === BillingProductStatus.TrialFinished) {
      userComActions.subscription.subChangesNeeded(
        productType,
        SubscriptionChangeType.PurchaseAfterTrial,
        workspaceId
      );
    }
    if (selectedProduct && selectedProduct.status === BillingProductStatus.Canceled) {
      userComActions.subscription.subChangesNeeded(
        productType,
        SubscriptionChangeType.NewPurchase,
        workspaceId
      );
    }
    onClose();
  };

  // Close if trial is started successfully
  useEffect(() => {
    if (setTrialSubscriptionStatus === RequestStatus.SUCCESS && startTrialLoading) {
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setTrialSubscriptionStatus]);

  return (
    <Dialog open={Boolean(templateDocument)} onClose={onClose}>
      <DialogContent className={classes.content}>
        <Typography className={classes.title} variant="h2">
          {isOwner ? (
            <>
              {templateDocument === TemplateDocumentType.CERTIFICATES && !certProduct && (
                <Trans
                  i18nKey="subscriptionModal.trialNotStarted.certificate.title"
                  components={{ span: <span className={classes.certColor} /> }}
                />
              )}
              {templateDocument &&
                [
                  TemplateDocumentType.DURABLE_MEDIA,
                  TemplateDocumentType.DOCUMENT_TO_BE_SIGNED,
                ].includes(templateDocument) &&
                !esignProduct && (
                  <Trans
                    i18nKey="subscriptionModal.trialNotStarted.esign.title"
                    components={{ span: <span className={classes.esignColor} /> }}
                  />
                )}
              {selectedProduct &&
                selectedProduct.status === BillingProductStatus.TrialFinished &&
                t<string>('subscriptionModal.trialCompleted.title')}
              {selectedProduct &&
                selectedProduct.status === BillingProductStatus.Canceled &&
                t<string>('subscriptionModal.subscriptionCanceled.title')}
            </>
          ) : (
            <>{t<string>('subscriptionModal.subscriptionNotActiveForMember.title')}</>
          )}
        </Typography>

        <Typography variant="body1" className={classes.description}>
          {isOwner ? (
            <>
              {selectedProduct &&
                selectedProduct.status === BillingProductStatus.TrialFinished &&
                t<string>('subscriptionModal.trialCompleted.description')}
              {selectedProduct &&
                selectedProduct.status === BillingProductStatus.Canceled &&
                t<string>('subscriptionModal.subscriptionCanceled.description')}
              {templateDocument === TemplateDocumentType.CERTIFICATES &&
                !certProduct &&
                t<string>('subscriptionModal.trialNotStarted.certificate.description')}

              {templateDocument &&
                [
                  TemplateDocumentType.DURABLE_MEDIA,
                  TemplateDocumentType.DOCUMENT_TO_BE_SIGNED,
                ].includes(templateDocument) &&
                !esignProduct &&
                t<string>('subscriptionModal.trialNotStarted.esign.description')}
            </>
          ) : (
            <>{t<string>('subscriptionModal.subscriptionNotActiveForMember.description')}</>
          )}
        </Typography>
      </DialogContent>

      <DialogActions className={classes.buttons}>
        {isOwner ? (
          <>
            {selectedProduct ? (
              <Button fullWidth className={classes.confirmButton} onClick={onStartChatWithSales}>
                {t<string>('subscriptionModal.startChatWithSales')}
              </Button>
            ) : (
              <Button
                disabled={startTrialLoading || isCustomerCreating}
                onClick={onStartTrial}
                fullWidth
                className={classes.confirmButton}
                endIcon={startTrialLoading && <CircularProgress size={20} color="inherit" />}
              >
                {t<string>('subscriptionModal.startTrial')}
              </Button>
            )}
            <Button
              disabled={startTrialLoading || isCustomerCreating}
              onClick={onClose}
              fullWidth
              variant="text"
              color="secondary"
            >
              {t<string>('common.cancel')}
            </Button>
          </>
        ) : (
          <Button onClick={onClose} fullWidth>
            {t<string>('common.cancel')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default SubscriptionModal;
