import typography from '../typography';
import palette from '../palette';

export default {
  root: {
    '&:hover > *': {
      color: 'inherit',
    },
    '&:focus > *': {
      color: 'inherit',
    },
  },
  active: {
    color: palette.primary.main,
    fontWeight: typography.fontWeightMedium,
    '&:hover': {
      color: palette.primary.dark,
    },
    '&:focus': {
      color: palette.primary.dark,
    },
    '& $icon': {
      opacity: 1,
    },
    '& > *': {
      color: 'inherit',
    },
  },
  icon: {
    opacity: 0.75,
    marginRight: 2,
    marginLeft: 2,
  },
};
