import React from 'react';
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon';

export default createSvgIcon(
  <>
    <g clipPath="url(#clip0_6814_7229)">
      <path
        d="M20.0007 7.91825V17.2984C20.0007 18.5324 19.0001 19.5328 17.7659 19.5326H2.4707C3.70487 19.5328 4.70549 18.5324 4.70549 17.2984V7.91825C4.70528 6.68429 5.70569 5.68388 6.93966 5.68408H17.7659C19.0001 5.68388 20.0007 6.68408 20.0007 7.91825Z"
        fill="#005DFF"
        fillOpacity="0.75"
      />
      <path
        d="M17.2595 5.22888V5.68406H6.93894C5.70498 5.68385 4.70457 6.68426 4.70477 7.91823V17.2984C4.70477 18.5323 3.70415 19.5327 2.46998 19.5325H2.23479C1.00062 19.5327 0 18.5323 0 17.2984V2.65507C0 1.4209 1.00062 0.420693 2.23479 0.420898H7.24794C7.87541 0.420898 8.47389 0.684673 8.89741 1.14767L10.419 2.81255C10.5249 2.9283 10.6745 2.9945 10.8316 2.9945H15.0247C16.2588 2.99429 17.2595 3.99471 17.2595 5.22888Z"
        fill="#005DFF"
      />
    </g>
    <defs>
      <clipPath id="clip0_6814_7229">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </>,
  'FolderColor'
);
