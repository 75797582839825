import moment from 'moment/moment';
import { UserManagerPerson } from './documentUsersManagement.model';
import { DateDropdownType } from '../components/DateDropdown/DateDropdown';

type DocumentRole = {
  admins: string[];
  auditors?: string[];
  editors?: string[];
  signers: string[];
  viewers?: string[];
  holder?: string[];
};

export enum DocumentStatus {
  EDITING = 'editing',
  SIGNING = 'signing',
  ISSUING = 'issuing',
  REJECTED = 'rejected',
  VALID = 'valid',
  INVALIDATED = 'invalidated',
  TEMPORARILY_INVALIDATE = 'temporarilyInvalidated',
  EXPIRED = 'expired',
  READY_FOR_PREVIEW = 'readyForPreview',
  FINISHED = 'finished',
}
export enum DocumentItemCategoryType {
  FOLDERS = 'folders',
  DOCUMENTS = 'documents',
}

export interface DocumentItem {
  author: string; // Identifier of document author
  createdAt: number; // Timestamp of creation
  documentType: string; // Document type (dsl or legacy-esign or legacy-drive)
  folderId: string; // Identifier of file directory
  folderName: string; // Name of directory
  id: string; // Document Identifier
  name: string; // Document name
  roles: DocumentRole; // Roles with Id's
  status: DocumentStatus; // Current document status
  rejected: string[]; // List of identifier
  singed: string[]; // List of identifier
  tags: string[]; // List of identifier
  updatedAt: number; // Timestamp of last update
  workspaceId: string; // Workspace id
}

export interface FolderItem {
  createdAt: number; // Timestamp of creation
  createdBy: string; // Identifier of user who created directory
  id: string; // Folder identifier
  isRoot: boolean; // Boolean if is root directory
  isTrash: boolean; // Boolean if is trash
  name: string; // Folder name
  parent: string; // Identifier of parent folder
  updatedAt: number; // Timestamp of last update
  workspaceId: string; // Workspace id
}

export interface SearchedItem {
  category: DocumentItemCategoryType; // Searched item category
  createdBy?: string; // Identifier of user who created directory
  isRoot?: boolean; // Boolean if is root directory
  isTrash?: boolean; // Boolean if is trash
  parent?: string; // Identifier of parent folder
  author?: string; // Identifier of document author
  createdAt: number; // Timestamp of creation
  documentType?: string; // Document type (dsl or legacy-esign or legacy-drive)
  folderId?: string; // Identifier of file directory
  folderName?: string; // Name of directory
  id: string; // Document Identifier
  name: string; // Document name
  roles?: DocumentRole; // Roles with Id's
  status?: DocumentStatus; // Current document status
  rejected?: string[]; // List of identifier
  singed?: string[]; // List of identifier
  tags?: string[]; // List of identifier
  updatedAt: number; // Timestamp of last update
  workspaceId: string; // Workspace id
}

export interface FolderTreeItem {
  createdAt: number; // Timestamp of creation
  createdBy: string; // Identifier of user who created
  id: string; // Identifier of folder
  isRoot: boolean; // Boolean if folder is root
  isTrash: boolean; // Boolean if folder is trash
  name: string; // Name of folder
  updatedAt: number; // Timestamp of last update
  workspaceId: string; // Workspace id
}

export const mapDocumentsItemToSearchedItem = (arr: DocumentItem[]): SearchedItem[] =>
  arr.map((el) => ({
    ...el,
    category: DocumentItemCategoryType.DOCUMENTS,
  }));

export const mapFolderItemsToSearchedItem = (arr: FolderItem[]): SearchedItem[] =>
  arr.map((el) => ({
    ...el,
    category: DocumentItemCategoryType.FOLDERS,
  }));

export enum AdvancedSearchFields {
  NAME = 'name',
  TYPE = 'documentType',
  STATUS = 'status',
  DATE_CREATED = 'dateCreated',
  DATE_MODIFIED = 'dateModified',
  RECIPIENTS = 'recipients',
  LOCATION = 'location',
}

export enum DocumentType {
  DSL = 'dsl',
  ESIGN = 'legacy-esign',
  DRIVE = 'legacy-drive',
}

export enum ExtraDocumentType {
  FOLDER = 'folder',
}

export type FolderPathType = { idPath: string; namePath: string };

type DateRangeType = {
  type: DateDropdownType;
  from: moment.Moment;
  to: moment.Moment;
};

export type AdvancedSearchType = {
  [AdvancedSearchFields.NAME]: string;
  [AdvancedSearchFields.TYPE]: Array<DocumentType | ExtraDocumentType>;
  [AdvancedSearchFields.STATUS]: DocumentStatus[];
  [AdvancedSearchFields.DATE_CREATED]: DateRangeType;
  [AdvancedSearchFields.DATE_MODIFIED]: DateRangeType;
  [AdvancedSearchFields.RECIPIENTS]: UserManagerPerson[];
  [AdvancedSearchFields.LOCATION]: FolderPathType | null;
};

export type AdvancedDocumentsQueryType = {
  hasMoreDocuments: boolean;
  limit: number;
  offset: number;
};
export type DocumentsQueryType = {
  limit: number;
  offset: number;
};

type queryDateType = {
  from: number;
  to: number;
};
const getDateFromDateRangeType = (dateType: DateRangeType): queryDateType | null => {
  switch (dateType.type) {
    case DateDropdownType.ANY:
      return null;
    case DateDropdownType.TODAY:
      return {
        from: moment().startOf('day').valueOf(),
        to: moment().endOf('day').valueOf(),
      };
    case DateDropdownType.YESTERDAY:
      return {
        from: moment().subtract(1, 'days').startOf('day').valueOf(),
        to: moment().subtract(1, 'days').endOf('day').valueOf(),
      };
    case DateDropdownType.LAST_7_DAYS:
      return {
        from: moment().subtract(7, 'days').startOf('day').valueOf(),
        to: moment().endOf('day').valueOf(),
      };
    case DateDropdownType.LAST_30_DAYS:
      return {
        from: moment().subtract(30, 'days').startOf('day').valueOf(),
        to: moment().endOf('day').valueOf(),
      };
    case DateDropdownType.BETWEEN:
      return {
        from: moment(dateType.from).valueOf(),
        to: moment(dateType.to).valueOf(),
      };
    default:
      return null;
  }
};
export const advancedSearchTypeToQuery = (
  search: AdvancedSearchType,
  offset: number,
  sortKey: string,
  sortOrder: string
): string => {
  const name = search[AdvancedSearchFields.NAME];
  const type = search[AdvancedSearchFields.TYPE].filter((el) => el !== ExtraDocumentType.FOLDER);
  const status = search[AdvancedSearchFields.STATUS];
  const dateCreated = getDateFromDateRangeType(search[AdvancedSearchFields.DATE_CREATED]);

  const dateModified = getDateFromDateRangeType(search[AdvancedSearchFields.DATE_MODIFIED]);
  const recipients = search[AdvancedSearchFields.RECIPIENTS];
  const location = search[AdvancedSearchFields.LOCATION];

  const query = {
    limit: 20,
    ...(location && { folderDepth: 10000 }),
    offset,
    ...(location && { folderIdLst: [location.idPath] }),
    ...(name.length > 0 && { document: name }),
    ...(type.length > 0 && { documentType: type }),
    ...(status.length > 0 && {
      status: status.map((el) => {
        if (el === DocumentStatus.READY_FOR_PREVIEW) {
          return DocumentStatus.EDITING;
        }
        return el;
      }),
    }),
    ...(dateCreated !== null && {
      createdAt: {
        from: dateCreated.from,
        to: dateCreated.to,
      },
    }),
    ...(dateModified !== null && {
      updatedAt: {
        from: dateModified.from,
        to: dateModified.to,
      },
    }),
    ...(recipients.length > 0 && { anyOfRecipients: recipients.map((el) => el._id) }),
    sortBy: {
      [sortKey]: sortOrder,
    },
  };

  return encodeURIComponent(JSON.stringify(query));
};

// Document type for document creation
export enum TemplateDocumentType {
  DOCUMENT_TO_BE_SIGNED = 'DOCUMENT_TO_BE_SIGNED',
  DURABLE_MEDIA = 'DURABLE_MEDIA',
  CERTIFICATES = 'CERTIFICATES',
}

export function areAdvancedSearchObjectsDifferent(
  obj1: AdvancedSearchType | null,
  obj2: AdvancedSearchType | null,
  excludeKeys: AdvancedSearchFields[] = []
): boolean {
  if (obj1 === null && obj2 === null) {
    return false;
  }

  if (obj1 === null || obj2 === null) {
    return true;
  }

  const keys = Object.values(AdvancedSearchFields).filter((key) => !excludeKeys.includes(key));

  for (const key of keys) {
    const value1 = obj1[key];
    const value2 = obj2[key];

    if (key === AdvancedSearchFields.DATE_CREATED || key === AdvancedSearchFields.DATE_MODIFIED) {
      if (!obj1[key].from.isSame(obj2[key].from) || !obj1[key].to.isSame(obj2[key].to)) {
        return true;
      }
    } else if (Array.isArray(value1) && Array.isArray(value2)) {
      if (value1.length !== value2.length || value1.some((el, index) => el !== value2[index])) {
        return true;
      }
    } else if (value1 !== value2) {
      return true;
    }
  }

  return false;
}
