import { useEffect, useRef } from 'react';
import WebSocketHelper from 'src/helpers/WebSocketHelper';
import { useDispatch } from 'react-redux';
import communicationsActions from '../../redux/actions/communications.actions';

const CertificateActionsWebsocket = (): null => {
  const ws = useRef(null);
  const dispatch = useDispatch();

  const onWebSocketMessage = (counter: number) => {
    dispatch(communicationsActions.updateUnreadSystemMessagesCounter(counter));
  };

  useEffect(() => {
    ws.current = WebSocketHelper.subscribe(
      'communications-module',
      `unread-system-messages`,
      (message: number) => {
        onWebSocketMessage(message);
      }
    );

    return () => {
      WebSocketHelper.unsubscribe(ws.current);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

export default CertificateActionsWebsocket;
