import React from 'react';
import { Button, DialogActions } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/redux/reducers';
import { useHistory } from 'react-router-dom';
import documentsActions from 'src/redux/actions/documents.actions';

type AdvancedSearchModalActionsProps = {
  onClose: () => void;
};

const AdvancedSearchModalActions = ({ onClose }: AdvancedSearchModalActionsProps): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const { draftAdvancedSearch } = useSelector((state: RootState) => state.documentsStore);

  const onReset = () => {
    dispatch(documentsActions.resetDraftAdvancedSearch());
  };

  const onSubmit = () => {
    dispatch(documentsActions.submitAdvancedSearch(draftAdvancedSearch));
    onClose();
    history.push('/search');
  };

  return (
    <DialogActions>
      <Button variant="text" onClick={onReset}>
        {t<string>('common.reset')}
      </Button>
      <Button onClick={onSubmit}>{t<string>('common.apply')}</Button>
    </DialogActions>
  );
};

export default AdvancedSearchModalActions;
