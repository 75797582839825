import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ breakpoints, spacing }) =>
    createStyles({
      root: {},
      smallSize: {},
      mediumSize: {},
      largeSize: {},
      topVertical: {
        paddingTop: spacing(2),
        '&$mediumSize': {
          [breakpoints.up('md')]: {
            paddingTop: spacing(2.5),
          },
          [breakpoints.up('lg')]: {
            paddingTop: spacing(3.5),
          },
        },
        '&$largeSize': {
          [breakpoints.up('md')]: {
            paddingTop: spacing(3),
          },
          [breakpoints.up('lg')]: {
            paddingTop: spacing(5),
          },
        },
      },
      bottomVertical: {
        paddingBottom: spacing(2),
        '&$mediumSize': {
          [breakpoints.up('md')]: {
            paddingBottom: spacing(2.5),
          },
          [breakpoints.up('lg')]: {
            paddingBottom: spacing(3.5),
          },
        },
        '&$largeSize': {
          [breakpoints.up('md')]: {
            paddingBottom: spacing(3),
          },
          [breakpoints.up('lg')]: {
            paddingBottom: spacing(5),
          },
        },
      },
      leftHorizontal: {
        paddingLeft: spacing(2),
        '&$mediumSize': {
          [breakpoints.up('md')]: {
            paddingLeft: spacing(2.5),
          },
          [breakpoints.up('lg')]: {
            paddingLeft: spacing(3.5),
          },
        },
        '&$largeSize': {
          [breakpoints.up('md')]: {
            paddingLeft: spacing(3),
          },
          [breakpoints.up('lg')]: {
            paddingLeft: spacing(5),
          },
        },
      },
      rightHorizontal: {
        paddingRight: spacing(2),
        '&$mediumSize': {
          [breakpoints.up('md')]: {
            paddingRight: spacing(2.5),
          },
          [breakpoints.up('lg')]: {
            paddingRight: spacing(3.5),
          },
        },
        '&$largeSize': {
          [breakpoints.up('md')]: {
            paddingRight: spacing(3),
          },
          [breakpoints.up('lg')]: {
            paddingRight: spacing(5),
          },
        },
      },
    }),
  { index: 0 }
);
