import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button,
  FormControl,
  TextField,
  Typography,
  ClickAwayListener,
  FormHelperText,
} from '@material-ui/core';
import { Publish } from 'src/components/CustomIcon';
import { useTranslation } from 'react-i18next';
import MomentAdapter from '@material-ui/pickers/adapter/moment';
import { LocalizationProvider, DesktopDatePicker } from '@material-ui/pickers';
import clsx from 'clsx';
import moment from 'moment';
import { toBase64 } from 'src/helpers/globalUtils';
import { RegexFormulas } from 'src/helpers/regex';
import snackbarActions from 'src/redux/actions/snackbar.actions';
import { SNACKBAR_VARIANT } from 'src/models/common.model';
import { useStyles } from './styles';

const MapField = ({
  label,
  name,
  index,
  placeholder,
  format,
  error,
  helperText,
  onFieldChange,
  value,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [fileName, setFileName] = useState('');
  const [date, setDate] = useState(new Date(value));

  const classes = useStyles();
  const isImportedFile =
    format === 'base64' && value && RegexFormulas.base64.test(value.slice(0, 500)) && value !== '';
  const base64Placeholder = isImportedFile
    ? t('dropzones.importedFile')
    : t('createCertificate.selectFile');

  const onChange = (e) => {
    onFieldChange(name, e.target.value);
  };

  const onDateChange = (e) => {
    setDate(e);
    onFieldChange(name, e);
  };

  const FileChange = (e, file) => {
    if (file) {
      if (file.size > 7340032) {
        dispatch(
          snackbarActions.enqueueSnackbar(
            SNACKBAR_VARIANT.ERROR,
            t('dropzones.fileTooLarge', { value: 7 })
          )
        );
      } else {
        setFileName(file.name);
        toBase64(file).then((base64) => {
          onFieldChange(name, base64.split(',')[1]);
        });
      }
    }
  };

  const handleClickAway = () => {
    if (datePickerOpen) {
      setDatePickerOpen(false);
    }
  };

  return (
    <FormControl className={classes.formControl} key={`form_control_${name}`}>
      <Typography className={classes.label} color="textSecondary" mb={16}>
        {label}
      </Typography>
      {(() => {
        switch (format) {
          case 'date':
            return (
              <ClickAwayListener onClickAway={handleClickAway}>
                <div>
                  <LocalizationProvider dateAdapter={MomentAdapter}>
                    <DesktopDatePicker
                      open={datePickerOpen}
                      onClose={() => setDatePickerOpen(false)}
                      onOpen={() => setDatePickerOpen(true)}
                      minDate={name === 'expiresOn' ? moment().add(1, 'days') : undefined}
                      inputFormat="yyyy-MM-DD"
                      mask="____-__-__"
                      onChange={onDateChange}
                      value={date}
                      renderInput={(props) => (
                        <TextField {...props} error={error} helperText={helperText} />
                      )}
                    />
                  </LocalizationProvider>
                </div>
              </ClickAwayListener>
            );
          case 'base64':
            return (
              <>
                <Button
                  htmlFor={`upload_${name}_${index}`}
                  component="label"
                  variant="text"
                  className={clsx(
                    classes.buttonUpload,
                    (fileName !== '' || isImportedFile) && classes.buttonUploadActive,
                    error ? classes.buttonUploadError : ''
                  )}
                  endIcon={<Publish />}
                >
                  {fileName === '' ? base64Placeholder : fileName}
                </Button>
                <FormHelperText error={error}>{helperText}</FormHelperText>
                <input
                  type="file"
                  hidden
                  id={`upload_${name}_${index}`}
                  accept="image/jpeg,image/png,.jpeg,.jpg,.png"
                  onChange={(e) => FileChange(e, e.target.files[0])}
                />
              </>
            );

          default:
            return (
              <TextField
                onChange={onChange}
                name={name}
                value={value}
                placeholder={placeholder}
                error={error}
                helperText={helperText}
              />
            );
        }
      })()}
    </FormControl>
  );
};

MapField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  index: PropTypes.number,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  format: PropTypes.string,
  onFieldChange: PropTypes.func.isRequired,
};

export default MapField;
