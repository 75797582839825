import { SNACKBAR_VARIANT } from 'src/models/common.model';
import documentDisplayConstants from '../constants/documentDisplay.constants';
import documentCoreService from '../services/documentCore.service';
import snackbarActions from './snackbar.actions';
import i18n from '../../helpers/i18n';
import filesModuleService from '../services/filesModule.service';

const documentDisplayActions = {
  clearDocumentDisplayStore,
  clearFilesAndTokens,
  getDocument,
  getDownloadTokens,
  downloadFiles,
  setActiveFileIndex,
  addEmptyFile,
  websocketUpdateDocument,
};

function getDocument(documentId, service) {
  return (dispatch) => {
    dispatch({ type: documentDisplayConstants.GET_DOCUMENT_REQUEST });
    documentCoreService
      .getDocument(documentId, service)
      .then((response) => {
        dispatch({
          type: documentDisplayConstants.GET_DOCUMENT_SUCCESS,
          data: response.data,
        });
      })
      .catch((error) => {
        dispatch({ type: documentDisplayConstants.GET_DOCUMENT_FAILURE });
        dispatch(
          snackbarActions.enqueueSnackbar(
            SNACKBAR_VARIANT.ERROR,
            i18n.t('snackbars.common.errorTitle'),
            `${i18n.t('snackbars.common.error')} ${error.code}.`
          )
        );
      });
  };
}

function getDownloadTokens(documentId, index, startPage, page, service) {
  const tokenPromises = [];

  for (let i = startPage; i < page; i += 1) {
    tokenPromises.push(documentCoreService.getDownloadToken(documentId, index, i, service));
  }

  return (dispatch) => {
    dispatch({ type: documentDisplayConstants.GET_DOWNLOAD_TOKEN_REQUEST });
    Promise.all(tokenPromises)
      .then((response) => {
        dispatch({
          type: documentDisplayConstants.GET_DOWNLOAD_TOKEN_SUCCESS,
          data: response,
          index,
        });
      })
      .catch(() => {
        dispatch({ type: documentDisplayConstants.GET_DOWNLOAD_TOKEN_FAILURE });
      });
  };
}

function downloadFiles(tokens, index) {
  const downloadFilePromises = tokens.map((token) => filesModuleService.downloadFile(token.token));
  return (dispatch) => {
    dispatch({ type: documentDisplayConstants.DOWNLOAD_FILE_REQUEST });
    Promise.all(downloadFilePromises)
      .then((response) => {
        dispatch({
          type: documentDisplayConstants.DOWNLOAD_FILE_SUCCESS,
          folders: response,
          index,
        });
      })
      .catch(() => {
        dispatch({ type: documentDisplayConstants.DOWNLOAD_FILE_FAILURE });
      });
  };
}

function addEmptyFile(index) {
  return { type: documentDisplayConstants.ADD_EMPTY_FILE, index };
}

function setActiveFileIndex(index) {
  return { type: documentDisplayConstants.SET_ACTIVE_FILE_INDEX, index };
}

function clearFilesAndTokens(index) {
  return { type: documentDisplayConstants.CLEAR_FILES_AND_TOKENS, index };
}

function clearDocumentDisplayStore() {
  return { type: documentDisplayConstants.CLEAR_DOCUMENT_DISPLAY_STORE };
}

function websocketUpdateDocument(documentData) {
  return { type: documentDisplayConstants.WS_UPDATE_DOCUMENT, documentData };
}

export default documentDisplayActions;
