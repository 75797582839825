import { FullScreenDialog } from 'src/components';

import React from 'react';
import { useHistory } from 'react-router-dom';
import StepResolver from './components/resolver/StepResolver';
import { StepperContextProvider } from './components/context/SteperContext';

import { useStyles } from './styles';

const TransferOwnership = () => {
  const classes = useStyles();
  const history = useHistory();

  const handleClose = () => {
    history.goBack();
  };

  return (
    <section>
      <StepperContextProvider>
        <FullScreenDialog
          className={classes.pageWrapper}
          isOpen={Boolean(true)}
          onClose={handleClose}
          withLogo={false}
        >
          <StepResolver />
        </FullScreenDialog>
      </StepperContextProvider>
    </section>
  );
};

export default TransferOwnership;
