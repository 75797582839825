import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import { SmallContentWithScrollbar } from 'src/components';
import { Success as SuccessIcon, Alert as ErrorIcon } from 'src/components/CustomIcon';
import parsePhoneNumber from 'libphonenumber-js/mobile';
import 'react-phone-input-2/lib/material.css';
import './react-phone.css';
import { useStyles } from './styles';

// TODO: move validation logic into formValidationRules
const PhoneEdit = ({ updateBasicData }) => {
  const { i18n, t } = useTranslation();
  const classes = useStyles();
  const { language } = i18n;
  const [updatedPhone, setUpdatedPhone] = useState('');
  const [phoneCountry, setPhoneCountry] = useState('');
  const [initialCountry, setInitialCountry] = useState('');
  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    if (language === 'en') {
      setInitialCountry('gb');
    } else {
      setInitialCountry(language);
    }
  }, [language]);

  const handleInputChange = (value, country) => {
    setUpdatedPhone(value);
    setPhoneCountry(country);
    if (country.countryCode) {
      const test = parsePhoneNumber(value, country.countryCode.toUpperCase());
      if (test && test.isValid()) {
        setIsPhoneValid(true);
      } else {
        setIsPhoneValid(false);
      }
    }
  };

  const validatePhoneNumber = (value, country) => {
    const input = document.getElementsByClassName('form-control')[0];
    let isValid = true;
    if (updatedPhone && updatedPhone.length > 0 && country) {
      const test = parsePhoneNumber(value, country.iso2.toUpperCase());
      if (test) {
        isValid = test.isValid();
        if (!isValid && formSubmitted) {
          input.classList.add('invalid-number-form-submitted');
        }
        if (isValid) {
          input.classList.add('valid-number');
        }
      }
    }
    return isValid;
  };

  const splitPrefixFromPhone = () => ({
    phoneNumber: {
      number: updatedPhone.substr(phoneCountry.dialCode.length + 1),
      prefix: `+${phoneCountry.dialCode}`,
    },
  });

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setFormSubmitted(true);
    if (isPhoneValid) {
      updateBasicData('phoneNumber', splitPrefixFromPhone());
    }
  };

  return (
    <SmallContentWithScrollbar
      startContent={<Typography>{t('userConfigurationPage.basic.phonePage.title')}</Typography>}
      endContent={
        <Button size="large" type="submit" fullWidth>
          {t('userConfigurationPage.basic.phonePage.button')}
        </Button>
      }
      hasForm
      formProps={{
        noValidate: true,
        autoComplete: 'off',
        onSubmit: handleFormSubmit,
      }}
    >
      <div style={{ position: 'relative' }}>
        <PhoneInput
          searchPlaceholder={t('userConfigurationPage.basic.phonePage.searchPlaceholder')}
          searchNotFound={t('userConfigurationPage.basic.phonePage.emptyResults')}
          placeholder={t('userConfigurationPage.basic.phonePage.title')}
          country={initialCountry}
          value={updatedPhone}
          // onFocus={() => setCloseVisible(false)}
          onChange={(val, country) => handleInputChange(`+${val}`, country)}
          enableSearch
          disableSearchIcon
          specialLabel=""
          copyNumbersOnly={false}
          // regions="europe"
          isValid={(val, country) => validatePhoneNumber(`+${val}`, country)}
          // onKeyDown={handleKeyDown}
        />
        {isPhoneValid && (
          <div className={classes.adornmentContainer}>
            <SuccessIcon className={classes.validIcon} />
          </div>
        )}
        {!isPhoneValid && formSubmitted && (
          <div className={classes.adornmentContainer}>
            <ErrorIcon className={classes.invalidIcon} />
          </div>
        )}
      </div>
    </SmallContentWithScrollbar>
  );
};

PhoneEdit.propTypes = {
  phoneNumber: PropTypes.shape({
    prefix: PropTypes.string,
    number: PropTypes.string,
  }),
  updateBasicData: PropTypes.func,
};

export default PhoneEdit;
