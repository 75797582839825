import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const dateLong = 'LL';
const dateLongShortMonth = 'll';
const dateShort = 'yyyy-MM-DD';
const timeLong = 'LTS';
const timeShort = 'LT';

export const DateTimeFormats = {
  dateLong,
  dateLongShortMonth,
  dateShort,
  timeLong,
  timeShort,
};

// English
// January 3, 2023 at 6:04:23 PM UTC // dateFormat: dateLong, timeFormat: timeLong
// January 3, 2023 at 6:04:23 PM     // dateFormat: dateLong, timeFormat: timeLong
// January 3, 2023 at 6:04 PM        // dateFormat: dateLong, timeFormat: timeShort
// Jan 3, 2023 at 06:04 PM           // dateFormat: dateLongShortMonth, timeFormat: timeShort
// 2023-01-03                        // dateFormat: dateShort
//
// Polish
// 3 stycznia 2023 o 18:01:25 CET   // dateFormat: dateLong, timeFormat: timeLong
// 3 stycznia 2023 o 18:01:25       // dateFormat: dateLong, timeFormat: timeLong
// 3 stycznia 2023 o 18:01          // dateFormat: dateLong, timeFormat: timeShort
// 3 sty 2023 o 18:01               // dateFormat: dateLongShortMonth, timeFormat: timeShort
// 2023-01-03                       // dateFormat: dateShort

export const FormatDate = ({
  date = new Date(),
  dateFormat = DateTimeFormats.dateShort,
  timeFormat,
}) => {
  const { t } = useTranslation();
  let finalDate = moment(date).format(dateFormat);

  if (timeFormat) {
    finalDate = finalDate.concat(` ${t('common.at')} ${moment(date).format(timeFormat)}`);
  }

  return <>{finalDate}</>;
};

FormatDate.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  date: PropTypes.object,
  dateFormat: PropTypes.string,
  timeFormat: PropTypes.string,
};
