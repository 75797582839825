import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Typography } from '@material-ui/core';
import { useStyles } from './styles';

const ContentHeader = ({
  className: classNameProp,
  children,
  subtitle,
  variant,
  color,
  margin,
}) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, {
        [classes[`${margin}Margin`]]: margin,
      })}
    >
      <Typography
        variant={variant}
        color={color}
        className={classNameProp}
        gutterBottom={!!subtitle}
      >
        {children}
      </Typography>

      {subtitle && <Typography variant="body1">{subtitle}</Typography>}
    </div>
  );
};

ContentHeader.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  subtitle: PropTypes.string,
  variant: PropTypes.oneOfType([
    PropTypes.oneOf([
      'body1',
      'body2',
      'button',
      'caption',
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
      'inherit',
      'overline',
      'subtitle1',
      'subtitle2',
    ]),
    PropTypes.string,
  ]),
  color: PropTypes.oneOf([
    'error',
    'inherit',
    'initial',
    'primary',
    'secondary',
    'textPrimary',
    'textSecondary',
  ]),
  margin: PropTypes.oneOf([false, 'small', 'medium', 'large']),
};

ContentHeader.defaultProps = {
  variant: 'h3',
  color: 'textPrimary',
  margin: 'large',
};

export default ContentHeader;
