import transitions from '@material-ui/core/styles/transitions';
import palette from '../palette';
import typography from '../typography';
import spacing from '../spacing';
import shadows from '../shadows';

export default {
  root: {
    position: 'relative',
    borderRadius: spacing(0.75),
    '& input': {
      transition: transitions.create(['background-color', 'color', 'box-shadow', 'border'], {
        duration: transitions.duration.short,
      }),
      '&[readonly]': {
        cursor: 'default',
      },
    },
    '&:hover $notchedOutline': {
      borderColor: palette.primary.main,
    },
    '&$focused $notchedOutline': {
      borderColor: palette.primary.main,
      borderWidth: 1,
      boxShadow: shadows[8],
    },
    '&$error $notchedOutline': {
      borderColor: palette.error.main,
    },
    '&$disabled $notchedOutline': {
      borderColor: palette.bottomLineColor,
    },
    '&$disabled': {
      backgroundColor: palette.secondary.light,
    },
    '&.Mui-error': {
      '& input, & .MuiSelect-root': {
        color: palette.error.main,
      },
    },
  },
  colorSecondary: {
    '&$focused $notchedOutline': {
      borderColor: palette.secondary.main,
    },
  },
  focused: {},
  disabled: {},
  adornedStart: {
    paddingLeft: 12,
  },
  adornedEnd: {
    paddingRight: 12,
  },
  error: {},
  marginDense: {},
  notchedOutline: {
    borderColor: palette.bottomLineColor,
  },
  multiline: {
    padding: '12px',
    minHeight: 48,
    '&$marginDense': {
      paddingTop: 10.5,
      paddingBottom: 10.5,
    },
  },
  input: {
    fontSize: '1rem',
    fontWeight: typography.fontWeightMedium,
    padding: 12,
    minHeight: 48,
    boxSizing: 'border-box',
    '&:-webkit-autofill': {
      borderRadius: 'inherit',
    },
    '&::placeholder': {
      fontWeight: typography.fontWeightRegular,
      color: `${palette.secondary.main} !important`,
      opacity: 1,
    },
  },
  inputMarginDense: {
    paddingTop: 10.5,
    paddingBottom: 10.5,
  },
  inputMultiline: {
    padding: 0,
  },
  inputAdornedStart: {
    paddingLeft: 0,
  },
  inputAdornedEnd: {
    paddingRight: 0,
  },
};
