import moment from 'moment';
import i18n from './i18n';
import cookieHelper from './cookieHelper';
import { RegexFormulas } from './regex';

export const isMail = (value) => RegexFormulas.email.test(value.toLowerCase());

export const switchLanguageInBrowser = (language) => {
  moment.locale(language);
  i18n.changeLanguage(language).then(() => true);
  cookieHelper.setCookie('lang', language.toLowerCase());
};

export const sortGroupsAllOwnersFirst = (data) => {
  const dataLength = data.length;
  if (dataLength === 0) return [];
  let first;
  let second;
  const others = [];
  for (let i = 0; i < dataLength; i += 1) {
    if (data[i].name === 'All') {
      first = data[i];
    } else if (data[i].name === 'Owners') {
      second = data[i];
    } else {
      others.push(data[i]);
    }
  }

  others.sort();
  return [first, second, ...others].filter((el) => el);
};

export function similarity(first, second) {
  first = first.replace(/\s+/g, '');
  second = second.replace(/\s+/g, '');

  if (first === second) return 1; // identical or empty
  if (first.length < 2 || second.length < 2) return 0; // if either is a 0-letter or 1-letter string

  const firstBigrams = new Map();
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < first.length - 1; i++) {
    const bigram = first.substring(i, i + 2);
    const count = firstBigrams.has(bigram) ? firstBigrams.get(bigram) + 1 : 1;

    firstBigrams.set(bigram, count);
  }

  let intersectionSize = 0;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < second.length - 1; i++) {
    const bigram = second.substring(i, i + 2);
    const count = firstBigrams.has(bigram) ? firstBigrams.get(bigram) : 0;

    if (count > 0) {
      firstBigrams.set(bigram, count - 1);
      // eslint-disable-next-line no-plusplus
      intersectionSize++;
    }
  }

  return (2.0 * intersectionSize) / (first.length + second.length - 2);
}

export function splitArrayIntoTwo(list) {
  const listCopy = [...list];
  const middleIndex = Math.ceil(listCopy.length / 2);

  const firstHalf = listCopy.splice(0, middleIndex);
  const secondHalf = listCopy.splice(-middleIndex);

  return [firstHalf, secondHalf];
}

// This function is used only as helper function, DO NOT USE IT FOR BLOCKCHAIN
export function generateUUID() {
  // Public Domain/MIT
  let d = new Date().getTime(); // Timestamp
  let d2 = (typeof performance !== 'undefined' && performance.now && performance.now() * 1000) || 0; // Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    let r = Math.random() * 16; // random number between 0 and 16
    if (d > 0) {
      // Use timestamp until depleted
      // eslint-disable-next-line no-bitwise
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      // Use microseconds since page-load if supported
      // eslint-disable-next-line no-bitwise
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    // eslint-disable-next-line no-bitwise
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
