import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ breakpoints, palette, spacing }) =>
    createStyles({
      root: {},
      rootOpen: {
        '& $headerRootButton': {
          color: palette.primary.main,
          borderColor: palette.primary.main,
          backgroundColor: palette.common.white,
        },
      },
      headerRoot: {
        padding: spacing(1.5, 2),
        cursor: 'pointer',
        boxShadow: `0 0 0 1px ${palette.divider}`,
        [breakpoints.up('md')]: {
          padding: spacing(2, 3),
        },
      },
      headerRootButton: {},
    }),
  { index: 0 }
);
