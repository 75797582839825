import axios from 'src/helpers/axios';
import { parseError } from 'src/helpers/parseErrors';

const moduleName = 'document-indexing-service';

const documentsService = {
  createFolder,
  fetchAnyDocuments,
  fetchDocuments,
  fetchDocumentsByQuery,
  fetchFolderByQuery,
  fetchFolderTree,
  findDocument,
  findFolder,
  getDocumentsInFolder,
  getFolderPath,
  moveDocument,
  removeFolder,
  renameFolder,
};

function fetchFolderTree(workspaceId, rootFolderId, depth = 1) {
  const folderPath = rootFolderId ? `/${rootFolderId}` : '';
  const query = encodeURIComponent(
    JSON.stringify({
      depth,
    })
  );
  return axios
    .get(`/rest/${moduleName}/workspaces/${workspaceId}/folders${folderPath}?query=${query}`, {
      withPermissions: ['DOCUMENT_INDEXING_SERVICE_USER_GET_FOLDERS'],
    })
    .then((response) => response.data)
    .catch((error) => parseError(error));
}

function getFolderPath(workspaceId, folderId) {
  return axios
    .get(`/rest/${moduleName}/workspaces/${workspaceId}/folders/${folderId}/path`, {
      withPermissions: ['DOCUMENT_INDEXING_SERVICE_USER_GET_FOLDER_PATH'],
    })
    .then((response) => response.data)
    .catch((error) => parseError(error));
}

function getDocumentsInFolder(workspaceId, folderId) {
  const query = encodeURIComponent(
    JSON.stringify({
      limit: 10,
      offset: 0,
    })
  );
  return axios
    .get(
      `/rest/${moduleName}/workspaces/${workspaceId}/folders/${folderId}/documents?query=${query}`,
      {
        withPermissions: ['DOCUMENT_INDEXING_SERVICE_USER_GET_FOLDERS'],
      }
    )
    .then((response) => response.data)
    .catch((error) => parseError(error));
}

function createFolder(workspaceId, folderId, name) {
  return axios
    .post(
      `/rest/${moduleName}/workspaces/${workspaceId}/folders/${folderId}`,
      { name },
      {
        withPermissions: ['DOCUMENT_INDEXING_SERVICE_USER_POST_FOLDER'],
      }
    )
    .then((response) => response.data)
    .catch((error) => parseError(error));
}

function removeFolder(workspaceId, folderId) {
  return axios
    .delete(`/rest/${moduleName}/workspaces/${workspaceId}/folders/${folderId}`, {
      withPermissions: ['DOCUMENT_INDEXING_SERVICE_USER_DELETE_FOLDER'],
    })
    .then((response) => response.data)
    .catch((error) => parseError(error));
}

function renameFolder(workspaceId, folderId, name) {
  return axios
    .put(
      `/rest/${moduleName}/workspaces/${workspaceId}/folders/${folderId}`,
      { name },
      {
        withPermissions: ['DOCUMENT_INDEXING_SERVICE_USER_PUT_MOVE_DOCUMENT'],
      }
    )
    .then((response) => response.data)
    .catch((error) => parseError(error));
}

let prevFolderController;
function findFolder(workspaceId, value) {
  if (prevFolderController) {
    prevFolderController.abort();
  }
  const controller = new AbortController();
  const { signal } = controller;
  prevFolderController = controller;

  const queryObj = { limit: 10, name: value };
  const query = encodeURIComponent(JSON.stringify(queryObj));
  return axios
    .get(`/rest/${moduleName}/workspaces/${workspaceId}/folders?query=${query}`, {
      withPermissions: ['DOCUMENT_INDEXING_SERVICE_USER_GET_FOLDERS'],
      signal,
    })
    .then((response) => response.data)
    .catch((error) => parseError(error));
}

function moveDocument(workspaceId, documents, sourceFolderId, targetFolderId) {
  return axios
    .put(
      `/rest/${moduleName}/workspaces/${workspaceId}/folders/${sourceFolderId}/documents`,
      {
        folderId: targetFolderId,
        documents,
      },
      {
        withPermissions: ['DOCUMENT_INDEXING_SERVICE_USER_PUT_MOVE_DOCUMENT'],
      }
    )
    .then((response) => response.data)
    .catch((error) => parseError(error));
}

let prevDocController;
function findDocument(workspaceId, value) {
  if (prevDocController) {
    prevDocController.abort();
  }
  const controller = new AbortController();
  const { signal } = controller;
  prevDocController = controller;

  const queryObj = {
    limit: 12,
    document: value,
  };
  const query = encodeURIComponent(JSON.stringify(queryObj));

  return axios
    .get(`/rest/${moduleName}/workspaces/${workspaceId}/documents?query=${query}`, {
      withPermissions: ['DOCUMENT_INDEXING_SERVICE_USER_GET_DOCUMENTS'],
      signal,
    })
    .then((response) => response.data)
    .catch((error) => parseError(error));
}

function fetchDocuments({
  workspaceId,
  folder,
  limit,
  offset,
  orderBy,
  sortOrder,
  filterKey,
  filterValue,
}) {
  const query = {
    limit: 20,
    offset: 0,
    sortBy: { updatedAt: 'DESC' },
  };
  if (limit) {
    query.limit = limit;
  }
  if (offset) {
    query.offset = offset;
  }
  if (orderBy && sortOrder) {
    query.sortBy = {}; // [orderBy] = sortOrder;
    query.sortBy[orderBy] = sortOrder;
  }
  if (!query.sortBy.id) {
    query.sortBy.id = 'desc';
  }
  if (filterKey && filterValue) {
    query[filterKey] = filterValue;
  }
  const pathQuery = encodeURIComponent(JSON.stringify(query));
  return (
    axios
      .get(
        `/rest/${moduleName}/workspaces/${workspaceId}/folders/${folder}/documents?query=${pathQuery}`
      )
      // .get(`/rest/${moduleName}/workspaces/${workspaceId}/documents?query=${pathQuery}`)
      .then((response) => response.data)
      .catch((error) => parseError(error))
  );
}
function fetchDocumentsByQuery({ workspaceId, query }) {
  return axios
    .get(`/rest/${moduleName}/workspaces/${workspaceId}/documents?query=${query}`)
    .then((response) => response.data)
    .catch((error) => parseError(error));
}

function fetchFolderByQuery({ workspaceId, query, folderId }) {
  return axios
    .get(`/rest/${moduleName}/workspaces/${workspaceId}/folders/${folderId || ''}?query=${query}`)
    .then((response) => response.data)
    .catch((error) => parseError(error));
}

function fetchAnyDocuments(
  workspaceId,
  limit,
  offset,
  orderBy,
  sortOrder,
  filterKey,
  filterValue,
  rolesId = []
) {
  const query = {
    limit: 20,
    offset: 0,
    sortBy: { updatedAt: 'DESC' },
  };
  if (limit) {
    query.limit = limit;
  }
  if (offset) {
    query.offset = offset;
  }
  if (orderBy && sortOrder) {
    query.sortBy = {}; // [orderBy] = sortOrder;
    query.sortBy[orderBy] = sortOrder;
  }
  if (!query.sortBy.id) {
    query.sortBy.id = 'desc';
  }
  if (filterKey && filterValue) {
    query[filterKey] = filterValue;
  }
  if (rolesId.length) {
    query.roles = {
      signers: rolesId,
    };
    query.notSigned = rolesId;
    query.notRejected = rolesId;
  }
  const pathQuery = encodeURIComponent(JSON.stringify(query));
  return axios
    .get(`/rest/${moduleName}/workspaces/${workspaceId}/documents?query=${pathQuery}`)
    .then((response) => response.data)
    .catch((error) => parseError(error));
}

export default documentsService;
