import breakpoints from '../breakpoints';
import spacing from '../spacing';

export default {
  root: {
    display: 'flex',
    alignItems: 'center',
    [breakpoints.down('xs')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  toLabelDelimiter: {
    margin: `${spacing(1)}px 0`,
    [breakpoints.up('sm')]: {
      margin: `0 ${spacing(2)}px`,
    },
  },
};
