import spacing from '../spacing';
import breakpoints from '../breakpoints';

export default {
  container: {
    outline: 'none !important',
    '& *': {
      outline: 'inherit !important',
    },
  },
  paper: {
    width: '100%',
    margin: spacing(2),
  },
  paperScrollPaper: {
    maxHeight: `calc(100% - ${spacing(4)}px)`,
  },
  paperWidthFalse: {
    maxWidth: `calc(100% - ${spacing(4)}px)`,
  },
  paperWidthXs: {
    maxWidth: Math.max(breakpoints.values.xs),
    '&$paperScrollBody': {
      [breakpoints.down(Math.max(breakpoints.values.xs) + spacing(4))]: {
        maxWidth: `calc(100% - ${spacing(2)})`,
      },
    },
  },
  paperWidthSm: {
    maxWidth: breakpoints.values.sm,
    '&$paperScrollBody': {
      [breakpoints.down(breakpoints.values.sm + spacing(4))]: {
        maxWidth: `calc(100% - ${spacing(2)})`,
      },
    },
  },
  paperWidthMd: {
    maxWidth: breakpoints.values.md,
    '&$paperScrollBody': {
      [breakpoints.down(breakpoints.values.md + spacing(4))]: {
        maxWidth: `calc(100% - ${spacing(2)})`,
      },
    },
  },
  paperWidthLg: {
    maxWidth: breakpoints.values.lg,
    '&$paperScrollBody': {
      [breakpoints.down(breakpoints.values.lg + spacing(4))]: {
        maxWidth: `calc(100% - ${spacing(2)})`,
      },
    },
  },
  paperWidthXl: {
    maxWidth: breakpoints.values.xl,
    '&$paperScrollBody': {
      [breakpoints.down(breakpoints.values.xl + spacing(4))]: {
        maxWidth: `calc(100% - ${spacing(2)})`,
      },
    },
  },
  paperFullWidth: {
    width: `calc(100% - ${spacing(4)}px)`,
  },
};
