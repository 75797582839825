import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import avatarActions from 'src/redux/actions/avatar.actions';
import { ConfirmDialog } from 'src/components';
import { RequestStatus } from 'src/helpers/reduxReuquest.util';

const DeleteAvatar = ({ open, onClose, workspaceId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const deleteWorkspaceAvatarRequestStatus = useSelector(
    (state) => state.avatarStore.deleteWorkspaceAvatarRequestStatus
  );

  const isPending = deleteWorkspaceAvatarRequestStatus === RequestStatus.PENDING;

  return (
    <ConfirmDialog
      variant="error"
      open={open}
      title={t('workspacePage.settings.logoDelete.header')}
      subtitle={t('workspacePage.settings.logoDelete.description')}
      applyButtonText={t('workspacePage.settings.logoDelete.delete')}
      cancelButtonText={t('common.cancel')}
      actionAccept={() => {
        dispatch(avatarActions.deleteWorkspaceAvatar(workspaceId, onClose));
      }}
      isLoading={isPending}
      actionCancel={onClose}
    />
  );
};

DeleteAvatar.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  workspaceId: PropTypes.string,
};

export default DeleteAvatar;
