import React from 'react';
import PropTypes from 'prop-types';
import { Button, TextField, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useForm from 'src/helpers/hooks/useForm';
import validate from 'src/helpers/formValidationRules';
import { SmallContentWithScrollbar } from 'src/components';

const UsernameEdit = (props) => {
  const { username, updateBasicData } = props;
  const { t } = useTranslation();

  const handleUpdate = () => {
    updateBasicData('username', values.username);
  };

  const { values, errors, handleChange, handleSubmit } = useForm(handleUpdate, validate, {
    username,
  });

  return (
    <SmallContentWithScrollbar
      startContent={<Typography>{t('userConfigurationPage.basic.usernamePage.title')}</Typography>}
      endContent={
        <Button size="large" type="submit" fullWidth>
          {t('userConfigurationPage.basic.usernamePage.button')}
        </Button>
      }
      hasForm
      formProps={{
        noValidate: true,
        autoComplete: 'off',
        onSubmit: handleSubmit,
      }}
    >
      <TextField
        name="username"
        placeholder={t('userConfigurationPage.basic.usernamePage.placeholder')}
        fullWidth
        autoFocus
        value={values.username || ''}
        onChange={handleChange}
        error={errors.username?.length > 0}
        helperText={errors.username}
      />
    </SmallContentWithScrollbar>
  );
};

UsernameEdit.propTypes = {
  username: PropTypes.string,
  updateBasicData: PropTypes.func,
};

export default UsernameEdit;
