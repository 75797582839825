import { fade } from '@material-ui/core/styles';
import palette from '../palette';
import typography from '../typography';

export default {
  '@global': {
    'html, body': {
      width: '100%',
      height: '100%',
      margin: 0,
      padding: 0,
      border: 'none',
      overflow: 'hidden',
      fontFamily: typography.fontFamily,
      fontSize: typography.htmlFontSize,
      color: palette.text.primary,
    },
    a: {
      textDecoration: 'none',
      color: palette.primary.main,
    },
    '#app': {
      height: '100%',
    },
    '::-moz-selection': {
      background: palette.primary.main,
      color: palette.primary.contrastText,
    },
    '::selection': {
      background: palette.primary.main,
      color: palette.primary.contrastText,
    },
    '::-webkit-scrollbar': {
      width: 6,
      height: 6,
    },
    '::-webkit-scrollbar-thumb': {
      border: 'none',
      background: palette.primary.main,
      borderRadius: 3,
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: palette.primary.dark,
    },
    '::-webkit-scrollbar-track': {
      background: 'transparent',
      borderRadius: 0,
    },
    '*': {
      scrollbarColor: `${palette.primary.main} ${fade(palette.greySimple.main, 0.1)}`,
      scrollbarWidth: 'thin',
    },
    'strong, b': {
      fontWeight: `${typography.fontWeightBold} !important`,
    },
    '.MuiMenuItem-root.country': {},

    '.MuiAutocomplete-option[data-focus="true"]': {
      '& .avatarWrapper .MuiAvatar-root': {
        transition: 'all .3s',
        boxShadow: '2px 5px 12px -4px rgb(0 0 0 / 20%)',
      },
      '& .itemTitleHover': {
        color: palette.primary.main,
      },

      '& .hoverEnabled': {
        background: palette.action.hover,
      },
    },
  },
};
