import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useStyles } from './styles';

const ExtendedGrid = ({
  xxs,
  xs,
  sm,
  md,
  lg,
  xl,
  alignContentXxs,
  alignContentXs,
  alignContentSm,
  alignContentMd,
  alignContentLg,
  alignContentXl,
  alignItemsXxs,
  alignItemsXs,
  alignItemsSm,
  alignItemsMd,
  alignItemsLg,
  alignItemsXl,
  justifyContentXxs,
  justifyContentXs,
  justifyContentSm,
  justifyContentMd,
  justifyContentLg,
  justifyContentXl,
  directionXxs,
  directionXs,
  directionSm,
  directionMd,
  directionLg,
  directionXl,
  wrapXxs,
  wrapXs,
  wrapSm,
  wrapMd,
  wrapLg,
  wrapXl,
  textAlignXxs,
  textAlignXs,
  textAlignSm,
  textAlignMd,
  textAlignLg,
  textAlignXl,
  offsetXxs,
  offsetXs,
  offsetSm,
  offsetMd,
  offsetLg,
  offsetXl,
  orderXxs,
  orderXs,
  orderSm,
  orderMd,
  orderLg,
  orderXl,
  spacing,
  spacingX,
  spacingY,
  className: classNameProp,
  container,
  item,
  zeroMinWidth,
  component: Component = 'div',
  ...other
}) => {
  const classes = useStyles();
  const className = clsx(
    classes.root,
    {
      [classes.container]: container,
      [classes.item]: item,
      [classes.zeroMinWidth]: zeroMinWidth,

      [classes[`spacing-${String(spacing)}`]]: container && spacing !== 0,
      [classes[`spacing-x-${String(spacingX)}`]]: container && spacingX !== 0,
      [classes[`spacing-y-${String(spacingY)}`]]: container && spacingY !== 0,

      [classes[`item-xxs-${String(xxs)}`]]: xxs !== false,
      [classes[`item-xs-${String(xs)}`]]: xs !== false,
      [classes[`item-sm-${String(sm)}`]]: sm !== false,
      [classes[`item-md-${String(md)}`]]: md !== false,
      [classes[`item-lg-${String(lg)}`]]: lg !== false,
      [classes[`item-xl-${String(xl)}`]]: xl !== false,

      [classes[`direction-xxs-${String(directionXxs)}`]]: container && directionXxs !== 'row',
      [classes[`direction-xs-${String(directionXs)}`]]: container && directionXs !== false,
      [classes[`direction-sm-${String(directionSm)}`]]: container && directionSm !== false,
      [classes[`direction-md-${String(directionMd)}`]]: container && directionMd !== false,
      [classes[`direction-lg-${String(directionLg)}`]]: container && directionLg !== false,
      [classes[`direction-xl-${String(directionXl)}`]]: container && directionXl !== false,

      [classes[`wrap-xxs-${String(wrapXxs)}`]]: container && wrapXxs !== 'wrap',
      [classes[`wrap-xs-${String(wrapXs)}`]]: container && wrapXs !== false,
      [classes[`wrap-sm-${String(wrapSm)}`]]: container && wrapSm !== false,
      [classes[`wrap-md-${String(wrapMd)}`]]: container && wrapMd !== false,
      [classes[`wrap-lg-${String(wrapLg)}`]]: container && wrapLg !== false,
      [classes[`wrap-xl-${String(wrapXl)}`]]: container && wrapXl !== false,

      [classes[`align-items-xxs-${String(alignItemsXxs)}`]]:
        container && alignItemsXxs !== 'stretch',
      [classes[`align-items-xs-${String(alignItemsXs)}`]]: container && alignItemsXs !== false,
      [classes[`align-items-sm-${String(alignItemsSm)}`]]: container && alignItemsSm !== false,
      [classes[`align-items-md-${String(alignItemsMd)}`]]: container && alignItemsMd !== false,
      [classes[`align-items-lg-${String(alignItemsLg)}`]]: container && alignItemsLg !== false,
      [classes[`align-items-xl-${String(alignItemsXl)}`]]: container && alignItemsXl !== false,

      [classes[`align-content-xxs-${String(alignContentXxs)}`]]:
        container && alignContentXxs !== 'stretch',
      [classes[`align-content-xs-${String(alignContentXs)}`]]:
        container && alignContentXs !== false,
      [classes[`align-content-sm-${String(alignContentSm)}`]]:
        container && alignContentSm !== false,
      [classes[`align-content-md-${String(alignContentMd)}`]]:
        container && alignContentMd !== false,
      [classes[`align-content-lg-${String(alignContentLg)}`]]:
        container && alignContentLg !== false,
      [classes[`align-content-xl-${String(alignContentXl)}`]]:
        container && alignContentXl !== false,

      [classes[`justify-content-xxs-${String(justifyContentXxs)}`]]:
        container && justifyContentXxs !== 'flex-start',
      [classes[`justify-content-xs-${String(justifyContentXs)}`]]:
        container && justifyContentXs !== false,
      [classes[`justify-content-sm-${String(justifyContentSm)}`]]:
        container && justifyContentSm !== false,
      [classes[`justify-content-md-${String(justifyContentMd)}`]]:
        container && justifyContentMd !== false,
      [classes[`justify-content-lg-${String(justifyContentLg)}`]]:
        container && justifyContentLg !== false,
      [classes[`justify-content-xl-${String(justifyContentXl)}`]]:
        container && justifyContentXl !== false,

      [classes[`text-align-xxs-${String(textAlignXxs)}`]]: textAlignXxs !== false,
      [classes[`text-align-xs-${String(textAlignXs)}`]]: textAlignXs !== false,
      [classes[`text-align-sm-${String(textAlignSm)}`]]: textAlignSm !== false,
      [classes[`text-align-md-${String(textAlignMd)}`]]: textAlignMd !== false,
      [classes[`text-align-lg-${String(textAlignLg)}`]]: textAlignLg !== false,
      [classes[`text-align-xl-${String(textAlignXl)}`]]: textAlignXl !== false,

      [classes[`item-offset-xxs-${String(offsetXxs)}`]]: item && offsetXxs !== false,
      [classes[`item-offset-xs-${String(offsetXs)}`]]: item && offsetXs !== false,
      [classes[`item-offset-sm-${String(offsetSm)}`]]: item && offsetSm !== false,
      [classes[`item-offset-md-${String(offsetMd)}`]]: item && offsetMd !== false,
      [classes[`item-offset-lg-${String(offsetLg)}`]]: item && offsetLg !== false,
      [classes[`item-offset-xl-${String(offsetXl)}`]]: item && offsetXl !== false,

      [classes[`item-order-xxs-${String(orderXxs)}`]]: item && orderXxs !== false,
      [classes[`item-order-xs-${String(orderXs)}`]]: item && orderXs !== false,
      [classes[`item-order-sm-${String(orderSm)}`]]: item && orderSm !== false,
      [classes[`item-order-md-${String(orderMd)}`]]: item && orderMd !== false,
      [classes[`item-order-lg-${String(orderLg)}`]]: item && orderLg !== false,
      [classes[`item-order-xl-${String(orderXl)}`]]: item && orderXl !== false,
    },
    classNameProp
  );

  return <Component className={className} {...other} />;
};

ExtendedGrid.propTypes = {
  xxs: PropTypes.oneOfType([
    PropTypes.oneOf(['auto', 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
    PropTypes.bool,
  ]),
  xs: PropTypes.oneOfType([
    PropTypes.oneOf(['auto', 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
    PropTypes.bool,
  ]),
  sm: PropTypes.oneOfType([
    PropTypes.oneOf(['auto', 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
    PropTypes.bool,
  ]),
  md: PropTypes.oneOfType([
    PropTypes.oneOf(['auto', 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
    PropTypes.bool,
  ]),
  lg: PropTypes.oneOfType([
    PropTypes.oneOf(['auto', 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
    PropTypes.bool,
  ]),
  xl: PropTypes.oneOfType([
    PropTypes.oneOf(['auto', 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
    PropTypes.bool,
  ]),
  alignContentXxs: PropTypes.oneOf([
    'stretch',
    'center',
    'flex-start',
    'flex-end',
    'space-between',
    'space-around',
  ]),
  alignContentXs: PropTypes.oneOf([
    false,
    'stretch',
    'center',
    'flex-start',
    'flex-end',
    'space-between',
    'space-around',
  ]),
  alignContentSm: PropTypes.oneOf([
    false,
    'stretch',
    'center',
    'flex-start',
    'flex-end',
    'space-between',
    'space-around',
  ]),
  alignContentMd: PropTypes.oneOf([
    false,
    'stretch',
    'center',
    'flex-start',
    'flex-end',
    'space-between',
    'space-around',
  ]),
  alignContentLg: PropTypes.oneOf([
    false,
    'stretch',
    'center',
    'flex-start',
    'flex-end',
    'space-between',
    'space-around',
  ]),
  alignContentXl: PropTypes.oneOf([
    false,
    'stretch',
    'center',
    'flex-start',
    'flex-end',
    'space-between',
    'space-around',
  ]),
  alignItemsXxs: PropTypes.oneOf(['flex-start', 'center', 'flex-end', 'stretch', 'baseline']),
  alignItemsXs: PropTypes.oneOf([false, 'flex-start', 'center', 'flex-end', 'stretch', 'baseline']),
  alignItemsSm: PropTypes.oneOf([false, 'flex-start', 'center', 'flex-end', 'stretch', 'baseline']),
  alignItemsMd: PropTypes.oneOf([false, 'flex-start', 'center', 'flex-end', 'stretch', 'baseline']),
  alignItemsLg: PropTypes.oneOf([false, 'flex-start', 'center', 'flex-end', 'stretch', 'baseline']),
  alignItemsXl: PropTypes.oneOf([false, 'flex-start', 'center', 'flex-end', 'stretch', 'baseline']),
  justifyContentXxs: PropTypes.oneOf([
    'center',
    'flex-start',
    'flex-end',
    'space-between',
    'space-around',
    'space-evenly',
  ]),
  justifyContentXs: PropTypes.oneOf([
    false,
    'center',
    'flex-start',
    'flex-end',
    'space-between',
    'space-around',
    'space-evenly',
  ]),
  justifyContentSm: PropTypes.oneOf([
    false,
    'center',
    'flex-start',
    'flex-end',
    'space-between',
    'space-around',
    'space-evenly',
  ]),
  justifyContentMd: PropTypes.oneOf([
    false,
    'center',
    'flex-start',
    'flex-end',
    'space-between',
    'space-around',
    'space-evenly',
  ]),
  justifyContentLg: PropTypes.oneOf([
    false,
    'center',
    'flex-start',
    'flex-end',
    'space-between',
    'space-around',
    'space-evenly',
  ]),
  justifyContentXl: PropTypes.oneOf([
    false,
    'center',
    'flex-start',
    'flex-end',
    'space-between',
    'space-around',
    'space-evenly',
  ]),
  directionXxs: PropTypes.oneOf(['row', 'row-reverse', 'column', 'column-reverse']),
  directionXs: PropTypes.oneOf([false, 'row', 'row-reverse', 'column', 'column-reverse']),
  directionSm: PropTypes.oneOf([false, 'row', 'row-reverse', 'column', 'column-reverse']),
  directionMd: PropTypes.oneOf([false, 'row', 'row-reverse', 'column', 'column-reverse']),
  directionLg: PropTypes.oneOf([false, 'row', 'row-reverse', 'column', 'column-reverse']),
  directionXl: PropTypes.oneOf([false, 'row', 'row-reverse', 'column', 'column-reverse']),
  textAlignXxs: PropTypes.oneOf([false, 'left', 'right', 'center', 'justify', 'inherit', 'unset']),
  textAlignXs: PropTypes.oneOf([false, 'left', 'right', 'center', 'justify', 'inherit', 'unset']),
  textAlignSm: PropTypes.oneOf([false, 'left', 'right', 'center', 'justify', 'inherit', 'unset']),
  textAlignMd: PropTypes.oneOf([false, 'left', 'right', 'center', 'justify', 'inherit', 'unset']),
  textAlignLg: PropTypes.oneOf([false, 'left', 'right', 'center', 'justify', 'inherit', 'unset']),
  textAlignXl: PropTypes.oneOf([false, 'left', 'right', 'center', 'justify', 'inherit', 'unset']),
  offsetXxs: PropTypes.oneOf([false, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  offsetXs: PropTypes.oneOf([false, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  offsetSm: PropTypes.oneOf([false, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  offsetMd: PropTypes.oneOf([false, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  offsetLg: PropTypes.oneOf([false, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  offsetXl: PropTypes.oneOf([false, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  wrapXxs: PropTypes.oneOf(['nowrap', 'wrap', 'wrap-reverse']),
  wrapXs: PropTypes.oneOf([false, 'nowrap', 'wrap', 'wrap-reverse']),
  wrapSm: PropTypes.oneOf([false, 'nowrap', 'wrap', 'wrap-reverse']),
  wrapMd: PropTypes.oneOf([false, 'nowrap', 'wrap', 'wrap-reverse']),
  wrapLg: PropTypes.oneOf([false, 'nowrap', 'wrap', 'wrap-reverse']),
  wrapXl: PropTypes.oneOf([false, 'nowrap', 'wrap', 'wrap-reverse']),
  orderXxs: PropTypes.oneOf([false, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  orderXs: PropTypes.oneOf([false, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  orderSm: PropTypes.oneOf([false, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  orderMd: PropTypes.oneOf([false, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  orderLg: PropTypes.oneOf([false, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  orderXl: PropTypes.oneOf([false, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  spacing: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]),
  spacingX: PropTypes.oneOf([false, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]),
  spacingY: PropTypes.oneOf([false, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]),
  children: PropTypes.node,
  className: PropTypes.string,
  container: PropTypes.bool,
  item: PropTypes.bool,
  zeroMinWidth: PropTypes.bool,
  component: PropTypes.elementType,
};

ExtendedGrid.defaultProps = {
  xxs: false,
  xs: false,
  sm: false,
  md: false,
  lg: false,
  xl: false,
  alignContentXxs: 'stretch',
  alignContentXs: false,
  alignContentSm: false,
  alignContentMd: false,
  alignContentLg: false,
  alignContentXl: false,
  alignItemsXxs: 'stretch',
  alignItemsXs: false,
  alignItemsSm: false,
  alignItemsMd: false,
  alignItemsLg: false,
  alignItemsXl: false,
  justifyContentXxs: 'flex-start',
  justifyContentXs: false,
  justifyContentSm: false,
  justifyContentMd: false,
  justifyContentLg: false,
  justifyContentXl: false,
  directionXxs: 'row',
  directionXs: false,
  directionSm: false,
  directionMd: false,
  directionLg: false,
  directionXl: false,
  wrapXxs: 'wrap',
  wrapXs: false,
  wrapSm: false,
  wrapMd: false,
  wrapLg: false,
  wrapXl: false,
  textAlignXxs: false,
  textAlignXs: false,
  textAlignSm: false,
  textAlignMd: false,
  textAlignLg: false,
  textAlignXl: false,
  offsetXxs: false,
  offsetXs: false,
  offsetSm: false,
  offsetMd: false,
  offsetLg: false,
  offsetXl: false,
  orderXxs: false,
  orderXs: false,
  orderSm: false,
  orderMd: false,
  orderLg: false,
  orderXl: false,
  spacing: 0,
  spacingX: false,
  spacingY: false,
  container: false,
  item: false,
  zeroMinWidth: false,
};

export default ExtendedGrid;
