import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { ContentContainer } from 'src/components';

import { useStyles } from './styles';

const TopBarContainer = ({ className: classNameProp, children, size, vertical, horizontal }) => {
  const classes = useStyles();
  return (
    <ContentContainer
      size={size}
      vertical={vertical}
      horizontal={horizontal}
      className={clsx(classes.root, classNameProp)}
    >
      {children}
    </ContentContainer>
  );
};

TopBarContainer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  vertical: PropTypes.bool,
  horizontal: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

TopBarContainer.defaultProps = {
  size: 'large',
  vertical: false,
  horizontal: true,
};

export default TopBarContainer;
