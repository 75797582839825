import moment from 'moment/moment';

export enum BillingProductType {
  EsignDrive = 'esign_drive',
  Certificates = 'certificates',
}

export enum BillingProductStatus {
  Trialing = 'trialing',
  Active = 'active',
  Canceled = 'canceled',
  TrialFinished = 'trial_finished',
}

export interface BillingProduct {
  subscriptionId: string;
  status: BillingProductStatus;
  productType: BillingProductType;
  cancelAt?: number;
}

type AlertLevel = 'info' | 'warning';

type checkSubscriptionReturn = {
  daysLeft: number;
  alertLevel: AlertLevel;
  showProductAlert: boolean;
};
export const checkSubscription = (product: BillingProduct | undefined): checkSubscriptionReturn => {
  const daysLeft = moment(product?.cancelAt).endOf('day').diff(moment(), 'days');
  let showProductAlert = false;
  if (product?.cancelAt !== undefined) {
    if (product?.status === BillingProductStatus.Trialing) {
      showProductAlert = daysLeft <= 13;
    }
    if (product?.status === BillingProductStatus.Active) {
      showProductAlert = daysLeft <= 30;
    }
  }

  let alertLevel: AlertLevel = 'info';

  if (
    product &&
    [BillingProductStatus.Trialing, BillingProductStatus.Active].includes(product.status)
  ) {
    if (daysLeft <= 7) {
      alertLevel = 'warning';
    }
  } else if (daysLeft <= 30) {
    alertLevel = 'warning';
  }

  return {
    daysLeft,
    showProductAlert,
    alertLevel,
  };
};
