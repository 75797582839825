import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@material-ui/core';
import useForm from 'src/helpers/hooks/useForm';
import validate from 'src/helpers/formValidationRules';

interface AddNewFolderModalProps {
  open: boolean;
  onClose: () => void;
  onChange: (name: string) => void;
}

const AddNewFolderModal = ({ open, onClose, onChange }: AddNewFolderModalProps): JSX.Element => {
  const { t } = useTranslation();
  const handleUpdate = () => {
    onChange(values.folderName);
  };

  const { values, errors, handleChange, handleSubmit } = useForm(handleUpdate, validate, {
    folderName: '',
  });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const vErrors = errors as any;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs">
      <DialogTitle disableTypography>
        <Typography variant="h4">{t<string>('drivePage.foldersDialog.addNew.title')}</Typography>
      </DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          placeholder={t('drivePage.foldersDialog.addNew.description')}
          name="folderName"
          autoFocus
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              handleSubmit();
            }
          }}
          value={values.folderName || ''}
          onChange={handleChange}
          error={vErrors.folderName?.length > 0}
          helperText={vErrors.folderName}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="text" type="button" onClick={onClose}>
          {t<string>('common.cancel')}
        </Button>
        <Button variant="contained" type="submit" onClick={handleSubmit}>
          {t<string>('drivePage.foldersDialog.addNew.apply')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddNewFolderModal;
