import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import WebSocketHelper from 'src/helpers/WebSocketHelper';
import snackbarActions from 'src/redux/actions/snackbar.actions';
import { SNACKBAR_VARIANT } from 'src/models/common.model';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ContactHelper from '../../helpers/contactHelper';
import documentsActions from '../../redux/actions/documents.actions';
import usePrevious from '../../helpers/hooks/usePrevious';

const DocumentCreatorWebsocket = ({ sortKey, sortOrder }) => {
  const ws = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const { currentIdentity } = useSelector((state) => state.identityStore, undefined);
  const workspaceId = currentIdentity?.workspace?.workspaceId;
  const { t } = useTranslation();
  const prevState = usePrevious({
    workspaceId,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onWebSocketMessage = (message) => {
    if (message.type === 'RESULT') {
      switch (message.code) {
        case 'FOLDER_CREATED': {
          dispatch(documentsActions.setSelectedFolders([message.data.result.id]));
          history.push('/drive');

          break;
        }
        case 'DOCUMENT_CREATED':
          dispatch(
            documentsActions.websocketCreateDocument(message.data.result, sortKey, sortOrder)
          );
          break;
        case 'DOCUMENT_UPDATED':
          if (ContactHelper.checkIfUserBelongsToDocument(message.data.result)) {
            dispatch(
              documentsActions.websocketUpdateDocument(message.data.result, sortKey, sortOrder)
            );
          } else {
            dispatch(documentsActions.websocketDeleteDocument(message.data.result));
          }
          break;
        case 'DOCUMENT_DELETED':
          dispatch(documentsActions.websocketDeleteDocument(message.data.result));
          break;
        default:
          console.warn(`Unknown code [${message.code}]`, message);
          break;
      }
    } else if (message.type === 'ERROR') {
      switch (message.code) {
        default:
          // eslint-disable-next-line no-case-declarations
          const resultCode = message?.data?.result?.code;
          // eslint-disable-next-line no-case-declarations
          const messagePart = resultCode ? `_${resultCode}` : '';

          dispatch(
            snackbarActions.enqueueSnackbar(
              SNACKBAR_VARIANT.ERROR,
              t('snackbars.common.errorTitle'),
              t(`drivePage.action.error.${message.code}${messagePart}`)
            )
          );
          break;
      }
    } else {
      console.warn(`Unknown action type [${message.type}]`, message);
    }
  };

  useEffect(() => {
    if (workspaceId !== prevState?.workspaceId) {
      ws.current = WebSocketHelper.subscribe(
        'document-indexing-service',
        `workspaces/${workspaceId}`,
        (message) => {
          onWebSocketMessage(message);
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceId, prevState]);

  useEffect(
    () => () => {
      WebSocketHelper.unsubscribe(ws.current);
    },
    []
  ); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

DocumentCreatorWebsocket.propTypes = {
  sortKey: PropTypes.string,
  sortOrder: PropTypes.string,
};

export default DocumentCreatorWebsocket;
