import palette from '../palette';
import spacing from '../spacing';

export default {
  root: {},
  loadingContainer: {},
  weekContainer: {},
  week: {},
  iconButton: {
    backgroundColor: palette.background.paper,
  },
  previousMonthButton: {
    marginRight: spacing(1.5),
  },
  daysHeader: {},
  weekDayLabel: {
    width: 32,
    height: 48,
    margin: '0 2px',
    color: palette.text.secondary,
  },
};
