import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Divider,
  Fab,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { CustomizedBreadcrumbs, ExtendedGrid, LinkDnd } from 'src/components';
import { Settings as SettingsIcon } from 'src/components/CustomIcon';
import { useStyles } from './styles';

const BreadcrumbsAndSettings = ({
  className: classNameProp,
  actions,
  additionalContent,
  folderPath,
}) => {
  const { t } = useTranslation();
  const mobileScreen = useMediaQuery(({ breakpoints }) => breakpoints.down('xs'));
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderMenuActions = (actions) => (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      {actions.map((action, index) => (
        <MenuItem
          key={index}
          onClick={() => {
            action.onClick();
            handleClose();
          }}
        >
          {action.icon && <ListItemIcon>{action.icon}</ListItemIcon>}
          {action.name}
        </MenuItem>
      ))}
    </Menu>
  );

  const renderActionButton = () => (
    <>
      {mobileScreen ? (
        <Tooltip title={t('drivePage.folderSettings')}>
          <Fab onClick={handleClick} size="medium">
            <SettingsIcon />
          </Fab>
        </Tooltip>
      ) : (
        <Button
          size="small"
          variant="text"
          startIcon={<SettingsIcon />}
          className={classes.settingsButton}
          onClick={handleClick}
        >
          {t('drivePage.folderSettings')}
        </Button>
      )}
    </>
  );

  const parsePath = (path) => {
    const parsedPath = [];
    const ids = path.idPath.split('/');
    const names = path.namePath.split('/');
    for (let i = 0; i < ids.length; i += 1) {
      const folderData = {
        name: names[i] !== '' ? names[i] : t('common.rootCatalog'),
        path: i !== 0 ? `/${ids[i]}` : '',
        isActive: i + 1 === ids.length,
        folderId: ids[i],
      };
      parsedPath.push(folderData);
    }
    return parsedPath;
  };

  const renderPath = () => {
    const path = parsePath(folderPath);

    return path.map((folder) => {
      if (folder.isActive) {
        return (
          <Typography key={`bread-${folder.id}`} color="textPrimary">
            {folder.name}
          </Typography>
        );
      }
      return <LinkDnd key={`bread-${folder.id}`} folder={folder} />;
    });
  };

  return (
    <div className={clsx(classes.root, classNameProp)}>
      <ExtendedGrid
        className={classes.container}
        container
        spacingX={2}
        spacingY={1}
        alignItemsXxs="center"
        wrapXxs="wrap"
      >
        <ExtendedGrid item xxs="auto">
          <CustomizedBreadcrumbs>{renderPath()}</CustomizedBreadcrumbs>
        </ExtendedGrid>

        {actions && actions.length > 0 && (
          <>
            <ExtendedGrid item xxs={0} sm="auto">
              <Divider orientation="vertical" className={classes.divider} />
            </ExtendedGrid>
            <ExtendedGrid item xxs="auto">
              {renderActionButton()}
            </ExtendedGrid>
          </>
        )}

        {additionalContent && (
          <ExtendedGrid item xxs textAlignXxs="right">
            {additionalContent}
          </ExtendedGrid>
        )}
      </ExtendedGrid>

      {actions && renderMenuActions(actions)}
    </div>
  );
};

BreadcrumbsAndSettings.propTypes = {
  className: PropTypes.string,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.node,
      name: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ),
  folderPath: PropTypes.shape({
    idPath: PropTypes.string,
    namePath: PropTypes.string,
  }),
  additionalContent: PropTypes.node,
};

BreadcrumbsAndSettings.defaultProps = {};

export default BreadcrumbsAndSettings;
