import { RootState } from '../reducers';
import {
  FolderTreeItem,
  mapDocumentsItemToSearchedItem,
  mapFolderItemsToSearchedItem,
  SearchedItem,
} from '../../models/documents.model';

const selectAllFolders = (state: RootState): FolderTreeItem[] =>
  state.documentsStore.folderTree || [];

const selectOrdinaryFolders = (state: RootState): FolderTreeItem[] => {
  const { currentFolderId, folderTree } = state.documentsStore;
  if (!folderTree) return [];
  return folderTree.filter(
    (folder) => folder.id !== currentFolderId && !folder.isTrash && !folder.isRoot
  );
};
const selectAdvancedSearchFolders = (state: RootState): FolderTreeItem[] => {
  const { advancedSearchedFolders } = state.documentsStore;
  if (!advancedSearchedFolders) return [];
  return advancedSearchedFolders.filter((folder) => !folder.isTrash && !folder.isRoot);
};

const selectTrash = (state: RootState): FolderTreeItem | undefined =>
  state.documentsStore?.folderTree
    ? state.documentsStore.folderTree.find((folder) => folder.isTrash)
    : undefined;

const selectSearchedList = (state: RootState): SearchedItem[] => {
  const documents = mapDocumentsItemToSearchedItem(state.documentsStore.quickSearchedDocuments);
  const folders = mapFolderItemsToSearchedItem(state.documentsStore.quickSearchedFolders);

  return folders.concat(documents).filter((item) => item.name !== 'trash' && item.name !== '/');
};
export const DocumentsSelector = {
  selectAllFolders,
  selectOrdinaryFolders,
  selectTrash,
  selectSearchedList,
  selectAdvancedSearchFolders,
};
