import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Checkbox } from '@material-ui/core';
import { useStyles } from '../styles';

const IconCell = ({
  onRowCheckboxClick,
  className: classNameProp,
  icon,
  isHover,
  isSelected,
  ...otherProps
}) => {
  const classes = useStyles();
  const showCheckbox = isHover || isSelected;
  return (
    <div className={clsx(classes.iconCell, classNameProp)} {...otherProps}>
      <Checkbox
        onClick={(e) => {
          e.stopPropagation();
          onRowCheckboxClick();
        }}
        checked={isSelected}
        style={{ display: showCheckbox ? 'block' : 'none' }}
      />
      <div style={{ display: !showCheckbox ? 'flex' : 'none', marginLeft: 4 }}> {icon}</div>
    </div>
  );
};

IconCell.propTypes = {
  icon: PropTypes.node,
  isHover: PropTypes.bool,
  onRowCheckboxClick: PropTypes.func,
  isSelected: PropTypes.bool,
  className: PropTypes.string,
};

export default IconCell;
