import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import authActions from 'src/redux/actions/auth.actions';
import { ContentHeader } from 'src/components';
import usePrevious from 'src/helpers/hooks/usePrevious';
import { RootState } from 'src/redux/reducers';
import { RequestStatus } from 'src/helpers/reduxReuquest.util';
import { useStyles } from './styles';
import AddKeyDialog from './subcomponents/AddKeyDialog';
import ApiKeysTable from './subcomponents/ApiKeysTable';

const ApiKeys = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { createNewApiKeyStatus } = useSelector((state: RootState) => state.authStore, undefined);
  const classes = useStyles();
  const [isAddKeyOpen, setIsAddKeyOpen] = useState(false);

  const prevState = usePrevious({
    createNewApiKeyStatus,
  });

  const toggleIsAddKeyOpen = () => setIsAddKeyOpen((prevOpen) => !prevOpen);

  // initial get api keys list
  useEffect(() => {
    dispatch(authActions.getApiKeys());
    dispatch(authActions.getPermissions());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // close add dialog when finished
  useEffect(() => {
    if (
      prevState &&
      prevState.createNewApiKeyStatus === RequestStatus.PENDING &&
      createNewApiKeyStatus === RequestStatus.IDLE
    ) {
      toggleIsAddKeyOpen();
      dispatch(authActions.getApiKeys());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createNewApiKeyStatus]);

  return (
    <div>
      <div className={classes.apiKeysHeaderWrapper}>
        <ContentHeader margin={false} subtitle={t('userConfigurationPage.apiKeys.apiKeysSubtitle')}>
          {t<string>('userConfigurationPage.apiKeys.apiKeysTitle')}
        </ContentHeader>

        <Button variant="contained" color="primary" size="large" onClick={toggleIsAddKeyOpen}>
          {t<string>('userConfigurationPage.apiKeys.addKey')}
        </Button>
      </div>
      <ApiKeysTable />
      {/* TODO: In next stage */}
      {/* <ContentHeading subtitle={t('userConfigurationPage.apiKeys.mobileDevicesSubtitle')}> */}
      {/*   {t<string>('userConfigurationPage.apiKeys.mobileDevicesTitle')} */}
      {/* </ContentHeading> */}

      {isAddKeyOpen && <AddKeyDialog isOpen={isAddKeyOpen} onClose={toggleIsAddKeyOpen} />}
    </div>
  );
};

export default ApiKeys;
