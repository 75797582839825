enum documentInvitationConstants {
  FETCH_DOCUMENT_SIGNERS_REQUEST = 'FETCH_DOCUMENT_SIGNERS_REQUEST',
  FETCH_DOCUMENT_SIGNERS_SUCCESS = 'FETCH_DOCUMENT_SIGNERS_SUCCESS',
  FETCH_DOCUMENT_SIGNERS_ERROR = 'FETCH_DOCUMENT_SIGNERS_ERROR',

  REPLACE_DOCUMENT_SIGNERS_REQUEST = 'REPLACE_DOCUMENT_SIGNERS_REQUEST',
  REPLACE_DOCUMENT_SIGNERS_SUCCESS = 'REPLACE_DOCUMENT_SIGNERS_SUCCESS',
  REPLACE_DOCUMENT_SIGNERS_ERROR = 'REPLACE_DOCUMENT_SIGNERS_ERROR',

  FETCH_DOCUMENT_ROLES_REQUEST = 'FETCH_DOCUMENT_ROLES_REQUEST',
  FETCH_DOCUMENT_ROLES_SUCCESS = 'FETCH_DOCUMENT_ROLES_SUCCESS',
  FETCH_DOCUMENT_ROLES_ERROR = 'FETCH_DOCUMENT_ROLES_ERROR',

  FETCH_DOCUMENT_ROLES_TYPES_REQUEST = 'FETCH_DOCUMENT_ROLES_TYPES_REQUEST',
  FETCH_DOCUMENT_ROLES_TYPES_SUCCESS = 'FETCH_DOCUMENT_ROLES_TYPES_SUCCESS',
  FETCH_DOCUMENT_ROLES_TYPES_ERROR = 'FETCH_DOCUMENT_ROLES_TYPES_ERROR',

  UPDATE_SIGNERS_COUNT_REQUEST = 'UPDATE_SIGNERS_COUNT_REQUEST',
  UPDATE_SIGNERS_COUNT_SUCCESS = 'UPDATE_SIGNERS_COUNT_SUCCESS',
  UPDATE_SIGNERS_COUNT_ERROR = 'UPDATE_SIGNERS_COUNT_ERROR',

  REPLACE_DOCUMENT_ROLES_REQUEST = 'REPLACE_DOCUMENT_ROLES_REQUEST',
  REPLACE_DOCUMENT_ROLES_PARTIAL_SUCCESS = 'REPLACE_DOCUMENT_ROLES_PARTIAL_SUCCESS',
  REPLACE_DOCUMENT_ROLES_SUCCESS = 'REPLACE_DOCUMENT_ROLES_SUCCESS',
  REPLACE_DOCUMENT_ROLES_ERROR = 'REPLACE_DOCUMENT_ROLES_ERROR',

  INVITE_USERS_BY_MAIL_REQUEST = 'INVITE_USERS_BY_MAIL_REQUEST',
  INVITE_USERS_BY_MAIL_SUCCESS = 'INVITE_USERS_BY_MAIL_SUCCESS',
  INVITE_USERS_BY_MAIL_ERROR = 'INVITE_USERS_BY_MAIL_ERROR',

  DELETE_USERS_MAIL_INVITATION_REQUEST = 'DELETE_USERS_MAIL_INVITATION_REQUEST',
  DELETE_USERS_MAIL_INVITATION_SUCCESS = 'DELETE_USERS_MAIL_INVITATION_SUCCESS',
  DELETE_USERS_MAIL_INVITATION_ERROR = 'DELETE_USERS_MAIL_INVITATION_ERROR',

  GET_WORKSPACE_ENTITIES_REQUEST = 'GET_WORKSPACE_ENTITIES_REQUEST',
  GET_WORKSPACE_ENTITIES_SUCCESS = 'GET_WORKSPACE_ENTITIES_SUCCESS',
  GET_WORKSPACE_ENTITIES_ERROR = 'GET_WORKSPACE_ENTITIES_ERROR',

  DOWNLOAD_TRANSFER_OWNERSHIP_PDF_REQUEST = 'DOWNLOAD_TRANSFER_OWNERSHIP_PDF_REQUEST',
  DOWNLOAD_TRANSFER_OWNERSHIP_PDF_SUCCESS = 'DOWNLOAD_TRANSFER_OWNERSHIP_PDF_SUCCESS',
  DOWNLOAD_TRANSFER_OWNERSHIP_PDF_ERROR = 'DOWNLOAD_TRANSFER_OWNERSHIP_PDF_ERROR',

  CLEAR_DOCUMENT_INVITATIONS_STORE = 'CLEAR_DOCUMENT_INVITATIONS_STORE',
}

export default documentInvitationConstants;
