import axios from '../../helpers/axios';
import { parseError } from '../../helpers/parseErrors';

export const moduleName = 'identity-service';

const contactsService = {
  getEntities,
};

function getEntities(workspaceId, entityIds) {
  return axios
    .post(
      `/rest/${moduleName}/workspaces/${workspaceId}/contact-book`,
      {
        entityIds,
      },
      {
        withPermissions: ['IDENTITY_SERVICE_USER_POST_CONTACT_BOOK'],
      }
    )
    .then((response) => response.data)
    .catch((error) => parseError(error));
}

export default contactsService;
