import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AvatarAndTextItem, Gap, SmallContentWithScrollbar } from 'src/components';
import { Button, Typography, useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import twoFactorActions from 'src/redux/actions/twoFactor.actions';
import identityActions from 'src/redux/actions/identity.actions';

const AccountDeleteError = ({ onBack }) => {
  const dispatch = useDispatch();
  const ownerIdentities = useSelector((state) => state.identityStore.nonEmptyWorkspaces);

  const { t } = useTranslation();
  const smallScreen = useMediaQuery(({ breakpoints }) => breakpoints.down('sm'));
  const avatarSize = !smallScreen ? 'large' : 'medium';
  const history = useHistory();

  const userData = {
    login: ' - ',
    email: ' - ',
    organisation: ' - ',
    organisationId: ' - ',
  };

  const changeWorkspace = (id) =>
    dispatch(
      identityActions.switchWorkspace(id, () =>
        history.push('/workspace-settings/user-management/users')
      )
    );

  useEffect(() => {
    setTimeout(() => {
      dispatch(twoFactorActions.closeTwoFactor());
    }, 500);
  }, [dispatch]);

  return (
    <SmallContentWithScrollbar>
      <Typography variant={smallScreen ? 'h3' : 'h2'} gutterBottom={!smallScreen}>
        {t('userConfigurationPage.basic.deleteErrorPage.title')}
      </Typography>

      <Typography variant="body1">
        {t('userConfigurationPage.basic.deleteErrorPage.description')}
      </Typography>
      <Gap size="medium" />
      {ownerIdentities?.length > 0 ? (
        ownerIdentities
          .filter((item) => item.workspace.workspaceId !== userData.organisationId)
          .map((item, index) => (
            <AvatarAndTextItem
              key={index}
              description={item.identity.name}
              text={item.workspace.name}
              spacing
              size={avatarSize}
              mainImageSrc={`${process.env.REACT_APP_AVATAR_URL}/workspace/${item.workspace.workspaceId}?kind=SMALL`}
              tooltip={t('common.changeIdentity')}
              isButton
              onClick={() => {
                changeWorkspace(item.workspace.workspaceId);
              }}
            />
          ))
      ) : (
        <Typography variant="subtitle2" component="div" align="center">
          {t('userDrawer.otherIdentitiesEmpty')}
        </Typography>
      )}
      <Gap size="medium" />
      <Button onClick={onBack} fullWidth size="large">
        {t('common.back')}
      </Button>
    </SmallContentWithScrollbar>
  );
};

AccountDeleteError.propTypes = {
  onBack: PropTypes.func,
};

export default AccountDeleteError;
