import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider } from '@material-ui/core';
import { Plus as PlusIcon } from 'src/components/CustomIcon';
import { DocumentMemberRole, UserManagerPerson } from 'src/models/documentUsersManagement.model';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/redux/reducers';
import { RequestStatus } from 'src/helpers/reduxReuquest.util';
import DocumentUserManagerSelector from 'src/redux/selectors/documentUserManager.selector';
import documentUserManagerActions from 'src/redux/actions/documentUserManager.actions';
import { IdentitySelector } from 'src/redux/selectors/identity.selector';
import { documentDisplaySelector } from 'src/redux/selectors/documentDisplay.selector';
import Section from '../subcomponents/Section';
import ItemsWrapper from '../subcomponents/ItemsWrapper';
import SectionButton from '../subcomponents/SectionButton';
import UserItem from '../subcomponents/UserItem/UserItem';
import { UsersManagerContext, UsersManagerContextType } from '../subcomponents/UsersManagerContext';
import UserItemSkeleton from '../subcomponents/UserItem/UserItemSkeleton';
import AddNewItem from '../subcomponents/UserItem/AddNewItem';

const canChangeRoleOwner = (entity: UserManagerPerson) =>
  !(entity.isGroup && entity._name === 'Owners' && !entity.isExternal);

const canRemoveOwner = (entity: UserManagerPerson) =>
  !(entity.isGroup && entity._name === 'Owners' && !entity.isExternal);

const UsersManagerOtherRoles = (): JSX.Element => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isCertificate = useSelector(documentDisplaySelector.isCertificate, undefined);
  const { draftOtherRoles, updateDraftOtherRoles } = useContext(
    UsersManagerContext
  ) as UsersManagerContextType;
  const { canAddUserDocCert, canModifyRoleDocCert } = useSelector(
    DocumentUserManagerSelector.selectDocumentAction,
    undefined
  );
  const { documentMembersStatus, externalEntitiesStatus, searchedEntitiesStatus } = useSelector(
    (state: RootState) => state.documentUsersManagerStore,
    undefined
  );
  const searchedEntities = useSelector(
    DocumentUserManagerSelector.selectSearchedEntitiesWithDetails,
    undefined
  );
  const workspaceId = useSelector(IdentitySelector.selectWorkspaceId, undefined);

  const [showAddNewItem, setShowAddNewItem] = useState<boolean>(false);
  const [focusInput, setFocusInput] = useState<boolean>(false);

  const isAutocompleteLoading = searchedEntitiesStatus === RequestStatus.PENDING;

  const isLoading =
    documentMembersStatus === RequestStatus.PENDING ||
    externalEntitiesStatus === RequestStatus.PENDING;

  // remove already added items
  const autocompleteItems = searchedEntities.filter(
    (entity) =>
      !draftOtherRoles.map((el) => el._id).includes(entity._id) &&
      !(entity.isGroup && entity.isExternal)
  );

  // skeleton while loading
  const skeleton = useMemo(
    () => (
      <>
        {[1, 2, 3].map(() => (
          <>
            <UserItemSkeleton />
            <Divider />
          </>
        ))}
      </>
    ),
    []
  );

  // Action updates draft state when user select entity from autocomplete list
  const onAddOtherRole = (val: UserManagerPerson) => {
    val.role = DocumentMemberRole.VIEWER;
    updateDraftOtherRoles([...draftOtherRoles, val]);
  };

  // Fetch entities by value from autocomplete
  const fetchWorkspaceEntities = (e: React.ChangeEvent<HTMLElement | unknown>, value: string) => {
    if (workspaceId) dispatch(documentUserManagerActions.getEntitiesByName(workspaceId, value));
  };

  // Close, hide autocomplete list, fetch contact members for next open
  const onInputBlur = () => {
    setShowAddNewItem(false);
    setFocusInput(false);
    if (workspaceId) dispatch(documentUserManagerActions.getEntitiesByName(workspaceId, ''));
  };

  // update draftOtherRoles with new value
  const onRoleChange = (val: UserManagerPerson) => {
    updateDraftOtherRoles(
      draftOtherRoles.map((dR) => {
        if (dR._id && val._id === dR._id) {
          return val;
        }
        if (!val._id && (dR.isInvited || dR.isInvitationToBeSend)) {
          if (dR.email === val.email) {
            // If new value is matches with sent invitation then mark as new invitation to be sent
            if (dR.isInvited) {
              val.isInvited = false;
              val.isInvitationToBeSend = true;
            }

            return val;
          }

          return dR;
        }
        return dR;
      })
    );
  };

  // remove selectedValue
  const onOtherRoleRemove = (index: number) => {
    updateDraftOtherRoles(draftOtherRoles.filter((item, idx) => idx !== index));
  };

  // On add new item button click, fetches initial entities from contact book
  const onAddNewOtherRoleClick = () => {
    setShowAddNewItem(true);
    setFocusInput(true);
    if (workspaceId) dispatch(documentUserManagerActions.getEntitiesByName(workspaceId, ''));
  };

  return (
    <Section
      title={t<string>('documentUserManager.otherRoles.title')}
      description={t('documentUserManager.otherRoles.description')}
      hideDivider={!isCertificate}
      rightContent={
        <ItemsWrapper
          actionButtons={
            canModifyRoleDocCert && !showAddNewItem ? (
              <SectionButton
                icon={<PlusIcon />}
                onClick={onAddNewOtherRoleClick}
                text={t<string>('documentUserManager.otherRoles.addNew')}
              />
            ) : undefined
          }
        >
          {isLoading ? (
            skeleton
          ) : (
            <>
              {draftOtherRoles.map((member: UserManagerPerson, index: number) => (
                <>
                  <UserItem
                    userData={member}
                    onRemove={() => onOtherRoleRemove(index)}
                    onRoleChange={onRoleChange}
                    canRemove={canModifyRoleDocCert && canRemoveOwner(member)}
                    canChangeRole={canModifyRoleDocCert && canChangeRoleOwner(member)}
                    showRoles
                  />
                  <Divider />
                </>
              ))}
              {showAddNewItem && (
                <AddNewItem
                  isLoading={isAutocompleteLoading}
                  focused={focusInput}
                  onInputBlur={onInputBlur}
                  onInputChange={fetchWorkspaceEntities}
                  onAddClick={onAddOtherRole}
                  canInviteByMail={canAddUserDocCert}
                  items={autocompleteItems.sort((a, b) => -b?.isGroup)} // sort by group
                  addedItems={draftOtherRoles}
                />
              )}
            </>
          )}
        </ItemsWrapper>
      }
    />
  );
};
export default UsersManagerOtherRoles;
