import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Collapse, Drawer } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import DocumentUserManagerSelector from 'src/redux/selectors/documentUserManager.selector';
import { DocumentMemberRole } from 'src/models/documentUsersManagement.model';
import { templateTypeToDocumentType, userComActions } from 'src/redux/actions/userCom.actions';
import { IdentitySelector } from 'src/redux/selectors/identity.selector';
import documentUserManagerActions from 'src/redux/actions/documentUserManager.actions';
import { useStyles } from './styles';
import RightSidebarSkeleton from './RightSidebarSkeleton';

const RightSidebar = ({ children, signBanner, tabs, fullHeightContent }) => {
  const [contentScrollable, setContentScrollable] = useState(true);
  const classes = useStyles();
  const dispatch = useDispatch();
  const workspaceId = useSelector(IdentitySelector.selectWorkspaceId);
  const documentInfo = useSelector((state) => state.documentDisplayStore.document, undefined);
  const { draftRoles, otherRecipientsChanged, signersChanged } = useSelector(
    (state) => state.documentUsersManagerStore
  );
  const isRecipientsUpdated = useSelector((state) =>
    DocumentUserManagerSelector.selectRecipientsUpdated(state, Object.values(DocumentMemberRole))
  );
  const onCollapseToggle = {
    onStart: () => {
      setContentScrollable(false);
    },
    onEnd: () => {
      setContentScrollable(true);
    },
  };

  // Check if recipient update finished, send events to user.com, cleanup updated state
  useEffect(() => {
    if (isRecipientsUpdated) {
      if (signersChanged) {
        userComActions.document.docSignersUpdated(
          templateTypeToDocumentType(documentInfo.templateType),
          draftRoles[DocumentMemberRole.SIGNER].length
        );
        dispatch(documentUserManagerActions.updateSignersChanged(false));
      }
      if (otherRecipientsChanged) {
        userComActions.document.docRecipientsUpdated(workspaceId);
        dispatch(documentUserManagerActions.updateRecipientsChanged(false));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRecipientsUpdated]);

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: clsx(
          classes.drawerPaper,
          'scrollElementDrawer',
          !contentScrollable && classes.drawerPaperNotScrollable
        ),
      }}
      PaperProps={{ id: 'scroll-element-sidebar' }}
      anchor="right"
    >
      <div className={classes.contentWrapper}>
        {!isRecipientsUpdated ? (
          <RightSidebarSkeleton />
        ) : (
          <>
            <Collapse
              in={!fullHeightContent}
              unmountOnExit
              onEnter={onCollapseToggle.onStart}
              onEntered={onCollapseToggle.onEnd}
              onExit={onCollapseToggle.onStart}
              onExited={onCollapseToggle.onEnd}
            >
              <div className={clsx(classes.content, classes.contentTop)}>
                {signBanner}
                {tabs}
              </div>
            </Collapse>

            {children && (
              <div
                className={clsx(classes.content, classes.contentBottom, classes.childrenWrapper)}
              >
                {children}
              </div>
            )}
          </>
        )}
      </div>
    </Drawer>
  );
};

RightSidebar.propTypes = {
  children: PropTypes.node,
  signBanner: PropTypes.node,
  tabs: PropTypes.node,
  fullHeightContent: PropTypes.bool,
};

RightSidebar.defaultProps = {
  fullHeightContent: false,
};

export default RightSidebar;
