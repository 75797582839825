import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ palette }) =>
    createStyles({
      root: {},
      inputsContainer: {
        width: '100%',
        position: 'relative',
        '& svg': {
          color: palette.text.disabled,
        },
      },
      inputRoot: {
        display: 'none',
      },
      input: {
        minHeight: 'unset',
        padding: 0,
      },
      inputNotchedOutline: {
        // border: 0,
      },
      inputAdornment: {
        color: palette.text.secondary,
        fontSize: 12,
        '& p': {
          color: 'inherit !important',
          fontSize: 'inherit !important',
        },
      },
      inputDisabled: {},
    }),
  { index: 0 }
);
