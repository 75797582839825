import React, { ChangeEvent, useState } from 'react';
import { Button, MenuItem, Select, Typography } from '@material-ui/core';
import { SmallContentWithScrollbar } from 'src/components';
import { useTranslation } from 'react-i18next';
import { switchLanguageInBrowser } from 'src/helpers/globalUtils';
import { useDispatch } from 'react-redux';
import authActions from 'src/redux/actions/auth.actions';

type LanguageEditProps = {
  onClose: () => void;
};
const LanguageEdit = ({ onClose }: LanguageEditProps): JSX.Element => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [lang, setLang] = useState(i18n.language);
  const items = ['EN', 'PL'];

  const getLanguageIconAndName = (language: string) => {
    switch (language.toLowerCase()) {
      case 'pl':
        return {
          code: 'pl-PL',
          text: 'language.pl-PL.text',
        };

      case 'en':
      default:
        return {
          code: 'en-GB',
          text: 'language.en-GB.text',
        };
    }
  };
  const languageItems = items.map((language) => ({
    value: language,
    active: i18n.language.toLocaleUpperCase() === language,
    name: t(getLanguageIconAndName(language).text),
  }));

  const changeUserLanguage = (language: string) => {
    dispatch(authActions.patchLanguage(language.toLowerCase()));
    switchLanguageInBrowser(language.toUpperCase());
    onClose();
  };

  return (
    <SmallContentWithScrollbar
      startContent={
        <Typography>{t<string>('userConfigurationPage.basic.languagePage.title')}</Typography>
      }
      scrollableContent
      hasForm
      formProps={{
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onSubmit: (e: any) => {
          e.preventDefault();
          changeUserLanguage(lang);
        },
      }}
      size="medium"
      endContent={
        <Button color="primary" variant="contained" fullWidth type="submit">
          {t<string>('userConfigurationPage.basic.languagePage.button')}
        </Button>
      }
    >
      <Select
        style={{ width: '100%' }}
        value={lang}
        onChange={(e: ChangeEvent<{ value: string | unknown }>) =>
          setLang(e.target.value as string)
        }
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
      >
        {languageItems.map((language, index) => (
          <MenuItem key={index} value={language.value}>
            {language.name}
          </MenuItem>
        ))}
      </Select>
    </SmallContentWithScrollbar>
  );
};

export default LanguageEdit;
