import { fade, lighten } from '@material-ui/core/styles';
import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ palette, spacing, transitions, typography }) =>
    createStyles({
      root: {
        display: 'inline-flex',
        textAlign: 'center',
        alignItems: 'center',
        borderRadius: spacing(2),
        minWidth: 'auto',
        textTransform: typography.button.textTransform,
        fontWeight: typography.fontWeightMedium,
        lineHeight: '1.15',
        padding: spacing(0.75, 1.5),
        fontSize: typography.subtitle2.fontSize,
        fontFamily: 'inherit',
        outline: 'none',
      },
      notClickableRoot: {},
      clickableRoot: {
        cursor: 'pointer',
        transition: transitions.create(['background-color', 'border', 'color'], {
          duration: transitions.duration.short,
        }),
      },
      defaultColor: {
        color: palette.text.secondary,
        backgroundColor: fade(palette.greySimple.light, 0.5),
        border: '1px solid transparent',
        '&$clickableRoot': {
          '&:hover': {
            borderColor: palette.greySimple.light,
          },
        },
      },
      errorColor: {
        backgroundColor: lighten(palette.error.main, 0.85),
        color: palette.error.main,
        border: `1px solid ${lighten(palette.error.main, 0.85)}`,
        '&$clickableRoot': {
          '&:hover': {
            borderColor: palette.error.main,
          },
        },
      },
      warningColor: {
        backgroundColor: lighten(palette.warning.main, 0.85),
        color: palette.warning.main,
        border: `1px solid ${lighten(palette.warning.main, 0.85)}`,
        '&$clickableRoot': {
          '&:hover': {
            borderColor: palette.warning.main,
          },
        },
      },
      infoColor: {
        color: palette.primary.light,
        backgroundColor: lighten(palette.primary.light, 0.85),
        border: `1px solid ${lighten(palette.primary.light, 0.85)}`,
        '&$clickableRoot': {
          '&:hover': {
            borderColor: palette.primary.light,
          },
        },
      },
      successColor: {
        backgroundColor: lighten(palette.success.main, 0.85),
        color: palette.success.main,
        border: `1px solid ${lighten(palette.success.main, 0.85)}`,
        '&$clickableRoot': {
          '&:hover': {
            borderColor: palette.success.main,
          },
        },
      },
    }),
  { index: 0 }
);
