import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ palette, spacing, typography }) =>
    createStyles({
      root: {},
      item: {
        paddingLeft: spacing(2),
        paddingRight: spacing(2),
      },
      listItem: {
        minHeight: spacing(10),
        '&:not(:hover):not(:focus)': {
          '& $listItemText': {
            color: palette.text.primary,
          },
        },
      },
      listItemActive: {
        fontWeight: typography.fontWeightBold,
        '& $listItemText': {
          color: `${palette.primary.main} !important`,
        },
      },
      listItemWithSecondaryAction: {
        paddingRight: spacing(6),
      },
      listItemIcon: {
        marginRight: spacing(2),
      },
      listItemText: {
        whiteSpace: 'normal',
        lineHeight: 1.3,
      },
      listItemSecondaryAction: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
        right: spacing(2),
      },
    }),
  { index: 0 }
);
