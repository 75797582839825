import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ palette, typography }) =>
    createStyles({
      wrapper: {
        position: 'relative',
        background: palette.background.default,
        padding: '16px 54px',
        borderRadius: 8,
        marginBottom: 16,
      },
      icon: {
        color: palette.primary.main,
        position: 'absolute',
        left: 16,
        top: 20,
      },
      text: {
        display: 'flex',
        flexDirection: 'column',
      },
      content: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 20,
      },
      button: {
        position: 'absolute',
        right: 16,
        top: 20,
      },
      bold: {
        fontWeight: typography.fontWeightMedium,
      },
    }),
  { index: 0 }
);
