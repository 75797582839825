import React from 'react';
import { Divider, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useUsersManagerSectionStyle } from './styles';

type classesOverride = {
  rightContentWrapper?: string;
};
interface SectionProps {
  title?: string;
  description?: JSX.Element;
  leftContent?: JSX.Element;
  rightContent?: JSX.Element;
  hideDivider?: boolean;
  classesOverride?: classesOverride;
}

const Section = ({
  title,
  description,
  leftContent,
  rightContent,
  hideDivider = false,
  classesOverride,
}: SectionProps): JSX.Element => {
  const classes = useUsersManagerSectionStyle();

  return (
    <>
      <div className={classes.sectionWrapper}>
        <div className={classes.leftContentWrapper}>
          <div className={classes.sticky}>
            {title && (
              <Typography className={classes.title} variant="h2">
                {title}
              </Typography>
            )}
            {description && (
              <Typography className={classes.description} color="textSecondary" variant="body1">
                {description}
              </Typography>
            )}
            <div className={classes.leftContentChildren}>{leftContent}</div>
          </div>
        </div>
        <div
          className={clsx(
            classes.rightContentWrapper,
            classesOverride?.rightContentWrapper && classesOverride.rightContentWrapper
          )}
        >
          {rightContent}
        </div>
      </div>
      {!hideDivider && <Divider className={classes.divider} />}
    </>
  );
};
export default Section;
