import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { NavLink, useLocation } from 'react-router-dom';
import { useStyles } from './styles';

const MenuNavigationList = ({ className: classNameProp, items, variant, size }) => {
  const classes = useStyles({});
  const location = useLocation();

  const checkIfChildrenRouteActive = (subitems) => {
    const currentPath = location.pathname;
    return !!(subitems && subitems.includes(currentPath));
  };

  return (
    <List
      component="nav"
      className={clsx(
        classes.root,
        {
          [classes[`${variant}Color`]]: variant,
        },
        classNameProp
      )}
    >
      {items.map((item, index) =>
        !item.onlyMobile ? (
          <div
            className={clsx(classes.mainItem, {
              [classes[`${size}MainItem`]]: size,
            })}
            key={index}
          >
            {item.children ? (
              <ListItem
                component="div"
                divider={false}
                className={clsx(classes.item, classes.itemNoClick, {
                  [classes[`${size}ItemSize`]]: size,
                  [classes.itemActive]: checkIfChildrenRouteActive(
                    item.children.map((item) => item.url)
                  ),
                })}
              >
                {item.icon && <ListItemIcon className={classes.itemIcon}>{item.icon}</ListItemIcon>}
                <ListItemText inset={!item.icon} primary={item.label} />
              </ListItem>
            ) : (
              <ListItem
                component={NavLink}
                to={item.url}
                button
                divider={false}
                className={clsx(classes.item, {
                  [classes[`${size}ItemSize`]]: size,
                })}
                activeClassName={classes.itemActive}
              >
                {item.icon && <ListItemIcon className={classes.itemIcon}>{item.icon}</ListItemIcon>}
                <ListItemText inset={!item.icon} primary={item.label} />
              </ListItem>
            )}

            {item.children && (
              <List component="div" className={classes.subItems}>
                {item.children.map((subItem, subItemIndex) => (
                  <ListItem
                    key={subItemIndex}
                    component={NavLink}
                    to={subItem.url}
                    exact
                    button
                    divider={false}
                    dense
                    className={clsx(
                      classes.item,
                      {
                        [classes[`${size}ItemSize`]]: size,
                      },
                      classes.nestedItem
                    )}
                    activeClassName={classes.itemActive}
                  >
                    <ListItemText primary={subItem.label} />
                  </ListItem>
                ))}
              </List>
            )}
          </div>
        ) : null
      )}
    </List>
  );
};

MenuNavigationList.propTypes = {
  className: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  variant: PropTypes.oneOf(['default', 'inverted']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

MenuNavigationList.defaultProps = {
  variant: 'default',
  size: 'large',
};

export default MenuNavigationList;
