import { SNACKBAR_VARIANT } from 'src/models/common.model';
import { AppDispatch } from 'src/redux/storeTs';
import i18n from 'src/helpers/i18n';
import billingConstants from 'src/redux/constants/billing.constants';
import snackbarActions from 'src/redux/actions/snackbar.actions';
import billingService from '../services/billing.service';
import { BillingProductType } from '../../models/billing.model';
import { ErrorCode } from '../../models/errors.model';

// TODO: implement action types
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const fetchSubscriptions = (workspaceId: string) => (dispatch: AppDispatch) => {
  dispatch({ type: billingConstants.FETCH_SUBSCRIPTIONS_REQUEST });
  billingService
    .fetchSubscriptions({ workspaceId })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .then((response: any) => {
      dispatch({
        type: billingConstants.FETCH_SUBSCRIPTIONS_SUCCESS,
        payload: response.data.data.collection,
      });
    })
    .catch(() => {
      dispatch({ type: billingConstants.FETCH_SUBSCRIPTIONS_FAILURE });
    });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const setTrialSubscription = (workspaceId: string, product: BillingProductType) => (
  dispatch: AppDispatch
) => {
  dispatch({ type: billingConstants.SET_TRIAL_SUBSCRIPTION_REQUEST });
  billingService
    .setTrialSubscription({ workspaceId, product })
    .then(() => {
      setTimeout(() => {
        dispatch({
          type: billingConstants.SET_TRIAL_SUBSCRIPTION_SUCCESS,
        });
        dispatch(billingActions.fetchSubscriptions(workspaceId));
      }, 1000);
    })
    .catch((error) => {
      dispatch({ type: billingConstants.SET_TRIAL_SUBSCRIPTION_FAILURE });
      dispatch(
        snackbarActions.enqueueSnackbar(
          SNACKBAR_VARIANT.ERROR,
          i18n.t('snackbars.common.errorTitle'),
          `${i18n.t('snackbars.common.error')} ${error.code}.`
        )
      );
    });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const fetchCustomer = (workspaceId: string) => (dispatch: AppDispatch) => {
  dispatch({ type: billingConstants.FETCH_CUSTOMER_REQUEST });
  billingService
    .fetchCustomer({ workspaceId })
    .then(() => {
      dispatch({
        type: billingConstants.FETCH_CUSTOMER_SUCCESS,
      });
    })
    .catch(() => {
      dispatch({ type: billingConstants.FETCH_CUSTOMER_FAILURE });
    });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const setCustomer = (workspaceId: string, workspaceName: string, callback: () => void) => (
  dispatch: AppDispatch
) => {
  dispatch({ type: billingConstants.SET_CUSTOMER_REQUEST });
  billingService
    .setCustomer({ workspaceId, workspaceName })
    .then(() => {
      dispatch(billingActions.fetchCustomer(workspaceId));
      dispatch({
        type: billingConstants.SET_CUSTOMER_SUCCESS,
      });
      callback();
    })
    .catch((error) => {
      if (error.code === ErrorCode.CONFLICT) {
        callback();
      } else {
        dispatch({ type: billingConstants.SET_CUSTOMER_FAILURE });
        dispatch(
          snackbarActions.enqueueSnackbar(
            SNACKBAR_VARIANT.ERROR,
            i18n.t('snackbars.common.errorTitle'),
            `${i18n.t('snackbars.common.error')} ${error.code}.`
          )
        );
      }
    });
};
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const resetBilling = () => (dispatch: AppDispatch) =>
  dispatch({ type: billingConstants.RESET_BILLING });

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const resetSetTrialSubscription = () => (dispatch: AppDispatch) =>
  dispatch({ type: billingConstants.SET_TRIAL_SUBSCRIPTION_IDLE });

const billingActions = {
  fetchSubscriptions,
  setTrialSubscription,
  resetSetTrialSubscription,
  fetchCustomer,
  setCustomer,
  resetBilling,
};

export default billingActions;
