import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Typography } from '@material-ui/core';
import { useStyles } from './styles';

const TabHeader = ({ className: classNameProp, label, action }) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, classNameProp)}>
      <Typography variant="button">{label}</Typography>
      {action && <div className={classes.action}>{action}</div>}
    </div>
  );
};

TabHeader.propTypes = {
  className: PropTypes.string,
  action: PropTypes.node,
  label: PropTypes.string.isRequired,
};

export default TabHeader;
