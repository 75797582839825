import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { AvatarAndTextItem, Tag } from 'src/components';
import ContactHelper from 'src/helpers/contactHelper';
import { IdentitySelector } from 'src/redux/selectors/identity.selector';
import { DocumentRole } from 'src/models/document.model';
import { useSelector } from 'react-redux';
import { RegexFormulas } from 'src/helpers/regex';
import { useStyles } from './styles';
import RecipientItemStep from './RecipientItemStep';

const getItemColor = (status) => {
  switch (status) {
    case 'SIGNED':
      return 'success';
    case 'REJECTED_SIGNATURE':
      return 'error';
    case 'AWAITING_SIGNATURE':
      return 'waiting';
    case 'PARTICIPANT':
      return 'default';
    default:
      return 'default';
  }
};

const RecipientsItem = ({ className: classNameProp, userId, status, role, active, userData }) => {
  const { t } = useTranslation();
  const [didMount, setDidMount] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    setDidMount(true);
    return () => setDidMount(false);
  }, []);

  const currentIdentityId = useSelector(
    (state) => state.identityStore.currentIdentity?.identity.identityId,
    undefined
  );
  const { document: documentInfo } = useSelector((state) => state.documentDisplayStore, undefined);

  const { isOrderSignificant } = useSelector((state) => state.documentInvitationsStore, undefined);
  const isSigningOrderAvailable = useSelector(
    IdentitySelector.hasSigningOrderPermission,
    undefined
  );

  const { authorId } = documentInfo;

  const user = userData || { name: userId, email: userId, active };

  const getColorAndTextStatus = () => {
    switch (status) {
      case 'SIGNED':
        return {
          color: 'success',
          text: t('documentActions.tabs.recipients.status.signed'),
        };

      case 'REJECTED_SIGNATURE':
        return {
          color: 'error',
          text: t('documentActions.tabs.recipients.status.reject'),
        };
      case 'AWAITING_SIGNATURE':
        return {
          color: 'info',
          text: t('documentActions.tabs.recipients.status.waiting'),
        };
      case 'PARTICIPANT':
        return {
          color: 'default',
          text: t('documentActions.tabs.recipients.status.preview'),
        };

      default:
        return {
          color: '',
          text: '',
        };
    }
  };

  const getColorAndTextRole = () => {
    switch (role.toLowerCase()) {
      case DocumentRole.SIGNERS:
        return {
          color: 'info',
          text: t('documentActions.tabs.recipients.status.waiting'),
        };
      case DocumentRole.ADMINS:
        return {
          color: 'default',
          text: t('documentActions.tabs.recipients.role.admin'),
        };
      case DocumentRole.VIEWERS:
        return {
          color: 'default',
          text: t('documentActions.tabs.recipients.role.viewer'),
        };
      case DocumentRole.AUDITORS:
        return {
          color: 'default',
          text: t('documentActions.tabs.recipients.role.auditor'),
        };
      case DocumentRole.HOLDER:
        return {
          color: 'default',
          text: t('documentActions.tabs.recipients.role.holder'),
        };
      case DocumentRole.EDITORS:
        return {
          color: 'default',
          text: t('documentActions.tabs.recipients.role.editor'),
        };
      default:
        return {
          color: '',
          text: '',
        };
    }
  };

  const additionalTextArray = [];

  if (userId === authorId) {
    additionalTextArray.push({
      classes: clsx(classes.additionalUserText, classes.authorText),
      text: `(${t('common.author')})`,
    });
  }
  if (userId === currentIdentityId) {
    additionalTextArray.push({
      classes: clsx(classes.additionalUserText, classes.youText),
      text: `(${t('common.you')})`,
    });
  }

  if (!user.active) {
    additionalTextArray.push({
      classes: clsx(classes.additionalUserText, classes.youText),
      text: `(${t('common.removed')})`,
    });
  }

  if (!userId) {
    additionalTextArray.push({
      classes: clsx(classes.additionalUserText, classes.youText, classes.additionalInfo),
      text: `(${t('common.invitationByMail')})`,
    });
  } else {
    const matchedIdentity = ContactHelper.getEntityData(userId);
    if (matchedIdentity && !matchedIdentity.isFromOrganisation) {
      additionalTextArray.push({
        classes: clsx(
          classes.additionalUserText,
          classes.youText,
          classes.additionalInfo,
          matchedIdentity.email !== matchedIdentity.workspaceName && classes.weightMedium
        ),
        text:
          matchedIdentity.email !== matchedIdentity.workspaceName
            ? matchedIdentity.workspaceName
            : t('inviteToDocument.entitiesList.outsideOrganization'),
      });
    }
  }

  const { color: tagColor, text: tagText } = getColorAndTextStatus();
  const { color: roleColor, text: roleText } = getColorAndTextRole();
  if (!didMount || !user) {
    return null;
  }

  if (user) {
    return (
      <div
        className={clsx(
          classes.root,
          classNameProp,
          !user.active && classes.deleteItem,
          isOrderSignificant && isSigningOrderAvailable && `${String(getItemColor(status))}Color`
        )}
      >
        {role === DocumentRole.SIGNERS &&
          user.active &&
          isOrderSignificant &&
          isSigningOrderAvailable && <RecipientItemStep />}
        <AvatarAndTextItem
          className={classes.user}
          description={user.email}
          text={user.name}
          additionalTextArray={additionalTextArray}
          size="medium"
          mainImageSrc={
            userId && !user.group && RegexFormulas.uuidV4.test(userId)
              ? `${process.env.REACT_APP_AVATAR_URL}/identity/${userId}?kind=SMALL`
              : null
          }
        />

        {tagText && role === DocumentRole.SIGNERS && user.active && (
          <Tag className={classes.tag} color={tagColor}>
            {tagText}
          </Tag>
        )}
        {roleText && (role !== DocumentRole.SIGNERS || status === undefined) && user.active && (
          <Tag className={classes.tag} color={roleColor}>
            {roleText}
          </Tag>
        )}
      </div>
    );
  }
  return null;
};

RecipientsItem.propTypes = {
  className: PropTypes.string,
  userData: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
  }),
  userId: PropTypes.string,
  status: PropTypes.string,
  active: PropTypes.string,
  role: PropTypes.string,
};

export default RecipientsItem;
