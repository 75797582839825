import i18n from 'src/helpers/i18n';
import { SNACKBAR_VARIANT } from 'src/models/common.model';
import snackbarActions from './snackbar.actions';
import filesModuleService from '../services/filesModule.service';
import documentCoreService from '../services/documentCore.service';
import documentDownloadConstants from '../constants/documentDownload.constants';

const documentDownloadActions = {
  downloadDocument,
  websocketDownloadDocument,
  downloadDocumentFile,
};

function downloadDocument(documentId, service) {
  return (dispatch) => {
    dispatch({ type: documentDownloadConstants.DOWNLOAD_DOCUMENT_REQUEST, documentId });
    documentCoreService
      .downloadDocument(documentId, service)
      .then(() => {
        dispatch({
          type: documentDownloadConstants.DOWNLOAD_DOCUMENT_SUCCESS,
        });
        dispatch(
          snackbarActions.enqueueSnackbar(
            SNACKBAR_VARIANT.INFO,
            i18n.t('snackbars.common.infoTitle'),
            i18n.t('documentActions.downloadDocumentToast')
          )
        );
      })
      .catch((error) => {
        dispatch({ type: documentDownloadConstants.DOWNLOAD_DOCUMENT_FAILURE, documentId });
        dispatch(
          snackbarActions.enqueueSnackbar(
            SNACKBAR_VARIANT.ERROR,
            i18n.t('snackbars.common.errorTitle'),
            `${i18n.t('snackbars.common.error')} ${error.code}.`
          )
        );
      });
  };
}

function downloadDocumentFile(documentId, token, callback = () => undefined) {
  return (dispatch) => {
    dispatch({ type: documentDownloadConstants.DOWNLOAD_DOCUMENT_FILE_REQUEST });
    filesModuleService
      .downloadFile(token)
      .then((response) => {
        dispatch({
          type: documentDownloadConstants.DOWNLOAD_DOCUMENT_FILE_SUCCESS,
        });

        const download = (url) => {
          const link = document.createElement('a');
          link.href = url;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        };
        download(response.request.responseURL);
        callback();
      })
      .catch((error) => {
        dispatch({ type: documentDownloadConstants.DOWNLOAD_DOCUMENT_FILE_FAILURE });
        dispatch(
          snackbarActions.enqueueSnackbar(
            SNACKBAR_VARIANT.ERROR,
            i18n.t('snackbars.common.errorTitle'),
            error.code === 'TOO_MANY_REQUESTS_ERROR'
              ? i18n.t('documentActions.downloadDocumentTooManyRequestsToast')
              : `${i18n.t('snackbars.common.error')} ${error.code}.`
          )
        );
      });
  };
}

function websocketDownloadDocument(documentId) {
  return { type: documentDownloadConstants.WS_DOWNLOAD_DOCUMENT, documentId };
}

export default documentDownloadActions;
