import React, { useEffect } from 'react';
import { Button, CircularProgress, Typography, useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { SmallContentWithScrollbar } from 'src/components';
import { RequestStatus } from 'src/helpers/reduxReuquest.util';
import { CertificateSteps } from 'src/models/certificate.model';
import { splitArrayIntoTwo } from 'src/helpers/globalUtils';
import certificateCreatorActions from 'src/redux/actions/certificateCreator.actions';
import { useStyles as useCreatorStyles } from '../../styles';
import CertificateOption from './CertificateOption';
import { useStyles } from './styles';

const SelectTemplateType = () => {
  const { t } = useTranslation();
  const {
    templateList,
    templateListStatus,
    selectedTemplate,
    certificateTemplatesStatus,
    templateListTokens,
  } = useSelector((state) => state.certificateCreatorStore, undefined);
  const { currentIdentity } = useSelector((state) => state.identityStore, undefined);
  const workspaceId = currentIdentity?.workspace?.workspaceId;

  const dispatch = useDispatch();
  const creatorClasses = useCreatorStyles();
  const classes = useStyles();
  const smallScreen = useMediaQuery(({ breakpoints }) => breakpoints.down('sm'));
  const isLoading = certificateTemplatesStatus === RequestStatus.PENDING;

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(
      certificateCreatorActions.setCreatorStep({ currentStep: CertificateSteps.SelectQuantityType })
    );
    dispatch(
      certificateCreatorActions.fetchTemplateDetails({
        templateId: selectedTemplate.id,
        workspaceId,
      })
    );
  };

  const onOptionClick = (template) => {
    dispatch(certificateCreatorActions.setSelectedTemplate(template));
  };

  const onPreviewClick = () => {
    dispatch(
      certificateCreatorActions.setCreatorStep({ currentStep: CertificateSteps.TemplatePreview })
    );
  };

  useEffect(() => {
    if (workspaceId && templateListStatus === RequestStatus.IDLE) {
      dispatch(certificateCreatorActions.fetchTemplateList(workspaceId));
    }
  }, [workspaceId, templateListStatus, dispatch]);

  useEffect(() => {
    if (templateList && templateList.length > 0 && workspaceId) {
      templateList.forEach((template) => {
        dispatch(
          certificateCreatorActions.fetchTemplateListThumbnailToken({ template, workspaceId })
        );
      });
    }
  }, [templateList, workspaceId, dispatch]);

  useEffect(() => {
    dispatch(certificateCreatorActions.resetForNewTemplate());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SmallContentWithScrollbar
      size="large"
      className={creatorClasses.contentWrapper}
      endContent={
        <Button
          size="large"
          type="submit"
          className={creatorClasses.bottomButton}
          disabled={selectedTemplate === undefined}
        >
          {t('createCertificate.createNewCertificate')}
        </Button>
      }
      startContent={
        <Typography variant={smallScreen ? 'h3' : 'h2'}>
          {t('createCertificate.chooseCertTemplate')}
        </Typography>
      }
      hasForm
      formProps={{
        noValidate: true,
        autoComplete: 'off',
        onSubmit,
      }}
    >
      {isLoading ? (
        <div className={creatorClasses.loaderWrapper}>
          <CircularProgress size={48} />
        </div>
      ) : (
        <div className={classes.wrapper}>
          {templateList && templateList.length > 0 && (
            <>
              {smallScreen
                ? splitArrayIntoTwo(templateList).map((list) => (
                    <div className={classes.mobileList}>
                      {list.map((template, index) => (
                        <CertificateOption
                          key={`${index}_${template.id}`}
                          name={template.templateName}
                          id={template.id}
                          token={templateListTokens[template.id]}
                          onPreviewClick={onPreviewClick}
                          onOptionClick={() => onOptionClick(template)}
                        />
                      ))}
                    </div>
                  ))
                : templateList.map((template, index) => (
                    <CertificateOption
                      key={`${index}_${template.id}`}
                      name={template.templateName}
                      id={template.id}
                      token={templateListTokens[template.id]}
                      onPreviewClick={onPreviewClick}
                      onOptionClick={() => onOptionClick(template)}
                    />
                  ))}
            </>
          )}
          {templateList && templateList.length === 0 && (
            <Typography>{t('createCertificate.noTemplatesMessage')}</Typography>
          )}
        </div>
      )}
    </SmallContentWithScrollbar>
  );
};

export default SelectTemplateType;
