import React, { useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  LinearProgress,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  BillingProduct,
  BillingProductStatus,
  BillingProductType,
  checkSubscription,
} from 'src/models/billing.model';
import { SubscriptionChangeType, userComActions } from 'src/redux/actions/userCom.actions';
import { useDispatch, useSelector } from 'react-redux';
import billingActions from 'src/redux/actions/billing.actions';
import { IdentitySelector } from 'src/redux/selectors/identity.selector';
import { RootState } from 'src/redux/reducers';
import { RequestStatus } from 'src/helpers/reduxReuquest.util';
import { DateTimeFormats } from 'src/helpers/dateTimeFormats';
import { useStyles } from './styles';

type UsageBarProps = {
  product: BillingProduct | undefined;
};
const UsageBar = ({ product }: UsageBarProps): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { daysLeft } = checkSubscription(product);
  return (
    <div className={classes.barWrapper}>
      <div className={classes.barText}>
        {t<string>('workspacePage.subscription.daysLeft', { count: daysLeft })}
      </div>
      <LinearProgress
        classes={{ root: classes.bar, bar: classes.innerBar }}
        value={(100 * daysLeft) / 14}
        variant="determinate"
      />
    </div>
  );
};

interface SubscriptionProductProps {
  product: BillingProduct | undefined;
  type: BillingProductType;
}

const SubscriptionProduct = ({ product, type }: SubscriptionProductProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const workspaceId = useSelector(IdentitySelector.selectWorkspaceId);
  const workspaceDetails = useSelector(IdentitySelector.selectWorkspaceDetails);
  const workspaceName = workspaceDetails?.name || '';
  const { isCustomerCreated, setCustomerStatus } = useSelector(
    (state: RootState) => state.billingStore
  );

  const [startTrialLoading, setStartTrialLoading] = useState<boolean>(false);
  const isCustomerCreating = setCustomerStatus === RequestStatus.PENDING;

  const title =
    type === BillingProductType.EsignDrive
      ? t<string>('workspacePage.subscription.esignDrive')
      : t<string>('workspacePage.subscription.certificates');

  // on Start trial clicked
  const onStartTrial = () => {
    if (!workspaceId) return;
    setStartTrialLoading(true);
    if (isCustomerCreated) {
      userComActions.subscription.subTrialStarted(type, workspaceId);
      dispatch(billingActions.setTrialSubscription(workspaceId, type));
    } else {
      dispatch(
        billingActions.setCustomer(workspaceId, workspaceName, () => {
          userComActions.subscription.subTrialStarted(type, workspaceId);
          dispatch(billingActions.setTrialSubscription(workspaceId, type));
        })
      );
    }
  };

  // Action - Contact sales when no product
  const onContactSales = (variant: SubscriptionChangeType): void => {
    if (workspaceId) userComActions.subscription.subChangesNeeded(type, variant, workspaceId);
  };

  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography variant="h4">{title}</Typography>

        <Divider className={classes.divider} />

        <div className={classes.content}>
          <div className={classes.textCol}>
            <Typography variant="h6" color="textSecondary">
              {t<string>('workspacePage.subscription.subscriptionStatus')}
            </Typography>
            {product && product.status === BillingProductStatus.Active && product.cancelAt && (
              <Typography variant="h6" color="textSecondary" className={classes.cancelAt}>
                {t<string>('workspacePage.subscription.endDate')}
              </Typography>
            )}
            {product && product.status === BillingProductStatus.Trialing && (
              <Typography variant="h6" color="textSecondary" className={classes.cancelAt}>
                {t<string>('workspacePage.subscription.usage')}
              </Typography>
            )}
          </div>
          <div className={classes.infoCol}>
            {!product && (
              <Typography className={classes.notStarted}>
                {t<string>('workspacePage.subscription.notStarted')}
              </Typography>
            )}
            {product && product.status === BillingProductStatus.Active && (
              <>
                <Typography className={classes.active}>
                  {t<string>('workspacePage.subscription.active')}
                </Typography>
                {product.cancelAt && (
                  <Typography variant="h6" className={classes.cancelAt}>
                    {moment(product.cancelAt).format(DateTimeFormats.dateShort)}
                  </Typography>
                )}
              </>
            )}
            {product && product.status === BillingProductStatus.Trialing && (
              <>
                <Typography className={classes.trial}>
                  {t<string>('workspacePage.subscription.trial')}
                </Typography>
                <UsageBar product={product} />
              </>
            )}
            {product && product.status === BillingProductStatus.TrialFinished && (
              <Typography className={classes.trialCompleted}>
                {t<string>('workspacePage.subscription.trialCompleted')}
              </Typography>
            )}
            {product && product.status === BillingProductStatus.Canceled && (
              <Typography className={classes.trialCompleted}>
                {t<string>('workspacePage.subscription.canceled')}
              </Typography>
            )}
          </div>
        </div>

        <Divider className={classes.divider} />
        <div className={classes.buttonsWrapper}>
          {!product && (
            <>
              <Button
                disabled={startTrialLoading || isCustomerCreating}
                endIcon={startTrialLoading && <CircularProgress size={20} color="inherit" />}
                onClick={onStartTrial}
                className={classes.wideButton}
                color="secondary"
                size="small"
              >
                {t<string>('workspacePage.subscription.startTrial')}
              </Button>
              <Button
                onClick={() => onContactSales(SubscriptionChangeType.NewPurchase)}
                variant="text"
                size="small"
              >
                {t<string>('workspacePage.subscription.contactSales')}
              </Button>
            </>
          )}
          {product && product.status === BillingProductStatus.Trialing && (
            <>
              <Button
                onClick={() => onContactSales(SubscriptionChangeType.PurchaseDuringTrial)}
                className={classes.wideButton}
                color="secondary"
                size="small"
              >
                {t<string>('workspacePage.subscription.contactSalesUpgrade')}
              </Button>
              <Button
                onClick={() => onContactSales(SubscriptionChangeType.TrialExtension)}
                variant="text"
                size="small"
              >
                {t<string>('workspacePage.subscription.extendTrial')}
              </Button>
            </>
          )}
          {product && product.status === BillingProductStatus.TrialFinished && (
            <>
              <Button
                onClick={() => onContactSales(SubscriptionChangeType.PurchaseAfterTrial)}
                className={classes.wideButton}
                color="secondary"
                size="small"
              >
                {t<string>('workspacePage.subscription.contactSalesPurchase')}
              </Button>
            </>
          )}
          {product && product.status === BillingProductStatus.Canceled && (
            <>
              <Button
                onClick={() => onContactSales(SubscriptionChangeType.NewPurchase)}
                className={classes.wideButton}
                color="secondary"
                size="small"
              >
                {t<string>('workspacePage.subscription.contactSalesPurchase')}
              </Button>
            </>
          )}

          {product && product.status === BillingProductStatus.Active && (
            <>
              <Button
                onClick={() => onContactSales(SubscriptionChangeType.Upgrade)}
                className={classes.wideButton}
                color="secondary"
                size="small"
              >
                {t<string>('workspacePage.subscription.contactSalesUpgrade')}
              </Button>
              <Button
                onClick={() => onContactSales(SubscriptionChangeType.Cancellation)}
                variant="text"
                size="small"
              >
                {t<string>('workspacePage.subscription.cancelSubscription')}
              </Button>
            </>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default SubscriptionProduct;
