import { RequestStatus } from 'src/helpers/reduxReuquest.util';
import documentJoinConstants from '../constants/documentJoin.constants';

const initialState = {
  documentInvitationData: {},
  fetchAcceptationDocumentInvitationStatus: RequestStatus.IDLE,
  fetchDocumentInvitationStatus: RequestStatus.IDLE,
};

export default function documentJoinStore(state = initialState, action) {
  switch (action.type) {
    case documentJoinConstants.GET_DOCUMENT_INVITATION_REQUEST:
      return {
        ...state,
        fetchDocumentInvitationStatus: RequestStatus.PENDING,
      };
    case documentJoinConstants.GET_DOCUMENT_INVITATION_SUCCESS:
      return {
        ...state,
        documentInvitationData: action.data,
        fetchDocumentInvitationStatus: RequestStatus.SUCCESS,
      };
    case documentJoinConstants.GET_DOCUMENT_INVITATION_FAILURE:
      return {
        ...state,
        fetchDocumentInvitationStatus: RequestStatus.ERROR,
      };

    case documentJoinConstants.ACCEPT_DOCUMENT_INVITATION_REQUEST:
      return {
        ...state,
        fetchAcceptationDocumentInvitationStatus: RequestStatus.PENDING,
      };
    case documentJoinConstants.ACCEPT_DOCUMENT_INVITATION_SUCCESS:
      return {
        ...state,
        documentInvitationData: action.data,
        fetchAcceptationDocumentInvitationStatus: RequestStatus.SUCCESS,
      };
    case documentJoinConstants.ACCEPT_DOCUMENT_INVITATION_FAILURE:
      return {
        ...state,
        fetchAcceptationDocumentInvitationStatus: RequestStatus.ERROR,
      };

    case documentJoinConstants.CLEAR_DOCUMENT_INVITATION_STORE:
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
