import palette from '../palette';
import spacing from '../spacing';

export default {
  root: {},
  rangeCalendarContainer: {
    '&:not(:last-child)': {
      borderRight: `1px solid ${palette.divider}`,
    },
  },
  calendar: {
    minWidth: 280,
    minHeight: 240,
  },
  arrowSwitcher: {
    padding: spacing(2),
    backgroundColor: palette.background.default,
    borderBottom: `1px solid ${palette.divider}`,
  },
};
