import React, { useState } from 'react';
import { ListSubheader, TextField, Typography } from '@material-ui/core';
import { AutocompleteRenderGroupParams } from '@material-ui/lab/Autocomplete/Autocomplete';
import { UserManagerPerson } from 'src/models/documentUsersManagement.model';
import { useTranslation } from 'react-i18next';
import { isMail } from 'src/helpers/globalUtils';
import { Autocomplete, Skeleton } from '@material-ui/lab';
import { useAddNewItemStyles } from './styles';
import UserItem from './UserItem';

type filterOptionsType = {
  inputValue: string;
};

interface AddNewItemProps {
  isLoading: boolean;
  focused: boolean;
  onInputBlur: () => void;
  onInputChange: (e: React.ChangeEvent<HTMLElement | unknown>, value: string) => void;
  onAddClick: (value: UserManagerPerson) => void;
  items: UserManagerPerson[];
  addedItems?: UserManagerPerson[];
  canInviteByMail?: boolean;
}

const checkMailNotRepeatedInAdded = (items: UserManagerPerson[], mail: string) =>
  !items.some((e) => e.email === mail && (e.isInvited || e.isInvitationToBeSend));

const AddNewItem = ({
  focused,
  isLoading,
  onInputBlur,
  items,
  addedItems = [],
  onInputChange,
  onAddClick,
  canInviteByMail = true,
}: AddNewItemProps): JSX.Element => {
  const { t } = useTranslation();
  const classes = useAddNewItemStyles();
  const [inputValue, setInputValue] = useState<string>('');
  const skeletonAnimation = isLoading ? 'pulse' : false;
  const hasGroup = items.some((el) => el.isGroup);

  const renderGroup = (params: AutocompleteRenderGroupParams) => [
    <ListSubheader key={params.key} className={classes.groupHeader}>
      <Typography variant="subtitle2">
        {params.group === 'true' ? t<string>('common.groups') : t<string>('common.users')}
      </Typography>
    </ListSubheader>,
    params.children,
  ];

  const onAutocompleteChange = (
    event: React.ChangeEvent<HTMLSelectElement | unknown>,
    value: UserManagerPerson | string | null
  ) => {
    if (value && typeof value !== 'string') {
      onAddClick(value);
    } else if (
      canInviteByMail &&
      typeof value === 'string' &&
      isMail(value) &&
      checkMailNotRepeatedInAdded(addedItems, value)
    ) {
      onAddClick({
        isGroup: false,
        isInvitationToBeSend: true,
        isInvited: false,
        email: value,
      });
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLElement | unknown>, value: string) => {
    onInputChange(e, value);
    setInputValue(value);
  };

  const filterOptions = (
    options: UserManagerPerson[],
    state: filterOptionsType
  ): UserManagerPerson[] => {
    // const mailNotRepeatedInAdded = checkMailNotRepeatedInAdded(addedItems, state.inputValue); // TODO: replace with mailNotRepeatedInContactBook, mailNotRepeatedInAdded when new contact book available
    const mailNotRepeatedInContactBook = !options.some((e) => e.email === state.inputValue);
    const mailNotRepeatedInAdded = !addedItems.some((e) => e.email === state.inputValue);
    if (
      canInviteByMail &&
      typeof state.inputValue === 'string' &&
      isMail(state.inputValue) &&
      // mailNotRepeatedInAdded // TODO: replace with mailNotRepeatedInContactBook, mailNotRepeatedInAdded when new contact book available
      mailNotRepeatedInContactBook &&
      mailNotRepeatedInAdded
    ) {
      options = [
        {
          isGroup: false,
          isInvitationToBeSend: true,
          isInvited: false,
          email: state.inputValue,
        },
        ...options,
      ];
    }
    return options;
  };

  return (
    <div className={classes.wrapper}>
      <Skeleton animation={skeletonAnimation} variant="circle" height={40} width={40} />

      <div className={classes.textWrapper}>
        {focused ? (
          <Autocomplete
            open
            blurOnSelect
            freeSolo
            onChange={onAutocompleteChange}
            onBlur={onInputBlur}
            options={items}
            renderInput={(params) => (
              <TextField {...params} name="new_item_autocomplete" autoFocus />
            )}
            renderOption={(option) => (
              <UserItem
                userData={option}
                highlightString={inputValue}
                showTitleAdditionalTexts={false}
                hoverEnabled
              />
            )}
            getOptionLabel={(item) => item._name || item.email || ''}
            renderGroup={renderGroup}
            groupBy={hasGroup ? (option) => String(option.isGroup) : undefined}
            classes={{
              input: classes.input,
              inputRoot: classes.inputRoot,
              option: classes.autocompleteOption,
            }}
            onInputChange={onChange}
            filterOptions={filterOptions}
          />
        ) : (
          <Skeleton
            className={classes.inputSkeleton}
            animation={skeletonAnimation}
            variant="text"
            height={24}
          />
        )}
        <Skeleton animation={skeletonAnimation} variant="text" height={16} width={150} />
      </div>
    </div>
  );
};
export default AddNewItem;
