enum workspaceGroupsConstants {
  CREATE_NEW_WORKSPACE_GROUP_REQUEST = 'CREATE_NEW_WORKSPACE_GROUP_REQUEST',
  CREATE_NEW_WORKSPACE_GROUP_SUCCESS = 'CREATE_NEW_WORKSPACE_GROUP_SUCCESS',
  CREATE_NEW_WORKSPACE_GROUP_FAILURE = 'CREATE_NEW_WORKSPACE_GROUP_FAILURE',

  FETCH_WORKSPACE_GROUPS_SEARCH_REQUEST = 'FETCH_WORKSPACE_GROUPS_SEARCH_REQUEST',
  FETCH_WORKSPACE_GROUPS_SEARCH_SUCCESS = 'FETCH_WORKSPACE_GROUPS_SEARCH_SUCCESS',
  FETCH_WORKSPACE_GROUPS_SEARCH_FAILURE = 'FETCH_WORKSPACE_GROUPS_SEARCH_FAILURE',

  DELETE_WORKSPACE_GROUP_REQUEST = 'DELETE_WORKSPACE_GROUP_REQUEST',
  DELETE_WORKSPACE_GROUP_SUCCESS = 'DELETE_WORKSPACE_GROUP_SUCCESS',
  DELETE_WORKSPACE_GROUP_FAILURE = 'DELETE_WORKSPACE_GROUP_FAILURE',

  UPDATE_WORKSPACE_GROUP_REQUEST = 'UPDATE_WORKSPACE_GROUP_REQUEST',
  UPDATE_WORKSPACE_GROUP_SUCCESS = 'UPDATE_WORKSPACE_GROUP_SUCCESS',
  UPDATE_WORKSPACE_GROUP_FAILURE = 'UPDATE_WORKSPACE_GROUP_FAILURE',

  ADD_MEMBER_TO_WORKSPACE_GROUP_REQUEST = 'ADD_MEMBER_TO_WORKSPACE_GROUP_REQUEST',
  ADD_MEMBER_TO_WORKSPACE_GROUP_SUCCESS = 'ADD_MEMBER_TO_WORKSPACE_GROUP_SUCCESS',
  ADD_MEMBER_TO_WORKSPACE_GROUP_FAILURE = 'ADD_MEMBER_TO_WORKSPACE_GROUP_FAILURE',

  DELETE_MEMBER_FROM_WORKSPACE_GROUP_REQUEST = 'DELETE_MEMBER_FROM_WORKSPACE_GROUP_REQUEST',
  DELETE_MEMBER_FROM_WORKSPACE_GROUP_SUCCESS = 'DELETE_MEMBER_FROM_WORKSPACE_GROUP_SUCCESS',
  DELETE_MEMBER_FROM_WORKSPACE_GROUP_FAILURE = 'DELETE_MEMBER_FROM_WORKSPACE_GROUP_FAILURE',

  FETCH_WORKSPACE_GROUPS_REQUEST = 'FETCH_WORKSPACE_GROUPS_REQUEST',
  FETCH_WORKSPACE_GROUPS_SUCCESS = 'FETCH_WORKSPACE_GROUPS_SUCCESS',
  FETCH_WORKSPACE_GROUPS_FAILURE = 'FETCH_WORKSPACE_GROUPS_FAILURE',

  FETCH_WORKSPACE_GROUP_MEMBERS_REQUEST = 'FETCH_WORKSPACE_GROUP_MEMBERS_REQUEST',
  FETCH_WORKSPACE_GROUP_MEMBERS_SUCCESS = 'FETCH_WORKSPACE_GROUP_MEMBERS_SUCCESS',
  FETCH_WORKSPACE_GROUP_MEMBERS_FAILURE = 'FETCH_WORKSPACE_GROUP_MEMBERS_FAILURE',

  FETCH_WORKSPACE_GROUP_REQUEST = 'FETCH_WORKSPACE_GROUP_REQUEST',
  FETCH_WORKSPACE_GROUP_SUCCESS = 'FETCH_WORKSPACE_GROUP_SUCCESS',
  FETCH_WORKSPACE_GROUP_FAILURE = 'FETCH_WORKSPACE_GROUP_FAILURE',

  SET_ACTIVE_GROUP = 'SET_ACTIVE_GROUP',

  CLEAR_WORKSPACE_GROUPS_STATE = 'CLEAR_WORKSPACE_GROUPS_STATE',
}

export default workspaceGroupsConstants;
