import React from 'react';
import { FormControl, InputAdornment, TextField, Typography } from '@material-ui/core';
import { func, shape, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Folder } from '../CustomIcon';
import { useStyles } from './styles';

const ChooseLocationCertificateButton = ({ onOpenClick, currentValue }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <FormControl>
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Folder />
              </InputAdornment>
            ),
          }}
          fullWidth
          disabled
          className={classes.textField}
          value={currentValue?.name || 'Drive'}
          onClick={onOpenClick}
        />
      </FormControl>

      <Typography variant="body1" className={classes.changeLocationButton} onClick={onOpenClick}>
        {t('createCertificate.changeLocation')}
      </Typography>
    </>
  );
};

ChooseLocationCertificateButton.propTypes = {
  currentValue: shape({
    id: string,
    name: string,
  }),
  onOpenClick: func,
};

ChooseLocationCertificateButton.defaultProps = {
  currentValue: { name: 'Drive', id: '' },
};
export default ChooseLocationCertificateButton;
