import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useForm from 'src/helpers/hooks/useForm';
import validate from 'src/helpers/formValidationRules';
import { Button, TextField, Typography } from '@material-ui/core';
import { FullScreenDialog, SmallContentWithScrollbar } from 'src/components';

const RenameFolder = (props) => {
  const { open, onClose, onChange, currentFolderName, smallScreen } = props;
  const { t } = useTranslation();

  const handleUpdate = () => {
    onChange(values.folderName);
  };

  const { values, errors, handleChange, handleSubmit } = useForm(handleUpdate, validate, {
    folderName: currentFolderName,
  });

  return (
    <FullScreenDialog isOpen={open} onClose={onClose} withLogo>
      <SmallContentWithScrollbar
        startContent={
          <Typography variant={smallScreen ? 'h3' : 'h2'}>
            {t('drivePage.foldersDialog.rename.title')}
          </Typography>
        }
        endContent={
          <Button size="large" fullWidth type="submit">
            {t('common.changeName')}
          </Button>
        }
        hasForm
        formProps={{
          noValidate: true,
          autoComplete: 'off',
          onSubmit: handleSubmit,
        }}
      >
        <TextField
          name="folderName"
          fullWidth
          placeholder={t('drivePage.foldersDialog.rename.description')}
          autoFocus
          value={values.folderName || ''}
          onChange={handleChange}
          error={errors.folderName?.length > 0}
          helperText={errors.folderName}
        />
      </SmallContentWithScrollbar>
    </FullScreenDialog>
  );
};

RenameFolder.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onChange: PropTypes.func,
  currentFolderName: PropTypes.string,
  smallScreen: PropTypes.bool,
};

RenameFolder.defaultProps = {
  open: false,
  smallScreen: false,
};

export default RenameFolder;
