import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ breakpoints, spacing }) =>
    createStyles({
      root: {},
      smallMargin: {
        marginBottom: spacing(2),
      },
      mediumMargin: {
        marginBottom: spacing(2),
        [breakpoints.up('md')]: {
          marginBottom: spacing(2.5),
        },
        [breakpoints.up('lg')]: {
          marginBottom: spacing(3.5),
        },
      },
      largeMargin: {
        marginBottom: spacing(2),
        [breakpoints.up('md')]: {
          marginBottom: spacing(3),
        },
        [breakpoints.up('lg')]: {
          marginBottom: spacing(5),
        },
      },
    }),
  { index: 0 }
);
