import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { CircularProgress, LinearProgress } from '@material-ui/core';
import { ContentContainer } from 'src/components';
import DataTableHeader from './DataTableHeader';
import DataTableRow from './DataTableRow';
import { NoItems } from './subcomponents';
import { useStyles } from './styles';

const DataTable = ({
  rows,
  cols,
  withTableHeader,
  iconWidth,
  headerTextWidthLikeIcon,
  headerAction,
  title,
  titleRef,
  disableTitle,
  disableHeader,
  headerRef,
  onClick,
  onRowCheckboxClick,
  onDblClick,
  onLongPress,
  selectable,
  selectedRows,
  sortBy,
  sortOrder,
  onChangeSort,
  paginationType,
  totalItems,
  isLoading,
  isLoadingMore,
  onLoadMore,
  tileRows,
  smallScreen,
  defaultIcon,
  hasMoreItems,
  noItems,
  isDrag,
  onDrag,
  onDrop,
  dragMode,
  hoverable,
  classes: classesProp,
}) => {
  const loaderRef = useRef(null);
  const classes = useStyles();
  const [hover, setHover] = useState(-1);
  const handleObserver = (entities) => {
    const target = entities[0];
    if (target.isIntersecting && !isLoadingMore) {
      onLoadMore();
    }
  };

  const handleMouseEnter = (index) => {
    if (hoverable) setHover(index);
  };

  const handleMouseLeave = () => {
    if (hoverable) setHover(-1);
  };

  useEffect(() => {
    if (paginationType === 'infinite') {
      const options = {
        root: null,
        rootMargin: '20px',
        threshold: 1.0,
      };
      const observer = new IntersectionObserver(handleObserver, options);
      if (loaderRef.current) {
        observer.observe(loaderRef.current);
      }
    }
  });

  const getRow = (row, i) => {
    const isTile = tileRows;
    const rowClasses = [];
    const isSelected =
      selectable && selectedRows !== undefined && selectedRows.indexOf(row.id) !== -1;
    rowClasses.push(classes.clickableRow);

    if (isTile) {
      rowClasses.push(classes.itemTileRow);
    }
    if (isSelected) {
      rowClasses.push(classes.itemRowSelected, dragMode ? classes.draggingRow : null);
    }

    return (
      <DataTableRow
        key={`row-${row.id}`}
        isSelected={isSelected}
        i={i}
        cols={cols}
        row={row}
        rowClasses={rowClasses}
        iconWidth={iconWidth}
        smallScreen={smallScreen}
        tile={isTile}
        onLongPress={onLongPress || undefined}
        onClick={onClick || undefined}
        onRowCheckboxClick={onRowCheckboxClick || undefined}
        onDblClick={onDblClick || undefined}
        defaultIcon={defaultIcon}
        isLoading={isLoading}
        onDrag={onDrag}
        onDrop={onDrop}
        isDrag={isDrag}
        isHovered={hover === i}
        onMouseEnter={() => handleMouseEnter(i)}
      />
    );
  };

  const renderRows = () => rows.map((row, i) => getRow(row, i));

  const getInfiniteLoader = () => {
    if (totalItems <= rows.length || isLoading || !hasMoreItems) {
      return null;
    }
    return (
      <ContentContainer size="small" horizontal={false} vertical="top">
        <LinearProgress ref={loaderRef} />
      </ContentContainer>
    );
  };

  const renderNoItems = () => {
    const { label: noItemsLabel, buttonText: noItemsButtonText, onClick: noItemsOnClick } = noItems;

    return (
      <NoItems
        className={classes.noItems}
        label={noItemsLabel}
        buttonText={noItemsButtonText}
        onClick={noItemsOnClick}
      />
    );
  };

  return (
    <div className={clsx(classes.dataTableWrapper, classesProp && classesProp.dataTableWrapper)}>
      {!disableHeader && (
        <div
          className={clsx(
            classes.container,
            classes.headerWrapper,
            classesProp && classesProp.headerWrapper
          )}
        >
          <DataTableHeader
            ref={headerRef}
            cols={cols}
            withTableHeader={withTableHeader}
            headerAction={headerAction}
            iconWidth={iconWidth}
            headerTextWidthLikeIcon={headerTextWidthLikeIcon}
            title={title || <div />}
            titleRef={titleRef}
            disableTitle={disableTitle}
            sortBy={sortBy}
            sortOrder={sortOrder}
            onChangeSort={onChangeSort}
            isLoading={isLoading || isLoadingMore}
            smallScreen={smallScreen}
          />
        </div>
      )}
      <div
        className={clsx(
          classes.container,
          tileRows && classes.tilesContainer,
          classesProp && classesProp.tilesContainer
        )}
        onMouseLeave={handleMouseLeave}
      >
        {rows && (
          <>
            {renderRows()}

            {paginationType === 'infinite' && getInfiniteLoader()}

            {isLoading && rows.length === 0 && (
              <CircularProgress className={classes.circularLoader} />
            )}

            {!isLoading && rows.length === 0 && noItems && renderNoItems()}

            {/* TODO: Add classic pagination support */}
          </>
        )}
      </div>
    </div>
  );
};

DataTable.propTypes = {
  classes: PropTypes.shape({
    dataTableWrapper: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    itemRowSelected: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    draggingRow: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    itemTileRow: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    clickableRow: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    headerWrapper: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    container: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    tilesContainer: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    circularLoader: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    noItems: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
  rows: PropTypes.arrayOf(PropTypes.shape),
  cols: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      dataKey: PropTypes.string.isRequired,
      sortable: PropTypes.bool,
      type: PropTypes.oneOf(['icon', 'text', 'time-ago', 'person-badge', 'document-status']),
      icon: PropTypes.oneOf(['file', 'folder']),
      colProps: PropTypes.shape(),
    })
  ),
  withTableHeader: PropTypes.bool,
  headerAction: PropTypes.node,
  selectable: PropTypes.bool,
  selectedRows: PropTypes.arrayOf(PropTypes.string),
  onClick: PropTypes.func,
  onRowCheckboxClick: PropTypes.func,
  onDblClick: PropTypes.func,
  onLongPress: PropTypes.func,
  sortBy: PropTypes.string,
  sortOrder: PropTypes.oneOf(['asc', 'desc']),
  onChangeSort: PropTypes.func,
  isLoading: PropTypes.bool,
  isLoadingMore: PropTypes.bool,
  totalItems: PropTypes.number,
  paginationType: PropTypes.oneOf(['infinite', 'pagination']),
  onLoadMore: PropTypes.func,
  iconWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['auto'])]),
  headerTextWidthLikeIcon: PropTypes.bool,
  headerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  titleRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  disableTitle: PropTypes.bool,
  disableHeader: PropTypes.bool,
  tileRows: PropTypes.bool,
  smallScreen: PropTypes.bool,
  defaultIcon: PropTypes.string,
  hasMoreItems: PropTypes.bool,
  noItems: PropTypes.shape({
    label: PropTypes.string,
    buttonText: PropTypes.string,
    onClick: PropTypes.func,
  }),
  isDrag: PropTypes.bool,
  onDrag: PropTypes.func,
  onDrop: PropTypes.func,
  dragMode: PropTypes.bool,
  hoverable: PropTypes.bool,
};

DataTable.defaultProps = {
  selectable: false,
  hoverable: false,
  paginationType: 'pagination',
  withTableHeader: false,
  disableHeader: false,
  disableTitle: false,
  tileRows: false,
};

export default DataTable;
