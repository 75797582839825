import { makeStyles, createStyles } from '@material-ui/core';

// eslint-disable-next-line import/prefer-default-export
export const useStyles = makeStyles(
  ({ palette }) =>
    createStyles({
      root: {},
      bars: {
        display: 'flex',
        '& $bar': {
          '&:not(:last-child)': {
            marginRight: 4,
          },
        },
      },
      bar: {
        width: 96,
        height: 2,
        backgroundColor: palette.bottomLineColor,
      },
      activeBar: {},
      easyBar: {
        '&$activeBar': {
          backgroundColor: palette.error.main,
        },
      },
      mediumBar: {
        '&$activeBar': {
          backgroundColor: palette.warning.light,
        },
      },
      strongBar: {
        '&$activeBar': {
          backgroundColor: palette.success.main,
        },
      },
      noneStrengthText: {},
      easyStrengthText: {
        color: palette.error.main,
      },
      mediumStrengthText: {
        color: palette.warning.light,
      },
      strongStrengthText: {
        color: palette.success.main,
      },
    }),
  { index: 0 }
);
