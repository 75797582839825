import { makeStyles, createStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ breakpoints }) =>
    createStyles({
      filesWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
      },
      buttonWrapper: {
        maxWidth: 380,
        display: 'flex',
        margin: 'auto',
        marginTop: 40,
      },
      actionButtonsWrapper: {
        display: 'flex',
        marginBottom: 24,
      },
      actionButton: {
        marginRight: 16,
      },
      buttonText: {
        '& .MuiButton-startIcon': {
          color: 'inherit !important',
        },
      },
      actionButtonRight: {
        marginLeft: 'auto',
        '& .MuiButton-endIcon': {
          color: 'inherit !important',
        },
      },
      fileInput: {
        display: 'none',
      },
      tableWrapper: {
        padding: 30,
        [breakpoints.down('md')]: {
          padding: 6,
        },
      },
      th: {
        whiteSpace: 'nowrap',
        minWidth: 150,
      },
      cellSelect: {
        width: 50,
      },
      loader: {
        display: 'flex',
        padding: 50,
        justifyContent: 'center',
      },
    }),
  { index: 0 }
);
