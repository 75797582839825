import palette from '../palette';

export default {
  root: {
    position: 'relative',
  },
  outlined: {
    border: `1px solid ${palette.divider}`,
  },
};
