import React, { useCallback, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FullScreenDialog, SmallContentWithScrollbar } from 'src/components/index';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'src/redux/storeTs';
import { RootState } from 'src/redux/reducers';
import DocumentUserManagerSelector from 'src/redux/selectors/documentUserManager.selector';
import documentUserManagerActions from 'src/redux/actions/documentUserManager.actions';
import { IdentitySelector } from 'src/redux/selectors/identity.selector';
import {
  DocumentMember,
  DocumentMemberRole,
  UserManagerPerson,
} from 'src/models/documentUsersManagement.model';
import { API_MODULE } from 'src/models/common.model';
import { RequestStatus } from 'src/helpers/reduxReuquest.util';
import usePrevious from 'src/helpers/hooks/usePrevious';
import UsersManagerSigners from './sections/UsersManagerSigners';
import UsersManagerHolder from './sections/UsersManagerHolder';
import UsersManagerOtherRoles from './sections/UsersManagerOtherRoles';
import UsersManagerAdvancedSettings from './sections/UsersManagerAdvancedSettings';
import { UsersManagerContext, UsersManagerContextType } from './subcomponents/UsersManagerContext';
import certificateManagementActions from '../../redux/actions/certificateManagement.actions';
import documentInvitationsTsActions from '../../redux/actions/documentInvitationsTs.actions';
import { documentInvitationsSelector } from '../../redux/selectors/documentInvitations.selector';
import certificateCreatorActions from '../../redux/actions/certificateCreator.actions';
import documentInvitationActions from '../../redux/actions/documentInvitiation.actions';
import MultipleInvitationWarningBar from './subcomponents/MultipleInvitationWarningBar';
import { TemplateDocumentType } from '../../models/documents.model';

const containsAll = (arr1: (string | undefined)[], arr2: (string | undefined)[]) =>
  arr2.every((arr2Item) => arr1.includes(arr2Item));

const sameMembers = (arr1: (string | undefined)[], arr2: (string | undefined)[]) =>
  containsAll(arr1, arr2) && containsAll(arr2, arr1);

interface DocumentUsersManagerProps {
  documentIds: string[];
  showHolders?: boolean;
  onClose: () => void;
}

const DocumentUsersManager = ({
  documentIds,
  onClose,
  showHolders = true,
}: DocumentUsersManagerProps): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const workspaceId = useSelector(IdentitySelector.selectWorkspaceId, undefined);
  const holders = useSelector(DocumentUserManagerSelector.selectHoldersWithDetails, undefined);
  const { canSetTransferabilityCert } = useSelector(
    DocumentUserManagerSelector.selectDocumentAction,
    undefined
  );
  const signers = useSelector(
    DocumentUserManagerSelector.selectSignersInOrderWithDetails,
    undefined
  );
  const otherRoles = useSelector(
    DocumentUserManagerSelector.selectOtherRolesWithDetails,
    undefined
  );
  const {
    documentMembers,
    documentMembersStatus,
    externalEntitiesStatus,
    invitedDocumentMembersStatus,
    documentSigners: { isOrderSignificant, requiredSignersCount },
  } = useSelector((state: RootState) => state.documentUsersManagerStore, undefined);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const documentInfo = useSelector((state: any) => state.documentDisplayStore.document, undefined);

  const {
    isDraftTransferable,
    isDraftPublicAccess,
    isDraftIsOrderSignificant,
    draftHolders,
    draftSigners,
    draftOtherRoles,
    updateDraftHolders,
    updateDraftSigners,
    updateDraftOtherRoles,
  } = useContext(UsersManagerContext) as UsersManagerContextType;

  const prevState = usePrevious({
    documentMembersStatus,
    externalEntitiesStatus,
    invitedDocumentMembersStatus,
  });

  const isCertificate = history.location.pathname.includes('certificate');
  const apiModule = isCertificate ? API_MODULE.DocumentDslService : API_MODULE.DocumentService;

  const isAdvancedSettingsPending = useSelector(
    documentInvitationsSelector.selectIsAdvancedSettingsPending,
    undefined
  );

  const isButtonDisabled = isAdvancedSettingsPending;
  const onModalCloseClick = () => {
    history.replace({
      search: '',
    });
    onClose();
  };

  const showSignersSection = documentInfo.documentType !== TemplateDocumentType.DURABLE_MEDIA;
  // on submit
  const onSubmit = () => {
    dispatch(
      documentUserManagerActions.updateDraftRoles({
        draftSigners,
        draftOtherRoles,
        draftHolders,
        draftTransferability: isDraftTransferable,
      })
    );
    submitSigners();
    submitOtherRoles();

    if (isCertificate) {
      submitHolders();
      submitAdvancedSettings();
    }
    onClose();
  };

  // submit signers section
  const submitSigners = useCallback(() => {
    const signersIds = signers.map((el) => el._id).filter((el) => el);
    const draftSignersIds = draftSigners.map((el) => el._id).filter((el) => el);
    const hasSameOrder = signersIds.join(',') === draftSignersIds.join(',');
    const hasSameSigners = sameMembers(signersIds, draftSignersIds);
    // update signers only if
    // - order is important and order has changed or
    // - signers changed or
    // - isOrderSignificant changed
    // - requiredSignersCount changed
    if (
      (isDraftIsOrderSignificant && !hasSameOrder) ||
      !hasSameSigners ||
      isDraftIsOrderSignificant !== isOrderSignificant ||
      (requiredSignersCount && requiredSignersCount !== draftSigners.length)
    ) {
      updateSigners();
      dispatch(documentUserManagerActions.updateSignersChanged(true));
    }
    // invite new signers by mail
    if (!isDraftIsOrderSignificant) inviteNewRolesByMail(draftSigners);

    // delete removed signers invitations
    deleteUnusedInvitations(signers, draftSigners);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signers, draftSigners, isDraftIsOrderSignificant, isOrderSignificant]);

  // submit other roles section
  const submitOtherRoles = () => {
    deleteUnusedInvitations(otherRoles, draftOtherRoles);
    inviteNewRolesByMail(draftOtherRoles);
    updateOtherRoles();
  };

  // update roles for contact book
  const updateOtherRoles = () => {
    const adminsChanged = rolesChanged(DocumentMemberRole.ADMIN);
    const auditorsChanged = rolesChanged(DocumentMemberRole.AUDITOR);
    const editorsChanged = rolesChanged(DocumentMemberRole.EDITOR);
    const viewersChanged = rolesChanged(DocumentMemberRole.VIEWER);

    // check if any role has changed
    if (adminsChanged || auditorsChanged || editorsChanged || viewersChanged) {
      const admins = draftOtherRoles
        .filter((el) => el.role === DocumentMemberRole.ADMIN && el._id)
        .map((el) => el._id || '');
      const auditors = draftOtherRoles
        .filter((el) => el.role === DocumentMemberRole.AUDITOR && el._id)
        .map((el) => el._id || '');
      const editors = draftOtherRoles
        .filter((el) => el.role === DocumentMemberRole.EDITOR && el._id)
        .map((el) => el._id || '');
      const viewers = draftOtherRoles
        .filter((el) => el.role === DocumentMemberRole.VIEWER && el._id)
        .map((el) => el._id || '');

      dispatch(documentUserManagerActions.updateRecipientsChanged(true));
      if (isCertificate && workspaceId) {
        dispatch(
          documentInvitationsTsActions.updateCertificateRoles({
            workspaceId,
            documentIds,
            admins,
            auditors,
            editors,
            viewers,
          })
        );
      } else {
        dispatch(
          documentInvitationsTsActions.updateDocumentRoles({
            documentId: documentIds[0],
            admins: adminsChanged ? admins : undefined,
            auditors: auditorsChanged ? auditors : undefined,
            editors: editorsChanged ? editors : undefined,
            viewers: viewersChanged ? viewers : undefined,
          })
        );
      }
    }
  };

  // compare ids for selected role
  const rolesChanged = (role: DocumentMemberRole): boolean => {
    const ids = otherRoles
      .filter((el) => el.role === role && el._id)
      .map((el) => el._id)
      .filter((el) => el);
    const draftIds = draftOtherRoles
      .filter((el) => el.role === role && el._id)
      .map((el) => el._id)
      .filter((el) => el);

    return !sameMembers(ids, draftIds);
  };

  // submit holders section. Invite by mail or remove invitation
  const submitHolders = () => {
    const holdersIds = holders
      // invite only newly added mails
      .filter((el) => el.isInvitationToBeSend)
      .map((el) => el._id || el.email)
      .filter((el) => el);
    const draftHoldersIds = draftHolders
      // invite only newly added mails
      .filter((el) => el.isInvitationToBeSend)
      .map((el) => el._id || el.email)
      .filter((el) => el);

    const isSameHolders = sameMembers(holdersIds, draftHoldersIds);
    if (draftHoldersIds.length > 0) {
      if (!isSameHolders) {
        dispatch(documentUserManagerActions.updateRecipientsChanged(true));
        dispatch(
          documentInvitationsTsActions.inviteRolesByMail({
            documentIds,
            users: draftHolders,
            service: apiModule,
          })
        );
      }
    } else {
      deleteUnusedInvitations(holders, draftHolders);
    }
  };

  // update advanced settings from certificate. Check if changed then update
  const submitAdvancedSettings = () => {
    const transferable = documentInfo?.additionalAttributes?.transferable;
    const publicAccess = documentInfo?.publicFile;

    if (
      canSetTransferabilityCert &&
      transferable !== undefined &&
      transferable !== isDraftTransferable
    ) {
      documentIds.forEach((dId) => {
        dispatch(
          certificateManagementActions.setCertificateTransferability(
            dId,
            workspaceId,
            isDraftTransferable
          )
        );
      });
    }

    if (publicAccess !== undefined && publicAccess !== isDraftPublicAccess) {
      documentIds.forEach((dId) => {
        dispatch(certificateManagementActions.setCertificateAccessLevel(dId, isDraftPublicAccess));
      });
    }
  };

  // Invite new roles by mail
  const inviteNewRolesByMail = (users: UserManagerPerson[]) => {
    const newUsersToInvite = users.filter((el) => el.isInvitationToBeSend);
    if (newUsersToInvite.length > 0) {
      if (newUsersToInvite.filter((el) => el.role === DocumentMemberRole.SIGNER).length > 0)
        dispatch(documentUserManagerActions.updateSignersChanged(true));
      if (newUsersToInvite.filter((el) => el.role !== DocumentMemberRole.SIGNER).length > 0)
        dispatch(documentUserManagerActions.updateRecipientsChanged(true));
      dispatch(
        documentInvitationsTsActions.inviteRolesByMail({
          documentIds,
          users: newUsersToInvite,
          service: apiModule,
        })
      );
    }
  };

  // update signers from contact book
  const updateSigners = () => {
    const signers = draftSigners.map((signer) => signer._id).filter((el) => el);
    if (apiModule === API_MODULE.DocumentDslService) {
      dispatch(
        certificateCreatorActions.updateCertificateSigners({
          workspaceId,
          documentIds,
          signers, // Pass id's only
          isOrderSignificant: isDraftIsOrderSignificant,
        })
      );
    } else {
      dispatch(
        documentInvitationActions.replaceDocumentSigners(
          documentIds[0],
          isDraftIsOrderSignificant,
          draftSigners.length,
          signers
        )
      );
    }
  };

  // check which invitation has been deleted and remove them
  const deleteUnusedInvitations = (users: UserManagerPerson[], draftUsers: UserManagerPerson[]) => {
    const invited = users.filter((el) => el.isInvited);
    const draftInvited = draftUsers.filter((el) => el.isInvited);
    const removedInvited = invited.filter(
      (el) => !draftInvited.map((el) => el.email).includes(el.email)
    );
    if (removedInvited.length > 0) {
      if (removedInvited.filter((el) => el.role === DocumentMemberRole.SIGNER).length > 0)
        dispatch(documentUserManagerActions.updateSignersChanged(true));
      if (removedInvited.filter((el) => el.role !== DocumentMemberRole.SIGNER).length > 0)
        dispatch(documentUserManagerActions.updateRecipientsChanged(true));

      dispatch(
        documentInvitationsTsActions.deleteRolesByMail({
          documentIds,
          users: removedInvited,
          service: apiModule,
        })
      );
    }
  };

  // set draft / prepared data when data is fetched
  useEffect(() => {
    if (
      (prevState?.documentMembersStatus === RequestStatus.PENDING &&
        documentMembersStatus === RequestStatus.IDLE) ||
      (prevState?.externalEntitiesStatus === RequestStatus.PENDING &&
        externalEntitiesStatus === RequestStatus.IDLE) ||
      (prevState?.invitedDocumentMembersStatus === RequestStatus.PENDING &&
        invitedDocumentMembersStatus === RequestStatus.IDLE)
    ) {
      updateDraftHolders(holders);
      updateDraftSigners(signers);
      updateDraftOtherRoles(otherRoles.sort((a, b) => Number(a.isGroup) - Number(b.isGroup))); // sort, group at the end of the list
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalEntitiesStatus, documentMembersStatus, invitedDocumentMembersStatus, prevState]);

  // fetch entities from external workspaces
  useEffect(() => {
    if (documentMembers.length > 0 && workspaceId) {
      dispatch(
        documentUserManagerActions.getDocumentExternalWorkspaceEntities(
          workspaceId,
          documentMembers.map((member: DocumentMember) => member.id)
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentMembers]);

  // fetch initial data
  useEffect(() => {
    if (documentIds.length > 0) {
      dispatch(documentUserManagerActions.getDocumentMembers(documentIds[0], apiModule));
      dispatch(documentUserManagerActions.getDocumentSigners(documentIds[0], apiModule));
      dispatch(documentUserManagerActions.getDocumentInvitations(documentIds[0], apiModule));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // fetch initial autocomplete searching items and available actions
  useEffect(() => {
    if (workspaceId) {
      dispatch(documentUserManagerActions.getEntitiesByName(workspaceId, ''));
      dispatch(
        documentUserManagerActions.updateDraftRoles({
          draftOtherRoles: [],
          draftSigners: [],
          draftHolders: [],
          draftTransferability: null,
        })
      );
      dispatch(
        documentUserManagerActions.getAvailableDocumentUserActions(documentIds[0], apiModule)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceId]);

  // clear user manager state on unmount
  useEffect(
    (): ReturnType<React.EffectCallback> => (): AppDispatch =>
      dispatch(documentUserManagerActions.clear()),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  return (
    <FullScreenDialog isOpen onClose={onModalCloseClick} backgroundVariant="grey" withLogo>
      <SmallContentWithScrollbar
        scrollableContent
        size="big"
        endContent={
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              style={{ maxWidth: 580 }}
              disabled={isButtonDisabled}
              size="large"
              fullWidth
              onClick={onSubmit}
            >
              {t<string>('inviteToDocument.apply')}
            </Button>
          </div>
        }
      >
        <MultipleInvitationWarningBar />
        {isCertificate && showHolders && <UsersManagerHolder documentId={documentIds[0]} />}
        {showSignersSection && <UsersManagerSigners />}
        <UsersManagerOtherRoles />
        {isCertificate && <UsersManagerAdvancedSettings />}
      </SmallContentWithScrollbar>
    </FullScreenDialog>
  );
};

export default DocumentUsersManager;
