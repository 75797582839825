enum documentDownloadConstants {
  DOWNLOAD_DOCUMENT_REQUEST = 'DOWNLOAD_DOCUMENT_REQUEST',
  DOWNLOAD_DOCUMENT_SUCCESS = 'DOWNLOAD_DOCUMENT_SUCCESS',
  DOWNLOAD_DOCUMENT_FAILURE = 'DOWNLOAD_DOCUMENT_FAILURE',

  DOWNLOAD_DOCUMENT_FILE_REQUEST = 'DOWNLOAD_DOCUMENT_FILE_REQUEST',
  DOWNLOAD_DOCUMENT_FILE_SUCCESS = 'DOWNLOAD_DOCUMENT_FILE_SUCCESS',
  DOWNLOAD_DOCUMENT_FILE_FAILURE = 'DOWNLOAD_DOCUMENT_FILE_FAILURE',

  WS_DOWNLOAD_DOCUMENT = 'WP_DOWNLOAD_DOCUMENT',
}

export default documentDownloadConstants;
