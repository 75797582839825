import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  () =>
    createStyles({
      menuItemAll: {
        width: 250,
      },
      itemsWrapper: {
        maxHeight: 200,
        overflowY: 'scroll',
      },
      menuFooter: {
        width: '100%',
        display: 'flex',
        // position: 'absolute',
        alignItems: 'center',
        padding: 8,
        justifyContent: 'space-between',
      },
    }),
  { index: 0 }
);
