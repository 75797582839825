enum contactsConstants {
  CONTACTS_FETCH_ENTITIES_REQUEST = 'CONTACTS_FETCH_ENTITIES_REQUEST',
  CONTACTS_FETCH_ENTITIES_SUCCESS = 'CONTACTS_FETCH_ENTITIES_SUCCESS',
  CONTACTS_FETCH_ENTITIES_FAILURE = 'CONTACTS_FETCH_ENTITIES_FAILURE',

  INITIALIZE_CONTACTS = 'INITIALIZE_CONTACTS',
  CONTACTS_ADD_TO_QUEUE = 'CONTACTS_ADD_TO_QUEUE',
}

export default contactsConstants;
