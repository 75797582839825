import React from 'react';
import { Chip, Typography } from '@material-ui/core';
import {
  CalendarAdd as CalendarAddIcon,
  CalendarEdit as CalendarEditIcon,
  Folder as FolderIcon,
  Users as UsersIcon,
} from 'src/components/CustomIcon';
import clsx from 'clsx';

import { FileCopyOutlined, InfoOutlined } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';
import {
  AdvancedSearchFields,
  DocumentType,
  ExtraDocumentType,
} from '../../models/documents.model';
import { RootState } from '../../redux/reducers';
import { advancedSearchInitialState } from '../../redux/reducers/documents.reducer';
import { DateDropdownType } from '../DateDropdown/DateDropdown';
import { DateTimeFormats, FormatDate } from '../../helpers/dateTimeFormats';
import documentsActions from '../../redux/actions/documents.actions';

type FilterChipProps = {
  variant: AdvancedSearchFields;
};
const FilterChip = ({ variant }: FilterChipProps): JSX.Element => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { advancedSearch } = useSelector((state: RootState) => state.documentsStore);

  const isActive =
    JSON.stringify(advancedSearch[variant]) !== JSON.stringify(advancedSearchInitialState[variant]);

  const properties = {
    [AdvancedSearchFields.TYPE]: {
      label: t<string>('advancedSearch.fields.documentType.label'),
      icon: <FileCopyOutlined className={classes.chipIcon} />,
    },
    [AdvancedSearchFields.NAME]: {
      label: t<string>('advancedSearch.fields.name.label'),
      icon: <div />,
    },
    [AdvancedSearchFields.STATUS]: {
      label: t<string>('advancedSearch.fields.status.label'),
      icon: <InfoOutlined className={classes.chipIcon} style={{ fontSize: 20 }} />,
    },
    [AdvancedSearchFields.DATE_CREATED]: {
      label: t<string>('advancedSearch.fields.dateCreated.label'),
      icon: <CalendarAddIcon className={classes.chipIcon} />,
    },
    [AdvancedSearchFields.DATE_MODIFIED]: {
      label: t<string>('advancedSearch.fields.dateModified.label'),
      icon: <CalendarEditIcon className={classes.chipIcon} />,
    },
    [AdvancedSearchFields.RECIPIENTS]: {
      label: t<string>('advancedSearch.fields.recipients.label'),
      icon: <UsersIcon className={classes.chipIcon} />,
    },
    [AdvancedSearchFields.LOCATION]: {
      label: t<string>('advancedSearch.fields.location.label'),
      icon: <FolderIcon className={classes.chipIcon} />,
    },
  };

  const activeText = (): string | JSX.Element => {
    switch (variant) {
      case AdvancedSearchFields.TYPE: {
        const count = advancedSearch[variant].length - 1;
        const countText = count > 0 ? ` +${count}` : '';
        const [firstEl] = advancedSearch[variant];
        if (firstEl === ExtraDocumentType.FOLDER) {
          return t<string>('common.folders') + countText;
        }
        if (firstEl === DocumentType.DSL) {
          return t<string>('common.certificates') + countText;
        }
        if (firstEl === DocumentType.DRIVE) {
          return t<string>('common.drive') + countText;
        }
        return t<string>('common.esign') + countText;
      }

      case AdvancedSearchFields.STATUS: {
        const count = advancedSearch[variant].length - 1;
        const countText = count > 0 ? ` +${count}` : '';
        const [status] = advancedSearch[variant];
        return t<string>(`statuses.${status}.short`) + countText;
      }
      case AdvancedSearchFields.RECIPIENTS: {
        const count = advancedSearch[variant].length - 1;
        const countText = count > 0 ? ` +${count}` : '';
        const [firstRecipient] = advancedSearch[variant];
        return (firstRecipient._name || firstRecipient.email) + countText;
      }

      case AdvancedSearchFields.LOCATION: {
        const location = advancedSearch[variant];
        if (location === null) return '';

        return location.namePath;
      }
      case AdvancedSearchFields.DATE_CREATED:
      case AdvancedSearchFields.DATE_MODIFIED: {
        const { type, from, to } = advancedSearch[variant];
        switch (type) {
          case DateDropdownType.ANY:
            return t<string>('advancedSearch.dateOptions.anyTime');
          case DateDropdownType.TODAY:
            return t<string>('advancedSearch.dateOptions.today');
          case DateDropdownType.YESTERDAY:
            return t<string>('advancedSearch.dateOptions.yesterday');
          case DateDropdownType.LAST_7_DAYS:
            return t<string>('advancedSearch.dateOptions.last7Days');
          case DateDropdownType.LAST_30_DAYS:
            return t<string>('advancedSearch.dateOptions.last30Days');

          default:
            return (
              <>
                <FormatDate date={from.toDate()} dateFormat={DateTimeFormats.dateShort} />
                {` - `}
                <FormatDate date={to.toDate()} dateFormat={DateTimeFormats.dateShort} />
              </>
            );
        }
      }

      case AdvancedSearchFields.NAME:
      default:
        return '';
    }
  };

  const onReset = (): void => {
    dispatch(documentsActions.updateAdvancedSearch(variant, advancedSearchInitialState[variant]));
    dispatch(
      documentsActions.updateDraftAdvancedSearch(variant, advancedSearchInitialState[variant])
    );
  };

  return (
    <Chip
      clickable
      className={clsx(classes.chip, isActive && classes.chipActive)}
      variant="outlined"
      icon={properties[variant].icon}
      onDelete={
        isActive
          ? (e) => {
              e.stopPropagation();
              onReset();
            }
          : undefined
      }
      label={
        <Typography color="textPrimary" variant="body2">
          {isActive ? activeText() : properties[variant].label}
          {!isActive && <div className={classes.dropdownIcon} />}
        </Typography>
      }
    />
  );
};

export default FilterChip;
