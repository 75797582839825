import { RequestStatus } from 'src/helpers/reduxReuquest.util';
import certificateManagementConstants from 'src/redux/constants/certificateManagement.constants';
import documentInvitationsConstants from '../constants/documentInvitiation.constants';

const initialState = {
  deleteUsersByMailStatus: RequestStatus.IDLE,
  documentRoles: [],
  documentRoleTypes: [],
  fetchDocumentRoleTypesStatus: RequestStatus.IDLE,
  documentSigners: [],
  fetchDocumentRolesStatus: RequestStatus.IDLE,
  fetchDocumentSignersStatus: RequestStatus.IDLE,
  fetchWorkspaceEntitiesStatus: RequestStatus.IDLE,
  inviteUsersByMailStatus: RequestStatus.IDLE,
  isOrderSignificant: false,
  replaceDocumentRolesStatus: RequestStatus.IDLE,
  replaceDocumentSignersStatus: RequestStatus.IDLE,
  documentTransferabilityChangeStatus: RequestStatus.IDLE,
  documentPublicAccessChangeStatus: RequestStatus.IDLE,
  requiredSignersCount: 0,
  workspaceEntities: [],
};

function documentInvitationsStore(state = initialState, action) {
  switch (action.type) {
    case documentInvitationsConstants.FETCH_DOCUMENT_SIGNERS_REQUEST:
      return {
        ...state,
        fetchDocumentSignersStatus: RequestStatus.PENDING,
      };
    case documentInvitationsConstants.FETCH_DOCUMENT_SIGNERS_SUCCESS: {
      const { documentSigners, isOrderSignificant, requiredSignersCount } = action.payload;
      return {
        ...state,
        documentSigners,
        isOrderSignificant,
        requiredSignersCount,
        fetchDocumentSignersStatus: RequestStatus.SUCCESS,
      };
    }
    case documentInvitationsConstants.FETCH_DOCUMENT_SIGNERS_ERROR:
      return {
        ...state,
        fetchDocumentSignersStatus: RequestStatus.ERROR,
      };

    case documentInvitationsConstants.REPLACE_DOCUMENT_SIGNERS_REQUEST:
      return {
        ...state,
        replaceDocumentSignersStatus: RequestStatus.PENDING,
      };
    case documentInvitationsConstants.REPLACE_DOCUMENT_SIGNERS_SUCCESS:
      return {
        ...state,
        replaceDocumentSignersStatus: RequestStatus.SUCCESS,
      };
    case documentInvitationsConstants.REPLACE_DOCUMENT_SIGNERS_ERROR:
      return {
        ...state,
        replaceDocumentSignersStatus: RequestStatus.ERROR,
      };

    case documentInvitationsConstants.FETCH_DOCUMENT_ROLES_REQUEST:
      return {
        ...state,
        fetchDocumentRolesStatus: RequestStatus.PENDING,
      };
    case documentInvitationsConstants.FETCH_DOCUMENT_ROLES_SUCCESS:
      return {
        ...state,
        fetchDocumentRolesStatus: RequestStatus.SUCCESS,
        documentRoles: action.documentRoles,
      };
    case documentInvitationsConstants.FETCH_DOCUMENT_ROLES_ERROR:
      return {
        ...state,
        fetchDocumentRolesStatus: RequestStatus.ERROR,
      };

    case documentInvitationsConstants.FETCH_DOCUMENT_ROLES_TYPES_REQUEST:
      return {
        ...state,
        fetchDocumentRoleTypesStatus: RequestStatus.PENDING,
      };
    case documentInvitationsConstants.FETCH_DOCUMENT_ROLES_TYPES_SUCCESS:
      return {
        ...state,
        fetchDocumentRoleTypesStatus: RequestStatus.SUCCESS,
        documentRoleTypes: action.payload,
      };
    case documentInvitationsConstants.FETCH_DOCUMENT_ROLES_TYPES_ERROR:
      return {
        ...state,
        fetchDocumentRoleTypesStatus: RequestStatus.ERROR,
      };

    case documentInvitationsConstants.INVITE_USERS_BY_MAIL_REQUEST:
      return {
        ...state,
        inviteUsersByMailStatus: RequestStatus.PENDING,
      };
    case documentInvitationsConstants.INVITE_USERS_BY_MAIL_SUCCESS:
      return {
        ...state,
        inviteUsersByMailStatus: RequestStatus.SUCCESS,
      };
    case documentInvitationsConstants.INVITE_USERS_BY_MAIL_ERROR:
      return {
        ...state,
        inviteUsersByMailStatus: RequestStatus.ERROR,
      };

    case documentInvitationsConstants.DELETE_USERS_MAIL_INVITATION_REQUEST:
      return {
        ...state,
        deleteUsersByMailStatus: RequestStatus.PENDING,
      };
    case documentInvitationsConstants.DELETE_USERS_MAIL_INVITATION_SUCCESS:
      return {
        ...state,
        deleteUsersByMailStatus: RequestStatus.SUCCESS,
      };
    case documentInvitationsConstants.DELETE_USERS_MAIL_INVITATION_ERROR:
      return {
        ...state,
        deleteUsersByMailStatus: RequestStatus.ERROR,
      };

    case documentInvitationsConstants.GET_WORKSPACE_ENTITIES_REQUEST:
      return {
        ...state,
        fetchWorkspaceEntitiesStatus: RequestStatus.PENDING,
      };
    case documentInvitationsConstants.GET_WORKSPACE_ENTITIES_SUCCESS:
      return {
        ...state,
        workspaceEntities: action.data,
        fetchWorkspaceEntitiesStatus: RequestStatus.SUCCESS,
      };
    case documentInvitationsConstants.GET_WORKSPACE_ENTITIES_ERROR:
      return {
        ...state,
        fetchWorkspaceEntitiesStatus: RequestStatus.ERROR,
      };
    case documentInvitationsConstants.CLEAR_DOCUMENT_INVITATIONS_STORE:
      return {
        ...initialState,
      };

    case certificateManagementConstants.DOCUMENT_SET_TRANSFERABILITY_REQUEST:
      return {
        ...state,
        documentTransferabilityChangeStatus: RequestStatus.PENDING,
      };
    case certificateManagementConstants.DOCUMENT_SET_TRANSFERABILITY_SUCCESS:
      return {
        ...state,
        documentTransferabilityChangeStatus: RequestStatus.SUCCESS,
      };
    case certificateManagementConstants.DOCUMENT_SET_TRANSFERABILITY_ERROR:
      return {
        ...state,
        documentTransferabilityChangeStatus: RequestStatus.ERROR,
      };

    case certificateManagementConstants.DOCUMENT_SET_ACCESS_LEVEL_REQUEST:
      return {
        ...state,
        documentPublicAccessChangeStatus: RequestStatus.PENDING,
      };
    case certificateManagementConstants.DOCUMENT_SET_ACCESS_LEVEL_SUCCESS:
      return {
        ...state,
        documentPublicAccessChangeStatus: RequestStatus.SUCCESS,
      };
    case certificateManagementConstants.DOCUMENT_SET_ACCESS_LEVEL_ERROR:
      return {
        ...state,
        documentPublicAccessChangeStatus: RequestStatus.ERROR,
      };

    default:
      return state;
  }
}

export default documentInvitationsStore;
