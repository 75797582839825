import i18n from 'src/helpers/i18n';
import { AppDispatch } from 'src/redux/storeTs';
import { API_MODULE, SNACKBAR_VARIANT } from 'src/models/common.model';
import { documentUsersManagerConstants } from 'src/redux/constants/documentUsersManager.constants';
import documentInvitationsService from 'src/redux/services/documentInvitations.service';
import {
  DocumentMemberRole,
  DocumentUserAction,
  UserManagerPerson,
} from 'src/models/documentUsersManagement.model';
import documentCoreService from '../services/documentCore.service';
import snackbarActions from './snackbar.actions';
import identityService from '../services/identity.service';
import contactsService from '../services/contacts.service';
import { ExternalContactGroup, ExternalContactIdentity } from '../../models/contacts.model';
import documentDSLServices from '../services/documentDSL.service';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const getDocumentMembers = (documentId: string, service: API_MODULE) => (dispatch: AppDispatch) => {
  dispatch({ type: documentUsersManagerConstants.FETCH_DOCUMENT_MEMBERS_REQUEST });
  documentCoreService
    .getDocumentMembers(documentId, service)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .then((response: any) => {
      dispatch({
        type: documentUsersManagerConstants.FETCH_DOCUMENT_MEMBERS_SUCCESS,
        payload: response.data.collection ? response.data.collection : response.data,
      });
    })
    .catch((error) => {
      dispatch({ type: documentUsersManagerConstants.FETCH_DOCUMENT_MEMBERS_FAILURE });
      dispatch(
        snackbarActions.enqueueSnackbar(
          SNACKBAR_VARIANT.ERROR,
          i18n.t('snackbars.common.errorTitle'),
          `${i18n.t('snackbars.common.error')} ${error.code}.`
        )
      );
    });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const getAvailableRoles = (documentId: string, service: API_MODULE) => (dispatch: AppDispatch) => {
  dispatch({ type: documentUsersManagerConstants.FETCH_AVAILABLE_ROLES_REQUEST });
  documentCoreService
    .getDocumentRoleTypes(documentId, service)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .then((response: any) => {
      dispatch({
        type: documentUsersManagerConstants.FETCH_AVAILABLE_ROLES_SUCCESS,
        payload: response.data.collection ? response.data.collection : response.data,
      });
    })
    .catch((error) => {
      dispatch({ type: documentUsersManagerConstants.FETCH_AVAILABLE_ROLES_FAILURE });
      dispatch(
        snackbarActions.enqueueSnackbar(
          SNACKBAR_VARIANT.ERROR,
          i18n.t('snackbars.common.errorTitle'),
          `${i18n.t('snackbars.common.error')} ${error.code}.`
        )
      );
    });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const getDocumentSigners = (documentId: string, service: API_MODULE) => (dispatch: AppDispatch) => {
  dispatch({ type: documentUsersManagerConstants.FETCH_DOCUMENT_SIGNERS_REQUEST });
  documentInvitationsService
    .getDocumentSigners(documentId, service)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .then((response: any) => {
      if (response) {
        const documentSigners = response?.data?.data?.ids;
        const isOrderSignificant = response?.data?.data?.isOrderSignificant;
        const requiredSignersCount = response?.data?.data?.requiredSignersCount;

        dispatch({
          type: documentUsersManagerConstants.FETCH_DOCUMENT_SIGNERS_SUCCESS,
          payload: {
            documentSigners,
            isOrderSignificant,
            requiredSignersCount,
          },
        });
      }
    })
    .catch((error) => {
      dispatch({ type: documentUsersManagerConstants.FETCH_DOCUMENT_SIGNERS_FAILURE });
      dispatch(
        snackbarActions.enqueueSnackbar(
          SNACKBAR_VARIANT.ERROR,
          i18n.t('snackbars.common.errorTitle'),
          `${i18n.t('snackbars.common.error')} ${error.code}.`
        )
      );
    });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const getDocumentInvitations = (documentId: string, service: string) => (dispatch: AppDispatch) => {
  dispatch({ type: documentUsersManagerConstants.FETCH_DOCUMENT_INVITATIONS_REQUEST });
  documentCoreService
    .getDocumentInvitations(documentId, service)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .then((response: any) => {
      dispatch({
        type: documentUsersManagerConstants.FETCH_DOCUMENT_INVITATIONS_SUCCESS,
        payload: response.data.collection,
      });
    })
    .catch((error) => {
      dispatch({ type: documentUsersManagerConstants.FETCH_DOCUMENT_INVITATIONS_FAILURE });
      dispatch(
        snackbarActions.enqueueSnackbar(
          SNACKBAR_VARIANT.ERROR,
          i18n.t('snackbars.common.errorTitle'),
          `${i18n.t('snackbars.common.error')} ${error.code}.`
        )
      );
    });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const getEntitiesByName = (workspaceId: string, entityName: string, limit?: number) => (
  dispatch: AppDispatch
) => {
  dispatch({ type: documentUsersManagerConstants.FETCH_ENTITIES_BY_NAME_REQUEST });
  identityService
    .fetchWorkspaceEntities(workspaceId, entityName, limit)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .then((response: any) => {
      dispatch({
        type: documentUsersManagerConstants.FETCH_ENTITIES_BY_NAME_SUCCESS,
        payload: response.data.collection,
      });
    })
    .catch((error) => {
      dispatch({ type: documentUsersManagerConstants.FETCH_ENTITIES_BY_NAME_FAILURE });
      dispatch(
        snackbarActions.enqueueSnackbar(
          SNACKBAR_VARIANT.ERROR,
          i18n.t('snackbars.common.errorTitle'),
          `${i18n.t('snackbars.common.error')} ${error.code}.`
        )
      );
    });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const getDocumentExternalWorkspaceEntities = (workspaceId: string, ids: string[]) => (
  dispatch: AppDispatch
) => {
  dispatch({ type: documentUsersManagerConstants.FETCH_DOCUMENT_ENTITIES_REQUEST });
  contactsService
    .getEntities(workspaceId, ids)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .then((response: any) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const parsedContacts: any = {};
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const parsedGroups: any = {};
      response?.data?.identities?.forEach((contactInfo: ExternalContactIdentity) => {
        parsedContacts[contactInfo.identityId] = contactInfo;
      });
      response?.data?.groups?.forEach((contactInfo: ExternalContactGroup) => {
        parsedGroups[contactInfo.groupId] = contactInfo;
      });
      dispatch({
        type: documentUsersManagerConstants.FETCH_DOCUMENT_ENTITIES_SUCCESS,
        payload: {
          contacts: parsedContacts,
          groups: parsedGroups,
        },
      });
    })
    .catch((error) => {
      dispatch({ type: documentUsersManagerConstants.FETCH_DOCUMENT_ENTITIES_FAILURE });
      dispatch(
        snackbarActions.enqueueSnackbar(
          SNACKBAR_VARIANT.ERROR,
          i18n.t('snackbars.common.errorTitle'),
          `${i18n.t('snackbars.common.error')} ${error.code}.`
        )
      );
    });
};

type mutationType = {
  transitionName: DocumentUserAction;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const getAvailableDocumentUserActions = (documentId: string, service: API_MODULE) => (
  dispatch: AppDispatch
) => {
  dispatch({ type: documentUsersManagerConstants.FETCH_AVAILABLE_DOCUMENT_ACTIONS_REQUEST });
  documentDSLServices
    .checkAvailableMutations(documentId, service)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .then((response: any) => {
      dispatch({
        type: documentUsersManagerConstants.FETCH_AVAILABLE_DOCUMENT_ACTIONS_SUCCESS,
        payload:
          service === API_MODULE.DocumentService
            ? response.data.data.mutationCollection.map((el: mutationType) => el.transitionName)
            : response.data.data.mutationCollection.map((el: mutationType) => el.transitionName),
      });
    })
    .catch(() => {
      dispatch({ type: documentUsersManagerConstants.FETCH_AVAILABLE_DOCUMENT_ACTIONS_FAILURE });
    });
};

type updateDraftRolesType = {
  draftHolders: UserManagerPerson[];
  draftSigners: UserManagerPerson[];
  draftOtherRoles: UserManagerPerson[];
  draftTransferability: boolean | null;
};

const updateDraftRoles = ({
  draftHolders,
  draftSigners,
  draftOtherRoles,
  draftTransferability,
}: // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
updateDraftRolesType) => (dispatch: AppDispatch) => {
  const admins = draftOtherRoles
    .filter((role) => role.role === DocumentMemberRole.ADMIN)
    .map((s) => s._id || s.email || '')
    .filter((el) => el);
  const auditors = draftOtherRoles
    .filter((role) => role.role === DocumentMemberRole.AUDITOR)
    .map((s) => s._id || s.email || '')
    .filter((el) => el);
  const editors = draftOtherRoles
    .filter((role) => role.role === DocumentMemberRole.EDITOR)
    .map((s) => s._id || s.email || '')
    .filter((el) => el);
  const viewers = draftOtherRoles
    .filter((role) => role.role === DocumentMemberRole.VIEWER)
    .map((s) => s._id || s.email || '')
    .filter((el) => el);

  dispatch({
    type: documentUsersManagerConstants.UPDATE_DRAFT_ROLES,
    payload: {
      role: DocumentMemberRole.HOLDER,
      ids: draftHolders
        .map((s) => (s.isToken ? 'token' : s._id || s.email || ''))
        .filter((el) => el),
    },
  });
  dispatch({
    type: documentUsersManagerConstants.UPDATE_DRAFT_ROLES,
    payload: {
      role: DocumentMemberRole.SIGNER,
      ids: draftSigners.map((s) => s._id || s.email || '').filter((el) => el),
    },
  });
  dispatch({
    type: documentUsersManagerConstants.UPDATE_DRAFT_ROLES,
    payload: {
      role: DocumentMemberRole.ADMIN,
      ids: admins,
    },
  });
  dispatch({
    type: documentUsersManagerConstants.UPDATE_DRAFT_ROLES,
    payload: {
      role: DocumentMemberRole.AUDITOR,
      ids: auditors,
    },
  });
  dispatch({
    type: documentUsersManagerConstants.UPDATE_DRAFT_ROLES,
    payload: {
      role: DocumentMemberRole.EDITOR,
      ids: editors,
    },
  });
  dispatch({
    type: documentUsersManagerConstants.UPDATE_DRAFT_ROLES,
    payload: {
      role: DocumentMemberRole.VIEWER,
      ids: viewers,
    },
  });
  dispatch({
    type: documentUsersManagerConstants.UPDATE_DRAFT_TRANSFERABILITY,
    payload: draftTransferability,
  });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const updateSignersChanged = (changed: boolean) => (dispatch: AppDispatch) =>
  dispatch({ type: documentUsersManagerConstants.UPDATE_SIGNERS_CHANGED, payload: changed });
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const updateRecipientsChanged = (changed: boolean) => (dispatch: AppDispatch) =>
  dispatch({
    type: documentUsersManagerConstants.UPDATE_OTHER_RECIPIENTS_CHANGED,
    payload: changed,
  });

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const clear = () => (dispatch: AppDispatch) =>
  dispatch({ type: documentUsersManagerConstants.CLEAR });

const documentManagementActions = {
  getDocumentMembers,
  getAvailableRoles,
  getDocumentSigners,
  getDocumentInvitations,
  getEntitiesByName,
  getDocumentExternalWorkspaceEntities,
  getAvailableDocumentUserActions,
  updateDraftRoles,
  updateSignersChanged,
  updateRecipientsChanged,
  clear,
};

export default documentManagementActions;
