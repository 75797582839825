import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ spacing }) =>
    createStyles({
      content: {
        '& > *:not(:last-child)': {
          marginBottom: spacing(2),
        },
      },
      inputPassword: {
        paddingRight: 0,
      },
      passwordMeter: {
        position: 'relative',
        marginTop: spacing(1),
      },
    }),
  { index: 0 }
);
