import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ palette, spacing, breakpoints }) =>
    createStyles({
      mainAvatar: {
        '&:hover': {
          boxShadow: '2px 5px 12px -4px rgb(0 0 0 / 20%)',
        },
        [breakpoints.down('sm')]: {
          marginLeft: spacing(2),
        },
      },
      logout: {
        color: `${palette.error.main} !important`,
        '&:hover p, p': {
          color: `${palette.error.main} !important`,
        },
      },
      menu: {
        maxWidth: 345,
        minWidth: 245,
        '& p': {
          color: palette.text.primary,
        },
      },
      user: {
        overflow: 'hidden',
      },
      userWrapper: {
        padding: spacing(2),
      },
    }),
  { index: 0 }
);
