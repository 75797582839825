/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import { ContentContainer } from 'src/components';

const useStyles = makeStyles(() => ({
  root: {},
}));

const DialogContent = (props) => {
  const classes = useStyles({});

  const { children, ...other } = props;
  return (
    <ContentContainer className={classes.root} {...other}>
      {children}
    </ContentContainer>
  );
};

DialogContent.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.objectOf(PropTypes.string),
  dividers: PropTypes.bool,
};

export default DialogContent;
