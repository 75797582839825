import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Drawer,
  List,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  MenuItem,
} from '@material-ui/core';

import { useStyles } from './styles';

const MobileBottomMenuList = ({ className: classNameProp, open, onClose, actions, subheader }) => {
  const classes = useStyles();
  return (
    <Drawer
      className={clsx(classes.root, classNameProp)}
      anchor="bottom"
      open={open}
      ModalProps={{
        onBackdropClick: onClose,
        keepMounted: true, // Better open performance on mobile.
      }}
      onClose={onClose}
    >
      <List
        subheader={
          subheader && (
            <ListSubheader
              classes={{
                root: classes.item,
              }}
            >
              {subheader}
            </ListSubheader>
          )
        }
      >
        {actions.map((action, index) => (
          <MenuItem
            classes={{
              root: clsx(
                classes.item,
                classes.listItem,
                action.active && classes.listItemActive,
                action.secondaryIcon && classes.listItemWithSecondaryAction
              ),
            }}
            key={index}
            disabled={action.disabled}
            onClick={() => {
              action.onClick();
              onClose();
            }}
          >
            {action.icon && (
              <ListItemIcon classes={{ root: classes.listItemIcon }}>{action.icon}</ListItemIcon>
            )}
            <ListItemText classes={{ root: classes.listItemText }}>{action.name}</ListItemText>
            {action.secondaryIcon && (
              <ListItemSecondaryAction classes={{ root: classes.listItemSecondaryAction }}>
                {action.secondaryIcon}
              </ListItemSecondaryAction>
            )}
          </MenuItem>
        ))}
      </List>
    </Drawer>
  );
};

MobileBottomMenuList.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool.isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.node,
      secondaryIcon: PropTypes.node,
      name: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
      active: PropTypes.bool,
      onClick: PropTypes.func.isRequired,
    })
  ),
  onClose: PropTypes.func.isRequired,
  subheader: PropTypes.string,
};

export default MobileBottomMenuList;
