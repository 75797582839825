import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ custom, palette, spacing }) =>
    createStyles({
      root: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: palette.background.paper,
        margin: spacing(2, 0, 4),
      },
      container: {
        height: 40,
      },
      divider: {
        height: custom.breadcrumbsMenuHeight,
      },
    }),
  { index: 0 }
);
