import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ConfirmDialog } from '../../index';

const DeleteFolder = ({ open, onChange, onClose }) => {
  const { t } = useTranslation();

  return (
    <ConfirmDialog
      open={open}
      title={t('drivePage.foldersDialog.delete.title')}
      subtitle={t('drivePage.foldersDialog.delete.description')}
      applyButtonText={t('drivePage.foldersDialog.delete.apply')}
      cancelButtonText={t('common.cancel')}
      actionAccept={onChange}
      actionCancel={onClose}
    />
  );
};

DeleteFolder.propTypes = {
  open: PropTypes.bool,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
};

DeleteFolder.defaultProps = {
  open: false,
};

export default DeleteFolder;
