import React from 'react';
import { sortableHandle } from 'react-sortable-hoc';
import { useStyles } from './styles';
import { Drag } from '../CustomIcon';

const Handle = () => {
  const classes = useStyles();

  return (
    <div className={classes.dragIcon}>
      <Drag />
    </div>
  );
};

const DragHandle = sortableHandle(() => <Handle />);

export default DragHandle;
