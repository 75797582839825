import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ breakpoints, custom, palette, spacing, zIndex }) => {
    const { leftMenuWidth: menuWidth } = custom;

    return createStyles({
      root: {
        height: '100%',
        width: '100%',
        backgroundColor: palette.background.paper,
      },
      headerVerticalPadding: {
        paddingTop: spacing(1),
        paddingBottom: spacing(1),
      },
      appHeader: { position: 'relative' },
      main: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        [breakpoints.up('md')]: {
          marginLeft: menuWidth,
          width: `calc(100% - ${menuWidth}px)`,
        },
      },
      content: {
        flex: 1,
        overflowX: 'hidden',
        overflowY: 'auto',
        position: 'relative',
        [breakpoints.up('md')]: {
          paddingTop: `0 !important`,
          paddingBottom: `${spacing(3)}px !important`,
        },
      },
      snackbarContainerRoot: {
        width: `calc(100% - ${spacing(1)}px)`,
        marginLeft: spacing(0.5),
        maxWidth: breakpoints.values.md,
        [breakpoints.up('sm')]: {
          marginLeft: 0,
        },
      },
      snackbarAnchorOriginTopCenter: {
        top: spacing(0.5),
        [breakpoints.up('sm')]: {
          top: spacing(1.5),
          left: '50%',
          right: 'auto',
          transform: 'translateX(-50%)',
        },
        [breakpoints.up('md')]: {
          top: spacing(3),
        },
      },
      snackbarCollapseContainer: {
        padding: 0,
      },
      snackbarCollapseWrapper: {
        marginTop: 0,
        marginBottom: spacing(0.5),
      },
      snackbarWrappedRoot: {
        top: 0,
      },
      authorizationLoader: {
        backgroundColor: palette.background.paper,
        zIndex: zIndex.tooltip + 10,
      },
    });
  },
  { index: 0 }
);
