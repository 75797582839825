import typography from '../typography';
import palette from '../palette';

export default {
  root: {
    fontSize: 12,
    '&$focused': {
      color: palette.primary.main,
    },
    '&$error': {
      fontWeight: typography.fontWeightMedium,
    },
  },
};
