import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { EmptyBanner, SmallContentWithScrollbar } from 'src/components';

const GoogleAuthenticatorDone = ({ nextHandle }) => {
  const { t } = useTranslation();

  return (
    <SmallContentWithScrollbar>
      <EmptyBanner
        src="/images/frames/no-action.svg"
        title={t('userConfigurationPage.security.method.googleAuthenticator.stepsTitle')}
        description={t(
          'userConfigurationPage.security.method.googleAuthenticator.done.description'
        )}
        buttonLabel={t(
          'userConfigurationPage.security.method.googleAuthenticator.done.backToPanel'
        )}
        onClick={nextHandle}
      />
    </SmallContentWithScrollbar>
  );
};

GoogleAuthenticatorDone.propTypes = {
  nextHandle: PropTypes.func,
};

export default GoogleAuthenticatorDone;
