import { lighten } from '@material-ui/core/styles';
import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ palette, spacing }) =>
    createStyles({
      root: {
        textAlign: 'center',
      },
      primaryRootVariant: {},
      successRootVariant: {
        '& $applyButton': {
          backgroundColor: palette.success.main,
          '&:hover': {
            backgroundColor: lighten(palette.success.main, 0.075),
          },
          '&:focus, &:active': {
            backgroundColor: palette.success.dark,
          },
          '&[disabled]': {
            backgroundColor: palette.action.disabledBackground,
            color: palette.action.disabled,
          },
        },
      },
      errorRootVariant: {
        '& $applyButton': {
          backgroundColor: palette.error.main,
          '&:hover': {
            backgroundColor: lighten(palette.error.main, 0.075),
          },
          '&:focus, &:active': {
            backgroundColor: palette.error.dark,
          },
          '&[disabled]': {
            backgroundColor: palette.action.disabledBackground,
            color: palette.action.disabled,
          },
        },
      },
      informationRootVariant: {
        textAlign: 'left',
      },
      actionsWrapper: {
        flexDirection: 'column',
      },
      button: {
        '&:not(:first-child)': {
          marginLeft: 0,
        },
        '&:not(:last-child)': {
          marginBottom: spacing(0.75),
        },
      },
      applyButton: {},
      subtitleWrapper: {},
    }),
  { index: 0 }
);
