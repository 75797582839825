import React from 'react';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/redux/reducers';
import { AdvancedSearchFields, DocumentStatus } from 'src/models/documents.model';
import documentsActions from 'src/redux/actions/documents.actions';
import DocumentStatusDropdown from 'src/components/DocumentStatusDropdown/DocumentStatusDropdown';
import DocumentStatusChip from 'src/components/DocumentStatusChip/DocumentStatusChip';
import { useStyles } from './styles';
import Field from '../../subcomponents/AdvancedSearchModalField';

const DocumentStatusField = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const draftStatusTypes = useSelector(
    (state: RootState) => state.documentsStore.draftAdvancedSearch[AdvancedSearchFields.STATUS]
  );

  const isEmpty = draftStatusTypes.length === 0;
  const nrSuffix =
    draftStatusTypes.length > 2 ? (
      <Typography color="textSecondary" variant="body1" className={classes.suffix}>
        +{draftStatusTypes.length - 2}
      </Typography>
    ) : null;

  const orderedStatuses = Object.values(DocumentStatus).filter((el) =>
    draftStatusTypes.includes(el)
  );

  const onSubmit = (statuses: DocumentStatus[]) => {
    dispatch(documentsActions.updateDraftAdvancedSearch(AdvancedSearchFields.STATUS, statuses));
  };

  return (
    <Field title={t<string>('advancedSearch.fields.status.label')} withInputWrapper>
      <DocumentStatusDropdown onSubmit={onSubmit} initialStatusTypes={draftStatusTypes}>
        <>
          {isEmpty ? (
            <Typography>{t<string>('advancedSearch.fields.status.placeholder')}</Typography>
          ) : (
            <>
              {orderedStatuses[0] && <DocumentStatusChip status={orderedStatuses[0]} />}
              {orderedStatuses[1] && (
                <div className={classes.suffix}>
                  <DocumentStatusChip status={orderedStatuses[1]} />
                </div>
              )}
              {nrSuffix}
            </>
          )}
        </>
      </DocumentStatusDropdown>
    </Field>
  );
};

export default DocumentStatusField;
