import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ breakpoints, palette, shadows, spacing, transitions, typography }) =>
    createStyles({
      root: {
        color: palette.text.primary,
        marginBottom: spacing(2),
        '&:hover': {
          color: 'inherit',
          boxShadow: shadows[12],
          '& $colorOnHover': {
            color: `${palette.primary.main} !important`,
          },
        },
      },
      colorOnHover: {
        transition: transitions.create(['color'], {
          duration: transitions.duration.short,
        }),
      },
      nameBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        flexWrap: 'wrap',
        [breakpoints.up('lg')]: {
          justifyContent: 'flex-start',
          flexDirection: 'row',
          flexWrap: 'unset',
        },
      },
      actionButton: {
        minWidth: 170,
        fontWeight: typography.fontWeightRegular,
        paddingLeft: spacing(1.5),
        paddingRight: spacing(1.5),
        fontSize: '0.925rem',
        margin: `${spacing(0.25)}px`,
        [breakpoints.up('md')]: {
          margin: `${spacing(0.25)}px 0 ${spacing(0.25)}px ${spacing(2)}px`,
        },
      },
      mediumText: {
        fontWeight: typography.fontWeightMedium,
      },
      disabled: {
        // pointerEvents: 'none',
      },
      inActive: {
        opacity: '0.1',
        pointerEvents: 'none',
      },
      icon: {
        height: 24,
        marginRight: spacing(0),
        marginBottom: spacing(1),
        color: palette.greySimple.main,
        [breakpoints.up('md')]: {
          marginRight: spacing(1),
          marginBottom: 0,
        },
      },
      additionalActionButtonsWrapper: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: spacing(1),

        [breakpoints.up('md')]: {
          display: 'inline-flex',
          justifyContent: 'flex-end',
          marginBottom: 0,
        },
        [breakpoints.up('xl')]: {
          marginRight: spacing(1),
        },
        '& $additionalActionButton': {
          [breakpoints.up('lg')]: {
            marginLeft: spacing(0.5),
          },
          [breakpoints.up('xl')]: {
            marginLeft: spacing(1.5),
          },
        },
      },
      additionalActionButton: {
        margin: spacing(0.25, 0),
        paddingLeft: spacing(1.25),
        paddingRight: spacing(1.25),
      },
      additionalActionButtonIcon: {
        transition: 'inherit',
        color: `${palette.text.secondary} !important`,
      },
    }),
  { index: 0 }
);
