import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import workspaceGroupsActions from 'src/redux/actions/workspaceGroups.actions';
import identityActions from 'src/redux/actions/identity.actions';
import { Route, Switch, Redirect, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { goBack } from 'src/helpers/goBack';
import { FullScreenDialog, FullScreenDialogContentWrapper } from 'src/components';
import { Groups, Settings, Users, Subscription } from './subpages';
import { Links } from './Links';

const WorkspaceConfigurationPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const { isOwner } = useSelector((state) => state.identityStore, undefined);

  useEffect(() => {
    if (!isOwner && isOwner !== null) {
      history.push('/');
    }
    return () => {
      dispatch(workspaceGroupsActions.clearWorkspaceGroupsStore());
      dispatch(identityActions.clearWorkspaceUsersStore());
    };
  }, [isOwner, dispatch, history]);

  const handleClose = () => {
    goBack(history);
  };

  const title = Links(t, false)
    .map((link) => link.children)
    .flat()
    .find((link) => link.url === location.pathname)?.label;

  return (
    <FullScreenDialog
      isOpen
      onClose={handleClose}
      backgroundVariant="grey"
      title={title}
      titleLeftSpace
    >
      <FullScreenDialogContentWrapper menuLinks={Links(t)}>
        <Switch>
          <Route path="/workspace-settings/basic/settings" component={Settings} />
          <Route path="/workspace-settings/user-management/users" component={Users} />
          <Route path="/workspace-settings/user-management/groups" component={Groups} />
          <Route path="/workspace-settings/subscription/products" component={Subscription} />
          <Redirect from="/workspace-settings" to="/workspace-settings/basic/settings" />
        </Switch>
      </FullScreenDialogContentWrapper>
    </FullScreenDialog>
  );
};

export default WorkspaceConfigurationPage;
