import React from 'react';
import clsx from 'clsx';
import { Identifier } from 'dnd-core';
import { useDragLayer } from 'react-dnd';
import { DocumentItem } from 'src/models/documents.model';
import { XYCoord } from 'react-dnd/dist/types/types/monitors';
import { useStyles } from './styles';
import ItemsTemplate from './ItemsTemplate';

type FieldDragLayerProps = {
  selectedDocuments: DocumentItem[];
};
const FieldDragLayer = ({ selectedDocuments }: FieldDragLayerProps): JSX.Element | null => {
  const classes = useStyles();
  const { itemType, isDragging, currentOffset } = useDragLayer((monitor) => ({
    itemType: monitor.getItemType(),
    currentOffset: monitor.getClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  const getItemStyles = (currentOffset: XYCoord | null) => {
    let itemStyle;

    if (!currentOffset) {
      itemStyle = { display: 'none' };
    } else {
      const { x, y } = currentOffset;
      const transform = `translate(${x}px, ${y}px)`;
      itemStyle = {
        transform,
        WebkitTransform: transform,
      };
    }

    return itemStyle;
  };

  const renderItem = (type: Identifier | null) =>
    type === 'ITEM' && <ItemsTemplate selectedDocuments={selectedDocuments} />;

  if (!isDragging) return null;

  return (
    <div className={clsx(classes.layer)} style={getItemStyles(currentOffset)}>
      {renderItem(itemType)}
    </div>
  );
};

export default FieldDragLayer;
