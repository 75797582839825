import React, { MouseEvent, useState } from 'react';
import { Divider, Link, Menu, MenuItem, Theme, Typography, useMediaQuery } from '@material-ui/core';
import { Help } from 'src/components/CustomIcon';
import { HeaderButton } from 'src/components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IdentitySelector } from 'src/redux/selectors/identity.selector';
import { userComActions } from 'src/redux/actions/userCom.actions';
import { useStyles } from './styles';

const HeaderHelp = (): JSX.Element | null => {
  const classes = useStyles();
  const workspaceId = useSelector(IdentitySelector.selectWorkspaceId);
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const smallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const handleClick = (e: MouseEvent<HTMLElement>) => {
    if (e) setAnchorEl(e.currentTarget);
  };

  const onChatSupport = () => {
    userComActions.openChat();
    handleClose();
  };

  const onSubmitRequest = () => {
    if (workspaceId) userComActions.user.submitRequest(workspaceId);
    handleClose();
  };

  const openPage = (url: string) => {
    window.open(url, '_blank');
    handleClose();
  };

  const handleClose = () => setAnchorEl(null);

  const isOpen = Boolean(anchorEl);
  if (smallScreen) return null;
  return (
    <>
      <HeaderButton icon={<Help />} onClick={handleClick} tooltip={t<string>('headerHelp.help')} />
      <Menu
        classes={{
          list: classes.menu,
        }}
        open={isOpen}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        autoFocus={false}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleClose}
      >
        <MenuItem divider={false} onClick={() => openPage('https://docs.doxychain.com')}>
          <Typography color="textPrimary">{t<string>('headerHelp.helpDocs')} </Typography>
        </MenuItem>
        <MenuItem divider={false} onClick={() => openPage('https://www.doxychain.com/tutorials')}>
          <Typography color="textPrimary">{t<string>('headerHelp.tutorials')}</Typography>
        </MenuItem>
        <MenuItem divider={false} onClick={() => openPage('https://www.doxychain.com/faqs')}>
          <Typography color="textPrimary">{t<string>('headerHelp.faq')}</Typography>
        </MenuItem>
        <Divider />
        <MenuItem divider={false} onClick={onChatSupport}>
          <Typography color="textPrimary">{t<string>('headerHelp.chatSupport')}</Typography>
        </MenuItem>
        <MenuItem divider={false} onClick={onSubmitRequest}>
          <Typography color="textPrimary">{t<string>('headerHelp.request')}</Typography>
        </MenuItem>
        <Divider />
        <MenuItem divider={false} onClick={() => openPage(t('links.terms'))}>
          <Typography color="textPrimary">{t<string>('headerHelp.terms')}</Typography>
        </MenuItem>
        <MenuItem divider={false} onClick={() => openPage(t('links.privacyPolicy'))}>
          <Typography color="textPrimary">{t<string>('headerHelp.privacy')}</Typography>
        </MenuItem>
        <Divider />
        <MenuItem divider={false} className={classes.appImageWrapper}>
          <Link
            href="https://apps.apple.com/pl/app/doxychain/id1579677845"
            className={classes.link}
            target="_blank"
          >
            <img className={classes.imageAppStore} src="/images/AppStore.png" alt="App Store" />
          </Link>
          <Link
            className={classes.link}
            href="https://play.google.com/store/apps/details?id=com.doxychain.DoxyChain"
            target="_blank"
          >
            <img className={classes.imagePlay} src="/images/GooglePlay.png" alt="Google Play" />
          </Link>
        </MenuItem>
      </Menu>
    </>
  );
};

export default HeaderHelp;
