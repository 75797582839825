import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import twoFactorActions from 'src/redux/actions/twoFactor.actions';
import axios from 'src/helpers/axios';
import tokenHelper from 'src/helpers/token.helper';
import { RequestStatus } from 'src/helpers/reduxReuquest.util';
import TwoFactorForm from './subcomponents/TwoFactorForm';
import { FullScreenDialog } from '../index';

const TwoFactorModal = () => {
  const {
    twoFactorActive,
    requestData,
    twoFactorMethod,
    twoFactorToken,
    twoFactorInitTimestamp,
    twoFactorSendStatus,
    twoFactorReSendStatus,
    modalMode,
    twoFactorInitStatus,
    twoFactorInitError,
  } = useSelector((state) => state.twoFactor, undefined);
  const [hasErrors, setHasErrors] = useState(false);
  const dispatch = useDispatch();
  const isSending =
    twoFactorSendStatus === RequestStatus.PENDING || twoFactorSendStatus === RequestStatus.SUCCESS;
  const isReSending = twoFactorReSendStatus === RequestStatus.PENDING;
  const twoFactorCallbacks = useRef([]);
  const shortToken = localStorage.getItem('shortToken') || '';
  const token = localStorage.getItem('token') || '';

  /*
   * Handle resend code if 2FA method not prevents it
   * */
  const onReSend = () => {
    startNewTwoFactorSession();
  };

  const startNewTwoFactorSession = () => {
    const finalToken = tokenHelper.getAnyToken();
    setHasErrors(false);
    dispatch(twoFactorActions.initTwoFactorSession(finalToken.token, requestData.withPermissions));
  };

  /*
   * Handle 2FA detected by axios interceptor
   * */
  const onTwoFactor = (requestData, successCallback, errorCallback, mode, is2faError) => {
    if (is2faError === '2FA_REQUIRED' || mode === 'initial') {
      twoFactorCallbacks.current = [];
    }
    /*
     * Preserving callbacks for 2FA resolution
     * */
    twoFactorCallbacks.current.push({ success: successCallback, error: errorCallback });
    /*
     * Passing request data to store, this action also opens TwoFactorModal
     * */
    dispatch(twoFactorActions.openTwoFactor(requestData, mode, is2faError));
  };

  const onCloseTwoFactor = () => {
    dispatch(twoFactorActions.closeTwoFactor());
  };

  /*
   * Pass response from repeated request with 2FA authorization to original promise
   * */
  const onTwoFactorResolution = (type, response) => {
    if (type === RequestStatus.SUCCESS) {
      for (let i = twoFactorCallbacks.current.length; i > 0; i -= 1) {
        twoFactorCallbacks.current[i - 1].success(response);
      }
    } else {
      for (let i = twoFactorCallbacks.current.length; i > 0; i -= 1) {
        twoFactorCallbacks.current[i - 1].error(response);
      }
    }
  };

  /*
   * Resend original request interrupted by 2FA with acquired 2FA token
   * */
  const retryRequest = (code) => {
    const { method, url, data, headers } = requestData;
    const finalToken = token || shortToken;

    /*
     * inject 2FA session headers to authorize request
     * */
    const newHeaders = {
      ...headers,
      'Two-Factor-Token': twoFactorToken.token,
      'Two-Factor-Code': code,
      Authorization: finalToken.token,
    };

    /*
     * Rebuild config for request
     * */
    const axiosConfig = {
      method,
      url,
      headers: newHeaders,
      twoFactorSigned: true,
    };

    /*
     * if request could have payload, inject it to config
     * */
    if (['post', 'put', 'patch'].indexOf(method) !== -1) {
      /*
       * try to decode JSON object, if not possible fallback to plaintext
       * */
      try {
        axiosConfig.data = JSON.parse(data);
      } catch (error) {
        axiosConfig.data = data;
      }
    }

    /*
     * Execute request, and pass response to callback in App.js, so it could be passed back to original promise
     * */
    axios(axiosConfig)
      .then((response) => {
        onTwoFactorResolution(RequestStatus.SUCCESS, response);
      })
      .catch((error) => {
        if (error.response.status === 403 && error.response.data.errors[0].code === 'FORBIDDEN') {
          setHasErrors(true);
        } else {
          onTwoFactorResolution(RequestStatus.ERROR, error);
          dispatch(twoFactorActions.closeTwoFactor());
        }
      });

    /*
     * Our job is done here, close 2FA modal and reset store
     * */
  };

  /*
   * Handle send code to acquire 2FA token
   * */
  const onSend = (code) => {
    if (modalMode === 'initial') {
      onTwoFactorResolution(RequestStatus.SUCCESS, {
        twoFactorCode: code,
        twoFactorToken: twoFactorToken.token,
      });
    } else {
      retryRequest(code);
    }
  };

  useEffect(() => {
    axios.onTwoFactorCallback = (requestData, successCallback, errorCallback, mode, is2faError) => {
      onTwoFactor(requestData, successCallback, errorCallback, mode, is2faError);
    };
    axios.closeTwoFactor = () => {
      onCloseTwoFactor();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (twoFactorActive) startNewTwoFactorSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [twoFactorActive]);

  useEffect(() => {
    if (twoFactorInitStatus === RequestStatus.ERROR) {
      onTwoFactorResolution(RequestStatus.ERROR, twoFactorInitError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [twoFactorInitStatus]);

  useEffect(() => {
    if (twoFactorSendStatus === RequestStatus.SUCCESS) {
      /*
       * code has been successfully send, now we should have token to authorize original request
       * lets resend it
       * */
      setHasErrors(false);
    } else if (twoFactorSendStatus === RequestStatus.ERROR) {
      setHasErrors(true);
    }
  }, [twoFactorSendStatus]);

  return (
    <>
      {twoFactorActive && twoFactorMethod && (
        <FullScreenDialog
          isOpen={twoFactorActive}
          withLogo
          showClose
          onClose={() => onCloseTwoFactor()}
        >
          <TwoFactorForm
            method={twoFactorMethod}
            hasErrors={hasErrors}
            isSending={isSending}
            isReSending={isReSending}
            lastSendTimestamp={twoFactorInitTimestamp}
            onClearErrors={() => {
              setHasErrors(false);
            }}
            onReSend={() => {
              onReSend();
            }}
            onSend={(code) => {
              onSend(code);
            }}
          />
        </FullScreenDialog>
      )}
    </>
  );
};

export default TwoFactorModal;
