import axios from 'src/helpers/axios';
import { parseError } from 'src/helpers/parseErrors';

const twoFactorService = {
  initTwoFactorSession,
  sendTwoFactor,
  reSendTwoFactorCode,
  getUserTwoFactorConfig,
  changeActiveTwoFactorType,
  deleteTwoFactorType,
  initTwoFactorTypeChange,
  confirmTwoFactorTypeChange,
};

function initTwoFactorSession(authToken, permissions) {
  return axios
    .post(
      '/rest/auth/security/2fa',
      { permissions },
      {
        headers: {
          Authorization: authToken,
        },
      }
    )
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

function sendTwoFactor(code, twoFactorId) {
  return axios
    .post(
      '/rest/auth/two-factor',
      {
        code,
      },
      {
        headers: {
          twoFactorId,
        },
      }
    )
    .then((response) => response)
    .catch((error) => Promise.reject(error.response.data.errors));
}

function reSendTwoFactorCode(twoFactorId) {
  return axios
    .post(
      '/rest/auth/two-factor/resend',
      {},
      {
        headers: {
          twoFactorId,
        },
      }
    )
    .then((response) => response)
    .catch((error) => Promise.reject(error.response.data.errors));
}

function getUserTwoFactorConfig() {
  return axios
    .get('/rest/auth/security/2fa', { withPermissions: ['AUTH_USER_GET_2FA_CONFIGURATION'] })
    .then((response) => response.data)
    .catch((error) => parseError(error));
}

function changeActiveTwoFactorType(type) {
  return axios
    .put(`rest/auth/security/2fa/${type}`, null, {
      withPermissions: ['AUTH_USER_PUT_2FA_CONFIGURATION'],
      withTwoFactor: true,
    })
    .then((response) => response.data)
    .catch((error) => parseError(error));
}

function deleteTwoFactorType(type) {
  return axios
    .delete(`rest/auth/security/2fa/${type}`, {
      withPermissions: ['AUTH_USER_DELETE_2FA_CONFIGURATION'],
      withTwoFactor: true,
    })
    .then((response) => response.data)
    .catch((error) => parseError(error));
}

function initTwoFactorTypeChange(type) {
  const permissions = [
    ...(type === 'EMAIL' ? ['AUTH_USER_POST_2FA_EMAIL_CODE_GENERATE'] : []),
    ...(type === 'PHONE' ? ['AUTH_USER_POST_2FA_SMS_CODE_GENERATE'] : []),
    ...(type === 'GOOGLE' ? ['AUTH_USER_POST_2FA_GOOGLE_CODE_GENERATE'] : []),
  ];
  return axios
    .post(`/rest/auth/security/2fa/${type.toLowerCase()}`, null, {
      withPermissions: permissions,
    })
    .then((response) => response.data)
    .catch((error) => parseError(error));
}

function confirmTwoFactorTypeChange(type, code) {
  return axios
    .put(`/rest/auth/security/2fa/${type}/code/${code}/confirm`, null, {
      withPermissions: ['AUTH_USER_PUT_TWO_FA_TYPE'],
      withTwoFactor: true,
    })
    .then((response) => response.data)
    .catch((error) => parseError(error));
}

export default twoFactorService;
