import React from 'react';
import { Chip, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/redux/reducers';
import { AdvancedSearchFields } from 'src/models/documents.model';
import documentsActions from 'src/redux/actions/documents.actions';
import { UserManagerPerson } from 'src/models/documentUsersManagement.model';
import Field from '../../subcomponents/AdvancedSearchModalField';
import RecipientsDropdown from '../../../RecipientsDropdown/RecipientsDropdown';
import { useStyles } from './styles';

const RecipientsField = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const draftRecipients = useSelector(
    (state: RootState) => state.documentsStore.draftAdvancedSearch[AdvancedSearchFields.RECIPIENTS]
  );
  const [firstRecipient] = draftRecipients;
  const recipientsCounter = draftRecipients.length - 1;
  const isEmpty = draftRecipients.length === 0;

  const onSubmit = (recipients: UserManagerPerson[]) =>
    dispatch(
      documentsActions.updateDraftAdvancedSearch(AdvancedSearchFields.RECIPIENTS, recipients)
    );

  return (
    <Field title={t<string>('advancedSearch.fields.recipients.label')} withInputWrapper>
      <RecipientsDropdown onSubmit={onSubmit} initialRecipients={draftRecipients}>
        <>
          {isEmpty && (
            <Typography>{t<string>('advancedSearch.fields.recipients.placeholder')}</Typography>
          )}
          {firstRecipient && (
            <Chip className={classes.chip} label={firstRecipient._name || firstRecipient.email} />
          )}
          {recipientsCounter > 0 && <Typography color="primary">+{recipientsCounter}</Typography>}
        </>
      </RecipientsDropdown>
    </Field>
  );
};

export default RecipientsField;
