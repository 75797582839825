import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  () =>
    createStyles({
      centerHolder: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      },
    }),
  { index: 0 }
);
