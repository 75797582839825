enum certificateManagementConstants {
  DOCUMENT_SIGN_REQUEST = 'DOCUMENT_SIGN_REQUEST',
  DOCUMENT_SIGN_SUCCESS = 'DOCUMENT_SIGN_SUCCESS',
  DOCUMENT_SIGN_ERROR = 'DOCUMENT_SIGN_ERROR',

  DOCUMENT_REJECT_REQUEST = 'DOCUMENT_REJECT_REQUEST',
  DOCUMENT_REJECT_SUCCESS = 'DOCUMENT_REJECT_SUCCESS',
  DOCUMENT_REJECT_ERROR = 'DOCUMENT_REJECT_ERROR',

  DOCUMENT_INVALIDATE_REQUEST = 'DOCUMENT_INVALIDATE_REQUEST',
  DOCUMENT_INVALIDATE_SUCCESS = 'DOCUMENT_INVALIDATE_SUCCESS',
  DOCUMENT_INVALIDATE_ERROR = 'DOCUMENT_INVALIDATE_ERROR',

  DOCUMENT_INVALIDATE_TEMPORARILY_REQUEST = 'DOCUMENT_INVALIDATE_TEMPORARILY_REQUEST',
  DOCUMENT_INVALIDATE_TEMPORARILY_SUCCESS = 'DOCUMENT_INVALIDATE_TEMPORARILY_SUCCESS',
  DOCUMENT_INVALIDATE_TEMPORARILY_ERROR = 'DOCUMENT_INVALIDATE_TEMPORARILY_ERROR',

  DOCUMENT_VALIDATE_REQUEST = 'DOCUMENT_VALIDATE_REQUEST',
  DOCUMENT_VALIDATE_SUCCESS = 'DOCUMENT_VALIDATE_SUCCESS',
  DOCUMENT_VALIDATE_ERROR = 'DOCUMENT_VALIDATE_ERROR',

  DOCUMENT_SET_TRANSFERABILITY_REQUEST = 'DOCUMENT_SET_TRANSFERABILITY_REQUEST',
  DOCUMENT_SET_TRANSFERABILITY_SUCCESS = 'DOCUMENT_SET_TRANSFERABILITY_SUCCESS',
  DOCUMENT_SET_TRANSFERABILITY_ERROR = 'DOCUMENT_SET_TRANSFERABILITY_ERROR',

  DOCUMENT_SET_ACCESS_LEVEL_REQUEST = 'DOCUMENT_SET_ACCESS_LEVEL_REQUEST',
  DOCUMENT_SET_ACCESS_LEVEL_SUCCESS = 'DOCUMENT_SET_ACCESS_LEVEL_SUCCESS',
  DOCUMENT_SET_ACCESS_LEVEL_ERROR = 'DOCUMENT_SET_ACCESS_LEVEL_ERROR',

  DELETE_USERS_MAIL_INVITATION_REQUEST = 'DELETE_USERS_MAIL_INVITATION_REQUEST',
  DELETE_USERS_MAIL_INVITATION_SUCCESS = 'DELETE_USERS_MAIL_INVITATION_SUCCESS',
  DELETE_USERS_MAIL_INVITATION_ERROR = 'DELETE_USERS_MAIL_INVITATION_ERROR',

  INVITE_USERS_BY_MAIL_ERROR = 'INVITE_USERS_BY_MAIL_ERROR',

  TRANSFER_OWNERSHIP_REQUEST = 'TRANSFER_OWNERSHIP_REQUEST',
  TRANSFER_OWNERSHIP_SUCCESS = 'TRANSFER_OWNERSHIP_SUCCESS',
  TRANSFER_OWNERSHIP_ERROR = 'TRANSFER_OWNERSHIP_ERROR',
}

export default certificateManagementConstants;
