import breakpoints from '../breakpoints';
import custom from '../custom';
import palette from '../palette';
import spacing from '../spacing';
import typography from '../typography';

export default {
  tooltip: {
    backdropFilter: 'blur(3px)',
    backgroundColor: custom.tooltipBackground,
    color: palette.common.white,
    borderRadius: spacing(0.375),
    padding: spacing(0.75, 1.5),
    fontSize: 12,
    fontWeight: typography.fontWeightRegular,
    lineHeight: 1,
    maxWidth: 300,
    textAlign: 'center',
    userSelect: 'none',
  },
  arrow: {
    fontSize: 8,
    color: custom.tooltipBackground,
  },
  tooltipPlacementLeft: {
    margin: spacing(0, 1.5),
    [breakpoints.up('sm')]: {
      margin: spacing(0, 0.75),
    },
  },
  tooltipPlacementRight: {
    margin: spacing(0, 1.5),
    [breakpoints.up('sm')]: {
      margin: spacing(0, 0.75),
    },
  },
  tooltipPlacementTop: {
    margin: spacing(1.5, 0),
    [breakpoints.up('sm')]: {
      margin: spacing(0.75, 0),
    },
  },
  tooltipPlacementBottom: {
    margin: spacing(1.5, 0),
    [breakpoints.up('sm')]: {
      margin: spacing(0.75, 0),
    },
  },
};
