import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import identityActions from 'src/redux/actions/identity.actions';
import {
  Button,
  FormControl,
  FormHelperText,
  List,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { AvatarAndTextItem, CardCollapseItem, Tag } from 'src/components';
import { ImportExport as ExportIcon, Info as InfoIcon } from 'src/components/CustomIcon';
import { ItemWrapper } from '../index';
import { useStyles } from './styles';

const Item = ({ item, onExport, openOnStart, disabledEmail }) => {
  const classes = useStyles();
  const smallScreen = useMediaQuery(({ breakpoints }) => breakpoints.down('sm'));
  const { date, groups, emails } = item;
  const { name, src: mainImageSrc, ...otherAvatarProps } = item.data;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleChangeEmail = (event) => {
    dispatch(identityActions.switchContactEmail(event.target.value));
  };

  return (
    <CardCollapseItem
      openOnStart={openOnStart}
      title={
        <AvatarAndTextItem
          size="small"
          text={name}
          mainImageSrc={mainImageSrc}
          {...otherAvatarProps}
        />
      }
      classes={{ root: classes.root }}
    >
      <List className={classes.list}>
        {date && (
          <ItemWrapper
            label={t('userConfigurationPage.identity.item.accessionDate')}
            smallScreen={smallScreen}
          >
            <Typography>
              <strong>{date}</strong>
            </Typography>
          </ItemWrapper>
        )}

        <ItemWrapper
          label={t('userConfigurationPage.identity.item.email')}
          smallScreen={smallScreen}
          alignItems="flex-start"
        >
          <FormControl fullWidth classes={{ root: classes.selectWrapper }}>
            <Select
              value={emails[0]}
              onChange={handleChangeEmail}
              disabled={disabledEmail}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              }}
            >
              {emails.map((email, index) => (
                <MenuItem key={index} value={email}>
                  {email}
                </MenuItem>
              ))}
            </Select>
            {disabledEmail && (
              <FormHelperText className={classes.infoWithIcon}>
                <InfoIcon />
                {t('userConfigurationPage.identity.disabledEmail')}
              </FormHelperText>
            )}
          </FormControl>
        </ItemWrapper>

        <ItemWrapper
          label={t('userConfigurationPage.identity.item.groups')}
          smallScreen={smallScreen}
        >
          {groups.map((group, index) => (
            <Tag key={index} automaticColor className="tag-item">
              {group}
            </Tag>
          ))}
        </ItemWrapper>

        {onExport && (
          <ItemWrapper
            label={!smallScreen ? t('userConfigurationPage.identity.item.export') : null}
            smallScreen={smallScreen}
          >
            <Button
              className={classes.actionButton}
              color="secondary"
              endIcon={<ExportIcon />}
              onClick={onExport}
              fullWidth={smallScreen}
            >
              {smallScreen ? t('userConfigurationPage.identity.item.export') : t('common.export')}
            </Button>
          </ItemWrapper>
        )}
      </List>
    </CardCollapseItem>
  );
};

Item.propTypes = {
  item: PropTypes.shape({
    data: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      src: PropTypes.string,
    }),
    date: PropTypes.string,
    groups: PropTypes.arrayOf(PropTypes.string),
    emails: PropTypes.arrayOf(PropTypes.string),
  }),
  onExport: PropTypes.func,
  openOnStart: PropTypes.bool,
  disabledEmail: PropTypes.bool,
};

Item.defaultProps = {
  openOnStart: false,
};

export default Item;
