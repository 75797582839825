import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ breakpoints }) =>
    createStyles({
      root: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        padding: 'inherit',
        overflowX: 'inherit',
        overflowY: 'inherit',
      },
      button: {
        display: 'block',
        margin: '0 auto',
        maxWidth: breakpoints.values.xs * 0.8,
      },
    }),
  { index: 0 }
);
