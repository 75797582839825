import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import avatarActions from 'src/redux/actions/avatar.actions';
import { ConfirmDialog } from 'src/components';
import { RequestStatus } from 'src/helpers/reduxReuquest.util';

const AvatarDelete = ({ open, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const deleteUserAvatarRequestStatus = useSelector(
    (state) => state.avatarStore.deleteUserAvatarRequestStatus
  );

  const isPending = deleteUserAvatarRequestStatus === RequestStatus.PENDING;

  return (
    <ConfirmDialog
      variant="error"
      open={open}
      title={t('userConfigurationPage.basic.avatarDelete.header')}
      subtitle={t('userConfigurationPage.basic.avatarDelete.description')}
      applyButtonText={t('userConfigurationPage.basic.avatarDelete.delete')}
      cancelButtonText={t('common.cancel')}
      actionAccept={() => {
        dispatch(avatarActions.deleteUserAvatar(onClose));
      }}
      isLoading={isPending}
      actionCancel={onClose}
    />
  );
};

AvatarDelete.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
};

export default AvatarDelete;
