import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import documentDisplayActions from 'src/redux/actions/documentDisplay.actions';
import { moduleName as documentDslService } from 'src/redux/services/documentDSL.service';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CertificateQuantityType } from 'src/models/certificate.model';
import UsersManagerContextProvider from 'src/components/DocumentUsersManager/subcomponents/UsersManagerContext';
import DocumentUsersManager from 'src/components/DocumentUsersManager/DocumentUsersManager';
import snackbarActions from 'src/redux/actions/snackbar.actions';
import { SNACKBAR_VARIANT } from 'src/models/common.model';
import { DocumentRole } from 'src/models/document.model';
import certificateCreatorActions from 'src/redux/actions/certificateCreator.actions';

const InvitePeople = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const {
    multipleHolders,
    sentForGeneratingList,
    generatedCertFileList,
    certificateQuantityType,
    currentLocation,
  } = useSelector((state) => state.certificateCreatorStore);

  useEffect(() => {
    dispatch(
      documentDisplayActions.getDocument(Object.keys(generatedCertFileList)[0], documentDslService)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      sentForGeneratingList.length === Object.keys(generatedCertFileList).length &&
      Object.keys(generatedCertFileList).length > 0
    ) {
      dispatch(
        snackbarActions.enqueueSnackbar(
          SNACKBAR_VARIANT.SUCCESS,
          t('createCertificate.createSuccessSnackbarTitle')
        )
      );
      if (
        Object.keys(multipleHolders).length > 0 &&
        certificateQuantityType === CertificateQuantityType.Multiple
      ) {
        const roleCollection = sentForGeneratingList.map((sent) => ({
          documentId: sent.documentId,
          role: DocumentRole.HOLDER,
          email: multipleHolders[sent.correlationId],
        }));

        if (roleCollection.length > 0)
          dispatch(
            certificateCreatorActions.inviteMultipleByRole({
              collection: roleCollection,
            })
          );
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <UsersManagerContextProvider>
        <DocumentUsersManager
          showHolders={certificateQuantityType === CertificateQuantityType.Single}
          documentIds={Object.keys(generatedCertFileList)}
          onClose={() => {
            if (Object.keys(generatedCertFileList).length === 1) {
              history.push(`/certificate/${Object.keys(generatedCertFileList)[0]}`);
            } else {
              history.push(
                currentLocation.name === 'Drive' ? '/drive' : `/drive/${currentLocation.id}`
              );
            }
          }}
        />
      </UsersManagerContextProvider>
    </>
  );
};

export default InvitePeople;
