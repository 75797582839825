import snackbarConstants from '../constants/snackbar.constants';

const snackbarActions = {
  enqueueSnackbar,
  closeSnackbar,
  removeSnackbar,
};

function enqueueSnackbar(variant, title, message, params) {
  return (dispatch) => {
    dispatch({
      type: snackbarConstants.ENQUEUE_SNACKBAR,
      notification: { message: JSON.stringify({ variant, title, message, params }) },
      key: new Date().getTime() + Math.random(),
    });
  };
}

function closeSnackbar(key) {
  return (dispatch) => {
    dispatch({
      type: snackbarConstants.CLOSE_SNACKBAR,
      dismissAll: !key, // dismiss all if no key has been defined
      key,
    });
  };
}

function removeSnackbar(key) {
  return (dispatch) => {
    dispatch({ type: snackbarConstants.REMOVE_SNACKBAR, key });
  };
}

export default snackbarActions;
