import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import BillingActions from 'src/redux/actions/billing.actions';
import { userComActions } from 'src/redux/actions/userCom.actions';
import { useDispatch, useSelector } from 'react-redux';
import { IdentitySelector } from 'src/redux/selectors/identity.selector';
import { RootState } from 'src/redux/reducers';
import { RequestStatus } from 'src/helpers/reduxReuquest.util';
import identityActions from 'src/redux/actions/identity.actions';
import { TemplateDocumentType } from 'src/models/documents.model';
import { BillingProductType } from 'src/models/billing.model';

const SubscriptionWatcher = (): null => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const history = useHistory();
  const urlState = new URLSearchParams(search).get('s');
  const { workspaceWorkflows, workspaceWorkflowsStatus } = useSelector(
    (state: RootState) => state.identityStore,
    undefined
  );
  const workspaceId = useSelector(IdentitySelector.selectWorkspaceId);

  // Check if workflows fetch is done and if url state 'joinWorkspace' and send userComAction
  useEffect(() => {
    if (
      workspaceWorkflowsStatus === RequestStatus.SUCCESS &&
      urlState === 'joinWorkspace' &&
      workspaceId
    ) {
      const activeSubscriptions = [];
      if (workspaceWorkflows.includes(TemplateDocumentType.CERTIFICATES)) {
        activeSubscriptions.push(BillingProductType.Certificates);
      }

      if (
        workspaceWorkflows.includes(TemplateDocumentType.DOCUMENT_TO_BE_SIGNED) ||
        workspaceWorkflows.includes(TemplateDocumentType.DURABLE_MEDIA)
      ) {
        activeSubscriptions.push(BillingProductType.EsignDrive);
      }
      userComActions.user.userJoinedWorkspace(activeSubscriptions, workspaceId);
      history.replace({
        search: '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceWorkflowsStatus]);

  // Fetch subscription info
  useEffect(() => {
    if (workspaceId) {
      dispatch(BillingActions.fetchCustomer(workspaceId));
      dispatch(BillingActions.fetchSubscriptions(workspaceId));
      dispatch(identityActions.fetchWorkspaceWorkflows(workspaceId));
    }
  }, [dispatch, workspaceId]);

  return null;
};

export default SubscriptionWatcher;
