import React from 'react';
import { sortableContainer } from 'react-sortable-hoc';
import PropTypes from 'prop-types';
import { List } from '@material-ui/core';

const SortableContainer = sortableContainer(({ children }) => <List>{children}</List>);

SortableContainer.propTypes = {
  children: PropTypes.node,
};

export default SortableContainer;
