import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useMediaQuery } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import {
  ContentContainer,
  Logo,
  MenuNavigationList,
  TopBarContainer,
  HeaderButton,
} from 'src/components';
import { Close as CloseIcon } from 'src/components/CustomIcon';
import { useStyles } from './styles';

const MenuSidebar = ({ className: classNameProp, items, onToggle, onClose }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const smallScreen = useMediaQuery(({ breakpoints }) => breakpoints.down('sm'));
  const location = useLocation();

  const [currentLocation, setCurrentLocation] = useState(location);

  useEffect(() => {
    if (location?.pathname !== currentLocation?.pathname) {
      onClose();
      setCurrentLocation(location);
    }
  }, [currentLocation?.pathname, location, onClose]);

  return (
    <div className={clsx(classes.root, classNameProp)}>
      <TopBarContainer className={classes.header}>
        <Logo size="large" />
        {smallScreen && (
          <HeaderButton
            color="primary"
            className={classes.backButton}
            onClick={onToggle}
            icon={<CloseIcon />}
            tooltip={t('common.closeMenu')}
          />
        )}
      </TopBarContainer>

      <ContentContainer horizontal={false} className={classes.content}>
        <MenuNavigationList items={items} />
      </ContentContainer>
    </div>
  );
};

MenuSidebar.propTypes = {
  className: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  items: PropTypes.arrayOf(PropTypes.object),
  onToggle: PropTypes.func,
  onClose: PropTypes.func,
};

export default MenuSidebar;
