import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import workspaceGroupsActions from 'src/redux/actions/workspaceGroups.actions';
import { ConfirmDialog } from 'src/components';
import { RequestStatus } from 'src/helpers/reduxReuquest.util';

const DeleteGroup = ({ open, workspaceId, group, onClose }) => {
  const { t } = useTranslation();
  const { groupId, name } = group;
  const dispatch = useDispatch();

  const deleteWorkspaceGroupStatus = useSelector(
    (state) => state.workspaceGroupsStore.deleteWorkspaceGroupStatus
  );

  const isPending = deleteWorkspaceGroupStatus === RequestStatus.PENDING;

  return (
    <ConfirmDialog
      variant="error"
      open={open}
      title={t('workspacePage.groups.deleteGroup.header')}
      subtitle={t('workspacePage.groups.deleteGroup.description', { name: name || null })}
      applyButtonText={t('workspacePage.groups.deleteGroup.delete')}
      cancelButtonText={t('common.cancel')}
      actionAccept={() => {
        dispatch(workspaceGroupsActions.deleteWorkspaceGroup(workspaceId, groupId, onClose));
      }}
      isLoading={isPending}
      actionCancel={onClose}
    />
  );
};

DeleteGroup.propTypes = {
  open: PropTypes.bool.isRequired,
  workspaceId: PropTypes.string,
  group: PropTypes.shape({
    groupId: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func,
};

export default DeleteGroup;
