import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, ButtonBase, CircularProgress, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { Preview } from 'src/components/CustomIcon';
import filesModuleService from 'src/redux/services/filesModule.service';
import { useStyles } from './styles';

const CertificateOption = ({ name, token, id, onOptionClick, onPreviewClick }) => {
  const classes = useStyles();
  const [thumbnail, setThumbnail] = useState(undefined);
  const { selectedTemplate } = useSelector((state) => state.certificateCreatorStore, undefined);
  const activeClassName =
    selectedTemplate && selectedTemplate.id === id ? classes.buttonActive : '';

  useEffect(() => {
    if (token) {
      filesModuleService
        .downloadFile(token)
        .then((response) => {
          setThumbnail(window.URL.createObjectURL(response.data));
        })
        .catch((err) => console.log(err));
    }
  }, [token]);

  return (
    <ButtonBase
      onClick={onOptionClick}
      focusRipple
      className={clsx(classes.button, activeClassName)}
    >
      <div className={classes.itemWrapper}>
        <Button
          component="div"
          onClick={onPreviewClick}
          className={classes.itemPreview}
          color="secondary"
        >
          <Preview />
        </Button>

        <div className={classes.thumbnailWrapper}>
          {thumbnail ? (
            <img className={classes.thumbnail} src={thumbnail} alt="certificate thumbnail" />
          ) : (
            <CircularProgress />
          )}
        </div>
        <div className={classes.nameWrapper}>
          <Typography className={classes.name} variant="body2">
            {name}
          </Typography>
        </div>
      </div>
    </ButtonBase>
  );
};
CertificateOption.propTypes = {
  name: PropTypes.string,
  token: PropTypes.string,
  id: PropTypes.string,
  onOptionClick: PropTypes.func,
  onPreviewClick: PropTypes.func,
};

export default CertificateOption;
