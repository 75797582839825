import React from 'react';
import PropTypes from 'prop-types';
import { DialogTitle, Typography, useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ExtendedGrid, HeaderButton } from 'src/components';
import { Close as CloseIcon } from '../CustomIcon';

import { useStyles } from './styles';

const DialogTitleWithButton = ({ onClose, title, titleAlign, titleVariant, icon }) => {
  const { t } = useTranslation();
  const smallScreen = useMediaQuery(({ breakpoints }) => breakpoints.down('sm'));
  const classes = useStyles();

  return (
    <DialogTitle disableTypography className={classes.root}>
      {icon ? (
        <ExtendedGrid container spacingX={2} alignItemsXxs="center" wrapXxs="nowrap">
          <ExtendedGrid item xxs="auto" className={classes.iconWrapper}>
            {icon}
          </ExtendedGrid>

          <ExtendedGrid item xxs>
            <Typography align={titleAlign} variant={titleVariant} className={classes.title}>
              {title}
            </Typography>
          </ExtendedGrid>
        </ExtendedGrid>
      ) : (
        <Typography align={titleAlign} variant={titleVariant} className={classes.title}>
          {title}
        </Typography>
      )}

      <HeaderButton
        color="primary"
        className={classes.closeButton}
        onClick={onClose}
        icon={<CloseIcon />}
        tooltip={smallScreen && t('common.close')}
      />
    </DialogTitle>
  );
};

DialogTitleWithButton.propTypes = {
  onClose: PropTypes.func,
  title: PropTypes.string,
  titleVariant: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'subtitle1',
    'subtitle2',
    'body1',
    'body2',
    'caption',
    'button',
    'overline',
    'inherit',
  ]),
  titleAlign: PropTypes.oneOf(['inherit', 'left', 'center', 'right', 'justify']),
  icon: PropTypes.node,
};

DialogTitleWithButton.defaultProps = {
  titleAlign: 'left',
  titleVariant: 'h2',
};

export default DialogTitleWithButton;
