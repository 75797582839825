import { makeStyles, createStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ breakpoints }) =>
    createStyles({
      centered: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'column',
        width: '100%',
        height: '100%',
      },
      items: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        minHeight: '100%',
        maxWidth: breakpoints.values.sm,
        margin: '0 auto',
        '& > *:not(:last-child)': {
          marginBottom: 14,
        },
      },
    }),
  { index: 0 }
);
