import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  BottomNavigation,
  BottomNavigationAction,
  ListItemIcon,
  Menu,
  MenuItem,
  Slide,
} from '@material-ui/core';
import { MoreHor as MoreIcon } from 'src/components/CustomIcon';
import { useStyles } from './styles';

const TransitionUp = forwardRef((props, ref) => <Slide {...props} ref={ref} direction="up" />);

const AppMenuMobile = forwardRef(
  ({ classes: classesProp, className: classNameProp, items, moreItems }, ref) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const [menuIsAnimating, setMenuIsAnimating] = useState(true);
    const classes = useStyles();
    const onMenuAnimatingToggle = {
      onStart: () => {
        setMenuIsAnimating(false);
      },
      onEnd: () => {
        setMenuIsAnimating(true);
      },
    };

    const isMoreOpen = Boolean(anchorEl);

    const handleMoreClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleMoreClose = () => {
      setAnchorEl(null);
    };

    const itemClasses = {
      root: clsx(classes.item, classes.itemMobile, classesProp && classesProp.item),
      label: classes.itemTextMobile,
    };

    const renderItemIcon = (icon) => (
      <div className={clsx(classes.itemIcon, classesProp && classesProp.itemIcon)}>{icon}</div>
    );

    return (
      <>
        <BottomNavigation
          ref={ref}
          showLabels
          classes={{
            root: clsx(classes.root, !menuIsAnimating && classes.rootOnAnimation, classNameProp),
          }}
        >
          {items?.length > 0 &&
            items.map((item, index) => {
              if (!item.onlyDesktop) {
                const additionalProps = item.url
                  ? {
                      component: NavLink,
                      to: item.url,
                      activeClassName: !isMoreOpen
                        ? clsx(classes.itemActive, classesProp && classesProp.itemActive)
                        : null,
                    }
                  : {};

                return (
                  <BottomNavigationAction
                    key={`${item.label}-${index}`}
                    label={item.label}
                    classes={itemClasses}
                    icon={renderItemIcon(item.icon)}
                    onClick={item.onClick}
                    {...additionalProps}
                  />
                );
              }
              return null;
            })}

          {moreItems?.length > 0 && (
            <BottomNavigationAction
              label={t('common.more')}
              classes={itemClasses}
              className={clsx(
                isMoreOpen && classes.itemActive,
                isMoreOpen && classesProp && classesProp.itemActive
              )}
              icon={renderItemIcon(<MoreIcon />)}
              onClick={handleMoreClick}
            />
          )}
        </BottomNavigation>

        {moreItems?.length > 0 && (
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={isMoreOpen}
            onClose={handleMoreClose}
            getContentAnchorEl={null}
            classes={{
              list: classes.moreMenu,
              paper: classes.moreMenuPaper,
            }}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            TransitionComponent={TransitionUp}
            onEnter={onMenuAnimatingToggle.onStart}
            onEntered={onMenuAnimatingToggle.onEnd}
            onExit={onMenuAnimatingToggle.onStart}
            onExited={onMenuAnimatingToggle.onEnd}
          >
            {moreItems.map((item, index) => {
              const additionalProps = item.url
                ? {
                    component: NavLink,
                    to: item.url,
                    activeClassName: clsx(
                      classes.itemActive,
                      classesProp && classesProp.itemActive
                    ),
                  }
                : {};

              return (
                <MenuItem
                  key={`${item.label}-${index}`}
                  classes={{ root: classes.moreMenuItem }}
                  icon={renderItemIcon(item.icon)}
                  onClick={() => {
                    handleMoreClose();
                    !!item.onClick && item.onClick();
                  }}
                  {...additionalProps}
                >
                  <ListItemIcon
                    classes={{ root: classes.moreMenuItemIconWrapper }}
                    style={{ backgroundColor: item.color }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  {item.label}
                </MenuItem>
              );
            })}
          </Menu>
        )}
      </>
    );
  }
);

AppMenuMobile.propTypes = {
  className: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  moreItems: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default AppMenuMobile;
