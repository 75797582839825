import { AxiosPromise } from 'axios';
import axios from 'src/helpers/axios';
import { parseError } from 'src/helpers/parseErrors';
import { API_MODULE } from 'src/models/common.model';
import { BillingProductType } from '../../models/billing.model';

type fetchSubscriptionsType = {
  workspaceId: string;
};
const fetchSubscriptions = ({ workspaceId }: fetchSubscriptionsType): AxiosPromise =>
  axios
    .get(`/rest/${API_MODULE.BillingService}/workspaces/${workspaceId}/subscription`)
    .then((response) => response)
    .catch((error) => parseError(error));

type setTrialSubscriptionType = {
  workspaceId: string;
  product: BillingProductType;
};
const setTrialSubscription = ({ workspaceId, product }: setTrialSubscriptionType): AxiosPromise =>
  axios
    .post(
      `/rest/${API_MODULE.BillingService}/workspaces/${workspaceId}/subscription/trial?product=${product}`
    )
    .then((response) => response)
    .catch((error) => parseError(error));

type fetchCustomerType = {
  workspaceId: string;
};
const fetchCustomer = ({ workspaceId }: fetchCustomerType): AxiosPromise =>
  axios
    .get(`/rest/${API_MODULE.BillingService}/workspaces/${workspaceId}/customer`)
    .then((response) => response)
    .catch((error) => parseError(error));

type setCustomerType = {
  workspaceId: string;
  workspaceName: string;
};

const setCustomer = ({
  workspaceId,
  workspaceName,
}: setCustomerType): AxiosPromise<{ name: string }> =>
  axios
    .post(`/rest/${API_MODULE.BillingService}/workspaces/${workspaceId}/customer`, {
      name: workspaceName,
    })
    .then((response) => response)
    .catch((error) => parseError(error));

const billingService = { fetchSubscriptions, setTrialSubscription, fetchCustomer, setCustomer };

export default billingService;
