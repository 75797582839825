export default {
  ripple: {
    opacity: 0,
  },
  rippleVisible: {
    opacity: 0.075,
  },
  child: {
    opacity: 1,
  },
  childLeaving: {
    opacity: 0,
  },
  '@keyframes enter': {
    '0%': {
      transform: 'scale(0)',
      opacity: 0.05,
    },
    '100%': {
      transform: 'scale(1)',
      opacity: 0.075,
    },
  },
  '@keyframes exit': {
    '0%': {
      opacity: 0.075,
    },
    '100%': {
      opacity: 0,
    },
  },
};
