enum identityConstants {
  FETCH_STRUCTURE_REQUEST = 'FETCH_STRUCTURE_REQUEST',
  FETCH_STRUCTURE_SUCCESS = 'FETCH_STRUCTURE_SUCCESS',
  FETCH_STRUCTURE_FAILURE = 'FETCH_STRUCTURE_FAILURE',

  FETCH_WORKSPACE_STRUCTURE_REQUEST = 'FETCH_WORKSPACE_STRUCTURE_REQUEST',
  FETCH_WORKSPACE_STRUCTURE_SUCCESS = 'FETCH_WORKSPACE_STRUCTURE_SUCCESS',
  FETCH_WORKSPACE_STRUCTURE_FAILURE = 'FETCH_WORKSPACE_STRUCTURE_FAILURE',

  FETCH_WORKSPACE_GROUPS_STRUCTURE_REQUEST = 'FETCH_WORKSPACE_GROUPS_STRUCTURE_REQUEST',
  FETCH_WORKSPACE_GROUPS_STRUCTURE_SUCCESS = 'FETCH_WORKSPACE_GROUPS_STRUCTURE_SUCCESS',
  FETCH_WORKSPACE_GROUPS_STRUCTURE_FAILURE = 'FETCH_WORKSPACE_GROUPS_STRUCTURE_FAILURE',

  FETCH_WORKSPACE_INFO_REQUEST = 'FETCH_WORKSPACE_INFO_REQUEST',
  FETCH_WORKSPACE_INFO_SUCCESS = 'FETCH_WORKSPACE_INFO_SUCCESS',
  FETCH_WORKSPACE_INFO_FAILURE = 'FETCH_WORKSPACE_INFO_FAILURE',

  UPDATE_WORKSPACE_INFO_REQUEST = 'UPDATE_WORKSPACE_INFO_REQUEST',
  UPDATE_WORKSPACE_INFO_SUCCESS = 'UPDATE_WORKSPACE_INFO_SUCCESS',
  UPDATE_WORKSPACE_INFO_FAILURE = 'UPDATE_WORKSPACE_INFO_FAILURE',

  SET_ACTIVE_IDENTITY = 'SET_ACTIVE_IDENTITY',

  CREATE_NEW_WORKSPACE_REQUEST = 'CREATE_NEW_WORKSPACE_REQUEST',
  CREATE_NEW_WORKSPACE_SUCCESS = 'CREATE_NEW_WORKSPACE_SUCCESS',
  CREATE_NEW_WORKSPACE_FAILURE = 'CREATE_NEW_WORKSPACE_FAILURE',

  SWITCH_CONTACT_EMAIL_REQUEST = 'SWITCH_CONTACT_EMAIL_REQUEST',
  SWITCH_CONTACT_EMAIL_SUCCESS = 'SWITCH_CONTACT_EMAIL_SUCCESS',
  SWITCH_CONTACT_EMAIL_FAILURE = 'SWITCH_CONTACT_EMAIL_FAILURE',

  FETCH_WORKSPACE_USERS_REQUEST = 'FETCH_WORKSPACE_USERS_REQUEST',
  FETCH_WORKSPACE_USERS_SUCCESS = 'FETCH_WORKSPACE_USERS_SUCCESS',
  FETCH_WORKSPACE_USERS_FAILURE = 'FETCH_WORKSPACE_USERS_FAILURE',

  FETCH_WORKSPACE_INVITED_USERS_REQUEST = 'FETCH_WORKSPACE_INVITED_USERS_REQUEST',
  FETCH_WORKSPACE_INVITED_USERS_SUCCESS = 'FETCH_WORKSPACE_INVITED_USERS_SUCCESS',
  FETCH_WORKSPACE_INVITED_USERS_FAILURE = 'FETCH_WORKSPACE_INVITED_USERS_FAILURE',

  FETCH_WORKSPACE_USERS_SEARCH_REQUEST = 'FETCH_WORKSPACE_USERS_SEARCH_REQUEST',
  FETCH_WORKSPACE_USERS_SEARCH_SUCCESS = 'FETCH_WORKSPACE_USERS_SEARCH_SUCCESS',
  FETCH_WORKSPACE_USERS_SEARCH_FAILURE = 'FETCH_WORKSPACE_USERS_SEARCH_FAILURE',

  INVITE_USER_TO_WORKSPACE_REQUEST = 'INVITE_USER_TO_WORKSPACE_REQUEST',
  INVITE_USER_TO_WORKSPACE_SUCCESS = 'INVITE_USER_TO_WORKSPACE_SUCCESS',
  INVITE_USER_TO_WORKSPACE_FAILURE = 'INVITE_USER_TO_WORKSPACE_FAILURE',

  DELETE_USER_FROM_WORKSPACE_REQUEST = 'DELETE_USER_FROM_WORKSPACE_REQUEST',
  DELETE_USER_FROM_WORKSPACE_SUCCESS = 'DELETE_USER_FROM_WORKSPACE_SUCCESS',
  DELETE_USER_FROM_WORKSPACE_FAILURE = 'DELETE_USER_FROM_WORKSPACE_FAILURE',

  DELETE_USER_INVITATION_FROM_WORKSPACE_REQUEST = 'DELETE_USER_INVITATION_FROM_WORKSPACE_REQUEST',
  DELETE_USER_INVITATION_FROM_WORKSPACE_SUCCESS = 'DELETE_USER_INVITATION_FROM_WORKSPACE_SUCCESS',
  DELETE_USER_INVITATION_FROM_WORKSPACE_FAILURE = 'DELETE_USER_INVITATION_FROM_WORKSPACE_FAILURE',

  UPDATE_WORKSPACE_MEMBERS_GROUP = 'UPDATE_WORKSPACE_MEMBERS_GROUP',

  CLEAR_WORKSPACE_USERS_STORE = 'CLEAR_WORKSPACE_USERS_STORE',

  UPDATE_NON_EMPTY_WORKSPACE_ONLY_OWNER = 'UPDATE_NON_EMPTY_WORKSPACE_ONLY_OWNER',

  UPDATE_WORKSPACE_DETAILS_REQUEST = 'UPDATE_WORKSPACE_DETAILS_REQUEST',
  UPDATE_WORKSPACE_DETAILS_SUCCESS = 'UPDATE_WORKSPACE_DETAILS_SUCCESS',
  UPDATE_WORKSPACE_DETAILS_FAILURE = 'UPDATE_WORKSPACE_DETAILS_FAILURE',

  SEND_TRANSACTION_FROM_NEW_DOCUMENT_REQUEST = 'SEND_TRANSACTION_FROM_NEW_DOCUMENT_REQUEST',
  SEND_TRANSACTION_FROM_NEW_DOCUMENT_SUCCESS = 'SEND_TRANSACTION_FROM_NEW_DOCUMENT_SUCCESS',
  SEND_TRANSACTION_FROM_NEW_DOCUMENT_FAILURE = 'SEND_TRANSACTION_FROM_NEW_DOCUMENT_FAILURE',

  SEND_TRANSACTION_FROM_NEW_CERTIFICATE_REQUEST = 'SEND_TRANSACTION_FROM_NEW_CERTIFICATE_REQUEST',
  SEND_TRANSACTION_FROM_NEW_CERTIFICATE_SUCCESS = 'SEND_TRANSACTION_FROM_NEW_CERTIFICATE_SUCCESS',
  SEND_TRANSACTION_FROM_NEW_CERTIFICATE_FAILURE = 'SEND_TRANSACTION_FROM_NEW_CERTIFICATE_FAILURE',

  UPDATE_WORKSPACE_GROUP_AFTER_FETCHING = 'UPDATE_WORKSPACE_GROUP_AFTER_FETCHING',
  DELETE_WORKSPACE_GROUP_AFTER_REMOVING = 'DELETE_WORKSPACE_GROUP_AFTER_REMOVING',

  FETCH_WORKSPACE_PERMISSIONS_REQUEST = 'FETCH_WORKSPACE_PERMISSIONS_REQUEST',
  FETCH_WORKSPACE_PERMISSIONS_SUCCESS = 'FETCH_WORKSPACE_PERMISSIONS_SUCCESS',
  FETCH_WORKSPACE_PERMISSIONS_FAILURE = 'FETCH_WORKSPACE_PERMISSIONS_FAILURE',

  FETCH_WORKSPACE_WORKFLOWS_REQUEST = 'FETCH_WORKSPACE_WORKFLOWS_REQUEST',
  FETCH_WORKSPACE_WORKFLOWS_SUCCESS = 'FETCH_WORKSPACE_WORKFLOWS_SUCCESS',
  FETCH_WORKSPACE_WORKFLOWS_FAILURE = 'FETCH_WORKSPACE_WORKFLOWS_FAILURE',
}

export default identityConstants;
