import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, Typography, createStyles, makeStyles } from '@material-ui/core';
import {
  Delete as DeleteIcon,
  GoogleAuthenticatorColor as GoogleAuthenticatorIcon,
  SmsColor as SmsIcon,
} from 'src/components/CustomIcon';
import { FullScreenDialog } from 'src/components';
import twoFactorConfigActions from 'src/redux/actions/twoFactorConfig.actions';
import authActions from 'src/redux/actions/auth.actions';
import usePrevious from 'src/helpers/hooks/usePrevious';
import { ContentHeading } from 'src/pages/WorkspaceConfiguration/subcomponents';
import { RequestStatus } from 'src/helpers/reduxReuquest.util';
import { MethodItem } from '../../subcomponents';
import {
  GoogleAuthenticatorDone,
  GoogleAuthenticatorStep1,
  GoogleAuthenticatorStep2,
} from './editDialogs';

export const useStyles = makeStyles(
  ({ spacing }) =>
    createStyles({
      typoInTypo: {
        marginLeft: spacing(0.5),
      },
      loader: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      },
    }),
  { index: 0 }
);

const Security = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [editedSubpage, setEditedSubpage] = useState(null);

  const twoFactorActiveMethod = useSelector((state) => state.twoFactorConfig.twoFactorActiveMethod);
  const twoFactorConfig = useSelector((state) => state.twoFactorConfig.twoFactorConfig);
  const getTwoFactorConfigRequestStatus = useSelector(
    (state) => state.twoFactorConfig.getTwoFactorConfigRequestStatus
  );

  const userProfile = useSelector((state) => state.authStore.userProfile);
  const userProfileRequestStatus = useSelector((state) => state.authStore.userProfileRequestStatus);

  const initTwoFactorTypeChangeRequestStatus = useSelector(
    (state) => state.twoFactorConfig.initTwoFactorTypeChangeRequestStatus
  );
  const initTwoFactorMethod = useSelector((state) => state.twoFactorConfig.initTwoFactorMethod);
  const initTwoFactorTypeConfig = useSelector(
    (state) => state.twoFactorConfig.initTwoFactorTypeConfig
  );

  const deleteTwoFactorTypeRequestStatus = useSelector(
    (state) => state.twoFactorConfig.deleteTwoFactorTypeRequestStatus
  );

  const confirmTwoFactorTypeChangeRequestStatus = useSelector(
    (state) => state.twoFactorConfig.confirmTwoFactorTypeChangeRequestStatus
  );

  const prevState = usePrevious({
    initTwoFactorTypeChangeRequestStatus,
    confirmTwoFactorTypeChangeRequestStatus,
    deleteTwoFactorTypeRequestStatus,
  });

  const firstRender = useRef(true);

  // on mount get 2fa config and user profile (number)
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      if (getTwoFactorConfigRequestStatus === RequestStatus.IDLE) {
        dispatch(twoFactorConfigActions.getUserTwoFactorConfig());
      }
      if (userProfileRequestStatus === RequestStatus.IDLE) {
        dispatch(authActions.getUserProfile());
      }
    }
  }, [dispatch, getTwoFactorConfigRequestStatus, userProfileRequestStatus]);

  // after delete and change fetch new config
  useEffect(() => {
    if (
      (prevState?.deleteTwoFactorTypeRequestStatus === RequestStatus.PENDING &&
        deleteTwoFactorTypeRequestStatus === RequestStatus.SUCCESS) ||
      (prevState?.confirmTwoFactorTypeChangeRequestStatus === RequestStatus.PENDING &&
        confirmTwoFactorTypeChangeRequestStatus === RequestStatus.SUCCESS)
    ) {
      dispatch(twoFactorConfigActions.clearStore());
      dispatch(twoFactorConfigActions.getUserTwoFactorConfig());
    }
  }, [
    confirmTwoFactorTypeChangeRequestStatus,
    deleteTwoFactorTypeRequestStatus,
    dispatch,
    prevState?.confirmTwoFactorTypeChangeRequestStatus,
    prevState?.deleteTwoFactorTypeRequestStatus,
  ]);

  // after init show edited subpage
  useEffect(() => {
    if (
      prevState?.initTwoFactorTypeChangeRequestStatus === RequestStatus.PENDING &&
      initTwoFactorTypeChangeRequestStatus === RequestStatus.SUCCESS
    ) {
      if (initTwoFactorMethod === 'GOOGLE') {
        setEditedSubpage('googleAuthenticator1');
      }
    }
  }, [
    initTwoFactorMethod,
    initTwoFactorTypeChangeRequestStatus,
    prevState?.initTwoFactorTypeChangeRequestStatus,
  ]);

  const checkIfTypeConfigured = (type) => {
    const config = twoFactorConfig?.find((config) => config.type === type);
    return !!config;
  };

  const renderButtonText = (type) =>
    checkIfTypeConfigured(type)
      ? t('userConfigurationPage.security.method.setAsDefaultMethod')
      : t('common.configure');

  const changeTwoFactorType = (type) => {
    if (checkIfTypeConfigured(type)) {
      dispatch(twoFactorConfigActions.changeActiveTwoFactorType(type));
    } else {
      dispatch(twoFactorConfigActions.initTwoFactorTypeChange(type));
    }
  };

  const handleBack = () => {
    switch (editedSubpage) {
      case 'googleAuthenticator1':
        setEditedSubpage(null);
        break;
      case 'googleAuthenticator2':
        setEditedSubpage('googleAuthenticator1');
        break;
      default:
        setEditedSubpage(null);
        break;
    }
  };

  const editPages = {
    googleAuthenticator1: {
      title: t('userConfigurationPage.security.method.googleAuthenticator.stepsTitle'),
      Component: (
        <GoogleAuthenticatorStep1
          qrCode={initTwoFactorTypeConfig?.authUrl}
          nextHandle={() => setEditedSubpage('googleAuthenticator2')}
          secret={initTwoFactorTypeConfig?.secret}
        />
      ),
    },
    googleAuthenticator2: {
      title: t('userConfigurationPage.security.method.googleAuthenticator.stepsTitle'),
      Component: (
        <GoogleAuthenticatorStep2 nextHandle={() => setEditedSubpage('googleAuthenticatorDone')} />
      ),
    },
    googleAuthenticatorDone: {
      title: '',
      Component: (
        <GoogleAuthenticatorDone
          nextHandle={() => {
            handleBack();
          }}
        />
      ),
    },
  };

  const googleAuthenticatorActions = [
    {
      icon: <DeleteIcon />,
      name: t('common.delete'),
      onClick: () => dispatch(twoFactorConfigActions.deleteTwoFactorType('GOOGLE')),
    },
  ];

  const renderEditPage = (name) => editPages[name].Component;
  const setTitle = (name) => editPages[name].title;

  const renderSummaryPage = () => (
    <>
      <ContentHeading subtitle={t('userConfigurationPage.security.description')}>
        {t('userConfigurationPage.security.header')}
      </ContentHeading>

      <MethodItem
        icon={<GoogleAuthenticatorIcon />}
        name={t('userConfigurationPage.security.method.googleAuthenticator.title')}
        header={
          twoFactorActiveMethod !== 'GOOGLE' ? (
            t('userConfigurationPage.security.method.googleAuthenticator.description')
          ) : (
            <Typography variant="body2" color="primary" component="span">
              {t('common.configured')}
            </Typography>
          )
        }
        subtitle={
          twoFactorActiveMethod !== 'GOOGLE'
            ? t('userConfigurationPage.security.method.googleAuthenticator.subDescription')
            : t('userConfigurationPage.security.method.googleAuthenticator.descriptionAdded')
        }
        actionButtonText={
          twoFactorActiveMethod === 'GOOGLE'
            ? t('userConfigurationPage.security.method.defaultMethod')
            : renderButtonText('GOOGLE')
        }
        additionalActions={checkIfTypeConfigured('GOOGLE') ? googleAuthenticatorActions : undefined}
        onClick={() => changeTwoFactorType('GOOGLE')}
        disabled={twoFactorActiveMethod === 'GOOGLE'}
      />
      <MethodItem
        icon={<SmsIcon />}
        name={t('userConfigurationPage.security.method.sms.title')}
        header={
          <>
            {userProfile
              ? `${userProfile.phoneNumber.prefix} ${userProfile.phoneNumber.number}`
              : '-'}
            <Typography
              variant="body2"
              color="primary"
              component="span"
              className={classes.typoInTypo}
            >
              ({t('common.verified').toLowerCase()})
            </Typography>
          </>
        }
        subtitle={t('userConfigurationPage.security.method.sms.subDescription')}
        disabled={twoFactorActiveMethod === 'PHONE'}
        actionButtonText={
          twoFactorActiveMethod === 'PHONE'
            ? t('userConfigurationPage.security.method.defaultMethod')
            : t('userConfigurationPage.security.method.setAsDefaultMethod')
        }
        onClick={() => changeTwoFactorType('PHONE')}
      />
    </>
  );

  const loader = () => (
    <div className={classes.loader}>
      <CircularProgress />
    </div>
  );

  const renderContent = () => (twoFactorConfig ? renderSummaryPage() : loader());

  return (
    <>
      {renderContent()}
      <FullScreenDialog
        isOpen={!!editedSubpage}
        onBack={handleBack}
        onClose={handleBack}
        title={editedSubpage ? setTitle(editedSubpage) : null}
      >
        {editedSubpage ? renderEditPage(editedSubpage) : null}
      </FullScreenDialog>
    </>
  );
};

export default Security;
