import transitions from '@material-ui/core/styles/transitions';

export default {
  root: {
    '& svg:not($layer)': {
      transform: 'scale(1.1)',
    },
    '&$checked svg': {
      '&$layer': {
        transform: 'scale(1.1)',
        transition: transitions.create('transform', {
          easing: transitions.easing.easeOut,
          duration: transitions.duration.shortest,
        }),
      },
      '&:not($layer)': {
        transform: 'scale(1.0)',
      },
    },
  },
  layer: {
    transform: 'scale(0)',
    transition: transitions.create('transform', {
      easing: transitions.easing.easeIn,
      duration: transitions.duration.shortest,
    }),
    '& path': {
      d:
        'path("M 12 2 C 6.4771525 2 2 6.4771525 2 12 C 2 17.522847 6.4771525 22 12 22 C 17.522847 22 22 17.522847 22 12 C 22 6.4771525 17.522847 2 12 2 z M 12 7 C 14.761424 7 17 9.2385763 17 12 C 17 14.761424 14.761424 17 12 17 C 9.2385762 17 7 14.761424 7 12 C 7 9.2385763 9.2385762 7 12 7 z")',
    },
  },
  checked: {},
};
