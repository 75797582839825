import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ breakpoints, spacing, palette }) =>
    createStyles({
      apiKeysHeaderWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: spacing(2),
        [breakpoints.up('md')]: {
          marginBottom: spacing(3),
        },
        [breakpoints.up('lg')]: {
          marginBottom: spacing(5),
        },
        [breakpoints.down('xs')]: {
          width: 'calc(100vw  - 32px)',
        },
      },
      dialogTitleWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
      keysTable: {
        marginBottom: spacing(5),
        [breakpoints.down('xs')]: {
          width: 'calc(100vw  - 14px)',
          height: 'calc(100vh  - 150px)',
          overflow: 'scroll',
        },
      },
      tableContent: {
        [breakpoints.down('xs')]: {
          minWidth: 600,
        },
      },
      textField: {
        marginBottom: spacing(3),
      },
      date: {
        paddingRight: spacing(1),
        overflowWrap: 'anywhere',
      },
      clickable: {
        cursor: 'pointer',
        paddingRight: spacing(1),
        overflowWrap: 'anywhere',
        '&:hover': {
          color: palette.info.main,
        },
      },
      tableRow: {
        padding: '18px 0',
        '& svg': {
          color: palette.secondary.main,
        },
        '& div': {
          alignItems: 'center',
          display: 'flex',
        },
        '&:not(:last-child)': {
          borderBottom: `1px solid ${palette.divider}`,
        },
      },
      tableHead: {
        padding: '18px 0',
        color: palette.secondary.dark,
      },
      iconColor: {
        color: palette.secondary.main,
      },
      closeIconColor: {
        color: palette.text.secondary,
      },
      partHighlight: {
        fontWeight: 700,
        color: palette.primary.main,
        textDecoration: 'underline',
      },
      permissionInput: {
        marginBottom: spacing(2),
      },
      chip: {
        marginBottom: spacing(1),
        color: palette.primary.main,
        background: '#f2f7ff',
      },
    }),
  { index: 0 }
);
