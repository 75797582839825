import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { Card, CardHeader } from '@material-ui/core';
import { useSelector } from 'react-redux';
import DocumentUserManagerSelector from 'src/redux/selectors/documentUserManager.selector';

const RightSidebarSkeleton = (): JSX.Element => {
  const signersCount = useSelector(DocumentUserManagerSelector.selectDraftSignersCount);
  const otherRolesCount = useSelector(DocumentUserManagerSelector.selectDraftOtherRolesCount);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', marginTop: 24 }}>
      <Card style={{ marginBottom: 24 }}>
        <Skeleton height={24} width="70%" style={{ marginTop: 12, marginLeft: 8 }} />
        <CardHeader
          style={{ paddingTop: 12, paddingLeft: 8, paddingBottom: 12 }}
          avatar={<Skeleton variant="circle" width={40} height={40} />}
          title={<Skeleton height={10} width="80%" style={{ marginBottom: 6 }} />}
          subheader={<Skeleton height={10} width="40%" />}
        />
        <Skeleton
          variant="rect"
          height={48}
          width="calc(100% - 24px)"
          style={{ marginLeft: 12, marginBottom: 12, borderRadius: 6 }}
        />
        <Skeleton
          variant="rect"
          height={48}
          width="calc(100% - 24px)"
          style={{ marginLeft: 12, marginBottom: 12, borderRadius: 6 }}
        />
      </Card>
      <Card
        style={{ marginBottom: 24, padding: 8, display: 'flex', justifyContent: 'space-between' }}
      >
        <Skeleton variant="rect" height={32} width="32%" style={{ borderRadius: 6 }} />
        <Skeleton variant="rect" height={32} width="32%" style={{ borderRadius: 6 }} />
        <Skeleton variant="rect" height={32} width="32%" style={{ borderRadius: 6 }} />
      </Card>
      <Skeleton
        variant="rect"
        height={18}
        width="32%"
        style={{ borderRadius: 6, marginLeft: 'auto', marginBottom: 24 }}
      />
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 12 }}>
        <Skeleton variant="rect" height={14} width={120} style={{ borderRadius: 6 }} />
        <Skeleton
          variant="rect"
          height={18}
          width={30}
          style={{ borderRadius: 6, marginLeft: 'auto' }}
        />
      </div>
      {Array(signersCount === 0 ? 2 : signersCount)
        .fill(0)
        .map(() => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <CardHeader
              style={{
                paddingTop: 12,
                paddingLeft: 0,
                paddingRight: 12,
                paddingBottom: 12,
                width: '80%',
              }}
              avatar={<Skeleton variant="circle" width={40} height={40} />}
              title={<Skeleton height={10} width="80%" style={{ marginBottom: 6 }} />}
              subheader={<Skeleton height={10} width="40%" />}
            />
            <Skeleton
              variant="rect"
              height={24}
              width="20%"
              style={{ borderRadius: 12, marginLeft: 'auto' }}
            />
          </div>
        ))}
      <Skeleton variant="rect" height={14} width={200} style={{ borderRadius: 6, marginTop: 24 }} />
      {Array(otherRolesCount === 0 ? 3 : otherRolesCount)
        .fill(0)
        .map(() => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <CardHeader
              style={{
                paddingTop: 12,
                paddingLeft: 0,
                paddingRight: 12,
                paddingBottom: 12,
                width: '80%',
              }}
              avatar={<Skeleton variant="circle" width={40} height={40} />}
              title={<Skeleton height={10} width="80%" style={{ marginBottom: 6 }} />}
              subheader={<Skeleton height={10} width="40%" />}
            />
            <Skeleton
              variant="rect"
              height={24}
              width="20%"
              style={{ borderRadius: 12, marginLeft: 'auto' }}
            />
          </div>
        ))}
    </div>
  );
};

export default RightSidebarSkeleton;
