import palette from '../palette';
import typography from '../typography';

export default {
  root: {
    fontSize: typography.subtitle2.fontSize,
    width: 32,
    height: 32,
    '&:disabled': {
      color: palette.text.disabled,
    },
  },
  today: {
    '&:not($selected)': {
      border: `1px solid ${palette.primary.main}`,
      '&:hover, &:focus': {
        borderColor: palette.primary.main,
        color: palette.primary.main,
      },
    },
  },
};
