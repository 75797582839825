import spacing from '../spacing';

export default {
  root: {
    padding: `0 ${spacing(2)}px ${spacing(2)}px`,
    overflowX: 'hidden',
    '&:first-child': {
      paddingTop: spacing(2),
    },
  },
};
