import axios from 'src/helpers/axios';
import { parseError } from 'src/helpers/parseErrors';

const moduleName = 'document-service';

const documentJoinService = {
  getDocumentInvitation,
  acceptDocumentInvitation,
};

function getDocumentInvitation(invitationId, service = moduleName) {
  return axios
    .get(`/rest/${service}/invitations/${invitationId}`)
    .then((response) => response.data)
    .catch((error) => parseError(error));
}

function acceptDocumentInvitation(invitationId, service, data = {}) {
  return axios
    .post(`/rest/${service}/invitations/${invitationId}/accept`, data)
    .then((response) => response.data)
    .catch((error) => parseError(error));
}

export default documentJoinService;
