import { RequestStatus } from 'src/helpers/reduxReuquest.util';
import identityConstants from 'src/redux/constants/identity.constants';
import {
  StructureGroup,
  StructureIdentity,
  StructureItemType,
  StructureWorkspace,
  WorkspaceDetailsInfo,
  WorkspaceInfo,
} from 'src/models/identity.model';
import { TemplateDocumentType } from 'src/models/documents.model';
import { ErrorResponse } from 'src/models/errors.model';
import { deactivatedGroup, updateOnlyOneGroupInState } from './workspaceGroups.reducer';

type WorkspaceGroupItemType = {
  info: StructureGroup;
  members: StructureIdentity[];
};

type IdentityStateType = {
  structure: StructureItemType[] | null; // List of all identities
  structureStatus: RequestStatus; // Status of the structure request
  createWorkspaceStatus: RequestStatus; // Status of the create workspace request
  currentIdentity: StructureItemType | null; // Current identity
  isOwner: boolean | null; // Is the current identity the owner of the workspace
  nonEmptyWorkspaces: StructureItemType[]; // When user tries to delete account, this list contains all non empty workspaces
  sendTransactionFromNewDocumentStatus: RequestStatus; // Status of the send transaction from new document request
  sendTransactionFromNewCertificateStatus: RequestStatus; // Status of the send transaction from new certificate request
  switchContactEmailRequestError: ErrorResponse | null; // Error response when trying to switch contact email
  switchContactEmailRequestStatus: RequestStatus; // Status of the switch contact email request
  updateWorkspaceDetailsStatus: RequestStatus; // Status of the update workspace details request
  updateWorkspaceInfoStatus: RequestStatus; // Status of the update workspace info request
  workspaceDetails: WorkspaceDetailsInfo | null; // Workspace details, details
  workspaceGroups: WorkspaceGroupItemType[]; // List of all groups in the workspace
  workspaceInfo: WorkspaceInfo | null; // Workspace info, details
  workspaceInfoStatus: RequestStatus; // Status of the fetch workspace info request
  workspacePermissions: string[]; // List of all permissions in the workspace
  workspacePermissionsStatus: RequestStatus; // Status of the fetch permissions request
  workspaceWorkflows: TemplateDocumentType[]; // List of all available workflows in the workspace (user has permission)
  workspaceWorkflowsStatus: RequestStatus; // Status of the fetch workflows request
  workspaceWorkflowsErrorCode: string | null; // Error code when trying to fetch workflows
  workspaceStructureStatus: RequestStatus; // Status of the fetch structure request
  workspaceUsers: StructureIdentity[]; // List of all users in the workspace
};

const initialState: IdentityStateType = {
  structure: null,
  structureStatus: RequestStatus.IDLE,
  createWorkspaceStatus: RequestStatus.IDLE,
  currentIdentity: null,
  isOwner: null,
  nonEmptyWorkspaces: [],
  sendTransactionFromNewDocumentStatus: RequestStatus.IDLE,
  sendTransactionFromNewCertificateStatus: RequestStatus.IDLE,
  switchContactEmailRequestError: null,
  switchContactEmailRequestStatus: RequestStatus.IDLE,
  updateWorkspaceDetailsStatus: RequestStatus.IDLE,
  updateWorkspaceInfoStatus: RequestStatus.IDLE,
  workspaceDetails: null,
  workspaceGroups: [],
  workspaceInfo: null,
  workspaceInfoStatus: RequestStatus.IDLE,
  workspacePermissions: [],
  workspacePermissionsStatus: RequestStatus.IDLE,
  workspaceWorkflows: [],
  workspaceWorkflowsStatus: RequestStatus.IDLE,
  workspaceWorkflowsErrorCode: null,
  workspaceStructureStatus: RequestStatus.IDLE,
  workspaceUsers: [],
};

const parseGroupsToUsers = (groups: WorkspaceGroupItemType[]): StructureIdentity[] => {
  const allGroup = groups.find((group) => group?.info?.name === 'All');
  return allGroup?.members ?? [];
};

const updateStructureWorkspace = (
  structure: StructureItemType[] | null,
  newWorkspace: StructureWorkspace,
  targetWorkspaceId: string
) => {
  if (structure === null) return null;

  const newStructure = [...structure];
  for (let i = 0; i < structure.length; i += 1) {
    if (structure[i].workspace.workspaceId === targetWorkspaceId) {
      newStructure[i].workspace = { ...structure[i].workspace, ...newWorkspace };
    }
  }
  return newStructure;
};

const updateStructureIdentityContactMail = (
  structure: StructureItemType[] | null,
  currentIdentity: StructureItemType | null,
  email: string
): StructureItemType[] | null => {
  if (structure === null) return null;
  const newStructure = [...structure];
  const updatedIndex = newStructure.findIndex(
    (user) => user.identity.identityId === currentIdentity?.identity.identityId
  );

  return [
    ...newStructure.slice(0, updatedIndex),
    {
      ...newStructure[updatedIndex],
      identity: {
        ...newStructure[updatedIndex].identity,
        contactEmail: email,
      },
    },
    ...newStructure.slice(updatedIndex + 1),
  ];
};

const isOwner = (currentIdentity: StructureItemType): boolean => {
  if (!currentIdentity) return false;
  const ownersGroup = currentIdentity.groups.find(
    (group) => group.name === 'Owners' && group.active
  );
  return !!ownersGroup;
};

const updateMembersInGroup = (
  state: IdentityStateType,
  groupId: string,
  members: StructureIdentity[]
) =>
  state.workspaceGroups.map((group) => {
    if (group.info.groupId === groupId) {
      group.members = members;
    }
    return group;
  });

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any
function identityStore(state: IdentityStateType = initialState, action: any): IdentityStateType {
  switch (action.type) {
    case identityConstants.FETCH_STRUCTURE_REQUEST:
      return {
        ...state,
        structureStatus: RequestStatus.PENDING,
      };
    case identityConstants.FETCH_STRUCTURE_SUCCESS:
      return {
        ...state,
        structureStatus: RequestStatus.SUCCESS,
        structure: action.structure,
      };
    case identityConstants.FETCH_STRUCTURE_FAILURE:
      return {
        ...state,
        structureStatus: RequestStatus.ERROR,
      };
    case identityConstants.SET_ACTIVE_IDENTITY:
      return {
        ...state,
        currentIdentity: action.identity,
        isOwner: isOwner(action.identity),
      };
    case identityConstants.FETCH_WORKSPACE_STRUCTURE_REQUEST:
      return {
        ...state,
        workspaceStructureStatus: RequestStatus.PENDING,
      };
    case identityConstants.FETCH_WORKSPACE_STRUCTURE_SUCCESS:
      return {
        ...state,
        workspaceStructureStatus: RequestStatus.SUCCESS,
        workspaceDetails: action.info,
        workspaceGroups: action.groups,
        workspaceUsers: parseGroupsToUsers(action.groups),
      };
    case identityConstants.FETCH_WORKSPACE_STRUCTURE_FAILURE:
      return {
        ...state,
        workspaceStructureStatus: RequestStatus.ERROR,
      };
    case identityConstants.FETCH_WORKSPACE_INFO_REQUEST: {
      return {
        ...state,
        workspaceInfoStatus: RequestStatus.PENDING,
      };
    }
    case identityConstants.FETCH_WORKSPACE_INFO_SUCCESS:
      return {
        ...state,
        workspaceInfoStatus: RequestStatus.SUCCESS,
        workspaceInfo: action.workspaceInfo,
      };
    case identityConstants.FETCH_WORKSPACE_INFO_FAILURE:
      return {
        ...state,
        workspaceInfoStatus: RequestStatus.ERROR,
      };
    case identityConstants.UPDATE_WORKSPACE_INFO_REQUEST:
      return {
        ...state,
        updateWorkspaceInfoStatus: RequestStatus.PENDING,
      };
    case identityConstants.UPDATE_WORKSPACE_INFO_SUCCESS:
      return {
        ...state,
        updateWorkspaceInfoStatus: RequestStatus.SUCCESS,
        workspaceInfo: {
          ...state.workspaceInfo,
          ...action.workspaceInfo,
        },
      };
    case identityConstants.UPDATE_WORKSPACE_INFO_FAILURE:
      return {
        ...state,
        updateWorkspaceInfoStatus: RequestStatus.ERROR,
      };
    case identityConstants.CREATE_NEW_WORKSPACE_FAILURE:
      return {
        ...state,
        createWorkspaceStatus: RequestStatus.ERROR,
      };
    case identityConstants.CREATE_NEW_WORKSPACE_SUCCESS:
      return {
        ...state,
        createWorkspaceStatus: RequestStatus.SUCCESS,
      };
    case identityConstants.CREATE_NEW_WORKSPACE_REQUEST:
      return {
        ...state,
        createWorkspaceStatus: RequestStatus.PENDING,
      };
    case identityConstants.SWITCH_CONTACT_EMAIL_REQUEST:
      return {
        ...state,
        switchContactEmailRequestStatus: RequestStatus.PENDING,
      };
    case identityConstants.SWITCH_CONTACT_EMAIL_SUCCESS:
      return {
        ...state,
        switchContactEmailRequestStatus: RequestStatus.SUCCESS,
        currentIdentity: state.currentIdentity
          ? {
              ...state.currentIdentity,
              identity: {
                ...state.currentIdentity.identity,
                contactEmail: action.email,
              },
            }
          : null,
        structure: updateStructureIdentityContactMail(
          state.structure,
          state.currentIdentity,
          action.email
        ),
      };
    case identityConstants.SWITCH_CONTACT_EMAIL_FAILURE:
      return {
        ...state,
        switchContactEmailRequestStatus: RequestStatus.ERROR,
        switchContactEmailRequestError: action.error,
      };
    case identityConstants.UPDATE_WORKSPACE_DETAILS_REQUEST:
      return {
        ...state,
        updateWorkspaceDetailsStatus: RequestStatus.PENDING,
      };
    case identityConstants.UPDATE_WORKSPACE_DETAILS_SUCCESS:
      return {
        ...state,
        structure: state.currentIdentity?.workspace?.workspaceId
          ? updateStructureWorkspace(
              state.structure,
              action.workspaceDetails,
              state.currentIdentity?.workspace?.workspaceId
            )
          : state.structure,
        updateWorkspaceDetailsStatus: RequestStatus.SUCCESS,
        workspaceDetails: {
          ...state.workspaceDetails,
          ...action.workspaceDetails,
        },

        currentIdentity: state.currentIdentity
          ? {
              ...state.currentIdentity,
              workspace: {
                ...state.currentIdentity.workspace,
                ...action.workspaceDetails,
              },
            }
          : state.currentIdentity,
      };
    case identityConstants.UPDATE_WORKSPACE_DETAILS_FAILURE:
      return {
        ...state,
        updateWorkspaceDetailsStatus: RequestStatus.ERROR,
      };
    case identityConstants.SEND_TRANSACTION_FROM_NEW_DOCUMENT_REQUEST:
      return {
        ...state,
        sendTransactionFromNewDocumentStatus: RequestStatus.PENDING,
      };
    case identityConstants.SEND_TRANSACTION_FROM_NEW_DOCUMENT_SUCCESS:
      return {
        ...state,
        sendTransactionFromNewDocumentStatus: RequestStatus.SUCCESS,
      };
    case identityConstants.SEND_TRANSACTION_FROM_NEW_DOCUMENT_FAILURE:
      return {
        ...state,
        sendTransactionFromNewDocumentStatus: RequestStatus.ERROR,
      };
    case identityConstants.SEND_TRANSACTION_FROM_NEW_CERTIFICATE_REQUEST:
      return {
        ...state,
        sendTransactionFromNewCertificateStatus: RequestStatus.PENDING,
      };
    case identityConstants.SEND_TRANSACTION_FROM_NEW_CERTIFICATE_SUCCESS:
      return {
        ...state,
        sendTransactionFromNewCertificateStatus: RequestStatus.SUCCESS,
      };
    case identityConstants.SEND_TRANSACTION_FROM_NEW_CERTIFICATE_FAILURE:
      return {
        ...state,
        sendTransactionFromNewCertificateStatus: RequestStatus.ERROR,
      };
    case identityConstants.FETCH_WORKSPACE_PERMISSIONS_REQUEST:
      return {
        ...state,
        workspacePermissionsStatus: RequestStatus.PENDING,
      };
    case identityConstants.FETCH_WORKSPACE_PERMISSIONS_SUCCESS:
      return {
        ...state,
        workspacePermissionsStatus: RequestStatus.SUCCESS,
        workspacePermissions: action.data,
      };
    case identityConstants.FETCH_WORKSPACE_PERMISSIONS_FAILURE:
      return {
        ...state,
        workspacePermissionsStatus: RequestStatus.ERROR,
      };
    case identityConstants.FETCH_WORKSPACE_WORKFLOWS_REQUEST:
      return {
        ...state,
        workspaceWorkflowsStatus: RequestStatus.PENDING,
      };
    case identityConstants.FETCH_WORKSPACE_WORKFLOWS_SUCCESS:
      return {
        ...state,
        workspaceWorkflowsStatus: RequestStatus.SUCCESS,
        workspaceWorkflows: action.data,
        workspaceWorkflowsErrorCode: null,
      };
    case identityConstants.FETCH_WORKSPACE_WORKFLOWS_FAILURE:
      return {
        ...state,
        workspaceWorkflowsStatus: RequestStatus.ERROR,
        workspaceWorkflowsErrorCode: action.payload,
      };
    case identityConstants.UPDATE_WORKSPACE_GROUP_AFTER_FETCHING:
      return {
        ...state,
        workspaceGroups: updateOnlyOneGroupInState(state.workspaceGroups, action.data),
      };

    case identityConstants.UPDATE_WORKSPACE_MEMBERS_GROUP:
      return {
        ...state,
        workspaceGroups: updateMembersInGroup(state, action.groupId, action.members),
      };

    case identityConstants.DELETE_WORKSPACE_GROUP_AFTER_REMOVING:
      return {
        ...state,
        workspaceGroups: deactivatedGroup(state.workspaceGroups, action.groupId),
      };
    case identityConstants.UPDATE_NON_EMPTY_WORKSPACE_ONLY_OWNER:
      return {
        ...state,
        nonEmptyWorkspaces:
          state.structure?.filter((structure) =>
            action.payload.includes(structure.workspace.workspaceId)
          ) || [],
      };

    default:
      return state;
  }
}

export default identityStore;
