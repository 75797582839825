import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import WebSocketHelper from 'src/helpers/WebSocketHelper';
import snackbarActions from 'src/redux/actions/snackbar.actions';
import { SNACKBAR_VARIANT } from 'src/models/common.model';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import documentsActions from '../../redux/actions/documents.actions';
import usePrevious from '../../helpers/hooks/usePrevious';
import { IdentitySelector } from '../../redux/selectors/identity.selector';

const SearchPageWebsocket = (): JSX.Element | null => {
  const ws = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const workspaceId = useSelector(IdentitySelector.selectWorkspaceId);
  const { t } = useTranslation();
  const prevState = usePrevious({
    workspaceId,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps,@typescript-eslint/no-explicit-any
  const onWebSocketMessage = (message: any) => {
    if (message.type === 'RESULT') {
      switch (message.code) {
        case 'FOLDER_CREATED': {
          dispatch(documentsActions.setSelectedFolders([message.data.result.id]));
          history.push('/drive');

          break;
        }
        default:
          console.warn(`Unknown code [${message.code}]`, message);
          break;
      }
    } else if (message.type === 'ERROR') {
      switch (message.code) {
        default:
          // eslint-disable-next-line no-case-declarations
          const resultCode = message?.data?.result?.code;
          // eslint-disable-next-line no-case-declarations
          const messagePart = resultCode ? `_${resultCode}` : '';

          dispatch(
            snackbarActions.enqueueSnackbar(
              SNACKBAR_VARIANT.ERROR,
              t('snackbars.common.errorTitle'),
              t(`drivePage.action.error.${message.code}${messagePart}`)
            )
          );
          break;
      }
    } else {
      console.warn(`Unknown action type [${message.type}]`, message);
    }
  };

  const subscribe = useCallback(
    (workspaceId: string) => {
      ws.current = WebSocketHelper.subscribe(
        'document-indexing-service',
        `workspaces/${workspaceId}`,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (message: any) => {
          onWebSocketMessage(message);
        }
      );
    },
    [onWebSocketMessage]
  );

  useEffect(() => {
    if (workspaceId && workspaceId !== prevState?.workspaceId) {
      subscribe(workspaceId);
    }
  }, [subscribe, workspaceId, prevState]);

  useEffect(() => {
    if (workspaceId) {
      subscribe(workspaceId);
    }

    return () => {
      WebSocketHelper.unsubscribe(ws.current);
    };
  }, [workspaceId, prevState]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

export default SearchPageWebsocket;
