import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ breakpoints, spacing }) =>
    createStyles({
      root: {},
      searchButton: {
        [breakpoints.up('sm')]: {
          minWidth: 210,
        },
        [breakpoints.down('sm')]: {
          marginBottom: spacing(2),
        },
      },
    }),
  { index: 0 }
);
