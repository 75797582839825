import { fade } from '@material-ui/core/styles';
import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ breakpoints, palette, spacing }) =>
    createStyles({
      root: {
        width: '100%',
        clear: 'both',
      },
      content: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',

        marginLeft: 'auto',
        marginRight: 'auto',
        width: '100%',
      },
      mobileTitle: {
        paddingBottom: spacing(2),
        marginBottom: spacing(1),
        position: 'sticky',
        top: 0,
        left: 0,
        zIndex: 1,
        backgroundColor: palette.background.paper,
      },
      buttons: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        flex: 1,
        maxWidth: breakpoints.values.sm,
        margin: '0 auto',
        '& > *:not(:last-child)': {
          marginBottom: spacing(1),
        },
      },
      actionButton: {
        maxWidth: breakpoints.values.xs,
        margin: '0 auto',
      },
      alertButton: {
        marginTop: spacing(1),
        color: palette.error.contrastText,
        textDecoration: 'none !important',
        border: `1px solid ${fade(palette.error.contrastText, 0.5)}`,
        '&:hover': {
          border: `1px solid ${palette.error.contrastText}`,
          backgroundColor: fade(palette.common.black, palette.action.hoverOpacity),
          '@media (hover: none)': {
            backgroundColor: 'transparent',
          },
        },
      },
      textWrapper: {
        width: 280,
        height: 72,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      },
      textDescription: {
        whiteSpace: 'break-spaces',
        marginTop: 4,
      },
      alert: {
        width: 350,
        margin: '0 auto',
      },
      optionItem: {
        alignItems: 'flex-start',
      },
      optionIcon: {
        marginTop: 6,
      },
    }),
  { index: 0 }
);

export const useSubscriptionModalStyles = makeStyles(
  ({ spacing, palette }) =>
    createStyles({
      content: {
        justifyContent: 'center',
        padding: `${spacing(5, 5, 1, 5)}!important`,
      },
      title: { whiteSpace: 'pre-line', textAlign: 'center', marginBottom: spacing(1) },
      description: {
        whiteSpace: 'pre-line',
        textAlign: 'center',
        marginBottom: spacing(3),
        color: palette.secondary.dark,
      },
      buttons: {
        flexDirection: 'column',
        padding: spacing(1, 9, 2, 9),
      },
      confirmButton: {
        marginBottom: spacing(1),
      },
      certColor: {
        color: palette.common.green,
      },
      esignColor: {
        color: palette.primary.light,
      },
    }),
  { index: 0 }
);
