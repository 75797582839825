import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import workspaceGroupsActions from 'src/redux/actions/workspaceGroups.actions';
import { useTranslation } from 'react-i18next';
import { Button, CircularProgress, TextField, Typography } from '@material-ui/core';
import { SmallContentWithScrollbar } from 'src/components';
import { RequestStatus } from 'src/helpers/reduxReuquest.util';

const RenameGroup = ({ workspaceId, group, onClose }) => {
  const { t } = useTranslation();
  const { groupId, name } = group;
  const defaultValue = name;
  const [value, setValue] = useState(name);

  const dispatch = useDispatch();

  const updateWorkspaceGroupStatus = useSelector(
    (state) => state.workspaceGroupsStore.updateWorkspaceGroupStatus
  );

  const isPending = updateWorkspaceGroupStatus === RequestStatus.PENDING;
  const isDisabled = !value || isPending;

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(
      workspaceGroupsActions.updateWorkspaceGroup(
        workspaceId,
        groupId,
        {
          name: value,
          description: '',
        },
        onClose
      )
    );
  };

  return (
    <SmallContentWithScrollbar
      startContent={
        <Typography>{t('workspacePage.groups.rename.description', { name })}</Typography>
      }
      endContent={
        <Button
          fullWidth
          size="large"
          disabled={isDisabled}
          type="submit"
          endIcon={isPending && <CircularProgress size={20} color="inherit" />}
        >
          {t('workspacePage.groups.rename.apply')}
        </Button>
      }
      hasForm
      formProps={{
        noValidate: true,
        autoComplete: 'off',
        onSubmit: handleSubmit,
      }}
    >
      <TextField
        autoFocus
        fullWidth
        defaultValue={defaultValue}
        placeholder={t('workspacePage.groups.rename.name')}
        onChange={(event) => {
          setValue(event.target.value);
        }}
        disabled={isPending}
      />
    </SmallContentWithScrollbar>
  );
};

RenameGroup.propTypes = {
  workspaceId: PropTypes.string,
  group: PropTypes.shape({
    groupId: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func,
};

export default RenameGroup;
