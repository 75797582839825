import React from 'react';
import { node, string } from 'prop-types';
import { Typography } from '@material-ui/core';
import { useStyles } from './styles';

const InfoSection = ({ title, description, startIcon, endButton, children }) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <div className={classes.icon}>{startIcon}</div>
      <div className={classes.text}>
        {title && (
          <Typography color="textPrimary" variant="body2" className={classes.bold}>
            {title}
          </Typography>
        )}
        {description && <Typography variant="subtitle2">{description}</Typography>}
      </div>
      {children && <div className={classes.content}>{children}</div>}
      <div className={classes.button}>{endButton} </div>
    </div>
  );
};

InfoSection.propTypes = {
  startIcon: node,
  endButton: node,
  children: node,
  title: string,
  description: string,
};

export default InfoSection;
