import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import documentActions from 'src/redux/actions/documents.actions';
import { Autocomplete } from '@material-ui/lab';
import {
  AppBar,
  CircularProgress,
  Dialog,
  Fab,
  TextField,
  Theme,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import {
  AutocompleteRenderGroupParams,
  AutocompleteRenderInputParams,
  AutocompleteInputChangeReason,
} from '@material-ui/lab/Autocomplete/Autocomplete';
import { HeaderButton, TopBarContainer } from 'src/components';
import { RequestStatus } from 'src/helpers/reduxReuquest.util';
import {
  ArrowLeftSLine as BackIcon,
  Search as SearchIcon,
  Slider as AdvancedIcon,
  Close as CloseIcon,
} from 'src/components/CustomIcon';
import { FileColsConfig, FolderColsConfig } from 'src/helpers/fileAndFolderColsConfig';
import { RootState } from 'src/redux/reducers';
import { IdentitySelector } from 'src/redux/selectors/identity.selector';
import { DocumentsSelector } from 'src/redux/selectors/documents.selector';
import {
  AdvancedSearchFields,
  DocumentItemCategoryType,
  SearchedItem,
} from 'src/models/documents.model';
import DataTableHeader from '../DataTable/DataTableHeader';
import { HeaderSearchItem } from './subcomponents';
import { useStyles } from './styles';
import AdvancedSearchModal from '../AdvancedSearchModal/AdvancedSearchModal';
import { advancedSearchInitialState } from '../../redux/reducers/documents.reducer';

type HeaderSearchProps = {
  showOpenButton?: boolean;
  iconWidth?: number | 'auto';
};

const HeaderSearch = ({
  showOpenButton = true,
  iconWidth = 'auto',
}: HeaderSearchProps): JSX.Element | null => {
  const classes = useStyles();
  const { palette } = useTheme();
  const { t } = useTranslation();
  const history = useHistory();
  const isSearchPage = history.location.pathname.includes('search');
  const dispatch = useDispatch();
  const smallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSearchActive, setSearchActive] = useState(false);
  const [isAdvancedSettingsModalOpen, setIsAdvancedSettingsModalOpen] = useState(false);
  const toggleSearchActive = () => {
    if (smallScreen) {
      setSearchActive(!isSearchActive);
    } else {
      setSearchActive(false);
    }
  };
  const [searchTerm, setSearchTerm] = useState<string>('');
  const { foldersSearchFetchStatus, documentsSearchFetchStatus, advancedSearch } = useSelector(
    (state: RootState) => state.documentsStore
  );
  const searchValue = isSearchPage ? advancedSearch?.[AdvancedSearchFields.NAME] : searchTerm;
  const foldersColConfig = FolderColsConfig(t);
  const filesColConfig = FileColsConfig(t);
  const workspaceId = useSelector(IdentitySelector.selectWorkspaceId);
  const searchedList = useSelector(DocumentsSelector.selectSearchedList);
  const isOpenDropdown = open && !isSearchPage && searchTerm.length > 0;
  const isSearchEdited =
    JSON.stringify(advancedSearch) !== JSON.stringify(advancedSearchInitialState);
  const toggleAdvanceSearchOpen = () => setIsAdvancedSettingsModalOpen((prevState) => !prevState);

  useEffect(() => {
    if (searchTerm.length > 0) {
      setLoading(true);
    } else {
      setLoading(false);
      setOpen(false);
    }
    if (searchTerm.length > 0) {
      dispatch(documentActions.getDocumentsBySearchQuery(workspaceId, searchTerm));
      dispatch(documentActions.getFoldersBySearchQuery(workspaceId, searchTerm));
    }
  }, [searchTerm, workspaceId, dispatch]);

  useEffect(() => {
    if (
      documentsSearchFetchStatus === RequestStatus.PENDING ||
      foldersSearchFetchStatus === RequestStatus.PENDING
    ) {
      setOpen(true);
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [documentsSearchFetchStatus, foldersSearchFetchStatus]);

  useEffect(() => {
    if (loading) {
      setOpen(true);
    }
  }, [loading]);

  // Reset search on route change
  useEffect(() => {
    setSearchTerm('');
    dispatch(documentActions.clearSearched());
    setOpen(false);
  }, [history.location, dispatch]);

  const onInputChange = (
    event: React.ChangeEvent<HTMLSelectElement | unknown>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    if (isSearchPage) {
      dispatch(documentActions.updateAdvancedSearch(AdvancedSearchFields.NAME, value));
      dispatch(documentActions.updateDraftAdvancedSearch(AdvancedSearchFields.NAME, value));
    } else {
      setSearchTerm(value);
      dispatch(documentActions.updateDraftAdvancedSearch(AdvancedSearchFields.NAME, value));
    }
    if (reason === 'clear') {
      dispatch(documentActions.clearSearched());
    }
  };

  const onAutocompleteSelect = (
    event: React.ChangeEvent<HTMLSelectElement | unknown>,
    newValue: SearchedItem | string | null
  ) => {
    if (newValue && typeof newValue !== 'string') {
      if (newValue.documentType) {
        history.push(
          `/${newValue?.documentType.includes('dsl') ? 'certificate' : 'document'}/${newValue?.id}`
        );
      } else {
        history.push(`/drive/${newValue?.id}`);
      }
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getRowParams = (type: string) => {
    switch (type) {
      case DocumentItemCategoryType.FOLDERS:
        return {
          title: t('drivePage.foldersTable.title'),
          cols: foldersColConfig,
          icon: 'folder',
        };

      default:
      case DocumentItemCategoryType.DOCUMENTS:
        return {
          title: t('drivePage.documentsTable.title'),
          cols: filesColConfig,
          icon: 'file',
        };
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const renderGroup = (params: AutocompleteRenderGroupParams) => {
    const groupParams = getRowParams(params.group);

    return [
      <DataTableHeader
        key={params.key}
        className={clsx(classes.groupHeader, smallScreen && classes.smallScreenGroupHeader)}
        title={groupParams.title}
        cols={groupParams.cols}
        titleVariant="h6"
        mediumTitleVariant="subtitle2"
        withTableHeader
        iconWidth={iconWidth}
        disableTitle={false}
        smallScreen={smallScreen}
        rightSpace={false}
      />,
      params.children,
    ];
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const renderOption = (option: SearchedItem) => {
    const groupParams = getRowParams(option.category);

    return (
      <HeaderSearchItem
        item={option}
        smallScreen={smallScreen}
        cols={groupParams.cols}
        iconWidth={iconWidth}
        defaultIcon={groupParams.icon}
      />
    );
  };

  const renderInput = (params: AutocompleteRenderInputParams) => (
    <TextField
      {...params}
      id="search-input"
      placeholder={t('header.searchItems')}
      fullWidth
      variant={smallScreen ? 'standard' : 'outlined'}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
          dispatch(documentActions.updateAdvancedSearch(AdvancedSearchFields.NAME, searchValue));
          dispatch(
            documentActions.updateDraftAdvancedSearch(AdvancedSearchFields.NAME, searchValue)
          );
          history.push('/search');
        }
      }}
      onFocus={() => {
        setOpen(true);
      }}
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <>
            {loading && <CircularProgress style={{ marginRight: 16 }} size={18} />}
            {searchValue.length > 0 && params.InputProps.endAdornment}
            {!smallScreen && (
              <Tooltip title={t<string>('advancedSearch.title')}>
                <Fab
                  size="small"
                  onMouseDown={(e) => {
                    e.preventDefault();
                    toggleAdvanceSearchOpen();
                  }}
                >
                  <AdvancedIcon style={{ color: isSearchEdited ? palette.primary.main : '' }} />
                </Fab>
              </Tooltip>
            )}
          </>
        ),
        startAdornment: (
          <>
            <Fab size="small">
              <SearchIcon />
            </Fab>
            {smallScreen && (
              <HeaderButton
                onClick={toggleSearchActive}
                icon={<BackIcon />}
                className={classes.backButton}
                tooltip={t<string>('common.back')}
              />
            )}
          </>
        ),
      }}
    />
  );
  const renderAutocomplete = () => (
    <Autocomplete
      inputValue={searchValue}
      classes={{
        inputRoot: clsx(classes.inputRoot, smallScreen && classes.smallScreenInputRoot),
        input: clsx(
          smallScreen && classes.smallScreenInput,
          isSearchEdited && classes.searchEdited
        ),
        popper: clsx(smallScreen && classes.smallScreenPopper),
        popperDisablePortal: clsx(
          classes.popperDisablePortal,
          smallScreen && classes.smallScreenPopperDisablePortal
        ),
        paper: clsx(smallScreen && classes.smallScreenPaper),
        listbox: clsx(classes.listBox, smallScreen && classes.smallScreenListBox),
        noOptions: clsx(smallScreen && classes.smallScreenNoOptionsAndLoading),
        loading: clsx(smallScreen && classes.smallScreenNoOptionsAndLoading),
        endAdornment: clsx(classes.endAdornment, smallScreen && classes.smallScreenEndAdornment),
        clearIndicator: clsx(
          classes.clearIndicator,
          smallScreen && classes.smallScreenClearIndicator
        ),
        option: classes.option,
      }}
      clearOnBlur={false}
      disableClearable={false}
      open={isOpenDropdown}
      options={searchedList}
      loading={loading}
      onClose={() => {
        setOpen(false);
      }}
      groupBy={(option) => option.category}
      getOptionLabel={(option) => option.name}
      onChange={onAutocompleteSelect}
      onInputChange={onInputChange}
      renderGroup={renderGroup}
      renderOption={renderOption}
      renderInput={renderInput}
      closeIcon={<CloseIcon />}
      fullWidth
      disablePortal
      loadingText={t<string>('common.loading')}
      clearText={t('common.clear')}
      noOptionsText={t<string>('common.noResults')}
    />
  );

  return (
    <div className={clsx(classes.root, smallScreen && classes.smallScreenRoot)}>
      {smallScreen ? (
        <>
          <Dialog open={isSearchActive} fullScreen>
            <AppBar position="relative" className={classes.appBar}>
              <TopBarContainer horizontal={false}>{renderAutocomplete()}</TopBarContainer>
            </AppBar>
          </Dialog>

          {showOpenButton && (
            <HeaderButton
              onClick={toggleSearchActive}
              icon={<SearchIcon />}
              tooltip={t<string>('common.search')}
            />
          )}
        </>
      ) : (
        renderAutocomplete()
      )}
      {isAdvancedSettingsModalOpen && <AdvancedSearchModal onClose={toggleAdvanceSearchOpen} />}
    </div>
  );
};

export default HeaderSearch;
