import { RequestStatus } from 'src/helpers/reduxReuquest.util';
import twoFactorConstants from '../constants/twoFactor.constants';

const initialState = {
  modalMode: 'initial',
  requestData: null,
  twoFactorActive: false,
  twoFactorInitError: null,
  twoFactorInitStatus: RequestStatus.IDLE,
  twoFactorInitTimestamp: null,
  twoFactorMethod: null,
  twoFactorReSendStatus: RequestStatus.IDLE,
  twoFactorSendStatus: RequestStatus.IDLE,
  twoFactorToken: null,
};

export default function twoFactor(state = initialState, action) {
  switch (action.type) {
    case twoFactorConstants.TWO_FACTOR_INIT:
      return {
        ...state,
        twoFactorActive: true,
        requestData: action.requestData,
        modalMode: action.mode,
        twoFactorInitTimestamp: Date.now(),
        twoFactorSendStatus:
          action.is2faError === '2FA_CODE_ERROR' ? RequestStatus.ERROR : RequestStatus.IDLE,
      };
    case twoFactorConstants.TWO_FACTOR_REQUEST:
      return {
        ...state,
        twoFactorSendStatus: RequestStatus.PENDING,
      };
    case twoFactorConstants.TWO_FACTOR_SUCCESS:
      return {
        ...state,
        twoFactorSendStatus: RequestStatus.SUCCESS,
        twoFactorToken: action.response.data.data.twoFactorToken,
      };
    case twoFactorConstants.TWO_FACTOR_ERROR:
      return {
        ...state,
        twoFactorSendStatus: RequestStatus.ERROR,
        twoFactorError: action.error,
      };
    case twoFactorConstants.TWO_FACTOR_RESEND_REQUEST:
      return {
        ...state,
        twoFactorReSendStatus: RequestStatus.PENDING,
      };
    case twoFactorConstants.TWO_FACTOR_RESEND_SUCCESS:
      return {
        ...state,
        twoFactorReSendStatus: RequestStatus.SUCCESS,
        twoFactorInitTimestamp: Date.now(),
      };
    case twoFactorConstants.TWO_FACTOR_RESEND_ERROR:
      return {
        ...state,
        twoFactorReSendStatus: RequestStatus.ERROR,
      };
    case twoFactorConstants.TWO_FACTOR_CLOSE:
      return {
        ...initialState,
      };

    case twoFactorConstants.TWO_FACTOR_INIT_SESSION_REQUEST:
      return {
        ...state,
        twoFactorInitStatus: RequestStatus.PENDING,
        twoFactorInitError: null,
      };

    case twoFactorConstants.TWO_FACTOR_INIT_SESSION_SUCCESS:
      return {
        ...state,
        twoFactorToken: action.response.data,
        twoFactorMethod: action.response.data.type,
        twoFactorInitStatus: RequestStatus.SUCCESS,
        twoFactorInitTimestamp: Date.now(),
        twoFactorInitError: null,
      };
    case twoFactorConstants.TWO_FACTOR_INIT_SESSION_ERROR:
      return {
        ...state,
        twoFactorInitStatus: RequestStatus.ERROR,
        twoFactorInitError: action.error,
      };
    default:
      return state;
  }
}
