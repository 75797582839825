import React from 'react';
import PropTypes from 'prop-types';
import { SmallContentWithScrollbar, AvatarUpload } from 'src/components';

const AvatarEdit = (props) => {
  const { updateBasicData, isLoading } = props;

  const handleUpdate = (value) => {
    updateBasicData('avatar', value);
  };

  return (
    <SmallContentWithScrollbar>
      <AvatarUpload handleUpdate={handleUpdate} isLoading={isLoading} />
    </SmallContentWithScrollbar>
  );
};

AvatarEdit.propTypes = {
  updateBasicData: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default AvatarEdit;
