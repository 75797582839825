enum documentsConstants {
  FETCH_FOLDER_TREE_REQUEST = 'FETCH_FOLDER_TREE_REQUEST',
  FETCH_FOLDER_TREE_SUCCESS = 'FETCH_FOLDER_TREE_SUCCESS',
  FETCH_FOLDER_TREE_FAILURE = 'FETCH_FOLDER_TREE_FAILURE',

  FETCH_FOLDER_PATH_REQUEST = 'FETCH_FOLDER_PATH_REQUEST',
  FETCH_FOLDER_PATH_SUCCESS = 'FETCH_FOLDER_PATH_SUCCESS',
  FETCH_FOLDER_PATH_FAILURE = 'FETCH_FOLDER_PATH_FAILURE',

  RENAME_FOLDER_REQUEST = 'RENAME_FOLDER_REQUEST',
  RENAME_FOLDER_SUCCESS = 'RENAME_FOLDER_SUCCESS',
  RENAME_FOLDER_FAILURE = 'RENAME_FOLDER_FAILURE',

  CREATE_FOLDER_REQUEST = 'CREATE_FOLDER_REQUEST',
  CREATE_FOLDER_SUCCESS = 'CREATE_FOLDER_SUCCESS',
  CREATE_FOLDER_FAILURE = 'CREATE_FOLDER_FAILURE',

  REMOVE_FOLDER_REQUEST = 'REMOVE_FOLDER_REQUEST',
  REMOVE_FOLDER_SUCCESS = 'REMOVE_FOLDER_SUCCESS',
  REMOVE_FOLDER_FAILURE = 'REMOVE_FOLDER_FAILURE',

  MOVE_DOCUMENT_REQUEST = 'MOVE_DOCUMENT_REQUEST',
  MOVE_DOCUMENT_SUCCESS = 'MOVE_DOCUMENT_SUCCESS',
  MOVE_DOCUMENT_FAILURE = 'MOVE_DOCUMENT_FAILURE',

  FETCH_DOCUMENTS_REQUEST = 'FETCH_DOCUMENTS_REQUEST',
  FETCH_DOCUMENTS_SUCCESS = 'FETCH_DOCUMENTS_SUCCESS',
  FETCH_DOCUMENTS_FAILURE = 'FETCH_DOCUMENTS_FAILURE',

  FETCH_DOCUMENTS_ADVANCED_SEARCH_REQUEST = 'documents/FETCH_DOCUMENTS_ADVANCED_SEARCH_REQUEST',
  FETCH_DOCUMENTS_ADVANCED_SEARCH_SUCCESS = 'documents/FETCH_DOCUMENTS_ADVANCED_SEARCH_SUCCESS',
  FETCH_DOCUMENTS_ADVANCED_SEARCH_FAILURE = 'documents/FETCH_DOCUMENTS_ADVANCED_SEARCH_FAILURE',

  FETCH_FOLDERS_ADVANCED_SEARCH_REQUEST = 'documents/FETCH_FOLDERS_ADVANCED_SEARCH_REQUEST',
  FETCH_FOLDERS_ADVANCED_SEARCH_SUCCESS = 'documents/FETCH_FOLDERS_ADVANCED_SEARCH_SUCCESS',
  FETCH_FOLDERS_ADVANCED_SEARCH_FAILURE = 'documents/FETCH_FOLDERS_ADVANCED_SEARCH_FAILURE',

  FETCH_DOCUMENTS_SEARCH_REQUEST = 'FETCH_DOCUMENTS_SEARCH_REQUEST',
  FETCH_DOCUMENTS_SEARCH_SUCCESS = 'FETCH_DOCUMENTS_SEARCH_SUCCESS',
  FETCH_DOCUMENTS_SEARCH_FAILURE = 'FETCH_DOCUMENTS_SEARCH_FAILURE',

  FETCH_FOLDERS_SEARCH_REQUEST = 'FETCH_FOLDERS_SEARCH_REQUEST',
  FETCH_FOLDERS_SEARCH_SUCCESS = 'FETCH_FOLDERS_SEARCH_SUCCESS',
  FETCH_FOLDERS_SEARCH_FAILURE = 'FETCH_FOLDERS_SEARCH_FAILURE',

  CLEAR_FOLDER_PATH = 'CLEAR_FOLDER_PATH',

  CLEAR_STORE = 'CLEAR_STORE',
  CLEAR_SEARCH = 'CLEAR_SEARCH',
  CLEAR_ADVANCED_SEARCH = 'documents/CLEAR_ADVANCED_SEARCH',

  WS_ADD_FOLDER = 'WS_ADD_FOLDER',
  WS_UPDATE_FOLDER = 'WS_UPDATE_FOLDER',
  WS_DELETE_FOLDER = 'WS_DELETE_FOLDER',
  WS_MOVED_DOCUMENT = 'WS_MOVED_DOCUMENT',
  WS_CREATED_DOCUMENT = 'WS_CREATED_DOCUMENT',
  WS_UPDATED_DOCUMENT = 'WS_UPDATED_DOCUMENT',
  WS_DELETE_DOCUMENT = 'WS_DELETE_DOCUMENT',

  SUBMIT_ADVANCED_SEARCH = 'documents/SUBMIT_ADVANCED_SEARCH',
  UPDATE_DRAFT_ADVANCED_SEARCH = 'documents/UPDATE_DRAFT_ADVANCED_SEARCH',
  UPDATE_ADVANCED_SEARCH = 'documents/UPDATE_ADVANCED_SEARCH',
  RESET_FIELD_DRAFT_ADVANCED_SEARCH = 'documents/RESET_FIELD_DRAFT_ADVANCED_SEARCH',
  RESET_DRAFT_ADVANCED_SEARCH = 'documents/RESET_DRAFT_ADVANCED_SEARCH',
  SET_SELECTED_FOLDERS = 'documents/SET_SELECTED_FOLDERS',
}

export default documentsConstants;
