import { combineReducers } from 'redux';

import authStore from './auth.reducer';
import avatarStore from './avatar.reducer';
import billingStore from './billing.reducer';
import communicationsStore from './communications.reducer';
import contactsStore from './contacts.reducer';
import documentCreationStore from './documentCreation.reducer';
import documentDisplayStore from './documentDisplay.reducer';
import documentDownloadStore from './documentDownload.reducer';
import documentInvitationsStore from './documentInvitiations.reducer';
import documentJoinStore from './documentJoin.reducer';
import documentManagementStore from './documentManagement.reducer';
import documentsStore from './documents.reducer';
import identityStore from './identity.reducer';
import snackbarStore from './snackbar.reducer';
import twoFactor from './twoFactor.reducer';
import twoFactorConfig from './twoFactorConfig.reducer';
import workspaceGroupsStore from './workspaceGroups.reducer';
import workspaceUserStore from './workspaceUser.reducer';
import certificateCreatorStore from './certificateCreator.reducer';
import documentUsersManagerStore from './documentUsersManager.reducer';

export const rootReducer = combineReducers({
  authStore,
  avatarStore,
  billingStore,
  communicationsStore,
  contactsStore,
  documentCreationStore,
  documentDisplayStore,
  documentDownloadStore,
  documentInvitationsStore,
  documentJoinStore,
  documentManagementStore,
  documentUsersManagerStore,
  documentsStore,
  identityStore,
  snackbarStore,
  twoFactor,
  twoFactorConfig,
  workspaceGroupsStore,
  workspaceUserStore,
  certificateCreatorStore,
});

export type RootState = ReturnType<typeof rootReducer>;
