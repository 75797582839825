export const EVENT_TYPES = {
  OPEN_CERTIFICATE_ERROR_MODAL: 'OPEN_CERTIFICATE_ERROR_MODAL',
};

// Progress is a string that might be injected into error message
// We listen on this event in modal component
export function dispatchOpenCertificateErrorModalEvent(progress) {
  const event = new CustomEvent(EVENT_TYPES.OPEN_CERTIFICATE_ERROR_MODAL, {
    bubbles: true,
    detail: {
      progress,
    },
  });

  document.dispatchEvent(event);
}
