import spacing from '../spacing';
import typography from '../typography';

export default {
  root: {
    borderRadius: spacing(0.5),
    fontWeight: typography.fontWeightRegular,
  },
  filledSuccess: {
    fontWeight: typography.fontWeightRegular,
  },
  filledInfo: {
    fontWeight: typography.fontWeightRegular,
  },
  filledWarning: {
    fontWeight: typography.fontWeightRegular,
  },
  filledError: {
    fontWeight: typography.fontWeightRegular,
  },
  icon: {
    padding: '8px 0',
    fontSize: 21,
    '& > svg': {
      fontSize: 'inherit',
    },
  },
  action: {
    display: 'block',
    marginRight: -9,
    '& button': {
      margin: '3px 0 0 0 !important',
      color: 'inherit',
    },
  },
  message: {
    fontSize: 14,
  },
};
