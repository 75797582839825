import spacing from '../spacing';

export default {
  root: {
    position: 'relative',
    fontSize: 12,
    marginTop: spacing(0.75),
    lineHeight: 1.2,
  },
  contained: {
    marginLeft: 0,
    marginRight: 0,
  },
};
