import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import { Button, Theme, useMediaQuery } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import { IdentitySelector } from '../redux/selectors/identity.selector';
import {
  BillingProductStatus,
  BillingProductType,
  checkSubscription,
} from '../models/billing.model';
import { RootState } from '../redux/reducers';
import { useStyles } from './styles';
import { userComActions, SubscriptionChangeType } from '../redux/actions/userCom.actions';

const SubscriptionAlert = (): JSX.Element | null => {
  const { t } = useTranslation();
  const classes = useStyles();
  const workspaceId = useSelector(IdentitySelector.selectWorkspaceId);
  const { subscriptions } = useSelector((state: RootState) => state.billingStore);
  const subscriptionAlertStorage = localStorage.getItem('subscriptionAlert');
  const [hasStorageSubscription, setHasStorageSubscription] = useState(
    Boolean(subscriptionAlertStorage)
  );
  const esignProduct = subscriptions.find((el) => el.productType === BillingProductType.EsignDrive);
  const certProduct = subscriptions.find(
    (el) => el.productType === BillingProductType.Certificates
  );
  const isTrialEsign = esignProduct && BillingProductStatus.Trialing === esignProduct.status;
  const isTrialCertificate = certProduct && BillingProductStatus.Trialing === certProduct.status;
  const smallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const {
    daysLeft: esignDaysLeft,
    alertLevel: esignAlertLevel,
    showProductAlert: esignShowAlert,
  } = checkSubscription(esignProduct);
  const {
    daysLeft: certDaysLeft,
    alertLevel: certAlertLevel,
    showProductAlert: certShowAlert,
  } = checkSubscription(certProduct);

  const onContactSales = (): void => {
    if (!workspaceId) return;
    if (esignProduct) {
      userComActions.subscription.subChangesNeeded(
        BillingProductType.EsignDrive,
        isTrialEsign ? SubscriptionChangeType.PurchaseDuringTrial : SubscriptionChangeType.Renewal,
        workspaceId
      );
    }
    if (certProduct) {
      userComActions.subscription.subChangesNeeded(
        BillingProductType.Certificates,
        isTrialCertificate
          ? SubscriptionChangeType.PurchaseDuringTrial
          : SubscriptionChangeType.Renewal,
        workspaceId
      );
    }
  };

  const getSeverenity = (): 'info' | 'warning' => {
    const lvls = [];
    if (esignProduct) lvls.push(esignAlertLevel);
    if (certProduct) lvls.push(certAlertLevel);
    return lvls.includes('warning') ? 'warning' : 'info';
  };

  // if subscription alert storage is not found, set it to empty object.
  // if subscription alert storage is found, assign alert level for key = workspaceId.
  // update local state = close alert
  const onAlertClose = () => {
    setHasStorageSubscription(true);
    const storage = subscriptionAlertStorage ? JSON.parse(subscriptionAlertStorage) : {};
    if (workspaceId)
      localStorage.setItem(
        'subscriptionAlert',
        JSON.stringify({
          ...storage,
          [workspaceId]: { esignLevelStorage: esignAlertLevel, certLevelStorage: certAlertLevel },
        })
      );
  };

  // if subscription alert storage is found and products are active
  // parse subscription alert storage
  // get alert level for key = workspaceId
  // if alert from storage is different from current product alert then remove from storage per key
  // update local state = open alert
  useEffect(() => {
    if (workspaceId && subscriptionAlertStorage && (esignProduct || certProduct)) {
      const storage = JSON.parse(subscriptionAlertStorage);
      const esignLevelStorage = storage[workspaceId]?.esignLevelStorage;
      const certLevelStorage = storage[workspaceId]?.certLevelStorage;

      if (esignAlertLevel !== esignLevelStorage || certAlertLevel !== certLevelStorage) {
        delete storage[workspaceId];
        localStorage.setItem('subscriptionAlert', JSON.stringify(storage));
        setHasStorageSubscription(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionAlertStorage, esignAlertLevel, certAlertLevel]);

  // if subscription alert storage exists or products are not active then don't show alert
  if (hasStorageSubscription || (!esignShowAlert && !certShowAlert)) return null;

  return (
    <Alert className={classes.alertWrapper} severity={getSeverenity()} onClose={onAlertClose}>
      <span>
        {esignShowAlert && (
          <>
            <Trans
              values={{
                count: esignDaysLeft,
                prefix: isTrialEsign
                  ? t<string>('subscriptionAlert.prefixTrial')
                  : t<string>('subscriptionAlert.prefixSub'),
                product: t<string>('subscriptionAlert.drive'),
                subscriptionType: isTrialEsign
                  ? t<string>('subscriptionAlert.trial')
                  : t<string>('subscriptionAlert.subscription'),
              }}
              i18nKey={
                esignDaysLeft === 1 ? 'subscriptionAlert.text' : 'subscriptionAlert.textPlural'
              }
              components={{ strong: <strong /> }}
            />
          </>
        )}
        {esignShowAlert && certShowAlert && t<string>('subscriptionAlert.and')}
        {certShowAlert && (
          <>
            <Trans
              values={{
                count: certDaysLeft,
                prefix: isTrialCertificate
                  ? t<string>('subscriptionAlert.prefixTrial')
                  : t<string>('subscriptionAlert.prefixSub'),
                product: t<string>('subscriptionAlert.certificates'),
                subscriptionType: isTrialCertificate
                  ? t<string>('subscriptionAlert.trial')
                  : t<string>('subscriptionAlert.subscription'),
              }}
              i18nKey={
                certDaysLeft === 1 ? 'subscriptionAlert.text' : 'subscriptionAlert.textPlural'
              }
              components={{ strong: <strong /> }}
            />
          </>
        )}
        .
      </span>
      <Button
        onClick={onContactSales}
        size="small"
        variant="outlined"
        classes={{ outlinedPrimary: classes.alertButton }}
      >
        {isTrialCertificate || isTrialEsign
          ? t<string>(
              smallScreen ? 'subscriptionAlert.purchaseNowShort' : 'subscriptionAlert.purchaseNow'
            )
          : t<string>(
              smallScreen ? 'subscriptionAlert.renewNowShort' : 'subscriptionAlert.renewNow'
            )}
      </Button>
    </Alert>
  );
};

export default SubscriptionAlert;
