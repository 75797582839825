import React from 'react';
import { Tab, Tabs, useMediaQuery, useTheme } from '@material-ui/core';
import { useStyles } from './styles';

export type tabType = {
  label: string;
  icon?: JSX.Element;
  value: number;
};

interface ButtonTabsProps {
  value: number;
  tabs: tabType[];
  onChange: (value: number) => void;
}

const ButtonTabs = ({ value, tabs, onChange }: ButtonTabsProps): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const { breakpoints } = theme;
  const miniScreen = useMediaQuery(`(max-width: ${breakpoints.values.xs - 1}px)`);

  const onTabChange = (event: React.ChangeEvent<HTMLElement | unknown>, newValue: number) => {
    onChange(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs
        value={value}
        onChange={onTabChange}
        variant={miniScreen ? 'scrollable' : 'fullWidth'}
        classes={{
          root: classes.tabsRoot,
          scroller: classes.tabsScroller,
        }}
      >
        {tabs.map((tab, index) => (
          <Tab
            value={tab.value}
            key={`tab_${index}_${tab.label}`}
            label={tab.label}
            icon={tab.icon && tab.icon}
          />
        ))}
      </Tabs>
    </div>
  );
};

export default ButtonTabs;
