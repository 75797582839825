import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ spacing, palette }) =>
  createStyles({
    listItemHeader: {
      padding: 0,
    },
    listHeader: {
      minHeight: spacing(3),
    },
    title: {
      '&.Mui-expanded': {
        '& button': {
          color: palette.primary.main,
          borderColor: palette.primary.main,
          backgroundColor: palette.common.white,
        },
      },
    },
    list: {
      width: '100%',
      '&:not(:first-child)': {
        paddingTop: spacing(5),
      },
    },
  })
);
