import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { lighten } from '@material-ui/core/styles';
import { CertificateQuantityType } from 'src/models/certificate.model';
import { RootState } from 'src/redux/reducers';

export const useStyles = makeStyles(
  ({ spacing, shape, palette }) =>
    createStyles({
      wrapper: {
        marginTop: -4,
        display: 'inline-flex',
        alignItems: 'center',
        marginBottom: 24,
        padding: spacing(1, 2),
        minHeight: 48,
        justifyContent: 'space-between',
        borderRadius: shape.borderRadius,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: lighten(palette.warning.main, 0.9),
        backgroundColor: lighten(palette.warning.main, 0.95),
        color: palette.warning.main,
      },
      container: {
        display: 'flex',
        justifyContent: 'center',
      },
    }),
  { index: 0 }
);

const MultipleInvitationWarningBar = (): JSX.Element | null => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { certificateQuantityType } = useSelector(
    (state: RootState) => state.certificateCreatorStore,
    undefined
  );

  if (certificateQuantityType !== CertificateQuantityType.Multiple) return null;
  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>{t<string>('inviteToDocument.multipleWarningText')}</div>
    </div>
  );
};

export default MultipleInvitationWarningBar;
