import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ palette, spacing, typography }) =>
    createStyles({
      root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
      },
      smallRoot: {
        maxWidth: 460,
        margin: '0 auto',
      },
      content: {
        padding: spacing(1, 0),
      },
      cardContent: {
        height: '100%',
        overflow: 'visible',
      },
      addedListItem: {
        color: palette.text.primary,
        '&:hover, &:focus': {
          color: palette.primary.main,
          '& $removeButton': {
            color: palette.primary.main,
          },
        },
      },
      addedListItemContent: {
        display: 'flex',
        alignItems: 'center',
        flex: 1,
        overflow: 'hidden',
      },
      addedListItemAdditionalContent: {
        fontSize: typography.subtitle2.fontSize,
        fontWeight: typography.fontWeightRegular,
        color: palette.text.secondary,
      },
      addedListItemAction: {
        display: 'flex',
        alignItems: 'center',
        '& > *': {
          marginLeft: spacing(1),
        },
      },
      removeButton: {
        width: 24,
        minWidth: 24,
        height: 24,
        minHeight: 24,
        '& svg': {
          width: 12,
          height: 12,
        },
      },
      avatarAndItemRoot: {
        display: 'flex',
        alignItems: 'center',
        flex: 1,
        marginTop: spacing(0.5),
        marginBottom: spacing(0.5),
      },
      authorBox: {
        display: 'flex',
        alignItems: 'center',
        color: palette.text.primary,
        '& > *:not(:last-child)': {
          marginRight: spacing(1),
        },
      },
      loading: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: -6,
        marginBottom: -6,
      },
      inputLoader: {
        position: 'absolute',
        right: spacing(1.25),
        zIndex: 1,
        pointerEvents: 'none',
      },
      disabledListBox: {
        '& *': {
          pointerEvents: 'none',
        },
      },
      groupHeader: {
        minHeight: spacing(4),
      },
    }),
  { index: 0 }
);
