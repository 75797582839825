import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ palette, spacing, transitions }) =>
    createStyles({
      root: {
        padding: spacing(1),
        color: palette.text.secondary,
      },
      opened: {
        color: palette.primary.main,
        '& $icon': {
          transform: 'rotate(-180deg)',
        },
      },
      icon: {
        width: 16,
        height: 16,
        marginLeft: spacing(-0.5),
        lineHeight: 1,
        color: 'inherit',
        transition: transitions.create(['color', 'transform', 'opacity'], {
          duration: transitions.duration.short,
        }),
      },
    }),
  { index: 0 }
);
