import { RequestStatus } from 'src/helpers/reduxReuquest.util';
import certificateManagementConstants from 'src/redux/constants/certificateManagement.constants';
import { DocumentRole, mapToDocumentRole } from 'src/models/document.model';
import documentManagementConstants from 'src/redux/constants/documentManagement.constants';

const getInitialStateOfRoles = () => ({
  [DocumentRole.ADMINS]: [],
  [DocumentRole.AUDITORS]: [],
  [DocumentRole.EDITORS]: [],
  [DocumentRole.SIGNERS]: [],
  [DocumentRole.VIEWERS]: [],
  [DocumentRole.HOLDER]: [],
});

const initialState = {
  clearHistoryStatus: true,
  documentInvalidationStatus: RequestStatus.IDLE,
  documentInvitations: [],
  documentMembers: [],
  documentRenameStatus: RequestStatus.IDLE,
  documentSigners: [],
  documentSigningStatus: RequestStatus.IDLE,
  documentTemporarilyInvalidationStatus: RequestStatus.IDLE,
  documentValidationStatus: RequestStatus.IDLE,
  documentTransferOwnershipStatus: RequestStatus.IDLE,
  getDocumentCardStatus: RequestStatus.IDLE,
  getDocumentHistoryStatus: RequestStatus.IDLE,
  getDocumentInvitationsStatus: RequestStatus.IDLE,
  getDocumentMembersByRoleStatus: RequestStatus.IDLE,
  getDocumentMembersStatus: RequestStatus.IDLE,
  getSignaturesStatus: RequestStatus.IDLE,
  historyDocument: [],
  historyHasNextPage: false,
  historyLength: 0,
  rejectingDocumentStatus: RequestStatus.IDLE,
  roles: getInitialStateOfRoles(),
  signatures: [],
};

const updateRolesByType = (roleType, rolesStatus, roleTypeCollection) => {
  const newRolesStatus = rolesStatus;
  newRolesStatus[roleType] = roleTypeCollection;
  return newRolesStatus;
};

const updateAllRoles = (allMembers) => {
  const newRolesStatus = getInitialStateOfRoles();
  allMembers.forEach((item) => {
    const groupName = mapToDocumentRole(item.role);
    newRolesStatus[groupName].push(item.id);
  });
  return newRolesStatus;
};

function documentManagementStore(state = initialState, action) {
  switch (action.type) {
    case documentManagementConstants.RENAME_DOCUMENT_REQUEST:
      return {
        ...state,
        documentRenameStatus: RequestStatus.PENDING,
      };
    case documentManagementConstants.RENAME_DOCUMENT_SUCCESS:
      return {
        ...state,
        name: action.name,
        documentRenameStatus: RequestStatus.SUCCESS,
      };
    case documentManagementConstants.RENAME_DOCUMENT_FAILURE:
      return {
        ...state,
        documentRenameStatus: RequestStatus.ERROR,
      };

    case documentManagementConstants.SIGN_DOCUMENT_REQUEST:
    case certificateManagementConstants.DOCUMENT_SIGN_REQUEST:
      return {
        ...state,
        documentSigningStatus: RequestStatus.PENDING,
      };

    case documentManagementConstants.SIGN_DOCUMENT_SUCCESS:
    case certificateManagementConstants.DOCUMENT_SIGN_SUCCESS:
      return {
        ...state,
        data: action.data,
        documentSigningStatus: RequestStatus.SUCCESS,
      };

    case documentManagementConstants.SIGN_DOCUMENT_FAILURE:
    case certificateManagementConstants.DOCUMENT_SIGN_ERROR:
      return {
        ...state,
        documentSigningStatus: RequestStatus.ERROR,
      };

    case documentManagementConstants.REJECT_DOCUMENT_REQUEST:
    case certificateManagementConstants.DOCUMENT_REJECT_REQUEST:
      return {
        ...state,
        rejectingDocumentStatus: RequestStatus.PENDING,
      };

    case documentManagementConstants.REJECT_DOCUMENT_SUCCESS:
    case certificateManagementConstants.DOCUMENT_REJECT_SUCCESS:
      return {
        ...state,
        data: action.data,
        rejectingDocumentStatus: RequestStatus.SUCCESS,
      };

    case documentManagementConstants.REJECT_DOCUMENT_FAILURE:
    case certificateManagementConstants.DOCUMENT_REJECT_ERROR:
      return {
        ...state,
        rejectingDocumentStatus: RequestStatus.ERROR,
      };

    case documentManagementConstants.GET_HISTORY_DOCUMENT_REQUEST:
      return {
        ...state,
        getDocumentHistoryStatus: RequestStatus.PENDING,
      };
    case documentManagementConstants.GET_HISTORY_DOCUMENT_SUCCESS:
      return {
        ...state,
        historyHasNextPage: action.history.hasNextPage,
        historyDocument: [...state.historyDocument, ...action.history.collection],
        historyLength: state.historyLength + action.history.collection.length,
        getDocumentHistoryStatus: RequestStatus.SUCCESS,
        clearHistoryStatus: false,
      };
    case documentManagementConstants.GET_HISTORY_DOCUMENT_FAILURE:
      return {
        ...state,
        getDocumentHistoryStatus: RequestStatus.ERROR,
      };

    case documentManagementConstants.RELOAD_DOCUMENT_HISTORY:
      return {
        ...state,
        clearHistoryStatus: true,
        historyHasNextPage: initialState.historyHasNextPage,
        historyDocument: initialState.historyDocument,
        historyLength: initialState.historyLength,
      };

    case documentManagementConstants.GET_DOCUMENT_MEMBERS_REQUEST:
      return {
        ...state,
        getDocumentMembersStatus: RequestStatus.PENDING,
      };
    case documentManagementConstants.GET_DOCUMENT_MEMBERS_SUCCESS:
      return {
        ...state,
        documentMembers: action.members.collection.map((el) => {
          el.role = mapToDocumentRole(el.role);
          return el;
        }),
        getDocumentMembersStatus: RequestStatus.SUCCESS,
        roles: updateAllRoles(action.members.collection),
      };
    case documentManagementConstants.GET_DOCUMENT_MEMBERS_FAILURE:
      return {
        ...state,
        getDocumentMembersStatus: RequestStatus.ERROR,
      };

    case documentManagementConstants.GET_DOCUMENT_INVITATIONS_REQUEST:
      return {
        ...state,
        getDocumentInvitationsStatus: RequestStatus.PENDING,
      };
    case documentManagementConstants.GET_DOCUMENT_INVITATIONS_SUCCESS:
      return {
        ...state,
        documentInvitations: action.members.collection.map((el) => {
          el.role = mapToDocumentRole(el.role);
          return el;
        }),
        getDocumentInvitationsStatus: RequestStatus.SUCCESS,
      };
    case documentManagementConstants.GET_DOCUMENT_INVITATIONS_FAILURE:
      return {
        ...state,
        getDocumentInvitationsStatus: RequestStatus.ERROR,
      };

    case documentManagementConstants.GET_DOCUMENT_MEMBERS_BY_ROLE_REQUEST:
      return {
        ...state,
        getDocumentMembersByRoleStatus: RequestStatus.PENDING,
      };
    case documentManagementConstants.GET_DOCUMENT_MEMBERS_BY_ROLE_SUCCESS:
      return {
        ...state,
        roles: updateRolesByType(
          mapToDocumentRole(action.roleType),
          state.roles,
          action.members.collection
        ),
        getDocumentMembersByRoleStatus: RequestStatus.SUCCESS,
      };
    case documentManagementConstants.GET_DOCUMENT_MEMBERS_BY_ROLE_FAILURE:
      return {
        ...state,
        getDocumentMembersByRoleStatus: RequestStatus.ERROR,
      };

    case documentManagementConstants.GET_SIGNATURES_REQUEST:
      return {
        ...state,
        getSignaturesStatus: RequestStatus.PENDING,
      };
    case documentManagementConstants.GET_SIGNATURES_SUCCESS:
      return {
        ...state,
        signatures: action.signatures.collection,
        getSignaturesStatus: RequestStatus.SUCCESS,
      };
    case documentManagementConstants.GET_SIGNATURES_FAILURE:
      return {
        ...state,
        getSignaturesStatus: RequestStatus.ERROR,
      };

    case documentManagementConstants.UPDATE_DOCUMENT_SIGNERS: {
      return {
        ...state,
        documentSigners: action.signers,
      };
    }

    case documentManagementConstants.GET_DOCUMENT_CARD_REQUEST:
      return {
        ...state,
        getDocumentCardStatus: RequestStatus.PENDING,
      };
    case documentManagementConstants.GET_DOCUMENT_CARD_SUCCESS:
      return {
        ...state,
        getDocumentCardStatus: RequestStatus.SUCCESS,
      };
    case documentManagementConstants.GET_DOCUMENT_CARD_FAILURE:
      return {
        ...state,
        getDocumentCardStatus: RequestStatus.ERROR,
      };

    case documentManagementConstants.WS_UPDATE_DOCUMENT_MANAGEMENT:
      return {
        ...state,
        roles: action.data.roles,
      };
    case documentManagementConstants.CLEAR_DOCUMENT_HISTORY:
      return {
        ...state,
        clearHistoryStatus: true,
        historyHasNextPage: initialState.historyHasNextPage,
        historyDocument: initialState.historyDocument,
        historyLength: initialState.historyLength,
      };
    case documentManagementConstants.CLEAR_DOCUMENT_MANAGEMENT_STORE:
      return {
        ...initialState,
        roles: getInitialStateOfRoles(),
      };

    case certificateManagementConstants.DOCUMENT_INVALIDATE_REQUEST:
      return {
        ...state,
        documentInvalidationStatus: RequestStatus.PENDING,
      };
    case certificateManagementConstants.DOCUMENT_INVALIDATE_SUCCESS:
      return {
        ...state,
        documentInvalidationStatus: RequestStatus.SUCCESS,
      };
    case certificateManagementConstants.DOCUMENT_INVALIDATE_ERROR:
      return {
        ...state,
        documentInvalidationStatus: RequestStatus.ERROR,
      };

    case certificateManagementConstants.DOCUMENT_INVALIDATE_TEMPORARILY_REQUEST:
      return {
        ...state,
        documentTemporarilyInvalidationStatus: RequestStatus.PENDING,
      };
    case certificateManagementConstants.DOCUMENT_INVALIDATE_TEMPORARILY_SUCCESS:
      return {
        ...state,
        documentTemporarilyInvalidationStatus: RequestStatus.SUCCESS,
      };
    case certificateManagementConstants.DOCUMENT_INVALIDATE_TEMPORARILY_ERROR:
      return {
        ...state,
        documentTemporarilyInvalidationStatus: RequestStatus.ERROR,
      };

    case certificateManagementConstants.DOCUMENT_VALIDATE_REQUEST:
      return {
        ...state,
        documentValidationStatus: RequestStatus.PENDING,
      };
    case certificateManagementConstants.DOCUMENT_VALIDATE_SUCCESS:
      return {
        ...state,
        documentValidationStatus: RequestStatus.SUCCESS,
      };
    case certificateManagementConstants.DOCUMENT_VALIDATE_ERROR:
      return {
        ...state,
        documentValidationStatus: RequestStatus.ERROR,
      };

    case certificateManagementConstants.TRANSFER_OWNERSHIP_REQUEST:
      return {
        ...state,
        documentTransferOwnershipStatus: RequestStatus.PENDING,
      };

    case certificateManagementConstants.TRANSFER_OWNERSHIP_SUCCESS:
      return {
        ...state,
        documentTransferOwnershipStatus: RequestStatus.SUCCESS,
      };

    case certificateManagementConstants.TRANSFER_OWNERSHIP_ERROR:
      return {
        ...state,
        documentTransferOwnershipStatus: RequestStatus.ERROR,
      };

    default:
      return state;
  }
}

export default documentManagementStore;
