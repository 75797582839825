import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Card,
  List,
  ListItem,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import {
  ContentContainer,
  QrImage,
  SmallContentWithScrollbar,
  ConfirmDialog,
} from 'src/components';

export const useStyles = makeStyles(
  ({ breakpoints, spacing }) =>
    createStyles({
      listItem: {
        minHeight: 48,
        [breakpoints.up('md')]: {
          minHeight: 56,
        },
      },
      listItemNumber: {
        minWidth: spacing(3),
      },
      qrImage: {
        margin: `${spacing(2)}px auto`,
        [breakpoints.up('md')]: {
          marginTop: spacing(3),
        },
      },
    }),
  { index: 0 }
);

const GoogleAuthenticatorStep1 = ({ nextHandle, qrCode, secret }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [secretDialogOpen, setSecretDialogOpen] = useState(false);

  const steps = [
    t('userConfigurationPage.security.method.googleAuthenticator.step1.list.step1'),
    t('userConfigurationPage.security.method.googleAuthenticator.step1.list.step2'),
    t('userConfigurationPage.security.method.googleAuthenticator.step1.list.step3'),
  ];

  return (
    <>
      <SmallContentWithScrollbar
        endContent={
          <Button size="large" fullWidth onClick={nextHandle}>
            {t('common.configure')}
          </Button>
        }
      >
        <Card variant="outlined">
          <ContentContainer size="small">
            <List>
              {steps.map((item, index) => (
                <ListItem key={index} disableGutters className={classes.listItem}>
                  <Typography
                    color="textSecondary"
                    component="span"
                    className={classes.listItemNumber}
                  >
                    <strong>{index + 1})</strong>
                  </Typography>
                  <Typography color="textPrimary" component="span">
                    {item}
                  </Typography>
                </ListItem>
              ))}
            </List>
            <Box textAlign="center">
              <QrImage code={qrCode} className={classes.qrImage} />

              <Button variant="text" onClick={() => setSecretDialogOpen(true)}>
                <Typography color="primary" component="u">
                  <strong>
                    {t(
                      'userConfigurationPage.security.method.googleAuthenticator.step1.downloadBackup'
                    )}
                  </strong>
                </Typography>
              </Button>
            </Box>
          </ContentContainer>
        </Card>
      </SmallContentWithScrollbar>
      <ConfirmDialog
        open={secretDialogOpen}
        variant="information"
        subtitle={t(
          'userConfigurationPage.security.method.googleAuthenticator.step1.downloadBackupDescription'
        )}
        cancelButtonText={t('common.back')}
        actionCancel={() => setSecretDialogOpen(false)}
      >
        <Typography color="primary" component="u" align="center">
          <strong>{secret}</strong>
        </Typography>
      </ConfirmDialog>
    </>
  );
};

GoogleAuthenticatorStep1.propTypes = {
  nextHandle: PropTypes.func,
  qrCode: PropTypes.string,
  secret: PropTypes.string,
};

export default GoogleAuthenticatorStep1;
