import { RequestStatus } from 'src/helpers/reduxReuquest.util';
import avatarConstants from '../constants/avatar.constants';

const initialState = {
  deleteUserAvatarRequestStatus: RequestStatus.IDLE,
  deleteWorkspaceAvatarRequestStatus: RequestStatus.IDLE,
  getUserAvatarRequestStatus: RequestStatus.IDLE,
  getWorkspaceAvatarRequestStatus: RequestStatus.IDLE,
  putUserAvatarRequestStatus: RequestStatus.IDLE,
  putWorkspaceAvatarRequestStatus: RequestStatus.IDLE,
  userAvatar: null,
  workspaceAvatar: null,
};

function avatarStore(state = initialState, action) {
  switch (action.type) {
    case avatarConstants.GET_USER_AVATAR_REQUEST:
      return {
        ...state,
        userAvatar: null,
        getUserAvatarRequestStatus: RequestStatus.PENDING,
      };
    case avatarConstants.GET_USER_AVATAR_SUCCESS:
      return {
        ...state,
        userAvatar: true,
        getUserAvatarRequestStatus: RequestStatus.SUCCESS,
      };
    case avatarConstants.GET_USER_AVATAR_ERROR:
      return {
        ...state,
        userAvatar: false,
        getUserAvatarRequestStatus: RequestStatus.ERROR,
      };
    case avatarConstants.PUT_USER_AVATAR_REQUEST:
      return {
        ...state,
        putUserAvatarRequestStatus: RequestStatus.PENDING,
      };
    case avatarConstants.PUT_USER_AVATAR_SUCCESS:
      return {
        ...state,
        putUserAvatarRequestStatus: RequestStatus.SUCCESS,
      };
    case avatarConstants.PUT_USER_AVATAR_ERROR:
      return {
        ...state,
        putUserAvatarRequestStatus: RequestStatus.ERROR,
      };
    case avatarConstants.DELETE_USER_AVATAR_REQUEST:
      return {
        ...state,
        deleteUserAvatarRequestStatus: RequestStatus.PENDING,
      };
    case avatarConstants.DELETE_USER_AVATAR_SUCCESS:
      return {
        ...state,
        deleteUserAvatarRequestStatus: RequestStatus.SUCCESS,
      };
    case avatarConstants.DELETE_USER_AVATAR_ERROR:
      return {
        ...state,
        deleteUserAvatarRequestStatus: RequestStatus.ERROR,
      };

    case avatarConstants.GET_WORKSPACE_AVATAR_REQUEST:
      return {
        ...state,
        workspaceAvatar: null,
        getWorkspaceAvatarRequestStatus: RequestStatus.PENDING,
      };
    case avatarConstants.GET_WORKSPACE_AVATAR_SUCCESS:
      return {
        ...state,
        workspaceAvatar: true,
        getWorkspaceAvatarRequestStatus: RequestStatus.SUCCESS,
      };
    case avatarConstants.GET_WORKSPACE_AVATAR_ERROR:
      return {
        ...state,
        workspaceAvatar: false,
        getWorkspaceAvatarRequestStatus: RequestStatus.ERROR,
      };
    case avatarConstants.PUT_WORKSPACE_AVATAR_REQUEST:
      return {
        ...state,
        putWorkspaceAvatarRequestStatus: RequestStatus.PENDING,
      };
    case avatarConstants.PUT_WORKSPACE_AVATAR_SUCCESS:
      return {
        ...state,
        putWorkspaceAvatarRequestStatus: RequestStatus.SUCCESS,
      };
    case avatarConstants.PUT_WORKSPACE_AVATAR_ERROR:
      return {
        ...state,
        putWorkspaceAvatarRequestStatus: RequestStatus.ERROR,
      };
    case avatarConstants.DELETE_WORKSPACE_AVATAR_REQUEST:
      return {
        ...state,
        deleteWorkspaceAvatarRequestStatus: RequestStatus.PENDING,
      };
    case avatarConstants.DELETE_WORKSPACE_AVATAR_SUCCESS:
      return {
        ...state,
        deleteWorkspaceAvatarRequestStatus: RequestStatus.SUCCESS,
      };
    case avatarConstants.DELETE_WORKSPACE_AVATAR_ERROR:
      return {
        ...state,
        deleteWorkspaceAvatarRequestStatus: RequestStatus.ERROR,
      };
    default:
      return state;
  }
}

export default avatarStore;
