import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import workspaceGroupsActions from 'src/redux/actions/workspaceGroups.actions';
import { Button, CircularProgress, TextField, Typography } from '@material-ui/core';
import { SmallContentWithScrollbar } from 'src/components';
import { RequestStatus } from 'src/helpers/reduxReuquest.util';

const AddNewGroup = ({ onClose, workspaceId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [value, setValue] = useState('');

  const createWorkspaceGroupStatus = useSelector(
    (state) => state.workspaceGroupsStore.createWorkspaceGroupStatus
  );

  const isPending = createWorkspaceGroupStatus === RequestStatus.PENDING;
  const isDisabled = !value || isPending;

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(
      workspaceGroupsActions.createWorkspaceGroup(
        workspaceId,
        {
          name: value,
          description: '',
        },
        onClose
      )
    );
  };

  return (
    <SmallContentWithScrollbar
      startContent={<Typography>{t('workspacePage.groups.add.description')}</Typography>}
      endContent={
        <Button
          fullWidth
          size="large"
          disabled={isDisabled}
          type="submit"
          endIcon={isPending && <CircularProgress size={20} color="inherit" />}
        >
          {t('workspacePage.groups.add.create')}
        </Button>
      }
      hasForm
      formProps={{
        noValidate: true,
        autoComplete: 'off',
        onSubmit: handleSubmit,
      }}
    >
      <TextField
        autoFocus
        fullWidth
        placeholder={t('workspacePage.groups.add.name')}
        onChange={(event) => {
          setValue(event.target.value);
        }}
        disabled={isPending}
      />
    </SmallContentWithScrollbar>
  );
};

AddNewGroup.propTypes = {
  onClose: PropTypes.func,
  workspaceId: PropTypes.string,
};

export default AddNewGroup;
