import React, { useEffect, useMemo, useState } from 'react';
import Lottie from 'react-lottie';
import cubeLottie from 'src/components/WaitingForBlockchain/cube.json';
import documentLottie from 'src/components/WaitingForBlockchain/document.json';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Theme, Typography, useMediaQuery } from '@material-ui/core';
import SmallContentWithScrollbar from '../SmallContentWithScrollbar';
import { useStyles } from './styles';

export enum Variant {
  BlockchainProcessing,
  DocumentsGenerating,
}

type WaitingForBlockchainProps = {
  variant?: Variant;
  isPlural?: boolean;
};

const WaitingForBlockchain = ({
  variant = Variant.BlockchainProcessing,
  isPlural = false,
}: WaitingForBlockchainProps): JSX.Element => {
  const { t } = useTranslation();
  const mediumScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const classes = useStyles();
  const [titleNr, setTitleNr] = useState(0);
  const lottieAnimation = {
    [Variant.BlockchainProcessing]: cubeLottie,
    [Variant.DocumentsGenerating]: documentLottie,
  };

  const titleTranslationsList = useMemo(
    () => ({
      [Variant.BlockchainProcessing]: [
        t('waitingForBlockchain.title1'),
        t('waitingForBlockchain.title2'),
        t('waitingForBlockchain.title3'),
        t('waitingForBlockchain.title4'),
        t('waitingForBlockchain.title5'),
        t('waitingForBlockchain.title6'),
        t('waitingForBlockchain.title7'),
      ],
      [Variant.DocumentsGenerating]: [
        t('waitingForBlockchain.generatingCertFilesTitle1'),
        t('waitingForBlockchain.generatingCertFilesTitle2'),
      ],
    }),
    [t]
  );

  const descriptionTranslation = useMemo(
    () => ({
      [Variant.BlockchainProcessing]: t('waitingForBlockchain.description'),

      [Variant.DocumentsGenerating]: isPlural
        ? 'waitingForBlockchain.generatingCertFilesDescription'
        : t('waitingForBlockchain.generatingCertFileDescription'),
    }),
    [t, isPlural]
  );

  useEffect(() => {
    const t = setTimeout(() => {
      if (titleNr < titleTranslationsList[variant].length - 1) {
        setTitleNr((t) => t + 1);
      } else clearTimeout(t);
    }, 2000);

    return () => clearTimeout(t);
  }, [titleNr, titleTranslationsList, variant]);

  return (
    <SmallContentWithScrollbar
      classes={{ root: classes.root, content: classes.content }}
      scrollableContent
      size="large"
    >
      <div className={classes.contentWrapper}>
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            // eslint-disable-next-line global-require
            animationData: lottieAnimation[variant],
          }}
          height={mediumScreen ? 300 : 500}
          width={mediumScreen ? 300 : 500}
          style={{ paddingBottom: variant === Variant.DocumentsGenerating ? 60 : 0 }}
        />
        <Typography
          variant={mediumScreen ? 'h4' : 'h1'}
          gutterBottom
          className={classes.titleWrapper}
        >
          {titleTranslationsList[variant].map((title, index) => (
            <div className={clsx(classes.title, index === titleNr && classes.titleActive)}>
              <span>{title}</span>
            </div>
          ))}
        </Typography>
        <Typography className={classes.description}>{descriptionTranslation[variant]}</Typography>
      </div>
    </SmallContentWithScrollbar>
  );
};

export default WaitingForBlockchain;
