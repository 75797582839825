import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ palette }) =>
    createStyles({
      root: {
        zIndex: 2,
        color: palette.primary.main,
        background: palette.common.white,
      },
    }),
  { index: 0 }
);
