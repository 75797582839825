import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import {
  HeaderButton,
  MobileBottomMenuList,
  OutsideClickHandlerClickExcpetion,
} from 'src/components';
import { MoreHor as SettingsIcon } from 'src/components/CustomIcon';

const ItemsActions = ({ actions, smallScreen }) => {
  const [open, setOpen] = useState(false);
  const handleToggle = {
    close: () => {
      setOpen(false);
    },
    open: () => {
      setOpen(true);
    },
  };

  const renderMenuActions = (actions) => (
    <>
      {smallScreen ? (
        <MobileBottomMenuList actions={actions} open={open} onClose={handleToggle.close} />
      ) : (
        actions.map((action, index) => (
          <Button
            key={index}
            size="small"
            variant="text"
            startIcon={action.icon}
            onClick={action.onClick}
          >
            {action.name}
            <OutsideClickHandlerClickExcpetion />
          </Button>
        ))
      )}
    </>
  );

  return (
    <>
      {smallScreen && <HeaderButton icon={<SettingsIcon />} onClick={handleToggle.open} />}
      {actions && renderMenuActions(actions)}
    </>
  );
};

ItemsActions.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.node,
      name: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ),
  smallScreen: PropTypes.bool,
};

ItemsActions.defaultProps = {};

export default ItemsActions;
