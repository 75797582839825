import React from 'react';
import { DialogTitle, Fab, Tooltip, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Close as CloseIcon } from 'src/components/CustomIcon';
import { useStyles } from '../styles';

type AdvancedSearchModalTitleProps = {
  onClose: () => void;
};

const AdvancedSearchModalTitle = ({ onClose }: AdvancedSearchModalTitleProps): JSX.Element => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <DialogTitle className={classes.title} disableTypography>
      <Typography variant="h3" className={classes.titleText}>
        {t<string>('advancedSearch.title')}
      </Typography>
      <Tooltip title={t<string>('common.close')}>
        <Fab color="primary" size="medium" onClick={onClose}>
          <CloseIcon />
        </Fab>
      </Tooltip>
    </DialogTitle>
  );
};

export default AdvancedSearchModalTitle;
