import i18n from 'i18next';

const ErrorCode = {
  FileInvalidType: 'file-invalid-type',
  FileTooLarge: 'file-too-large',
  FileTooSmall: 'file-too-small',
  TooManyFiles: 'too-many-files',
};

export const acceptableFileTypes = [
  'application/pdf',
  'application/zip',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'image/jpeg',
  'image/png',
  'application/x-zip-compressed',
  'multipart/x-zip',
];
export const maxFiles = 10;
export const dropzoneErrors = {
  [ErrorCode.FileInvalidType]: i18n.t('dropzones.fileTypeError'),
  [ErrorCode.TooManyFiles]: i18n.t('dropzones.fileTooManyError', { value: maxFiles }),
  [ErrorCode.FileTooLarge]: i18n.t('dropzones.fileTooLarge', { value: 50 }),
  [ErrorCode.FileTooSmall]: i18n.t('dropzones.fileTooSmall'),
};

export const maxSize = 52428800; // 50MB
