import React, { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import identityActions from 'src/redux/actions/identity.actions';
import workspaceGroupsActions from 'src/redux/actions/workspaceGroups.actions';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  Card,
  CardContent,
  LinearProgress,
  List,
  ListItem,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { EmptyBanner, ExtendedGrid, FullScreenDialog } from 'src/components';
import {
  Delete as DeleteIcon,
  PenOutlined as EditIcon,
  Users as ManageIcon,
} from 'src/components/CustomIcon';
import { RequestStatus } from 'src/helpers/reduxReuquest.util';
import { GroupItem, Search, HeaderAndButton } from '../../subcomponents';
import { AddNewGroup, DeleteGroup, ManageGroupUsers, RenameGroup } from './editDialogs';
import { useStyles } from '../styles';
import { sortGroupsAllOwnersFirst } from '../../../../helpers/globalUtils';

const Groups = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const smallScreen = useMediaQuery(({ breakpoints }) => breakpoints.down('sm'));
  const classes = useStyles();
  const [didMount, setDidMount] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const [editedSubpage, setEditedSubpage] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  useEffect(() => {
    setDidMount(true);
    return () => setDidMount(false);
  }, []);

  const workspace = useSelector((state) => state.identityStore);
  const {
    activeGroup,
    workspaceGroups,
    hasNextPage: workspaceGroupsHasNextPage,
    pageIndex: workspaceGroupsPageIndex,
    fetchWorkspaceGroupsStatus: workspaceGroupsRequestStatus,
    deleteWorkspaceGroupStatus: getDeleteWorkspaceGroupStatus,
    searchWorkspaceGroupsStatus,
  } = useSelector((state) => state.workspaceGroupsStore);

  const workspaceId = workspace.currentIdentity?.workspace?.workspaceId;

  const workspaceMembers = useMemo(() => workspace?.workspaceUsers || [], [
    workspace?.workspaceUsers,
  ]);

  const setActiveGroup = (activeGroup) =>
    dispatch(workspaceGroupsActions.setActiveGroup(activeGroup));

  const handleBack = () => {
    setEditedSubpage(null);
  };

  const deleteDialogToggle = {
    open: () => {
      setDeleteDialogOpen(true);
    },
    close: () => {
      setDeleteDialogOpen(false);
    },
  };

  const editPages = {
    add: {
      title: t('workspacePage.groups.add.header'),
      Component: <AddNewGroup workspaceId={workspaceId} onClose={handleBack} />,
    },
    manage: {
      title: t('workspacePage.groups.manageUsers.header'),
      Component: (
        <ManageGroupUsers
          workspaceId={workspaceId}
          group={activeGroup}
          availableMembers={workspaceMembers.filter((member) => member?.active === true)}
        />
      ),
    },
    rename: {
      title: t('workspacePage.groups.rename.header'),
      Component: <RenameGroup workspaceId={workspaceId} group={activeGroup} onClose={handleBack} />,
    },
  };

  useEffect(() => {
    if (didMount) {
      setLoading(true);
      if (
        workspaceGroupsRequestStatus === RequestStatus.IDLE &&
        !workspaceGroups.length &&
        workspaceGroupsPageIndex === 0 &&
        workspaceId !== undefined
      ) {
        dispatch(
          workspaceGroupsActions.fetchWorkspaceGroups(workspaceId, workspaceGroupsPageIndex)
        );
      }
      if (
        workspaceGroupsRequestStatus === RequestStatus.SUCCESS ||
        workspaceGroupsRequestStatus === RequestStatus.ERROR
      ) {
        setLoading(false);
      }
    }
  }, [
    didMount,
    dispatch,
    workspaceGroups.length,
    workspaceGroupsPageIndex,
    workspaceGroupsRequestStatus,
    workspaceId,
  ]);

  useEffect(() => {
    if (
      workspaceGroupsRequestStatus !== RequestStatus.IDLE &&
      workspaceGroupsRequestStatus !== RequestStatus.PENDING
    ) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchWorkspaceGroupsStatus]);

  useEffect(() => {
    if (getDeleteWorkspaceGroupStatus === RequestStatus.ERROR) {
      setDeleteDialogOpen(false);
    }
  }, [getDeleteWorkspaceGroupStatus]);

  useEffect(() => {
    if (searchTerm.length > 0) {
      setLoading(true);
    } else if (searchWorkspaceGroupsStatus !== RequestStatus.IDLE) {
      dispatch(workspaceGroupsActions.clearWorkspaceGroupsStore());
    }
    const delayDebounceFn = setTimeout(() => {
      if (searchTerm.length > 0) {
        dispatch(workspaceGroupsActions.searchWorkspaceGroups(workspaceId, 0, 50, searchTerm));
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, searchTerm]);

  useEffect(() => {
    if (didMount) {
      const scrollElement = document.getElementById('fullScreenDialogContent');
      const hasVerticalScrollbar = scrollElement?.scrollHeight > scrollElement?.clientHeight;
      if (workspaceGroupsHasNextPage && workspaceGroupsPageIndex > 0 && !hasVerticalScrollbar) {
        fetchMoreData();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceGroupsHasNextPage, workspaceGroups, didMount, workspaceGroupsPageIndex]);

  const fetchMoreData = () => {
    if (workspaceGroupsHasNextPage && workspaceGroupsPageIndex > 0 && workspaceGroups?.length) {
      dispatch(
        workspaceGroupsActions.fetchWorkspaceGroups(
          workspaceId,
          workspaceGroupsPageIndex,
          50,
          searchTerm
        )
      );
    }
  };

  const renderEditPage = (name) => editPages[name].Component;
  const setTitle = (name) => editPages[name].title;

  const handleSetSearchTerm = (value) => {
    setSearchTerm(value);
  };

  const renderSummaryPage = () => (
    <>
      <HeaderAndButton
        subtitle={t('workspacePage.groups.subheader')}
        onButtonClick={() => setEditedSubpage('add')}
        buttonLabel={t('workspacePage.groups.addGroup')}
        smallScreen={smallScreen}
      >
        {t('workspacePage.drawer.userManagementGroup')}
      </HeaderAndButton>
      <Search
        className={classes.searchInput}
        inputLabel={`${t('common.search')}...`}
        handleSetSearchTerm={handleSetSearchTerm}
        valueInput={searchTerm}
        loading={loading && searchTerm !== ''}
      />

      <Card variant="outlined" className={classes.root}>
        <InfiniteScroll
          dataLength={workspaceGroups?.length}
          next={fetchMoreData}
          hasMore={workspaceGroupsHasNextPage}
          scrollThreshold={0.9}
          style={{
            overflow: 'hidden',
          }}
          loader={loading && <LinearProgress />}
          scrollableTarget="fullScreenDialogContent"
        >
          <CardContent>
            {workspaceGroupsRequestStatus !== RequestStatus.ERROR ? (
              <List>
                {!smallScreen && (
                  <ListItem
                    divider={false}
                    classes={{
                      root: clsx(classes.listHeader, classes.listItemRoot),
                      container: classes.listItemContainer,
                    }}
                  >
                    <ExtendedGrid container spacingX={2} alignItemsXxs="center">
                      <ExtendedGrid item xxs={12} xs={6} sm={5} md={4} lg={3}>
                        <Typography variant="subtitle2">
                          {t('workspacePage.groups.tableHeader.groupName')}
                        </Typography>
                      </ExtendedGrid>
                      <ExtendedGrid item xxs={12} xs={6} sm={7} md={4}>
                        <Typography variant="subtitle2">
                          {t('workspacePage.groups.tableHeader.numberOfUsers')}
                        </Typography>
                      </ExtendedGrid>

                      <ExtendedGrid item xxs textAlignXxs="right">
                        <Typography variant="subtitle2">{t('common.actions')}</Typography>
                      </ExtendedGrid>
                    </ExtendedGrid>
                  </ListItem>
                )}

                {workspaceGroups &&
                  sortGroupsAllOwnersFirst(workspaceGroups).map((item, index) => {
                    const { groupId, name, membersAmount, active } = item;
                    const canRemove = item.name !== 'All' && item.name !== 'Owners';
                    const canEditName = item.name !== 'All' && item.name !== 'Owners';
                    const canManage = item.name !== 'All';
                    return (
                      <GroupItem
                        key={index}
                        smallScreen={smallScreen}
                        item={item}
                        actions={
                          active
                            ? [
                                {
                                  icon: <ManageIcon />,
                                  name: t('common.manage'),
                                  showButton: canManage,
                                  onClick: () => {
                                    setActiveGroup({ groupId, name, membersAmount });
                                    setEditedSubpage('manage');
                                  },
                                },
                                {
                                  icon: <EditIcon />,
                                  name: t('common.changeName'),
                                  showButton: canEditName,
                                  onClick: () => {
                                    setActiveGroup({ groupId, name });
                                    setEditedSubpage('rename');
                                  },
                                },
                                {
                                  icon: <DeleteIcon />,
                                  name: t('common.delete'),
                                  showButton: canRemove,
                                  onClick: () => {
                                    setActiveGroup({ groupId, name });
                                    deleteDialogToggle.open();
                                  },
                                },
                              ]
                            : []
                        }
                      />
                    );
                  })}
              </List>
            ) : (
              <EmptyBanner
                src="/images/frames/error.svg"
                title={t('workspacePage.groups.fetchMainStructureError.header')}
                description={t('workspacePage.groups.fetchMainStructureError.description')}
              />
            )}
          </CardContent>
          {loading && <LinearProgress />}
        </InfiniteScroll>
      </Card>
      <DeleteGroup
        open={deleteDialogOpen}
        onClose={() => {
          deleteDialogToggle.close();
          setActiveGroup({});
        }}
        workspaceId={workspaceId}
        group={activeGroup}
      />
    </>
  );

  if (!didMount) {
    return null;
  }
  return (
    <>
      {renderSummaryPage()}
      <FullScreenDialog
        isOpen={!!editedSubpage}
        onBack={() => {
          if (editedSubpage === 'manage') {
            dispatch(identityActions.getWorkspaceStructure(workspaceId));
          }
          handleBack();
        }}
        onClose={handleBack}
        title={editedSubpage ? setTitle(editedSubpage) : null}
      >
        {editedSubpage ? renderEditPage(editedSubpage) : null}
      </FullScreenDialog>
    </>
  );
};

export default Groups;
