export default {
  selectMenu: {
    minHeight: 48,
    lineHeight: '24px',
    paddingRight: 32,
    '&[class*="MuiOutlinedInput-input-"] + input + $icon': {
      right: '2px !important',
    },
  },
  icon: {
    width: 16,
    height: 16,
    top: '50%',
    lineHeight: 1,
    marginTop: -8,
    right: 3,
  },
  select: {
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
};
