import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Drawer, Typography } from '@material-ui/core';
import moment from 'moment';
import { HeaderButton, ContentContainer, TopBarContainer } from 'src/components';
import { Notification as NotificationIcon, Close as CloseIcon } from 'src/components/CustomIcon';
import { RootState } from 'src/redux/reducers';
import communicationsActions from 'src/redux/actions/communications.actions';
import { SystemMessage } from 'src/models/communications.model';
import InfiniteScroll from 'react-infinite-scroll-component';
import ButtonTabs from 'src/components/ButtonTabs/ButtonTabs';
import usePrevious from 'src/helpers/hooks/usePrevious';
import { useStyles } from './styles';
import { notificationTabs, NotificationTabType } from './config';
import HeaderNotificationsItem from './subcomponents/HeaderNotificationsItem';
import HeaderNotificationsWebsocket from './HeaderNotificationsWebsocket';

const HeaderNotifications = (): JSX.Element => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    hasMoreReadSystemMessages,
    hasMoreUnreadSystemMessages,
    unreadSystemMessages,
    unreadSystemMessagesCounter,
    readSystemMessages,
    settingSystemMessageAsReadById,
  } = useSelector((state: RootState) => state.communicationsStore);

  const isAnyUnreadSystemMessages = unreadSystemMessagesCounter > 0;
  const isAnyReadSystemMessages = readSystemMessages.length > 0;
  const [open, setOpen] = useState<boolean>(false);
  const [showRingAnimation, setShowRingAnimation] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<number>(NotificationTabType.Unread);
  const hasMoreMessages =
    activeTab === NotificationTabType.Unread
      ? hasMoreUnreadSystemMessages
      : hasMoreReadSystemMessages;
  const dataLength =
    activeTab === NotificationTabType.Unread
      ? unreadSystemMessages.length
      : readSystemMessages.length;

  const prevState = usePrevious({
    open,
    unreadSystemMessagesCounter,
  });

  // Drawer toggle actions
  const handleDrawerToggle = {
    open: () => {
      setOpen(true);
      setShowRingAnimation(false);
    },
    close: () => {
      setOpen(false);
    },
  };

  // Grouped unread message for today and rest
  const groupedUnreadSystemMessages = {
    todaySystemMessages: isAnyUnreadSystemMessages
      ? unreadSystemMessages.filter((message) => moment(message.createdAt).isSame(moment(), 'day'))
      : [],
    earlierSystemMessages: isAnyUnreadSystemMessages
      ? unreadSystemMessages.filter((message) => !moment(message.createdAt).isSame(moment(), 'day'))
      : [],
  };

  // Grouped read message for today and rest
  const groupedReadSystemMessages = {
    todaySystemMessages: isAnyReadSystemMessages
      ? readSystemMessages.filter((message) => moment(message.createdAt).isSame(moment(), 'day'))
      : [],
    earlierSystemMessages: isAnyReadSystemMessages
      ? readSystemMessages.filter((message) => !moment(message.createdAt).isSame(moment(), 'day'))
      : [],
  };

  // Mark all unread notifications as read
  const setAllAsRead = () => {
    dispatch(communicationsActions.setAllNotificationSystemMessagesAsRead());
  };

  // Change tab
  const onTabChange = (value: number) => setActiveTab(value);

  // Close drawer and set default tab when user entered notificiation
  const goToSystemMessageSubjectCallback = () => {
    handleDrawerToggle.close();
    setActiveTab(NotificationTabType.Unread);
  };

  // fetch more mossegas on scroll
  const fetchMoreMessages = () => {
    if (activeTab === NotificationTabType.Unread)
      dispatch(communicationsActions.getUnreadSystemMessages({ offset: dataLength, limit: 20 }));
    else if (activeTab === NotificationTabType.Read)
      dispatch(communicationsActions.getReadSystemMessages({ offset: dataLength, limit: 20 }));
  };

  const onDrawerClose = () => {
    handleDrawerToggle.close();
    setActiveTab(NotificationTabType.Unread);
  };

  // fetch inital system messages for tab if no data
  useEffect(() => {
    if (open) {
      if (activeTab === NotificationTabType.Unread && unreadSystemMessages.length === 0)
        dispatch(communicationsActions.getUnreadSystemMessages({ offset: 0, limit: 20 }));
      else if (activeTab === NotificationTabType.Read && readSystemMessages.length === 0)
        dispatch(communicationsActions.getReadSystemMessages({ offset: 0, limit: 20 }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, activeTab]);

  // Update list of unread if counter incremented
  useEffect(() => {
    if (
      unreadSystemMessages.length > 0 &&
      unreadSystemMessagesCounter !== unreadSystemMessages.length &&
      open
    ) {
      dispatch(
        communicationsActions.getUnreadSystemMessages({
          offset: 0,
          limit: 20,
          replace: true,
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unreadSystemMessagesCounter]);

  // fetch counter on mount
  useEffect(() => {
    dispatch(communicationsActions.getUnreadSystemMessagesCounter());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // fetch counter on mount
  useEffect(() => {
    if (!open) {
      dispatch(communicationsActions.clearCommunicationsStore());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  // run ring animation for bell icon
  useEffect(() => {
    if (
      prevState?.unreadSystemMessagesCounter &&
      prevState?.unreadSystemMessagesCounter < unreadSystemMessagesCounter
    ) {
      setShowRingAnimation(true);
      setTimeout(() => setShowRingAnimation(false), 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unreadSystemMessagesCounter, prevState]);
  return (
    <>
      <HeaderNotificationsWebsocket />
      <HeaderButton
        icon={<NotificationIcon className={showRingAnimation ? classes.newNotification : ''} />}
        onClick={handleDrawerToggle.open}
        badge={isAnyUnreadSystemMessages}
        badgeContent={isAnyUnreadSystemMessages ? `+${unreadSystemMessagesCounter}` : null}
        tooltip={t<string>('notifications.header')}
      />
      <Drawer
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="right"
        open={open}
        onClose={onDrawerClose}
      >
        <div className={classes.list} role="presentation">
          <TopBarContainer className={classes.top}>
            <Typography variant="h6">
              <strong>{t<string>('notifications.header')}</strong>
            </Typography>
            <HeaderButton
              className={classes.closeButton}
              color="primary"
              onClick={onDrawerClose}
              icon={<CloseIcon />}
              tooltip={t<string>('common.close')}
            />
          </TopBarContainer>

          <ContentContainer vertical={false} className={classes.mainContent} id="scroll">
            <div className={classes.tabs}>
              <ButtonTabs value={activeTab} tabs={notificationTabs} onChange={onTabChange} />
            </div>
            <InfiniteScroll
              loader={false}
              scrollThreshold={0.9}
              style={{
                overflow: 'unset',
              }}
              hasMore={hasMoreMessages}
              dataLength={dataLength}
              next={fetchMoreMessages}
              refreshFunction={fetchMoreMessages}
              scrollableTarget="scroll"
            >
              {activeTab === NotificationTabType.Unread && (
                <>
                  {isAnyUnreadSystemMessages && (
                    <div className={classes.clearButtonWrapper}>
                      <Button
                        size="small"
                        onClick={setAllAsRead}
                        variant="text"
                        classes={{ root: classes.clearButton }}
                      >
                        {t<string>('notifications.setAsRead.setAll')}
                      </Button>
                    </div>
                  )}
                  {groupedUnreadSystemMessages.todaySystemMessages?.length > 0 && (
                    <>
                      <div className={classes.header}>
                        <Typography variant="subtitle2">
                          {t<string>('notifications.from.today')}
                        </Typography>
                      </div>

                      <div className={classes.listNotifications}>
                        {groupedUnreadSystemMessages.todaySystemMessages.map(
                          (item: SystemMessage) => (
                            <HeaderNotificationsItem
                              key={`notification_t_u_${item.id}`}
                              goToSystemMessageSubjectCallback={goToSystemMessageSubjectCallback}
                              notification={item}
                              isPending={settingSystemMessageAsReadById === item.id}
                              live
                            />
                          )
                        )}
                      </div>
                    </>
                  )}
                  {groupedUnreadSystemMessages.earlierSystemMessages?.length > 0 ? (
                    <>
                      <div className={classes.header}>
                        <Typography variant="subtitle2">
                          {t<string>('notifications.from.earlier')}
                        </Typography>
                      </div>
                      <div className={classes.listNotifications}>
                        {groupedUnreadSystemMessages.earlierSystemMessages.map(
                          (item: SystemMessage) => (
                            <HeaderNotificationsItem
                              key={`notification_e_u_${item.id}`}
                              goToSystemMessageSubjectCallback={goToSystemMessageSubjectCallback}
                              isPending={settingSystemMessageAsReadById === item.id}
                              notification={item}
                              live
                            />
                          )
                        )}
                      </div>
                    </>
                  ) : null}
                </>
              )}
              {activeTab === NotificationTabType.Read && (
                <>
                  {groupedReadSystemMessages.todaySystemMessages?.length > 0 && (
                    <>
                      <div className={classes.header}>
                        <Typography variant="subtitle2">
                          {t<string>('notifications.from.today')}
                        </Typography>
                      </div>

                      <div className={classes.listNotifications}>
                        {groupedReadSystemMessages.todaySystemMessages.map(
                          (item: SystemMessage) => (
                            <HeaderNotificationsItem
                              key={`notification_r_t_${item.id}`}
                              goToSystemMessageSubjectCallback={goToSystemMessageSubjectCallback}
                              notification={item}
                              isPending={false}
                              live
                            />
                          )
                        )}
                      </div>
                    </>
                  )}
                  {groupedReadSystemMessages.earlierSystemMessages?.length > 0 ? (
                    <>
                      <div className={classes.header}>
                        <Typography variant="subtitle2">
                          {t<string>('notifications.from.earlier')}
                        </Typography>
                      </div>
                      <div className={classes.listNotifications}>
                        {groupedReadSystemMessages.earlierSystemMessages.map(
                          (item: SystemMessage) => (
                            <HeaderNotificationsItem
                              key={`notification_r_e_${item.id}`}
                              goToSystemMessageSubjectCallback={goToSystemMessageSubjectCallback}
                              isPending={false}
                              notification={item}
                              live
                            />
                          )
                        )}
                      </div>
                    </>
                  ) : null}
                </>
              )}
            </InfiniteScroll>
          </ContentContainer>
        </div>
      </Drawer>
    </>
  );
};

export default HeaderNotifications;
