import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ breakpoints, palette, spacing, transitions }) =>
    createStyles({
      root: {
        display: 'flex',
        color: palette.text.primary,
        paddingTop: spacing(2),
        paddingBottom: spacing(2),
        '&:first-child': {
          paddingTop: 0,
        },
        '&:last-child': {
          paddingBottom: 0,
        },
        '&:hover': {
          color: 'inherit',
          '& $actionButton': {
            color: palette.text.primary,
          },
          '& $colorOnHover, & $actionButtonIcon': {
            color: `${palette.primary.main} !important`,
          },
        },
      },
      rootContainer: {
        display: 'flex',
        '&:first-child': {
          marginTop: spacing(-2),
        },
        '&:last-child': {
          marginBottom: spacing(-2),
        },
      },
      colorOnHover: {
        transition: transitions.create(['color'], {
          duration: transitions.duration.short,
        }),
      },
      actionButtonsWrapper: {
        textAlign: 'right',
        marginRight: spacing(-1.25),
        [breakpoints.down('md')]: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
        },
      },
      actionButton: {
        paddingLeft: spacing(1.25),
        paddingRight: spacing(1.25),
      },
      actionButtonIcon: {
        transition: 'inherit',
        color: `${palette.text.secondary} !important`,
      },
      mobileActionButtonWrapper: {
        position: 'relative',
        top: 0,
        right: spacing(-1),
        transform: 'none',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      },
    }),
  { index: 0 }
);
