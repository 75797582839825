import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ palette, shadows, shape, spacing, typography }) =>
    createStyles({
      root: {
        cursor: 'pointer',
        backgroundColor: palette.common.white,
        justifyContent: 'flex-start',
        textAlign: 'left',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        marginTop: spacing(0.5),
        padding: `${spacing(1)}px ${spacing(5)}px ${spacing(1)}px ${spacing(1)}px`,
        marginLeft: spacing(-1),
        marginRight: spacing(-1),
        width: `calc(100% + ${spacing(2)}px)`,
        borderRadius: shape.borderRadius,
        transition: 'all .6s ease-out',
        '&:hover, &:focus': {
          backgroundColor: palette.action.hover,
        },
        '&:hover $notificationContent': {
          color: palette.primary.main,
        },
      },
      disabled: {
        opacity: '1 !important',
        pointerEvents: 'none',
      },
      textMultiline: {
        margin: 0,
      },
      pending: {
        marginTop: '-58px',
        zIndex: -1,
      },
      read: {},
      notRead: {
        '& $notificationContent': {
          fontWeight: typography.fontWeightBold,
        },
      },
      notificationContent: {},
      date: {
        fontSize: 10,
        lineHeight: 1.3,
      },
      checkIconWrapper: {
        position: 'absolute',
        zIndex: 1,
        right: 4,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover': {
          backgroundColor: palette.action.hover,
          '& svg': {
            color: palette.primary.main,
          },
        },
        '& svg': {
          display: 'block',
          backgroundColor: palette.background.paper,
          color: palette.secondary.main,
          borderRadius: '50%',
          padding: spacing(0.5),
          boxShadow: shadows[6],
        },
      },
      avatar: {
        position: 'relative',
        display: 'flex',
        backgroundColor: 'rgb(222, 238, 250);',
        padding: 6,
        borderRadius: '100%',

        '& svg': {
          fontSize: spacing(2.5),
          color: palette.info.light,
        },
        '& $loader': {
          position: 'absolute',
          top: 0,
          left: 0,
        },
      },
      loader: {},
    }),
  { index: 0 }
);
