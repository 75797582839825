import React from 'react';
import { string, any } from 'prop-types';
import { Typography } from '@material-ui/core';
import { useStyles } from './styles';

const LabelValueReadonly = ({ label, value }) => {
  const classes = useStyles();

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 10,
      }}
    >
      {label && <Typography variant="subtitle2">{label}</Typography>}
      {value ? (
        <Typography className={classes.bold} variant="body2" color="textPrimary">
          {value}
        </Typography>
      ) : (
        '-'
      )}
    </div>
  );
};

LabelValueReadonly.propTypes = {
  label: string,
  // eslint-disable-next-line react/forbid-prop-types
  value: any,
};

export default LabelValueReadonly;
