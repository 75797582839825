import { createStyles, makeStyles } from '@material-ui/core';

export const useUsersManagerSectionStyle = makeStyles(
  ({ spacing, palette, breakpoints }) =>
    createStyles({
      sectionWrapper: {
        display: 'flex',
        [breakpoints.down('md')]: {
          flexDirection: 'column',
        },
      },
      sticky: {
        position: 'sticky',
        top: 0,
      },
      leftContentWrapper: {
        minWidth: 380,
        width: 380,
        [breakpoints.down('md')]: {
          width: '100%',
        },
      },
      leftContentChildren: {
        marginBottom: spacing(1),
      },
      title: {
        marginBottom: spacing(1),
      },
      description: {
        marginBottom: spacing(2),
      },
      rightContentWrapper: {
        width: '100%',
        paddingLeft: spacing(8),
        [breakpoints.down('md')]: {
          paddingLeft: 0,
          marginTop: spacing(2),
        },
      },
      divider: {
        marginTop: spacing(5),
        background: palette.action.selected,
        marginBottom: spacing(4),
      },
    }),
  { index: 0 }
);
