import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ breakpoints, spacing }) =>
    createStyles({
      root: {
        '&:not(:last-child)': {
          marginBottom: spacing(1),
        },
      },
      list: {
        [breakpoints.up('md')]: {
          paddingLeft: spacing(4),
        },
        [breakpoints.up('xl')]: {
          paddingLeft: spacing(3),
        },
      },
      selectWrapper: {
        [breakpoints.up('md')]: {
          maxWidth: breakpoints.values.xs,
        },
      },
      actionButton: {
        [breakpoints.down('sm')]: {
          display: 'flex',
          margin: '0 auto',
          maxWidth: breakpoints.values.xs,
        },
      },
      infoWithIcon: {
        display: 'flex',
        alignItems: 'center',
        '& svg': {
          marginRight: spacing(0.75),
          fontSize: 18,
        },
      },
    }),
  { index: 0 }
);
