import React from 'react';
import { createStyles, makeStyles, Tooltip } from '@material-ui/core';
import { HelpOutline as HelpOutlineIcon } from '@material-ui/icons';

export const useStyles = makeStyles(
  ({ palette }) =>
    createStyles({
      helpIcon: {
        fontSize: 16,
        color: palette.primary.main,
        marginLeft: 4,
        verticalAlign: 'text-bottom',
      },
    }),
  { index: 0 }
);

interface InfoTooltipProps {
  message: string;
}

const InfoTooltip = ({ message }: InfoTooltipProps): JSX.Element => {
  const classes = useStyles();
  return (
    <Tooltip title={message} className={classes.helpIcon}>
      <HelpOutlineIcon />
    </Tooltip>
  );
};
export default InfoTooltip;
