import React, { useState } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Menu, MenuItem, Tooltip, Typography } from '@material-ui/core';
import { documentDisplaySelector } from 'src/redux/selectors/documentDisplay.selector';
import { DocumentMemberRole } from 'src/models/documentUsersManagement.model';
import { ArrowDownSLine as ChevronDownIcon } from 'src/components/CustomIcon';
import { useUserItemRolesStyles } from './styles';

type availableRole = {
  label: string;
  description: string;
  value: DocumentMemberRole;
};

export interface UserItemRolesProps {
  role?: DocumentMemberRole;

  disabled?: boolean;
  onRoleChange?: (role: DocumentMemberRole) => void;
}

const UserItemRoles = ({
  role,
  onRoleChange,
  disabled = false,
}: UserItemRolesProps): JSX.Element => {
  const classes = useUserItemRolesStyles();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isCertificate = useSelector(documentDisplaySelector.isCertificate, undefined);
  const availableRoles: availableRole[] = [
    {
      label: t<string>('documentUserManager.role.admin'),
      description: isCertificate
        ? t<string>('documentUserManager.roleDescription.adminCert')
        : t<string>('documentUserManager.roleDescription.admin'),
      value: DocumentMemberRole.ADMIN,
    },
    {
      label: t<string>('documentUserManager.role.editor'),
      description: t<string>('documentUserManager.roleDescription.editor'),
      value: DocumentMemberRole.EDITOR,
    },
    ...(isCertificate // certificates don't have auditor role
      ? []
      : [
          {
            label: t<string>('documentUserManager.role.auditor'),
            description: t<string>('documentUserManager.roleDescription.auditor'),
            value: DocumentMemberRole.AUDITOR,
          },
        ]),
    {
      label: t<string>('documentUserManager.role.viewer'),
      description: t<string>('documentUserManager.roleDescription.viewer'),
      value: DocumentMemberRole.VIEWER,
    },
  ];

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (e) setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const isOpen = Boolean(anchorEl);

  return (
    <>
      <Tooltip title={t<string>('documentUserManager.changeRole')}>
        <div>
          <Button
            className={classes.button}
            classes={{ root: clsx(isOpen && classes.opened) }}
            size="small"
            variant="text"
            color="default"
            disabled={disabled}
            onClick={handleClick}
            endIcon={!disabled && <ChevronDownIcon className={classes.icon} />}
          >
            <Typography variant="body2">
              {availableRoles.find((item) => item.value === role)?.label}
            </Typography>
          </Button>
        </div>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        keepMounted
        open={isOpen}
        onClose={handleClose}
      >
        {availableRoles.map((item, index) => (
          <MenuItem
            key={index}
            className={classes.menuWrapper}
            value={item.value}
            selected={role === item.value}
            onClick={() => {
              if (onRoleChange) onRoleChange(item.value);
              handleClose();
            }}
          >
            <Typography className={classes.title}>{item.label}</Typography>
            <Typography variant="body2" className={classes.description}>
              {item.description}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
export default UserItemRoles;
