import { createStyles, makeStyles } from '@material-ui/core';
import { lighten } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  ({ palette }) =>
    createStyles({
      chip: {
        marginRight: 6,
        background: lighten(palette.primary.main, 0.95),
        color: palette.primary.main,
      },
    }),
  { index: 0 }
);
