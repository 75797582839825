import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Button, Fab, useMediaQuery, createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  () =>
    createStyles({
      smallFab: {
        width: 24,
        minWidth: 24,
        height: 24,
        minHeight: 24,
        '& svg': {
          width: 18,
          height: 18,
        },
      },
    }),
  { index: 0 }
);

const EnterButtonCell = ({ className: classNameProp, onClick, type, label, icon }) => {
  const classes = useStyles();
  const mobileScreen = useMediaQuery(({ breakpoints }) => breakpoints.down('xs'));

  const getButton = () => {
    switch (type) {
      case 'icon':
        return (
          <Fab
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onClick();
              return false;
            }}
            size="small"
            classes={{
              root: classNameProp,
              sizeSmall: clsx(mobileScreen && classes.smallFab),
            }}
          >
            {icon}
          </Fab>
        );
      case 'text':
      default:
        return (
          <Button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onClick();
              return false;
            }}
          >
            {label}
          </Button>
        );
    }
  };

  return getButton();
};

EnterButtonCell.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['icon', 'text']),
  label: PropTypes.string,
  icon: PropTypes.node,
};

export default EnterButtonCell;
