import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ breakpoints, custom, palette }) =>
    createStyles({
      root: {},
      greyBackground: {
        background: palette.background.default,
      },
      content: {
        width: '100%',
        flex: '1 1 auto',
        overflowX: 'hidden',
        overflowY: 'auto',
        paddingTop: '0 !important',
        height: `calc(100vh - ${custom.headerMobileNavHeight}px)`,
        [breakpoints.up('md')]: {
          height: `calc(100vh - ${custom.headerNavHeight}px)`,
        },
      },
    }),
  { index: 0 }
);
