import React, { useState } from 'react';
import { Grid, InputAdornment, TextField, Typography } from '@material-ui/core';
import DateDropdown, { DateDropdownType } from 'src/components/DateDropdown/DateDropdown';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { RootState } from 'src/redux/reducers';
import MomentAdapter from '@material-ui/pickers/adapter/moment';
import { DateRangePicker, LocalizationProvider } from '@material-ui/pickers';
import { AdvancedSearchFields } from 'src/models/documents.model';
import documentsActions from 'src/redux/actions/documents.actions';
import Field from '../../subcomponents/AdvancedSearchModalField';
import { CalendarSelectDay as CalendarIcon } from '../../../CustomIcon';
import { useStyles } from './styles';

type DateFieldProps = {
  type: AdvancedSearchFields.DATE_CREATED | AdvancedSearchFields.DATE_MODIFIED;
};

const DateField = ({ type }: DateFieldProps): JSX.Element => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const classes = useStyles();
  const date = useSelector((state: RootState) => state.documentsStore.draftAdvancedSearch[type]);
  const [error, setError] = React.useState<Array<string | null>>([null, null]);
  const [openState, setOpenState] = useState(false);
  const [dateValue, setDateValue] = useState<Array<moment.Moment | null>>([date.from, date.to]);

  const fieldName = {
    [DateDropdownType.ANY]: t('advancedSearch.dateOptions.anyTime'),
    [DateDropdownType.TODAY]: t('advancedSearch.dateOptions.today'),
    [DateDropdownType.YESTERDAY]: t('advancedSearch.dateOptions.yesterday'),
    [DateDropdownType.LAST_7_DAYS]: t('advancedSearch.dateOptions.last7Days'),
    [DateDropdownType.LAST_30_DAYS]: t('advancedSearch.dateOptions.last30Days'),
    [DateDropdownType.BETWEEN]: t('advancedSearch.dateOptions.between'),
  };
  const onSelect = (newType: DateDropdownType) =>
    dispatch(
      documentsActions.updateDraftAdvancedSearch(type, {
        ...date,
        type: newType,
      })
    );

  const onDateChange = (newDate: Array<moment.Moment | null>) => {
    dispatch(
      documentsActions.updateDraftAdvancedSearch(type, {
        ...date,
        from: newDate[0],
        to: newDate[1],
      })
    );
  };

  const title = {
    [AdvancedSearchFields.DATE_CREATED]: t<string>('advancedSearch.fields.dateCreated.label'),
    [AdvancedSearchFields.DATE_MODIFIED]: t<string>('advancedSearch.fields.dateModified.label'),
  };

  return (
    <>
      <Field title={title[type]} withInputWrapper>
        <DateDropdown onSelect={onSelect} selectedItem={date.type}>
          <Typography>{fieldName[date.type]}</Typography>
        </DateDropdown>
      </Field>
      {date.type === DateDropdownType.BETWEEN && (
        <Field title="">
          <LocalizationProvider dateAdapter={MomentAdapter} locale={language}>
            <DateRangePicker
              inputFormat="yyyy-MM-DD"
              mask="____-__-__"
              open={openState}
              onOpen={() => {
                setOpenState(true);
              }}
              onError={([startReason, endReason], [start, end]) => {
                if (
                  startReason === 'invalidRange' &&
                  start &&
                  end &&
                  start.valueOf() === end.valueOf()
                ) {
                  setOpenState(false);
                  setError([null, null]);
                  const date = [moment(start), moment(end)];
                  setDateValue(date);
                  return;
                }

                setError([startReason, endReason]);
              }}
              showToolbar={false}
              allowSameDateSelection
              disableCloseOnSelect={false}
              onAccept={(date: unknown) => {
                const newDate: Array<moment.Moment | null> = date as Array<moment.Moment | null>;
                onDateChange(newDate);
                setOpenState(false);
              }}
              value={[dateValue[0], dateValue[1]]}
              onChange={setDateValue}
              renderInput={(startProps, endProps) => (
                <Grid container spacing={1} wrap="nowrap">
                  <Grid item>
                    <TextField
                      {...startProps}
                      fullWidth
                      error={Boolean(error[0])}
                      className={classes.dateInput}
                      label={undefined}
                      helperText={undefined}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <CalendarIcon color="disabled" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      {...endProps}
                      fullWidth
                      error={Boolean(error[1])}
                      className={classes.dateInput}
                      label={undefined}
                      helperText={undefined}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <CalendarIcon color="disabled" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              )}
            />
          </LocalizationProvider>
        </Field>
      )}
    </>
  );
};

export default DateField;
