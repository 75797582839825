import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme,
} from '@material-ui/core';
import { useStyles } from './styles';

const ConfirmDialog = ({
  className: classNameProp,
  children,
  open,
  smallTitle,
  variant,
  title,
  subtitle,
  subtitleColor,
  applyButtonText,
  cancelButtonText,
  actionAccept,
  actionCancel,
  onExit,
  maxWidth,
  isLoading,
  isDisabled,
}) => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <Dialog
      maxWidth={maxWidth}
      disableBackdropClick
      disableEscapeKeyDown
      open={open}
      onExit={onExit}
      classes={{
        root: clsx(
          classes.root,
          {
            [classes[`${String(variant)}RootVariant`]]: variant,
          },
          classNameProp
        ),
      }}
    >
      {title && (
        <DialogTitle disableTypography>
          <Typography variant={smallTitle ? 'h6' : 'h2'}>{title}</Typography>
        </DialogTitle>
      )}

      {subtitle && (
        <DialogContent className={classes.subtitleWrapper}>
          <Typography style={subtitleColor ? { color: theme.palette.error.main } : {}}>
            {subtitle}
          </Typography>
        </DialogContent>
      )}

      {children}

      {(applyButtonText || cancelButtonText) && (
        <DialogActions className={classes.actionsWrapper}>
          {applyButtonText && (
            <Button
              disabled={isDisabled || isLoading}
              onClick={actionAccept}
              fullWidth
              className={clsx(classes.button, classes.applyButton)}
              size="large"
              endIcon={isLoading && <CircularProgress size={20} color="inherit" />}
            >
              {applyButtonText}
            </Button>
          )}
          {cancelButtonText && (
            <Button
              disabled={isLoading}
              onClick={actionCancel}
              fullWidth
              variant="text"
              size="large"
              className={classes.button}
            >
              {cancelButtonText}
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

ConfirmDialog.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(['primary', 'success', 'error', 'information']),
  open: PropTypes.bool,
  smallTitle: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  subtitleColor: PropTypes.oneOf([false, 'error']),
  applyButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  actionAccept: PropTypes.func,
  actionCancel: PropTypes.func,
  onExit: PropTypes.func,
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', false]),
};

ConfirmDialog.defaultProps = {
  open: false,
  subtitleColor: false,
  variant: 'primary',
  maxWidth: 'xs',
  isLoading: false,
  isDisabled: false,
};

export default ConfirmDialog;
