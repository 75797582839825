import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ palette, shape, spacing, transitions }) =>
    createStyles({
      root: {},
      rootOpen: {
        '& $header': {
          color: palette.primary.main,
        },
        '& $headerIcon': {
          transform: 'rotate(-180deg)',
        },
      },
      notClickable: {
        cursor: 'default',
      },
      header: {
        padding: spacing(1, 2),
        width: '100%',
        minHeight: 48,
        justifyContent: 'space-between',
        borderRadius: shape.borderRadius,
        backgroundColor: palette.background.default,
      },
      headerIcon: {
        transition: transitions.create(['color', 'transform', 'opacity'], {
          duration: transitions.duration.short,
        }),
        '& svg': {
          display: 'block',
          width: 16,
          height: 16,
        },
      },
      content: {
        padding: `${spacing(2)}px 0 ${spacing(1)}px 0`,
      },
    }),
  { index: 0 }
);
