import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ palette }) =>
    createStyles({
      menuItem: {
        width: 250,
      },
      menuFooter: {
        display: 'flex',
        alignItems: 'center',
        padding: 8,
        justifyContent: 'space-between',
      },
      esignIcon: {
        fontSize: 16,
        marginRight: 6,
        color: palette.primary.light,
      },
      driveIcon: {
        fontSize: 16,
        marginRight: 6,
        color: palette.common.purple,
      },
      certificateIcon: {
        fontSize: 16,
        marginRight: 6,
        color: palette.common.green,
      },
      folderIcon: {
        fontSize: 16,
        marginRight: 6,
      },
    }),
  { index: 0 }
);
