import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { ButtonBase, Typography } from '@material-ui/core';
import Dropzone from 'react-dropzone';
import { SmallContentWithScrollbar } from 'src/components';
import { FileColor as FileColorIcon, ArrowTop as ArrowTopIcon } from 'src/components/CustomIcon';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import { useStyles } from '../../styles';
import { DropzoneVariant, getAcceptableFiles, getMaxSize } from '../../helpers';

const StepUpload = ({ classes: classesProp, smallScreen, endContent, handleDrop, variant }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const workspacePermissions = useSelector(
    (state) => state.identityStore.workspacePermissions,
    undefined
  );
  const avatarUploadMode = variant === DropzoneVariant.AVATAR;

  const isHavePermissions =
    (workspacePermissions.includes('DOCUMENT_SERVICE_USER_GET_FILE_UPLOAD') &&
      workspacePermissions.includes('DOCUMENT_SERVICE_USER_POST_DOCUMENTS')) ||
    avatarUploadMode ||
    variant === DropzoneVariant.CERTIFICATE;

  const acceptableFiles = getAcceptableFiles(variant);
  const MAX_SIZE = getMaxSize(variant);

  return (
    <SmallContentWithScrollbar
      scrollableContent
      classes={{ root: clsx(classes.root, classesProp), content: classes.content }}
      size={smallScreen ? 'large' : 'small'}
      startContent={
        <>
          <Typography
            variant={smallScreen ? 'h3' : 'h2'}
            gutterBottom={!smallScreen}
            align={smallScreen ? 'left' : 'center'}
            className={classes.title}
          >
            {
              {
                [DropzoneVariant.AVATAR]: t('dropzones.uploadPhotosTitle'),
                [DropzoneVariant.DOCUMENT]: t('dropzones.uploadTitle'),
                [DropzoneVariant.CERTIFICATE]: t('dropzones.uploadCertificateTitle'),
              }[variant]
            }
          </Typography>

          {!smallScreen && (
            <Typography variant="body1" align="center" className={classes.subtitle}>
              {
                {
                  [DropzoneVariant.AVATAR]: t('dropzones.uploadPhotosSubTitle'),
                  [DropzoneVariant.DOCUMENT]: t('dropzones.uploadSubTitle'),
                  [DropzoneVariant.CERTIFICATE]: t('dropzones.uploadSubTitle'),
                }[variant]
              }
            </Typography>
          )}
        </>
      }
      endContent={endContent}
    >
      <div className={classes.dropzoneWrapper}>
        {!isHavePermissions && (
          <Alert severity="error" classes={{ root: classes.alert }}>
            {t('selectTemplate.permissionAlert')}
          </Alert>
        )}
        <Dropzone
          onDrop={handleDrop}
          accept={acceptableFiles}
          maxSize={MAX_SIZE}
          disabled={!isHavePermissions}
          classes={{ isDragActive: classes.iconBox }}
          multiple={!avatarUploadMode}
        >
          {({ getRootProps, getInputProps, isDragAccept, isDragReject, isDragActive }) => (
            <ButtonBase
              component="div"
              {...getRootProps({
                className: clsx(
                  classes.dropzone,
                  !isHavePermissions ? classes.dropzoneDisabled : null,
                  isDragActive ? classes.dropzoneActive : null,
                  isDragReject ? classes.dropzoneReject : null,
                  isDragAccept ? classes.dropzoneAccept : null
                ),
              })}
            >
              <input {...getInputProps()} />
              <div className={classes.iconBox}>
                <FileColorIcon className={classes.icon} />
                <div className={classes.smallIconBox}>
                  <ArrowTopIcon className={classes.smallIcon} />
                </div>
              </div>
              <Typography variant="h5" align="center">
                {t('dropzones.uploadFileTitle')}
              </Typography>
              <Typography variant="body1" align="center" className={classes.textSmall}>
                {
                  {
                    [DropzoneVariant.AVATAR]: t('dropzones.uploadImageFileSubTitle'),
                    [DropzoneVariant.DOCUMENT]: t('dropzones.uploadFileSubTitle'),
                    [DropzoneVariant.CERTIFICATE]: t('dropzones.uploadFileSubTitle'),
                  }[variant]
                }
              </Typography>
            </ButtonBase>
          )}
        </Dropzone>
      </div>
    </SmallContentWithScrollbar>
  );
};

StepUpload.propTypes = {
  classes: PropTypes.string,
  smallScreen: PropTypes.bool,
  handleDrop: PropTypes.func,
  endContent: PropTypes.node,
  variant: PropTypes.oneOf([
    DropzoneVariant.AVATAR,
    DropzoneVariant.DOCUMENT,
    DropzoneVariant.CERTIFICATE,
  ]).isRequired,
};

export default StepUpload;
