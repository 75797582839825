import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Button, Menu, MenuItem } from '@material-ui/core';
import { ArrowDownSLine as ChevronDownIcon } from 'src/components/CustomIcon';

import { useStyles } from './styles';

const RoleSelection = ({
  className: classNameProp,
  role,
  availableRoles,
  handleRoleChange,
  active,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isOpen = Boolean(anchorEl);

  return (
    <>
      <Button
        classes={{ root: clsx(classes.root, isOpen && classes.opened, classNameProp) }}
        size="small"
        variant="text"
        color="default"
        onClick={handleClick}
        disabled={!active}
        endIcon={active && <ChevronDownIcon className={classes.icon} />}
      >
        {availableRoles.find((item) => item.value === role)?.label}
      </Button>
      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        keepMounted
        open={isOpen}
        onClose={handleClose}
      >
        {availableRoles.map((item, index) => (
          <MenuItem
            key={index}
            value={item.value}
            selected={role === item.value}
            onClick={() => {
              handleRoleChange(item.value);
              handleClose();
            }}
          >
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

RoleSelection.propTypes = {
  className: PropTypes.string,
  role: PropTypes.string,
  availableRoles: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  handleRoleChange: PropTypes.func,
  active: PropTypes.bool,
};

RoleSelection.defaultProps = {};

export default RoleSelection;
