import React from 'react';
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon';

export default createSvgIcon(
  <>
    <g clipPath="url(#clip0_6814_7315)">
      <path
        d="M17.5 5.48965V5.5H14.0351C12.911 5.5002 11.9998 4.58728 12 3.46112L12 0H12.0101L17.5 5.48965Z"
        fill="#5BCDFF"
      />
      <path
        d="M17.5 5.48496V18C17.5002 19.1047 16.613 20.0004 15.5185 20H4.4815C3.38703 20.0004 2.49961 19.1047 2.5 18V2C2.49961 0.895313 3.38703 -0.000390497 4.4815 1.27718e-07H12.041L12.05 0L12.1401 3.40508C12.1397 4.50977 13.0271 5.40527 14.1216 5.40508H17.495V5.48496H17.5Z"
        fill="#00B1FF"
      />
      <rect x="5" y="9.1665" width="6.66667" height="1.25" rx="0.625" fill="white" />
      <path
        d="M5 13.125C5 12.7798 5.27982 12.5 5.625 12.5H14.375C14.7202 12.5 15 12.7798 15 13.125C15 13.4702 14.7202 13.75 14.375 13.75H5.625C5.27982 13.75 5 13.4702 5 13.125Z"
        fill="white"
      />
      <path
        d="M5 16.4585C5 16.1133 5.27982 15.8335 5.625 15.8335H14.375C14.7202 15.8335 15 16.1133 15 16.4585C15 16.8037 14.7202 17.0835 14.375 17.0835H5.625C5.27982 17.0835 5 16.8037 5 16.4585Z"
        fill="white"
      />
      <path
        d="M11.5984 17.5006C10.861 15.8119 11.3204 13.8395 12.7277 12.6504C14.1351 11.4613 16.1563 11.3378 17.6982 12.3467C19.2399 13.3557 19.9357 15.2575 19.4094 17.0233C18.8832 18.789 17.2596 19.9995 15.4172 19.9998C13.7592 20.005 12.2574 19.0222 11.5984 17.5006Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4095 16.692L16.2739 13.8259C16.3188 13.7811 16.3785 13.7543 16.4418 13.7505C16.5051 13.7466 16.5676 13.766 16.6176 13.805L16.6436 13.8259L17.4248 14.6075C17.4695 14.6525 17.4963 14.7123 17.5002 14.7756C17.504 14.8389 17.4846 14.9014 17.4457 14.9515L17.4248 14.9775L14.5605 17.8437C14.5189 17.8831 14.4661 17.9086 14.4094 17.9167H13.5944C13.5304 17.9171 13.4685 17.894 13.4205 17.8517C13.3724 17.8094 13.3417 17.7509 13.334 17.6874V16.877C13.3342 16.8195 13.3534 16.7636 13.3887 16.7181L13.4095 16.692ZM16.4588 14.3783L13.8548 16.9839V17.3981H14.2688L15.5708 16.0953L16.8728 14.7925L16.4588 14.3783Z"
        fill="#00B1FF"
      />
    </g>
    <defs>
      <clipPath id="clip0_6814_7315">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </>,
  'ESignIcon'
);
