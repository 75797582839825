import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ breakpoints, spacing }) =>
    createStyles({
      root: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      },
      header: {
        justifyContent: 'space-between',
      },
      content: {
        width: '100%',
        flex: 1,
        overflowY: 'auto',
        paddingTop: spacing(1),
        paddingBottom: spacing(1),
        [breakpoints.up('lg')]: {
          paddingBottom: spacing(3),
        },
      },
    }),
  { index: 0 }
);
