import shape from '@material-ui/core/styles/shape';
import transitions from '@material-ui/core/styles/transitions';
import breakpoints from '../breakpoints';
import palette from '../palette';
import spacing from '../spacing';
import shadows from '../shadows';
import typography from '../typography';

export default {
  root: {
    borderRadius: shape.borderRadius,
    padding: spacing(0, 1),
    minHeight: 48,
    lineHeight: 1.2,
    fontSize: typography.htmlFontSize,
    minWidth: 'auto',
    transition: transitions.create(['background-color', 'color', 'box-shadow', 'border'], {
      duration: transitions.duration.short,
    }),
    [breakpoints.up('sm')]: {
      minWidth: 'auto',
    },
    [breakpoints.up('md')]: {
      minWidth: 'auto',
      padding: spacing(0, 1.5),
    },
    [breakpoints.up('lg')]: {
      padding: spacing(0, 2),
    },
  },
  wrapper: {},
  labelIcon: {
    minHeight: 48,
    paddingTop: 0,
    '& $wrapper': {
      flexDirection: 'row',
      '& > *:first-child': {
        fontSize: 20,
        marginBottom: 0,
        marginRight: spacing(0.75),
        [breakpoints.up('md')]: {
          marginRight: spacing(1),
        },
      },
    },
  },
  selected: {},
  textColorPrimary: {
    color: palette.text.primary,
    '& svg': {
      fill: palette.text.disabled,
    },
    '&$selected': {
      boxShadow: shadows[6],
      backgroundColor: palette.background.paper,
      '& svg': {
        fill: palette.primary.light,
      },
    },
  },
};
