import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Backdrop, CircularProgress } from '@material-ui/core';
import { useStyles } from './styles';

const FullPageLoader = ({ className: classNameProp, open, transitionDuration }) => {
  const classes = useStyles();

  return (
    <Backdrop
      open={open}
      className={clsx(classes.root, classNameProp)}
      transitionDuration={transitionDuration}
    >
      <CircularProgress size={48} color="inherit" />
    </Backdrop>
  );
};

FullPageLoader.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  transitionDuration: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({
      appear: PropTypes.number,
      enter: PropTypes.number,
      exit: PropTypes.number,
    }),
  ]),
};

FullPageLoader.defaultProps = {
  open: false,
  transitionDuration: { enter: 300, exit: 300 },
};

export default FullPageLoader;
