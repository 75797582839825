export default {
  dialogRoot: {
    minWidth: 'auto',
    maxWidth: 280,
  },
  dialogRootWider: {
    minWidth: 286,
  },
  dialogContainer: {
    '&:focus > $dialogRoot': {
      outline: 'auto',
      '@media (pointer:coarse)': {
        outline: 0,
      },
    },
  },
  dialog: {
    '&:first-child': {
      padding: 0,
    },
  },
  dialogAction: {},
  withAdditionalAction: {
    justifyContent: 'flex-start',
    '& > *:first-child': {
      marginRight: 'auto',
    },
  },
};
