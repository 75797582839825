import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ breakpoints, palette, shape, spacing }) =>
    createStyles({
      root: {},
      differentMailWrapper: {
        display: 'flex',
        [breakpoints.down('md')]: {
          flexDirection: 'column',
        },
      },
      differentMailCol: {
        flex: '0 0 50%',
        [breakpoints.down('md')]: {
          flex: 1,
        },
      },
      differentMailColContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      differentMailContent: {
        padding: spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      },
      differentMailTitle: {
        marginBottom: spacing(2),
        whiteSpace: 'pre',
        textAlign: 'center',
      },
      differentMailButton: {
        marginTop: spacing(5),
      },
      contentWrapper: {
        [breakpoints.up('sm')]: {
          maxWidth: breakpoints.values.sm,
        },
        [breakpoints.up('md')]: {
          maxWidth: breakpoints.values.sm + 100,
        },
      },
      preview: {},
      previewImage: {},
      label: {
        display: 'block',
        lineHeight: 1.3,
        marginBottom: spacing(0.5),
        [breakpoints.up('sm')]: {
          marginBottom: spacing(1),
        },
      },
      selectFormControl: {
        marginLeft: spacing(-1),
        marginRight: spacing(-1),
        width: `calc(100% + ${spacing(2)}px)`,
        [breakpoints.up('xs')]: {
          width: 'auto',
          maxWidth: `calc(100% + ${spacing(2)}px)`,
        },
      },
      input: {
        display: 'flex',
        alignItems: 'center',
        padding: spacing(0, 1),
        // minHeight: 'auto',
      },
      inputNotchedOutline: {
        border: 'none',
      },
      avatarAndTextItemRoot: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
      },

      imageContainer: {
        backgroundColor: palette.greySimple.lightest,
        borderRadius: shape.borderRadius,
        paddingBottom: '53%',
        position: 'relative',
        overflow: 'hidden',
        marginTop: spacing(1),
        marginBottom: spacing(1),
        [breakpoints.up('sm')]: {
          marginTop: spacing(2),
          marginBottom: spacing(2),
        },
        '& img': {
          display: 'block',
          position: 'absolute',
          margin: 'auto',
          maxWidth: '66.6%',
          left: spacing(1),
          right: spacing(1),
          top: spacing(1),
          bottom: spacing(1),
          transform: 'translateY(30%)',
        },
      },
      imageContainerFullScreen: {
        paddingBottom: 0,
        overflow: 'auto',
        '& img': {
          top: 'unset',
          bottom: 'unset',
          right: 'unset',
          left: 'unset',
          position: 'relative',
          transform: 'unset',
          maxWidth: '100%',
        },
      },
      imageContainerClickable: {
        cursor: 'zoom-in',
      },
      imageNotLoadedContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        color: palette.greySimple.light,
        '& svg': {
          fontSize: spacing(8),
          [breakpoints.up('xs')]: {
            fontSize: spacing(11),
          },
          [breakpoints.up('sm')]: {
            fontSize: spacing(14),
          },
        },
      },
      documentName: {
        [breakpoints.up('sm')]: {
          minWidth: '50%',
          '& + *': {
            maxWidth: '50%',
          },
        },
      },
    }),
  { index: 0 }
);
