export default {
  root: {},
  iconButton: {
    backgroundColor: 'transparent',
  },
  previousMonthButtonMargin: {
    marginRight: 0,
  },
  hidden: {},
};
