import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ breakpoints, palette, spacing, transitions }) =>
    createStyles({
      listItemRoot: {
        color: palette.text.primary,
        border: 'none',
        padding: 0,
        '& .titleCell': {
          transition: transitions.create(['color'], {
            duration: transitions.duration.short,
          }),
        },
        '&:hover': {
          '& .titleCell': {
            color: `${palette.primary.main}`,
          },
        },
      },
      title: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '1rem',
      },
      avatar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      icon: {
        fontSize: 20,
        color: palette.primary.main,
        marginRight: spacing(2),

        [breakpoints.down('sm')]: {
          marginRight: spacing(1),
        },
      },
      lastCell: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingRight: spacing(2),
        textAlign: 'right',

        [breakpoints.up('sm')]: {
          paddingRight: spacing(1),
        },
      },

      dataTableRow: {
        width: '100%',
      },
    }),
  { index: 0 }
);
