import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ breakpoints, spacing }) =>
    createStyles({
      root: {
        minHeight: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      inner: {
        maxWidth: breakpoints.values.sm,
        '& > *:not(:last-child)': {
          marginBottom: spacing(3),
          [breakpoints.up('sm')]: {
            marginBottom: spacing(4),
          },
          [breakpoints.up('lg')]: {
            marginBottom: spacing(5),
          },
        },
      },
      image: {
        minHeight: 32,
      },
      imageContainer: {
        '& img': {
          display: 'block',
          margin: '0 auto',
          width: '100%',
          maxWidth: 160,
          [breakpoints.up('xs')]: {
            maxWidth: 200,
          },
          [breakpoints.up('sm')]: {
            maxWidth: 240,
          },
          [breakpoints.up('lg')]: {
            maxWidth: 280,
          },
        },
      },
      imageLoader: {
        margin: '0 auto',
      },
      button: {
        width: '100%',
        maxWidth: 300,
      },
      disabled: {
        opacity: 0,
        display: 'none !important',
      },
      title: {
        whiteSpace: 'pre-line',
      },
    }),
  { index: 0 }
);
