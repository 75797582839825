export default {
  root: {},
  penIcon: {
    display: 'none',
    /* position: 'absolute',
    top: '50%',
    right: 0,
    transform: 'translateY(-50%)', */
  },
  dateTextContainer: {
    display: 'flex',
  },
};
