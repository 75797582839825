import axios from 'src/helpers/axios';
import { parseError } from 'src/helpers/parseErrors';

const moduleName = 'communications-module';

const communicationsService = {
  getUserNotificationChannelsSettings,
  updateNotificationChannel,
  getUserNotificationSystemMessages,
  setNotificationSystemMessageAsRead,
  getUserNotificationSystemMessagesCounter,
};

function getUserNotificationChannelsSettings() {
  return axios
    .get(`/rest/${moduleName}/v1/notifications`)
    .then((response) => response.data)
    .catch((error) => parseError(error));
}

function updateNotificationChannel(newData) {
  return axios
    .put(`/rest/${moduleName}/v1/notifications`, newData)
    .then((response) => response.data)
    .catch((error) => parseError(error));
}

function getUserNotificationSystemMessages({ limit = 20, offset = 0, read = false }) {
  return axios
    .get(`/rest/${moduleName}/v1/system_messages?limit=${limit}&offset=${offset}&read=${read}`)
    .then((response) => response.data)
    .catch((error) => parseError(error));
}
function getUserNotificationSystemMessagesCounter() {
  return axios
    .get(`/rest/${moduleName}/v1/system_messages?read=false`)
    .then((response) => response.data)
    .catch((error) => parseError(error));
}

function setNotificationSystemMessageAsRead(notificationId, clearAll = false) {
  let urlRequest = `/rest/${moduleName}/v1/system_messages?index=${notificationId}`;
  if (clearAll) {
    urlRequest = `/rest/${moduleName}/v1/system_messages`;
  }
  return axios
    .post(urlRequest)
    .then((response) => response)
    .catch((error) => parseError(error));
}

export default communicationsService;
