export interface Channel {
  createdAt: number;
  email: boolean;
  emailCanBeChanged: boolean;
  notificationTemplate: string;
  sms: boolean;
  smsCanBeChanged: boolean;
  systemMessage: boolean;
  systemMessageCanBeChanged: boolean;
  updatedAt: number;
  userId: string;
}

export enum SystemMessageType {
  Certificate = 'certificate',
  Document = 'document',
  User = 'user',
  Workspace = 'workspace',
}

export interface SystemMessage {
  content: string;
  createdAt: number;
  documentId: string;
  id: number;
  read: boolean;
  type: SystemMessageType;
  updatedAt: number;
  userId: string;
  workspaceId: string;
}
