import spacing from '../spacing';
import typography from '../typography';

export default {
  root: {
    padding: spacing(2),
    '& h2': {
      fontSize: typography.h2.fontSize,
    },
  },
};
