import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ breakpoints, spacing, mixins }) =>
    createStyles({
      root: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        [breakpoints.up('md')]: {
          paddingTop: spacing(1.5),
          paddingBottom: spacing(0.5),
        },
        ...mixins.toolbar,
      },
    }),
  { index: 0 }
);
