import React, { useMemo } from 'react';
import { UserManagerPerson } from 'src/models/documentUsersManagement.model';
import { RegexFormulas } from 'src/helpers/regex';
import { AvatarWithBadge } from 'src/components/index';
import clsx from 'clsx';
import { Fab, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import UserItemDescription from './UserItemDescription';
import { useUserItemStyles } from './styles';
import UserItemTitle from './UserItemTitle';
import { Delete as RemoveIcon } from '../../../CustomIcon';
import UserItemRoles from './UserItemRoles';

const getTitle = (userData: UserManagerPerson): string | undefined =>
  userData._name || userData.email;

interface UserItemProps {
  userData: UserManagerPerson;
  highlightString?: string;
  showTitleAdditionalTexts?: boolean;
  canRemove?: boolean;
  showRoles?: boolean;
  canChangeRole?: boolean;
  hoverEnabled?: boolean;
  onRemove?: (item: UserManagerPerson) => void;
  onRoleChange?: (item: UserManagerPerson) => void;
}

const UserItem = ({
  userData,
  highlightString = '',
  showTitleAdditionalTexts = true,
  canRemove = false,
  canChangeRole = false,
  showRoles = false,
  hoverEnabled = false,
  onRemove,
  onRoleChange,
}: UserItemProps): JSX.Element => {
  const { t } = useTranslation();
  const { _id, isGroup } = userData;
  const classes = useUserItemStyles();
  const avatarSrc = useMemo(
    () =>
      _id && !isGroup && RegexFormulas.uuidV4.test(_id)
        ? `${process.env.REACT_APP_AVATAR_URL}/identity/${_id}?kind=SMALL`
        : null,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [_id]
  );

  const title = userData.isToken ? t('common.generatedToken') : getTitle(userData);

  return (
    <div className={clsx(classes.wrapper, hoverEnabled ? 'hoverEnabled' : '')}>
      <AvatarWithBadge
        className={!showTitleAdditionalTexts ? 'avatarWrapper' : undefined}
        size="large"
        src={avatarSrc}
        alt={title}
      >
        {title}
      </AvatarWithBadge>
      <div className={classes.textWrapper}>
        <UserItemTitle
          userData={userData}
          highlightString={highlightString}
          showTitleAdditionalTexts={showTitleAdditionalTexts}
        />
        <UserItemDescription userData={userData} highlightString={highlightString} />
      </div>
      {showRoles && (
        <UserItemRoles
          role={userData.role}
          disabled={!canChangeRole}
          onRoleChange={(role) => {
            if (onRoleChange)
              onRoleChange({
                ...userData,
                role,
              });
          }}
        />
      )}
      {canRemove && (
        <Tooltip title={t<string>('common.remove')}>
          <Fab
            size="small"
            color="primary"
            className={classes.removeButton}
            onClick={() => (onRemove ? onRemove(userData) : undefined)}
          >
            <RemoveIcon />
          </Fab>
        </Tooltip>
      )}
    </div>
  );
};
export default UserItem;
