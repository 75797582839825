import spacing from '../spacing';

export default {
  root: {
    justifyContent: 'flex-end',
    paddingBottom: spacing(2),
    paddingLeft: spacing(2),
    paddingRight: spacing(2),
  },
};
