import { RequestStatus } from 'src/helpers/reduxReuquest.util';
import { WorkspaceUser } from 'src/models/workspaceUser.model';
import identityConstants from '../constants/identity.constants';

export type WorkspaceUserState = {
  workspaceUsers: WorkspaceUser[];
  workspaceInvitedUsers: WorkspaceUser[];
  hasNextPage: boolean;
  pageIndex: number;
  fetchWorkspaceUsersStatus: RequestStatus;
  fetchWorkspaceInvitedUsersStatus: RequestStatus;
  inviteUsersToWorkspaceStatus: RequestStatus;
  searchWorkspaceUsersStatus: RequestStatus;
  deleteUserFromWorkspaceStatus: RequestStatus;
  deleteUserInvitationFromWorkspaceStatus: RequestStatus;
};

const initialState = {
  workspaceUsers: [],
  workspaceInvitedUsers: [],
  hasNextPage: false,
  pageIndex: 0,
  fetchWorkspaceUsersStatus: RequestStatus.IDLE,
  inviteUsersToWorkspaceStatus: RequestStatus.IDLE,
  searchWorkspaceUsersStatus: RequestStatus.IDLE,
  fetchWorkspaceInvitedUsersStatus: RequestStatus.IDLE,
  deleteUserFromWorkspaceStatus: RequestStatus.IDLE,
  deleteUserInvitationFromWorkspaceStatus: RequestStatus.IDLE,
};

function workspaceUserStore(
  state: WorkspaceUserState = initialState,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any
  action: any // FIXME: fix this in next stage
): WorkspaceUserState {
  switch (action.type) {
    case identityConstants.FETCH_WORKSPACE_USERS_REQUEST:
      return {
        ...state,
        fetchWorkspaceUsersStatus: RequestStatus.PENDING,
      };
    case identityConstants.FETCH_WORKSPACE_USERS_SUCCESS:
      return {
        ...state,
        fetchWorkspaceUsersStatus: RequestStatus.SUCCESS,
        hasNextPage: action.hasNextPage,
        pageIndex: action.hasNextPage ? state.pageIndex + 1 : state.pageIndex,
        workspaceUsers:
          action.queryPage === 0
            ? action.workspaceUsers
            : [...state.workspaceUsers, ...action.workspaceUsers],
      };
    case identityConstants.FETCH_WORKSPACE_USERS_FAILURE:
      return {
        ...state,
        fetchWorkspaceUsersStatus: RequestStatus.ERROR,
      };
    case identityConstants.FETCH_WORKSPACE_INVITED_USERS_REQUEST:
      return {
        ...state,
        fetchWorkspaceInvitedUsersStatus: RequestStatus.PENDING,
      };
    case identityConstants.FETCH_WORKSPACE_INVITED_USERS_SUCCESS:
      return {
        ...state,
        fetchWorkspaceInvitedUsersStatus: RequestStatus.SUCCESS,
        workspaceInvitedUsers: action.payload,
      };
    case identityConstants.FETCH_WORKSPACE_INVITED_USERS_FAILURE:
      return {
        ...state,
        fetchWorkspaceInvitedUsersStatus: RequestStatus.ERROR,
      };

    case identityConstants.FETCH_WORKSPACE_USERS_SEARCH_REQUEST:
      return {
        ...state,
        searchWorkspaceUsersStatus: RequestStatus.PENDING,
      };
    case identityConstants.FETCH_WORKSPACE_USERS_SEARCH_SUCCESS:
      return {
        ...state,
        searchWorkspaceUsersStatus: RequestStatus.SUCCESS,
        pageIndex: 0,
        workspaceUsers: [...action.workspaceUsers],
      };
    case identityConstants.FETCH_WORKSPACE_USERS_SEARCH_FAILURE:
      return {
        ...state,
        searchWorkspaceUsersStatus: RequestStatus.ERROR,
      };
    case identityConstants.INVITE_USER_TO_WORKSPACE_REQUEST:
      return {
        ...state,
        inviteUsersToWorkspaceStatus: RequestStatus.PENDING,
      };
    case identityConstants.INVITE_USER_TO_WORKSPACE_SUCCESS:
      return {
        ...state,
        inviteUsersToWorkspaceStatus: RequestStatus.SUCCESS,
      };
    case identityConstants.INVITE_USER_TO_WORKSPACE_FAILURE:
      return {
        ...state,
        inviteUsersToWorkspaceStatus: RequestStatus.ERROR,
      };

    case identityConstants.DELETE_USER_FROM_WORKSPACE_REQUEST:
      return {
        ...state,
        deleteUserFromWorkspaceStatus: RequestStatus.PENDING,
      };
    case identityConstants.DELETE_USER_FROM_WORKSPACE_SUCCESS:
      return {
        ...state,
        deleteUserFromWorkspaceStatus: RequestStatus.SUCCESS,
      };
    case identityConstants.DELETE_USER_FROM_WORKSPACE_FAILURE:
      return {
        ...state,
        deleteUserFromWorkspaceStatus: RequestStatus.ERROR,
      };

    case identityConstants.DELETE_USER_INVITATION_FROM_WORKSPACE_REQUEST:
      return {
        ...state,
        deleteUserInvitationFromWorkspaceStatus: RequestStatus.PENDING,
      };
    case identityConstants.DELETE_USER_INVITATION_FROM_WORKSPACE_SUCCESS:
      return {
        ...state,
        deleteUserInvitationFromWorkspaceStatus: RequestStatus.SUCCESS,
      };
    case identityConstants.DELETE_USER_INVITATION_FROM_WORKSPACE_FAILURE:
      return {
        ...state,
        deleteUserInvitationFromWorkspaceStatus: RequestStatus.ERROR,
      };
    case identityConstants.CLEAR_WORKSPACE_USERS_STORE:
      return initialState;
    default:
      return state;
  }
}

export default workspaceUserStore;
