import { makeStyles, createStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ breakpoints }) =>
    createStyles({
      fieldsWrapper: {
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: 24,
        alignItems: 'center',
        '& .MuiFormControl-root': {
          width: '100%',
        },

        [breakpoints.down('sm')]: {
          height: '100%',
        },
      },
    }),
  { index: 0 }
);
