import spacing from '../spacing';

export default {
  root: {
    background: 'transparent',
    padding: spacing(0.25, 0.5),
    minWidth: 64,
    maxWidth: 'auto',
  },
};
