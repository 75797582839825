import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import certificateCreatorActions from 'src/redux/actions/certificateCreator.actions';
import { CertificateQuantityType, CertificateSteps } from 'src/models/certificate.model';
import WaitingForBlockchain, {
  Variant,
} from 'src/components/WaitingForBlockchain/WaitingForBlockchain';

const CreateFilesForCertProgressScreen = () => {
  const dispatch = useDispatch();
  const { generatedCertFileList, sentForGeneratingList, certificateQuantityType } = useSelector(
    (state) => state.certificateCreatorStore,
    undefined
  );
  const progress =
    sentForGeneratingList.length === 0
      ? 0
      : (Object.keys(generatedCertFileList).length / sentForGeneratingList.length) * 100;

  useEffect(() => {
    if (progress === 100) {
      if (certificateQuantityType === CertificateQuantityType.Single) {
        dispatch(
          certificateCreatorActions.setCreatorStep({
            currentStep: CertificateSteps.UploadAdditionalDocuments,
          })
        );
      } else if (certificateQuantityType === CertificateQuantityType.Multiple) {
        dispatch(
          certificateCreatorActions.setCreatorStep({
            currentStep: CertificateSteps.ChooseLocation,
          })
        );
      }
    }
  }, [dispatch, certificateQuantityType, progress]);

  return <WaitingForBlockchain variant={Variant.DocumentsGenerating} />;
};

export default CreateFilesForCertProgressScreen;
