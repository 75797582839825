import { makeStyles, createStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ breakpoints, palette, typography }) =>
    createStyles({
      header: {
        width: '100%!important',
        margin: '0!important',
        top: 0,
        left: 0,
        position: 'sticky',
        zIndex: 1,
        '& > div': {
          minHeight: '40px',
          paddingTop: 'unset',
        },
        '& h4 strong': {
          fontWeight: '500 !important',
          fontSize: 16,
        },
      },
      tilesContainer: {
        width: '100%!important',
        margin: '0!important',
        '& > div': {
          minHeight: '40px',

          [breakpoints.down('sm')]: {
            padding: '0 12px',
          },
        },
      },
      tilesContainerGrayed: {
        opacity: 0.5,
        '& > div:hover': {
          backgroundColor: 'unset !important',
          '& .titleCell': {
            color: `${palette.text.primary} !important`,
          },
        },
      },
      popover: {
        overflow: 'hidden',
      },
      textField: {
        '& .Mui-disabled': {
          background: 'white',
          cursor: 'pointer',
          color: palette.text.primary,
        },
      },
      changeLocationButton: {
        color: palette.primary.main,
        textDecoration: 'underline',
        fontWeight: typography.fontWeightBold,
        cursor: 'pointer',
      },
      locationExplorer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        position: 'relative',
        alignItems: 'flex-start',
        width: '400px',
        height: '400px',
        boxSizing: 'border-box',
        [breakpoints.down('sm')]: {
          width: '360px',
          height: '400px',
        },
      },
      locationExplorerHeader: {
        position: 'absolute',
        height: 50,
        padding: '0 12px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        borderBottom: `1px solid ${palette.secondary.light}`,
      },
      locationExplorerContent: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
        margin: '50px 0',
        overflowY: 'auto',
      },
      locationExplorerContentCategory: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexDirection: 'column',
        width: '100%',
        boxSizing: 'border-box',
      },
      locationExplorerContentCategoryHeader: {
        color: palette.secondary.dark,
        fontWeight: typography.fontWeightBold,
        cursor: 'pointer',
      },
      locationExplorerFooter: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        position: 'absolute',
        bottom: 0,
        height: 50,
        padding: '0 16px',
        borderTop: `1px solid ${palette.secondary.light}`,
      },
      locationExplorerSubmitButton: {
        display: 'flex',
        alignItems: 'center',
        color: palette.primary.main,
        fontWeight: typography.fontWeightBold,
        cursor: 'pointer',
      },
      locationExplorerHeaderText: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: typography.fontWeightBold,
        cursor: 'pointer',
        width: '100%',
      },
      locationExplorerItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        cursor: 'pointer',
        width: '100%',
        background: 'white',
        margin: '10px 0',
        borderRadius: '5px',
        border: `1px solid transparent`,
      },
      locationExplorerItemSelected: {
        border: `1px solid ${palette.primary.main}`,
      },
      locationExplorerItemIcon: {
        marginRight: '10px',
        color: palette.primary.main,
      },
      centerWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
      },
      backButton: {
        marginRight: '10px',
      },
      input: {
        width: '100%',
        '& input': {
          minHeight: 32,
          lineHeight: '32px',
        },
        '& .MuiFormHelperText-root': {
          position: 'absolute',
          top: '100%',
        },
      },
      inputRoot: {
        paddingRight: 0,
      },
      inputButton: {
        height: 32,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
        padding: 8,
      },
      createNewFolderContent: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
      },
      form: {
        width: '100%',
      },
      dataTableWrapper: {
        width: '100%',
        boxSizing: 'border-box',

        [breakpoints.down('sm')]: {
          width: '100%',
          padding: '0 5px',
        },
      },
    }),
  { index: 0 }
);
