import React, { MouseEvent, useState } from 'react';
import {
  Avatar,
  Divider,
  Fab,
  ListItemIcon,
  Menu,
  MenuItem,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { UserSettings as UserSettingsIcon, Logout as LogoutIcon } from 'src/components/CustomIcon';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { lighten } from '@material-ui/core/styles';
import { getColorFromText, getInitials } from 'src/helpers/globalUtilsTS';
import { IdentitySelector } from 'src/redux/selectors/identity.selector';
import { AvatarAndTextItem, AvatarWithBadge, HeaderUserDrawer } from 'src/components';
import authActions from 'src/redux/actions/auth.actions';
import { useHistory } from 'react-router-dom';
import { useStyles } from './styles';

const HeaderHelp = (): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const smallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const currentIdentity = useSelector(IdentitySelector.selectCurrentIdentity);
  const workspaceId = useSelector(IdentitySelector.selectWorkspaceId);
  const workspaceDetails = useSelector(IdentitySelector.selectWorkspaceDetails);
  const handleClick = (e: MouseEvent<HTMLElement>) => {
    if (e) setAnchorEl(e.currentTarget);
  };
  const history = useHistory();

  const initials = currentIdentity ? getInitials(currentIdentity?.name) : ' ';
  const userAvatar = `${process.env.REACT_APP_AVATAR_URL}/identity/${currentIdentity?.identityId}?kind=SMALL`;
  const workspaceAvatar = `${process.env.REACT_APP_AVATAR_URL}/workspace/${workspaceId}?kind=SMALL`;
  const colorByInitials = getColorFromText(initials);
  const userText = currentIdentity?.name || currentIdentity?.email || '';
  const userDescription =
    currentIdentity?.name && currentIdentity?.name !== currentIdentity?.email
      ? currentIdentity?.email
      : undefined;

  const isOpen = Boolean(anchorEl);
  const dispatch = useDispatch();
  const handleClose = () => setAnchorEl(null);
  const handleLogout = () => {
    dispatch(authActions.deleteToken());
  };

  const goToSettings = () => {
    history.push(`/user-settings/basic/settings`);
    handleClose();
  };

  return (
    <>
      <Tooltip title={t<string>('headerUser.account')}>
        <Fab onClick={handleClick} className={classes.mainAvatar}>
          {smallScreen ? (
            <AvatarWithBadge
              size="large"
              src={userAvatar}
              alt="User Avatar"
              badgeChildren={workspaceDetails?.name}
              badgeAlt="Workspace Avatar"
              badgeSrc={workspaceAvatar}
            >
              {initials}
            </AvatarWithBadge>
          ) : (
            <Avatar
              sizes="large"
              variant="circular"
              src={userAvatar}
              style={{
                color: colorByInitials,
                backgroundColor: lighten(colorByInitials, 0.825),
              }}
            >
              {initials}
            </Avatar>
          )}
        </Fab>
      </Tooltip>

      {!smallScreen && (
        <Menu
          open={isOpen}
          anchorEl={anchorEl}
          onClose={handleClose}
          classes={{
            list: classes.menu,
          }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: -40,
            horizontal: 'right',
          }}
        >
          <div className={classes.userWrapper}>
            <AvatarAndTextItem
              className={classes.user}
              size="large"
              text={userText}
              description={userDescription}
              mainImageSrc={userAvatar}
            />
          </div>
          <Divider />
          <MenuItem onClick={goToSettings} divider={false}>
            <ListItemIcon>
              <UserSettingsIcon />
            </ListItemIcon>
            <Typography color="textPrimary">{t<string>('headerUser.accountSettings')} </Typography>
          </MenuItem>
          <Divider />
          <MenuItem className={classes.logout} onClick={handleLogout}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <Typography color="textPrimary">{t<string>('headerUser.logout')} </Typography>
          </MenuItem>
        </Menu>
      )}
      {smallScreen && <HeaderUserDrawer opened={isOpen} onClose={() => setAnchorEl(null)} />}
    </>
  );
};

export default HeaderHelp;
