import axios from 'src/helpers/axios';
import { parseError } from 'src/helpers/parseErrors';

const moduleName = 'files-module';

const filesModuleService = {
  uploadFile,
  downloadFile,
};

function uploadFile(file, token, fileSize, config) {
  const formData = new FormData();
  formData.append('upfile', file);
  formData.append('token', token);
  formData.append('file_size', fileSize);

  return axios
    .post(`/rest/${moduleName}/v1/file`, formData, config)
    .then((response) => response.data)
    .catch((error) => parseError(error));
}

function downloadFile(token, config = { responseType: 'blob' }) {
  return axios
    .get(`/rest/${moduleName}/v1/file?token=${token}`, config)
    .then((response) => response)
    .catch((error) => parseError(error));
}

export default filesModuleService;
