import React from 'react';
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon';

export default createSvgIcon(
  <>
    <path
      d="m 19.5,7.4896499 v 0.01035 h -3.453899 c -1.120535,2e-4 -2.028909,-0.9127166 -2.028742,-2.0388833 V 1.9999999 h 0.01014 z"
      id="path2"
      fill="#11b1ff"
    />
    <path
      d="M 19.45265,7.4849583 V 20 c 1.65e-4,1.104667 -0.884201,2.000417 -1.975246,2 H 6.4752462 C 5.3842255,22.000417 4.4996097,21.104667 4.5000001,20 V 4 C 4.4996097,2.8953165 5.3842255,1.9996094 6.4752462,2 h 7.5356328 l 0.0091,-1e-7 0.08972,3.405075 c -4.15e-4,1.1046917 0.884201,2.0002 1.975247,2 h 3.36277 v 0.079883 z"
      id="path4"
      fill="url(#paint0_linear) #365fff"
    />
    <path
      d="M 12.582402,11.15025 H 8.1914374 c -0.2753033,0 -0.4984218,-0.223833 -0.4984218,-0.5 0,-0.276167 0.2231185,-0.500025 0.4984218,-0.500025 h 4.3909646 c 0.275294,0 0.498422,0.223858 0.498422,0.500025 0,0.276167 -0.223128,0.5 -0.498422,0.5 z"
      id="path6"
      fill="#ffffff"
    />
    <path
      d="M 16.893752,14.93525 H 8.1914374 c -0.2753033,0 -0.4984218,-0.223833 -0.4984218,-0.5 0,-0.276167 0.2231185,-0.5 0.4984218,-0.5 h 8.7023146 c 0.275294,0 0.498422,0.223833 0.498422,0.5 0,0.276167 -0.223128,0.5 -0.498422,0.5 z"
      id="path8"
      fill="#ffffff"
    />
    <path
      d="M 16.893752,18.715333 H 8.1914374 c -0.2753033,0 -0.4984218,-0.223833 -0.4984218,-0.5 0,-0.276166 0.2231185,-0.5 0.4984218,-0.5 h 8.7023146 c 0.275294,0 0.498422,0.223834 0.498422,0.5 0,0.276167 -0.223128,0.5 -0.498422,0.5 z"
      id="path10"
      fill="#ffffff"
    />
    <defs id="defs22">
      <linearGradient
        gradientUnits="userSpaceOnUse"
        y2="17.280001"
        x2="25.9814"
        y1="0"
        x1="2.9414101"
        id="paint0_linear"
      >
        <stop id="stop14" stopColor="#11B1FF" />
        <stop id="stop16" stopColor="#005DFF" offset="1" />
      </linearGradient>
      <clipPath id="clip0">
        <rect id="rect19" fill="white" height="24" width="24" />
      </clipPath>
    </defs>
  </>,
  'FileColor'
);
