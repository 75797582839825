import React, {
  Children as ReactChildren,
  cloneElement,
  forwardRef,
  isValidElement,
  useRef,
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useDrop, useDragDropManager } from 'react-dnd';
import { LinkDnd } from 'src/components';
import { ButtonBase, Menu, MenuItem, Typography } from '@material-ui/core';
import BreadcrumbCollapsed from './BreadcrumbCollapsed';
import { ArrowRightSLine as ChevronRightIcon, FolderOpen as FolderIcon } from '../CustomIcon';
import { useStyles } from './styles';

const CustomizedBreadcrumbs = forwardRef(
  (
    {
      children,
      className,
      component: Component,
      expandText,
      itemsAfterCollapse,
      itemsBeforeCollapse,
      separator,
      ...other
    },
    ref
  ) => {
    const classes = useStyles();

    const [expanded, setExpanded] = useState(false);

    const handleClickExpand = () => {
      setExpanded(true);

      const focusable = listRef.current.querySelector('a[href],button,[tabindex]');
      if (focusable) {
        focusable.focus();
      }
    };

    const handleCollapse = () => {
      setExpanded(false);
    };

    const insertSeparators = (items, className, separator) =>
      items.reduce((acc, current, index) => {
        if (index < items.length - 1) {
          acc = acc.concat(
            current,
            <li aria-hidden key={`separator-${index}`} className={className}>
              {separator}
            </li>
          );
        } else {
          acc.push(current);
        }

        return acc;
      }, []);

    const listRef = useRef(null);
    const ellipsisRef = useRef(null);

    const allItems = ReactChildren.toArray(children)
      .filter((child) => isValidElement(child))
      .map((child, index, allItems) => {
        const isFirst = index === 0;
        const isLast = index === allItems.length - 1;
        return (
          <ButtonBase
            component="li"
            className={clsx(classes.li, isFirst && classes.firstLi, isLast && classes.lastLi)}
            key={`child-${index}`}
            disableRipple={isLast}
          >
            {cloneElement(
              child,
              {
                ...child.props,
              },
              <>
                {isFirst && <FolderIcon />}
                {child.props.children}
              </>
            )}
          </ButtonBase>
        );
      });

    const [{ isOver }, drop] = useDrop(() => ({
      accept: 'ITEM',
      hover: () => {
        setExpanded(true);
      },
      drop: () => {
        setExpanded(false);
      },
      collect: (monitor) => ({
        isOver: monitor.isOver({ shallow: true }),
      }),
    }));

    const dragDropManager = useDragDropManager();
    const monitor = dragDropManager.getMonitor();
    const menuRef = useRef();
    const [menuCoords, setMenuCoords] = useState({ minX: '', maxX: '', minY: '', maxY: '' });

    const between = (x, min, max) => x >= min && x <= max;

    useEffect(
      () =>
        monitor.subscribeToOffsetChange(() => {
          const offset = monitor.getClientOffset();
          if (
            offset &&
            !(
              between(offset.x, menuCoords.minX, menuCoords.maxX) &&
              between(offset.y, menuCoords.minY - 30, menuCoords.maxY)
            )
          ) {
            setExpanded(false);
          }
        }),
      [monitor, expanded, menuCoords]
    );

    useEffect(() => {
      if (expanded) {
        const { x, y, width, height } = menuRef.current.getBoundingClientRect();
        const coordsObj = { minX: x, maxX: x + width, minY: y, maxY: y + height };
        setMenuCoords(coordsObj);
      }
    }, [expanded]);

    const beforeAndAfterItems = () => {
      if (itemsBeforeCollapse + itemsAfterCollapse + 1 >= allItems.length) {
        return allItems;
      }

      return [
        ...allItems.slice(0, itemsBeforeCollapse),
        <div ref={drop} className={clsx(isOver && classes.dropBackground)} key="drop">
          <BreadcrumbCollapsed
            ref={ellipsisRef}
            aria-label={expandText}
            key="ellipsis"
            active={expanded}
            onClick={expanded ? handleCollapse : handleClickExpand}
          />
        </div>,
        ...allItems.slice(allItems.length - itemsAfterCollapse, allItems.length),
      ];
    };

    const betweenItems = () => {
      if (itemsBeforeCollapse + itemsAfterCollapse + 1 >= allItems.length) {
        return null;
      }

      const childrenInArray = ReactChildren.toArray(children).slice(
        itemsBeforeCollapse,
        allItems.length - itemsAfterCollapse
      );

      return childrenInArray
        .filter((child) => isValidElement(child))
        .map((child, index) => (
          <MenuItem className={classes.menuItem} disableGutters>
            <LinkDnd
              key={`child-${index}`}
              button
              component={LinkDnd}
              {...child.props}
              onClick={handleCollapse}
              isMenuLink
            />
          </MenuItem>
        ));
    };

    const itemsInPopover = betweenItems();

    return (
      <Typography
        ref={ref}
        component={Component}
        color="textSecondary"
        className={clsx(classes.root, className)}
        {...other}
      >
        <ol className={classes.ol} ref={listRef}>
          {insertSeparators(beforeAndAfterItems(), classes.separator, separator)}
        </ol>

        <Menu
          ref={menuRef}
          getContentAnchorEl={null}
          anchorEl={ellipsisRef.current}
          keepMounted
          open={expanded && itemsInPopover !== null && Boolean(ellipsisRef.current)}
          onClose={handleCollapse}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {itemsInPopover}
        </Menu>
      </Typography>
    );
  }
);

CustomizedBreadcrumbs.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  component: PropTypes.elementType,
  expandText: PropTypes.string,
  itemsAfterCollapse: PropTypes.number,
  itemsBeforeCollapse: PropTypes.number,
  separator: PropTypes.node,
};

CustomizedBreadcrumbs.defaultProps = {
  component: 'nav',
  expandText: 'Show path',
  itemsAfterCollapse: 1,
  itemsBeforeCollapse: 1,
  separator: <ChevronRightIcon />,
};

export default CustomizedBreadcrumbs;
