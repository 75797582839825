import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ palette, spacing, transitions }) =>
    createStyles({
      root: {
        display: 'flex',
      },
      inputContainer: {
        position: 'relative',
        width: spacing(7.5),
        height: spacing(9),
        '&:not(:last-child)': {
          marginRight: spacing(1),
        },
        '& $underline': {
          position: 'absolute',
          bottom: spacing(2.5),
          left: '50%',
          marginLeft: '-15%',
        },
        '&:hover, &:focus': {
          '& $underline': {
            backgroundColor: palette.primary.main,
          },
        },
      },
      input: {
        minHeight: spacing(9),
        textAlign: 'center',
        fontSize: '1.285rem',
        '&[type=number]': {
          '-moz-appearance': 'textfield',
        },
        '&::-webkit-outer-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0,
        },
        '&::-webkit-inner-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0,
        },
      },
      inputFocus: {
        '& $underline': {
          backgroundColor: palette.primary.main,
        },
      },
      inputRoot: {},
      inputFilled: {
        '& $input + $underline': {
          backgroundColor: palette.text.primary,
        },
      },
      notchedOutline: {},
      underline: {
        width: '30%',
        height: 1,
        background: palette.bottomLineColor,
        transition: transitions.create(['background-color'], {
          duration: transitions.duration.short,
        }),
      },
    }),
  { index: 0 }
);
