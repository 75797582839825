import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Tooltip, Typography } from '@material-ui/core';
import { ExtendedGrid } from 'src/components';
import {
  Notification as DefaultIcon,
  Visibility as VisibilityIcon,
  Edit as EditIcon,
  FileAdd as FileAddIcon,
  Email as EmailIcon,
  UserDelete as UserDeleteIcon,
  UserAdd as UserAddIcon,
  Download as DownloadIcon,
  FileRemove as FileRemoveIcon,
  FileEdit as FileEditIcon,
  Globe as GlobeIcon,
  Sort as SortIcon,
  ImportExport as ImportExportIcon,
  Stopwatch as StopwatchIcon,
  Time as TimeIcon,
  Check as CheckIcon,
  Users as UsersIcon,
} from 'src/components/CustomIcon';
import { TimeAgoCell } from 'src/components/DataTable/cells';
import ContactHelper from 'src/helpers/contactHelper';
import moment from 'moment';
import 'moment/locale/pl'; // without this line it didn't work
import { HistoryType } from 'src/models/document.model';
import { useStyles } from './styles';

const HistoryItem = ({
  className: classNameProp,
  userId,
  date,
  action,
  metadata,
  dateGroup,
  dateActive,
  lastDateInGroup,
}) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const matchedIdentity = ContactHelper.getEntityData(userId);
  const user = matchedIdentity || { name: userId, email: userId };

  const getItemProps = () => {
    let email;
    let role;
    let reason;
    let oldName;
    let newHolder;
    let oldHolder;
    let newName;
    let groupName;
    let isPublic;
    let ordered;
    let transferable;
    let entryType = false;

    if (metadata) {
      email = metadata.email;
      role =
        metadata.role &&
        t(`documentActions.tabs.recipients.role.${metadata.role.toLowerCase()}`).toLowerCase();
      reason = metadata.reason;
      oldName = metadata.oldName;
      newName = metadata.newName;
      newName = metadata.newName;
      isPublic = metadata.public;
      ordered = metadata.ordered;
      transferable = metadata.transferable;

      if (metadata.entityId) {
        const matchedIdentity = ContactHelper.getEntityData(metadata.entityId);
        email = matchedIdentity.email || metadata.entityId;

        entryType = matchedIdentity.isGroup ? 'GROUP' : 'MEMBER';
        if (entryType === 'GROUP') {
          groupName = matchedIdentity.name;
        }
      }

      if (metadata.newHolder) {
        const newHolderIdentity = ContactHelper.getEntityData(metadata.newHolder);

        newHolder = newHolderIdentity.name || metadata.newHolder;
      }

      if (metadata.oldHolder) {
        const oldHolderIdentity = ContactHelper.getEntityData(metadata.oldHolder);

        oldHolder = oldHolderIdentity.name || metadata.newHolder;
      }
    }

    const defaultTitle = (variables) =>
      t(`historyType.${action}${entryType ? `.${entryType}` : ''}.longText`, variables);

    switch (action) {
      case HistoryType.VIEW:
        return {
          title: defaultTitle(),
          color: 'info',
          icon: <VisibilityIcon />,
        };
      case HistoryType.SIGN:
        return {
          title: defaultTitle(),
          color: 'success',
          icon: <EditIcon />,
        };
      case HistoryType.SEND_INVITATION:
        return {
          title: defaultTitle({ email, role }),
          color: 'primary',
          icon: <EmailIcon />,
        };
      case HistoryType.DELETE_INVITATION:
        return {
          title: defaultTitle({ email }),
          color: 'error',
          icon: <UserDeleteIcon />,
        };
      case HistoryType.ADD_ENTITY:
        return {
          title: defaultTitle(
            entryType && entryType === 'MEMBER' ? { email, role } : { groupName, role }
          ),
          color: 'primary',
          icon: <UserAddIcon />,
        };
      case HistoryType.DELETE_ENTITY:
        return {
          title: defaultTitle(
            entryType && entryType === 'MEMBER' ? { email, role } : { groupName, role }
          ),
          color: 'error',
          icon: <UserDeleteIcon />,
        };
      case HistoryType.DOWNLOAD:
        return {
          title: defaultTitle(),
          color: 'default',
          icon: <DownloadIcon />,
        };
      case HistoryType.REJECT:
        return {
          title: defaultTitle({ reason }),
          color: 'error',
          icon: <FileRemoveIcon />,
        };
      case HistoryType.RENAME:
        return {
          title: defaultTitle({ oldName, newName }),
          color: 'primary',
          icon: <FileEditIcon />,
        };
      case HistoryType.CREATE:
        return {
          title: defaultTitle(),
          color: 'success',
          icon: <FileAddIcon />,
        };
      case HistoryType.VISIBILITY_CHANGE:
        return {
          title: defaultTitle({
            public: isPublic
              ? t('documentActions.tabs.history.metadata.public')
              : t('documentActions.tabs.history.metadata.private'),
          }),
          color: 'default',
          icon: <GlobeIcon />,
        };

      case HistoryType.CHANGE_SIGN_ORDER:
        return {
          title: defaultTitle({
            ordered: ordered
              ? t('documentActions.tabs.history.metadata.on')
              : t('documentActions.tabs.history.metadata.off'),
          }),
          color: 'default',
          icon: <SortIcon />,
        };

      case HistoryType.CHANGE_HOLDER:
        return {
          title: defaultTitle({ oldHolder, newHolder, context: oldHolder ? 'from' : 'to' }),
          color: 'info',
          icon: <ImportExportIcon />,
        };

      case HistoryType.INVALIDATE:
        return {
          title: defaultTitle({ reason }),
          color: 'error',
          icon: <FileRemoveIcon />,
        };
      case HistoryType.INVALIDATE_TEMPORARY:
        return {
          title: defaultTitle({ reason }),
          color: 'warning',
          icon: <StopwatchIcon />,
        };
      case HistoryType.EXPIRED:
        return {
          title: defaultTitle(),
          color: 'error',
          icon: <TimeIcon />,
        };
      case HistoryType.VALIDATE:
        return {
          title: defaultTitle({ reason }),
          color: 'success',
          icon: <CheckIcon />,
        };
      case HistoryType.TRANSFERABILITY_CHANGE:
        return {
          title: defaultTitle({
            transferable: transferable
              ? t('documentActions.tabs.history.metadata.on')
              : t('documentActions.tabs.history.metadata.off'),
          }),
          color: 'default',
          icon: <UsersIcon />,
        };
      case HistoryType.TRANSFER_INVITATION_CREATED:
        return {
          title: defaultTitle(),
          color: 'info',
          icon: <UserAddIcon />,
        };
      case HistoryType.GENERATE_PDF_INVITATION:
        return {
          title: defaultTitle(),
          color: 'info',
          icon: <UserAddIcon />,
        };
      case HistoryType.TRANSFER_INVITATION_REVOKED:
        return {
          title: defaultTitle(),
          color: 'info',
          icon: <UserDeleteIcon />,
        };
      case HistoryType.DELETE_PDF_INVITATION:
        return {
          title: defaultTitle(),
          color: 'error',
          icon: <UserDeleteIcon />,
        };

      default:
        return {
          color: 'default',
          icon: <DefaultIcon />,
        };
    }
  };

  const getDateGroup = (dateGroup) => {
    const today = moment
      .unix(new Date().getTime() / 1000)
      .locale(language)
      .format('ll');
    const yesterday = moment
      .unix(new Date(Date.now() - 86400000).getTime() / 1000)
      .locale(language)
      .format('ll');

    switch (dateGroup) {
      case today:
        return t('calendar.today');
      case yesterday:
        return t('calendar.yesterday');
      default:
        return dateGroup;
    }
  };

  const { color, icon, title } = getItemProps();

  const itemByText = `&nbsp;${t('documentActions.tabs.history.entryByUser', {
    name: user.name,
    interpolation: { escapeValue: false },
  })}`;

  return (
    <div
      className={clsx(
        classes.root,
        lastDateInGroup && classes.hideDots,
        {
          [classes[`${String(color)}Color`]]: color,
        },
        classNameProp
      )}
    >
      {dateActive && (
        <ExtendedGrid container spacingX={2} spacingY={1} alignItemsXxs="center" wrapXs="nowrap">
          <ExtendedGrid item xxs="auto">
            <Tooltip title={dateGroup}>
              <Typography
                variant="body1"
                color="textSecondary"
                className={classes.entriesDividerText}
              >
                {getDateGroup(dateGroup)}
              </Typography>
            </Tooltip>
          </ExtendedGrid>
        </ExtendedGrid>
      )}
      <ExtendedGrid container spacingX={2} spacingY={1} alignItemsXxs="center" wrapXs="nowrap">
        <ExtendedGrid item xxs="auto">
          <div className={classes.icon}>{icon}</div>
        </ExtendedGrid>
        <ExtendedGrid item xxs>
          {title && (
            <Typography
              color="textPrimary"
              className={classes.title}
              dangerouslySetInnerHTML={{
                __html: title,
              }}
            />
          )}

          <Typography variant="subtitle2">
            <TimeAgoCell value={date} className={classes.timeText} />
            {/* eslint-disable-next-line react/no-danger */}
            <span dangerouslySetInnerHTML={{ __html: itemByText }} />
          </Typography>
        </ExtendedGrid>
      </ExtendedGrid>
    </div>
  );
};

HistoryItem.propTypes = {
  className: PropTypes.string,
  userId: PropTypes.string,
  date: PropTypes.number,
  action: PropTypes.string,
  metadata: PropTypes.shape({
    entityId: PropTypes.string,
    email: PropTypes.string,
    role: PropTypes.string,
    oldName: PropTypes.string,
    newName: PropTypes.string,
    reason: PropTypes.string,
    public: PropTypes.bool,
    ordered: PropTypes.bool,
    transferable: PropTypes.bool,
    newHolder: PropTypes.string,
    oldHolder: PropTypes.string,
  }),
  dateGroup: PropTypes.string,
  dateActive: PropTypes.bool,
  lastDateInGroup: PropTypes.bool,
};

export default HistoryItem;
