import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import authActions from 'src/redux/actions/auth.actions';
import identityActions from 'src/redux/actions/identity.actions';
import avatarActions from 'src/redux/actions/avatar.actions';
import usePrevious from 'src/helpers/hooks/usePrevious';
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  List,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { Download as DownloadData, Delete as DeleteIcon } from 'src/components/CustomIcon';
import {
  Avatar,
  ContentContainer,
  ConfirmDialog,
  ExtendedGrid,
  FullScreenDialog,
  Gap,
} from 'src/components';
import { ContentHeading } from 'src/pages/WorkspaceConfiguration/subcomponents';
import { RequestStatus } from 'src/helpers/reduxReuquest.util';
import {
  AvatarEdit,
  AvatarDelete,
  UsernameEdit,
  PasswordEdit,
  PhoneEdit,
  EmailEdit,
  AccountDeleteError,
} from './editDialogs';
import { BasicItem } from './subcomponents';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import { useStyles } from '../styles';
import LanguageEdit from './editDialogs/LanguageEdit/LanguageEdit';

const Basic = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const smallScreen = useMediaQuery(({ breakpoints }) => breakpoints.down('sm'));

  const [editedSubpage, setEditedSubpage] = useState(null);
  const [deleteAvatarDialogOpen, setDeleteAvatarDialogOpen] = useState(false);
  const [downloadDialogOpen, setDownloadDialogOpen] = useState(false);
  const [deleteAccountDialogOpen, setDeleteAccountDialogOpen] = useState(false);
  const [deleteAccountErrorDialogOpen, setDeleteAccountErrorDialogOpen] = useState(false);
  const [requiredConfirmationMethod, setRequiredConfirmationMethod] = useState(null);

  const userProfile = useSelector((state) => state.authStore.userProfile);
  const userProfileRequestStatus = useSelector((state) => state.authStore.userProfileRequestStatus);
  const patchUsernameRequestStatus = useSelector(
    (state) => state.authStore.patchUsernameRequestStatus
  );
  const patchPasswordRequestStatus = useSelector(
    (state) => state.authStore.patchPasswordRequestStatus
  );
  const patchEmailRequestStatus = useSelector((state) => state.authStore.patchEmailRequestStatus);
  const postEmailRequestStatus = useSelector((state) => state.authStore.postEmailRequestStatus);
  const postEmailRequestError = useSelector((state) => state.authStore.postEmailRequestError);
  const patchPhoneRequestStatus = useSelector((state) => state.authStore.patchPhoneRequestStatus);
  const postPhoneRequestStatus = useSelector((state) => state.authStore.postPhoneRequestStatus);
  const postPhoneRequestError = useSelector((state) => state.authStore.postPhoneRequestError);
  const putUserAvatarRequestStatus = useSelector(
    (state) => state.avatarStore.putUserAvatarRequestStatus
  );
  const deleteUserAvatarRequestStatus = useSelector(
    (state) => state.avatarStore.deleteUserAvatarRequestStatus
  );
  const structureIdentity = useSelector((state) => state.identityStore.structure);
  const userAvatar = useSelector((state) => state.avatarStore.userAvatar);

  const firstRender = useRef(true);

  const availableIdentities = structureIdentity
    ? structureIdentity.filter((structure) => structure.identity.active)
    : structureIdentity;

  const prevState = usePrevious({
    putUserAvatarRequestStatus,
    deleteUserAvatarRequestStatus,
  });

  const getLanguageIconAndName = (language) => {
    switch (language.toLowerCase()) {
      case 'pl':
        return {
          code: 'pl-PL',
          text: 'language.pl-PL.text',
        };

      case 'en':
      default:
        return {
          code: 'en-GB',
          text: 'language.en-GB.text',
        };
    }
  };

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      if (userProfileRequestStatus === RequestStatus.IDLE) {
        dispatch(authActions.getUserProfile());
      }
    }
  }, [dispatch, userProfileRequestStatus]);

  useEffect(() => {
    if (userProfile) {
      dispatch(avatarActions.getUserAvatar(userProfile.userId));
    }
  }, [dispatch, userProfile]);

  useEffect(() => {
    if (patchUsernameRequestStatus === RequestStatus.SUCCESS) {
      handleBack();
      dispatch(identityActions.getUserStructure());
    }
  }, [dispatch, patchUsernameRequestStatus]);

  useEffect(() => {
    if (
      prevState?.putUserAvatarRequestStatus === RequestStatus.PENDING &&
      putUserAvatarRequestStatus === RequestStatus.SUCCESS
    ) {
      handleBack();
      dispatch(avatarActions.getUserAvatar(userProfile.userId));
      avatarActions.refreshAvatars(
        availableIdentities.map((structure) => structure.identity.identityId),
        'identity'
      );
    }
  }, [
    dispatch,
    prevState?.putUserAvatarRequestStatus,
    putUserAvatarRequestStatus,
    availableIdentities,
    userProfile,
  ]);

  useEffect(() => {
    if (
      prevState?.deleteUserAvatarRequestStatus === RequestStatus.PENDING &&
      deleteUserAvatarRequestStatus === RequestStatus.SUCCESS
    ) {
      dispatch(avatarActions.getUserAvatar(userProfile.userId));
      avatarActions.refreshAvatars(
        availableIdentities.map((structure) => structure.identity.identityId),
        'identity'
      );
    }
  }, [
    dispatch,
    prevState?.deleteUserAvatarRequestStatus,
    deleteUserAvatarRequestStatus,
    availableIdentities,
    userProfile,
  ]);

  useEffect(() => {
    if (patchEmailRequestStatus === RequestStatus.SUCCESS) {
      setRequiredConfirmationMethod('emailConfirm');
    }
  }, [patchEmailRequestStatus]);

  useEffect(() => {
    if (
      postEmailRequestStatus === RequestStatus.SUCCESS ||
      (postEmailRequestStatus === RequestStatus.ERROR &&
        postEmailRequestError !== 'CODE_VERIFICATION_FAILED')
    ) {
      setRequiredConfirmationMethod(null);
      handleBack();
    }
  }, [postEmailRequestStatus, postEmailRequestError]);

  useEffect(() => {
    if (patchPhoneRequestStatus === RequestStatus.SUCCESS) {
      setRequiredConfirmationMethod('phoneConfirm');
    }
  }, [patchPhoneRequestStatus]);

  useEffect(() => {
    if (
      postPhoneRequestStatus === RequestStatus.SUCCESS ||
      (postPhoneRequestStatus === RequestStatus.ERROR &&
        postPhoneRequestError !== 'CODE_VERIFICATION_FAILED')
    ) {
      setRequiredConfirmationMethod(null);
      handleBack();
    }
  }, [postPhoneRequestStatus, postPhoneRequestError]);

  useEffect(() => {
    if (patchPasswordRequestStatus === RequestStatus.SUCCESS) {
      handleBack();
    }
  }, [patchPasswordRequestStatus]);

  const deleteAvatarDialogToggle = {
    open: () => {
      setDeleteAvatarDialogOpen(true);
    },
    close: () => {
      setDeleteAvatarDialogOpen(false);
    },
  };

  const downloadDialogToggle = {
    open: () => {
      setDownloadDialogOpen(true);
    },
    close: () => {
      setDownloadDialogOpen(false);
    },
  };

  const deleteAccountDialogToggle = {
    open: () => {
      setDeleteAccountDialogOpen(true);
    },
    close: () => {
      setDeleteAccountDialogOpen(false);
    },
  };

  const deleteAccountErrorDialogToggle = {
    open: () => {
      deleteAccountDialogToggle.close();
      setDeleteAccountErrorDialogOpen(true);
    },
    close: () => {
      setDeleteAccountErrorDialogOpen(false);
    },
  };

  const handleBack = () => {
    setEditedSubpage(null);
  };

  const handleDataUpdate = (name, value) => {
    switch (name) {
      case 'avatar':
        dispatch(avatarActions.updateUserAvatar(value));
        break;
      case 'username':
        dispatch(authActions.updateUsername(value));
        break;
      case 'password':
        dispatch(authActions.updatePassword(value));
        break;
      case 'phoneNumber':
        dispatch(authActions.updatePhoneNumber(value));
        break;
      case 'email':
        dispatch(authActions.updateEmail(value));
        break;

      default:
    }
  };

  const deleteAccount = () => {
    setTimeout(() => {
      deleteAccountDialogToggle.close();
    }, 500);
    dispatch(authActions.deleteAccount(deleteAccountErrorDialogToggle.open));
  };

  const showEditPage = (name) => {
    if (userProfile) {
      setEditedSubpage(name);
    }
  };

  const extractHeader = (name) => {
    switch (name) {
      case 'avatar':
        return t('userConfigurationPage.basic.avatarPage.header');
      case 'username':
        return t('userConfigurationPage.basic.usernamePage.header');
      case 'password':
        return t('userConfigurationPage.basic.passwordPage.header');
      case 'phone':
        return t('userConfigurationPage.basic.phonePage.header');
      case 'email':
        return t('userConfigurationPage.basic.emailPage.header');
      case 'language':
        return t('userConfigurationPage.basic.languagePage.header');
      default:
        return '';
    }
  };
  const renderEditPage = (name) => {
    switch (name) {
      case 'avatar':
        return (
          <AvatarEdit
            updateBasicData={handleDataUpdate}
            isLoading={putUserAvatarRequestStatus === RequestStatus.PENDING}
          />
        );
      case 'username':
        return <UsernameEdit username={userProfile.userName} updateBasicData={handleDataUpdate} />;
      case 'password':
        return <PasswordEdit updateBasicData={handleDataUpdate} />;
      case 'phone':
        return (
          <PhoneEdit phoneNumber={userProfile.phoneNumber} updateBasicData={handleDataUpdate} />
        );
      case 'email':
        return <EmailEdit email={userProfile.email} updateBasicData={handleDataUpdate} />;
      case 'language':
        return <LanguageEdit onClose={handleBack} />;
      default:
        return loader();
    }
  };

  const loader = () => (
    <ExtendedGrid container alignItemsXxs="center" justifyContentXxs="center">
      <ExtendedGrid item>
        <CircularProgress />
      </ExtendedGrid>
    </ExtendedGrid>
  );

  const renderDownloadButton = () => (
    <Button
      classes={{ root: classes.actionButton }}
      endIcon={<DownloadData />}
      fullWidth={smallScreen}
      onClick={downloadDialogToggle.open}
      size={smallScreen ? 'large' : 'medium'}
    >
      {smallScreen ? t('common.downloadPersonalData') : t('common.downloadData')}
    </Button>
  );
  const renderSummaryPage = () => (
    <>
      <ContentHeading subtitle={t('userConfigurationPage.basic.subtitle')}>
        {t('userConfigurationPage.basic.header')}
      </ContentHeading>

      <Card variant="outlined" className={classes.root}>
        <CardContent>
          <List>
            <BasicItem
              smallScreen={smallScreen}
              label={t('userConfigurationPage.basic.avatar')}
              oneRowMobile
              onClick={() => showEditPage('avatar')}
              onDeleteClick={userAvatar ? deleteAvatarDialogToggle.open : null}
            >
              <Avatar
                size="medium"
                src={
                  userProfile && userAvatar
                    ? `${process.env.REACT_APP_AVATAR_URL}/user/${userProfile?.userId}?kind=SMALL`
                    : null
                }
                tooltip={userProfile?.userName}
              >
                {userProfile?.userName}
              </Avatar>
            </BasicItem>

            <BasicItem
              smallScreen={smallScreen}
              label={t('userConfigurationPage.basic.username')}
              onClick={() => showEditPage('username')}
            >
              <Typography>
                <strong>{userProfile ? userProfile.userName : '-'}</strong>
              </Typography>
            </BasicItem>

            <BasicItem
              smallScreen={smallScreen}
              label={t('userConfigurationPage.basic.password')}
              onClick={() => showEditPage('password')}
            >
              <Typography>
                <strong>••••••••••••••</strong>
              </Typography>
            </BasicItem>

            <BasicItem
              smallScreen={smallScreen}
              label={t('userConfigurationPage.basic.email')}
              onClick={() => showEditPage('email')}
            >
              <Typography>
                <strong>{userProfile ? userProfile.email : '-'}</strong>
              </Typography>
            </BasicItem>

            <BasicItem
              smallScreen={smallScreen}
              label={t('userConfigurationPage.basic.phoneNumber')}
              onClick={() => showEditPage('phone')}
            >
              <Typography>
                <strong>
                  {userProfile
                    ? `(${userProfile.phoneNumber.prefix}) ${userProfile.phoneNumber.number}`
                    : '-'}
                </strong>
              </Typography>
            </BasicItem>

            <BasicItem
              smallScreen={smallScreen}
              label={t('userConfigurationPage.basic.language')}
              onClick={() => showEditPage('language')}
            >
              <Typography>
                <strong>{t(getLanguageIconAndName(userProfile.language).text)}</strong>
              </Typography>
            </BasicItem>
          </List>
        </CardContent>
      </Card>

      <ContentContainer horizontal={false} vertical="top">
        {smallScreen ? (
          renderDownloadButton()
        ) : (
          <Card variant="outlined" className={classes.root}>
            <CardContent>
              <ExtendedGrid
                container
                spacing={2}
                alignItemsXxs="center"
                justifyContentXxs="space-between"
              >
                <ExtendedGrid item xxs={0} sm={6} textAlignXxs="center" textAlignSm="left">
                  <Typography variant="h6" color="initial" component="label">
                    {t('userConfigurationPage.basic.personalData')}
                  </Typography>
                </ExtendedGrid>
                <ExtendedGrid item xxs={12} sm={6} textAlignXxs="center" textAlignSm="right">
                  {renderDownloadButton()}
                </ExtendedGrid>
              </ExtendedGrid>
            </CardContent>
          </Card>
        )}
      </ContentContainer>

      <ContentContainer horizontal={false} vertical="top">
        <Card variant="outlined" className={classes.root}>
          <CardContent>
            <ExtendedGrid
              container
              spacing={2}
              alignItemsXxs="center"
              justifyContentXxs="space-between"
            >
              <ExtendedGrid item xxs={12} md={9}>
                <Grid container direction="column">
                  <Typography variant="h6" color="initial" component="label">
                    {t('userConfigurationPage.basic.deleteAccount.title')}
                  </Typography>
                  <Gap size="small" />
                  <Typography variant="body1" color="initial" component="label">
                    {t('userConfigurationPage.basic.deleteAccount.description')}
                  </Typography>
                </Grid>
              </ExtendedGrid>
              {smallScreen && <Gap size="default" />}
              <ExtendedGrid item xxs={12} md={3} textAlignXxs="center" textAlignSm="right">
                <Button
                  classes={{ root: classes.errorButton }}
                  endIcon={<DeleteIcon />}
                  fullWidth={smallScreen}
                  onClick={() => deleteAccountDialogToggle.open()}
                  size={smallScreen ? 'large' : 'medium'}
                >
                  {t('userConfigurationPage.basic.deleteAccount.button')}
                </Button>
              </ExtendedGrid>
            </ExtendedGrid>
          </CardContent>
        </Card>
      </ContentContainer>

      <AvatarDelete open={deleteAvatarDialogOpen} onClose={deleteAvatarDialogToggle.close} />

      <ConfirmDialog
        open={deleteAccountDialogOpen}
        classes={{ applyButton: classes.errorButton, subtitleWrapper: classes.subtitleWrapper }}
        variant="primary"
        title={t('userConfigurationPage.basic.deleteAccount.title')}
        subtitle={t('userConfigurationPage.basic.deleteAccount.confirmDescription')}
        applyButtonText={t('userConfigurationPage.basic.deleteAccount.button')}
        cancelButtonText={t('common.cancel')}
        actionAccept={deleteAccount}
        actionCancel={deleteAccountDialogToggle.close}
      />

      <ConfirmDialog
        open={downloadDialogOpen}
        variant="primary"
        subtitle={t('userConfigurationPage.basic.personalDataInfo')}
        cancelButtonText={t('common.back')}
        actionCancel={downloadDialogToggle.close}
      />
    </>
  );

  return (
    <>
      {renderSummaryPage()}
      <FullScreenDialog
        isOpen={!!editedSubpage}
        onBack={handleBack}
        onClose={handleBack}
        title={extractHeader(editedSubpage)}
      >
        <div className={classes.fullScreenDialog}>
          <div className={classes.content}>{renderEditPage(editedSubpage)}</div>
        </div>
      </FullScreenDialog>
      <FullScreenDialog
        isOpen={!!deleteAccountErrorDialogOpen}
        onBack={deleteAccountErrorDialogToggle.close}
        onClose={deleteAccountErrorDialogToggle.close}
        title={t('userConfigurationPage.basic.deleteErrorPage.header')}
      >
        <div className={classes.fullScreenDialog}>
          <div className={classes.content}>
            <AccountDeleteError onBack={deleteAccountErrorDialogToggle.close} />
          </div>
        </div>
      </FullScreenDialog>
      <ConfirmModal
        isOpen={!!requiredConfirmationMethod}
        method={requiredConfirmationMethod}
        handleConfirmDialogClose={() => setRequiredConfirmationMethod(null)}
        onResend={(name, value) => handleDataUpdate(name, value)}
      />
    </>
  );
};

export default Basic;
