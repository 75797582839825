import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ breakpoints, palette, spacing, typography, zIndex }) =>
    createStyles({
      root: {},
      rootOnAnimation: {
        zIndex: zIndex.modal + 10,
      },
      item: {},
      itemActive: {},
      itemMobile: {
        '& $itemIcon': {
          lineHeight: 1,
          marginBottom: spacing(1),
          '& svg': {
            display: 'block',
            width: 20,
            height: 20,
          },
        },
        '&$itemActive': {
          '& $itemTextMobile': {
            opacity: 1,
          },
        },
      },
      itemIcon: {},
      itemTextMobile: {
        fontSize: 10,
        fontWeight: typography.fontWeightBold,
        textAlign: 'center',
        lineHeight: 1.2,
        opacity: 0.8,
      },
      moreMenu: {},
      moreMenuPaper: {
        width: '100%',
        maxWidth: '100%',
        left: '0 !important',
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        border: `1px solid ${palette.divider}`,
      },
      moreMenuItem: {
        padding: spacing(1.5, 2),
        minHeight: 'auto',
        fontSize: '1.075rem',
        fontWeight: typography.fontWeightMedium,
        [breakpoints.up('xs')]: {
          fontSize: typography.h5.fontSize,
        },
      },
      moreMenuItemIconWrapper: {
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: palette.secondary.main,
        color: palette.common.white,
        minWidth: spacing(5),
        minHeight: spacing(5),
        borderRadius: '50%',
        marginRight: spacing(2),
      },
    }),
  { index: 0 }
);
