import { DocumentRole, rolesTranslations } from 'src/models/document.model';
import { capitalizeFirstLetter } from 'src/helpers/globalUtils';
import { RequestStatus } from '../../helpers/reduxReuquest.util';

const selectAvailableRoles = (state, t) =>
  state.documentInvitationsStore.documentRoleTypes
    .filter((role) => role !== DocumentRole.SIGNERS && role !== DocumentRole.HOLDER)
    .map((role) => ({
      value: role,
      label: rolesTranslations(t)[role] || capitalizeFirstLetter(role),
    }));

const selectIsAdvancedSettingsPending = (state) =>
  state.documentInvitationsStore.documentTransferabilityChangeStatus === RequestStatus.PENDING ||
  state.documentInvitationsStore.documentPublicAccessChangeStatus === RequestStatus.PENDING;

export const documentInvitationsSelector = {
  selectAvailableRoles,
  selectIsAdvancedSettingsPending,
};
