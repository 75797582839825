import React from 'react';
import clsx from 'clsx';
import { CertificateFileIcon, DriveIcon, ESign as ESignIcon } from 'src/components/CustomIcon';
import { DocumentItem } from 'src/models/documents.model';
import { useStyles } from './styles';
import { IconCell } from '../cells';

type ItemsTemplateProps = {
  selectedDocuments: DocumentItem[];
};

const getIcon = (documentType: string) => {
  if (documentType.includes('dsl')) {
    return <CertificateFileIcon />;
  }
  if (documentType.includes('legacy-drive')) {
    return <DriveIcon />;
  }
  return <ESignIcon />;
};

const ItemsTemplate = ({ selectedDocuments }: ItemsTemplateProps): JSX.Element => {
  const classes = useStyles();
  const rows = selectedDocuments.map((doc, index) => (
    <div key={`${doc.id}_${index}`} className={clsx(classes.dragItem)}>
      <IconCell
        isHover={false}
        isSelected={false}
        onRowCheckboxClick={() => undefined}
        icon={getIcon(doc.documentType)}
        className={clsx(classes.dragItemCell)}
      />
      {doc.name}
    </div>
  ));
  return <div>{rows}</div>;
};

export default ItemsTemplate;
