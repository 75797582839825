import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { ContentContainer, HeaderButton, TopBarContainer, Logo } from 'src/components';
import { ArrowLeftSLine as BackIcon, Close as CloseIcon } from 'src/components/CustomIcon';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

const useStyles = makeStyles(
  ({ breakpoints }) => ({
    header: {
      justifyContent: 'space-between',
      [breakpoints.up('sm')]: {
        justifyContent: 'flex-end',
      },
    },
    headerWithLeftSpace: {
      marginLeft: 40,
      [breakpoints.up('md')]: {
        marginLeft: 48,
      },
    },
    headerWithLogo: {
      justifyContent: 'space-between',
    },
    title: {
      flex: 1,
      overflow: 'hidden',
    },
    closeButton: {
      [breakpoints.up('lg')]: {
        marginRight: -12,
      },
    },
  }),
  { index: 0 }
);

const DialogTitle = (props) => {
  const classes = useStyles({});
  const { t } = useTranslation();
  const { children, onClose, onBack, leftSpace, withLogo } = props;

  const titleContentHorizontalProp = () => {
    if ((leftSpace || onBack) && onClose) {
      return true;
    }
    if (leftSpace || onBack) {
      return 'left';
    }
    if (onClose) {
      return 'right';
    }
    return false;
  };

  return (
    <TopBarContainer
      className={clsx({
        [classes.header]: !withLogo,
        [classes.headerWithLogo]: withLogo,
      })}
    >
      {withLogo ? <Logo size="large" /> : <div />}

      {onBack ? (
        <>
          <HeaderButton
            color="primary"
            className={classes.backButton}
            onClick={onBack}
            icon={<BackIcon />}
            tooltip={t('common.back')}
          />
        </>
      ) : (
        <div />
      )}

      <ContentContainer
        vertical={false}
        size="small"
        horizontal={titleContentHorizontalProp()}
        className={clsx(classes.title, {
          [classes.headerWithLeftSpace]: leftSpace,
        })}
      >
        <Typography variant="h5">{children}</Typography>
      </ContentContainer>

      {onClose && (
        <HeaderButton
          color="primary"
          className={classes.closeButton}
          onClick={onClose}
          icon={<CloseIcon />}
          tooltip={t('common.close')}
        />
      )}
    </TopBarContainer>
  );
};

DialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func,
  onBack: PropTypes.func,
  withLogo: PropTypes.bool,
  leftSpace: PropTypes.bool,
};

DialogTitle.defaultProps = {
  leftSpace: false,
};

export default DialogTitle;
