import i18n from 'src/helpers/i18n';
import { SNACKBAR_VARIANT } from 'src/models/common.model';
import { moduleName as dslService } from 'src/redux/services/documentDSL.service';
import documentJoinService from '../services/documentJoin.service';
import documentJoinConstants from '../constants/documentJoin.constants';
import snackbarActions from './snackbar.actions';
import { ErrorCode } from '../../models/errors.model';

const documentJoinActions = {
  getDocumentInvitation,
  acceptDocumentInvitation,
  clearDocumentJoinStore,
};

function getDocumentInvitation(invitationId, service) {
  return (dispatch) => {
    dispatch({ type: documentJoinConstants.GET_DOCUMENT_INVITATION_REQUEST });
    documentJoinService
      .getDocumentInvitation(invitationId, service)
      .then((response) => {
        dispatch({
          type: documentJoinConstants.GET_DOCUMENT_INVITATION_SUCCESS,
          data: response.data,
        });
      })
      .catch(() => {
        dispatch({ type: documentJoinConstants.GET_DOCUMENT_INVITATION_FAILURE });
        dispatch(
          snackbarActions.enqueueSnackbar(
            SNACKBAR_VARIANT.ERROR,
            i18n.t('snackbars.common.errorTitle'),
            i18n.t('joiningToDocumentPage.fetchInvitationError.toast.error')
          )
        );
      });
  };
}

function acceptDocumentInvitation(invitationId, callbackOnSuccess, apiService, data) {
  return (dispatch) => {
    dispatch({ type: documentJoinConstants.ACCEPT_DOCUMENT_INVITATION_REQUEST });
    documentJoinService
      .acceptDocumentInvitation(invitationId, apiService, data)
      .then(() => {
        dispatch({
          type: documentJoinConstants.ACCEPT_DOCUMENT_INVITATION_SUCCESS,
        });
        if (typeof callbackOnSuccess === 'function') {
          dispatch(callbackOnSuccess);
        }
      })
      .catch((errors) => {
        if (errors.code === ErrorCode.IDENTITY_NOT_READY) {
          setTimeout(() => {
            dispatch(acceptDocumentInvitation(invitationId, callbackOnSuccess, apiService));
          }, 5000);
        } else {
          dispatch({ type: documentJoinConstants.ACCEPT_DOCUMENT_INVITATION_FAILURE });
          dispatch(
            snackbarActions.enqueueSnackbar(
              SNACKBAR_VARIANT.ERROR,
              i18n.t('joiningToDocumentPage.acceptInvitationError.header'),
              apiService === dslService
                ? i18n.t('joiningToDocumentPage.acceptInvitationError.descriptionCert')
                : i18n.t('joiningToDocumentPage.acceptInvitationError.description')
            )
          );
        }
      });
  };
}

function clearDocumentJoinStore() {
  return { type: documentJoinConstants.CLEAR_DOCUMENT_INVITATION_STORE };
}

export default documentJoinActions;
