import React from 'react';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import moment from 'moment';
import certificateCreatorActions from 'src/redux/actions/certificateCreator.actions';
import { CertificateQuantityType, CertificateSteps } from 'src/models/certificate.model';
import { generateUUID } from 'src/helpers/globalUtils';
import { RegexFormulas } from 'src/helpers/regex';
import ActionButtons from './components/ActionButtons';
import { useStyles } from './styles';
import ImportDataTable from './components/ImportDataTable';

const ImportMultipleData = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    certificateQuantityType,
    selectedTemplate,
    draftImportedData,
    templateDetails,
  } = useSelector((state) => state.certificateCreatorStore, undefined);
  const { currentIdentity } = useSelector((state) => state.identityStore, undefined);
  const workspaceId = currentIdentity?.workspace?.workspaceId;

  const onContinueClick = () => {
    const holders = {};
    if (certificateQuantityType === CertificateQuantityType.Multiple) {
      dispatch(
        certificateCreatorActions.createCertificate({
          workspaceId,
          templateId: selectedTemplate.id,
          certificateList: draftImportedData
            .filter((fields) => Object.values(fields).filter((el) => el).length !== 0)
            .map((fields) => {
              let additionalFiles = [];
              let documentName = null;
              const correlationId = generateUUID();
              Object.keys(fields).forEach((key) => {
                const timestamp = Date.parse(fields[key]);
                if (Number.isNaN(timestamp) === false) {
                  fields[key] =
                    typeof fields[key] === 'object'
                      ? moment(new Date(fields[key])).format('yyyy-MM-DD')
                      : fields[key];
                }
                if (Array.isArray(fields[key])) {
                  additionalFiles = [...fields[key]].filter((el) => el).map((el) => el.base64);
                  delete fields[key];
                }
                if (key === 'documentName') {
                  documentName = fields[key] === '' ? null : fields[key];
                  delete fields[key];
                }
                if (key === 'holderMailMulti') {
                  if (fields[key] !== '') holders[correlationId] = fields[key];
                  delete fields[key];
                }
              });

              return {
                correlationId,
                additionalFiles,
                documentName,
                templateFields: fields,
              };
            }),
        })
      );
      dispatch(certificateCreatorActions.setMultipleHolders(holders));
      dispatch(
        certificateCreatorActions.setCreatorStep({
          currentStep: CertificateSteps.CreatingCertificatesProgressScreen,
        })
      );
    }
  };
  const validate = (values) => {
    const emptyRows = [];
    const errors = values.fields.map((row) => {
      const hasAllEmptyFields = Object.values(row).filter((el) => el).length === 0;
      emptyRows.push(hasAllEmptyFields);
      if (hasAllEmptyFields) return undefined;
      const errors = {};
      Object.keys(row).forEach((field) => {
        if (templateDetails.fields[field]) {
          const { required, format } = templateDetails.fields[field];

          if (
            format === 'date' &&
            !yup.date().typeError(t('validation.dateFormat')).isValidSync(row[field])
          ) {
            errors[field] = t('validation.dateFormat');
          } else if (
            format === 'base64' &&
            !yup.mixed().when('isArray', {
              is: Array.isArray,
              then: yup.array(yup.string().matches(RegexFormulas.base64).isValidSync(row[field])),
              otherwise: yup.string().matches(RegexFormulas.base64).isValidSync(row[field]),
            })
          ) {
            errors[field] = t('validation.base64');
          } else if (
            field === 'documentName' &&
            !yup
              .string()
              .matches(RegexFormulas.folderName, t('validation.fileName'))
              .isValidSync(row[field])
          ) {
            errors[field] = t('validation.fileName');
          } else if (required && !yup.string().required().isValidSync(row[field])) {
            errors[field] = t('validation.required');
          }
        }
      });

      return Object.keys(errors).length === 0 ? undefined : errors;
    });
    if (
      (values.fields.length === 1 && errors.length === 0) ||
      emptyRows.every((el) => el === true)
    ) {
      return { fields: {} };
    }

    return errors.filter((el) => el).length === 0 ? undefined : { fields: errors };
  };

  return (
    <>
      <Formik
        validate={validate}
        initialValues={{ fields: draftImportedData }}
        onSubmit={onContinueClick}
        enableReinitialize
      >
        <Form>
          <ActionButtons />
          <ImportDataTable />
          <div className={classes.buttonWrapper}>
            <Button size="large" type="submit" fullWidth disabled={draftImportedData.length === 0}>
              {t('createCertificate.continue')}
            </Button>
          </div>
        </Form>
      </Formik>
    </>
  );
};

export default ImportMultipleData;
