import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  ListSubheader,
  Typography,
} from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { ConfirmDialog } from 'src/components';
import { useStyles } from './styles';
import { arrayMoveImmutable } from '../Sortable/sortableUtils';
import { SortableItem, SortableContainer } from '../Sortable';
import { extendOptionsByIdAndFirstLetter, filterOptionsFunctions } from './helpers';
import { AutocompleteOption, AutocompleteInput, AddAndRemoveUsersListItem } from './subcomponents';

const AddAndRemoveUsers = ({
  className: classNameProp,
  isSmall,
  items,
  availableItems,
  placeholder,
  noResultsText,
  hideNoResults = false,
  hideInput = false,
  disabled = false,
  loading = false,
  isPending = false,
  clearInput = false,
  allowDuplicatedForDiffIds = false,
  onAddClick,
  onDeleteClick,
  authorAction,
  onAddNewEmailClick,
  documentInvitationMode,
  onRoleUpdate,
  onInputChange,
  disableInviting = false,
  sortByGroup = false,
  isOrderSignificant = false,
  onItemsOrderChange,
  availableRoles,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [focused, setFocused] = useState(false);
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [openGroupAllDialog, setOpenGroupAllDialog] = useState(false);
  const [groupAllDialogValue, setGroupAllDialogValue] = useState(null);

  const workspace = useSelector((state) => state.identityStore, undefined);
  const workspaceId = workspace.currentIdentity?.workspace?.workspaceId;
  const currentIdentityId = workspace.currentIdentity?.identity.identityId;

  const extendedAutocompleteOptions = useMemo(
    () =>
      extendOptionsByIdAndFirstLetter(availableItems).sort(
        (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
      ),
    [availableItems]
  );

  const autocompleteOptions = useMemo(
    () =>
      extendedAutocompleteOptions
        .filter((o) => (isOrderSignificant ? o.workspaceId === workspaceId : true)) // check if isOrderSignificant then user is in same workspace
        .filter((o1) => !items.some((o2) => o1?.id === o2?.id)), // check if user is not already on list
    [extendedAutocompleteOptions, items, workspaceId, isOrderSignificant]
  );

  const hasAddedItems = useMemo(() => items?.length > 0, [items?.length]);

  useMemo(() => {
    if (clearInput) {
      setValue(null);
      setInputValue('');
    }
  }, [clearInput]);

  const filter = createFilterOptions();
  const additionalText = documentInvitationMode
    ? `(${t('common.author')})`
    : `(${t('common.you')})`;

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const sorted = arrayMoveImmutable(items, oldIndex, newIndex);
    onItemsOrderChange(sorted);
  };

  const renderGroup = (params) => [
    <ListSubheader key={params.key} classes={{ root: classes.groupHeader }}>
      <Typography variant="subtitle2">
        {t(`common.${params.group === 'true' ? 'groups' : 'users'}`)}
      </Typography>
    </ListSubheader>,
    params.children,
  ];

  const onAutocompleteChange = (event, newValue) => {
    if (documentInvitationMode && typeof newValue === 'string') {
      // Value selected with enter, right from the input
      const newRole = {
        id: Date.now(),
        name: newValue.trim(),
        email: newValue.trim(),
        identityId: null,
        active: true,
      };
      if (typeof onAddNewEmailClick === 'function') {
        onAddNewEmailClick(newRole);
      }
      setValue(newRole);
    } else if (documentInvitationMode && newValue && newValue.inputValue) {
      // Create a new value from the user input
      const newRole = {
        id: Date.now(),
        name: newValue.inputValue.trim(),
        email: newValue.inputValue.trim(),
        identityId: null,
        active: true,
      };

      if (typeof onAddNewEmailClick === 'function') {
        onAddNewEmailClick(newRole);
      }
      setValue(newRole);
    } else if (newValue?.name === 'All' && newValue?.identityId === null) {
      // Adding group 'All'
      setGroupAllDialogValue(newValue);
      setOpenGroupAllDialog(true);
    } else {
      setValue(newValue);
      if (newValue !== null) {
        const { id } = newValue;
        onAddClick(id);
      }
    }
  };

  const getOptionLabel = (option) => {
    // Value selected with enter, right from the input
    if (documentInvitationMode && typeof option === 'string') {
      return option;
    }
    // Add option created dynamically
    if (documentInvitationMode && option.inputValue) {
      return option.inputValue;
    }
    // Regular option
    return option.name === option.email ? option.name : `${option.name} ${option.email}`;
  };

  const filterOptions = (options, params) => {
    const filtered = filter(options, params);
    const value = params.inputValue;
    // Suggest the creation of a new value and new mails are not available when order is important
    if (
      !isOrderSignificant &&
      documentInvitationMode &&
      filterOptionsFunctions.isMail(value) &&
      filterOptionsFunctions.isNotEmptyString(value) &&
      filterOptionsFunctions.isNameAndMailNotRepeated({
        items: [...filtered, ...items],
        value,
        allowDuplicatedForDiffIds,
      })
    ) {
      filtered.push({
        inputValue: value,
        name: value,
      });
    }

    return filtered;
  };

  return (
    <>
      <Box className={clsx(classes.root, isSmall && classes.smallRoot, classNameProp)}>
        {!disableInviting && !hideInput && (
          <Autocomplete
            classes={{ listbox: clsx(loading && classes.disabledListBox) }}
            value={value}
            inputValue={inputValue}
            disabled={disabled}
            options={autocompleteOptions.sort((a, b) => -b?.group) || []}
            renderOption={(option) => (
              <AutocompleteOption
                option={option}
                workspaceId={workspaceId}
                additionalText={additionalText}
                documentInvitationMode={documentInvitationMode}
                currentIdentityId={currentIdentityId}
              />
            )}
            filterOptions={filterOptions}
            freeSolo={documentInvitationMode}
            getOptionLabel={getOptionLabel}
            getOptionSelected={(option) => option.name || ''}
            renderGroup={sortByGroup ? renderGroup : null}
            groupBy={sortByGroup ? (option) => String(option.group) : null}
            fullWidth
            blurOnSelect
            clearText={t('common.clear')}
            noOptionsText={t('common.noResults')}
            renderInput={(params) => (
              <AutocompleteInput
                params={params}
                loading={loading}
                focused={focused}
                placeholder={placeholder}
              />
            )}
            onChange={onAutocompleteChange}
            onFocus={() => {
              setFocused(true);
              if (typeof onInputChange === 'function') {
                onInputChange(inputValue);
              }
            }}
            onBlur={() => setFocused(false)}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
              if (typeof onInputChange === 'function') {
                onInputChange(newInputValue);
              }
            }}
          />
        )}

        {!hideNoResults && (
          <div className={classes.content}>
            <Card variant="outlined" className={classes.cardContent}>
              {!hasAddedItems && (
                <CardContent>
                  {isPending ? (
                    <div className={classes.loading}>
                      <CircularProgress />
                    </div>
                  ) : (
                    <Typography align="center" variant="body2">
                      {noResultsText}
                    </Typography>
                  )}
                </CardContent>
              )}
              {hasAddedItems && (
                <SortableContainer onSortEnd={onSortEnd} useDragHandle lockAxis="y">
                  {items.map((user, index) => (
                    <SortableItem
                      key={`item-${index}`}
                      index={index}
                      className={classes.addedListItem}
                      allowItemsSorting={typeof onItemsOrderChange === 'function'}
                    >
                      <AddAndRemoveUsersListItem
                        user={user}
                        documentInvitationMode={documentInvitationMode}
                        currentIdentityId={currentIdentityId}
                        workspaceId={workspaceId}
                        authorAction={authorAction}
                        disableInviting={disableInviting}
                        onDeleteClick={onDeleteClick}
                        onRoleUpdate={onRoleUpdate}
                        availableRoles={availableRoles}
                      />
                    </SortableItem>
                  ))}
                </SortableContainer>
              )}
            </Card>
          </div>
        )}
      </Box>

      <ConfirmDialog
        open={openGroupAllDialog}
        title={t('inviteToDocument.inviteGroupAllDialog.header')}
        subtitle={t('inviteToDocument.inviteGroupAllDialog.description')}
        applyButtonText={t('inviteToDocument.inviteGroupAllDialog.confirm')}
        cancelButtonText={t('common.cancel')}
        actionAccept={() => {
          setValue(groupAllDialogValue);
          if (groupAllDialogValue !== null) {
            const { id } = groupAllDialogValue;
            onAddClick(id);
            setGroupAllDialogValue(null);
          }
          setOpenGroupAllDialog(false);
        }}
        actionCancel={() => {
          setValue(null);
          setInputValue('');
          setGroupAllDialogValue(null);
          setOpenGroupAllDialog(false);
        }}
      />
    </>
  );
};

AddAndRemoveUsers.propTypes = {
  className: PropTypes.string,
  isSmall: PropTypes.bool,
  placeholder: PropTypes.string,
  noResultsText: PropTypes.string.isRequired,
  availableRoles: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  availableItems: PropTypes.arrayOf(
    PropTypes.shape({
      contactEmail: PropTypes.string,
      email: PropTypes.string,
      identityId: PropTypes.string,
      name: PropTypes.string,
    })
  ).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string,
      identityId: PropTypes.string,
      name: PropTypes.string,
    })
  ).isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  isPending: PropTypes.bool,
  hideNoResults: PropTypes.bool,
  hideInput: PropTypes.bool,
  clearInput: PropTypes.bool,
  onAddClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  authorAction: PropTypes.node,
  onAddNewEmailClick: PropTypes.func,
  allowDuplicatedForDiffIds: PropTypes.bool,
  documentInvitationMode: PropTypes.bool,
  isOrderSignificant: PropTypes.bool,
  onRoleUpdate: PropTypes.func,
  onInputChange: PropTypes.func,
  disableInviting: PropTypes.bool,
  sortByGroup: PropTypes.bool,
  onItemsOrderChange: PropTypes.func,
};

export default AddAndRemoveUsers;
