import palette from '../palette';
import custom from '../custom';

export default {
  root: {
    position: 'relative',
    justifyContent: 'space-between',
    height: custom.bottomNavigationHeight,
    maxHeight: '19vh',
    backgroundColor: '#3C4163',
    color: palette.common.white,
    '&:before': {
      content: "''",
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: 'radial-gradient(84.9% 84.9% at 50% -34.9%, #FFFFFF 0%, #0C0C1F 100%), #0C0C1F',
      backgroundBlendMode: 'color-dodge, normal',
    },
  },
};
