import { makeStyles, createStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  () =>
    createStyles({
      fieldsWrapper: {
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: 24,
      },
      loader: {
        display: 'flex',
        justifyContent: 'center',
      },
      bottomButton: {
        marginTop: 'auto',
      },
      select: {
        marginBottom: 12,
      },
      selectLabel: {
        marginBottom: 8,
      },
      title: {
        marginBottom: 24,
      },
    }),
  { index: 0 }
);
