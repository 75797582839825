import spacing from '../spacing';
import breakpoints from '../breakpoints';

export default {
  root: {
    flexWrap: 'nowrap',
    padding: spacing(1.5),
    borderRadius: 'unset',
    [breakpoints.up('sm')]: {
      minWidth: 'auto',
      maxWidth: 'none',
      width: 300,
      borderRadius: spacing(1),
    },
    [breakpoints.up('xl')]: {
      width: 320,
    },
  },
  message: {
    padding: `0 ${22 + spacing(1)}px`,
    position: 'relative',
    width: '100%',
    '& > svg': {
      position: 'absolute',
      top: -2,
      left: -2,
      fontSize: 24,
    },
  },
  action: {
    position: 'absolute',
    top: spacing(0.5),
    right: spacing(0.5),
    marginRight: 0,
    paddingLeft: spacing(1.5),
  },
};
