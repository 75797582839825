export enum documentUsersManagerConstants {
  FETCH_DOCUMENT_MEMBERS_REQUEST = 'documentUsersManager/FETCH_DOCUMENT_MEMBERS_REQUEST',
  FETCH_DOCUMENT_MEMBERS_SUCCESS = 'documentUsersManager/FETCH_DOCUMENT_MEMBERS_SUCCESS',
  FETCH_DOCUMENT_MEMBERS_FAILURE = 'documentUsersManager/FETCH_DOCUMENT_MEMBERS_FAILURE',

  FETCH_AVAILABLE_ROLES_REQUEST = 'documentUsersManager/FETCH_AVAILABLE_ROLES_REQUEST',
  FETCH_AVAILABLE_ROLES_SUCCESS = 'documentUsersManager/FETCH_AVAILABLE_ROLES_SUCCESS',
  FETCH_AVAILABLE_ROLES_FAILURE = 'documentUsersManager/FETCH_AVAILABLE_ROLES_FAILURE',

  FETCH_DOCUMENT_SIGNERS_REQUEST = 'documentUsersManager/FETCH_DOCUMENT_SIGNERS_REQUEST',
  FETCH_DOCUMENT_SIGNERS_SUCCESS = 'documentUsersManager/FETCH_DOCUMENT_SIGNERS_SUCCESS',
  FETCH_DOCUMENT_SIGNERS_FAILURE = 'documentUsersManager/FETCH_DOCUMENT_SIGNERS_FAILURE',

  FETCH_DOCUMENT_INVITATIONS_REQUEST = 'documentUsersManager/FETCH_DOCUMENT_INVITATIONS_REQUEST',
  FETCH_DOCUMENT_INVITATIONS_SUCCESS = 'documentUsersManager/FETCH_DOCUMENT_INVITATIONS_SUCCESS',
  FETCH_DOCUMENT_INVITATIONS_FAILURE = 'documentUsersManager/FETCH_DOCUMENT_INVITATIONS_FAILURE',

  FETCH_ENTITIES_BY_NAME_REQUEST = 'documentUsersManager/FETCH_ENTITIES_BY_NAME_REQUEST',
  FETCH_ENTITIES_BY_NAME_SUCCESS = 'documentUsersManager/FETCH_ENTITIES_BY_NAME_SUCCESS',
  FETCH_ENTITIES_BY_NAME_FAILURE = 'documentUsersManager/FETCH_ENTITIES_BY_NAME_FAILURE',

  FETCH_DOCUMENT_ENTITIES_REQUEST = 'documentUsersManager/FETCH_DOCUMENT_ENTITIES_REQUEST',
  FETCH_DOCUMENT_ENTITIES_SUCCESS = 'documentUsersManager/FETCH_DOCUMENT_ENTITIES_SUCCESS',
  FETCH_DOCUMENT_ENTITIES_FAILURE = 'documentUsersManager/FETCH_DOCUMENT_ENTITIES_FAILURE',

  FETCH_AVAILABLE_DOCUMENT_ACTIONS_REQUEST = 'documentUsersManager/FETCH_AVAILABLE_DOCUMENT_ACTIONS_REQUEST',
  FETCH_AVAILABLE_DOCUMENT_ACTIONS_SUCCESS = 'documentUsersManager/FETCH_AVAILABLE_DOCUMENT_ACTIONS_SUCCESS',
  FETCH_AVAILABLE_DOCUMENT_ACTIONS_FAILURE = 'documentUsersManager/FETCH_AVAILABLE_DOCUMENT_ACTIONS_FAILURE',

  UPDATE_DRAFT_ROLES = 'documentUsersManager/UPDATE_DRAFT_ROLES',
  UPDATE_DRAFT_TRANSFERABILITY = 'documentUsersManager/UPDATE_DRAFT_TRANSFERABILITY',

  UPDATE_SIGNERS_CHANGED = 'documentUsersManager/UPDATE_SIGNERS_CHANGED',
  UPDATE_OTHER_RECIPIENTS_CHANGED = 'documentUsersManager/UPDATE_OTHER_RECIPIENTS_CHANGED',

  CLEAR = 'documentUsersManager/CLEAR',
}
