import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, TextField, Typography } from '@material-ui/core';
import useForm from 'src/helpers/hooks/useForm';
import validate from 'src/helpers/formValidationRules';
import { FullScreenDialog, SmallContentWithScrollbar } from '../../index';

const AddNewFolder = ({ open, onClose, onChange }) => {
  const { t } = useTranslation();

  const handleUpdate = () => {
    onChange(values.folderName);
  };

  const { values, errors, handleChange, handleSubmit } = useForm(handleUpdate, validate, {
    folderName: '',
  });

  return (
    <FullScreenDialog isOpen={open} onClose={onClose} withLogo>
      <SmallContentWithScrollbar
        startContent={
          <Typography variant="h2">{t('drivePage.foldersDialog.addNew.title')}</Typography>
        }
        endContent={
          <Button
            size="large"
            fullWidth
            // onClick={() => onChange(newName.trim())}
            type="submit"
          >
            {t('drivePage.foldersDialog.addNew.apply')}
          </Button>
        }
        hasForm
        formProps={{
          noValidate: true,
          autoComplete: 'off',
          onSubmit: handleSubmit,
        }}
      >
        <TextField
          fullWidth
          placeholder={t('drivePage.foldersDialog.addNew.description')}
          name="folderName"
          autoFocus
          value={values.folderName || ''}
          onChange={handleChange}
          error={errors.folderName?.length > 0}
          helperText={errors.folderName}
        />
      </SmallContentWithScrollbar>
    </FullScreenDialog>
  );
};

AddNewFolder.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onChange: PropTypes.func,
};

AddNewFolder.defaultProps = {
  open: false,
};

export default AddNewFolder;
