import transitions from '@material-ui/core/styles/transitions';
import breakpoints from '../breakpoints';
import palette from '../palette';

export default {
  tag: {
    margin: '2px 4px 2px 0',
    maxWidth: 'calc(100% - 4px)',
    '& + $input': {
      marginRight: '0 !important',
      minWidth: '64px !important',
    },
  },
  tagSizeSmall: {
    margin: '3px 6px 3px 0',
    maxWidth: 'calc(100% - 6px)',
  },
  input: {},
  inputRoot: {
    '$hasPopupIcon &, $hasClearIcon &': {
      paddingRight: 26 + 4,
    },
    '$hasPopupIcon$hasClearIcon &': {
      paddingRight: 52 + 4,
    },
    '& $input': {
      width: 0,
      minWidth: 30,
    },
    '&[class*="MuiInput-root"]': {
      paddingBottom: 1,
      '& $input': {
        padding: 4,
      },
      '& $input:first-child': {
        padding: '6px 0',
      },
    },
    '&[class*="MuiOutlinedInput-root"]': {
      minHeight: 48,
      padding: '10px 12px',
      '& $input': {
        padding: '10px 12px',
        margin: '-10px -12px',
      },
      '$hasPopupIcon &, $hasClearIcon &': {
        paddingRight: 26 + 4 + 9,
      },
      '$hasPopupIcon$hasClearIcon &': {
        paddingRight: 52 + 4 + 9,
      },
      '& $input:first-child': {
        paddingLeft: 12,
      },
      '& $endAdornment': {
        right: 12,
      },
    },
  },
  endAdornment: {
    top: 'calc(50% - 12px)',
  },
  clearIndicator: {
    marginRight: -2,
    padding: 5,
    // visibility: 'unset',
    '& svg': {
      fontSize: 14,
    },
  },
  popupIndicator: {
    padding: 2,
    marginRight: -2,
  },
  popupIndicatorOpen: {
    transform: 'rotate(180deg)',
  },
  paper: {
    margin: 0,
  },
  listbox: {
    padding: 0,
    maxHeight: 'calc(50vh - 24px)',
  },
  loading: {
    color: palette.text.secondary,
    padding: '14px 16px',
  },
  noOptions: {
    color: palette.text.secondary,
    padding: '14px 16px',
  },

  option: {
    WebkitTapHighlightColor: 'transparent',
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 6,
    paddingBottom: 6,
    minHeight: 48,
    borderBottom: `1px solid ${palette.divider}`,
    [breakpoints.up('sm')]: {
      minHeight: 48,
    },
    transition: transitions.create(['background-color', 'border', 'color', 'opacity'], {
      duration: transitions.duration.short,
    }),
    '&[aria-selected="true"]': {
      backgroundColor: palette.background.paper,
      color: palette.primary.main,
      '&[data-focus="true"]': {
        backgroundColor: palette.action.hover,
      },
    },
    '&:active': {
      backgroundColor: palette.background.paper,
      color: palette.primary.main,
    },
    '&[data-focus="true"]': {
      backgroundColor: palette.action.hover,
    },
    '&[aria-disabled="true"]': {
      opacity: palette.action.disabledOpacity,
    },
  },
  groupLabel: {
    backgroundColor: palette.background.paper,
    top: -8,
  },
  groupUl: {
    padding: 0,
    '& $option': {
      paddingLeft: 24,
    },
  },
};
