export interface WorkspaceUser {
  active?: boolean;
  addedAt?: number;
  email: string;
  identityId?: string;
  name?: string;
}

export interface WorkspaceInvitedUser {
  email: string;
}

export const mapWorkspaceInvitedUserToWorkspaceUser = (
  invitedUsers: WorkspaceInvitedUser[]
): WorkspaceUser[] =>
  invitedUsers.map((el) => ({
    active: undefined,
    addedAt: undefined,
    email: el.email,
    identityId: undefined,
    name: undefined,
  }));
