import { fade } from '@material-ui/core/styles';
import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ palette, spacing, breakpoints }) =>
    createStyles({
      alertWrapper: {
        zIndex: 1,
        paddingTop: 0,
        paddingBottom: 0,
        minHeight: spacing(7),
        display: 'flex',
        alignItems: 'center',
        '& .MuiAlert-action button': {
          margin: '0!important',
        },
        '& .MuiAlert-message ': {
          [breakpoints.down('md')]: {
            display: 'flex',
            alignItems: 'center',
            '& span': {
              paddingRight: spacing(1),
            },
          },
        },

        [breakpoints.down('md')]: {
          marginBottom: spacing(2),
        },
      },
      alertButton: {
        marginLeft: spacing(2),
        color: palette.error.contrastText,
        textDecoration: 'none !important',
        border: `1px solid ${fade(palette.error.contrastText, 0.5)}`,
        '&:hover': {
          border: `1px solid ${palette.error.contrastText}`,
          backgroundColor: fade(palette.common.black, palette.action.hoverOpacity),
          '@media (hover: none)': {
            backgroundColor: 'transparent',
          },
        },
        [breakpoints.down('md')]: {
          marginLeft: 0,
        },
      },
    }),
  { index: 0 }
);
