import spacing from 'src/theme/options/spacing';
import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ palette, typography, transitions }) =>
    createStyles({
      root: {},
      menuLink: {
        padding: spacing(0.75, 1.5),
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        minHeight: 36,
        fontSize: typography.subtitle2.fontSize,
        color: palette.text.secondary,
        borderBottom: `1px solid ${palette.divider}`,
        '&:hover, &:focus': {
          color: palette.primary.main,
        },
      },
      breadcrumbLink: {
        padding: 4,
        margin: -4,
      },
      dropBackground: {
        backgroundColor: palette.primary.lightest,
        borderRadius: spacing(0.5),
        transition: transitions.create(['background-color', 'color'], {
          duration: transitions.duration.standard,
        }),
      },
    }),
  { index: 0 }
);
