import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Avatar, Tooltip } from '@material-ui/core';
import { lighten } from '@material-ui/core/styles';
import { blueGrey } from '@material-ui/core/colors';
import { useStyles } from './styles';
import { getColorFromText, getInitials } from '../../helpers/globalUtilsTS';

const UserAvatar = ({
  className: classNameProp,
  size,
  color,
  customColor,
  children,
  extraAvatars,
  coefficientColor,
  tooltip,
  ...other
}) => {
  const classes = useStyles();
  const defaultColor = blueGrey[600];
  const initials = getInitials(children || ' ');
  const colorByChild = initials ? getColorFromText(initials) : defaultColor;

  const renderAvatar = () => (
    <Avatar
      className={clsx(
        classes.root,
        {
          [classes[`${String(size)}Size`]]: size,
          [classes[`${String(color)}Color`]]: color,
        },
        classNameProp
      )}
      {...other}
      style={
        !color && children
          ? {
              color: customColor || colorByChild,
              backgroundColor: customColor
                ? lighten(customColor, coefficientColor)
                : lighten(colorByChild, coefficientColor),
            }
          : null
      }
    >
      {extraAvatars && '+'}
      {children ? initials : undefined}
    </Avatar>
  );

  return <>{tooltip ? <Tooltip title={tooltip}>{renderAvatar()}</Tooltip> : renderAvatar()}</>;
};

UserAvatar.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  className: PropTypes.string,
  size: PropTypes.oneOf(['large', 'medium', 'small', 'mini']),
  color: PropTypes.oneOf(['default', 'error', 'warning', 'success', 'info']),
  customColor: PropTypes.string,
  coefficientColor: PropTypes.number,
  tooltip: PropTypes.string,
  extraAvatars: PropTypes.bool,
};

UserAvatar.defaultProps = {
  size: 'large',
  coefficientColor: 0.825,
};

export default UserAvatar;
