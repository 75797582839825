import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Theme, Typography, useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { DataTable, FullPageLoader } from 'src/components';
import { RootState } from 'src/redux/reducers';
import { FileColsConfig, FolderColsConfig } from 'src/helpers/fileAndFolderColsConfig';
import {
  AdvancedSearchFields,
  AdvancedSearchType,
  advancedSearchTypeToQuery,
  DocumentItem,
  DocumentStatus,
  DocumentType,
  ExtraDocumentType,
  FolderItem,
} from 'src/models/documents.model';
import { useHistory } from 'react-router-dom';
import documentsActions from 'src/redux/actions/documents.actions';
import { IdentitySelector } from 'src/redux/selectors/identity.selector';
import { RequestStatus } from '../../helpers/reduxReuquest.util';
import { DocumentsSelector } from '../../redux/selectors/documents.selector';
import AdvancedSearchFilterBar from '../../components/AdvancedSearchFilterBar/AdvancedSearchFilterBar';
import { useStyles } from './styles';
import usePrevious from '../../helpers/hooks/usePrevious';
import SearchPageWebsocket from './SearchPageWebsocket';

const filterDocuments = (documents: DocumentItem[], search: AdvancedSearchType) => {
  const status = search[AdvancedSearchFields.STATUS];

  return documents.filter((doc) => {
    if (
      status.includes(DocumentStatus.READY_FOR_PREVIEW) &&
      !status.includes(DocumentStatus.EDITING) &&
      doc.status === DocumentStatus.EDITING &&
      !doc.documentType.includes(DocumentType.DRIVE)
    )
      return false;
    return true;
  });
};

const SearchPage = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const smallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const foldersColConfig = FolderColsConfig(t);
  const filesColConfig = FileColsConfig(t);
  const [dragMode, setDragMode] = useState(false);
  const [selectedFilesRowName, setSelectedFilesRowName] = useState<Array<string>>([]);
  const [selectedFilesRows, setSelectedFilesRows] = useState<Array<string>>([]);
  const [selectedFolders, setSelectedFolders] = useState([]);
  const searchLocation = useSelector(
    (state: RootState) => state.documentsStore.advancedSearch[AdvancedSearchFields.LOCATION]
  );
  const {
    advancedSearch,
    advancedSearchedDocuments,
    advancedSearchedDocumentsStatus,
    advancedSearchedDocumentsQuery,
    defaultSortKey,
    defaultSortOrder,
    currentFolderId,
  } = useSelector((state: RootState) => state.documentsStore);
  const workspaceId = useSelector(IdentitySelector.selectWorkspaceId);
  const folders = useSelector(DocumentsSelector.selectAdvancedSearchFolders);
  const folderIsRoot = searchLocation && searchLocation.namePath === '/';
  const isLoadingMore = useRef(false);
  const [sortKey, setSortKey] = useState(defaultSortKey);
  const [sortOrder, setSortOrder] = useState(defaultSortOrder);
  const selectedDocumentTypes = advancedSearch[AdvancedSearchFields.TYPE];
  const hideDocuments =
    selectedDocumentTypes.length === 1 && selectedDocumentTypes.includes(ExtraDocumentType.FOLDER);
  const hideFolders =
    selectedDocumentTypes.length > 0 && !selectedDocumentTypes.includes(ExtraDocumentType.FOLDER);

  const isLoadingMoreFiles =
    advancedSearchedDocumentsStatus === RequestStatus.PENDING &&
    advancedSearchedDocumentsQuery.hasMoreDocuments;
  const isLoadingFiles = advancedSearchedDocumentsStatus === RequestStatus.PENDING;
  const allLoaded = !isLoadingFiles || isLoadingMoreFiles;
  const [offset, setOffset] = useState(0);
  const filteredDocuments = filterDocuments(advancedSearchedDocuments, advancedSearch);
  const noFoldersConfig = {
    label: t('drivePage.foldersTable.noItems'),
  };
  const prevState = usePrevious({
    offset,
    advancedSearchedDocuments,
  });
  const onFolderClick = (row: FolderItem, isDoubleClick: boolean) => {
    if (smallScreen) {
      history.push(`/drive/${row.id}`);
    } else if (!isDoubleClick) {
      setDragMode(true);
      setSelectedFilesRowName([]);
      setSelectedFilesRows([]);
    } else {
      setSelectedFolders([]);
      history.push(`/drive/${row.id}`);
    }
  };

  const onLoadMoreFiles = () => {
    if (
      advancedSearchedDocumentsStatus !== RequestStatus.PENDING &&
      advancedSearchedDocumentsQuery.hasMoreDocuments &&
      !isLoadingMore.current
    ) {
      setOffset(offset + 20);
      isLoadingMore.current = true;
    }
  };

  const goToDocument = (row: DocumentItem) => {
    if (searchLocation)
      localStorage.setItem(
        'createdDocumentFolderId',
        folderIsRoot ? 'root' : searchLocation.idPath
      );

    if (row?.documentType.includes('dsl')) {
      history.push(`/certificate/${row.id}`);
    } else {
      history.push(`/document/${row.id}`);
    }
  };

  const onFileRowSelection = (
    row: DocumentItem,
    callback: (e: string[]) => void = () => undefined
  ) => {
    const newRowsId = Array.from(selectedFilesRows);
    const newRowsName = Array.from(selectedFilesRowName);
    if (selectedFilesRows.indexOf(row.id) !== -1) {
      newRowsId.splice(newRowsId.indexOf(row.id), 1);
      newRowsName.splice(newRowsName.indexOf(row.name), 1);
    } else {
      newRowsId.push(row.id);
      newRowsName.push(row.name);
    }
    callback(newRowsId);
    setSelectedFilesRows(newRowsId);
    setSelectedFilesRowName(newRowsName);
    setSelectedFolders([]);
  };
  const loadFilesRows = useCallback(
    (offset: number) => {
      if (workspaceId) {
        dispatch(
          documentsActions.fetchAdvancedSearchedDocuments({
            workspaceId,
            searchQuery: advancedSearchTypeToQuery(advancedSearch, offset, sortKey, sortOrder),
          })
        );
      }
    },
    [advancedSearch, dispatch, sortKey, sortOrder, workspaceId]
  );

  useEffect(() => {
    if (offset > 0 && offset !== prevState?.offset) {
      loadFilesRows(offset);
    }
  }, [loadFilesRows, offset, prevState]);

  useEffect(() => {
    if (advancedSearchedDocuments.length !== prevState?.advancedSearchedDocuments?.length) {
      isLoadingMore.current = false;
    }
  }, [advancedSearchedDocuments, prevState?.advancedSearchedDocuments]);

  useEffect(() => {
    if (advancedSearch && workspaceId) {
      const searchName = advancedSearch[AdvancedSearchFields.NAME];

      loadFilesRows(0);
      dispatch(
        documentsActions.fetchAdvancedSearchedFolders({
          workspaceId,
          folderId: searchLocation?.idPath,
          searchQuery: encodeURIComponent(
            JSON.stringify({
              limit: 100,
              includeRoot: false,
              ...(searchName.length > 0 && { name: searchName }),
            })
          ),
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchLocation, workspaceId, advancedSearch, sortKey, sortOrder]);

  // Set storage current folder Id
  useEffect(() => {
    if (currentFolderId) {
      localStorage.setItem('currentFolderId', currentFolderId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceId, currentFolderId]);

  useEffect(() => {
    dispatch(documentsActions.getFolderTree(workspaceId, 'root'));

    return () => {
      dispatch(documentsActions.clearAdvancedSearch());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.root}>
      <SearchPageWebsocket />
      <AdvancedSearchFilterBar />
      {!hideFolders && (
        <>
          <Typography className={classes.foldersTitle} variant="h6" color="textPrimary">
            <strong>{t<string>('drivePage.foldersTable.title')}</strong>
          </Typography>
          <DataTable
            iconWidth={40}
            headerTextWidthLikeIcon
            rows={folders}
            cols={foldersColConfig}
            onClick={(row) => {
              onFolderClick(row, false);
            }}
            selectable
            onDblClick={(row) => {
              onFolderClick(row, true);
            }}
            selectedRows={selectedFolders}
            isLoading={false}
            defaultIcon="folder"
            tileRows
            smallScreen={smallScreen}
            disableHeader
            noItems={noFoldersConfig}
          />
        </>
      )}

      {!hideDocuments && (
        <>
          <Typography className={classes.documentsTitle} variant="h6" color="textPrimary">
            <strong>{t<string>('drivePage.documentsTable.title')}</strong>
          </Typography>
          <DataTable
            rows={filteredDocuments}
            cols={filesColConfig}
            withTableHeader
            iconWidth={40}
            headerTextWidthLikeIcon={false}
            onClick={(row) => {
              if (smallScreen) {
                goToDocument(row);
              } else {
                onFileRowSelection(row);
              }
            }}
            onDblClick={(row) => {
              if (!smallScreen) {
                goToDocument(row);
              }
            }}
            onLongPress={(row) => {
              if (smallScreen) {
                onFileRowSelection(row);
              }
            }}
            onDrop={() => undefined}
            selectedRows={selectedFilesRows}
            sortBy={sortKey}
            sortOrder={sortOrder}
            onChangeSort={({ sortOrder, sortKey }) => {
              setSortKey(sortKey);
              setSortOrder(sortOrder);
            }}
            isLoading={isLoadingFiles}
            isLoadingMore={isLoadingFiles}
            hasMoreItems={advancedSearchedDocumentsQuery.hasMoreDocuments} // TODO: remove !
            paginationType="infinite"
            defaultIcon="file"
            smallScreen={smallScreen}
            disableHeader={smallScreen}
            onLoadMore={() => {
              onLoadMoreFiles();
            }}
            noItems={{
              label: t('drivePage.documentsTable.noItems'),
            }}
            isDrag
            dragMode={dragMode}
          />
        </>
      )}

      <FullPageLoader open={!allLoaded} transitionDuration={{ enter: 100, exit: 300 }} />
    </div>
  );
};

export default SearchPage;
