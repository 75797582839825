import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ spacing, palette }) =>
    createStyles({
      root: {
        width: '100%',
      },
      tabHeader: {},
      advancedSettings: {
        marginBottom: spacing(2),
      },
      icon: {
        borderRadius: 10,
        display: 'flex',
        padding: spacing(0.5),
        marginRight: spacing(1),
        '& svg': {
          fontSize: 18,
        },
      },
      iconSuccess: {
        color: palette.greySimple.contrastText,
        backgroundColor: palette.success.light,
      },
      iconDefault: {
        backgroundColor: palette.greySimple.main,
        color: palette.greySimple.contrastText,
      },
      entriesList: {
        paddingTop: spacing(0.5),
      },
      entriesDividerText: {
        padding: spacing(0.5, 0),
        marginTop: spacing(2),
        marginBottom: 0,
      },
      noItems: {
        padding: spacing(1.5, 0),
        textAlign: 'center',
      },
      entriesListTitle: {
        display: 'flex',
        justifyContent: 'space-between',
      },
      row: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: spacing(1),
      },
      helpIcon: {
        color: palette.primary.main,
        marginLeft: 4,
        fontSize: 16,
      },
    }),
  { index: 0 }
);
