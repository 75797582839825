import axios from 'src/helpers/axios';
import { parseError } from 'src/helpers/parseErrors';

const moduleName = 'blockchain-explorer';

const blockchainExplorerService = {
  getDocumentCard,
};

function getDocumentCard(documentId, language) {
  return axios
    .get(`/rest/${moduleName}/document_card/${documentId}`, {
      params: { lang: language },
      responseType: 'blob',
    })
    .then((response) => response)
    .catch((error) => parseError(error));
}

export default blockchainExplorerService;
