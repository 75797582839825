/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable no-nested-ternary */
export const parseError = (error) => {
  const hasErrorData = Object.prototype.hasOwnProperty.call(error.response.data, 'errors');
  return Promise.reject(
    hasErrorData
      ? {
          code: error.response.data.errors[0].code.toString(),
          message: error.response.data.errors[0].message,
          data: error.response.data.errors[0].data,
        }
      : `${error.response.status}: ${error.response.statusText}`
  );
};
