import React, { MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/redux/reducers';
import DirectoryExplorer from 'src/components/DirectoryExplorer/DirectoryExplorer';
import { IdentitySelector } from 'src/redux/selectors/identity.selector';
import { AdvancedSearchFields } from 'src/models/documents.model';
import documentsActions from 'src/redux/actions/documents.actions';
import { Folder as FolderIcon } from 'src/components/CustomIcon';

import Field from '../../subcomponents/AdvancedSearchModalField';
import { useStyles } from './styles';

type DirectoryExplorerReturnType = {
  id: string;
  name: string;
};

const LocationField = (): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const workspaceId = useSelector(IdentitySelector.selectWorkspaceId);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const draftLocation = useSelector(
    (state: RootState) => state.documentsStore.draftAdvancedSearch[AdvancedSearchFields.LOCATION]
  );
  const closeLocationExplorer = () => {
    setAnchorEl(null);
  };

  const handleClick = (e: MouseEvent<HTMLElement>) => {
    if (e) setAnchorEl(e.currentTarget);
  };

  const currentFolderId = useSelector((state: RootState) => state.documentsStore.currentFolderId);
  const handleChange = (value: DirectoryExplorerReturnType) =>
    dispatch(
      documentsActions.updateDraftAdvancedSearch(AdvancedSearchFields.LOCATION, {
        idPath: value.id,
        namePath: value.name,
      })
    );

  return (
    <Field title={t<string>('advancedSearch.fields.location.label')} withInputWrapper>
      <>
        <div aria-hidden="true" onClick={handleClick}>
          <FolderIcon className={classes.icon} />
          {draftLocation && (
            <Typography>
              {draftLocation.namePath === '/' ? t<string>('common.drive') : draftLocation.namePath}
            </Typography>
          )}
          {draftLocation === null && (
            <Typography>{t<string>('advancedSearch.fields.location.placeholder')}</Typography>
          )}
        </div>
        {anchorEl && (
          <DirectoryExplorer
            hideCreateNew
            initialFolderId={draftLocation === null ? currentFolderId : draftLocation.idPath}
            workspaceId={workspaceId}
            onClose={closeLocationExplorer}
            handleChange={handleChange}
            anchorEl={anchorEl}
            confirmText={t<string>('common.apply')}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          />
        )}
      </>
    </Field>
  );
};

export default LocationField;
