import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ breakpoints, spacing }) =>
    createStyles({
      root: {
        paddingTop: spacing(2.5),
        paddingBottom: spacing(2.5),
      },
      updating: {
        pointerEvents: 'none',
      },
      withSubtitle: {
        [breakpoints.down('sm')]: {
          paddingLeft: spacing(2),
        },
        [breakpoints.up('md')]: {
          '& $title': {
            paddingLeft: spacing(2),
            [breakpoints.up('lg')]: {
              paddingLeft: spacing(3),
            },
          },
        },
      },
      title: {
        [breakpoints.down('sm')]: {
          marginBottom: spacing(2),
        },
      },
      label: {
        margin: 0,
        position: 'relative',
        right: -4,
        [breakpoints.down('sm')]: {
          right: 0,
        },
      },
    }),
  { index: 0 }
);
