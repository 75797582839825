import spacing from '../spacing';
import breakpoints from '../breakpoints';

export default {
  root: {
    padding: `0 ${spacing(2)}px ${spacing(2)}px ${spacing(2)}px`,
    [breakpoints.up('md')]: {
      padding: `0 ${spacing(3)}px ${spacing(2)}px ${spacing(3)}px`,
    },
    [breakpoints.up('lg')]: {
      padding: `0 ${spacing(5)}px ${spacing(2.5)}px ${spacing(5)}px`,
    },
  },
};
