import React from 'react';
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon';

export default createSvgIcon(
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 16H17C19.7614 16 22 18.2386 22 21C22 21.5523 21.5523 22 21 22C20.4872 22 20.0645 21.614 20.0067 21.1166L19.9949 20.8237C19.907 19.3072 18.6928 18.093 17.1763 18.0051L17 18H7C5.34315 18 4 19.3431 4 21C4 21.5523 3.55228 22 3 22C2.44772 22 2 21.5523 2 21C2 18.3112 4.12231 16.1182 6.78311 16.0046L7 16ZM12 2C12.4062 2 12.8078 2.04051 13.2004 2.12029C13.7416 2.23029 14.0912 2.75821 13.9812 3.29943C13.8712 3.84065 13.3432 4.19022 12.802 4.08022C12.5404 4.02705 12.2722 4 12 4C9.79086 4 8 5.79086 8 8C8 10.2091 9.79086 12 12 12C13.2748 12 14.4482 11.4008 15.2001 10.4004C15.5319 9.95889 16.1588 9.86999 16.6003 10.2018C17.0418 10.5336 17.1307 11.1605 16.7989 11.602C15.6731 13.0999 13.9104 14 12 14C8.68629 14 6 11.3137 6 8C6 4.68629 8.68629 2 12 2Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.6181 2C18.2742 2 17.9955 2.27875 17.9955 2.6226C17.9955 3.10189 17.4766 3.40143 17.0615 3.16179C16.7637 2.98985 16.3829 3.09188 16.211 3.38968L16.0836 3.61034C15.9117 3.90811 16.0137 4.28887 16.3115 4.46079C16.7265 4.70042 16.7265 5.29951 16.3114 5.53914C16.0136 5.71107 15.9116 6.09187 16.0835 6.38967L16.2109 6.61025C16.3828 6.90807 16.7637 7.0101 17.0615 6.83816C17.4766 6.5985 17.9955 6.89805 17.9955 7.37736C17.9955 7.72124 18.2742 8 18.6181 8H18.8729C19.2167 8 19.4955 7.72125 19.4955 7.3774C19.4955 6.89815 20.0143 6.5986 20.4293 6.83822C20.7271 7.01014 21.1078 6.90812 21.2797 6.61035L21.4072 6.38967C21.5791 6.09188 21.477 5.71108 21.1792 5.53914C20.7642 5.29951 20.7641 4.70042 21.1792 4.46079C21.477 4.28887 21.579 3.90811 21.4071 3.61034L21.2796 3.38958C21.1077 3.09184 20.727 2.98982 20.4292 3.16172C20.0142 3.40133 19.4955 3.10179 19.4955 2.62257C19.4955 2.27875 19.2167 2 18.8729 2H18.6181ZM18.7454 6C19.2976 6 19.7454 5.55228 19.7454 5C19.7454 4.44772 19.2976 4 18.7454 4C18.1931 4 17.7454 4.44772 17.7454 5C17.7454 5.55228 18.1931 6 18.7454 6Z"
      fill="black"
    />
  </>,
  'UserSettings'
);
