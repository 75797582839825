import { makeStyles, createStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ palette }) =>
    createStyles({
      documentStatusText: {
        color: 'inherit',
        marginLeft: '10px',
      },

      modal: {
        width: '400px',
        borderRadius: '6px',
        background: palette.common.white,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        padding: '40px 20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-around',
        boxSizing: 'border-box',
      },

      header: {
        textAlign: 'center',
        marginBottom: '15px',
      },

      message: {
        textAlign: 'center',
        marginBottom: '20px',
      },

      messageWithWarning: {
        color: palette.warning.main,
      },

      messageWithError: {
        color: palette.error.main,
      },

      submitButton: {
        marginBottom: '10px',
      },

      textArea: {
        width: '100%',
        marginBottom: '20px',
      },

      documentStatus: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '20px',

        '&.success': {
          color: palette.success.main,
        },

        '&.default': {
          color: palette.text.secondary,
        },

        '&.error': {
          color: palette.error.main,
        },

        '&.info': {
          color: palette.info.main,
        },

        '&.warning': {
          color: palette.warning.main,
        },
      },

      successButton: {
        marginBottom: '10px',
        backgroundColor: palette.success.main,
        color: palette.common.white,

        '&:hover': {
          backgroundColor: palette.success.main,
          borderColor: palette.common.white,
          boxShadow: 'none',
        },
        '&:active': {
          backgroundColor: palette.success.main,
          borderColor: palette.common.white,
        },
      },

      errorButton: {
        marginBottom: '10px',
        backgroundColor: palette.error.main,
        color: palette.common.white,
        border: `1px solid ${palette.error.main}`,

        '&:hover': {
          backgroundColor: palette.error.main,
          borderColor: palette.common.white,
          boxShadow: 'none',
        },
        '&:active': {
          backgroundColor: palette.error.main,
          borderColor: palette.common.white,
        },
      },

      warningButton: {
        marginBottom: '10px',
        backgroundColor: palette.warning.main,
        color: palette.common.white,

        '&:hover': {
          backgroundColor: palette.warning.main,
          borderColor: palette.common.white,
          boxShadow: 'none',
        },
        '&:active': {
          backgroundColor: palette.warning.main,
          borderColor: palette.common.white,
        },
      },

      button: {
        marginBottom: '10px',
        backgroundColor: palette.primary.main,
        color: palette.common.white,

        '&:hover': {
          backgroundColor: palette.primary.main,
          borderColor: palette.common.white,
          boxShadow: 'none',
        },
        '&:active': {
          backgroundColor: palette.primary.main,
          borderColor: palette.common.white,
        },
      },
    }),
  { index: 0 }
);
