import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { DocumentRole } from 'src/models/document.model';

const SingersCounter = ({ signers }) => {
  const { documentSigners, documentInvitations } = useSelector(
    (state) => state.documentManagementStore,
    undefined
  );
  const invitedNr = documentInvitations.filter((el) => el.role === DocumentRole.SIGNERS).length;
  const signedNr = signers.filter((obj) => Object.is(obj.status, 'SIGNED')).length;
  return (
    <Typography variant="body1" color="textSecondary">
      {signedNr} / {documentSigners.length + invitedNr}
    </Typography>
  );
};

SingersCounter.propTypes = {
  signers: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.string,
    })
  ),
};

export default SingersCounter;
