import React from 'react';
import { Fade, Slide } from '@material-ui/core';

import {
  CheckboxBlankOutlined as CheckboxIcon,
  CheckboxMarked as CheckboxCheckedIcon,
  IndeterminateCheckbox as CheckboxIndeterminateIcon,
  Alert as AlertIcon,
  ArrowDownSLine as ChevronDownIcon,
  ArrowRightSLine as ChevronRightIcon,
  Close as CloseIcon,
} from 'src/components/CustomIcon';

export default {
  MuiAutocomplete: {
    popupIcon: <ChevronDownIcon />,
    forcePopupIcon: false,
    closeIcon: <CloseIcon />,
  },
  MuiAppBar: {
    elevation: 0,
    color: 'default',
  },
  MuiButtonBase: {
    focusRipple: true,
  },
  MuiIconButton: {
    centerRipple: false,
  },
  MuiList: {
    disablePadding: true,
  },
  MuiListItem: {
    divider: true,
  },
  MuiListItemText: {
    disableTypography: true,
  },
  MuiTabs: {
    textColor: 'primary',
  },
  MuiButton: {
    variant: 'contained',
    color: 'primary',
  },
  MuiFab: {
    size: 'large',
    color: 'default',
  },
  MuiCheckbox: {
    color: 'primary',
    icon: <CheckboxIcon />,
    checkedIcon: <CheckboxCheckedIcon />,
    indeterminateIcon: <CheckboxIndeterminateIcon />,
  },
  MuiRadio: {
    color: 'primary',
  },
  MuiTextField: {
    variant: 'outlined',
    size: 'medium',
    InputProps: {
      rows: 4,
    },
    InputLabelProps: {
      disableAnimation: true,
      shrink: true,
    },
  },
  MuiCircularProgress: {
    color: 'primary',
    size: 32,
    thickness: 2.5,
  },
  MuiSwitch: {
    color: 'primary',
  },
  MuiSelect: {
    IconComponent: ChevronDownIcon,
    variant: 'outlined',
  },
  MuiInputLabel: {
    variant: 'outlined',
  },
  MuiLabel: {
    disableAnimation: true,
    shrink: true,
  },
  MuiDrawer: {
    elevation: 12,
  },
  MuiPaper: {
    elevation: 8,
    variant: 'elevation',
  },
  MuiDialog: {
    PaperProps: {
      elevation: 12,
    },
  },
  MuiTooltip: {
    enterDelay: 100,
    enterTouchDelay: 600,
    disableTouchListener: false,
    leaveTouchDelay: 1000,
    arrow: true,
  },
  MuiHidden: {
    implementation: 'css',
  },
  MuiPopover: {
    marginThreshold: 8,
    elevation: 12,
    transitionDuration: 200,
    TransitionComponent: Fade,
  },
  MuiCard: {
    raised: false,
  },
  MuiCardHeader: {
    titleTypographyProps: {
      variant: 'h5',
    },
  },
  MuiAvatar: {},
  MuiBadge: {
    color: 'primary',
    overlap: 'circle',
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'right',
    },
  },
  MuiBreadcrumbs: {
    separator: <ChevronRightIcon />,
  },
  MuiSnackbar: {
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
    transitionDuration: {
      enter: 200,
      exit: 200,
    },
    TransitionComponent: Slide,
  },
  MuiAlert: {
    variant: 'filled',
    elevation: 0,
    iconMapping: {
      error: <AlertIcon />,
      info: <AlertIcon />,
      success: <AlertIcon />,
      warning: <AlertIcon />,
    },
  },
  MuiAvatarGroup: {
    max: 6,
  },
  MuiToggleButtonGroup: {
    exclusive: true,
  },
  MuiLink: {
    underline: 'none',
  },
};
