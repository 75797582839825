import React from 'react';
import { Button, Typography } from '@material-ui/core';
import { ListChoiceButton, SmallContentWithScrollbar } from 'src/components';
import { useTranslation } from 'react-i18next';
import { File, MultipleFiles } from 'src/components/CustomIcon';
import { CertificateQuantityType, CertificateSteps } from 'src/models/certificate.model';
import { useDispatch, useSelector } from 'react-redux';

import { useStyles } from './styles';
import certificateCreatorActions from '../../../../redux/actions/certificateCreator.actions';

const SelectQuantityType = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { certificateQuantityType } = useSelector((state) => state.certificateCreatorStore);

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(
      certificateCreatorActions.setCreatorStep({
        currentStep:
          certificateQuantityType === CertificateQuantityType.Single
            ? CertificateSteps.MapSingleFields
            : CertificateSteps.ImportMultipleData,
      })
    );
  };

  const handleChange = (type) => {
    dispatch(certificateCreatorActions.setQuantityType({ type }));
  };

  return (
    <SmallContentWithScrollbar
      startContent={
        <Typography variant="body1">{t('createCertificate.selectQuantityTitle')}</Typography>
      }
      endContent={
        <Button
          size="large"
          type="submit"
          fullWidth
          disabled={certificateQuantityType === undefined}
        >
          {t('createCertificate.createNewCertificate')}
        </Button>
      }
      formProps={{
        noValidate: true,
        autoComplete: 'off',
        onSubmit,
      }}
      hasForm
    >
      <div className={classes.items}>
        <ListChoiceButton
          icon={<File />}
          label={t('createCertificate.singleCertificateTitle')}
          description={t('createCertificate.singleCertificateDescription')}
          onClick={() => handleChange(CertificateQuantityType.Single)}
          selected={certificateQuantityType === CertificateQuantityType.Single}
        />
        <ListChoiceButton
          icon={<MultipleFiles />}
          label={t('createCertificate.multipleCertificateTitle')}
          disabled={process.env.NODE_ENV === 'production'}
          description={t('createCertificate.multipleCertificateDescription')}
          onClick={() => handleChange(CertificateQuantityType.Multiple)}
          selected={certificateQuantityType === CertificateQuantityType.Multiple}
        />
      </div>
    </SmallContentWithScrollbar>
  );
};

export default SelectQuantityType;
