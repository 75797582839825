import axios from 'src/helpers/axios';
import { parseError } from 'src/helpers/parseErrors';

export const moduleName = 'avatar-service';

const avatarService = {
  getIdentityAvatar,
  getUserAvatar,
  uploadUserAvatar,
  deleteUserAvatar,
  getWorkspaceAvatar,
  uploadWorkspaceAvatar,
  deleteWorkspaceAvatar,
};

function getIdentityAvatar(id) {
  return axios
    .get(`/rest/${moduleName}/avatar/identity/${id}`, {
      params: {
        kind: 'SMALL',
      },
      headers: { 'Cache-Control': 'no-cache' },
    })
    .then((response) => response.data)
    .catch((error) => parseError(error));
}

function getUserAvatar(id) {
  return axios
    .get(`/rest/${moduleName}/avatar/user/${id}`, {
      params: {
        kind: 'SMALL',
      },
      headers: { 'Cache-Control': 'no-cache' },
    })
    .then((response) => response.data)
    .catch((error) => parseError(error));
}

function uploadUserAvatar(file) {
  return axios
    .put(`/rest/${moduleName}/avatar/user`, { image: file })
    .then((response) => response.data)
    .catch((error) => parseError(error));
}

function deleteUserAvatar() {
  return axios
    .delete(`/rest/${moduleName}/avatar/user`)
    .then((response) => response.data)
    .catch((error) => parseError(error));
}

function getWorkspaceAvatar(workspaceId) {
  return axios
    .get(`/rest/${moduleName}/avatar/workspace/${workspaceId}`, {
      params: {
        kind: 'SMALL',
      },
      headers: { 'Cache-Control': 'no-cache' },
    })
    .then((response) => response.data)
    .catch((error) => parseError(error));
}

function uploadWorkspaceAvatar(workspaceId, file) {
  return axios
    .put(`/rest/${moduleName}/avatar/workspace/${workspaceId}`, { image: file })
    .then((response) => response.data)
    .catch((error) => parseError(error));
}

function deleteWorkspaceAvatar(workspaceId) {
  return axios
    .delete(`/rest/${moduleName}/avatar/workspace/${workspaceId}`)
    .then((response) => response.data)
    .catch((error) => parseError(error));
}

export default avatarService;
