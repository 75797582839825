import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useStyles } from './styles';

const ContentContainer = ({
  className: classNameProp,
  children,
  size = 'large',
  horizontal = true,
  vertical = true,
  ...otherProps
}) => {
  const classes = useStyles();
  return (
    <div
      className={clsx(
        classes.root,
        (vertical === true || vertical === 'top') && classes.topVertical,
        (vertical === true || vertical === 'bottom') && classes.bottomVertical,
        (horizontal === true || horizontal === 'left') && classes.leftHorizontal,
        (horizontal === true || horizontal === 'right') && classes.rightHorizontal,
        {
          [classes[`${size}Size`]]: size,
        },
        classNameProp
      )}
      {...otherProps}
    >
      {children}
    </div>
  );
};

ContentContainer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  horizontal: PropTypes.oneOf([true, false, 'left', 'right']),
  vertical: PropTypes.oneOf([true, false, 'top', 'bottom']),
};

export default ContentContainer;
