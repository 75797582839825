import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import TwoFactorForm from 'src/components/TwoFactorModal/subcomponents/TwoFactorForm';
import authActions from 'src/redux/actions/auth.actions';
import { FullScreenDialog } from 'src/components';
import { RequestStatus } from 'src/helpers/reduxReuquest.util';

const ConfirmModal = (props) => {
  const { method, isOpen, handleConfirmDialogClose, onResend } = props;
  const dispatch = useDispatch();

  const [hasErrors, setHasErrors] = useState(false);
  const postEmailRequestStatus = useSelector(
    (state) => state.authStore.postEmailRequestStatus,
    undefined
  );
  const postEmailRequestError = useSelector(
    (state) => state.authStore.postEmailRequestError,
    undefined
  );
  const postPhoneRequestStatus = useSelector(
    (state) => state.authStore.postPhoneRequestStatus,
    undefined
  );
  const postPhoneRequestError = useSelector(
    (state) => state.authStore.postPhoneRequestError,
    undefined
  );

  const lastSendEmailTimestamp = useSelector(
    (state) => state.authStore.lastSendEmailTimestamp,
    undefined
  );
  const lastSendSmsTimestamp = useSelector(
    (state) => state.authStore.lastSendSmsTimestamp,
    undefined
  );

  const emailBeforeConfirmation = useSelector(
    (state) => state.authStore.emailBeforeConfirmation,
    undefined
  );
  const phoneNumberBeforeConfirmation = useSelector(
    (state) => state.authStore.phoneNumberBeforeConfirmation,
    undefined
  );

  useEffect(() => {
    if (
      (postEmailRequestStatus === RequestStatus.ERROR &&
        postEmailRequestError === 'CODE_VERIFICATION_FAILED' &&
        method === 'emailConfirm') ||
      (postPhoneRequestStatus === RequestStatus.ERROR &&
        postPhoneRequestError === 'CODE_VERIFICATION_FAILED' &&
        method === 'phoneConfirm')
    ) {
      setHasErrors(true);
    }
  }, [
    postEmailRequestStatus,
    postPhoneRequestStatus,
    postEmailRequestError,
    postPhoneRequestError,
    method,
  ]);

  const handleSetCode = (confirmationCode) => {
    if (method === 'emailConfirm') {
      dispatch(authActions.confirmEmail(confirmationCode));
    }
    if (method === 'phoneConfirm') {
      dispatch(authActions.confirmPhoneNumber(confirmationCode));
    }
  };

  const handleResend = () => {
    const name = method === 'emailConfirm' ? 'email' : 'phoneNumber';
    const value =
      method === 'emailConfirm' ? emailBeforeConfirmation : phoneNumberBeforeConfirmation;
    onResend(name, value);
    setHasErrors(false);
  };

  return (
    <FullScreenDialog withLogo isOpen={isOpen} onClose={handleConfirmDialogClose}>
      {method && (
        <TwoFactorForm
          hasErrors={hasErrors}
          method={method}
          lastSendTimestamp={
            method === 'emailConfirm' ? lastSendEmailTimestamp : lastSendSmsTimestamp
          }
          isReSending={
            method === 'emailConfirm'
              ? postEmailRequestStatus === RequestStatus.PENDING
              : postPhoneRequestStatus === RequestStatus.PENDING
          }
          isSending={
            method === 'emailConfirm'
              ? postEmailRequestStatus === RequestStatus.PENDING
              : postPhoneRequestStatus === RequestStatus.PENDING
          }
          onSend={(confirmationCode) => {
            setHasErrors(false);
            handleSetCode(confirmationCode);
          }}
          onReSend={() => {
            handleResend();
          }}
          onClearErrors={() => {
            setHasErrors(false);
          }}
        />
      )}
    </FullScreenDialog>
  );
};

ConfirmModal.propTypes = {
  method: PropTypes.string,
  isOpen: PropTypes.bool,
  handleConfirmDialogClose: PropTypes.func,
  onResend: PropTypes.func,
};

export default ConfirmModal;
