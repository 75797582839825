import { createStyles, makeStyles } from '@material-ui/core';
import { lighten } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  ({ palette, spacing }) =>
    createStyles({
      filterBar: {
        display: 'flex',
      },
      clearIcon: {
        color: palette.text.secondary,
        width: 16,
      },
      clearButton: {
        padding: 4,
      },
      clearWrapper: {
        marginRight: 'auto',
        paddingLeft: spacing(2),
      },
      title: {
        marginTop: spacing(2),
        marginBottom: spacing(4),
        fontSize: 16,
      },
      wrapper: {
        display: 'flex',
      },
      chip: {
        position: 'relative',
        padding: spacing(1, 2, 1, 0.5),
        marginRight: spacing(1),
        backgroundColor: 'transparent !important',
        '&:hover': {
          backgroundColor: 'transparent !important',
          borderColor: palette.primary.main,
          '& *': {
            color: `${palette.primary.main}!important`,
            borderTopColor: palette.primary.main,
          },
        },
      },
      chipActive: {
        borderColor: 'transparent',
        paddingRight: spacing(0.5),
        backgroundColor: `${lighten(palette.primary.main, 0.85)}!important`,
        '&:hover': {
          borderColor: palette.primary.main,
          backgroundColor: `${lighten(palette.primary.main, 0.85)}!important`,
        },
        '& *': {
          color: `${palette.primary.main}!important`,
          borderTopColor: `${palette.primary.main}!important`,
        },
      },
      dropdownIcon: {
        width: 0,
        height: 0,
        position: 'absolute',
        borderLeft: '4px solid transparent',
        borderRight: '4px solid transparent',
        borderTop: `4px solid ${palette.text.secondary}`,
        top: 14,
        right: 8,
      },
      chipIcon: {
        fontSize: 18,
        color: palette.text.secondary,
      },
    }),
  { index: 0 }
);
