import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { ButtonBase } from '@material-ui/core';
import { lighten } from '@material-ui/core/styles';
import {
  amber,
  blue,
  cyan,
  deepOrange,
  deepPurple,
  green,
  indigo,
  lightBlue,
  lightGreen,
  lime,
  orange,
  pink,
  purple,
  red,
  teal,
  yellow,
} from '@material-ui/core/colors';
import { useStyles } from './styles';

const Tag = ({
  children,
  className: classNameProp,
  onClick,
  color,
  automaticColor,
  automaticCoefficientColor,
}) => {
  const classes = useStyles();
  const Component = !onClick ? 'div' : ButtonBase;

  const colors = [
    red[600],
    pink[600],
    purple[600],
    deepPurple[600],
    indigo[600],
    blue[600],
    lightBlue[600],
    cyan[600],
    teal[600],
    green[600],
    lightGreen[600],
    lime[600],
    yellow[600],
    amber[600],
    orange[600],
    deepOrange[600],
  ];

  const colorFromText = (text) => {
    const charCodes = String(text)
      .split('')
      .map((char) => char.charCodeAt(0))
      .join('');
    return parseInt(charCodes, 10);
  };

  return (
    <Component
      className={clsx(
        classes.root,
        !onClick ? classes.notClickableRoot : classes.clickableRoot,
        !automaticColor && {
          [classes[`${String(color)}Color`]]: color,
        },
        classNameProp
      )}
      onClick={onClick}
      style={
        automaticColor && children
          ? {
              color: colors[colorFromText(children) % colors.length],
              backgroundColor: lighten(
                colors[colorFromText(children) % colors.length],
                automaticCoefficientColor
              ),
            }
          : null
      }
    >
      {children}
    </Component>
  );
};

Tag.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  color: PropTypes.oneOf(['default', 'error', 'warning', 'success', 'info']),
  automaticColor: PropTypes.bool,
  automaticCoefficientColor: PropTypes.number,
};

Tag.defaultProps = {
  color: 'default',
  automaticColor: false,
  automaticCoefficientColor: 0.85,
};

export default Tag;
