import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { useDrop } from 'react-dnd';
import { useStyles } from './styles';
import { FolderOpen as FolderIcon } from '../CustomIcon';

const LinkBehavior = React.forwardRef((props, ref) => <RouterLink ref={ref} {...props} />);

const LinkDnd = ({ folder, isMenuLink }) => {
  const classes = useStyles();
  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'ITEM',
    item: { folder },
    drop: () => folder,
    collect: (monitor) => ({
      isOver: monitor.isOver({ shallow: true }),
    }),
  }));

  return (
    <Link
      ref={drop}
      className={clsx(
        isMenuLink ? classes.menuLink : classes.breadcrumbLink,
        isOver && classes.dropBackground
      )}
      key={`bread-${folder.id}`}
      component={LinkBehavior}
      to={`/drive${folder.path}`}
    >
      {folder.path === '' && <FolderIcon />}
      {folder.name}
    </Link>
  );
};

LinkDnd.propTypes = {
  folder: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    path: PropTypes.string,
    isActive: PropTypes.bool,
  }),
  isMenuLink: PropTypes.bool,
};

export default LinkDnd;
