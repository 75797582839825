import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import certificateCreatorActions from 'src/redux/actions/certificateCreator.actions';

const CertificateCreatorLongPooling = () => {
  const dispatch = useDispatch();
  const { sentForGeneratingList, generatedCertFileList } = useSelector(
    (state) => state.certificateCreatorStore,
    undefined
  );
  const intervalId = useRef(null);

  useEffect(() => {
    if (
      sentForGeneratingList.length > 0 &&
      Object.keys(generatedCertFileList).length !== sentForGeneratingList.length
    ) {
      intervalId.current = setInterval(() => {
        dispatch(
          certificateCreatorActions.checkCreatedFileIds(
            sentForGeneratingList.map((el) => el.documentId).filter((el) => el)
          )
        );
      }, 3000);
    } else if (Object.keys(generatedCertFileList).length === sentForGeneratingList.length) {
      clearInterval(intervalId.current);
    }
    return () => clearInterval(intervalId.current);
  }, [dispatch, sentForGeneratingList, generatedCertFileList]);

  return null;
};

export default CertificateCreatorLongPooling;
