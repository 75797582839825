import typography from '../typography';

export default {
  root: {
    textTransform: 'lowercase',
    opacity: 0.65,
    fontSize: typography.h4.fontSize,
    '&:hover:not($selected)': {
      opacity: 0.8,
      // color: palette.text.primary,
    },
    '&$selected': {
      opacity: 1,
      // color: palette.primary.main,
    },
  },
  selected: {},
};
