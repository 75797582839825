import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  CircularProgress,
  Fab,
  Fade,
  LinearProgress,
  ListItemSecondaryAction,
  ListSubheader,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { ContentContainer, EmptyBanner } from 'src/components';
import { VisibilityOffOutlined as EmptyImageIcon } from '@material-ui/icons';
import {
  ArrowDown as ArrowDownIcon,
  ArrowTop as ArrowTopIcon,
  Close as CloseIcon,
  FileColor as FileIcon,
  Fullscreen as FullScreenIcon,
  FullscreenExit as FullScreenExitIcon,
  Layers as LayersIcon,
} from 'src/components/CustomIcon';
import { RequestStatus } from 'src/helpers/reduxReuquest.util';
import { useStyles } from './styles';

const DocumentContent = ({
  filesSrc,
  files,
  activeFileIndex,
  activeFileIndexRedux,
  setActiveFileIndexDocument,
  smallContent,
  getDownloadToken,
  changeFileIndex,
  checkShowScroll,
  loading,
  disabledMenu,
  setDisabledMenu,
  watermark,
  watermarkText,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const layersButtonSize = smallContent ? 'medium' : 'large';
  const largeContent = useMediaQuery(({ breakpoints }) => breakpoints.up('lg'));
  const [documentMaximized, setDocumentMaximized] = useState(false);

  const [anchorLayersEl, setAnchorLayersEl] = useState(null);

  const {
    getDocumentStatus,
    downloadFileStatus,
    getDownloadTokenStatus,
    allFilesLoadedStatus,
  } = useSelector((state) => state.documentDisplayStore);

  const tooltipPlacement = 'right';
  const handleOpenLayers = (event) => {
    setAnchorLayersEl(event.currentTarget);
  };
  const handleCloseLayers = () => {
    setAnchorLayersEl(null);
  };

  const checkActiveFileOnScroll = () => {
    const mainNavLinks = files?.map((file) => `document-number-${file.index}`);
    const scrollContent = document.getElementById('scroll-element-file');

    const fromTop = scrollContent.scrollTop;
    mainNavLinks?.forEach((link) => {
      const section = document.querySelectorAll(`.${link}`);
      if (section && section.length) {
        const sectionFirst = section[0];
        const sectionLast = section[section.length - 1];

        if (
          sectionFirst.offsetTop <= fromTop &&
          sectionLast.offsetTop + sectionLast.offsetHeight >= fromTop
        ) {
          setActiveFileIndexDocument(Number(link.split('-')[2]));
        } else if (fromTop === 0) {
          setActiveFileIndexDocument(Number(filesSrc.length ? filesSrc[0]?.indexFile : 0));
        }
      }
    });
  };

  useEffect(() => {
    const scrollContent = document.getElementById('scroll-element-file');
    if (getDocumentStatus === RequestStatus.SUCCESS) {
      scrollContent.addEventListener('scroll', checkActiveFileOnScroll, false);
    }
    return () => {
      scrollContent.removeEventListener('scroll', checkActiveFileOnScroll, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDocumentStatus]);

  useEffect(() => {
    if (downloadFileStatus === RequestStatus.SUCCESS) {
      checkActiveFileOnScroll();
      setDisabledMenu(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadFileStatus]);

  const layersOpen = Boolean(anchorLayersEl);

  const fetchMoreData = () => {
    if (filesSrc && filesSrc.length) {
      if (files[activeFileIndexRedux].pages === 0) {
        getDownloadToken(0);
        return;
      }
      if (downloadFileStatus !== RequestStatus.PENDING) getDownloadToken();
    }
  };

  const filesEmpty = files && !files.length;
  const filesIsNotEmpty = files && files.length;

  const isArrowButtonsDisabled = files && files.length <= 1;

  return (
    <>
      <div className={classes.root} id="scroll-element-file">
        {!smallContent && !filesEmpty && (
          <ContentContainer
            vertical={false}
            horizontal="right"
            size="medium"
            className={classes.layers}
          >
            <div className={classes.layersInner}>
              {isArrowButtonsDisabled ? (
                <Fab color="secondary" size={layersButtonSize} disabled>
                  <ArrowTopIcon />
                </Fab>
              ) : (
                <Tooltip title={t('documentActions.layers.previous')} placement={tooltipPlacement}>
                  <Fab
                    color="secondary"
                    size={layersButtonSize}
                    onClick={() => changeFileIndex('prev')}
                  >
                    <ArrowTopIcon />
                  </Fab>
                </Tooltip>
              )}

              <Tooltip title={t('documentActions.layers.fileList')} placement={tooltipPlacement}>
                <Fab
                  color="secondary"
                  size={layersButtonSize}
                  onClick={handleOpenLayers}
                  classes={{ root: clsx(layersOpen && classes.activeLayersFabButton) }}
                >
                  <LayersIcon />
                </Fab>
              </Tooltip>

              {largeContent && (
                <Tooltip
                  title={documentMaximized ? t('common.zoomOut') : t('common.zoomIn')}
                  placement={tooltipPlacement}
                >
                  <Fab
                    color="secondary"
                    size={layersButtonSize}
                    onClick={() => setDocumentMaximized(!documentMaximized)}
                  >
                    {documentMaximized ? <FullScreenExitIcon /> : <FullScreenIcon />}
                  </Fab>
                </Tooltip>
              )}

              {isArrowButtonsDisabled ? (
                <Fab color="secondary" size={layersButtonSize} disabled>
                  <ArrowDownIcon />
                </Fab>
              ) : (
                <Tooltip title={t('documentActions.layers.next')} placement={tooltipPlacement}>
                  <Fab
                    color="secondary"
                    size={layersButtonSize}
                    onClick={() => changeFileIndex('next')}
                  >
                    <ArrowDownIcon />
                  </Fab>
                </Tooltip>
              )}
            </div>
          </ContentContainer>
        )}

        <InfiniteScroll
          dataLength={filesSrc.length}
          next={fetchMoreData}
          refreshFunction={fetchMoreData}
          pullDownToRefresh
          scrollThreshold="200px"
          pullDownToRefreshThreshold="200px"
          hasMore
          style={{
            overflow: 'initial',
            position: 'relative',
          }}
          // loader={loading && <LinearProgress className={classes.loaderFile} />}
          loader={false}
          scrollableTarget="scroll-element-file"
        >
          <div className={classes.inner}>
            <div className={classes.documentWrapper}>
              {filesSrc &&
                files &&
                filesSrc.map((src, index) => (
                  <div
                    key={index}
                    className={clsx(
                      classes.imageContainer,
                      !src.file.length && classes.imageEmptyContainer,
                      (documentMaximized || !largeContent) && classes.imageMaximized,
                      `document-number document-number-${src.indexFile}`
                    )}
                    id={src.page === 0 ? `document-number-${src.indexFile}` : null}
                  >
                    <div className={classes.imageInner}>
                      {watermark && watermarkText && (
                        <Fade in>
                          <div className={classes.watermark}>
                            <div className={classes.watermarkInner}>
                              <Typography component="span">{watermarkText}</Typography>
                            </div>
                          </div>
                        </Fade>
                      )}
                      {!src.file.length && files.length ? (
                        <div className={classes.emptyImageBoxContainer}>
                          <EmptyBanner
                            src="/images/frames/no-workspace.svg"
                            disabledImage
                            title={files[src.indexFile]?.name}
                            onLoad={() => checkShowScroll()}
                            description={t('documentActions.emptyImageDescription')}
                            titleVariant="h3"
                            classes={{
                              root: classes.emptyImageBox,
                              inner: classes.emptyImageBoxInner,
                            }}
                          >
                            <EmptyImageIcon classes={{ root: classes.emptyImageIcon }} />
                          </EmptyBanner>
                        </div>
                      ) : (
                        <img src={src.file} onLoad={() => checkShowScroll()} alt={index} />
                      )}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </InfiniteScroll>
      </div>

      {filesEmpty && (
        <div className={classes.emptyFilesInfoBox}>
          <EmptyBanner
            src="/images/frames/document-generating.svg"
            title={t('documentActions.emptyFiles')}
            description={t('documentActions.emptyFilesDescription')}
            titleVariant="h2"
          />
        </div>
      )}

      {loading && (
        <>
          {!filesSrc.length ? (
            <div className={classes.firstLoader}>
              <CircularProgress size={48} />
            </div>
          ) : (
            <div className={classes.loaderFile}>
              <LinearProgress />
            </div>
          )}
        </>
      )}

      <Menu
        classes={{
          paper: classes.layersMenu,
        }}
        open={layersOpen}
        anchorEl={anchorLayersEl}
        onClose={handleCloseLayers}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
      >
        <ListSubheader>
          {t('documentActions.layers.fileList')}

          <ListItemSecondaryAction>
            <Fab
              size="small"
              color="secondary"
              onClick={handleCloseLayers}
              classes={{ root: classes.layersMenuCloseButton }}
            >
              <CloseIcon />
            </Fab>
          </ListItemSecondaryAction>
        </ListSubheader>
        {filesIsNotEmpty ? (
          files.map((item, index) => (
            <MenuItem
              key={index}
              selected={item.index === activeFileIndex}
              classes={{ root: classes.layersMenuItem }}
              disabled={
                getDownloadTokenStatus === RequestStatus.PENDING ||
                downloadFileStatus === RequestStatus.PENDING ||
                disabledMenu
              }
              onClick={() => {
                !allFilesLoadedStatus && setDisabledMenu(true);
                changeFileIndex(item.index);
                handleCloseLayers();
              }}
            >
              {item.name}
              <ListItemSecondaryAction classes={{ root: classes.layersMenuItemIcon }}>
                <FileIcon />
              </ListItemSecondaryAction>
            </MenuItem>
          ))
        ) : (
          <MenuItem classes={{ root: classes.layersMenuItem }}>{t('common.empty')}</MenuItem>
        )}
      </Menu>
    </>
  );
};

DocumentContent.propTypes = {
  filesSrc: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
  files: PropTypes.arrayOf(PropTypes.shape()),
  activeFileIndex: PropTypes.number,
  activeFileIndexRedux: PropTypes.number,
  setActiveFileIndexDocument: PropTypes.func,
  getDownloadToken: PropTypes.func,
  changeFileIndex: PropTypes.func,
  checkShowScroll: PropTypes.func,
  loading: PropTypes.bool,
  disabledMenu: PropTypes.bool,
  setDisabledMenu: PropTypes.func,
  smallContent: PropTypes.bool,
  watermark: PropTypes.bool,
  watermarkText: PropTypes.string,
};

export default DocumentContent;
