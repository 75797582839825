import axios from 'src/helpers/axios';
import { parseError } from 'src/helpers/parseErrors';
import { DocumentRole } from 'src/models/document.model';

export const moduleName = 'document-service';

const getUploadToken = (service = moduleName) =>
  axios
    .get(`/rest/${service}/files/upload-token?t=${Date.now()}`)
    .then((response) => response.data)
    .catch((error) => parseError(error));

const createNewDocument = (workspaceId, name, documentType, filesIds, signatureLevel, folderId) => {
  const body = {
    name,
    documentType,
    filesIds,
    tags: [],
    roles: {
      viewersIds: [],
      auditsIds: [],
      signers: {
        isOrderSignificant: false,
        requiredSignersCount: 0,
        ids: [],
      },
      editorsIds: [],
      adminsIds: [],
    },
    signatureLevel: signatureLevel || null,
  };
  return axios
    .post(`/rest/${moduleName}/workspaces/${workspaceId}/documents`, body, {
      params: { folderId },
      withPermissions: ['DOCUMENT_SERVICE_USER_POST_DOCUMENTS'],
    })
    .then((response) => response.data)
    .catch((error) => parseError(error));
};

const getDocument = (documentId, service = moduleName) =>
  axios
    .get(`/rest/${service}/documents/${documentId}`, {
      withPermissions: ['DOCUMENT_SERVICE_USER_GET_DOCUMENTS'],
    })
    .then((response) => response.data)
    .catch((error) => parseError(error));

const downloadDocument = (documentId, service = moduleName) =>
  axios
    .post(`/rest/${service}/documents/${documentId}/download`)
    .then((response) => response.data)
    .catch((error) => parseError(error));

const getDownloadToken = (documentId, index, page, service = moduleName) =>
  axios
    .get(
      `/rest/${service}/documents/${documentId}/files/download-token?index=${index}&page=${page}`,
      {
        withPermissions: ['DOCUMENT_SERVICE_USER_GET_FILE_DOWNLOAD'],
      }
    )
    .then((response) => response)
    .catch((error) => parseError(error));

const renameDocument = (documentId, name, service = moduleName) =>
  axios
    .put(
      `/rest/${service}/documents/${documentId}/name`,
      { name },
      {
        withPermissions: ['DOCUMENT_SERVICE_USER_PUT_DOCUMENT_NAME'],
      }
    )
    .then((response) => response.data)
    .catch((error) => parseError(error));

const signDocument = (documentId) =>
  axios
    .post(`/rest/${moduleName}/documents/${documentId}/sign`, null, {
      withPermissions: ['DOCUMENT_SERVICE_USER_POST_SIGN_DOCUMENT'],
    })
    .then((response) => response.data)
    .catch((error) => parseError(error));

const rejectDocument = (documentId, reason) =>
  axios
    .post(
      `/rest/${moduleName}/documents/${documentId}/reject`,
      { reason },
      {
        withPermissions: ['DOCUMENT_SERVICE_USER_POST_REJECT_DOCUMENT'],
      }
    )
    .then((response) => response.data)
    .catch((error) => parseError(error));

const getHistoryDocument = (
  documentId,
  workspaceId,
  limit = 10,
  offset = 0,
  performedAction = [],
  rangeDate = [],
  service = moduleName
) => {
  const query = encodeURIComponent(
    JSON.stringify({
      limit,
      offset,
      documentId,
      workspaceId,
      performedAction,
      dateFrom: rangeDate.length ? rangeDate[0] : null,
      dateTo: rangeDate.length ? rangeDate[1] : null,
    })
  );
  return axios
    .get(`/rest/${service}/documents/history?query=${query}`, {
      withPermissions: ['DOCUMENT_SERVICE_USER_GET_DOCUMENT_HISTORY'],
    })
    .then((response) => response.data)
    .catch((error) => parseError(error));
};

const getDocumentMembers = (documentId, service = moduleName) =>
  axios
    .get(`/rest/${service}/documents/${documentId}/members`, {
      withPermissions: ['DOCUMENT_SERVICE_USER_GET_ROLES'],
    })
    .then((response) => response.data)
    .catch((error) => parseError(error));

const getDocumentInvitations = (documentId, service = moduleName) =>
  axios
    .get(`/rest/${service}/documents/${documentId}/invitations`, {
      withPermissions: ['DOCUMENT_SERVICE_USER_GET_DOCUMENT_INVITE'],
    })
    .then((response) => response.data)
    .catch((error) => parseError(error));

const getDocumentRoleTypes = (documentId, service = moduleName) =>
  axios
    .get(`/rest/${service}/documents/${documentId}/roles`)
    .then((response) => response.data)
    .catch((error) => parseError(error));

const getDocumentMembersByRole = (documentId, role = DocumentRole.ADMINS, service = moduleName) =>
  axios
    .get(`/rest/${service}/documents/${documentId}/roles/${role}`, {
      withPermissions: ['DOCUMENT_SERVICE_USER_GET_ROLES'],
    })
    .then((response) => response.data)
    .catch((error) => parseError(error));

const getSignatures = (documentId) =>
  axios
    .get(`/rest/${moduleName}/documents/${documentId}/signatures`, {
      withPermissions: ['DOCUMENT_SERVICE_USER_GET_ROLES'],
    })
    .then((response) => response.data)
    .catch((error) => parseError(error));

const documentCoreService = {
  getUploadToken,
  createNewDocument,
  getDocument,
  downloadDocument,
  getDownloadToken,
  renameDocument,
  signDocument,
  rejectDocument,
  getHistoryDocument,
  getDocumentMembers,
  getDocumentInvitations,
  getDocumentMembersByRole,
  getDocumentRoleTypes,
  getSignatures,
};

export default documentCoreService;
