enum communicationsConstants {
  GET_USER_NOTIFICATIONS_CHANNELS_REQUEST = 'GET_USER_NOTIFICATIONS_CHANNELS_REQUEST',
  GET_USER_NOTIFICATIONS_CHANNELS_SUCCESS = 'GET_USER_NOTIFICATIONS_CHANNELS_SUCCESS',
  GET_USER_NOTIFICATIONS_CHANNELS_FAILURE = 'GET_USER_NOTIFICATIONS_CHANNELS_FAILURE',

  UPDATE_NOTIFICATION_CHANNEL_REQUEST = 'UPDATE_NOTIFICATION_CHANNEL_REQUEST',
  UPDATE_NOTIFICATION_CHANNEL_SUCCESS = 'UPDATE_NOTIFICATION_CHANNEL_SUCCESS',
  UPDATE_NOTIFICATION_CHANNEL_FAILURE = 'UPDATE_NOTIFICATION_CHANNEL_FAILURE',

  GET_UNREAD_USER_NOTIFICATION_SYSTEM_MESSAGES_REQUEST = 'GET_UNREAD_USER_NOTIFICATION_SYSTEM_MESSAGES_REQUEST',
  GET_UNREAD_USER_NOTIFICATION_SYSTEM_MESSAGES_SUCCESS = 'GET_UNREAD_USER_NOTIFICATION_SYSTEM_MESSAGES_SUCCESS',
  GET_UNREAD_USER_NOTIFICATION_SYSTEM_MESSAGES_FAILURE = 'GET_UNREAD_USER_NOTIFICATION_SYSTEM_MESSAGES_FAILURE',

  UPDATE_UNREAD_USER_NOTIFICATION_SYSTEM_MESSAGES_COUNTER = 'UPDATE_UNREAD_USER_NOTIFICATION_SYSTEM_MESSAGES_COUNTER',
  GET_UNREAD_USER_NOTIFICATION_SYSTEM_MESSAGES_COUNTER_REQUEST = 'GET_UNREAD_USER_NOTIFICATION_SYSTEM_MESSAGES_COUNTER_REQUEST',
  GET_UNREAD_USER_NOTIFICATION_SYSTEM_MESSAGES_COUNTER_SUCCESS = 'GET_UNREAD_USER_NOTIFICATION_SYSTEM_MESSAGES_COUNTER_SUCCESS',
  GET_UNREAD_USER_NOTIFICATION_SYSTEM_MESSAGES_COUNTER_FAILURE = 'GET_UNREAD_USER_NOTIFICATION_SYSTEM_MESSAGES_COUNTER_FAILURE',

  GET_READ_USER_NOTIFICATION_SYSTEM_MESSAGES_REQUEST = 'GET_READ_USER_NOTIFICATION_SYSTEM_MESSAGES_REQUEST',
  GET_READ_USER_NOTIFICATION_SYSTEM_MESSAGES_SUCCESS = 'GET_READ_USER_NOTIFICATION_SYSTEM_MESSAGES_SUCCESS',
  GET_READ_USER_NOTIFICATION_SYSTEM_MESSAGES_FAILURE = 'GET_READ_USER_NOTIFICATION_SYSTEM_MESSAGES_FAILURE',

  SET_USER_NOTIFICATION_AS_READ_REQUEST = 'SET_USER_NOTIFICATION_AS_READ_REQUEST',
  SET_USER_NOTIFICATION_AS_READ_SUCCESS = 'SET_USER_NOTIFICATION_AS_READ_SUCCESS',
  SET_USER_NOTIFICATION_AS_READ_FAILURE = 'SET_USER_NOTIFICATION_AS_READ_FAILURE',

  SET_USER_ALL_NOTIFICATIONS_AS_READ_REQUEST = 'SET_USER_ALL_NOTIFICATIONS_AS_READ_REQUEST',
  SET_USER_ALL_NOTIFICATIONS_AS_READ_SUCCESS = 'SET_USER_ALL_NOTIFICATIONS_AS_READ_SUCCESS',
  SET_USER_ALL_NOTIFICATIONS_AS_READ_FAILURE = 'SET_USER_ALL_NOTIFICATIONS_AS_READ_FAILURE',

  CLEAR_COMMUNICATIONS_STORE = 'CLEAR_COMMUNICATIONS_STORE',
}

export default communicationsConstants;
